import React, { Component } from "react";
import moment from "moment-timezone";
import "./css/LoginComponent.css";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import clsx from 'clsx';

import { unlockScreen, loadUsers, logoutUser } from "../../redux/actions/auth";
import { withStyles } from "@material-ui/core/styles";
import { loadProperty } from "../../redux/actions/property";
import { createErrorMessageSelector } from "../../redux/selectors/selectors";
import { PAGES } from "../../utils/constants";
import { fetchDateFormat, DATE_FORMATS } from "../../utils/utility";
import { colorPrimaryBg } from "../../utils/css";
import { loadOneCashDrawer, setCashDrawerDialogCloseValue } from "../../redux/actions/cashDrawer";

import ConfirmLogoutModal from './ConfirmLogoutModal';

import { Hidden } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import BackspaceIcon from "@material-ui/icons/Backspace";
import PowerIcon from '@material-ui/icons/PowerSettingsNew';

function getInitialState() {
  return {
    enteredPin: [],
    parentDotsClass: "confirmation-dots",
    logoutModal: false,
    registerClosed: false,
    dots: [
      { key: 1, class: "dot" },
      { key: 2, class: "dot" },
      { key: 3, class: "dot" },
      { key: 4, class: "dot" }
    ]
  };
}

const styles = theme => ({
  container: {
    alignItems: "center",
    background: '#F8F9FB',
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    justifyContent: "center",
    position: 'relative',
    width: "100%",
  },
  status: {
    background: '#715497',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    color: '#FFFFFF',
    display: 'flex',
    fontSize: '1.2rem',
    justifyContent: 'center',
    maxWidth: 462,
    padding: '20px 0px',
    width: '100%',
  },
  closed: {
    background: '#000000'
  },
  formWrapper: {
    backgroundColor: "#FFFFFF",
    border: '1px solid #ECECEC',
    padding: "40px 30px",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    maxWidth: 400,
    [theme.breakpoints.down('xs')]:{
      backgroundColor: "#F8F9FB",
      padding: "40px 15px",
      border: 'none'
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  imageContainer: {
    alignItems: 'center',
    borderRadius: '50%',
    border: '1px solid #ECECEC',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  propertyLogo: {
    height: 100,
    width: 100,
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: 60,
      width: 60,
    }
  },
  title: {
    color: '#484848',
    fontSize: '1.8rem',
    fontWeight: 600,
    marginTop: 20,
  },
  subTitle: {
    color: '#666666',
    fontSize: '1.3rem',
    marginTop: 10,
    marginBottom: 40,
  },
  passcodeText: {
    color: '#666666',
    display: "flex",
    justifyContent: "center",
    marginBottom: 40
  },
  pinContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  bottomBar: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 20px',
    width: 'calc(100vw - 40px)'
  },
  powerContainer: {
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: 40,
    bottom: 40,
    height: 40,
    width: 40,
    '&:hover': {
      background: '#E0E0E0',
    },
    [theme.breakpoints.down('xs')]:{
      bottom: 20,
      left: 20
    }
  },
  powerIcon: {
    fontSize: '3rem',
    color: '#b83b3b'
  }
})

class LoginComponent extends Component {
  constructor(props) {
    super(props);

    this.state = getInitialState();
    this.entered = " entered";
    this.error = " error";
    this.success = " success";
  }

  componentDidMount() {
    const { register, property} = this.props;
    // If logged in and user navigates to lock page, should redirect them to dashboard
    if (this.props.auth && this.props.auth.isAuthenticated) {
      if (localStorage.sessionToken) this.props.history.push(`/${PAGES.LANDING}`);
    }
    if (!this.props.auth || (this.props.auth && !this.props.auth.isAuthenticated)) {
      this.props.history.push(`/${PAGES.LOGIN}`);
    }
    if (!register.propertyID) {
      this.props.history.push(`/${PAGES.SETUP}`);
    }
    if (property && property.cashDrawer && property.cashDrawer[register.cashDrawerID]) {
      this.setState({registerClosed: property.cashDrawer[register.cashDrawerID].isClosed})
    } else {
      this.setState({registerClosed: true});
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, register, property} = this.props;
    if (this.props.errorMessage && !_.isEqual(this.props.errorMessage, prevProps.errorMessage)) {
      this.handleError();
    }
    if (
      this.props.unlockSuccess && !_.isEqual(prevProps.unlockSuccess, this.props.unlockSuccess)
    ) {
      this.handleFetchProperty();
      this.handleloadUsers();
      dispatch(setCashDrawerDialogCloseValue(false));
      dispatch(loadOneCashDrawer(this.props.register.cashDrawerID ))
    }
  }

  handleInitKeypad = num => {
    let { enteredPin } = this.state;
    if (enteredPin.length >= 4) {
      return;
    } else {
      enteredPin.push(num);
      this.handleBindPinToDisplay(enteredPin, this.entered);
      if (enteredPin.length === 4) {
        this.handleSubmit(enteredPin);
      }
    }
    this.setState({ enteredPin });
  };

  handleBindPinToDisplay = (enteredPin, pinStatus) => {
    if (enteredPin.length <= 4) {
      let dots = [...this.state.dots];
      if (pinStatus === this.entered) {
        const index = enteredPin.length - 1;
        dots[index].class += pinStatus;
      } else if (pinStatus === this.error || pinStatus === this.success) {
        dots = dots.map(d => {
          d.class += pinStatus;
          return d;
        });
      }
      this.setState({ dots });
    }
  };

  handleReset = () => {
    let initialState = getInitialState();
    this.setState(initialState);
  };

  handleBackspace = () => {
    let enteredPin = [...this.state.enteredPin];
    if (enteredPin.length > 0) {
      const dots = [...this.state.dots];
      const index = enteredPin.length - 1;
      dots[index].class = "dot";
      enteredPin.pop();
      this.setState({
        dots,
        enteredPin,
        parentDotsClass: "confirmation-dots"
      });
    }
  };

  handleSubmit = enteredPin => {
    let pin = _.join(enteredPin, "");
    this.props.dispatch(
      unlockScreen(this.props.propertyID, { loginPin: pin }, this.props.history)
    );
    let initialState = getInitialState();
    this.setState(initialState);
  };

  handleError = () => {
    let { parentDotsClass } = this.state;
    parentDotsClass += this.error;
    this.handleBindPinToDisplay(this.state.enteredPin, this.error);
    this.setState({ parentDotsClass: parentDotsClass });
    setTimeout(() => this.handleReset(), 300);
  };

  handleFetchProperty = () => {
    this.props.dispatch(loadProperty());
  };

  handleloadUsers = () => {
    this.props.dispatch(loadUsers());
  };

  handleLogout = () => {
    this.props.dispatch(logoutUser(this.props.history))
  }

  render() {
    const { parentDotsClass, logoutModal, registerClosed } = this.state;
    const { classes, register } = this.props;
    const year = fetchDateFormat(moment(), DATE_FORMATS.YEAR);
    return (
      <div className={classes.container}>
        <div className={clsx({[classes.status]: true, [classes.closed]: registerClosed})}>
          {registerClosed ? 'Register Closed' : 'Register Open'}
        </div>
        <div className={classes.formWrapper}>
          
          <div className={classes.titleContainer}>
            <div className={classes.imageContainer}><img className={classes.propertyLogo} src="/images/walking-tour.jpg" /></div>
            <div className={classes.title}>{register.propertyName}</div>
            <div className={classes.subTitle}>{register.cashDrawerName}</div>
          </div>

          <form className={classes.pinContainer}>
            <span className={parentDotsClass}>
              {this.state.dots.map(dot => (
                <span key={dot.key} className={dot.class} />
              ))}
            </span>

            <Grid container justify="center">
              <Grid item>
                <ButtonGroup
                  color="primary"
                  aria-label="Outlined primary button group"
                  className="numpad-button"
                >
                  <Button
                    onClick={() => this.handleInitKeypad(1)}
                    defaultValue={1}
                  >
                    1
                  </Button>
                  <Button onClick={() => this.handleInitKeypad(2)}>2</Button>
                  <Button onClick={() => this.handleInitKeypad(3)}>3</Button>
                </ButtonGroup>
              </Grid>
              <Grid item>
                <ButtonGroup
                  color="primary"
                  aria-label="Outlined primary button group"
                  className="numpad-button"
                >
                  <Button onClick={() => this.handleInitKeypad(4)}>4</Button>
                  <Button onClick={() => this.handleInitKeypad(5)}>5</Button>
                  <Button onClick={() => this.handleInitKeypad(6)}>6</Button>
                </ButtonGroup>
              </Grid>
              <Grid item>
                <ButtonGroup
                  color="primary"
                  aria-label="Outlined primary button group"
                  className="numpad-button"
                >
                  <Button onClick={() => this.handleInitKeypad(7)}>7</Button>
                  <Button onClick={() => this.handleInitKeypad(8)}>8</Button>
                  <Button onClick={() => this.handleInitKeypad(9)}>9</Button>
                </ButtonGroup>
              </Grid>
              <Grid item>
                <ButtonGroup
                  color="primary"
                  aria-label="Outlined primary button group"
                  className="numpad-button"
                >
                  <Button onClick={() => this.handleReset()}>
                    <ClearIcon />
                  </Button>
                  <Button onClick={() => this.handleInitKeypad(0)}>0</Button>
                  <Button onClick={() => this.handleBackspace()}>
                    <BackspaceIcon />
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>
        </div>
        {/* LOGOUT BUTTON */}
        {/* ------------------------------------------------ */}
        <div className={classes.powerContainer} onClick={() => this.setState({logoutModal: true})}>
          <PowerIcon className={classes.powerIcon} />
        </div>

        {/* MODAL TO LOGOUT USER AND ERASE REGISTER SETTINGS */}
        {/* ------------------------------------------------ */}
        {logoutModal && 
          <ConfirmLogoutModal 
            handleCancel={() => this.setState({logoutModal: false})}
            handleConfirm={this.handleLogout}
          />
        }
      </div>
    );
  }
}

const errorSelector = createErrorMessageSelector([
  "UNLOCK"
]);

const mapStateToProps = state => ({
  errorMessage: errorSelector(state),
  auth: state.auth,
  register: state.register || {},
  unlockSuccess: state.auth.unlockSuccess,
  propertyID: state.register.propertyID,
  property: state.property
});

export default withStyles(styles)(connect(mapStateToProps)(withRouter(LoginComponent)));
