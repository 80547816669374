import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Footer from '../../common/Footer';
import moment from 'moment-timezone';

import DateChangeInfo from '../DateChangeInfo';
import { getDurationCount, formatDateRange } from '../../helper';
import { fetchDateFormat, DATE_FORMATS } from '../../../../utils/utility';
import { fetchNoOfNights } from '../../../../utils/utility';
import IMAGES from '../../../../constants/images';

const useStyles = makeStyles(theme =>({
    root: {
        
    },
    datePicker: {
        width: '100%',
        '& > div, & > div > div, & > div > div > div': {
          width: '100%',
        },
        '& .DateInput_1': {
          width: 'calc(45% - 30px)',
        },
        '& .DateRangePickerInput_arrow': {
            marginLeft: 30,
            marginRight: 30,
        },
        '& .DateInput_input': {
          borderBottom: '0px solid rgba(0, 0, 0, 0.87)',
          width: '96%',
          paddingRight: 0,
          padding: '10px 5px 10px',
          paddingLeft: 0,
          fontSize: '1.5rem',

        },
        '& .DateInput__disabled': {
            color: '#666666',
            fontStyle: 'normal',
            background: '#FFFFFF',
        },
        '& .DateRangePickerInput__disabled': {
            color: '#666666',
            fontStyle: 'normal',
            background: '#FFFFFF',
        },
        '& .DateInput_input__disabled': {
            color: '#666666',
            fontStyle: 'normal',
            background: '#FFFFFF',
        },
        '& .DayPickerNavigation_button__verticalDefault': {
          width: 'calc(50% - 12px)',
          height: 'unset',
        },
        '& .DayPickerNavigation_svg__vertical': {
        },
        '& .DateRangePicker_picker.DateRangePicker_picker_1.DateRangePicker_picker__directionLeft.DateRangePicker_picker__directionLeft_2': {
            top: '45px!important',
        },
        // '& .CalendarDay__selected': {
        //     background: '#000',
        //     border: '1px double #F2F2F2',
        //     color: '#FFF'
        // },
        // '& .CalendarDay__selected_span': {
        //     background: '#000',
        //     border: '1px double #F2F2F2',
        //     color: '#FFF'
        // },
        // '& .CalendarDay__selected_span:hover': {
        //     background: '#DADADA',
        //     border: '1px double #DADADA',
        // },
        // '& .CalendarDay__default:hover': {
        //     background: '#DADADA',
        //     border: '1px double #DADADA',
        // },
        // '& .CalendarDay__selected:hover': {
        //     background: '#13408d',
        //     border: '1px double #13408d',
        // },
    },
    labelContainer: {
        color: '#666666',
        display: 'flex',
        '& > div': {
            fontSize: '1.1rem',
            fontWeight: 600,
        }
    },
    leftLabel: {
        width: 'calc(45% + 50px)',
    },
    arrow: {
        width: 20,
        height: 20,
    },
}));

const DATE_FORMAT_OPTIONS = {
    dateFormat: 'DD. MMM YYYY', 
    seperator: '-'
}

const DurationSelect = props =>{
    const classes = useStyles();
    const { selectedAccomodation, setSelectedDateRange, nextStep, selectedDateRange } = props;
    const [focused, setFocused] = useState(null);
    const [dates, setDates] = useState({ original: '', new: '' });

    useEffect(() =>{
        const dateParams = {
            startDate: selectedAccomodation.checkIn,
            endDate: selectedAccomodation.checkOut,
        }
        setDates({ original: { ...dateParams }, new: { ...dateParams } });
    }, []);

    const onDateChangeHandler = ({ startDate, endDate }) =>{
        const newDates = {
            startDate: moment(startDate).format(DATE_FORMATS.DEFAULT_DATE),
            endDate: moment(endDate).format(DATE_FORMATS.DEFAULT_DATE)
        }
        setDates({ ...dates, new: { ...newDates }});
        setSelectedDateRange(newDates);
    }

    return (
        <>
            <div className={classes.root}>

                <DateChangeInfo 
                    originalDates={dates.original && formatDateRange(dates.original, DATE_FORMAT_OPTIONS) || ''}
                    newDates={dates.new && formatDateRange(dates.new, DATE_FORMAT_OPTIONS) || ''}
                />
                
                <div className={classes.labelContainer}>
                    <div className={classes.leftLabel}>Check in date</div>
                    <div className={classes.rightLabel}>Check out date</div>
                </div>
                
                <div className={classes.datePicker}>
                    <DateRangePicker 
                        startDate={moment(dates.new?.startDate)}
                        startDateId={`startDate_${selectedAccomodation.guestNumber}`}
                        endDateId={`endDate_${selectedAccomodation.guestNumber}`}
                        endDate={moment(dates.new?.endDate)}
                        onDatesChange={onDateChangeHandler}
                        focusedInput={focused}
                        onFocusChange={focusedInput => setFocused(focusedInput)}
                        noBorder 
                        numberOfMonths={1}
                        displayFormat={'DD MMM YYYY'}
                        hideKeyboardShortcutsPanel
                        customArrowIcon={<img className={classes.arrow} src={IMAGES.ICONS.dateArrow} />}
                        isOutsideRange={day =>{ 
                            const checkinDate = moment(selectedAccomodation.checkIn).format(DATE_FORMATS.DEFAULT_DATE);
                            const checkoutDate = moment(selectedAccomodation.checkOut).add(1, 'day').format(DATE_FORMATS.DEFAULT_DATE);

                            return day.isBefore(fetchDateFormat(checkinDate, DATE_FORMATS.DEFAULT_DATE)) ||
                                day.isAfter(fetchDateFormat(checkoutDate, DATE_FORMATS.DEFAULT_DATE))
                        }}
                    />
                </div>

            </div>

            <Footer 
                label="Next" 
                isBtnDisabled={!selectedDateRange ? true : false}
                onBtnClick={nextStep}
            />
        </>
    )
}

export default connect(null)(DurationSelect);