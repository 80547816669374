const PAGES = {
  LOGIN: "login",
  SETUP: "setup",
  LOCK: "lock",
  LANDING: "",
  RESERVATION: "reservation",
  RESERVATION_EDIT: "reservation/edit",
  BOOKING_ENGINE: "booking",
  POS: "pos",
  CHECKIN: 'checkin',
  KIOSK: 'kiosk',
  HOUSEKEEPING: 'housekeeping',
  GOOGLE_HOTEL: 'google-hotel'
}

const RESERVATION_STATUS = {
  BLOCK: "Block",
  CONFIRMED: 'Confirmed',
  PENDING_CONF: 'Pending Confirmation',
  CHECKED_IN: 'Checked In',
  CHECKED_OUT: 'Checked Out',
  NO_SHOW: 'No Show',
  CANCELLED: 'Cancelled',
  WITHIN_FREE_CANCL: 'Within Free Cancellation Window',
  NO_FREE_CANCEL: 'No Free Cancellation',
  IN_HOUSE: 'In House',
  ONLINE_CHECKED_IN: 'Online Checked In'
}

const RESERVATION_CANCELLED_STATUSES = [
  RESERVATION_STATUS.NO_SHOW,
  RESERVATION_STATUS.CANCELLED
]

const RESERVATION_TYPE = {
  PRIORITY: "Priority",
  ARRIVAL: "Arrivals",
  DEPARTURE: "Departures",
  INHOUSE: "In-house Guests"
}

const BOOKING_SOURCE = {
  PHONE: "Phone",
  EMAIL: "Email",
  WALKIN: "Walk-In",
  OTHER: "Other"
}

const TENSE = {
  PAST: "PAST",
  PRESENT: "PRESENT",
  FUTURE: "FUTURE",
}

const EDIT_TYPE = {
  EXTEND: "extend",
  SHORTEN: "shorten",
  ROOM_CHANGE: "roomChange",
  ARRIVAL_CHANGE: "arrivalChange",
}

const TRANSACTION_MODE = {
  CASH: 'cash',
  CARD: 'card',
  HOSTELWORLD: 'HostelWorld',
  NOT_DEF: '',
  CHANNEL: 'Channel',
  CARD_ON_FILE: 'card on file',
  DISCOUNT: 'discount',
  PAYPAL: 'paypal',
  BANK_TRANSFER: 'bank transfer',
  SECURITY_DEPOSIT: 'Security Deposit',
  STRIPE: "Stripe",
  SURCHARGE: "surcharge"
}

const TRANSACTION_TYPE = {
  CASH_IN: "CASH_IN",
  CASH_OUT: "CASH_OUT",
  TRANSACTION: "TRANSACTION"
}

const UNALLOCATED = 'Unallocated';

const CHANNEL = {
  Hostelworld: {
    code: 'Hostelworld',
    name: 'Hostelworld',
  },
  myAllocator: {
    code: 'myAllocator',
    name: 'myAllocator',
  },
  BookingCom: {
    code: 'BookingCom',
    name: 'Booking.com'
  },
  Agoda: {
    code: 'Agoda',
    name: 'Agoda'
  },
  AirBNB: {
    code: 'AirBNB',
    name: 'Airbnb'
  },
  Expedia: {
    code: 'Expedia',
    name: 'Expedia'
  }
}

const ONBOARDING_STATUSES = {
  free: 'free',
  plus: 'plus',
  pro: 'pro'
}

const BED_STATUSES = {
  occupied: "Occupied", // Occupied
  ready: "Ready",   // Clean
  unclean: "Unclean" // Dirty
}

const GENDER_OPTIONS = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "N/A", label: "N/A" }
];

export {
  PAGES,
  RESERVATION_STATUS,
  RESERVATION_TYPE,
  BOOKING_SOURCE,
  EDIT_TYPE,
  TRANSACTION_MODE,
  TRANSACTION_TYPE,
  TENSE,
  UNALLOCATED,
  RESERVATION_CANCELLED_STATUSES,
  CHANNEL,
  ONBOARDING_STATUSES,
  BED_STATUSES,
  GENDER_OPTIONS
}