import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import ButtonBase from '@material-ui/core/ButtonBase';

import Summary from '../summary';
import useReservationStatus from '../hooks/useReservationStatus';
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme =>({
    root: {
        backgroundColor: "#fff",
        borderRadius: '10px',
        overflow: "hidden",
    },
    buttonContainer: {
        display: 'flex',
        '&.expandedView': {
            // borderRadius: "0px 0px 10px 10px",
            // borderTop: "0px",
        }
    },
    infoContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '20px',
        flex: 1
    },
    checkIcon: {
        width: '20px',
        height: '20px'
    },
    details:{
        marginLeft: '10px',
        '& .primaryText':{
            fontSize: '1.3rem',
            fontWeight: 700,
            color: '#519884'
        },
        '& .secondaryText':{
            fontSize: '1.2rem',
            color:' #519884',
        }
    },
    viewBtn: {
        display: "flex",
        alignItems: "center",
        padding: "0px 20px",
        borderLeft: "1px solid #9dc0b9",
        fontSize: "1.1rem",
        fontWeight: 600,
        color: "#519884",
        cursor: "pointer",
    },

    warningContainer: {
        cursor: "pointer",
        display: "flex",
        padding: "20px",
        borderRadius: "10px",
        backgroundColor: "#fff",
        alignItems: "center",
    },
    warningText: {
        fontSize: '1.3rem',
        fontWeight: 700,
        color: '#cd706e',
    },
    icon: {
        marginRight: "12px",
        color: '#cd706e',
    },
    closeBtn: {
        width: "100%",
        backgroundColor: "#f2f2f2",
        padding: "25px",
        fontSize: "1.1rem",
        fontWeight: 600,
        color: "#525252",
    }
}));

const PaymentStatus = props =>{
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = React.useState(false);
    const resvStatus = useReservationStatus();

    const toggleExpand = () =>{
        setIsExpanded(prevState => !prevState);
    }

    return (
        <div className={classes.root}>

            {resvStatus.isPaymentCompleted && (
                <div className={clsx(classes.buttonContainer, { expandedView: isExpanded })}>

                    <div className={classes.infoContainer}>
                        <img src={IMAGES.ICONS.check} className={classes.checkIcon}/>
                        <div className={classes.details}>
                            <div className="primaryText">Payment complete</div>
                        </div>
                    </div>

                    {!isExpanded && (
                        <div 
                            className={classes.viewBtn} 
                            onClick={() =>setIsExpanded(true)}
                        >
                            View
                        </div>
                    )}
                </div>
            )}
            
            {!resvStatus.isPaymentCompleted && (
                <div className={classes.warningContainer} onClick={toggleExpand}>
                    <WarningRoundedIcon className={classes.icon} />
                    <div className={classes.warningText}>Outstanding Balance</div>
                </div>
            )}

            {isExpanded && (
                <>
                    <Summary />

                    <ButtonBase 
                        className={classes.closeBtn}
                        onClick={() =>setIsExpanded(false)}
                    >
                        {resvStatus.isPaymentCompleted ? 'Close window' : 'Collect payment later' }
                    </ButtonBase>
                </>
            )}
        </div>
    )
}

export default PaymentStatus;