import React from "react";
import { connect } from 'react-redux';
import { Grid, Paper } from "@material-ui/core";
import { fetchProductCategorys } from "../../redux/actions/pos";
import ItemList from "./ItemList";

class ProductOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemType: 'category',
      selectedCategory: null
    }
  }

  componentDidMount() {
    this.props.dispatch(fetchProductCategorys())
  }

  componentDidUpdate(prevProps){
    if (this.state.itemType === 'category' &&
      this.props.categories &&
      !_.isEqual(this.props.categories, prevProps.categories)
    ) {
      this.setState({ items: this.props.categories });
    }

    if (this.state.itemType === 'product' && this.state.selectedCategory &&
      this.props.products &&
      !_.isEqual(this.props.products, prevProps.products)
    ) {
      let categories = { ...this.props.categories };
      let selectedCategory = this.state.selectedCategory;
      let categorIDs = (categories[this.state.selectedCategory] && categories[this.state.selectedCategory].products) || []
      this.setState({ items: this.props.products });
    }
  }

  render() {
    const { itemType } = this.state;
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={8}>
            <Paper style={{ marginTop: "8px" }}>
              <ItemList
              itemType={itemType}
              items={[]}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.pos.categories || {},
  products: state.pos.products || {}
})

export default (connect(mapStateToProps)(ProductOrderPage));
