import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Dialog from '../common/CustomDialog';
import Footer from './Footer';
import LineItems from './LineItems';
import { fetchCurrencyFormat } from '../../../utils/utility';
// import { updateReservationItems } from '../../../redux/actions/reservations';
import { getPropertyCurrency } from '../helper';
import { createOrder } from '../../../redux/actions/pos';
import IMAGES from '../../../constants/images';

const INIT_SUMMARY_INFO = {
    price: 0, 
    quantity: 0 
}

const getSelectedItemsSummary = (selectedItems) =>{
    let summary = { ...INIT_SUMMARY_INFO };

    for(const [, itemData] of Object.entries(selectedItems)){
        summary.quantity += itemData.quantity;
        summary.price += itemData.price;
    }
    return summary
}

const useStyles = makeStyles(theme =>({
    root: {
    
    },
    noDataConatiner: {
        border: "1px solid #e0e0e0",
        padding: "20px",
        borderRadius: "10px",
        fontSize: "1.4rem",
        color: "#999999",
        marginBottom: '20px',
        lineHeight: '18px',

        '& .title':{
            color: "#777777",
            fontSize: "1.2rem",
            fontWeight: 600,
            margin: "0px",
        }
    }
}));

const POSModal = props =>{
    const classes = useStyles();
    const { closeModalHandler, posCategories, posProducts, currency, dispatch, reservation, isLoading, propertyID } = props;
    const [selectedItems, setSelectedItems] = useState({});
    const [summaryInfo, setSummaryInfo] = useState({ ...INIT_SUMMARY_INFO });
    let productCount = 0;

    const onCountChange = (itemID, count) => {
        let selected = { ...selectedItems };
        if (!selected[itemID]) {
            selected[itemID] = {
                productID: itemID,
                productCategoryID: posProducts[itemID].productCategoryID,
            }
        }
        selected[itemID].quantity = count;

        const productPrice = posProducts[itemID].price || 0;
        selected[itemID].price = Math.round(((productPrice * count) + Number.EPSILON) * 100) / 100;
        
        if (count === 0 && selected[itemID]) delete selected[itemID];
        setSelectedItems({ ...selected });

        const summaryData = getSelectedItemsSummary(selected);
        setSummaryInfo({ ...summaryData }); 
    }

    const getSelectedList = (selectedItems) => {
        return Object.keys(selectedItems).map(id => selectedItems[id]);
    };

    const handleSubmit = () => {
        const productList = getSelectedList(selectedItems);
        const productSummary = getSelectedItemsSummary(selectedItems);
        
        const payload = {
            productList,
            productAmount: productSummary.price,
            reservationID: reservation._id,
            propertyID,
            status: "Closed"
        }
        dispatch(createOrder(payload))
            .then(success =>{
                if(success) closeModalHandler();
            })
    }

    return (
        <Dialog
            headerTitle="POS"
            closeModalHandler={closeModalHandler}
            isLoading={isLoading}
            // errors={errors}
            // handleCloseError={this.handleCloseError}
        >   

            {Object.entries(posCategories).map(([id, category]) =>(
                category.products.map(productID =>{
                    const product = posProducts[productID];
                    const itemData = selectedItems?.[productID] || null;
                    productCount++;
                    return (
                        <LineItems 
                            key={`posProduct_${productID}`}
                            itemID={productID}
                            name={product.name}
                            amount={product.price}
                            imageSrc={posProducts?.[productID]?.image || IMAGES.PLACEHOLDERS.pos}
                            currency={currency}
                            onCountChange={onCountChange}
                            quantity={itemData?.quantity || 0}
                        />
                    )
                })
            ))}

            {productCount == 0 && (
                <div className={classes.noDataConatiner}>
                    <p className="title">No items found.</p>
                </div>
            )}

            <Footer 
                label="Save"
                onBtnClick={handleSubmit}
                count={summaryInfo.quantity}
                amount={summaryInfo.price}
                currency={currency}
            />

        </Dialog>
    )
}

const mapStateToProps = state => {
    const { property, register, loading, reservationDetails, pos  } = state;

    const currencyCode = getPropertyCurrency(register, property);

    return {
        posProducts: pos.products || {},
        posCategories: pos.categories || {},
        currency: fetchCurrencyFormat(currencyCode) || '',
        reservation: reservationDetails.reservation || {},
        isLoading: loading.CREATE_ORDER,
        propertyID: register.propertyID
    }
}

export default connect(mapStateToProps)(POSModal);