import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ReadMore from '../readMore';

const secondaryColor = '#5C5C5C';

const useStyles = makeStyles(theme =>({
    mainContentCard: {
        borderRadius: '20px',
        padding: '20px',
        boxShadow: '0 2px 20px 0px #F0F0F0'
    },
    roomImageHolder: {
        height: '190px',
        width: '100%',

        [theme.breakpoints.up('md')]: {
            height: '137px',
        },
    },
    roomImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        borderRadius: '20px'
    },
    roomName: {
        marginBottom: '15px',
    }
}))

const RoomInfoCard = ({ roomData }) =>{
    const classes = useStyles();
    return(
        <Card className={classes.mainContentCard}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <div className={classes.roomImageHolder}>
                        <img 
                            className={classes.roomImage} 
                            src={
                                roomData.images.length > 0 ? 
                                    roomData.images[roomData.images.length - 1 ] : 
                                    "/images/dorm2.jpg"
                                }
                            alt="room" 
                        />
                    </div>
                </Grid>

                <Grid item xs={12} sm={8}>
                    <Typography component="div" >
                        <Box component="h5" className={`${classes.roomName}`} m={0} fontWeight="bold" fontSize="18px">
                            { roomData.name }
                        </Box>

                        {/* <Box component="h5"
                            className={classes.roomTypeDesc}
                            m={0}
                            fontWeight="bold"
                            color={secondaryColor}
                            fontSize="16px"
                            mb={2}
                        >
                            1 Bed in shared 6-bed-dorm
                        </Box>

                        <Box variant="p" component="div" className={classes.roomDesc} mb={0}>
                            Ensuit Bathroom
                        </Box> */}
                        { roomData.description && (
                            <ReadMore data={roomData.description}/>
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
}

export default RoomInfoCard;