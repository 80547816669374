import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputBase from '@material-ui/core/InputBase';

import Dialog from '../common/CustomDialog';
import { RadioButton } from '../common/Button';
import Checkbox from '../common/CustomCheckbox';
import Footer from '../common/Footer';
import { RESERVATION_STATUS, CHANNEL } from '../../../utils/constants';

const useStyles = makeStyles(theme =>({
    root: {
        '& .dialogBody': {
            minHeight: "360px",
            maxHeight: "360px",
        },
    },
    radioGroup: {
        display: "flex",
        flexDirection: "row",
        marginBottom: "25px",
        '& .radioBtn': {
            marginRight: '10px',
            '& .MuiFormControlLabel-label':{
                fontSize: "1.2rem",
            }
        }
    },
    notes: {
        border: "1px solid #e0e0e0",
        width: "100%",
        padding: "10px 20px",
        borderRadius: "10px",
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "column",
        marginTop: "20px",
        '& .MuiFormControlLabel-label': {
            color: '#305c8b'
        }
    }
}));

const CANCELLATION_REASONS = [
    { key: RESERVATION_STATUS.NO_SHOW, value: RESERVATION_STATUS.NO_SHOW },
    { key: "p" + RESERVATION_STATUS.CANCELLED, value: "Property Canceled" },
    { key: "g" + RESERVATION_STATUS.CANCELLED, value: "Guest Canceled" }
];

const INVALID_CC = {
    key: 'invalidCC',
    value: 'Invalid Credit Card'
}

const isSourceBookingCom = (channelStatusList, bookingSource) => channelStatusList[CHANNEL.BookingCom.code] && bookingSource === CHANNEL.BookingCom.name;

const CancelReservationModal = props =>{
    const classes = useStyles();
    const { closeModalHandler, isLoading, errors, handleCancelReservation, channelStatusList, reservation } = props;
    const [cancellationList, setCancellationList] = useState([ ...CANCELLATION_REASONS ]);
    const [cancellation, setCancellation] = useState({ status: null, reason: ''});
    const [isDisabled, setIsDisabled] = useState(true)
    const [bookingCom, setBookingCom] = useState({ noShow: false, waivedFees: false });

    useEffect(() =>{

        if (isSourceBookingCom(channelStatusList, reservation.bookingSource) && 
            reservation.invalidCCTransactions > 0
        ){
            const invalidCC = { 
                key: INVALID_CC.key + RESERVATION_STATUS.CANCELLED, 
                value: INVALID_CC.value
            }
            setCancellationList([ ...CANCELLATION_REASONS, invalidCC ]);
        }
        
    }, [channelStatusList]);

    const onStatusSelectHandler = event =>{
        const value = event.target.value;
        setCancellation({ ...cancellation, status: value });
        if(['pCancelled', 'gCancelled'].includes(value) && reservation.bookingSource === CHANNEL.Hostelworld.name){
            let isDisabled = cancellation.reason.trim() ? false : true;
            setIsDisabled(isDisabled)
        }
        else setIsDisabled(false)
    }

    const checkboxHandler = event =>{
        const value = event.target.checked;
        const name = event.target.name;
        setBookingCom({ ...bookingCom, [name]: value });
    }

    const submitHandler = () =>{
        const payload = {
            status: cancellation.status,
            cancellationReason: cancellation.reason,
            noShowBooking: cancellation.status === RESERVATION_STATUS.NO_SHOW && reservation.bookingSource === CHANNEL.BookingCom.name && bookingCom.noShow,
            invalidCC: cancellation.status.includes(INVALID_CC.key),
            waived_fees: bookingCom.waivedFees,
        }

        if(cancellation.status.includes(RESERVATION_STATUS.CANCELLED)){
            payload.status = RESERVATION_STATUS.CANCELLED;
        }
        handleCancelReservation(payload);
    }

    const onNotesChangeHandler = event => {
        if (['pCancelled', 'gCancelled'].includes(cancellation.status) && reservation.bookingSource === CHANNEL.Hostelworld.name) {
            let isDisabled = event.target.value.trim() ? false : true;
            setIsDisabled(isDisabled)
          }
        else setIsDisabled(false)
        setCancellation({ ...cancellation, reason: event.target.value })
    }

    return (
        <Dialog
            className={classes.root}
            headerTitle="Cancel Reservation"
            closeModalHandler={closeModalHandler}
            isLoading={isLoading}
            errors={errors}
            // handleCloseError={handleCloseError}
        >
            <RadioGroup
                name="cancellationStatus"
                className={classes.radioGroup}
                onChange={onStatusSelectHandler}
            >
                {cancellationList.map((item, index) =>(
                    <RadioButton
                        key={`${index.key}_${index}`}
                        label={item.value}
                        value={item.key}
                        className="radioBtn"
                    />
                ))}
            </RadioGroup>

            <InputBase
                placeholder="Notes"
                className={classes.notes}
                value={cancellation.reason}
                onChange={onNotesChangeHandler}
            />
            
            {isSourceBookingCom(channelStatusList, reservation.bookingSource) && 
                cancellation.status === RESERVATION_STATUS.NO_SHOW && (

                    <div className={classes.checkboxContainer}>
                        <Checkbox
                            name="noShow"
                            label="Mark as no-show on Booking.com"
                            value={bookingCom.noShow}
                            onChangeHandler={checkboxHandler}
                        />
                        <Checkbox
                            name="waivedFees"
                            label="Waive fees"
                            value={bookingCom.waivedFees}
                            onChangeHandler={checkboxHandler}
                        />
                    </div>
            )}

            <Footer 
                label="Cancel booking"
                isBtnDisabled={isDisabled}
                onBtnClick={submitHandler}
            />
        </Dialog>
    )
}

const mapStateToProps = state =>{
    const { reservationDetails, propertyConfig } = state;
    return {
        reservation: reservationDetails?.reservation || {},
        channelStatusList: propertyConfig?.channelList || {}
    };
}

export default connect(mapStateToProps)(CancelReservationModal);