import React from "react";
import { connect } from "react-redux";
import getVisibleReservations from "../../redux/selectors/reservations";
// import SearchTile from "./SearchTile";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import ReservationCard from "../landing/ReservationCard";


const useStyles = makeStyles( theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  noResult: {
    fontSize: "1.4rem",
    color: "#EA847F",
    marginTop: 20,
    marginBottom: 20,
  }
}));

const SearchResults = props => {

  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <Grid container spacing={3} className="reservations-list__in-house">
        {props.reservations.map((reservation, index) => (
          <Grid item xs={12} sm={6} md={4} xl={3} key={reservation._id}>
            <ReservationCard
              currency={props.property?.currency}
              timezone={props.property?.timezone}
              accommodation={props?.accommodations?.[reservation?.accommodationList?.[0]]}
              guest={props?.allGuests?.[reservation?.guests?.[0]]}
              reservation={reservation}
              handleGoReservation={props.handleGoReservation}
            />

          </Grid>
        ))}
      </Grid>
      {props.isSearched && !props.reservations.length && <span className={classes.noResult}>No results found</span>}
    </div>
  )
};

const mapStateToProps = state => {
  let reservations = [];
  let allGuests = {};
  let accommodations = {};

  if (!state.filters.onLoad) {
    if (state.filters.isSearched) {
      reservations = Object.values(state.filters.reservation || {})
      allGuests = state.filters.guests || {};
      accommodations = state.filters.accommodationList;
    } else if(state.filters.text){
      reservations = getVisibleReservations(state.today, state.filters);
      allGuests = state.today.guests || {};
      accommodations = state.today.accommodationList;
    }
  }

  return {
    isSearched: state.filters.isSearched,
    reservations: reservations,
    allGuests: allGuests || {},
    accommodations: accommodations
  };
};

export default connect(mapStateToProps)(SearchResults);
