import React from 'react';
import { makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

import CustomCheckBox from '../../../booking_engine/checkout/customCheckBox';

const useStyles = makeStyles(theme => ({
    root: {
        // padding: '0px 25px',
        // marginTop: '30px',
        [theme.breakpoints.down("xs")]:{
            // padding: '0px 10px',
            marginTop: '40px'
        }
    },
    cardErrorBlock:{
        //width: '100%',
        marginLeft: '44px',
    },

    errorMessage: {
        color: '#f44336',
        marginLeft: '14px',
        lineHeight: '20px',
        '&::first-letter':{
            textTransform: 'uppercase'
        }
    },
}));

const CheckboxSection = props =>{
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <CustomCheckBox
                name="marketingUpdatesChecked"
                label="I’d like to occasionally receive marketing updates"
                checked = {props.marketingUpdatesChecked}
                onChangeHandler={props.checkBoxHandler}
            />
            {/* <div className={classes.cardErrorBlock}>
                <FormHelperText className={classes.errorMessage}>{props.paymentError}</FormHelperText>
            </div> */}
        </div>

    );
}

export default CheckboxSection;