import { filter } from 'lodash'

// Get Visible Reservations
export default (
  reservations,
  { text, sortBy, roomType, guestStatus, arrivalDate }
) => {
  reservations.data = reservations.reservation || {};
  reservations.guests = reservations.guests || {};
  return filter(reservations.data, reservation => {
    // const guestId = reservation.guests ? reservation.guests[0] : null;
    // const guest = reservations.guests[guestId];
    // const arrivalDateMoment = moment(reservation.arrivalDate);
    // const startDateMatch = arrivalDate
    //   ? arrivalDate.isSame(arrivalDateMoment, "day")
    //   : true;
    // const statusMatch =
    //   guestStatus === "" || reservation.guestStatus === guestStatus;
    // const roomMatch = roomType === "" || reservation.roomType === roomType;
    const textMatch = text === "" || reservation.guestName.toLowerCase().includes(text.toLowerCase());
      // guest.guestName.toLowerCase().includes(text.toLowerCase()) ||
      // guest.lastName.toLowerCase().includes(text.toLowerCase());

    return textMatch;
    // return startDateMatch && statusMatch && roomMatch && textMatch;
  })
    .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
};
