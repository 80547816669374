
// Set logged in owner
export const setupRegister = (
  propertyID, 
  propertyName, 
  propertyCode,
  cashDrawerID, 
  cashDrawerName
) => {
  return {
    type: "SETUP_REGISTER",
    payload: {
      propertyID,
      propertyName,
      propertyCode,
      cashDrawerID,
      cashDrawerName
    }
  };
};