import React, { forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import Item from './Item';
import { Grid } from '@material-ui/core';
import { ICONS } from '../../../redux/utils/imageUrls';

const useStyles = makeStyles(theme => ({
  icon: {
    width: 20,
    height: 20,
    marginRight: 20
  },
  section: {
    margin: '30px 0 20px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600
  },
  rooms: {
    
  }
}));

const SelectRoomTypes = forwardRef((props, ref) => {
  const classes = useStyles();
  const { property, roomTypes, multiple, rooms, itemName, prefilledData, checkAvailability, availability, availabilityMin } = props;
  const [selectedItem, setSelectedItem] = React.useState({});

  useImperativeHandle(ref, () => ({
    handleProcessData() {
      if (multiple) {
        return fetchMultipleData();
      } else {
        let itemKeys = Object.keys(selectedItem) || [];
        if (itemName === 'rooms') {
          return itemKeys[0] && selectedItem[itemKeys[0]] && rooms[itemKeys[0]]
        } else
          return itemKeys[0] && selectedItem[itemKeys[0]] && roomTypes[itemKeys[0]]
      }
    }
  }))

  React.useEffect(() => {
    if(prefilledData && Object.keys(prefilledData).length) {
      setSelectedItem({ ...prefilledData })
    }
  },[prefilledData])

  const fetchMultipleData = () => {
    let data = { typeID: {}, guestCount: 0, hasShared: false, hasPrivate: false };
    data.typeID = { ...selectedItem };
    Object.keys(selectedItem).map(key => {
      data.guestCount = data.guestCount + selectedItem[key];
      if (roomTypes[key] && roomTypes[key].dormOrPrivate === 'private' && selectedItem[key])
        data.hasPrivate = true;
      if (roomTypes[key] && roomTypes[key].dormOrPrivate === 'dorm' && selectedItem[key])
        data.hasShared = true;
    })
    return data;
  }

  const onCountChange = (count, itemID, itemName) => {
    let itemData = {};
    if (multiple) {
      itemData = { ...selectedItem };
    }
    if (itemID) {
      itemData[itemID] = count;
      setSelectedItem({ ...itemData })
    }
  }

  const fetchItemListView = (value, image) => {

    return Object.keys(roomTypes).map((id, typeIndex) => {
        let itemData = { ...roomTypes[id] };
        let roomTypeImage = itemData.images && itemData.images.length ? (itemData.images[itemData.images.length - 1].imagelink || image) : image;
        itemData.image = roomTypeImage;
        if (itemData.dormOrPrivate !== value) return null;
        if (itemName === 'rooms') {
          return itemData.rooms.filter(roomId => rooms[roomId].primaryRoomTypeID === id)
          .map((roomID, roomIndex) => {
            itemData = { ...rooms[roomID] };
            let roomImage = itemData.images && itemData.images.length ? itemData.images[itemData.images.length - 1].key : null;
            itemData.image = roomImage || roomTypeImage;
            return (
              <Grid item xs={6} sm={4} md={3} key={roomIndex}>
                <Item
                  itemID={itemData._id}
                  data={itemData}
                  onCountChange={onCountChange}
                  multiple={multiple}
                  selected={selectedItem[itemData._id]} />
              </Grid>
            )
          })
        } else {
          itemData.maximum = availability?.[id]?.maxBookableNights || 0;
          if(checkAvailability && itemData.maximum <= 0 ) return null;
          return (
            <Grid item xs={6} sm={4} md={3} key={typeIndex}>
              <Item
                itemID={itemData._id}
                data={itemData}
                onCountChange={onCountChange}
                multiple={multiple}
                prefilledCount={prefilledData[itemData._id]}
                selected={selectedItem[itemData._id]} />
            </Grid>
          )
        }
      })
  }

  return (
    <>
      <Grid container ref={ref} className={classes.rooms}>
        <Grid item xs={12}>
          <div className={classes.section}>
            <img className={classes.icon} src={ICONS.BED} />
            <span className={classes.title}>Private Rooms</span>
          </div>
        </Grid>
        {fetchItemListView('private', "/images/PrivateRoomType.png")}
      </Grid>
      <Grid container ref={ref} className={classes.rooms}>
        <Grid item xs={12}>
          <div className={classes.section}>
            <img className={classes.icon} src={ICONS.HOSTEL} />
            <span className={classes.title}>Shared Rooms</span>
          </div>
        </Grid>
        {fetchItemListView('dorm', "/images/DormRoomType.png")}
      </Grid>
    </>
  )
})

export default (SelectRoomTypes);