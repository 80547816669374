import React from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Accommodation from './../Accommodation';
import CalendarDates from './../CalendarDates';

import {
  getMomentAddDayISO,
  fetchCurrencyFormat,
  fetchNoOfDays,
  fetchDateFormat,
  DATE_FORMATS
} from '../../../utils/utility';

import { Grid } from "@material-ui/core";
import { ICONS } from '../../../redux/utils/imageUrls';

const styles = (theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  extend: {
    backgroundColor: "#fff",
    borderTopRightRadius: 2,
    borderTopLeftRadius: 2,
    borderColor: "transparent",
    display: "flex",
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: 20,
    overflow: 'hidden',
    paddingBottom: 20,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 5,
      paddingRight: 5,
      width: 'calc(100% - 10px)'
    }
  },
  options: {
    overflowY: 'hidden'
  },
  icon: {
    width: 20,
    height: 20,
    padding: 15,
    borderRadius: '50%',
    boxShadow: '0 0 5px 5px #f5f5f5',
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  },
  sectionGuest: {
    height: 66,
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 8,
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 56,
      marginTop: 10,
      marginBottom: 7,
    }
  },
  dayBlock: {
    width: "10%",
    paddingBottom: 20,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    backgroundColor: '#f8f9fb',
    margin: '0 1px',
  },
  button: {
    alignItems: "center",
    border: "none",
    borderRadius: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    whiteSpace: "nowrap"
  },
  styleCurve: {
    borderBottom: "0px solid #dddddd",
    display: 'flex',
    flexDirection: 'row',
    textAlign: "center",
    width: "100%",
  },
  error: {
    width: '100%',
    textAlign: 'center',
    color: 'red'
  }
});

const AddNights = (props) => {
  const { classes, showPerNight, showGuest, guestCount, showRate, nights, suggestions } = props;
  const [day, setDay] = React.useState({})
  const currency = fetchCurrencyFormat(props.property ? props.property.currency : null);
  const startDate = props.date || null;
  const endDate = fetchDateFormat(getMomentAddDayISO(props.date, showPerNight ? nights - 1 : nights), DATE_FORMATS.DEFAULT_DATE) || null;
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)

  React.useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const handleCalendarDays = (day) => {
    setDay(day)
  };

  const handleCalendarDataList = (suggestions) => {
    let totalamount = 0, accommodatedGuestCount = 0;
    let dataInfo = {}, dataKeys = [], beds = { ...props.beds };
    let calendarOptions = [...suggestions.calendarOptions];

    if (calendarOptions.length) {
      //Grouping bed data per guest
      let perGuestData = calendarOptions.reduce(function (r, a) {
        r[a.guest] = r[a.guest] || [];
        r[a.guest].push(a);
        return r;
      }, Object.create(null));

      Object.keys(perGuestData).map(guestKey => {
        let sortedGuestData = perGuestData[guestKey]
          .filter(data => data.reservationID === "NEW_RES")
          .sort((a, b) => (a.date > b.date ? 1 : -1));

        let bedID = null;
        let key = null;

        if (guestKey && guestKey !== 'undefined') {
          for (let counter = 0; counter < sortedGuestData.length; counter++) {
            const element = sortedGuestData[counter];
            if (counter === 0 || bedID !== element.bedID) {
              if (bedID && bedID !== element.bedID) {
                dataInfo[key].siblingAccommodationID = (guestKey + '_' + counter).trim();
                accommodatedGuestCount--;
              }
              bedID = element.bedID;
              key = (guestKey + '_' + counter).trim();
              dataKeys.push(key)
              accommodatedGuestCount++;
              dataInfo[key] = {
                _id: key,
                bedID: element.bedID,
                roomID: element.roomID,
                roomTypeID: beds[element.bedID] && beds[element.bedID].roomTypeID,
                checkIn: element.date,
                checkOut: fetchDateFormat(getMomentAddDayISO(element.date, 1), DATE_FORMATS.DEFAULT_DATE),
                amount: element.rate
              }
              totalamount = totalamount + element.rate;
            } else if (bedID === element.bedID) {
              dataInfo[key].checkOut = fetchDateFormat(getMomentAddDayISO(dataInfo[key].checkOut, 1), DATE_FORMATS.DEFAULT_DATE);
              dataInfo[key].amount = (dataInfo[key].checkOut || 0) + element.rate;
              totalamount = totalamount + element.rate;
            }
          }
        }
      })
    }
    props.calculateTotal(totalamount)
    return { dataKeys: dataKeys.sort(), dataInfo: dataInfo, accommodatedGuestCount: accommodatedGuestCount };
  }

  const handleManageReservation = (block) => {
    props.handleSelectBed(block);
  }

  const displayGuests = (guestCount, classes) => {
    let view = [];
    for (let index = 0; index < guestCount; index++) {
      view.push(<Grid item xs={12} key={index}><div className={classes.sectionGuest}><img className={classes.icon} src={ICONS.SMILE} /></div></Grid>)
    }
    return view;
  }

  const accommodationInfo = handleCalendarDataList(suggestions);
  const dataKeys = accommodationInfo.dataKeys;

  let accommodatedGuestCount = accommodationInfo.accommodatedGuestCount;


  return (
    <div className={classes.section}>
      <div className={classes.extend}>
        {dataKeys.length ? (<>
          {accommodatedGuestCount < guestCount && showGuest &&
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.error}>
                  <span>{guestCount - accommodatedGuestCount} guests not accommodated. </span>
                </div>
              </Grid>
            </Grid>
          }
          <Grid container>
            {showGuest && <Grid item xs={2}></Grid>}
            <Grid item xs={showGuest ? 10 : 12}>
              {startDate && endDate &&
                <CalendarDates
                  dateRangeAPIEnable={false}
                  showPerNight={showPerNight}
                  dateSelectEnabled={true}
                  calendarDaysEnabled={true}
                  calendarChangeEnabled={
                    (windowWidth <= 901 && nights > 3) ||
                    nights > 4
                  }
                  checkIn={startDate}
                  checkOut={endDate}
                  handleCalendarDays={handleCalendarDays}
                  numberOfDays={windowWidth > 901 ? '5' : '3'}
                  blockStyle={props.blockStyle}
                />}
            </Grid>
          </Grid>
          <Grid container>
            {showGuest &&
              <Grid item xs={2}>
                <Grid container>
                  {displayGuests(accommodatedGuestCount, classes)}
                </Grid>
              </Grid>
            }
            <Grid item xs={showGuest ? 10 : 12}>
              {dataKeys && dataKeys.length > 0 && day && day.days &&
                <div className={classes.options}>
                  <Accommodation
                    dataKeys={dataKeys}
                    dataInfo={accommodationInfo.dataInfo}
                    day={day}
                    editEnabled={false}
                    isForDisplay={false}
                    showPerNight={showPerNight}
                    suggestEnabled={true}
                    showRate={false}
                    currency={currency}
                    numberOfDays={windowWidth > 901 ? '5' : '3'}
                    handleManageReservation={handleManageReservation}
                  />
                </div>}

              {/* this section is only for style of the table  */}
              {showPerNight && (<Grid item xs={12}>
                <div className={classes.styleCurve}>
                  {[...Array(window.innerWidth > 901 ? 5 : 3)].map((x, i) => (
                    <React.Fragment key={i}>
                      <div className={`${classes.dayBlock} ${classes.night}`} style={{ width: `${100 / (window.innerWidth > 901 ? 5 : 3)}%` }}>
                        <div className={classes.button}></div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </Grid>)}
            </Grid>
          </Grid>
        </>) : null}
      </div>
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
    beds: state.rooms.beds || {},
    rooms: state.rooms.rooms || {},
    roomTypes: state.rooms.roomTypes || {},
  };
};
export default withStyles(styles)(connect(mapStateToProps)(AddNights));