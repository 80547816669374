import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import BookingStatus from './timeline-items/BookingStatus';
import PaymentStatus from './timeline-items/PaymentStatus';
import CheckinStatus from './timeline-items/CheckinStatus';
import CheckoutStatus from './timeline-items/CheckoutStatus';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    timelineItem: {
        display: 'flex',
        overflowY: 'hidden'
    },
    seperator: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 5px'
    },
    dot: {
        backgroundColor: '#ffffffd4',
        borderRadius: '50%',
        zIndex: 1,
        height: 10,
        width: 10
    },
    connector: {
        position: 'absolute',
        height: '100%',
        width: '2px',
        backgroundColor: '#ffffff85',
    },
    firstElement: {
        top: '50%'
    },
    bottomElement: {
        bottom: '50%'
    },
    timelineContainer: {
        flex: 1,
        marginBottom: '15px'
    }
}));

const TimelineData = [
    { id: 'bookingStatus', component: <BookingStatus />},
    { id: 'paymentStatus', component: <PaymentStatus />},
    { id: 'checkinStatus', component: <CheckinStatus />},
    { id: 'checkoutStatus', component: <CheckoutStatus />},
];

export default function CustomizedTimeline() {
    const classes = useStyles();
    const totalItems = TimelineData.length;
    const lastElementIndex = totalItems - 1;
    return (
        <div className={classes.root}>

            {TimelineData.map((data, index) => (

                <div className={classes.timelineItem} key={`timeline_${index}`}>

                    <div className={classes.seperator}>
                        <div className={clsx(classes.connector, {
                            [classes.firstElement]: index == 0,
                            [classes.bottomElement]: index == lastElementIndex,
                        })} />
                        
                        <div className={classes.dot} />
                    </div>

                    <div className={classes.timelineContainer}>
                       {data.component}
                    </div>

                </div>
            ))}

        </div>
    );
}