import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { getCurrency } from '../helper';

const useStyles = makeStyles(theme=>({
    mainContentCard: {
        backgroundColor: '#fff',
        padding: '15px',
        boxShadow: '0 2px 20px #F0F0F0',
        cursor: "pointer"
    },
    totalAmount: {
        padding: '20px',
        borderRadius: '10px'
    },
    rowItems: {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        marginBottom: "10px"
    },
    rowItemsHeader:{
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "center",
        borderRadius: "10px 10px 10px 10px"
    },
    summaryCard:{
        borderRadius: "0px 0px 10px 10px"
    },
    icon: {
        marginLeft: "10px"
    },
    bedQuantityIcon: {
        backgroundColor: '#fff',
        marginLeft: '10px',
        boxShadow: '0 2px 20px #F0F0F0',
        height: '35px',
        width: '35px'
    }
}));

const SummaryCard = ({bookingCostInfo, taxItems, currency, t}) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () =>{
        setIsExpanded(!isExpanded);
    }
    return (
        <>  
            <Typography onClick={toggleExpand} component="div" className={`${classes.mainContentCard} ${classes.rowItemsHeader}`}>
                <Box fontWeight="bold" >{t('bookingEngine.summary.total')}</Box>
                <Box fontWeight="bold" >
                    <span className={`${classes.bottomMargin}`}>{getCurrency(currency, bookingCostInfo.grandTotal)}</span>
                    <Fab 
                        aria-label="add" 
                        className={classes.bedQuantityIcon}
                    >
                        {isExpanded ?  <ExpandLess /> : <ExpandMore />}
                    </Fab>
                </Box>
            </Typography>    
            <Collapse in={isExpanded} timeout="auto" unmountOnExit component="div" className={`${classes.mainContentCard} ${classes.summaryCard}`} >
                <Box className = {classes.rowItems} >
                    <Box>{t('bookingEngine.summary.subTotal')}:</Box>
                    <Box>{getCurrency(currency, bookingCostInfo.subTotal)}</Box>
                </Box>
                { bookingCostInfo.taxList.map(taxData =>(
                    <Box className = {classes.rowItems} key={Math.random()}>
                        <Box>{taxItems[taxData.taxID] &&taxItems[taxData.taxID].name}:</Box>
                        <Box>{getCurrency(currency, taxData.amount)}</Box>
                    </Box>
                ))}
                <Box className = {classes.rowItems} >
                    <Box>{t('bookingEngine.summary.tax')}:</Box>
                    <Box>{getCurrency(currency, bookingCostInfo.totalTax)}</Box>
                </Box>
                <Box className = {classes.rowItems} >
                    <Box>{t('bookingEngine.summary.chargable')}:</Box>
                    <Box>{getCurrency(currency, bookingCostInfo.chargableNow)}</Box>
                </Box>
                <Box className = {classes.rowItems} >
                    <Box>{t('bookingEngine.summary.grandTotal')}:</Box>
                    <Box>{getCurrency(currency, bookingCostInfo.grandTotal)}</Box>
                </Box>
            </Collapse>
        </>
    );
}

export default withTranslation()(SummaryCard);