export default function (state = {}, action) {
  switch (action.type) {
    case "SETUP_REGISTER":
      return { 
        ...state, 
        propertyID: action.payload.propertyID, 
        propertyName: action.payload.propertyName,
        propertyCode: action.payload.propertyCode,
        cashDrawerID: action.payload.cashDrawerID,
        cashDrawerName: action.payload.cashDrawerName 
      };
    
    //SETTING PROPERTY CODE RECIEVED ON UNLOCK SCREEN..................
    case "UNLOCK_SCREEN_PROPERTY_CODE":
      return {
        ...state,
        propertyCode: action.payload
      }

    
    // case "GET_WS_ONBORADING_STATUS_SUCCESS":
    //   return{
    //     ...state,
    //     onboardingStatus: action.payload?.onboardingStatus 
    //   }

    case "LOGOUT":
      return {};
    default:
      return state;
  }
};
