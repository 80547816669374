import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import HorizontalScollView from './common/HorizontalScollView';

import ExtendStay from './guest-accomodation/extend-stay';
import ChangeArrivalDate from './guest-accomodation/change-arrival-date';
import ShortenStay from './guest-accomodation/shorten-stay';
import ChangeBed from './guest-accomodation/change-bed';

import IMAGES from '../../constants/images';
import useReservationStatus from './hooks/useReservationStatus';
import useOnboardingStatus from '../../hooks/useOnboardingStatus';
import UpgradeMsgModal from './UpgradeMsgModal';

const useStyles = makeStyles(theme =>({
    root: {
        marginBottom: 10
    },
    plusIcon: {
        width: '20px',
        height: '20px'
    },
    btnList: {
        display: 'flex',
        '& $iconButton:nth-child(2n)': {
            margin: '0px 10px'
        },
        '& $iconButton:last-child': {
            marginLeft: '10px'
        },

        [theme.breakpoints.up('sm')]: {
            width: '100%'
        }
    },
    iconButton: {
        border: "1px solid #e0e0e0",
        cursor: 'pointer',
        width: "120px",
        height: "180px",
        padding: "10px 20px",
        paddingTop: 25,
        borderRadius: "10px",
        fontSize: "1.2rem",
        fontWeight: 600,
        transition: 'all .3s ease-in-out',
        '&.disableClick':{
            pointerEvents: "none",
        },
        '&:hover': {
            transform: 'scale(1.04, 1.04)',
            transition: '0.15s ease-in-out'
        }
    }
}));

const OPTIONS = {
    extendStay: 'extendStay',
    changeArrival: 'changeArrival',
    changeBed: 'changeBed',
    shortenStay: 'shortenStay',
    addRoomBed: 'addRoomBed'
};

const OPTIONS_LIST = [
    { id: OPTIONS.extendStay, label: 'Extend stay'},
    { id: OPTIONS.changeArrival, label: 'Change arrival date'},
    { id: OPTIONS.changeBed, label: 'Change bed'},
    { id: OPTIONS.shortenStay, label: 'Shorten stay'},
    // { id: OPTIONS.addRoomBed, label: 'Add room or bed'}
];

const RoomUpdatesOptions = props =>{
    const classes = useStyles();
    const [selectedOption, setSelectedOption] = useState(null);
    const [showUpgradeMsg, setShowUpgradeMsg] = useState(false);
    const resvStatus = useReservationStatus();
    const onboardingStatus = useOnboardingStatus();

    const onClickHandler = optionID =>{
        
        if(!onboardingStatus.isPaid){
            setShowUpgradeMsg(true);
            return;
        }

        if(resvStatus.isCancelled) return;
        setSelectedOption(optionID);
    }

    const resetHandler = () =>{
        setSelectedOption(null);
    }

    const closeMsgModal = () =>{
        setShowUpgradeMsg(false);
    }

    const componentProps = { closeModalHandler: resetHandler }

    return (
        <div className={classes.root}>
            <HorizontalScollView>
                <div className={classes.btnList}>
                    {OPTIONS_LIST.map(option =>(
                        <div 
                            key={`addons_${option.id}`}
                            className={clsx(classes.iconButton, { disableClick: resvStatus.isCancelled })}
                            onClick={() => onClickHandler(option.id)}
                            style={{ backgroundImage: `url(${IMAGES.ILLUSTRATIONS[option.id]})`, backgroundSize: 'cover' }}
                        >
                            <div>
                                {option.label}
                            </div>
                        </div>
                    ))}
                </div>
            </HorizontalScollView>

            {({

                [OPTIONS.extendStay]: <ExtendStay {...componentProps} />,

                [OPTIONS.changeArrival]: <ChangeArrivalDate {...componentProps} />,

                [OPTIONS.changeBed]: <ChangeBed {...componentProps} />,

                [OPTIONS.shortenStay]: <ShortenStay {...componentProps} />

            })[selectedOption]}

            {showUpgradeMsg && <UpgradeMsgModal closeModalHandler={closeMsgModal} />}
        </div>
    )
}

export default RoomUpdatesOptions;