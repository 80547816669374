import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import clsx from 'clsx';
import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import { 
    loadReservationInvoice, 
    deleteReservationTax, 
    createReservationTransaction,
    getReservationDetailsViaCode
} from "../../../redux/actions/reservations";
import { deleteOrder } from "../../../redux/actions/pos";

import {
    getAmtFormat,
    fetchCurrencyFormat,
    fetchCombineDateFormat,
    fetchNoOfDays
} from "../../../utils/utility";

import TaxSummary from './TaxSummary';
import PaymentSummary from './PaymentSummary';
import DiscountModal from './DiscountModal';
import CollectPaymentModal from './CollectPaymentModal';
import UpsellSummary from './UpsellSummary';
import POSSummary from './POSSummary';
import RefundModal from './RefundModal';
import TaxModal from "./TaxModal";
import IMAGES from "../../../constants/images";

const styles = theme => ({
    root: {
        // borderRadius: "10px 10px 0px 0px",
        // border: '1px solid #9dc0b9',
        padding: "15px",
    },
    roomInfoContainer: {
        color: "#5f5f5f",
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #e3e3e3",

        '& .nights': {
            fontSize: "1.1rem",
            fontWeight: 600,
            marginBottom: "5px",
        },
        '& .resv-date': {
            fontSize: "1.2rem",
            color: "#bdbdbd",
        }
    },
    rowItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    roomName: {
        fontWeight: 600,
        fontSize: "1.4rem",
        marginBottom: "5px",
        color: "#5f5f5f",
    },
    balance: {
        fontSize: "1.6rem",
        fontWeight: 600,
        color: '#d06e6b',
    },
    buttonContainer: {
        marginTop: "35px",
        marginBottom: "30px",
    },
    button: {
        color: "#ffffff",
        fontWeight: 400,
        width: "100%",
        padding: "15px",
        background: "#d06e6b",
        borderRadius: "30px",
        '&:hover': {
            background: "#d06e6b",
        }
    },

    aux: {
        display: "flex",
        padding: "0px 40px",
        marginBottom: "10px",
    },
    auxText: {
        color: '#cccccc',
        fontSize: '1.1rem',
    },
    auxIcon: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        width: '33%',
        '& img': {
            height: 25,
            marginBottom: 10,
            opacity: 0.3,
            width: 25,
        },
        '&:hover': {
            '& img': {
                opacity: 1
            },
            '& p': {
                color: '#333333'
            }
        },
    },
});

const ACTION_BUTTONS = [
    { id: 'discount', label: 'Discount', imgSrc: IMAGES.ICONS.discount },
    { id: 'invoice', label: 'Invoice', imgSrc: IMAGES.ICONS.printer },
    { id: 'refund', label: 'Refund', imgSrc: IMAGES.ICONS.refunds },
    { id: 'tax', label: 'Tax', imgSrc: IMAGES.ICONS.taxes }
];

class SummaryList extends React.Component {

    state = {
        showDiscountModal: false,
        showPaymentModal: false,
        showRefundModal: false,
        showTaxModal: false
    }

    loadReservation = () =>{
        const { history, match: { params }, dispatch } = this.props;

        if(!params?.propcode || !params.resvcode){
            history.push('/');
            return;
        }
        dispatch(getReservationDetailsViaCode(params.propcode, params.resvcode));
    }

    deleteTaxHandler = taxID => {
        const { reservation, dispatch } = this.props;
        dispatch(deleteReservationTax(reservation._id, { taxIDs: [taxID] }));
    }

    getRoomSummary = () => {
        const { reservation, classes, currency, rooms } = this.props;
        const accommodations = reservation?.accommodationList || [];

        const summary = accommodations.map((row, index) => {

            const roomName = rooms?.[row.roomID]?.name || '';
            const totalAmount = row.rateList ? getAmtFormat(row.rateList.reduce((a, b) => a + (b['rate'] || 0), 0)) : 0;
            const nightCount = fetchNoOfDays(row.checkIn, row.checkOut) || 1;

            return (
                <div className={classes.roomInfoContainer} key={`room_${index}`}>
                    <div className="nights">{nightCount} x Nights</div>
                    <div className={clsx(classes.rowItem, classes.roomName)}>
                        <div>{roomName}</div>
                        <div>{`${currency}${totalAmount}`}</div>
                    </div>
                    <div className="resv-date">{fetchCombineDateFormat(row.checkIn, row.checkOut)}</div>
                </div>
            )
        });

        return summary;
    }

    createTransactionHandler = (payload, callback) => {
        const { reservation, dispatch } = this.props;
        dispatch(createReservationTransaction(reservation._id, payload))
            .then(success =>{
                if(success){ 
                    this.loadReservation();
                    if(callback) callback();
                }
            });
    }

    handleFetchInvoice = () => {
        const { reservation, dispatch } = this.props;
        dispatch(loadReservationInvoice(reservation._id));
    }

    onActionBtnClick = type =>{
        switch(type){
            case 'discount': 
                this.setState({ showDiscountModal: true });
                break;

            case 'invoice': 
                this.handleFetchInvoice();
                break;

            case 'refund': 
                this.setState({ showRefundModal: true });
                break;

            case 'tax': 
                this.setState({ showTaxModal: true });
                break;
        }
    }

    closeActionModalHandler = () =>{
        this.setState({
            showDiscountModal: false,
            showRefundModal: false,
            showTaxModal: false
        });
    }

    deletePOSItem = (payload) =>{
        const { reservation, dispatch } = this.props;
        dispatch(deleteOrder(reservation._id, payload));
    }

    render() {

        const {
            classes,
            currency,
            reservation,
            taxes,
            transactionModes,
            users,
            transactions,
            timezone,
            transactionModeIDs,
            upsellItems,
            posProducts,
            posOrders
        } = this.props;

        const { showDiscountModal, showPaymentModal, showRefundModal, showTaxModal } = this.state;

        return (
            <div className={classes.root}>
                {/* ---------------ROOMS SUMMARY-------------------- */}
                {this.getRoomSummary()}

                {/* ---------------UPSELL SUMMARY-------------------- */}
                <UpsellSummary 
                    upsellItems={upsellItems}
                    currency={currency}
                    reservation={reservation}
                />

                {/* ---------------POS SUMMARY-------------------- */}
                <POSSummary
                    currency={currency}
                    posProducts={posProducts}
                    orders={posOrders}
                    deleteHandler={this.deletePOSItem}
                />

                {/* ---------------TAX SUMMARY-------------------- */}
                {reservation.taxAmount > 0 && (
                    <TaxSummary
                        taxes={taxes}
                        reservation={reservation}
                        currency={currency}
                        handleDelete={this.deleteTaxHandler}
                    />
                )}

                {/* ---------------SUMMARY TOTAL-------------------- */}
                <div className={clsx(classes.rowItem, classes.roomName)}>
                    <div>Total</div>
                    <div>{getAmtFormat(reservation.totalAmount || 0, currency)}</div>
                </div>

                {/* ---------------PAYMENT SUMMARY-------------------- */}
                {transactions?.length > 0 && (
                    <PaymentSummary
                        transactions={transactions}
                        currency={currency}
                        transactionModes={transactionModes}
                        users={users}
                        timezone={timezone}
                    />
                )}

                {/* ---------------BALANCE-------------------- */}
                <div className={clsx(classes.rowItem, classes.balance)}>
                    <div>Balance</div>
                    <div> {getAmtFormat(reservation.balance || 0, currency)}</div>
                </div>

                {/* ---------------COLLECT PAYMNET BUTTON-------------------- */}
                <div className={classes.buttonContainer}>
                    <Button 
                        variant="contained" 
                        className={classes.button}
                        onClick={() =>this.setState({ showPaymentModal: true })}
                    >
                        Collect Payment
                    </Button>
                </div>

                {/* ---------------FOOTER ACTION BUTTONS-------------------- */}
                <div className={classes.aux}>

                    {ACTION_BUTTONS.map(action =>(
                        <div 
                            key={`action_${action.id}`}
                            className={classes.auxIcon} 
                            onClick={() =>this.onActionBtnClick(action.id)}
                        >
                            <img title={action.label} src={action.imgSrc} />
                            <div className={classes.auxText}>{action.label}</div>
                        </div>
                    ))}
                    
                </div>
                
                {/* ---------------COLLECT PAYMENT MODAL-------------------- */}
                {showPaymentModal && (
                    <CollectPaymentModal
                        property={this.props.property}
                        open={showPaymentModal}
                        closeModalHandler={() => this.setState({ showPaymentModal: false })}
                        reservationId={reservation._id}
                        createTransactionHandler={this.createTransactionHandler}
                        transactionMode={transactionModes}
                        transactionModeIDs={transactionModeIDs}
                    />
                )}

                {/* ---------------ADD DISCOUNT/SURCHARGE MODAL-------------------- */}
                {showDiscountModal && (
                    <DiscountModal 
                        open={showDiscountModal}
                        createTransactionHandler={this.createTransactionHandler}
                        totalAmount={reservation?.totalAmount || 0}
                        closeModalHandler={this.closeActionModalHandler}
                        transactionMode={transactionModes}
                        transactionModeIDs={transactionModeIDs}
                    />
                )}

                {/* ---------------REFUND MODAL-------------------- */}
                {showRefundModal && (
                    <RefundModal 
                        open={showRefundModal}
                        closeModalHandler={this.closeActionModalHandler}
                        transactionModes={transactionModes}
                        transactionModeIDs={transactionModeIDs}
                        currency={currency}
                        transactions={transactions}
                        timezone={timezone}
                    />
                )}

                {/* ---------------TAX MODAL-------------------- */}
                {showTaxModal && (
                    <TaxModal 
                        open={showTaxModal}
                        closeModalHandler={this.closeActionModalHandler}
                        taxes={taxes}
                        reservationID={reservation._id}
                    />
                )}

            </div>
        )
    }
}

const mapStateToProps = state => {
    const { reservationDetails, property, rooms, register, auth, pos } = state;
    const propertyID = register.propertyID;
    const propertyInfo = property?.property?.[propertyID] || {};
    return {
        reservation: reservationDetails?.reservation || {},
        transactions: reservationDetails?.transactions || [],
        rooms: rooms.rooms,
        currency: fetchCurrencyFormat(propertyInfo.currency) || '',
        upsellItems: property.upsellItems || {},
        taxes: rooms?.taxes || {},
        transactionModes: property.transactionMode || {},
        transactionModeIDs: property.transactionModeIDs || [],
        users: auth.users || {},
        timezone: property.timezone || '',
        posProducts: pos.products || {},
        posOrders: reservationDetails.orders || [],
    }
};

export default withStyles(styles)(connect(mapStateToProps)(withRouter(SummaryList)));
