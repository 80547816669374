// Customer Reducer
const defaultState = {
  selectedCustomer: null,
  cards: null,
  isCustomerUpdated: false,
  isCustomerCreated: false,
  isCustomerDocsUploaded: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    // ----------------------------------------------------------------
    case "LOAD_PRIMARY_CUSTOMER_REQUEST":
      return { ...state, selectedCustomer: null, primaryGuestID: null, isCustomerCreated: false, isCustomerUpdated: false, isCustomerDocsUploaded: false, cards: null };
    case "LOAD_PRIMARY_CUSTOMER_SUCCESS":
      return { ...state, selectedCustomer: action.payload ? action.payload.customer : null, primaryGuestID: action._id ? action._id : null, cards: action.payload ? action.payload.cards : null,};
    case "LOAD_PRIMARY_CUSTOMER_FAILURE":
      return { ...state, selectedCustomer: null, primaryGuestID: null, cards: null };
    // ----------------------------------------------------------------
    case "LOAD_GUEST_CUSTOMER_REQUEST":
      return { ...state, guestCustomer: null };
    case "LOAD_GUEST_CUSTOMER_SUCCESS":
      return { ...state, guestCustomer: action.payload ? action.payload.customer : null };
    case "LOAD_GUEST_CUSTOMER_FAILURE":
      return { ...state, guestCustomer: null };
    // ----------------------------------------------------------------
    case "UPDATE_CUSTOMER_REQUEST":
      return { ...state, isCustomerUpdated: false };
    case "UPDATE_CUSTOMER_SUCCESS":
      return { ...state, isCustomerUpdated: true, selectedCustomer: action.payload && state.primaryGuestID === action._id ? action.payload.customer : state.selectedCustomer };
    case "UPDATE_CUSTOMER_FAILURE":
      return { ...state, isCustomerUpdated: false };
    // ----------------------------------------------------------------
    case "CREATE_CUSTOMER_REQUEST":
      return { ...state, isCustomerCreated: false };
    case "CREATE_CUSTOMER_SUCCESS":
      return { ...state, isCustomerCreated: true };
    case "CREATE_CUSTOMER_FAILURE":
      return { ...state, isCustomerCreated: false };
    // ----------------------------------------------------------------
    case "LOAD_CUSTOMER_CARD_REQUEST":
      return { ...state, customerCard: null, cardIds: null };
    case "LOAD_CUSTOMER_CARD_SUCCESS":
      return { ...state, customerCard: action.payload, cardIds: action.cardIds };
    case "LOAD_CUSTOMER_CARD_FAILURE":
      return { ...state, customerCard: null, cardIds: null };
    // ----------------------------------------------------------------
    case "UPLOAD_CUSTOMER_DOC_REQUEST":
      return { ...state, isCustomerDocsUploaded: false };
    case "UPLOAD_CUSTOMER_DOC_SUCCESS":
      return { ...state, selectedCustomer: action.payload && state.primaryGuestID === action._id ? action.payload.customer : state.selectedCustomer, isCustomerDocsUploaded: true };
    case "UPLOAD_CUSTOMER_DOC_FAILURE":
      return { ...state, isCustomerDocsUploaded: false };
    // ----------------------------------------------------------------
    default:
      return state;
  }
};
