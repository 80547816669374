import React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

import DashboardPage from "../components/landing/DashboardPage";
import Header from "../components/header/Header";
import SearchPage from '../components/search/SearchPage';

import { loadLandingPageReservations } from "../redux/actions/today";
import { loadRooms, loadRoomTypes, loadBeds, loadBedsStatus, loadBedAllocations, loadRatesAvailability, updateBedsStatus, loadTax } from "../redux/actions/room";
// import { getWSOnboardingStatus } from '../redux/actions/auth';
import { loadCashAccounts } from "../redux/actions/cashDrawer";
// import { loadUpsellItems, loadTransactionMode } from "../redux/actions/property";
import { fetchBookingSource, resetReservationDetails } from "../redux/actions/reservations";
// import { loadPropertyConfig } from "../redux/actions/propertyConfigs";

import { fetchDateFormat, DATE_FORMATS, fetchDatePerTimezone } from "../utils/utility";
import { PAGES } from "../utils/constants";

import HousekeepingPage from "../components/housekeeping/HousekeepingPage";
import { getHousekeepingData } from "../redux/actions/housekeeping";

const styles = theme => ({
  container: {
    background: '#FFFFFF',
    display: "flex",
    flexDirection: "column",
    marginTop: 0,
    position: "relative",
    paddingLeft: 40,
    paddingRight: 40,
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px 0 10px"
    }
  },
  addIconContainer: {
    position: "absolute",
    top: 15,
    right: 84,
    [theme.breakpoints.up("lg")]: {
      right: 224
    },
    [theme.breakpoints.down("sm")]: {
      right: 24,
      top: 18
    }
  },
});

class DashboardContainer extends React.Component {

  componentDidMount = () => {
    // console.log(this.props.auth.isAuthenticated);
    if (!this.props.auth.isUnlocked) {
      this.props.history.push(`/${PAGES.LOGIN}`);
    } else {
      // this.props.dispatch(getWSOnboardingStatus());
      // this.props.dispatch(loadPropertyConfig());
      // this.props.dispatch(loadTransactionMode())
      this.props.dispatch(loadLandingPageReservations());
      this.props.dispatch(getHousekeepingData());
      
      // this.props.dispatch(loadRooms());
      // this.props.dispatch(loadRoomTypes());
      // this.props.dispatch(loadBeds());
      // this.props.dispatch(loadBedsStatus());
      // this.props.dispatch(loadTax());
      // this.props.dispatch(fetchBookingSource());
      // this.props.dispatch(loadUpsellItems());
      this.props.dispatch(resetReservationDetails());
      if (this.props.property && Object.keys(this.props.property).length) {
        this.handleCallInventoryData();
      }
      if(this.props.cashDrawerFeatureEnabled) {
        this.props.dispatch(loadCashAccounts())
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.property && !_.isEqual(this.props.property, prevProps.property)) {
      this.handleCallInventoryData();
    }
    // if (this.props.isBedsStatusUpdated && !_.isEqual(this.props.isBedsStatusUpdated, prevProps.isBedsStatusUpdated)) {
    //   this.props.dispatch(loadBedsStatus());
    // }
  }

  handleCallInventoryData = (date1, date2) => {
    const timezone = this.props.timezone;
    date1 = date1 || fetchDatePerTimezone(null, timezone);
    if (!(date1 && date2))
      this.props.dispatch(loadBedAllocations(fetchDateFormat(fetchDatePerTimezone(null, timezone), DATE_FORMATS.DEFAULT_DATE)));
    
    this.props.dispatch(loadRatesAvailability({
      startDate: fetchDateFormat(date1, DATE_FORMATS.DEFAULT_DATE),
      endDate: date2 ?
        fetchDateFormat(date2, DATE_FORMATS.DEFAULT_DATE) :
        fetchDateFormat(fetchDatePerTimezone(null, timezone).add(1, 'days'), DATE_FORMATS.DEFAULT_DATE)
    }))
  };

  handleGoReservation = (propertyCode, reservCode) => {
    this.props.history.push(`${propertyCode}/${PAGES.RESERVATION}/${reservCode}`);
  };

  render() {
    const { classes, searchActive, timezone } = this.props;
    const property = { ...this.props.property };
    return (
      <>

        <Header/>

        <div className={classes.container}>
          {!searchActive && (
            <Switch>
              <Route exact path={`/${PAGES.LANDING}`}>
                <DashboardPage
                  property={property}
                  handleGoReservation={this.handleGoReservation}
                  handleSelectedDate={this.handleCallInventoryData}
                  history={this.props.history}
                  handleCallInventoryData={this.handleCallInventoryData}
                  timezone={timezone}
                />
              </Route>

              <Route exact path={`/${PAGES.HOUSEKEEPING}`}>
                <HousekeepingPage />
              </Route>
            </Switch>
          )}

          {searchActive && <SearchPage />}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  let propertyID = state.register.propertyID;
  let property = propertyID && state.property.property ? state.property.property[propertyID] : {};
  property.features = property.features || [];
  let propertyfeatures = {...state.property.features}
  let cashDrawerFeatureEnabled = false;
  property.features.map(fID => {
    if(propertyfeatures[fID] && propertyfeatures[fID].name === "Cash Drawer" && propertyfeatures[fID].isEnabled === "true"){
      cashDrawerFeatureEnabled = true;
    }
  });

  return {
    searchActive:
      state.filters.searchActive ||
      !(state.filters.text === "") ||
      !(state.filters.guestStatus === "") ||
      !(state.filters.roomType === "") ||
      !(state.filters.sortBy === "") ||
      !(state.filters.arrivalDate === ""),
    auth: state.auth,
    property: property,
    cashDrawerFeatureEnabled: cashDrawerFeatureEnabled,
    // isBedsStatusUpdated: state.inventory.isBedsStatusUpdated,
    timezone: state.property.timezone
  };
};

export default withStyles(styles)(connect(mapStateToProps)(DashboardContainer));
