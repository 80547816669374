import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import IconButton from '@material-ui/core/IconButton';
import { fetchDateFormat, DATE_FORMATS } from "../../../utils/utility";

const useStyles = makeStyles(theme =>({
    root: {
      marginTop: 10,
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    addBtn: {
      color: "#4f4f4f",
      border: "1px solid #e0e0e0",
      padding: "0px 9px",
      fontSize: "1.1rem",
      fontWeight: 600,
      borderRadius: "5px",
      height: "30px",
      '& .addIcon': {
          color: "#4f4f4f",
          fontSize: "2rem",
          marginRight: "5px",
      },
      '&:hover': {
        background: '#ECECEC'
      }
    },
    note: {
      background: '#F0F0F0',
      border: "1px solid #e0e0e0",
      borderRadius: "5px",
      boxSizing: "border-box",
      display: "flex",
      fontSize: "1.1rem",
      marginRight: "5px",
      marginBottom: "5px",
      padding: "10px",
      position: 'relative',
    },
    title: {
      color: '#828282',
      fontWeight: 600,
    },
    time: {
      color: '#999999',
      fontSize: '1rem',
      marginRight: 8,
      marginLeft: 8,
    },
    text: {
      color: '#828282',
      display: 'block',
      overflow: 'auto',
      whiteSpace: 'pre-wrap',
      maxWidth: '80%',
      wordWrap: 'break-word',
    }
}));

const ReservationNotes = props =>{
    const classes = useStyles();
    const { reservation, timezone, addNoteModalHandler } = props;

    return (
        <div className={classes.root}>
          <div className={classes.container}>
            {reservation.notes?.map((item, index) =>(
                item && !item.isArchived && 
                <div key={index} className={classes.note}>
                  <div className={classes.title}>
                    {item.addedBy}
                  </div>
                  <div className={classes.time}>
                    {fetchDateFormat(item.createdAt, `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY}, ${DATE_FORMATS.TIME}`, timezone)}
                  </div>
                  <div className={classes.text}>{item && item.note}</div>
                </div>
            ))}
          </div>

          <IconButton 
            className={classes.addBtn}
            onClick={addNoteModalHandler}
          >
            <AddRoundedIcon className="addIcon"/> Add note
          </IconButton>
        </div>
    )
}

const mapStateToProps = state =>{
  const { reservationDetails, register, property } = state;
  return {
      reservation: reservationDetails?.reservation || {},
      propertyID: register.propertyID,
      timezone: property.timezone,
  }
}

export default connect(mapStateToProps)(ReservationNotes);