import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(theme =>({
    addMore:{
        display: 'flex',
        marginRight: '10px'
    },
    iconBtn:{
        borderRadius: '5px',
        background: '#fff',
        boxShadow: '0 0 8px 0px #dedede',
        marginRight: '10px',
        width: '40px',
        height: '40px',
    },
    valueBlock: {
        width: '40px',
        height: '40px',
        background: '#fff',
        boxShadow: '0 0 8px 0px #dedede',
        borderRadius: '5px',
        textAlign: 'center',
        lineHeight: '50px',
        marginRight: '10px',
        lineHeight: '40px'
    },
}));

const CounterInput = props =>{
    const classes = useStyles();
    const [count, setCount ] = useState(0);
    return(
        <Box className={classes.addMore}>
            <IconButton 
                aria-label="add" 
                className={classes.iconBtn}
                onClick={()=>setCount(count + 1)}
            >
                <AddIcon />
            </IconButton>
            
            <Box className={classes.valueBlock}>{count}</Box>

            <IconButton 
                aria-label="sub" 
                className={classes.iconBtn}
                onClick={()=>setCount(count - 1)}
            >
                <RemoveIcon />
            </IconButton>
        </Box>
    );
}

export default CounterInput;