import http from "../utils/http";
import { normalize } from "normalizr";
import { reservationAllSchema } from "../schema/reservation";
//----------------------------------------------------------------
// LOAD TODAYS CHECK-INS
//----------------------------------------------------------------
export function loadCheckIns() {
  const ACTION_NAME = "LOAD_CHECKINS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/todayCheckIns")
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD TODAYS CHECK-OUTS
//----------------------------------------------------------------
export function loadCheckOuts() {
  const ACTION_NAME = "LOAD_CHECKOUTS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/todayCheckOuts")
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD TODAYS IN-HOUSE
//----------------------------------------------------------------
export function loadInHouse() {
  const ACTION_NAME = "LOAD_INHOUSE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/todayInHouse")
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD LANDING PAGE RESERVATIONS
//----------------------------------------------------------------
export function loadLandingPageReservations() {
  const ACTION_NAME = "LOAD_LANDING_PAGE_RESERVATIONS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/landingPage/")
      .then(successResult => {
        let normalizedData = normalize(
          successResult.data,
          reservationAllSchema
        );
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: {
            accommodationList: normalizedData.entities.accommodationList,
            reservation: normalizedData.entities.reservation,
            guests: normalizedData.entities.guests,
            reservationIDs: normalizedData.result
          }
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}