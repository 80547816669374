import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme =>({
    root: {
    
    },
    inputLabel: {
        fontSize: '1.2rem',
        color: '#666666',
        marginBottom: 10,
    },
    formControl: {
        width: '100%'
    },
    errorMessage: {
        color: "#f44336",
        marginLeft: '0px',
        fontSize: '1.2rem'
    }
}));

const CustomInput = props =>{
    const classes = useStyles();
    const {
        hide = false,
        isMandatory = false,
        label, 
        isError,
        name,
        errorMessage
    } = props;

    if(hide) return null;

    return (
        <Box mb={3}>

            <InputLabel 
                htmlFor={name}
                className={classes.inputLabel}
            >
                {label} {isMandatory && '*'}
            </InputLabel>

            <FormControl variant="outlined" className={classes.formControl}>
                {props.children}

                {isMandatory && isError && (
                    <FormHelperText className={classes.errorMessage}>{errorMessage}</FormHelperText>
                )}
            </FormControl>
        </Box>
    )   
}

export default CustomInput;