import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";

import { getCurrency } from "../helper";

const secondaryColor = "#5C5C5C";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: "20px",
        maxWidth: 500,
        borderRadius: "20px",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0)
        }
    },
    roomInfoCard: {
        //alignItems: 'center',
        //display: 'flex',
        marginBottom: "30px",
        padding: "20px 20px",
        boxShadow: "0 2px 20px 0px #F0F0F0",
        borderRadius: "20px",
        width: "calc(100% - 40px)"
    },
    roomCard: {
        alignItems: "center",
        display: "flex"
    },
    nameContainer: {
        display: "flex",
        justifyContent: "flex-start"
    },
    img: {
        // margin: "auto",
        // display: "block",
        // maxWidth: "100%",
        // maxHeight: "100%",
        // borderRadius: "10px"
        height: "100%",
        width: "100%",
        objectFit: "cover",
        objectPosition: "center",
        borderRadius: "20px"
    },
    roomNameContainer: {
        marginLeft: 40,
        width: "calc(100% - 200px)"
    },
    bookButton: {
        alignSelf: "center",
        background: "#000000",
        borderRadius: "10px",
        boxShadow: "none",
        fontWeight: 600,
        width: "100%"
    },
    roomPriceOccupancy: {
        color: secondaryColor,
        marginTop: "10px"
    },
    roomPrice: {
        marginRight: "5px"
    },
    roomOccupancyType: {
        fontSize: "1.2rem",
        alignSelf: "center"
    },
    roomImageHolder: {
        height: "100px",
        width: "100px"
    },
    extraPadding: {
        paddingLeft: 20
    },
    desc: {
        color: "#666666",
        fontSize: "1.2rem"
    },
    topMargin: {
        marginTop: 10
    },
    buttonContainer: {
        marginLeft: "auto",
        width: "80px"
    }
}));

const RoomCategoryListItem = ({
    roomListData,
    currency,
    selectRoomTypeHandler,
    t
}) => {
    const classes = useStyles();

    if (!roomListData || roomListData?.length === 0)
        return (
            <Box textAlign="center">
                {t("bookingEngine.roomCategory.noRooms")}
            </Box>
        );

    const sortedRoomList = roomListData?.sort(
        (currentRoom, nextRoom) =>
            (nextRoom?.absolutePosition || 0) -
            (currentRoom?.absolutePosition || 0)
    );

    return (
        <>
            {sortedRoomList.map((data, index) => (
                <div key={index} className={classes.roomInfoCard}>
                    {window.innerWidth < 460 && (
                        <div>
                            <Typography gutterBottom variant="subtitle1">
                                {data.name}
                            </Typography>
                        </div>
                    )}
                    <div className={classes.roomCard}>
                        <div>
                            <div className={classes.roomImageHolder}>
                                <img
                                    className={classes.img}
                                    alt="room"
                                    src={data.roomImage}
                                />
                            </div>
                        </div>
                        <div
                            className={clsx({
                                [classes.roomNameContainer]: true,
                                [classes.extraPadding]: window.innerWidth <= 560
                            })}
                        >
                            <Grid
                                item
                                xs
                                container
                                direction="column"
                                spacing={2}
                            >
                                <Grid item xs>
                                    {window.innerWidth > 460 && (
                                        <Typography
                                            gutterBottom
                                            variant="subtitle1"
                                        >
                                            {data.name}
                                        </Typography>
                                    )}

                                    <Typography
                                        component="div"
                                        className={classes.roomPriceOccupancy}
                                        fontWeight="bold"
                                        fontSize="18px"
                                    >
                                        <div className={classes.desc}>
                                            {t(
                                                "bookingEngine.roomCategory.from"
                                            )}
                                        </div>
                                        <Box display="flex">
                                            <Box
                                                className={`${classes.roomPrice}`}
                                                fontWeight="bold"
                                            >
                                                {getCurrency(
                                                    currency,
                                                    data.perNightCost
                                                )}{" "}
                                                <span
                                                    className={
                                                        classes.roomOccupancyType
                                                    }
                                                >
                                                    {t(
                                                        "bookingEngine.roomCategory.perNight"
                                                    )}
                                                </span>
                                            </Box>
                                        </Box>
                                    </Typography>
                                    {window.innerWidth <= 460 && (
                                        <div className={classes.topMargin}>
                                            <div
                                                className={
                                                    classes.buttonContainer
                                                }
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="medium"
                                                    className={
                                                        classes.bookButton
                                                    }
                                                    onClick={() =>
                                                        selectRoomTypeHandler(
                                                            data
                                                        )
                                                    }
                                                >
                                                    {t(
                                                        "bookingEngine.roomCategory.book"
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                        {window.innerWidth > 460 && (
                            <div className={clsx(classes.buttonContainer)}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    className={classes.bookButton}
                                    onClick={() => selectRoomTypeHandler(data)}
                                >
                                    {t("bookingEngine.roomCategory.book")}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
};

export default withTranslation()(RoomCategoryListItem);
