import React, {Component} from 'react';
import { withStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import ActivityItem from './ActivityItem';
import DateFilterBtn from './DateFilterBtn';
import Footer from '../Footer';

const styles = theme =>({
    root: {
        // border: '1px solid'
    },
    dateFilterSection:{
        marginBottom: '40px',
        display: 'flex',
    }
});

const Activities = (props) => {

    const { classes, nextStep } = props;

    nextStep()
    
    return (
        <>
            <Box className={classes.root}>
                <Box className={classes.dateFilterSection}>
                    <DateFilterBtn 
                        isActive={true}
                        label="Mon, June 12"
                    />
                    <DateFilterBtn 
                        isActive={false}
                        label="Tue, June 13"
                    />
                    <DateFilterBtn 
                        isActive={false}
                        label="Wed, June 14"
                    />
                </Box>
                <ActivityItem />
                <ActivityItem />
                <ActivityItem />
            </Box>
            <Footer 
                stepInfo="step 4 of 5"
                buttonLabel="Proceed"
                onProceed = {nextStep}
            />
        </>
    )
}

export default withStyles(styles)(Activities);