import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';

import Dialog from '../common/CustomDialog';
import Footer from '../common/Footer';
import { fetchCurrencyFormat } from '../../../utils/utility';
import RateList from './RateList';
import UpdatePriceForm from './UpdatePriceForm';

import { editReservation, resetError } from '../../../redux/actions/reservations';

const styles = theme =>({
    root: {},
});

class EditPrice extends Component {
    state = {
        currentStep: 1,
        selectedRates: [],
        rateList: [],
        newPrice: 0,
        isSelectAll: false
    }

    componentDidMount(){
        this.initRateList();
    }

    componentDidUpdate(prevProps){
        if(!isEqual(this.props.guestAccomodation, prevProps.guestAccomodation)){
            this.initRateList();
        }
    }

    initRateList = () =>{
        const { guestNumber, guestAccomodation } = this.props;
        const accomodationData = guestAccomodation[guestNumber];
        let ratesData = [];

        accomodationData.forEach(acc =>{
            ratesData = [ ...ratesData, ...acc.rateList ];
        });

        this.setState({ rateList: [...ratesData] });
    }

    computeCheckAll = () =>{
        const { selectedRates, rateList } = this.state;

        if(rateList.length == selectedRates.length){
            this.setState({ isSelectAll: true });
            return;
        }

        this.setState({ isSelectAll: false });
    }

    onChangeHandler = event =>{
        const { selectedRates } = this.state;
        const isChecked = event.target.checked;
        const rateID = event.target.value;

        let updatedData = [];
        
        if(!isChecked){
            updatedData = selectedRates.filter(id => id != rateID);
            this.setState({ selectedRates: [ ...updatedData ] }, this.computeCheckAll);
            return
        }

        this.setState({ selectedRates: [ ...selectedRates, rateID ] }, this.computeCheckAll);
    }

    selectedAllHandler = event =>{
        const { rateList } = this.state;
        const isChecked = event.target.checked;

        if(!isChecked){
            this.setState({ selectedRates: [], isSelectAll: false });
            return;
        }

        const ratesIDs = rateList.map(rate => rate._id);
        this.setState({ selectedRates: [ ...ratesIDs ], isSelectAll: true });
    }

    nextStep = () =>{
        this.setState(prevStep =>({
            currentStep: prevStep.currentStep + 1
        }))
    }

    prevStep = () =>{
        this.setState(prevStep =>({
            currentStep: prevStep.currentStep - 1,
            newPrice: 0
        }));
    }

    getFooterProps = () =>{
        const { currentStep, selectedRates, newPrice } = this.state;
        if(currentStep == 1){
            return {
                label: "Next",
                nextStepHandler: this.nextStep,
                isNextBtnDisabled:  selectedRates.length == 0
            }
        }

        return {
            label: "Save",
            nextStepHandler: this.submitHandler,
            isNextBtnDisabled: (newPrice == 0 || !newPrice)
        }
    }

    submitHandler = () =>{
        const { dispatch, guestNumber, reservation } = this.props;
        const { selectedRates, rateList, newPrice } = this.state;

        const changeRatesByDate = rateList.filter(rate => selectedRates.includes(rate._id))
            .map(rateInfo =>({ 
                date: rateInfo.date,
                rate: parseFloat(newPrice)
            }));

        const data = {
            guestNumber,
            reservationID: reservation._id,
            changeRatesByDate
        }

        dispatch(editReservation(data))
            .then(success =>{
                if(success) this.prevStep();
            });
    }

    updatePriceHandler = amount =>{
        this.setState({ newPrice: amount });
    }

    handleCloseError = () =>{
        const { dispatch } = this.props;
        dispatch(resetError('UPDATE_RESERVATION_DATA'));
    }

    render() {
        const { closeModalHandler, currency, errors, isLoading } = this.props;
        const { rateList, selectedRates, currentStep, newPrice, isSelectAll } = this.state;
        
        return (
            <Dialog
                headerTitle="Edit Price"
                closeModalHandler={closeModalHandler}
                // errors={errors}
                isLoading={isLoading}
                handleCloseError={this.handleCloseError}
            >
                {({
                    1: <RateList 
                            isSelectAll={isSelectAll}
                            rateList={rateList} 
                            selectedRates={selectedRates} 
                            currency={currency}
                            selectedAllHandler={this.selectedAllHandler}
                            onChangeHandler={this.onChangeHandler} 
                        />,

                    2: <UpdatePriceForm 
                            newPrice={newPrice}
                            onChangeHandler={this.updatePriceHandler}
                            currency={currency}
                        />

                }[currentStep])}
                
                <Footer 
                    {...this.getFooterProps()} 
                    showPrevBtn={currentStep > 1}
                    prevStepHandler={this.prevStep}
                />
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    const { property, register, reservationDetails, error, loading } = state;
    const propertyID = register.propertyID;
    const propertyInfo = property?.property?.[propertyID] || {};
    return {
        currency: fetchCurrencyFormat(propertyInfo.currency) || '',
        reservation: reservationDetails?.reservation || {},
        // errors: error.UPDATE_RESERVATION_DATA,
        isLoading: loading.UPDATE_RESERVATION_DATA
    }
};

export default withStyles(styles)(connect(mapStateToProps)(EditPrice));