import React, { forwardRef, useImperativeHandle } from 'react';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from '@material-ui/core';
import AddNights from './AddNights';
import { fetchCurrencyFormat, getAmtFormat } from '../../../utils/utility';

const useStyles = makeStyles(theme => ({
  sectionOption: {
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    marginTop: 20
  },
  sectionAction: {
    width: '100%',
    marginTop: 20
  },
  sectionAmount: {
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    margin: '20px 0',
    borderRadius: '5px',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      padding: '10px 10px 15px',
      '& span': {
        marginTop: 5
      }
    }
  },
  sectionAccommodation: {
    padding: '20px 20px 20px 0'
  },
  label: {
    fontSize: '1.4rem',
    display: 'block',
    fontWeight: 600
  },
  Button: {
    backgroundColor: '#e6bbb4',
    color: '#fff',
    padding: '15px 25px',
    '&:hover': {
      backgroundColor: '#e6bbb4',
    }
  },
  disabled: {
    backgroundColor: '#e0e0e0',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    }
  },
  icon: {
    width: 20,
    height: 20,
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    borderRadius: '50%'
  },
  title: {
    padding: '20px 20px 0 20px',
    fontSize: '1.8rem',
    fontWeight: 600
  },
  emptyContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 80,
    paddingTop: 80,
    width: '100%',
    '& h5':{
      textAlign: 'center'
    }
  },
  back: {
    marginTop: 40,
    marginBottom: 10,
    width: '120px'
  },
  finePrint: {
    color: '#999999',
    cursor: 'pointer',
    fontSize: '1.1rem',
  },
}));

const AutomaticSelectRooms = forwardRef((props, ref) => {
  const classes = useStyles();
  const { guestCount, date, nights, property, suggestions, loading, currentStep, selectedRatePlan } = props;
  const [currentNum, setCurrentNum] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [selectedRatePlanIDs, setSelectedRatePlanIDs] = React.useState([]);
  const [accommodationAmount, setAccommodationAmount] = React.useState(0);
  const [taxAmount, setTaxAmount] = React.useState(0);
  const currency = fetchCurrencyFormat(property ? property.currency : null);

  React.useEffect(() => {
    if(suggestions && suggestions.length){
      calculateAmount(0)
    }
  }, [suggestions, selectedRatePlan]);

  useImperativeHandle(ref, () => ({
    handleProcessData() {
      return {
        calendarOptions: suggestions[currentNum] && suggestions[currentNum].calendarOptions,
        total: total,
        ratePlanIDs: [ ...selectedRatePlanIDs ]
      }
    }
  }))

  const handleNext = (key) => {
    if (suggestions[key + 1]) {
      setCurrentNum(key + 1);
      calculateAmount(key + 1)
    }
  }

  const handlePrev = (key) => {
    if (suggestions[key - 1]) {
      setCurrentNum(key - 1)
      calculateAmount(key - 1)
    }
  }

  const calculateTotal = (total) => {

  }

  const setRatePlanIDHandler = (priceArray) =>{
    let currentRatePlanIDs = priceArray.map(data => data.ratePlanID);
    currentRatePlanIDs = [ ...new Set([ ...currentRatePlanIDs] )];
    setSelectedRatePlanIDs([ ...currentRatePlanIDs ]);
  }

  const calculateAmount = (num) => {
    let currentSuggestion = { ...suggestions[num] };
    
    let priceArray = currentSuggestion.prices[selectedRatePlan] ? 
      [ ...currentSuggestion.prices[selectedRatePlan] ] : [];

    if(priceArray.length > 0){
      const currentPrice = priceArray.reduce((acc, curr) =>{
        return {
          accommodationAmount: acc.accommodationAmount + curr.accommodationAmount,
          exclusiveTax: acc.exclusiveTax + curr.exclusiveTax,
          inclusiveTax: acc.inclusiveTax + curr.inclusiveTax
        }
      });

      setAccommodationAmount(currentPrice.accommodationAmount || 0);
      setTaxAmount((currentPrice.exclusiveTax || 0) + (currentPrice.inclusiveTax || 0));
      setTotal((currentPrice.accommodationAmount || 0) + (currentPrice.exclusiveTax || 0));
      
      setRatePlanIDHandler(priceArray);
    }
  }

  const handleSelectBed = (block) => {

  }

  return (
    <>
      <div className={classes.sectionOption}>
        {!loading && (suggestions.length > 0 ?
          <>
            <div className={classes.title}>Option {currentNum + 1} - Best available option!</div>
            <div className={classes.sectionAccommodation}>
              <AddNights
                mode={"add"}
                date={date}
                nights={nights}
                property={property}
                suggestions={suggestions && suggestions[currentNum]}
                handleSelectBed={handleSelectBed}
                calculateTotal={calculateTotal}
                showPerNight={true}
                showRate={false}
                showGuest={true}
                guestCount={guestCount}
                blockStyle={true}
              />
            </div>
          </> :
          <div className={classes.emptyContainer}>
            <Typography variant="h5">Wow! You're choc-a-block booked out for this period.</Typography>
            <Button variant="outlined" className={classes.back} onClick={() => props.onChangePrev(currentStep - 1)}>Go Back</Button>
            <div className={classes.finePrint}>No I'm not. Report an issue.</div>
          </div>
        )}
      </div>
      {suggestions.length >= 2 &&
        <div className={classes.sectionAction}>
          <Grid container>
            <Grid item xs={6} sm={6}>
              <Button className={clsx({
                [classes.Button]: true,
                [classes.disabled]: currentNum === 0
              })} variant="contained" disabled={currentNum === 0} onClick={() => handlePrev(currentNum)}>Previous Options</Button>
            </Grid>

            <Grid item xs={6} sm={6}>
              <Button style={{ float: 'right' }} className={clsx({
                [classes.Button]: true,
                [classes.disabled]: currentNum === (suggestions.length - 1)
              })} variant="contained" disabled={currentNum === (suggestions.length - 1)} onClick={() => handleNext(currentNum)}>Next Options</Button>
            </Grid>
          </Grid>
        </div>
      }
      {suggestions.length > 0 &&
        <div className={classes.sectionAmount}>
          <span className={classes.label}>Accommodation amount {getAmtFormat(accommodationAmount, currency)}</span>
          <span className={classes.label}>Tax amount {getAmtFormat(taxAmount, currency)}</span>
          <span className={classes.label}>Total amount {getAmtFormat(total, currency)}</span>
        </div>
      }
    </>
  )
})

export default (AutomaticSelectRooms);