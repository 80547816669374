import React, { useEffect } from 'react';
import { Switch } from "react-router-dom";
import { connect } from 'react-redux';
import clsx from 'clsx';
import PrivateRoute from './PrivateRoute';
import MainLayout from '../layout/MainLayout';
import { AppRoute } from './AppRoute';
import { PAGES } from '../../utils/constants';

import LoginPage from '../auth/LoginPage';
import ReservationPage from '../../containers/ReservationPage';
import DashboardContainer from '../../containers/DashboardContainer';

import { loadPropertyConfig } from '../../redux/actions/propertyConfigs';
import { loadEmailTemplates, loadTransactionMode, loadUpsellItems } from '../../redux/actions/property';
import { loadBeds, loadRooms, loadRoomTypes, loadTax } from '../../redux/actions/room';
import { fetchBookingSource } from '../../redux/actions/reservations';
import { getStripeAccountStatus } from '../../redux/actions/payments';

const InSessionRoutes = props =>{
    const { dispatch, auth } = props;

    useEffect(() =>{
        
        if(!auth.isAuthenticated) return;

        dispatch(loadPropertyConfig());
        dispatch(getStripeAccountStatus());
        dispatch(loadTransactionMode())
        dispatch(loadRooms());
        dispatch(loadRoomTypes());
        dispatch(loadBeds());
        dispatch(loadTax());
        dispatch(fetchBookingSource());
        dispatch(loadUpsellItems());
        dispatch(loadEmailTemplates());

    }, []);

    return (
        <Switch>
            <PrivateRoute exact layout={props => <div className="main">{props.children}</div>} path={`/:propcode/reservation/:resvcode`} component={ReservationPage} />
            <AppRoute exact layout={MainLayout} path={[`/${PAGES.LANDING}`, `/${PAGES.HOUSEKEEPING}`]} component={DashboardContainer} exact={true} />

            {/* Rediect to login page if route not found, login component will further
              check to redirect to proper pages as per the current session */}
            <PrivateRoute layout={MainLayout} path="*" component={LoginPage}/>
        </Switch>
    )
}

const mapStateToProps = props =>{   
    const { auth } = props; 
    return { auth };
}

export default connect(mapStateToProps)(InSessionRoutes);