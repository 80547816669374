import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Chip } from '@material-ui/core';
import Select from 'react-select';

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    borderRadius: 5,
    boxShadow: '0 3px 4px 0 #ECECEC',
    border: 1,
    padding: '10px 20px',
    minWidth: 100,
    margin: 0,
    marginBottom: 10,
    marginRight: 10,
    height: 'auto',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1]
    },
  },
}))(Chip);

const useStyles = makeStyles(theme => ({
  header: {
    padding: '35px 0 0 0px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiBreadcrumbs-separator': {
      display: 'none',
    }
  },
  break: {
    // margin: '0px -30px 0',
    // width: 'calc(100% + 60px)',
    height: 4,
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
  },
  icon: {
    width: 20,
    height: 20
  },
  button: {
    borderRadius: '2px!important',
    backgroundColor: '#f7f7f7',
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    border: 1,
    padding: '10px 20px',
    minWidth: 100,
    margin: '0 5px 5px 0'
  },
  final: {
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
  dividerButton: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    margin: 0,
    marginRight: 10,
  },
  dividerBreadcrumb: {
    padding: '10px 0 0 0',
    '& .MuiBreadcrumbs-separator': {
      display: 'block',
      width: 3,
      boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
      height: 50,
    }
  },
  nolink: {
    cursor: 'default',
    '&:hover, &:focus': {
      backgroundColor: '#fff'
    },
    '&:active': {
      boxShadow: 'none'
    }
  },
  selectContainer:{
    marginTop: '20px',
    width: '33%',
    [theme.breakpoints.down("xs")]:{
      width: '100%'
    }
  }
}))

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    borderRadius: 10,
    boxShadow: '0 2px 30px #F2F2F2',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

const Breadcrumb = (props) => {
  const classes = useStyles();
  const { 
    list, 
    divider, 
    ratePlanOptions = [], 
    selectRatePlanHandler = null,
    selectedRatePlan = '', 
    displayOption
  } = props;

  return (
    <>
      {divider && <div className={classes.break}></div>}
      <div className={clsx({[classes.header]: true, [classes.dividerBreadcrumb]: divider})}>
        <Breadcrumbs separator="" aria-label="breadcrumb">
          {Object.keys(list).map(key => (
            <StyledBreadcrumb
              key={key}
              component="a"
              href="#"
              className={clsx({[classes.dividerButton]: divider, [classes.final]: list[key].final, [classes.nolink]: !list[key].step })}
              label={list[key].text}
              icon={list[key].icon ? <img className={classes.icon} src={list[key].icon} /> : null}
              onClick={() => props.onChangePrev(list[key].step)}
            />
          ))}
        </Breadcrumbs>
      </div>

      {displayOption && (
          <div className={classes.selectContainer}>
            <Select
              value={ratePlanOptions.find(option => selectedRatePlan == option.value)}
              options={ratePlanOptions}
              onChange={selectRatePlanHandler}
              isClearable={false}
              styles={selectStyle}
              theme={theme => ({
                  ...theme,
                  border: 0,
                  colors: {
                  ...theme.colors,
                  primary: '#666666',
                  primary25: '#dddddd'
                },
              })}
            />
          </div>
      )}
    </>
  )
}

export default (Breadcrumb);