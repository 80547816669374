import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';

const useStyles = makeStyles(theme =>({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: "100px",
    },
    IconButton: {
        border: '1px solid #e0e0e0',
        color: '#000',
        padding: "5px",
    },
    incCount: {
        textAlign: "center",
        fontSize: "1.2rem",
        fontWeight: 600,
        '& p': {
            margin: '0px',
        },
        '& .count': {
            fontSize: "1.7rem",
            fontWeight: 600,
        },
    },
    seperator: {
        fontSize: '1.3rem',
        fontWeight: '600',
        margin: '30px 0px'
    },
    icon: {
        fontSize: '2rem'
    }
}));

const Counter = props =>{
    const classes = useStyles();
    const { count = 0, onCountChange, itemID } = props;

    const decrementCountHandler = () =>{
        if(count == 0) return;
        onCountChange(itemID, count - 1);
    }

    const incrementCountHandler = () =>{
        onCountChange(itemID, count + 1);
    }

    return (
        <div className={classes.root}>

            <IconButton 
                className={classes.IconButton}
                onClick={decrementCountHandler}
            >
                <RemoveRoundedIcon className={classes.icon} />
            </IconButton>

            <div className={classes.incCount}>
                <p className="count">{count}</p>
            </div>

            <IconButton 
                className={classes.IconButton}
                onClick={incrementCountHandler}
            >
                <AddRoundedIcon className={classes.icon} />
            </IconButton>
        </div>
    )
}

export default Counter;