const initialState = {
    accommodationList: [],
    guests: {},
    reservationID: null,
    balance: 0,
    taxAmount: 0,
    totalAmount: 0,
    checkIn: null,
    checkOut: null,
    accomodationLinkRequired: false
};

export default (state ={ ...initialState }, action) =>{
    switch(action.type){
        case 'FETCH_CHECKIN_ROOMS_INFO_SUCCESS':
            return { 
                ...state, 
                propertyDetails: action.payload.propertyDetails,
                roomTypes: action.payload.roomTypes,
                rooms: action.payload.rooms,
                beds: action.payload.beds,
                taxItems: action.payload.taxItems,
                ratePlans: action.payload.ratePlans,
                s3Path: action.payload.s3Path,
                currency: {
                    code:  action.payload.propertyDetails.currency,
                    country: action.payload.propertyDetails.address ? action.payload.propertyDetails.address.country : 'Germany'
                },
                settings: action.payload.settings
            };
        
        case 'FETCH_RESERVATION_DETAILS_SUCCESS':
            return{
                ...state,
                accommodationList: action.payload.accommodationList,
                guests: action.payload.guests,
                reservationID: action.payload.reservationID,
                balance: action.payload.balance,
                taxAmount: action.payload.taxAmount,
                totalAmount: action.payload.totalAmount,
                checkIn: action.payload.checkIn,
                checkOut: action.payload.checkOut,
                accomodationLinkRequired: action.payload.accomodationLinkRequired,
                status: action.payload.status,
                maskedCards: [ ...action.payload.maskedCards ]
            }
        
        case 'UPDATE_GUEST_DETAILS_SUCCESS':
            return{
                ...state,
                guests: { 
                    ...state.guests,
                    [action.payload.guestID]: {
                        ...state.guests[action.payload.guestID],
                        ...action.payload.guestData
                    }
                }
            }

        case 'ADD_NEW_GUEST_SUCCESS':
            return{
                ...state,
                accommodationList: [...action.payload.accommodationList],
                guests: {...action.payload.guests}
            }

        case 'UPDATE_CUSTOMER_ACCOMODATION_SUCCESS' :
            return{
                ...state,
                accommodationList: [...action.payload.accommodationList],
                accomodationLinkRequired: false
            }

        case 'UPLOAD_DOCUMENT_SUCCESS':
            return{
                ...state,
                guests: { 
                    ...state.guests,
                    [action.payload.guestID]: {
                        ...state.guests[action.payload.guestID],
                        documents: [...action.payload.documents]
                    }
                }
            }

        case 'UPLOAD_PROFILE_PIC_SUCCESS':
            return{
                ...state,
                guests: { 
                    ...state.guests,
                    [action.payload.guestID]: {
                        ...state.guests[action.payload.guestID],
                        image: action.payload.image
                    }
                }
            }

        case 'SAVE_CHANNEX_TOKEN_SUCCESS':
            return{
                ...state,
                maskedCards: [ ...state.maskedCards, action.payload ]
            }

        case 'PAYMENT_SUCCESS':{
            return{
                ...state,
                accommodationList: [...action.payload.accommodationList],
                guests: {...action.payload.guests},
                reservationID: action.payload.reservationID,
                balance: action.payload.balance,
                taxAmount: action.payload.taxAmount,
                totalAmount: action.payload.totalAmount,
                checkIn: action.payload.checkIn,
                checkOut: action.payload.checkOut,
            }
        }

        default:
            return state;
    }
}