import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from 'clsx';

import { lockScreen } from "../../redux/actions/auth";
import { loadOneCashDrawer, loadTransactionReport } from "../../redux/actions/cashDrawer";
import { toggleSearchStatus } from "../../redux/actions/filters";
import { initials } from "../../utils/utility";
import { PAGES } from "../../utils/constants";

import CashReport from "../cash_drawer/CashReport";
import CashFlow from "../cash_drawer/CashFlow";
import CashDrawer from "../cash_drawer/CashDrawer";
import SearchBar from "./SearchBar";
import CashMenu from "./CashMenu";
import CashIn from '../cash_drawer/CashIn'

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import IMAGES from "../../constants/images";

const styles = theme => ({
  root: {
    backgroundColor: '#FFFFFF',
    width: '100%'
  },
  header: {
    backgroundColor: "#FFFFFF",
    boxShadow: "none",
    borderBottom: "none",
    color: "#484848",
    height: 70,
    marginLeft: 40,
    marginRight: 40,
    paddingTop: 10,
    paddingRight: "0px !important",
    transition: "all 0.15s linear",
    width: 'calc(100% - 80px)',
    [theme.breakpoints.down('xs')]: {
      height: 60,
      marginLeft: 10,
      marginRight: 10,
      width: 'calc(100% - 20px)',
    }
  },
  toolbar: {
    minHeight: 60,
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      minHeight: 50,
    }
  },
  iconButton: {
    padding: 3,
    cursor: 'default'
  },
  tab: {
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    cursor: 'pointer',
    display: "flex",
    alignItems: "center",
    height: 30,
    padding: '20px 30px',
    marginRight: 20,
    [theme.breakpoints.down('xs')]: {
      height: 20,
      marginLeft: 10,
      marginRight: 10,
      padding: '15px 15px',
    }
  },
  active: {
    background: '#F8F9FB',
    '& $tabIcon': {
      opacity: 1
    }
  },
  tabIcon: {
    height: 30,
    width: 30,
    opacity: 0.4,
    [theme.breakpoints.down('xs')]: {
      height: 20,
      width: 20,
    }
  },
  rightMenuContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  rightMenu: {
    alignItems: 'center',
    display: "flex",
    justifyContent: "flex-end",
    height: '100%',
  },
  iconContainer: {
    padding: 20,
    marginRight: 15,
    height: 30,
    [theme.breakpoints.down('xs')]: {
      height: 20,
      paddingRight: 15,
      paddingLeft: 15,
    }
  },
  icon: {
    width: 30,
    cursor: "pointer",
    height: 30,
    opacity: 1,
    [theme.breakpoints.down('xs')]: {
      height: 20,
      width: 20,
    }
  },
  inactiveSearch: {
    marginLeft: 'auto',
  },
  searchInput: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    height: 70,
    paddingRight: 15,
    [theme.breakpoints.down('xs')]: {
      height: 50
    }
  },
  inputFieldContainer: {
    alignItems: 'center',
    color: '#666666',
    fontSize: '1.6rem',
    flexGrow: 1,
    display: 'flex',
    height: 40,
  },
  closeIcon: {
    alignItems: 'center',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 10,
    height: 40, 
    width: 40,
    cursor: 'pointer',
    '&:hover': {
      background: '#ECECEC'
    }
  },
  avatar: {
    fontSize: 18,
    height: 40,
    margin: 2,
    width: 40,
    [theme.breakpoints.down('xs')]: {
      height: 30,
      width: 30,
    }
  },
  menuList: {
    '& ul': {
      padding: 0
    }
  },
  menuItem: {
    color: "#666666",
    fontSize: 15,
    fontWeight: 500,
    minWidth: "180px"
  },
  hide: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  }
});

const TABS_LIST = [
  { id: 'home', iconSrc: IMAGES.ICONS.home, path: '', disableClick: true },
  { id: 'calendar', iconSrc: IMAGES.ICONS.calendar, path: `/${PAGES.LANDING}`, disableClick: false },
  { id: 'housekeeping', iconSrc: IMAGES.ICONS.broom, path: `/${PAGES.HOUSEKEEPING}`, disableClick: false }
]

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cashDrawerDialogOpen: false,
      cashReportDialogOpen: false,
      cashInOutDialog: '',
      user: null,
      anchorEl: null,
      searchActive: false,
      cashMenuAnchorEl: null
    };
  }

  componentDidMount() {
    this.checkPage(this.props.history.location);
    this.handleFetchCashDrawerAccount();
  }

  componentWillUnmount() {
    // To reset react state update on unmounted component
    this.setState = (state, callback) => {
      return
    }
  }

  componentWillMount() {
    this.props.history.listen((location, action) => {
      this.checkPage(this.props.history.location);
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.cashDrawerFeatureEnabled){
      if (this.props.cashDrawerIsClosed && !_.isEqual(this.props.cashDrawerIsClosed, prevProps.cashDrawerIsClosed)) {
        this.checkPage(this.props.history.location);
      }
      if (this.props.openCashDrawer && !_.isEqual(this.props.openCashDrawer, prevProps.openCashDrawer)) {
        this.setState({ cashDrawerDialogOpen: false })
      }
      if (this.props.closeCashDrawer && !_.isEqual(this.props.closeCashDrawer, prevProps.closeCashDrawer)) {
        this.setState({cashReportDialogOpen: true, cashDrawerDialogOpen: false});
        if (this.props.closeCashDrawerID && this.props.closeCashDrawer[this.props.closeCashDrawerID]) {
          this.props.dispatch(loadTransactionReport(this.props.closeCashDrawer[this.props.closeCashDrawerID]._id))
        }
      }
      // if (this.props.transactionReport && !_.isEqual(this.props.transactionReport, prevProps.transactionReport)) {
      //   this.handleLock();
      // }
      if (!_.isEqual(this.props.cashDrawerFeatureEnabled, prevProps.cashDrawerFeatureEnabled)) {
        this.handleFetchCashDrawerAccount();
      }
    }
  }

  handleFetchCashDrawerAccount = () => {
    if (this.props.cashDrawerFeatureEnabled && this.props.auth && this.props.auth.unlockSuccess) {
      this.props.dispatch(loadOneCashDrawer(this.props.register.cashDrawerID))
    }
  }

  // handleMenu = event => {
  //   this.setState({ anchorEl: event.currentTarget });
  // };

  handleSettingsMenu = event => {
    this.setState({ settingsAnchorEl: event.currentTarget });
  };

  // handleClose = () => {
  //   this.setState({ anchorEl: null });
  // };

  handleOpenCashMenu = event => {
    this.setState({ cashMenuAnchorEl: event.currentTarget });
  };

  handleCloseCashMenu = event => {
    this.setState({ cashMenuAnchorEl: null });
  };

  handleSettingsClose = () => {
    this.setState({ settingsAnchorEl: null });
  };

  handleLock = () => {
    this.props.dispatch(lockScreen(this.props.propertyID, this.props.history));
    this.setState({ anchorEl: null });
  };

  handleCloseReport = () => {
    this.setState({ cashReportDialogOpen: false });
    this.handleLock();
  }

  handleEndShift = () => {
    this.setState({ cashDrawerDialogOpen: true });
    // this.handleClose();
  };

  handleCloseCashDrawer = () => {
    this.setState({ cashDrawerDialogOpen: false });
  }

  handleSearchToggle = () => {
    if (this.props.searchActive) {
      this.props.dispatch(toggleSearchStatus(false))
    } else {
      this.props.dispatch(toggleSearchStatus(true))
    }
  }
  
  checkPage = location => {
    let cashDrawerDialogOpen = false;
    let loadedPage = location && location.pathname ? location.pathname.slice(1) : null;
    let pageArr = loadedPage.split("/")
    loadedPage = pageArr.length ? pageArr[0] : loadedPage;

    if (location && location.pathname && location.pathname === `/${PAGES.LANDING}`) {
      if (this.props.cashDrawerFeatureEnabled && this.props.cashDrawerIsClosed && !this.props.cashDrawerDialogCloseValue)
        cashDrawerDialogOpen = true;
    }
    this.setState({ cashDrawerDialogOpen, loadedPage });
  };

  handleCashFlow = (value) => {
    this.setState({ cashInOutDialog: value });
    this.handleCloseCashMenu();
  }

  render() {
    const { classes, searchActive, cashDrawerFeatureEnabled, property, match, history, cashDrawerID } = this.props;
    const { anchorEl, cashMenuAnchorEl, cashDrawerDialogOpen, cashReportDialogOpen, cashInOutDialog } = this.state;
    const open = Boolean(anchorEl);
    const auth = { ...this.props.auth };
    const cashDrawerIsClosed = this.props.cashDrawerIsClosed;

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.header}>
          <Toolbar className={classes.toolbar}>

            {/* --------------------HEADER TABS--------------------------- */}
            {TABS_LIST.map(tab =>(
              <div 
                key={`tab_${tab.id}`}
                className={clsx(classes.tab, { 
                  [classes.active]: match.path == tab.path,
                  [classes.hide]: searchActive 
                })}
                onClick={() => !tab.disableClick && history.push(tab.path)}
              >
                <img className={classes.tabIcon} src={tab.iconSrc} />
              </div>
            ))}
                
            {/* INACTIVE SEARCH */}
            {/* ----------------------------------- */}
            <div 
              className={clsx({[classes.iconContainer]: true, [classes.inactiveSearch]: !searchActive})} 
              onClick={this.handleSearchToggle}
            >
              <img src={IMAGES.ICONS.search} className={classes.icon} />
            </div>

            {/* ACTIVE SEARCH */}
            {/* ----------------------------------- */}
            {searchActive && <div className={classes.searchInput}>
              <SearchBar />
            </div>}
            
            {/* CASH FUNCTION MENU */}
            {/* ----------------------------------- */}
            {this.props.cashDrawerFeatureEnabled && 
              <div className={clsx({[classes.iconContainer]: true, [classes.hide]: searchActive})} onClick={this.handleOpenCashMenu}>
                <img src={IMAGES.ICONS.apps} className={classes.icon} />
              </div>
            }

            {Boolean(cashMenuAnchorEl) && 
              <CashMenu 
                open={Boolean(cashMenuAnchorEl)} 
                anchorEl={cashMenuAnchorEl}
                handleClose={this.handleCloseCashMenu}
                onClickHandler={this.handleCashFlow}
                shiftHandler={this.handleEndShift}
                isCashDrawerClosed={cashDrawerIsClosed == "true"}
                cashDrawerID={cashDrawerID}
              /> 
            }

            {/* LOCK */}
            {/* ----------------------------------- */}
            <div className={clsx({[classes.iconContainer]: true, [classes.hide]: searchActive})} onClick={this.handleLock}>
              <img src={IMAGES.ICONS.lock} className={classes.icon} />
            </div>
            
            
            {/* USER PROFILE */}
            {/* ----------------------------------- */}
            <IconButton
              aria-owns={open ? "menu-appbar" : undefined}
              aria-haspopup="true"
              // onClick={this.handleMenu}
              color="inherit"
              className={clsx({[classes.iconButton]: true, [classes.hide]: searchActive})}
            >
              <Avatar className={classes.avatar}>{auth.isUnlocked &&
              initials(auth.unlockSuccess.firstName, auth.unlockSuccess.lastName)
              }
              </Avatar>
            </IconButton>

          </Toolbar>
        </AppBar>

        {cashDrawerFeatureEnabled && (
          <>
            {/* ------------------START/END SHIFT HANDLER MODAL------------------ */}
            {cashDrawerDialogOpen && (
              <CashDrawer 
                property={property}
                type={cashDrawerIsClosed ? "open" : "close"}
                handleCloseCashDrawer={this.handleCloseCashDrawer} 
                handleLock={this.handleLock}
              />
            )}

            {/* ------------------CASH REPORT-------------------------------- */}
            {cashReportDialogOpen && ( 
              <CashReport 
                property={this.props.property} 
                handleCloseReport={this.handleCloseReport} 
              />
            )}
            
            {/* -------------------CASH OUT MODAL---------------------------- */}
            {cashInOutDialog=='cashOut' && (
              <CashFlow 
                property={property} 
                handleCashFlow={this.handleCashFlow} 
              />
            )}

            {/* -------------------CASH IN MODAL---------------------------- */}
            {cashInOutDialog =='cashIn' && (
              <CashIn 
                property={this.props.property} 
                handleCashFlow={this.handleCashFlow} 
              />
            )}
          </>
        )}
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  let propertyID = state.register.propertyID;
  let cashDrawerID = state.register.cashDrawerID;
  let cashDrawerData = state.property.cashDrawer || {};
  let cashDrawerIsClosed = cashDrawerData[cashDrawerID] ? cashDrawerData[cashDrawerID].isClosed : null;
  let property = state.property.property && propertyID ? {...state.property.property[propertyID]} : {};
  property.features = property.features || [];
  let propertyfeatures = {...state.property.features}
  let cashDrawerFeatureEnabled = false;
  property.features.map(fID => {
    if(propertyfeatures[fID] && propertyfeatures[fID].name === "Cash Drawer" && propertyfeatures[fID].isEnabled === "true"){
      cashDrawerFeatureEnabled = true;
    }
  })

  return {
    auth: state.auth,
    cashDrawerFeatureEnabled: cashDrawerFeatureEnabled,
    propertyID: state.register.propertyID,
    property: property,
    register: state.register,
    openCashDrawer: state.cashDrawer.openCashDrawer,
    closeCashDrawer: state.cashDrawer.closeCashDrawer,
    closeCashDrawerID: state.cashDrawer.closeCashDrawerID,
    transactionReport: state.cashDrawer.transactionReport,
    cashDrawerDialogCloseValue: state.property.cashDrawerDialogCloseValue,
    cashDrawerIsClosed: cashDrawerIsClosed,
    searchActive: state.filters.searchActive || false,
    cashDrawerID
  }
};

export default withStyles(styles)(connect(mapStateToProps)(withRouter(Header)));
