import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { getAmtFormat } from '../../../utils/utility';
import ConfirmForm from '../../common/ConfirmForm';
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: '20px'
    },
    taxContainer:{
        marginBottom: '10px'
    },
    TaxItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: "1.4rem",
        color: '#666666',
        marginBottom: "5px",
    },
    taxInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    actionIcon: {
        width: "15px",
        height: "15px",
        marginLeft: "10px",
        cursor: "pointer",
    },
    taxes: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: "1.3rem",
        color: "#bdbdbd",
        marginBottom: "10px",
        '& .expand':{
            cursor: 'pointer'
        }
    }
}));

const TaxItem = props =>{
    const classes = useStyles();
    const { resTaxData, taxData, currency, handleDelete } = props;
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [selectedID, setSelectedID] = React.useState(null);

    const handleConfirmFormOpen = (id) => {
        setSelectedID(id);
        setConfirmOpen(true);
    }

    const handleConfirmFormClose = () => {
        setSelectedID(null);
        setConfirmOpen(false);
    }

    const handleConfirmDelete = () => {
        handleDelete(selectedID);
    }

    return (
        <div className={classes.taxContainer}>

            <div className={classes.TaxItem}>
                <div className={classes.taxInfo}>
                    <div>{taxData.name} ({taxData.calculationMode})</div>

                    {!selectedID && (
                        <img 
                            className={classes.actionIcon} 
                            src={IMAGES.ICONS.trash} 
                            onClick={() => handleConfirmFormOpen(resTaxData.taxID)} 
                        />
                    )}

                </div>
                <div>{getAmtFormat(resTaxData.amount, currency)}</div>
            </div>

            {selectedID == resTaxData.taxID && (
                <ConfirmForm
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onClose={handleConfirmFormClose}
                    onConfirm={handleConfirmDelete}
                >
                    Are you sure you would like to delete this tax?
                </ConfirmForm>
            )}
        </div>
    )
}

const TaxSummary = props => {

    const classes = useStyles();
    const { taxes, reservation, currency, handleDelete } = props;
    const [isTaxExpanded, setIsTaxExpanded] = React.useState(false);

    return (
        <>
            {/* ----------------TAX EXPAND HEAD-------------- */}
            {reservation.taxAmount && (
                <div className={classes.taxes}>

                    <div className="expand" onClick={() => setIsTaxExpanded(!isTaxExpanded)}>
                        <span> {isTaxExpanded ? '-' : '+'} Tax</span>
                    </div>

                    <div>{getAmtFormat(reservation.taxAmount || 0, currency)}</div>
                </div>
            )}

            {/* ----------------TAX COLLAPSABLE BODY-------------- */}
            {isTaxExpanded && (
                <div className={classes.root}>
                    {reservation?.taxesApplied.map((resTaxData, index) => {

                        const taxData = { ...taxes[resTaxData.taxID] };

                        return(
                            <TaxItem 
                                key={`tax_${resTaxData.taxID}`}
                                resTaxData={resTaxData} 
                                taxData={taxData} 
                                currency={currency} 
                                handleDelete={handleDelete}
                            />
                        )
                    })}

                    {reservation?.taxesApplied?.length == 0 && (
                        <div className={classes.taxContainer}>

                            {reservation.inclusiveTaxAmount && (
                                <div className={classes.TaxItem}>
                                    <div className={classes.taxInfo}>Inclusive Tax</div>
                                    <div>{getAmtFormat(reservation.inclusiveTaxAmount || 0, currency)}</div>
                                </div>
                            )}

                            {reservation.exclusiveTaxAmount && (
                                <div className={classes.TaxItem}>
                                    <div className={classes.taxInfo}>Exclusive Tax</div>
                                    <div>{getAmtFormat(reservation.exclusiveTaxAmount || 0, currency)}</div>
                                </div>
                            )}

                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default TaxSummary;