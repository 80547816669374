import React from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme =>({
    root:{
        marginBottom: '30px',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '20px',
            paddingLeft: '0px',
        }
    },
    itemInfo:{
        padding: '0px',
        paddingLeft: '10px',
        [theme.breakpoints.down('xs')]: {
            padding: '0px',
        }
    },
    imageContainer: {
        width: '100%',
        height: '120px',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    roomImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        borderRadius: '5px',
    },
    iconContainer:{
        fontSize: '15px',
        marginBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        '& img':{
            marginRight: '10px'
        }
    },
    addBtn:{
        borderRadius: '20px',
        backgroundColor: '#000',
        color: '#fff',
        padding: '10px 40px',
        '&:hover':{
            border: '0.5px solid #000',
            color: '#000',
        },
        [theme.breakpoints.down('sm')]:{
            width: '100%',
        }
    }
}));

const PaymentRoomItem = ({accommodationData}) =>{
    const classes = useStyles();
    const theme = useTheme();
    const deviceMD = useMediaQuery(theme.breakpoints.up('sm'));

    return(
        <Grid container className={classes.root} spacing={3}>
            <Grid item xs={12} sm={3} className={classes.imageContainer}>
                    <img 
                        alt={accommodationData.name} 
                        src={accommodationData.image}
                        className={classes.roomImage}
                    />
            </Grid>
            <Grid item xs={9} sm={7} className={classes.itemInfo}>
                <Box fontSize="15px" marginBottom="3px" >{accommodationData.name}</Box>
                <Box fontSize="12px" color="gray" mb={deviceMD ? 2 : 1}>{accommodationData.date}</Box>
                <Box fontSize="12px">{accommodationData.duration} night(s)</Box>
                <Box fontSize="12px">{accommodationData.guestCount} people</Box>
            </Grid>
            <Grid item xs={3} sm={2}>
                <Box fontSize="15px" textAlign="right" >{accommodationData.amount}</Box>
            </Grid>
        </Grid>
    );
}

export default PaymentRoomItem;