import React, { Component } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import UploadItem from "./UploadItem";
import { withTranslation } from "react-i18next";

import {
  getUploadUrl,
  uploadDoc,
  saveUploadData,
  showCheckinLoader,
  hideCheckinLoader,
  updateGuestDetails,
} from "../../../redux/actions/onlineCheckin";
import { GUEST_DOC_TYPES } from "../../../common/constants/guest";
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles((theme) => ({
  root: {
    // border: '1px solid'
  },
  error: {
    color: "#f44336",
    fontWeight: 400,
    fontSize: "12px",
  },
  bottom: {
    marginBottom: 20,
  },
  label: {
    fontSize: "1.2rem",
    color: "#666666",
    marginBottom: 18,
  },
}));

const UploadIdProof = (props) => {
  const { settings, guestData, s3Path, history, t } = props;
  const [doc, setDoc] = React.useState("");
  const [errorFile, setError] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    if (settings?.customerDocuments) {
      let docSettings = settings?.customerDocuments;
      if (docSettings?.document) {
        //Temp code to handle inconsistent document list previously created....................
        if (["visa", "passport", "nationalID"].includes(docSettings.document)) {
          const docType = GUEST_DOC_TYPES.find(
            (type) => type.id == docSettings.document
          );
          setDoc(docType ? docType.value : GUEST_DOC_TYPES[4].value);
          return;
        }

        setDoc(docSettings.document);
      }
    }
  }, [settings]);

  const uploadHandler = (files, customerID) => {
    const { dispatch, settings, docUploaded } = props;
    //this.setState({length: files.length, errorFile: false})
    // setLength(files.length)
    setError(false);

    const documentFile = files[0];
    const fileParts = documentFile.name.split(".");
    const fileName = fileParts[0];
    const fileType = fileParts[1];
    const type = "doc";
    const data = {
      name: fileName,
      type: fileType,
    };
    dispatch(showCheckinLoader());
    const response = getUploadUrl(type, data, customerID);
    response
      .then(({ data }) => {
        const key = data.key;
        const uploadData = {
          key,
          file: documentFile,
          type: fileType,
        };

        //Get upload to storage url........
        const uploadResponse = uploadDoc(data.url, uploadData);
        //Upload to storage.....
        uploadResponse
          .then((res) => {
            //Save uploaded details....
            dispatch(saveUploadData(type, key, doc, customerID));
            props.docUploaded(true);
          })
          .catch((error) => {
            dispatch(hideCheckinLoader());
            console.log("upload error ", error);
          });
      })
      .catch((errorResult) => {
        dispatch(hideCheckinLoader());
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        console.log("get upload url ", error);
      });
  };

  const handleDelete = (data) => {
    const { dispatch } = props;

    dispatch(showCheckinLoader());
    const response = dispatch(updateGuestDetails(data));
    response.then((success) => {
      props.docUploaded(false);
    });
  };

  return (
    <>
      <Box className={classes.root}>
        <div className={classes.label}>
          Document {doc.length > 0 ? `(${doc})` : ""}
        </div>
        <div>
          <UploadItem
            guestIndex={1}
            guestData={guestData}
            uploadHandler={uploadHandler}
            handleDelete={handleDelete}
            docBaseUrl={s3Path}
            t={t}
          />
          {errorFile && (
            <p className={classes.error}>{t("onlineCheckin.doc.idRequired")}</p>
          )}
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    s3Path: state.onlineCheckin.s3Path,
    settings: state.onlineCheckin.settings,
  };
};

export default withTranslation()(connect(mapStateToProps)(UploadIdProof));
