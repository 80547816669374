import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import moment from 'moment';

import PersonalInfoCard from './PersonalInfoCard';
import RoomInfoCard from './RoomInfoCard';
import AddGuest from './AddGuest';
import UserInfo from './UserInfo';
import Footer from '../Footer';

import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import AddIcon from '@material-ui/icons/Add';

import { fetchDateFormat } from '../../../utils/utility';
import { 
    updateCustomerAccomodation, 
    updateGuestDetails, 
    addNewGuest 
} from '../../../redux/actions/onlineCheckin';
import { withTranslation } from 'react-i18next';

const styles = theme =>({
    root: {
        margin: '0 auto',
        width: '75%',
        maxWidth: 500,
        paddingTop: '60px',
        [theme.breakpoints.down('sm')]: {
           width: '100%',
           maxWidth: 'auto',
        },
    },
    mainContent: {
        float: 'left', 
        display:'inline-block',
        width: '90%'
    },
    roomBox: {
        borderBottom: '1px solid #E0E0E0',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 10,
        marginTop: 30,
    },
    room: {
        color: '#7B7B7B',
        fontSize: '1.4rem',
        paddingBottom: 10,
    },
    nights: {
        color: '#7B7B7B',
        fontSize: '1.4rem',
        marginTop: 20,
        marginBottom: 5,
        width: '100%',
    },
    dateBox: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: 30,
        width: '100%',
    },
    guestSummary: {
        marginBottom: 20
    },
    date: {
        alignItems: 'center',
        color: '#7B7B7B',
        display: 'flex',
        fontSize: '1.4rem',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 8,
        marginRight: 8,
        '& svg': {
            opacity: 0.5,
            fontSize: '2.5rem'
        },
    },
    addBox: {
        alignItems: 'center',
        border: '1px dashed #BDBDBD',
        color: '#7B7B7B',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.4rem',
        justifyContent: 'center',
        padding: 20,
        width: 'calc(100% - 42px)',
    },
    addIcon: {
        color: '#7B7B7B',
        fontSize: '1.6rem',
        marginRight: 10,
    },
    day: {
        color: '#999999',
        marginTop: 5,
        fontSize: '1.3rem',
    },
    personalInfoSection:{
        marginTop: '20px',
    },
    addGuestSection:{
        marginBottom: '20px',
    },
    title:{
        color: '#333333',
        fontSize: '2.4rem',
        marginBottom: 40,
    },
    errorMessage: {
        color: "#f44336",
        fontSize: '12px'
    },
    errorSection: {
        marginTop: 20
    },
    backIcon: {
        color: '#000000',
        cursor: 'pointer',
        fontSize: '18px',
        width: 40
    }
});

function validateCustomerFields(mand, guest) {

    let success = true;

    if(mand === 'name'){
        if(!guest['firstName'] || !guest['lastName'] || (guest['firstName'] && guest['firstName'].length === 0) || (guest['lastName'] && guest['lastName'].length === 0))
            success = false;
    }
    
    if(mand === 'phone') {
       if(!guest['phone'] || guest['phone'] && guest['phone'].length === 0)
            success = false;
    }
    
    if (mand === 'email') {
        if(!guest['email'] || guest['email'] && guest['email'].length === 0) {
            success = false;
        }
        else if(guest['email'].length > 0) {
            const emailRegex = /^[A-Za-z0-9._%+-]+@(guest.booking.com|m.expediapartnercentral.com)$/;
            if(emailRegex.test(guest['email']))
                success = false;
        }
    }
    
    if(mand === 'address') {
        if(!guest['address'] || !guest?.['address']?.['lineOne'] || !guest?.['address']?.['lineOne']?.trim())
            success = false;
    }

    if(mand === 'city' || mand === 'country') {
        if(guest['address']) {
            if(guest['address'][mand]){
                if(guest['address'][mand].length === 0)
                    success = false;
            }
            else
                success = false;
        }
        else {
            success = false;
        }
    }

    // if(mand === 'photo') {
    //     if(!guest['image'])
    //         success = false;
    // }

    if(mand === 'document') {
        if(!guest['documents'] || guest['documents'].length === 0)
            success = false;
    }

    if(mand === 'documentNumber' && !guest['documents']?.[0]?.number){
        success = false;
    }

    if(mand === 'documentExpiry' && !guest['documents']?.[0]?.expiry){
        success = false;
    }

    if(mand === 'documentIssuingCountry' && !guest['documents']?.[0]?.issuingCountry){
        success = false;
    }

    return success;
}

function getMandatoryFields(settings) {
    
    let mandatory = [];

    if(settings?.customerDetails) {
        const custSetting = settings.customerDetails
        Object.keys(custSetting).map((det) => {
            if(custSetting[det] === 'mandatory')
                mandatory.push(det)
        })
    }

    if(settings?.customerDocuments && !settings?.customerDocuments.skip) {
        const docSettings = settings.customerDocuments;

        if(docSettings?.documentRequired){
            mandatory.push('document')
        }

        ['documentNumber', 'documentExpiry', 'documentIssuingCountry'].forEach(field =>{
            if(docSettings?.[field] == 'mandatory') mandatory.push(field);
        });
    }

    return mandatory;
}

const PersonalInformation = props => {
    const { accomodationLinkRequired, accommodationList, dispatch, reservationID, 
            settings, guests, nextStep, classes, roomTypes, checkIn, checkOut, t, history } = props

    const [next, setNext] = React.useState(true);
    const [edit, setEdit] = React.useState({});
    const [roomTypeList, setRoomTypeList] = React.useState([])

    React.useEffect(() => {
        const editFlag = { ...edit }
        let array = [];
        accommodationList.forEach((accList) => {
            const index = array.findIndex(e => e.roomTypeID == accList.roomTypeID);
            if (index == -1 ) {
                array.push({roomTypeID: accList.roomTypeID, count: 1})
            } else {
                let count = array[index] && array[index].count;
                array[index] = {roomTypeID: accList.roomTypeID, count: count + 1}
            }   
            accList.customerID.forEach((custID) => {
                if(!editFlag[custID])
                    editFlag[custID] = false
            })
        });
        setRoomTypeList([...array]);
        setEdit(editFlag)
    }, [accommodationList])

    React.useEffect(() => {

        const editFlag = { ...edit };

        Object.keys(guests).map(customerID => {
            const success = validateUserData(customerID);
            editFlag[customerID] = !success;
        });

        setEdit(editFlag);
    }, [settings]);

    const addNewGuestSubmit = data =>{
        if(accomodationLinkRequired){
            
           //Create accomodation data ..............
            const accommodationData = {};
            accommodationList.map(accommodation =>{
                accommodationData[accommodation._id] = [...accommodation.customerID];
            });

            //Update accomodation ............
            const response = updateAccommodation(accommodationData);
            response.then(success=>{
                if(success){
                    //Add new guest..............
                    addGuestHandler(data);
                }
            });

            return;
        }

        //Add new guest..............
        addGuestHandler(data)
    }

    const checkForChange = () => {
        if(!next)
            setNext(true)
    }

    const addGuestHandler = data =>{
        //Add new guest.....
        const response = dispatch(addNewGuest(
            {...data.formData}, 
            reservationID, 
            data.accommodationID)
        );
        response.then(success=>{
            if(success) data.toggleMode();
        });
    }

    const saveGuestDetails = data =>{
        const updateData = {
            ...data.formData,
            _id: data.guestID
        };

        const response = dispatch(updateGuestDetails(updateData));
        response.then(success=>{
            if(success && data.toggleMode) {
                data.toggleMode();
                changeEdit(data.guestID)
            }
        });
    }

    

    const validateUserData = (customerID) => {
        let success = true;
        const mandatory = getMandatoryFields(settings);

        const guest = guests[customerID];
        for (let i = 0; i < mandatory.length; i++) {
            const mand = mandatory[i]
            success = validateCustomerFields(mand, guest);
            if (!success) break;
        }
        return success;
    }

    const validateonProceed =()=>{
        return Object.keys(guests).every(customerID => validateUserData(customerID))        
    }

    
    const onProceed = () =>{
        const success = validateonProceed();
        if(success) {
            const data = {};
            accommodationList.map(accommodation =>{
                data[accommodation._id]= [...accommodation.customerID];
            });
            const response = updateAccommodation(data);
            response.then(success=>{
                if(success) nextStep();
            });
        }
        else {
            setNext(false)
        }
    }

    const updateAccommodation = (data) =>{
        const response = dispatch(updateCustomerAccomodation(reservationID, data));
        return response;
    }

    const fetchAccommodationMenuList = (accommodationID) =>{
        const list = accommodationList.filter(data => data._id != accommodationID);
        const menuList = list.map(data =>{
            return{
                title: `Move To ${roomTypes[data.roomTypeID].name}`,
                id: data._id
            }
        });
        return menuList;
    }

    const changeEdit = (custID) => {
        const editFlag = { ...edit }

        Object.keys(editFlag).map(id => {
            if(id === custID)
                editFlag[id] = !editFlag[custID]
            else
                editFlag[id] = false
        })
        
        setEdit(editFlag)
        setNext(true)
    }

    return(
        <>
            <Box className={classes.root}>

                <Box className={classes.personalInfo}>
                    <div className={classes.title}>
                        Here are your booking details
                    </div>
                </Box>

                <div className={classes.roomBox}>
                    {roomTypeList.length >0 && roomTypeList.map(item => 
                        <div key={item.roomTypeID} className={classes.room}>{item.count} x {roomTypes[item.roomTypeID].name}</div>
                    )}
                </div>

                <div className={classes.nights}>
                    {moment(checkOut).diff(moment(checkIn), 'days')} nights
                </div>

                <div className={classes.dateBox}>
                    <div className={classes.date}>
                        <div>{fetchDateFormat(checkIn, 'ddd, MMM D')}</div>
                    </div>
                    <div className={classes.icon}>
                        <RightIcon />
                    </div>
                    <div className={classes.date}>
                        <div>{fetchDateFormat(checkOut, 'ddd, MMM D')}</div>
                    </div>
                </div>

                {accommodationList.map((accommodation, accmIndx) =>(
                    <Box mt={8} key={`accommodation_${accommodation._id}`} className={classes.personalInfoSection}>
                        {accommodation.customerID && accommodation.customerID.map((customerID,index) =>(
                            <div key={`userInfo_${accommodation._id}_${customerID}`}>
                                {!edit[customerID] && <UserInfo 
                                    guestData={guests[customerID]} 
                                    t={t} 
                                    customerID={customerID}
                                    changeEdit={changeEdit}
                                    validateGuest={validateUserData}
                                />}
                                {edit[customerID] && <PersonalInfoCard 
                                    key={`guest_${customerID}`}
                                    guestData={guests[customerID]}
                                    settings={settings}
                                    accommodationID={accommodation._id}
                                    submitHandler={saveGuestDetails}
                                    accommodationMenuList = {fetchAccommodationMenuList(accommodation._id)}
                                    updateAccommodation = {updateAccommodation}
                                    checkForChange={checkForChange}
                                    t = {t}
                                    customerID={customerID}
                                    changeEdit={changeEdit}
                                />}
                            </div>
                        ))}
                    </Box>
                ))}

                <AddGuest
                    className={classes.addGuestSection}
                    settings={settings}
                    accommodationID={1}
                    submitHandler={addNewGuestSubmit}
                    checkForChange={checkForChange}
                    t = {t}
                />
                {!next && <div className={classes.errorSection}>
                    <span className={classes.errorMessage}>Please complete guest profiles to proceed.</span>
                </div>}
            </Box>
            
            <Footer 
                buttonLabel={t('actions.proceed')}
                onProceed = {onProceed}
            />
        </>
    )
}

const mapStateToProps = state =>{
    return{
        accommodationList: state.onlineCheckin.accommodationList,
        checkIn: state.onlineCheckin.checkIn,
        checkOut: state.onlineCheckin.checkOut,
        guests: state.onlineCheckin.guests,
        propertyDetails:  state.onlineCheckin.propertyDetails,
        roomTypes: state.onlineCheckin.roomTypes,
        reservationID: state.onlineCheckin.reservationID,
        accomodationLinkRequired: state.onlineCheckin.accomodationLinkRequired,
        settings: state.onlineCheckin.settings,
        isKioskFlow: Boolean(state.kiosk.mode)
    }
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(PersonalInformation)));