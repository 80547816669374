import React from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from './PaymentForm';

const key = 'pk_test_51Gs6odJ8RJXoqPTxETj5uYGS3lmCCBe7Tmy6OdyLN2n0WHoLAfLp2X9IZsYJvtcnc9X8fyewiOh0UrnbdQdxmdBw00F8rbs7y5';
const stripePromise = loadStripe(key);

const StripeElementProvider = props =>{
    return(
        <Elements stripe={stripePromise}>
            <PaymentForm
                nextStep = {props.nextStep}
            />
        </Elements>
    )
}

export default StripeElementProvider;