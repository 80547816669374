import React from "react";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "../redux/utils/setAuthToken";
import { logoutUser } from "../redux/actions/auth";
import Header from "../components/header/Header";
import PrivateRoute from "../components/private-route/PrivateRoute";
import { AppRoute } from "../components/private-route/AppRoute";
import DashboardContainer from "../containers/DashboardContainer";
// import ReservationPage from "../components/reservation/ReservationPage";
import LoginPage from "../components/auth/LoginPage";
import ConfigureRegister from "../components/auth/ConfigureRegister";
import LockScreen from "../components/auth/LockScreen";
import BookingEnginePage from "../containers/BookingEnginePage";
import OnlineCheckin from "../containers/OnlineCheckin";
import KioskPage from "../containers/KioskPage";
import GoogleHotelBookingPage from "../containers/GoogleHotelBookingPage";
import { MainLayout } from "../components/layout/RouteLayout";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { PAGES } from "../utils/constants";
import { checkIfFrontdeskPortal } from "../utils/utility";

import ReservationPage from '../containers/ReservationPage';

import DemoBookingEnginePage from "../components/demo/booking_engine";
import DemoOnlineCheckin from "../components/demo/online_checkin";
import ProductOrderPage from "../components/pos/ProductOrderPage";

import InSessionRoutes from "../components/private-route/InSessionRoutes";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#ff8e8c",
      main: "#484848",
      dark: "#000000",
      contrastText: "#fff"
    },
    secondary: {
      light: "#008489",
      main: "#999999", // light grey
      dark: "#004e5a",
      contrastText: "#000"
    }
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    htmlFontSize: 10,
    textTransform: "none",
    color: "#484848",
    button: {
      textTransform: "none"
    },
    subtitle1: {
      fontWeight: 600,
      color: "#484848",
      fontSize: 14
    },
    body1: {
      fontSize: '1.4rem'
    },
    h1: {
      color: "#000000",
      fontSize: "4rem",
      fontWeight: 600,
    },
    h2: {
      fontSize: "3rem",
      fontWeight: 500
    },
    h3: {
      fontSize: "2.5rem",
      fontWeight: 500
    },
    h4: {
      fontSize: 18,
      fontWeight: 500,
      color: "#444444"
    },
    h5: {
      fontSize: 16,
      fontWeight: 500,
      color: "#444444"
    },
    h6: {
      fontSize: 14,
      fontWeight: 500,
      color: "#484848"
    }
  }
});

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  let token;
  localStorage.sessionToken
    ? (token = localStorage.sessionToken)
    : (token = localStorage.jwtToken);
  setAuthToken(token);

  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser()); // Redirect to login
    window.location.href = "./"+PAGES.LOGIN;
  }
}

class AppRouter extends React.Component {
  state = {
    showHeader: true
  }

  componentDidMount(){
    const isFrontdeskPortal = checkIfFrontdeskPortal();
  
    if(!isFrontdeskPortal){
      this.setState({ showHeader: false });
      return;
    }

    //Adding document title and website icon for frontdesk ( not to be included for booking engine )...
    document.title = 'Counter Frontdesk';
    let link = document.querySelector("link[rel*='icon']");
    link.href = '/images/favicon.png';
  }

  render() {
    return (
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <Switch>

            {/* <PrivateRoute exact layout={props => <div className="main">{props.children}</div>} path={`/:propcode/reservation/:resvcode`} component={ReservationPage} /> */}

            {/* <AppRoute exact layout={MainLayout} path={`/${PAGES.LANDING}`} component={DashboardContainer} exact={true} /> */}
            <AppRoute exact layout={MainLayout} path={`/${PAGES.BOOKING_ENGINE}/:id`} component={BookingEnginePage} exact={true} />
            <AppRoute exact layout={MainLayout} path={`/${PAGES.GOOGLE_HOTEL}`} component={GoogleHotelBookingPage} exact={true} />
            <AppRoute layout={MainLayout} path={`/${PAGES.CHECKIN}/:id`} component={OnlineCheckin} />
            <AppRoute layout={MainLayout} path={`/${PAGES.POS}`} component={ProductOrderPage} />

            <AppRoute layout={MainLayout} path={`/${PAGES.KIOSK}/:id`} component={KioskPage} />
            
            <AppRoute exact layout={MainLayout} path={`/demo-booking`} component={DemoBookingEnginePage} exact={true} />
            <AppRoute layout={MainLayout} path={`/demo-checkin`} component={DemoOnlineCheckin} />

            <AppRoute layout={MainLayout} path={`/${PAGES.LOCK}`} component={LockScreen} />
            <AppRoute layout={MainLayout} path={`/${PAGES.SETUP}`} component={ConfigureRegister} />
            <AppRoute layout={MainLayout} path={`/${PAGES.LOGIN}`} component={LoginPage} />
            
            <Route path="/">
              <InSessionRoutes />
            </Route>
            
            {/* Rediect to login page if route not found, login component will further
              check to redirect to proper pages as per the current session */}
            {/* <PrivateRoute layout={MainLayout} path="*" component={LoginPage}/> */}

          </Switch>
        </MuiThemeProvider>
      </BrowserRouter>
    );
  }
}

export default AppRouter;
