import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import InputBase from "@material-ui/core/InputBase";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import get from "lodash/get";

import CustomInput from "../common/CustomInput";
import UploadDocs from "./UploadDocs";
import { GENDER_OPTIONS as genderOptions } from "../../../utils/constants";
import { GUEST_DOC_TYPES as documentTypeOptions } from "../../../common/constants/guest";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: "1px solid #c3c3c3",
    marginTop: "20px",
    marginBottom: "25px",
    "&.spaceTop": {
      paddingTop: "25px",
    },
  },
  textInput: {
    width: "100%",
    height: "45px",
  },
  showMoreContainer: {
    // marginBottom: '10px',
    "& button": {
      "&:hover": {
        background: "#FFFFFF",
      },
    },
  },
  showMore: {
    "&.hide": {
      display: "none",
    },
  },
  formHeader: {
    display: "flex",
    boxSizing: "border-box",
    justifyContent: "flex-end",
    paddingTop: "10px",

    "& .iconBtn": {
      padding: "5px",
    },

    "& .closeIcon": {
      fontSize: "2.2rem",
      color: "#4f4f4f",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "15px 20px",
    },
  },
}));

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: "none",
    boxShadow: 0,
    cursor: "pointer",
    margin: "0px",
    height: "45px",
    "&:hover": { border: "none" },
  }),
  indicatorSeparator: (base, state) => ({
    display: "none",
  }),
};

const GuestForm = (props) => {
  const classes = useStyles();
  const {
    keyID,
    data = {},
    countryOptions = [],
    onChangeHandler,
    formErrors,
    timeStamp,
    guestIndex,
    onDeleteHandler,
    inputProps,
    fileSelectHandler,
    todaysDate,
  } = props;

  const [showMore, setShowMore] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [editAll, setEditAll] = useState(false);

  useEffect(() => {
    setIsCreateMode(Boolean(!data._id));
  }, [data]);

  const onEditClick = () => {
    setEditAll(true);
  };

  const showMoreHandler = () => {
    setShowMore((prevState) => !prevState);
  };

  const onTextInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const inputData = { key: name, value, guestIndex };
    onChangeHandler(inputData);
  };

  const onSelectHandler = (key, value) => {
    const inputData = { key, value, guestIndex };
    onChangeHandler(inputData);
  };

  const removeFormHandler = () => {
    onDeleteHandler(guestIndex);
  };

  const commonInputProps = {
    editable: true,
    onEditClick: onEditClick,
    presetEdit: editAll,
    resetEdit: timeStamp,
    createMode: isCreateMode,
  };

  return (
    <div className={clsx(classes.root, { spaceTop: !isCreateMode })}>
      {isCreateMode && guestIndex !== 0 && (
        <div className={classes.formHeader}>
          <IconButton className="iconBtn" onClick={removeFormHandler}>
            <CloseIcon className="closeIcon" />
          </IconButton>
        </div>
      )}

      {/* --------------FIRST NAME------------------- */}
      <CustomInput
        label="First Name"
        value={data.firstName}
        error={formErrors.firstName}
        {...commonInputProps}
      >
        <InputBase
          className={classes.textInput}
          name="firstName"
          value={data.firstName}
          onChange={onTextInputChange}
          {...inputProps}
        />
      </CustomInput>

      {/* --------------LAST NAME------------------- */}
      <CustomInput
        label="Last Name"
        value={data.lastName}
        error={formErrors.lastName}
        {...commonInputProps}
      >
        <InputBase
          className={classes.textInput}
          name="lastName"
          value={data.lastName}
          onChange={onTextInputChange}
          {...inputProps}
        />
      </CustomInput>

      {/* --------------EMAIL ADDRESS------------------- */}
      <CustomInput
        label="Email Address"
        value={data.email}
        {...commonInputProps}
      >
        <InputBase
          type="email"
          className={classes.textInput}
          name="email"
          value={data.email}
          onChange={onTextInputChange}
          {...inputProps}
        />
      </CustomInput>

      {/* --------------NATIONALITY------------------- */}
      <CustomInput
        label="Nationality"
        value={countryOptions.find((e) => e.value === data.nationality)?.label}
        {...commonInputProps}
      >
        <Select
          styles={selectStyle}
          options={countryOptions}
          value={countryOptions.find((e) => e.value === data.nationality)}
          onChange={(option) => onSelectHandler("nationality", option.value)}
          menuPlacement="top"
          {...inputProps}
        />
      </CustomInput>

      <div className={classes.showMoreContainer}>
        <Button
          startIcon={showMore ? <RemoveRoundedIcon /> : <AddRoundedIcon />}
          onClick={showMoreHandler}
          disableRipple
        >
          {showMore ? "Less details" : "More details"}
        </Button>
      </div>

      <div className={clsx(classes.showMore, { hide: !showMore })}>
        {/* --------------Phone Number------------------- */}
        <CustomInput
          label="Phone Number"
          value={data.phone}
          {...commonInputProps}
        >
          <InputBase
            className={classes.textInput}
            type="tel"
            name="phone"
            value={data.phone}
            onChange={onTextInputChange}
            {...inputProps}
          />
        </CustomInput>

        {/* --------------DATE OF BIRTH------------------- */}
        <CustomInput
          label="Date of Birth"
          value={data.dateOfBirth}
          error={formErrors.dateOfBirth}
          {...commonInputProps}
        >
          <InputBase
            type="date"
            className={classes.textInput}
            defaultValue={data.dateOfBirth}
            onChange={(e) => onSelectHandler("dateOfBirth", e.target.value)}
            inputProps={{ max: todaysDate }}
            {...inputProps}
          />
        </CustomInput>

        {/* --------------GENDER------------------- */}
        <CustomInput
          label="Gender"
          value={genderOptions.find((e) => e.value === data.gender)?.label}
          {...commonInputProps}
        >
          <Select
            styles={selectStyle}
            options={genderOptions}
            value={genderOptions.find((e) => e.value === data.gender)}
            onChange={(option) => onSelectHandler("gender", option.value)}
            {...inputProps}
          />
        </CustomInput>

        {/* {data.documents.length > 0 && ( */}
        <>
          {/* --------------DOCUMENT TYPE------------------- */}
          <CustomInput
            label="Document Type"
            value={
              documentTypeOptions.find(
                (e) => e.value === get(data, "documents[0].type")
              )?.label
            }
            {...commonInputProps}
          >
            <Select
              styles={selectStyle}
              options={documentTypeOptions}
              value={documentTypeOptions.find(
                (e) => e.value === get(data, "documents[0].type")
              )}
              onChange={(option) =>
                onSelectHandler("documents[0].type", option.value)
              }
              {...inputProps}
            />
          </CustomInput>

          {/* --------------DOCUMENT NUMBER------------------- */}
          <CustomInput
            label="Document Number"
            value={get(data, "documents[0].number", "")}
            {...commonInputProps}
          >
            <InputBase
              className={classes.textInput}
              value={get(data, "documents[0].number", "")}
              onChange={(e) =>
                onSelectHandler("documents[0].number", e.target.value)
              }
              {...inputProps}
            />
          </CustomInput>

          {/* --------------DOCUMENT ISSUING COUNTRY------------------- */}
          <CustomInput
            label="Country of issue of the document"
            value={
              countryOptions.find(
                (e) => e.value === get(data, "documents[0].issuingCountry")
              )?.label
            }
            {...commonInputProps}
          >
            <Select
              styles={selectStyle}
              options={countryOptions}
              value={countryOptions.find(
                (e) => e.value === get(data, "documents[0].issuingCountry")
              )}
              menuPlacement="top"
              onChange={(option) =>
                onSelectHandler("documents[0].issuingCountry", option.value)
              }
            />
          </CustomInput>

          {/* --------------DOCUMENT EXPIRY------------------- */}
          <CustomInput
            label="Document expiry"
            value={get(data, "documents[0].expiry", "")}
            {...commonInputProps}
          >
            <InputBase
              className={classes.textInput}
              type="date"
              defaultValue={get(data, "documents[0].expiry", "")}
              onChange={(e) =>
                onChangeHandler({
                  key: "documents[0].expiry",
                  value: e.target.value,
                  guestIndex,
                })
              }
              inputProps={{ min: todaysDate }}
            />
          </CustomInput>
        </>
        {/* )} */}

        {/* --------------COMPANY NAME------------------- */}
        <CustomInput
          label="Company Name"
          value={data.companyName}
          {...commonInputProps}
        >
          <InputBase
            className={classes.textInput}
            name="companyName"
            value={data.companyName}
            onChange={onTextInputChange}
            {...inputProps}
          />
        </CustomInput>

        {/* --------------TAX ID------------------- */}
        <CustomInput label="Tax ID" value={data.taxID} {...commonInputProps}>
          <InputBase
            className={classes.textInput}
            name="taxID"
            value={data.taxID}
            onChange={onTextInputChange}
            {...inputProps}
          />
        </CustomInput>

        {/* --------------ADDRESS------------------- */}
        <CustomInput
          label="Address"
          value={data.address.lineOne}
          {...commonInputProps}
        >
          <InputBase
            className={classes.textInput}
            name="address.lineOne"
            value={data.address.lineOne}
            onChange={onTextInputChange}
            {...inputProps}
          />
        </CustomInput>

        {/* --------------CITY------------------- */}
        <CustomInput
          label="City"
          value={data.address.city}
          {...commonInputProps}
        >
          <InputBase
            className={classes.textInput}
            name="address.city"
            value={data.address.city}
            onChange={onTextInputChange}
            {...inputProps}
          />
        </CustomInput>

        {/* --------------STATE------------------- */}
        <CustomInput
          label="State"
          value={data.address.state}
          {...commonInputProps}
        >
          <InputBase
            className={classes.textInput}
            name="address.state"
            value={data.address.state}
            onChange={onTextInputChange}
            {...inputProps}
          />
        </CustomInput>

        {/* --------------ZIPCODE------------------- */}
        <CustomInput
          label="Zipcode"
          value={data.address.zipCode}
          {...commonInputProps}
        >
          <InputBase
            className={classes.textInput}
            name="address.zipCode"
            value={data.address.zipCode}
            onChange={onTextInputChange}
            {...inputProps}
          />
        </CustomInput>

        {/* --------------COUNTRY------------------- */}
        <CustomInput
          label="Country"
          value={
            countryOptions.find((e) => e.value === data.address.country)?.label
          }
          {...commonInputProps}
        >
          <Select
            styles={selectStyle}
            options={countryOptions}
            value={countryOptions.find((e) => e.value === data.address.country)}
            menuPlacement="top"
            onChange={(option) =>
              onSelectHandler("address.country", option.value)
            }
            {...inputProps}
          />
        </CustomInput>
      </div>

      {data.firstName && data.lastName && (
        <UploadDocs
          keyID={`upload_${keyID}`}
          guestID={data._id}
          documents={data.documents}
          onFileSelect={fileSelectHandler}
        />
      )}
    </div>
  );
};

export default GuestForm;
