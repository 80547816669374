import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import ReadMore from '../readMore';
import AddBedInput from "../addBedInput";

const secondaryColor = '#5C5C5C';

const useStyles = makeStyles(theme => ({
    mainContentCard: {
        borderRadius: '20px',
        padding: '20px',
        boxShadow: '0 2px 20px 0px #F0F0F0',
        marginBottom: "20px"
    },

    roomImageHolder: {
        height: '160px',
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            height: '130px',
        },
    },

    roomImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        borderRadius: '20px'
    },

    roomName: {
        marginBottom: '10px',
    },
    roomTypeDesc: {
        fontWeight: 'bold',
        color: secondaryColor,
        fontSize: '16px',
        marginBottom: '20px'
    },
    roomDesc: {
        marginBottom: '10px',
        fontSize: '14px',
        color: secondaryColor
    }
}));

const BookingCartItem = ({
    updateBedCountHandler,
    noOfBedsAvailable,
    noOfBedsBooked,
    roomData,
    roomTypeID,
    addBedInputRenderer
}) => {
    const classes = useStyles();
    return (
        <Card className={classes.mainContentCard}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <div className={classes.roomImageHolder}>
                        <img 
                            className={classes.roomImage} 
                            src={
                                roomData.images.length > 0 ? 
                                    roomData.images[roomData.images.length - 1 ] : 
                                    "/images/dorm2.jpg"
                                }
                            alt="room" 
                        />
                    </div>
                </Grid>

                <Grid item xs={12} sm={5}>
                    <Typography className={classes.roomInfoHolder} component="div">
                        <Box variant="h6" component="h6" className={`${classes.roomName}`} m={0} fontWeight="bold" fontSize="18px">
                            {roomData.name}
                        </Box>

                        {/* <Box component="div" className={classes.roomDesc}>
                            <Box fontSize="13px" lineHeight={1}>
                                Ensuit Bathroom
                            </Box>
                            <Box fontSize="13x" lineHeight={1}>
                                Bed in shared 6-bed-dorm
                            </Box>
                        </Box> */}
                        { roomData.description && (
                            <ReadMore data={roomData.description}/>
                        )}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={3}>
                    <AddBedInput 
                        id = {roomTypeID}
                        onChangeHandler = {updateBedCountHandler}
                        maxValue = {noOfBedsAvailable}
                        value = {noOfBedsBooked}
                        renderHandler = {addBedInputRenderer}
                    />
                </Grid>
            </Grid>
        </Card>
    );
}
export default BookingCartItem;