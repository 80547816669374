import { BED_STATUSES } from '../../utils/constants';

const FILTERS = {
    buildingID: 'buildingID',
    floor: 'floor',
    sort: 'sort',
    roomID: 'roomID',
    roomTypeID: 'roomTypeID',
    statuses: 'statuses'
}
  
const INIT_FILTERS = {
    [FILTERS.buildingID]: '',
    [FILTERS.floor]: '',
    [FILTERS.sort]: '',
    [FILTERS.roomID]: '',
    [FILTERS.roomTypeID]: '',
    [FILTERS.statuses]: []
}

const ROOM_STATUS_OPTIONS = [
    { label: 'Dirty', value: BED_STATUSES.unclean },
    { label: 'Clean', value: BED_STATUSES.ready },
    { label: 'Occupied', value: BED_STATUSES.occupied }
];

const SORT_OPTIONS = [
    { label: 'A-Z', value: 'a-z' },
    { label: 'Z-A', value: 'z-a' },
];

export {
    FILTERS,
    INIT_FILTERS,
    ROOM_STATUS_OPTIONS,
    SORT_OPTIONS
}