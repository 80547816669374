import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>({
    root: {
        height: "calc(100vh - 160px)",
        display: "flex",
        alignItems: "center",
    },
    content: {
        width: "50%",
        margin: "0 auto",
        textAlign: "center",
        lineHeight: "25px",
        color: "#4f4f4f",
        fontSize: "1.6rem",
        '& a': { 
            color: "#0055dc",
        },
        [theme.breakpoints.down('xs')]: {
            width: 'unset'
        }
    }
}));

const UpgradeMsgPage = props =>{
    const classes = useStyles();
    const { featureType = 'Plus' } = props;

    return (
        <div className={classes.root}>
            <p className={classes.content}>This is a <strong>{featureType}</strong> feature. Please upgrade your account in order to unlock this advanced functionality.
            Contact <a href="mailto:support@counter.app">support@counter.app</a> if you need help upgrading your account.</p>
        </div>
    );
}

export default UpgradeMsgPage;