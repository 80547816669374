import React, { Component } from "react";
import { connect } from "react-redux";
import SpinnerWrapper from "../common/SpinnerWrapper";
import NotificationView from "../common/NotificationView";
import getVisibleReservations from "../../redux/selectors/reservations";
import { createLoadingSelector, createErrorMessageSelector } from "../../redux/selectors/selectors";
import { colorPrimaryBg } from "../../utils/css";
import { checkIfFrontdeskPortal } from '../../utils/utility';

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: {
        open: false,
        variant: "",
        message: ""
      },
      isFrontdeskPortal: true
    };
  }

  componentDidMount(){
    const isFrontdeskPortal = checkIfFrontdeskPortal();
    this.setState({ isFrontdeskPortal });
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorMessage && !_.isEqual(this.props.errorMessage, prevProps.errorMessage)) {
      this.setState({ snackbar: { open: true, variant: "error", message: this.props.errorMessage } });
    }
  }

  handleSnackbarClose = () => {
    this.setState({ snackbar: { open: false, variant: "", message: "" } });
  };

  render() {
    const { isFetching } = this.props;
    const snackbar = { ...this.state.snackbar };
    return (
      <div style={{backgroundColor: this.state.isFrontdeskPortal ? colorPrimaryBg : '' }}>
        <SpinnerWrapper loading={isFetching} />
        {this.props.children}
        <NotificationView
          open={snackbar.open}
          onClose={this.handleSnackbarClose}
          variant={snackbar.variant}
          message={snackbar.message}
        />
      </div>
    );
  }
}

const selectors = [
  "LOGIN",
  "UNLOCK",
  "LOCK",
  "CREATE_RESERVATION",
  "UPDATE_RESERVATION",
  "LOAD_RESERVATION",
  "LOAD_RESERVATION_ALERTS",
  "CREATE_RESERVATION_NOTE",
  "DELETE_RESERVATION_ALERT",
  "LOAD_RESERVATION_TRANSACTIONS",
  "CANCEL_RESERVATION",
  "LOAD_CUSTOMER",
  "UPDATE_CUSTOMER",
  "CREATE_CUSTOMER",
  "CREATE_RESERVATION_TRANSACTION",
  "UPDATE_RESERVATION_STATUS",
  "UPLOAD_CUSTOMER_DOC",
  // "CREATE_RESERVATION_ACCOMMODATION",
  // "UPDATE_BEDS_STATUS",
  "LOAD_CASH_ACCOUNTS",
  "FETCH_RESERVATION_EXTEND_SCENARIO",
  "LOAD_ROOM_SUGGESTION",
  "OPEN_CASH_DRAWER",
  "CLOSE_CASH_DRAWER",
  "LOAD_TRANSACTION_REPORT",
  "LOAD_ACTIVITY_LOG",
  "CREATE_CASH_FLOW",
  "FETCH_AVAILABLE_ROOMS",
  "FETCH_TOTAL_BOOKING_COST_INFO",
  "BOOKING_LOADER",
  "LOAD_PRODUCT_CATEGORIES",
  "UPDATE_RESERVATION_ITEMS",
  "ONLINE_CHECKIN_LOADER",
  "HOUSEKEEPING_RESERVATION_CHECKIN"
];

const allSelectors = selectors.concat(
  "LOAD_LANDING_PAGE_RESERVATIONS", 
  "LOAD_RATES_AVAILABILITY",
  "LOAD_ALL_BEDS_STATUS",
  "LOAD_BEDS",
  "LOAD_BED_ALLOCATIONS",
  "SEND_EMAIL",
  "SEARCH_RESERVATION",
  "HOUSEKEEPING_RESERVATION_CHECKIN"
  );

const errorSelector = createErrorMessageSelector(allSelectors);

let loadingSelector = createLoadingSelector(selectors);

const mapStateToProps = (state, props) => {
  if ((!state.today.reservationIDs || !state.today.reservationIDs.length) && 
  !selectors.includes("LOAD_LANDING_PAGE_RESERVATIONS")) {
    selectors.push("LOAD_LANDING_PAGE_RESERVATIONS")
    loadingSelector = createLoadingSelector(selectors)
  }
  
  if(state.today.reservationIDs && state.today.reservationIDs.length && state.today.loadLandingPage){
    if (selectors.includes("LOAD_LANDING_PAGE_RESERVATIONS")) {
      let index = selectors.indexOf("LOAD_LANDING_PAGE_RESERVATIONS");
      if (index !== -1) {
        selectors.splice(index, 1);
        loadingSelector = createLoadingSelector(selectors)
      }
    }
  }

  if (state.filters.isSearching) {
    if (getVisibleReservations(state.today, state.filters).length === 0 && 
    !selectors.includes("SEARCH_RESERVATION")) {
      selectors.push("SEARCH_RESERVATION")
      loadingSelector = createLoadingSelector(selectors)
    } else if (selectors.includes("SEARCH_RESERVATION")) {
      let index = selectors.indexOf("SEARCH_RESERVATION");
      if (index !== -1) {
        selectors.splice(index, 1);
        loadingSelector = createLoadingSelector(selectors)
      }
    }
  }
  return {
    isFetching: loadingSelector(state),
    errorMessage: errorSelector(state)
  };
};

export default connect(mapStateToProps)(MainLayout);