import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import ImageIcon from '@material-ui/icons/Image';

import IMAGES from '../../../constants/images';
import { handleLoadDocument } from '../../../redux/actions/reservations';


const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: "20px",
        marginTop: 40,
    },
    hide: {
        display: 'none'
    },
    drop: {
        border: "1px solid #e0e0e0",
        cursor: "pointer",
        height: "100px",
        display: "flex",
        alignItems: "center",
        borderRadius: "30px",
        flexDirection: "column",
        justifyContent: "center",
        padding: theme.spacing(3),
        marginBottom: '10px',
        '&:focus': {
            outline: 'none',
        },
        '& img': {
            width: 50,
            opacity: 0.5
        },
        '& h4': {
            margin: "0px",
            fontSize: "1.4rem",
            color: "#777777",
            marginTop: "15px",
        }
    },
    documentsContainer: {
        paddingLeft: '10px',
    },
    docItem:{
        display: "flex",
        alignItems: "center",
        marginBottom: "5px",
        '& .icon':{
            fontSize: "1.7rem",
            color: "#4f4f4f",
        },
        '& .link':{
            cursor: "pointer",
            fontSize: "1.4rem",
            color: "#1b82d7",
            marginLeft: "5px",
        }
    }
}));

const getFileData = files => {
    const file = files[0];
    const fileParts = file.name.split(".");
    const name = fileParts[0];
    const type = fileParts[1];
    return { file, name, type };
}

const getFileName = (file) => {
    const fileArr = file.key.split("/");
    let name = fileArr.length ? fileArr[fileArr.length - 1] : file.key;
    name = name?.split('_time_')?.[0] || null;
    return name;
}

const UploadDocs = props => {
    const classes = useStyles();
    const { onFileSelect, dispatch, documents = [] } = props;

    const imageUploadHandler = event => {
        const fileData = getFileData(event.target.files);
        onFileSelect(fileData);
    }

    const downloadFileHandler = (file, fileName) => {
        dispatch(handleLoadDocument(file, fileName));
    }

    const files = documents && documents.filter(doc => doc.key).map((file, docIndex) => {
        const name = getFileName(file);
        return (
            <div className={classes.docItem} key={docIndex}>
                <ImageIcon className="icon"/>
                <a className="link" onClick={() => downloadFileHandler(file.key, name)}>{name}</a>
            </div>
        )
    });

    return (
        <div className={classes.root}>
            <input
                accept="image/*, .pdf"
                className={classes.hide}
                id={'photo'}
                type="file"
                onChange={imageUploadHandler}
            />
            <label htmlFor={'photo'}>
                <div className={classes.drop}>
                    <img src={IMAGES.ICONS.camera} />
                    <h4>Upload guest documents</h4>
                </div>
            </label>

            <div className={classes.documentsContainer}>
                {files}
            </div>
        </div>
    )
}

export default connect(null)(UploadDocs);