import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { withStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Header from '../../booking_engine/header';
import BookingNavigationInfo from '../../booking_engine/bookingNavigationInfo';
import TravelDetails from './travelDetails';
import RoomCategoryList from '../../booking_engine/room_category_list/roomCategoryList';
import RatePlanList from '../../booking_engine/rate_plan/ratePlanList';
import BookingCart from './bookingCart';
import Checkout from './checkout';
import ConfirmBooking from './confirmBooking';
// import PageNotFound from '../components/booking_engine/pageNotFound';
import AlertModal from '../../booking_engine/alertModal';

import * as DATA from './data';

const headerHeight = 60;
const navigationBlockHeight = 50;
const totalMinusHeight = headerHeight + navigationBlockHeight;

const styles = theme => ({
    mainContainer: {
        paddingTop: totalMinusHeight,
        height: '100vh',
        background: '#ffffff',
        fontFamily: "Roboto, sans-serif",
        [theme.breakpoints.down('sm')]: {
            marginTop: '140px',
        },
    },
    mainContentHolder: {
        margin: '35px 0',
        // minHeight: `calc(100vh - ${totalMinusHeight}px)`,
    },
    mainContent: {
        width: '550px',
        borderRadius: '20px',
        margin: '0 auto',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
});

const BookingSteps = {
    STEP1: 1,
    STEP2: 2,
    STEP3: 3,
    STEP4: 4,
    STEP5: 5,
    STEP6: 6
}

class BookingEnginePage extends Component {
    state = {
        propertyID: null,
        showPage: false,
        activeStep: BookingSteps.STEP1,
        isPageNotFound: false,
        showAlert: false,
        continueHandler: null
    }

    setDocumentTitle = (title) =>{
        document.title = title;
    }

    componentDidMount(){
        const { dispatch } = this.props;
        dispatch({ 
            type: `FETCH_ROOMS_INFO_SUCCESS`, 
            payload: {
                bookEngineConfig: DATA.bookEngineConfig,
                propertyDetails: DATA.propertyDetails,
                roomTypes: DATA.roomTypes,
                taxItems: DATA.taxItems,
                ratePlans: DATA.ratePlans
            }
        });

        window.addEventListener('beforeunload', e => e.returnValue = '/' );
    }

    componentDidUpdate(prevProps){
        if(!_.isEqual(this.props.propertyDetails, prevProps.propertyDetails)){
            this.setState({ 
                showPage: true,
                propertyID:  this.props.propertyDetails._id
            });
            this.setDocumentTitle(this.props.propertyDetails.name);
        }
    }

    closeAlertModal = () =>{
        this.setState({
            showAlert: false,
            continueHandler: null
        })
    }

    navigateToStepHandler = (stepNo) =>{
        const { bookingCartItemCount } = this.props;
        if( stepNo == BookingSteps.STEP1 && bookingCartItemCount > 0 ){
            this.setState({
                showAlert: true,
                continueHandler: () =>{
                    this.setState({ 
                        showAlert: false,
                        activeStep: stepNo 
                    });
                }
            })
            return;
        }
        this.setState({ activeStep: stepNo });
    }

    nextStep = () =>{
        const { activeStep } = this.state;
        this.setState({ activeStep: activeStep + 1 });
    }

    prevStep = () =>{
        const { activeStep } = this.state;
        const gotoStep = activeStep - 1;
        this.navigateToStepHandler(gotoStep);
    }

    fetchBookingPageComponent = () => {
        const { activeStep, propertyID, alertModal } = this.state;
        const props = {
            nextStep: this.nextStep,
            navigateToStep: this.navigateToStepHandler,
            propertyID
        };
        switch(activeStep){
            case BookingSteps.STEP1:
                return <TravelDetails { ...props } />

            case BookingSteps.STEP2:
                return <RoomCategoryList { ...props } />
            
            case BookingSteps.STEP3:
                return <RatePlanList { ...props } />

            case BookingSteps.STEP4:
                return <BookingCart { ...props } />

            case BookingSteps.STEP5:
                return <Checkout { ...props } />

            case BookingSteps.STEP6:
                return <ConfirmBooking { ...props} />

            default: 
                return <TravelDetails />
        }
    }

    pageNotFoundHandler = () =>{
        this.setState({ isPageNotFound: true });
    }
    
    render(){
        const { classes, propertyDetails } = this.props;
        const { activeStep, showPage, isPageNotFound, showAlert, continueHandler } = this.state;
        const BookingComponent = this.fetchBookingPageComponent();
        const propertyImage = propertyDetails.images ? propertyDetails.images[propertyDetails.images.length - 1] : '';
        return(
            <>
                {showPage &&  
                    <Container className={`${classes.mainContainer} booking-main-containter`}>
                        <Header 
                            title={propertyDetails.name}
                            currency={propertyDetails.currency}
                            image = {propertyImage}
                        />
                        { activeStep != BookingSteps.STEP6 && (
                            <BookingNavigationInfo 
                                activeStep={ activeStep }
                                prevStepHandler = {this.prevStep}
                            />
                        )}
                        <Box className={`${classes.mainContentHolder}`}>
                            <Box className={classes.mainContent}>
                                { BookingComponent }
                            </Box>
                        </Box>
                    </Container>
                }

                { isPageNotFound && (
                    <PageNotFound />
                )}

                { showAlert && (
                    <AlertModal 
                        isOpen={showAlert}
                        message = "Your booking progress will be discarded. Do you wish to continue?"
                        continueHandler={continueHandler}
                        cancelHandler={this.closeAlertModal}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    const { bookingEngine } = state;
    const propertyDetails = bookingEngine.propertyDetails ? bookingEngine.propertyDetails: {};
    const bookingCartItemCount = Object.keys(bookingEngine.bookingDetails).length;
    return{
        propertyDetails,
        bookingCartItemCount
    };
};

export default withStyles(styles)(connect(mapStateToProps)(withRouter(BookingEnginePage)));