import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import InputBase from '@material-ui/core/InputBase';
import Select from 'react-select';
import TimePicker from '../../common/TimePicker';

import CustomInput from '../common/CustomInput';
import Button from '../common/Button';
import { BOOKING_SOURCE } from '../../../utils/constants';
import { fetchDateFormat } from '../../../utils/utility';
import { updateReservationDetails } from '../../../redux/actions/reservations';
import ViewCardDetails from './ViewCardDetails';
import useOnboardingStatus from '../../../hooks/useOnboardingStatus';

const useStyles = makeStyles(theme =>({
    root: {
        padding: "15px",
    },
    btnContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: "10px",

        '& .primaryBtn':{
            padding: '10px 30px',
        },
        '& .secondaryBtn':{
            padding: '10px 25px',
            marginRight: '10px'
        }
    }
}));

const selectStyle = {
    control: (base, state) => ({
        ...base,
        border: 'none',
        boxShadow: 0,
        cursor: 'pointer',
        margin: "5px 0px",
        '&:hover': { border: 'none' },
    }),
    indicatorSeparator: (base, state) =>({
        display: 'none'
    })
};

const fetchBookingSources = (bookingSources) =>{
    const defaultSourceKeys = [ 'PHONE', 'EMAIL', 'WALKIN', 'OTHER' ];
    let sourceList = defaultSourceKeys.map(source => ({ value: BOOKING_SOURCE[source], label: BOOKING_SOURCE[source] }));
    const newBookingSources = bookingSources.map(source => ({ value: source, label: source }));
    sourceList = [ ...sourceList, ...newBookingSources ];
    sourceList = [ ...new Set(sourceList.map(JSON.stringify))].map(JSON.parse); 
    return sourceList;
}

const ReservationDetails = props => {
    const classes = useStyles();
    const { reservation, bookingSource, dispatch } = props;
    const [sourceList, setSourceList] = useState([]);
    const [formData, setFormData] = useState({});
    const [timeStamp, setTimestamp] = useState(null);
    const [showActionBtns, setShowActionBtns] = useState(false);

    const onboardingStatus = useOnboardingStatus();

    const timeOfArrival = reservation.timeOfArrival && reservation.timeOfArrival !== '--' ?
        (reservation.timeOfArrival.length <= 2 ? reservation.timeOfArrival + ':00' : reservation.timeOfArrival) : '--';

    useEffect(() => init(), []);

    const init = () =>{
        const data = {
            bookingSource: reservation.bookingSource,
            cancellationPolicy: reservation.cancellationPolicy,
            timeOfArrival,
        }

        setFormData({ ...data });
    }   

    useEffect(() =>{
        if(bookingSource){
            const bookingSourceList = fetchBookingSources(bookingSource);
            setSourceList([ ...bookingSourceList ]);
        }   
    }, [bookingSource]);

    const onEditHandler = (key, value) =>{
        setFormData({ ...formData, [key]: value });
        setShowActionBtns(true);
    }

    const resetFormHandler = () =>{
        setTimestamp(new Date());
        setShowActionBtns(false);
    }

    const submitHandler = () =>{
        let data = {
            bookingSource: formData.bookingSource,
            cancellationPolicy: formData.cancellationPolicy,
            tags: [ ...reservation.tags ]
        }

        if(formData.timeOfArrival && 
            formData.timeOfArrival !== reservation.timeOfArrival && 
            formData.timeOfArrival !== '--'
        ){
            data.timeOfArrival = fetchDateFormat(formData.timeOfArrival, 'LT');
        }

        dispatch(updateReservationDetails(reservation._id, data))
            .then(success =>{
                if(success) resetFormHandler();
            });
    }

    return (
        <div className={classes.root}>
            {/* --------------BOOKING SOURCE------------------- */}
            <CustomInput
                label="Booking Source"
                value={formData.bookingSource}
                resetEdit={timeStamp}
                editable
            >
                <Select
                    styles={selectStyle}
                    options={sourceList}
                    value={sourceList.find(item => item.value == formData.bookingSource)}
                    onChange={option => onEditHandler('bookingSource', option.value)}
                />
            </CustomInput>

            {/* --------------BOOKING ID------------------- */}
            <CustomInput
                label="Booking ID"
                value={reservation.sourceResvID || '--'}
            />

            {/* --------------CANCELLATION POLICY------------------- */}
            <CustomInput
                label="Cancellation Policy"
                value={formData.cancellationPolicy}
                resetEdit={timeStamp}
                editable
            >
                <InputBase
                    autoFocus
                    value={formData.cancellationPolicy}
                    onChange={e => onEditHandler('cancellationPolicy', e.target.value)}
                />
            </CustomInput>
            
            {/* --------------ARRIVAL TIME------------------- */}
            <CustomInput
                name="timeOfArrival"
                label="Arrival Time"
                value={timeOfArrival}
                resetEdit={timeStamp}
                editable
            >
                <TimePicker 
                    value={formData.timeOfArrival == '--' ? null : formData.timeOfArrival}
                    onChange={value => onEditHandler('timeOfArrival', value)}
                />
            </CustomInput>

            {/* --------------COUNTER RESERVATION ID------------------- */}
            <CustomInput
                label="Counter Reservation ID"
                value={reservation.code}
            />

            {showActionBtns && (
                <div className={classes.btnContainer}>
                    <Button
                        type="secondary"
                        className="secondaryBtn"  
                        onClick={resetFormHandler}
                    >
                        Cancel
                    </Button>
                    <Button  
                        className="primaryBtn"
                        onClick={submitHandler}
                    >
                        Save
                    </Button>
                </div>
            )}

            {onboardingStatus.isPaid && <ViewCardDetails />}

        </div>
    )
}

const mapStateToProps = state =>{
    const { reservationDetails, bookingSource } = state;
    return {
        reservation: reservationDetails?.reservation || {},
        bookingSource: bookingSource || []
    }
}

export default connect(mapStateToProps)(ReservationDetails);