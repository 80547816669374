import http from '../redux/utils/http'

const GoogleHotelBookingPage = props => {
    const searchParams = new URLSearchParams(props.location.search)
    const hotelID = searchParams.get('hotelID')
    const checkIn = searchParams.get('checkIn')
    const checkOut = searchParams.get('checkOut')
    const guestCount = searchParams.get('guestCount')
    const roomTypeMapID = searchParams.get('roomTypeMapID')
    const ratePlanMappingCode = searchParams.get('ratePlanMappingCode')

    http({
        method: 'GET',
        url: '/googleHotel/redirectInfo',
        params: { hotelID, roomTypeMapID, ratePlanMappingCode }
    }).then(result => {
        const { bookingEngineURL, roomTypeID, ratePLanID } = result.data
        props.history.replace(`/booking/${bookingEngineURL}?checkIn=${checkIn}&checkOut=${checkOut}&roomTypeID=${roomTypeID}&guestCount=${guestCount}&ratePlanID=${ratePLanID}`)
    })

    return null
}

export default GoogleHotelBookingPage