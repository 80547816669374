import React from 'react';
import { makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withTranslation } from 'react-i18next';

import CustomCheckBox from '../customCheckBox';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0px 25px',
        // marginTop: '30px',
        [theme.breakpoints.down("xs")]:{
            padding: '0px 10px',
            // marginTop: '60px'
        }
    },
    cardErrorBlock:{
        marginLeft: '44px',
    },

    errorMessage: {
        color: '#f44336',
        marginLeft: '14px',
        lineHeight: '20px',
        '&::first-letter':{
            textTransform: 'uppercase'
        }
    },
}));

const CheckboxSection = props =>{
    const { t } = props
    const classes = useStyles();
    return(
        <div className={classes.root}>
            {(props.termsConditions?.length > 0) && <div>
                <CustomCheckBox
                    name="termsAndCondtionChecked"
                    //label={t('bookingEngine.agreeTerms')}
                    label="I agree to the property's terms and conditions*"
                    termsConditions={props.termsConditions}
                    termsAndConditions={(props.termsConditions?.length) ? true : false}
                    checked = {props.termsAndCondtionChecked}
                    onChangeHandler={props.checkBoxHandler}
                />
                {props.termsAndConditionError && (
                    <FormHelperText className={classes.errorMessage}>{t('bookingEngine.acceptTerms')}</FormHelperText>
                )}
            </div>}
        
            <CustomCheckBox
                name="marketingUpdatesChecked"
                label={t('bookingEngine.receiveUpdates')}
                checked = {props.marketingUpdatesChecked}
                onChangeHandler={props.checkBoxHandler}
            />
            <div className={classes.cardErrorBlock}>
                <FormHelperText className={classes.errorMessage}>{props?.paymentError}</FormHelperText>
            </div>
        </div>

    );
}

export default withTranslation()(CheckboxSection);