import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { Checkbox } from '@material-ui/core';
import HtmlTooltip from './HtmlTooltip';

const useStyles = makeStyles(theme =>({
    primaryBtn: {
        fontSize: "1.2rem",
        backgroundColor: '#000 !important',
        color: '#fff',
        borderRadius: "30px",
        padding: "12px 40px",

        '&:hover': {
            color: '#fff'
        },
        '&:disabled': {
            color: '#fff',
            opacity: 0.4,
        }
    },
    secondaryBtn: {
        fontSize: "1.2rem",
        color: "#000",
        padding: "10px 30px",
        borderRadius: "30px",
        backgroundColor: "#fff",
        border: "1px solid #e0e0e0",

        '&:hover': {
            backgroundColor: "#fff",
            color: '#000',
            opacity: 0.4,
        },

        '&:disabled': {
            color: '#fff',
            opacity: 0.4,
        }
    },

    selectBtn: {
        display: 'flex',
        margin: "0px",
        marginBottom: "12px",

        '& .MuiIconButton-root': {
            display: 'none'
        },

        '& .MuiFormControlLabel-label': {
            border: "1px solid #e0e0e0",
            padding: "10px 20px",
            borderRadius: "40px",
            cursor: "pointer",
            width: '100%',
            fontSize: "1.1rem",
            fontWeight: 600,
        },

        '& .Mui-checked + .MuiFormControlLabel-label':{
            backgroundColor: '#000',
            color: '#fff',
        },

        '& .Mui-checked + .MuiFormControlLabel-label p': {
            color: '#fff !important',
        },

        '&.Mui-disabled .MuiFormControlLabel-label': {
            opacity: '0.8 !important',
            cursor: 'default'
        }
    },
    iconContainer: {
        borderRadius: "50%",
        border: "1px solid #e3e3e35c",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        padding: "8px",
        marginLeft: "15px",
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: "#e3e3e35c",
        }
    },
    actionIcons: {
        width: '16px',
        height: '16px',
        opacity: 0.8
    },
}));

const CustomButton = props =>{
    const classes = useStyles();
    const { type="primary", onClick, className, disabled } = props;
    return(
        <Button 
            className={clsx(
                ({
                    "primary": classes.primaryBtn,
                    "secondary": classes.secondaryBtn
                })[type]
            , className)}
            onClick={!disabled && onClick || null}
            disabled={disabled}
        >
            {props.children}
        </Button>
    )
}

export const RadioButton = props =>{
    const classes = useStyles();
    const { keyID, label, value, className, disabled=false, tooltipTitle="" } = props;

    if(tooltipTitle){
        return(
            <HtmlTooltip  title={tooltipTitle}>
                <FormControlLabel
                    key={keyID}
                    className={clsx(classes.selectBtn, className)}
                    control={<Radio />}
                    label={label}
                    value={value}
                    disabled={disabled}
                />
            </HtmlTooltip>
        )
    }

    return(
        <FormControlLabel
            key={keyID}
            className={clsx(classes.selectBtn, className)}
            control={<Radio />}
            label={label}
            value={value}
            disabled={disabled}
        />
    )
}

export const CheckboxButton = props =>{
    const classes = useStyles();
    const { keyID, label, value, className,checked, onChange } = props;
    return(
        <FormControlLabel
            key={keyID}
            className={clsx(classes.selectBtn, className)}
            control={<Checkbox />}
            label={label}
            value={value}
            checked={checked}
            onChange={onChange}
        />
    )
}

export const IconButton = props =>{
    const classes = useStyles();
    const { imgSrc, onClick, className, IconComponent } = props;
    return (
        <div
            className={clsx(classes.iconContainer, className)}
            onClick={onClick}
        >
            {IconComponent && <IconComponent className={classes.actionIcons} />}

            {imgSrc && (
                <img 
                    src={imgSrc} 
                    className={classes.actionIcons} 
                />
            )}
            
        </div>
    );
}

export default CustomButton;