import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { CheckboxButton } from '../../common/Button';
import { getAccomodationInfo } from '../../helper';

const useStyles = makeStyles(theme =>({
    root: {
    
    },
    roomInfoContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        '& p': {
            margin: 0
        }
    },
    roomInfo: {
        '& .title': {
            fontSize: "1.3rem",
            fontWeight: 600,
            marginBottom: "3px",
        },
        '& .subText': {
            color: "#737373",
            fontSize: "1.1rem",
            fontWeight: 600,
        }
    }
}));

const BedsRangeSelect = props =>{
    const classes = useStyles();
    const { data, rooms, onDateChangeHandler } = props;

    const [dates, setDates] = useState({ startDate: null, endDate: null });

    useEffect(() =>{
        if(dates.startDate){
            onDateChangeHandler({ ...dates });
        }
    }, [dates]);

    const onChangeHandler = (event) =>{
        const isChecked = event.target.checked;
        const value = event.target.value;

        if(!isChecked){ 
            setDates({ startDate: value, endDate: null });
            return;
        }

        if((dates.startDate && dates.endDate) || (!dates.startDate && !dates.endDate)){
            setDates({ startDate: value, endDate: null });
            return;
        }

        if(dates.startDate && !dates.endDate && moment(value).diff(dates.startDate) >= 0){
            setDates({ ...dates, endDate: value });
            return;
        }

        setDates({ startDate: value, endDate: null });
    }

    const fetchBedsInfoLabel = (item) =>{
        const accomodationData = {
            roomTypeID: item.roomTypeID,
            roomID: item.roomID,
            bedID: item.bedID
        }

        return(
            <div className={classes.roomInfoContainer}>
                <div className={classes.roomInfo}>
                    <p className="title">{getAccomodationInfo(accomodationData, rooms).name}</p>
                    <p className="subText">{moment(item.date).format('dddd, DD MMM YYYY')}</p>
                </div>
                <p>{item.currency}{item.rate}</p>
            </div>
        )
    }

    const isDateSelected = date =>{
        const { startDate, endDate } = dates;

        if([startDate, endDate].includes(date)) return true;

        if(moment(date).isBetween(moment(startDate), moment(endDate))) return true;

        return false;
    }

    return(
        <div>
            {data.map((item, index) =>(
                <CheckboxButton
                    key={`rate_${item.ID}_${index}`}
                    label={fetchBedsInfoLabel(item)}
                    value={item.date}
                    checked={isDateSelected(item.date)}
                    onChange={onChangeHandler}
                />
            ))}
        </div>
    )
}

const mapStateToProps = state =>{
    const { rooms } = state;
    return {
        rooms,
    }   
}

export default connect(mapStateToProps)(BedsRangeSelect);