const defaultState = {
    mode: 0, // 0: default, 1: booking, 2: checkin
    
}

const KioskReducer = (state = { ...defaultState },action) => {
    switch (action.type) {

        case "SET_KIOSK_MODE":
            return { 
                ...state,
                mode: action.payload
            };

        case "LOAD_KIOSK_SUCCESS":
            return {
                ...state,
                config: action.payload.kioskConfig
            }

        default:
            return state;
    }
};

export default KioskReducer;
  