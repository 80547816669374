import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme =>({
    cardContainer:{
        boxShadow: '0 3px 4px 0 #ECECEC',
        borderRadius: '5px',
        // marginTop: '20px',
        display: 'flex',
        marginBottom: '25px',
        padding: 10,
        [theme.breakpoints.up("sm")]: {
            justifyContent: 'space-evenly'
        }
    },
    iconContainer:{
        textAlign: 'center',
        padding: '10px',
        fontSize: '13px',
        fontSize: '12px',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        },
        '& img':{
            width: '30px',
            height: '30px',
            marginBottom: 10,
            [theme.breakpoints.down("sm")]: {
                width: '35px',
                height: '35px',
            },
        },
        '& div':{
            fontWeight: 'bold'
        }
    },
}));

const RoomInfoCard = ({
    roomTypeName,
    dates,
    guestCount,
    t
}) =>{
    const classes = useStyles();
    return(
        <Card className={classes.cardContainer}>
            <Grid container justify="center">
                <Grid item sm={4}>
                    <Box className={classes.iconContainer}>
                        <img src="/images/icons/rooms.png" />
                        <div>{roomTypeName} {t('onlineCheckin.details.room')}</div>
                    </Box>
                </Grid>
                <Grid item sm={4}>
                    <Box className={classes.iconContainer}>
                        <img src="/images/icons/guests.png" />
                        <div>{guestCount} {t('onlineCheckin.details.people')}</div>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
}

export default withTranslation()(RoomInfoCard);