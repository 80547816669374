
import { getDatesOfRange, fetchDatePerTimezone } from '../../../../utils/utility';

export const createNewReservationAccommodations = (data, selectedDate, formData) => {
  let savedData = { ...data };
  const { amount, rateEntireStay, entireStay, bedID, roomID, roomTypeID, typeOfRoom } = formData;

  savedData.rateEntireStay = rateEntireStay || false;
  savedData.selectionEntireStay = entireStay || false;

  savedData.items = savedData.items.map(itemData => {
    let item = { ...itemData }
    if (rateEntireStay || item.date === selectedDate) item.rate = amount || 0;

    if (entireStay || item.date === selectedDate) {
      item = { ...item, bedID, roomID, roomTypeID, rateEntireStay, entireStay, typeOfRoom };
    }
    return item;
  })

  return savedData;
}


export const editReservationExtendStay = (selectedDate, totalNights, formData) => {
  let nights = totalNights;
  let date1, date2, savedData = [];
  const { amount, entireStay, bedID, roomID, roomTypeID } = formData;

  let next = false;
  date1 = selectedDate;
  date2 = fetchDatePerTimezone(selectedDate).add((nights || 1) - 1, 'days').format("YYYY-MM-DD");
  let datesOfRange = getDatesOfRange(date1, date2, null, 'objectArray');

  if (entireStay) {
    next = false; nights = 0;
    savedData = datesOfRange.map(itemData => {
      let item = { ...itemData }
      item = { ...item, rate: amount, bedID, roomID, roomTypeID, isSaved: false };
      return item;
    })
  } else {
    if (nights === 1) next = false; else next = true;
    nights = nights - 1;

    if (next) {
      savedData = datesOfRange.map(itemData => {
        let item = { ...itemData }
        item.rate = amount;
        item.isSaved = false;
        if (selectedDate === item.date) {
          item = { ...item, bedID, roomID, roomTypeID };
        }
        return item;
      })
    } else {
      savedData.push({ date: selectedDate, rate: amount, bedID, roomID, roomTypeID, isSaved: false})
    }
  }

  return {
    savedData, next, nights
  }
}