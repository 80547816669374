import http from "../utils/http";
import { normalize } from "normalizr";
import { propertySchema, upsellItemsSchema, transactionModeSchema } from "../schema/property";
import { emailTemplateSchema } from "../schema/reservation";

export function loadProperty() {
  const ACTION_NAME = "LOAD_PROPERTY";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/property/")
      .then(successResult => {
        let normalizedData = normalize(successResult.data, propertySchema);
        let payload = normalizedData.entities;
        payload.timezone = successResult.data.timezone;
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: payload
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD EMAILS TEMPLATE LIST
//----------------------------------------------------------------
export function loadEmailTemplates() {
  const ACTION_NAME = "LOAD_EMAIL_TEMPLATES";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/emailTemplateList/")
      .then(successResult => {
        let normalizedData = normalize(successResult.data, emailTemplateSchema);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: { emailTemplates: normalizedData.entities.emailTemplates, emailTemplateIDs: normalizedData.result }
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// GET TRANSACTION MODE
//----------------------------------------------------------------
export function loadTransactionMode() {
  const ACTION_NAME = "LOAD_TRANSACTION_MODES";
  return function (dispatch) {
    return http.get('/api/transactionModes')
      .then(successResult => {
        var normalizedData = normalize(successResult.data, [transactionModeSchema]);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: { transactionMode: normalizedData.entities.transactionMode, transactionModeIDs: normalizedData.result }
        })
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  }
}


//----------------------------------------------------------------
// LOAD UPSELL ITEM
//----------------------------------------------------------------
export function loadUpsellItems() {
  const ACTION_NAME = "LOAD_UPSELL_ITEMS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/upsell")
      .then(successResult => {
        let normalizedData = normalize(successResult.data, [upsellItemsSchema]);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: {
            upsellItems: normalizedData.entities.upsellItems,
            itemKeys: normalizedData.result
          }
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}
