import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import http from "../../redux/utils/http";
import { withRouter } from "react-router-dom";
import { setupRegister } from "../../redux/actions/register";

import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";

import Grid from "@material-ui/core/Grid";
import { loadAllCashDrawers } from "../../redux/actions/cashDrawer";
import { PAGES } from "../../utils/constants";

const styles = theme => ({
  formContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    // height: "90vh",
    paddingTop: "120px",
    width: "100%"
  },
  formWrapper: {
    // backgroundColor: "#fff",
    padding: "75px",
    borderRadius: "5px"
  },
  setUpGridItem: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "100% !important"
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  configureHeader: {
    marginBottom: 40
  },
  card: {
    minWidth: 160,
    maxWidth: 345
  },
  media: {
    height: 140
  },
  noCashDrawer: {
    marginTop: 60,
    padding: theme.spacing(3, 2)
  },
  cashDrawer: {
    marginTop: 60
  },
  saveButton: {
    marginTop: 60,
    display: "flex",
    justifyContent: "flex-end"
  }
});

class ConfigureRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyID: "",
      propertyName: "",
      propertyCode: "",
      cashDrawerID: "",
      cashDrawerName: "",
      hasCashDrawer: false,
      success: false,
      cashDrawers: {}
    };
  }

  componentDidMount() {
    // If not logged in 
    if (!this.props.auth || (this.props.auth && !this.props.auth.isAuthenticated)) {
      this.props.history.push(`/${PAGES.LOGIN}`);
    }
    // If logged in and user navigates to setup page, should redirect them to dashboard
    if (this.props.auth && this.props.auth.isAuthenticated) {
      if (localStorage.sessionToken) this.props.history.push(`/${PAGES.LANDING}`);
    }

    if (this.props?.register?.propertyID) {
      this.props.history.push(`/${PAGES.LOCK}`);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props.cashDrawers, prevProps.cashDrawers)) {
      this.setState({
        success: true,
        hasCashDrawer: this.props.cashDrawers ? true : false,
        cashDrawers: this.props.cashDrawers || {},
        cashDrawerID: this.props.cashDrawers && Object.keys(this.props.cashDrawers).length === 1 ?
          Object.keys(this.props.cashDrawers)[0] : null
      });
    }
  }

  handlePropertySelect = (property) => {
    const propertyID = property.propertyID;
    const propertyName = property.name;
    const propertyCode = property.code;
    this.setState({ propertyID, propertyName, cashDrawerID: "", cashDrawerName: "", hasCashDrawer: false, propertyCode });
    if(property.features && property.features.length){
      this.props.dispatch(loadAllCashDrawers(propertyID));
    } else {
      this.props.dispatch(loadAllCashDrawers(null));
    }
  };

  handleCashDrawerSelect = (cashDrawerID, cashDrawerName) => {
    this.setState({ cashDrawerID, cashDrawerName });
  };

  handleSubmit = () => {
    this.props.dispatch(
      setupRegister(
        this.state.propertyID, 
        this.state.propertyName, 
        this.state.propertyCode,
        this.state.cashDrawerID, 
        this.state.cashDrawerName
      )
    );
    this.props.history.push(`/${PAGES.LOCK}`);
  };

  render() {
    const { classes, propertyImgPrefix } = this.props;
    const cashDrawers = { ...this.state.cashDrawers };

    const getStyle = isActive => {
      return isActive ? { background: "#34364c" } : {};
    };

    const getFontStyle = isActive => {
      return isActive ? { color: "#ffffff" } : {};
    };

    const propertySelected = () => {
      return this.state.propertyID !== "" ? { display: "none" } : {};
    };

    return (
      <div className={classes.formContainer}>
        <div className={classes.formWrapper}>
          <Grid container spacing={5}>
            {this.props.properties &&
              this.props.properties.map(property => {
                let propertyImg = (propertyImgPrefix && property.images) ? propertyImgPrefix.concat(property.images) : "";
                if(propertyImg) propertyImg = propertyImg.replace("thumbnail", "original")
                return (
                  <Grid
                    sm={4}
                    className={classes.setUpGridItem}
                    item
                    key={property.propertyID}
                  >
                    <Card className={classes.card}>
                      <CardActionArea
                        onClick={() =>
                          this.handlePropertySelect(property)
                        }
                      >
                        <CardMedia
                          className={classes.media}
                          image={propertyImg}
                          title=""
                        />
                        <CardContent
                          style={getStyle(
                            property.propertyID === this.state.propertyID
                          )}
                        >
                          <Typography
                            style={getFontStyle(
                              property.propertyID === this.state.propertyID
                            )}
                            gutterBottom
                            variant="h6"
                            component="h2"
                          >
                            {property.name}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )
              })}
          </Grid>

          {this.state.success && Object.keys(cashDrawers).length === 0 && (
            <Paper className={classes.noCashDrawer}>
              <Typography gutterBottom variant="h6" component="h2">
                No cash drawers configured at this property. Please visit your
                admin dashboard and complete setup.
              </Typography>
            </Paper>
          )}

          {this.state.success && (
            <Grid container spacing={5} className={classes.cashDrawer}>
              {Object.keys(cashDrawers).length != 0 &&
                Object.keys(cashDrawers).map((key, index) => (
                  <Grid
                  sm={4}
                  className={classes.setUpGridItem}
                  key={index}
                  item
                  >
                    {cashDrawers[key] && cashDrawers[key].propertyID === this.state.propertyID && <Card className={classes.card}>
                      <CardActionArea
                        onClick={() =>
                          this.handleCashDrawerSelect(cashDrawers[key]._id, cashDrawers[key].name)
                        }
                      >
                        <CardMedia
                          className={classes.media}
                          image="/images/cash-drawer-image.jpeg"
                          title=""
                        />
                        <CardContent
                          style={getStyle(
                            cashDrawers[key]._id === this.state.cashDrawerID
                          )}
                        >
                          <Typography
                            style={getFontStyle(
                              cashDrawers[key]._id === this.state.cashDrawerID
                            )}
                            gutterBottom
                            variant="h6"
                            component="h2"
                          >
                            {cashDrawers[key].name}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>}
                  </Grid>
                ))}
            </Grid>
          )}

          {this.state.propertyID && (!this.props.isFetchingCashDrawers) && (!this.state.hasCashDrawer || this.state.cashDrawerID) && (
            <div className={classes.saveButton}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={this.handleSubmit}
              >
                Save
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ConfigureRegister.protoTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  register: state.register || {},
  properties: state?.auth?.loginSuccess?.accessToProperties || null,
  propertyImgPrefix: state?.auth?.loginSuccess?.s3path || null,
  isFetchingCashDrawers: state.cashDrawer.isFetchingCashDrawers,
  cashDrawers: state.cashDrawer.cashDrawers
});

export default withStyles(styles)(
  connect(mapStateToProps)(withRouter(ConfigureRegister))
);
