import React, { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

const secondaryColor = '#5C5C5C';

const useStyles = makeStyles(theme => ({
    readMoreBtn: {
        color: secondaryColor,
        fontSize: '1.4rem',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        marginLeft: '-7px',
        marginBottom: '0px',
        marginTop: 5,
        cursor: 'pointer'
    },
    readMoreIcon: {
        color: secondaryColor,
        fontSize: '28px'
    },
    readMoreContentHolder: {
        overflow: 'hidden',
        color: secondaryColor,
    },
    showMore: {
        height: "100%"
    },
    showLess: {
        height: "0px"
    },
    readMoreContent:{
        color: '#666666',
        fontSize: '1.2rem',
        lineHeight: 1.5,
        maxHeight: '35px',
        overflow: 'hidden',
        '&.show':{
            maxHeight: '100%'
        },
    }
}));

const ReadMore = props =>{
    const { t } = props
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const [showMore, setShowMore] = useState(false);
    let textRef = React.createRef();

    useEffect(()=>{
        const divHeight = textRef.current.scrollHeight;
        const defaultHeight = 25;
        if(divHeight > defaultHeight){
            setShow(true);
        }
    },[])
    return(
        <Typography component="div" className={classes.root} >
            <Box ref={textRef}  variant="body1" component="div" className={`${classes.readMoreContent} ${showMore ? 'show' : '' }`}>
                {props.data}
            </Box>
            {show && (
                <>
                    { showMore ? (
                    
                        <Box className={classes.readMoreBtn} onClick={()=>setShowMore(false)}>
                            <ExpandLessIcon className={classes.readMoreIcon} /> {t('actions.less')}
                        </Box>
                    ) : (
                        <Box className={classes.readMoreBtn} onClick={()=>setShowMore(true)}>
                            <ExpandMoreOutlinedIcon className={classes.readMoreIcon} /> {t('actions.more')}
                        </Box>
                    )}
                </>
            )}
            {/* <Collapse 
                in={showMore}
                className={classes.readMoreContentHolder}
            >
                <Box variant="body1" component="div" className={classes.readMoreContent}>
                    {props.data}
                </Box>
            </Collapse> */}
        </Typography>
    );
}

export default withTranslation()(ReadMore);