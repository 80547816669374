export default (state = {}, action) => {
  switch (action.type) {
    case "LOAD_ALL_CASH_DRAWERS_REQUEST":
      return { ...state, cashDrawers: null, isFetchingCashDrawers: true };
    case "LOAD_ALL_CASH_DRAWERS_SUCCESS":
      const { cashDrawer } = action.payload;
      return { ...state, cashDrawers: cashDrawer, isFetchingCashDrawers: false };
    case "LOAD_ALL_CASH_DRAWERS_FAILURE":
      return { ...state, cashDrawers: null, isFetchingCashDrawers: false };
    //------------------------------------------------------------------------
    case "OPEN_CASH_DRAWER_REQUEST":
      return { ...state, openCashDrawer: null };
    case "OPEN_CASH_DRAWER_SUCCESS":
      return { ...state, openCashDrawer: action.payload };
    case "OPEN_CASH_DRAWER_FAILURE":
      return { ...state, openCashDrawer: null };
    //------------------------------------------------------------------------
    case "CLOSE_CASH_DRAWER_REQUEST":
      return { ...state, closeCashDrawer: null };
    case "CLOSE_CASH_DRAWER_SUCCESS":
      return { ...state, ...action.payload };
    case "CLOSE_CASH_DRAWER_FAILURE":
      return { ...state, closeCashDrawer: null };
    //------------------------------------------------------------------------
    case "CREATE_CASH_FLOW_REQUEST":
      return { ...state, createCashFlow: false };
    case "CREATE_CASH_FLOW_SUCCESS":
      return { ...state, createCashFlow: true };
    case "CREATE_CASH_FLOW_FAILURE":
      return { ...state, createCashFlow: false };
    //------------------------------------------------------------------------
    case "LOAD_TRANSACTION_REPORT_REQUEST":
      return { ...state, transactionReport: null };
    case "LOAD_TRANSACTION_REPORT_SUCCESS":
      return { ...state, transactionReport: action.payload };
    case "LOAD_TRANSACTION_REPORT_FAILURE":
      return { ...state, transactionReport: null };
    //------------------------------------------------------------------------
    default:
      return state;
  }
}