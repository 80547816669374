const initialState = {
    onboardingStatus: 'free'
};

const PropertyConfigReducer = (state = initialState, action) => {
    switch(action.type){
        case "LOAD_PROPERTY_CONFIG_SUCCESS":
            return {
                ...state,
                ...action.payload
            };

        case "LOGOUT":
            return { ...initialState };

        default:
            return state;
    }
}

export default PropertyConfigReducer;