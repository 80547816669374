import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

import CancelReservationModal from './CancelReservationModal';
import { cancelReservation, getReservationTags, updateReservationDetails, createReservationTag, createReservationNote } from '../../../redux/actions/reservations';
import { toTitleCase } from '../../../utils/wordUtils';
import ReservationTags from './ReservationTags';
import ReservationNotes from './ReservationNotes';
import AddTagModal from './AddTagModal';
import AddNoteModal from './AddNoteModal';
import ActivityLogModal from './ActivityLogModal';
import { RESERVATION_CANCELLED_STATUSES } from '../../../utils/constants';
import IMAGES from '../../../constants/images';
import { IconButton } from '../common/Button';
import EmailLogsModal from './EmailLogsModal';

const styles = theme => ({
    root: {
        backgroundColor: "#fff",
        border: "1px solid #e0e0e0",
        borderRadius: '7px',
        padding: '25px',
        boxSizing: 'border-box',
        marginBottom: '10px',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 10px',
        }
    },
    resHeader: {    
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: "30px",
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    resHeaderIcons: {
        width: '12px',
        height: '12px',
        marginRight: "4px",
        '&.section2': {
            marginLeft: '10px'
        }
    },
    resDetails: {
        display: 'flex',
        // alignItems: 'center',
    },
    infoContainer: {
        '& p':{
            margin: 'unset'
        },
    
        '& .primaryText':{
            fontSize: "1.6rem",
            fontWeight: 600,
            marginBottom: "5px",
        },
        '& .secondaryText':{
            color: "#b0b0b0",
            fontSize: "1.1rem",
        },
    },
});

const ACTIONS = {
    delete: {
        iconUrl: IMAGES.ICONS.trash,
        onClickEvent: 'cancelReservation'
    },
    activity: {
        iconUrl: IMAGES.ICONS.history,
        onClickEvent: 'activityLogs'
    },
    emailLogs: {
        iconUrl: IMAGES.ICONS.conversation,
        onClickEvent: 'emailLogs'
    },
}

class InfoSection extends Component {
    state ={
        showCancelDialog: false,
        showActivityLogs: false,
        showAddTagModal: false,
        showAddNoteModal: false,
        showEmailLogModal: false,
        tagList: [],
    }

    componentDidMount(){
        this.fetchTags();
    }

    componentDidUpdate(prevProps){
        const { reservation } = this.props;
        if(reservation.code && reservation.code !== prevProps.reservation.code){
            document.title=`${reservation.code} - ${toTitleCase(reservation.guestName)} | Counter Frontdesk`;
        }
    }

    componentWillUnmount(){
        document.title='Counter Frontdesk';
    }

    fetchTags = () =>{
        const { propertyID, dispatch } = this.props;
        dispatch(getReservationTags(propertyID))
            .then(responseData =>{
                if(responseData){
                    let tags = {};
                    responseData.forEach(data => tags[data._id] = { ...data });
                    this.setState({ tagList: { ...tags } });
                }
            })
    }

    closeModalHandler = () => {
        this.setState({ 
            showCancelDialog: false,
            showAddTagModal: false, 
            showActivityLogs: false,
            showAddNoteModal: false,
            showEmailLogModal: false
        });
    };

    handleCancelReservation = payload => {
        const { reservation, dispatch, goToDashboard } = this.props;
        dispatch(cancelReservation(reservation._id, payload))
            .then(success =>{
                if(success) goToDashboard();
            })
    };

    triggerAction = action =>{
        switch(action){
            case 'cancelReservation':
                this.setState({ showCancelDialog: true });
                break;

            case 'activityLogs':
                this.setState({ showActivityLogs: true });
                break;

            case 'emailLogs':
                this.setState({ showEmailLogModal: true });
                break;
        }
    }

    saveReservationTagHandler = (tagID) =>{
        const { dispatch, reservation } = this.props;
        const reservationTags = reservation?.tags || [];

        const payload = { 
            bookingSource: reservation.bookingSource,
            cancellationPolicy: reservation.cancellationPolicy,
            tags: [ ...reservationTags, tagID ],
            timeOfArrival: reservationTags.timeOfArrival
        };

        dispatch(updateReservationDetails(reservation._id, payload))
            .then(success =>{
                if(success) this.closeModalHandler();
            });
    }

    createNewTagHandler = (tagName) =>{
        const { dispatch, reservation, propertyID } = this.props;
        const payload = {
            name: tagName,
            reservationID: reservation._id,
            propertyID
        }
        dispatch(createReservationTag(payload))
            .then(data =>{
                if(data){
                    this.setState(prevState =>({
                        tagList: { 
                            ...prevState.tagList, 
                            [data._id]: { ...data } 
                        }
                    }));
                    this.closeModalHandler();
                }
            });
    }

    saveReservationNoteHandler = (note) => {
        const {dispatch, reservation, propertyID} = this.props;
        const payload = {
            note,
            propertyID,
            reservationID: reservation._id
        };

        dispatch(createReservationNote(reservation._id, payload))
            .then(success =>{
                if(success) this.closeModalHandler();
            });
    }

    getBookingNumber = () =>{
        const { reservation } = this.props;
        const { bookingSource, sourceResvID, code } = reservation;
        return sourceResvID ? `${sourceResvID} (${bookingSource})` : code;
    }

    render() {
        const { classes, reservation, openDrawerHandler } = this.props;
        const { 
            showCancelDialog, 
            tagList, 
            showAddTagModal, 
            showActivityLogs, 
            showAddNoteModal,
            showEmailLogModal 
        } = this.state;

        return (
            <div className={classes.root}>
                <div className={classes.resHeader}>

                    <div className={classes.infoContainer}>
                        <p className="primaryText">{toTitleCase(reservation?.guestName)}</p>
                        <p className="secondaryText">Booking Number {this.getBookingNumber()}</p>
                    </div>

                    <div className={classes.actionsContainer}>

                        {/* -----------------EMAIL LOGS BUTTON--------------------- */}
                        <IconButton 
                            imgSrc={ACTIONS.emailLogs.iconUrl}
                            onClick={() => this.triggerAction(ACTIONS.emailLogs.onClickEvent)}
                        />

                        {/* -----------------ACITVITY LOGS BUTTON--------------------- */}
                        <IconButton 
                            imgSrc={ACTIONS.activity.iconUrl}
                            onClick={() => this.triggerAction(ACTIONS.activity.onClickEvent)}
                        />
                        
                        {/* -----------------CANCEL RESERVATION BUTTON--------------------- */}
                        {!RESERVATION_CANCELLED_STATUSES.includes(reservation.status) && (
                            <IconButton 
                                imgSrc={ACTIONS.delete.iconUrl}
                                onClick={() => this.triggerAction(ACTIONS.delete.onClickEvent)}
                            />
                        )}

                        {/* -----------------TIMELINE DRAWER BUTTON--------------------- */}
                        <Hidden mdUp>
                            <IconButton
                                IconComponent={MenuRoundedIcon}
                                onClick={openDrawerHandler}
                            />
                        </Hidden>
                    </div>
                </div>

                {/* --------------RESERVATION TAGS LIST-------------------- */}
                <ReservationTags 
                    tagList={tagList} 
                    tagIDs={reservation?.tags}
                    addTagModalHandler={() =>{ this.setState({ showAddTagModal: true })}}
                />

                {/* --------------RESERVATION NOTES-------------------- */}
                <ReservationNotes 
                    addNoteModalHandler={() =>{ this.setState({ showAddNoteModal: true })}} 
                />
                
                {/* --------------CANCEL RESERVATION MODAL-------------------- */}
                {showCancelDialog && (
                    <CancelReservationModal
                        closeModalHandler={this.closeModalHandler}
                        handleCancelReservation={this.handleCancelReservation}
                    />
                )}

                {/* --------------ADD RESERVATION NOTE MODAL-------------------- */}
                {showAddNoteModal && (
                    <AddNoteModal 
                        saveReservationNote={this.saveReservationNoteHandler}
                        closeModalHandler={this.closeModalHandler}
                    />
                )}
                
                {/* --------------ADD RESERVATION TAG MODAL-------------------- */}
                {showAddTagModal && (
                    <AddTagModal 
                        tagList={tagList} 
                        saveReservationTag={this.saveReservationTagHandler}
                        createNewTag={this.createNewTagHandler}
                        closeModalHandler={this.closeModalHandler}
                    />
                )}

                {/* --------------ACTIVITY LOGS MODAL-------------------- */}
                {showActivityLogs && <ActivityLogModal closeModalHandler={this.closeModalHandler} />}

                {/* --------------EMAIL LOGS MODAL-------------------- */}
                {showEmailLogModal && <EmailLogsModal closeModalHandler={this.closeModalHandler} />}
            </div>
        )
    }
}

const mapStateToProps = state =>{
    const { reservationDetails, register } = state;
    return {
        reservation: reservationDetails?.reservation || {},
        propertyID: register.propertyID
    }
}

export default withStyles(styles)(connect(mapStateToProps)(InfoSection));