import React, { useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { TabPanel, a11yProps } from './roomTabConfig';
import RoomCategoryListItem from './roomCategoryListItem';

import { setSelectedRoomType } from "../../../redux/actions/bookingEngine";
import { fetchLowestRatePlan } from "../helper";

const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: "#fff",
      borderRadius: "20px",
      boxShadow: "0 2px 20px 0 #F0F0F0",
      // width: "500px"
    },
    appBar: {
      background: "none",
      borderRadius: "20px",
      boxShadow: "none",
    },
    tabContainer: {
      display: "flex",
      justifyContent: "center",
      paddingTop: 30,
      paddingBottom: 20,
    },
    tab: {
      borderRadius: 15,
      marginRight: 10,
      marginLeft: 10,
      marginTop: 5,
      marginBottom: 10,
      [theme.breakpoints.down("xs")]: {
        margin: "5.5vw"
      }
    },
    tabTitle: {
      color: "#484848",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: 1,
      width: "44px"
    },
    tabPanel: {
      "&:first-child": {
        [theme.breakpoints.down("xs")]: {
          padding: "6px"
        }
      }
    },
    swipe: {
      overflowY: "hidden"
    },
    tabSection: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    icon: {
      display: 'block',
      height: 35,
      marginBottom: 15,
      width: 35
    },
    indicator: {
      background: "#4444C2"
    },
    wip: {
      height: '100vh'
    },
    roomListContainer: {
      padding: '30px 20px'
    }
}));

const RoomCategoryList = ({
  allRooms,
  privateRooms,
  dormRooms,
  currency,
  dispatch,
  nextStep,
  checkOutDate,
  checkInDate,
  t
}) =>{
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = index => {
        setValue(index);
    };

    const getStyle = isActive => {
        return isActive
        ? { opactiy: 1 }
        : { opacity: 0.3 };
    };

    const selectRoomTypeHandler = (roomData) =>{
      const data = { 
        roomTypeID: roomData._id,
        dormOrPrivate: roomData.dormOrPrivate
      };
      dispatch(setSelectedRoomType(data));
      nextStep();
    }

    return (
      <div className={classes.root}>

        {dormRooms.length > 0 && (
          <div>
            <AppBar position="static" className={classes.appBar}>
              <Tabs
                TabIndicatorProps={{ style: { background: "transparent" } }}
                value={value}
                classes={{
                    indicator: classes.indicator
                }}
                className={classes.tabContainer}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="full width tabs example"
              >
                <Tab
                    className={classes.tab}
                    style={getStyle(value === 0)}
                    label={
                    <div className={classes.tabSection}>
                        <img src='/images/icons/grid.png' className={classes.icon} />
                        <div className={classes.tabTitle}>{t('bookingEngine.roomCategory.all')}</div>
                    </div>
                    }
                    {...a11yProps(0)}
                />
                <Tab
                    className={classes.tab}
                    style={getStyle(value === 1)}
                    label={
                    <div className={classes.tabSection}>
                        <img src='/images/icons/rooms.png' className={classes.icon} />
                        <div className={classes.tabTitle}>{t('bookingEngine.roomCategory.private')}</div>
                    </div>
                    }
                    {...a11yProps(1)}
                />
                <Tab
                    className={classes.tab}
                    style={getStyle(value === 2)}
                    label={
                    <div className={classes.tabSection}>
                        <img src='/images/icons/bunk-bed.png' className={classes.icon} />
                        <div className={classes.tabTitle}>{t('bookingEngine.roomCategory.shared')}</div>
                    </div>
                    }
                    {...a11yProps(2)}
                />
              </Tabs>
          </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
              className={classes.swipe}
            >
              <TabPanel
                  className={classes.tabPanel}
                  value={value}
                  index={0}
                  dir={theme.direction}
              >
                <RoomCategoryListItem 
                  roomListData={allRooms}
                  currency = {currency}
                  selectRoomTypeHandler = {selectRoomTypeHandler}
                  t = {t}
                />
              </TabPanel>
              <TabPanel
                  className={classes.tabPanel}
                  value={value}
                  index={1}
                  dir={theme.direction}
              >
                <RoomCategoryListItem 
                  roomListData={privateRooms}
                  currency = {currency}
                  selectRoomTypeHandler = {selectRoomTypeHandler} 
                  t = {t}
                />
              </TabPanel>
              <TabPanel
                  className={classes.tabPanel}
                  value={value}
                  index={2}
                  dir={theme.direction}
              >
                <RoomCategoryListItem 
                  roomListData={dormRooms}
                  currency = {currency}
                  selectRoomTypeHandler = {selectRoomTypeHandler}
                  t = {t}
                />
              </TabPanel>
            </SwipeableViews>
          </div>
        )}
        
          
        {dormRooms.length == 0 && (
          <div className={classes.roomListContainer}>
            <RoomCategoryListItem 
              roomListData={allRooms}
              currency = {currency}
              selectRoomTypeHandler = {selectRoomTypeHandler}
              t = {t}
            />
          </div>
        )}
          
      </div>
    );
}
const mapStateToProps = state =>{
  const { bookingEngine } = state;
  const roomList = bookingEngine.roomList || [];
  const roomTypes = bookingEngine.roomTypes;
  let allRooms = [];
  let privateRooms = [];
  let dormRooms = [];
  const bookedRoomIDs = Object.keys(bookingEngine.bookingDetails).map(key => key);
  
  Object.keys(roomList).map(id =>{
    // Check to exclude rooms already added to booking cart and room availabity is zero.
    if(!bookedRoomIDs.includes(id) && roomList[id].available && roomList[id].available > 0 && roomTypes[id]){

      const minRoomCost = fetchLowestRatePlan(roomList[id].rates);
      const roomImages =  roomTypes[id].images;
      let perNightCost = (minRoomCost / bookingEngine.bookingDuration).toFixed(2);

      const roomDataObj = { 
        ...roomTypes[id],
        perNightCost: parseFloat(perNightCost),
        roomImage: roomImages && (roomImages.length > 0) ? roomImages[roomImages.length - 1] : '/images/dorm2.jpg'
      };
      roomTypes[id].dormOrPrivate === "private" ? privateRooms.push(roomDataObj): dormRooms.push(roomDataObj);
      allRooms.push(roomDataObj);
    }
  });
  
  return{
    allRooms,
    privateRooms,
    dormRooms,
    currency: bookingEngine.currency,
    checkInDate: bookingEngine.checkInDate,
    checkOutDate: bookingEngine.checkOutDate
  }
};
export default withTranslation()(connect(mapStateToProps)(RoomCategoryList));