import React from 'react';
import {
    Box,
    Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TermsAndConditions from './TermsAndConditions'

const secondaryColor = '#5C5C5C';
const useStyles = makeStyles(theme => ({
    root: {
        '&:hover': {
            backgroundColor: '#fff',
        },
    },
    checkBoxContainer:{
        display: "flex",
        alignItems: "center"
    },
    icon: {
        width: 30,
        height: 30,
        boxShadow: '0 0 8px 0px #dedede',
        borderRadius: '10px',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: secondaryColor,
        backgroundImage: secondaryColor,
        '&:before': {
            display: 'block',
            width: 30,
            height: 30,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: secondaryColor,
        },
    },
    checkBoxLabel: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: '12px',
        marginLeft: '10px',
        lineHeight: "20px"
    },
    link: {
        color: 'blue',
        cursor: 'pointer'
    }
}));

const CustomCheckBox = ({
    label, 
    name, 
    checked,  
    onChangeHandler,
    termsAndConditions = false,
    termsConditions
}) => {
    const classes = useStyles();
    const [terms, setTerms] = React.useState(false)

    const handleOpenTerms = () => {
        setTerms(true);
        window.Intercom && window.Intercom('update', { "hide_default_launcher": true});
    }

    const handleAcceptTerms = () => {
        setTerms(false);
        window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
        onChangeHandler()
    }

    const handleCloseTerms = () => {
        setTerms(false);
        window.Intercom && window.Intercom('update', { "hide_default_launcher": false});
    }

    return (
        <Box className={classes.checkBoxContainer}>
            <Box>
                <Checkbox
                    className={classes.root}
                    disableRipple
                    color="default"
                    checkedIcon={<span className={`${classes.icon} ${classes.checkedIcon}`} />}
                    icon={<span className={classes.icon} />}
                    inputProps={{ 
                        'name': name,
                        'aria-label': 'decorative checkbox' 
                    }}
                    checked={checked}
                    onChange={onChangeHandler}
                />
            </Box>
            {(!termsAndConditions) && <Box className={classes.checkBoxLabel}>{label}</Box>}
            {(termsAndConditions) && <Box className={classes.checkBoxLabel}>I agree to the property's <span className={classes.link} onClick={handleOpenTerms}>terms and conditions</span>*</Box>}
            
            {terms && <TermsAndConditions open={terms} handleClose={handleCloseTerms} handleAccept={handleAcceptTerms} termsAndConditions={termsConditions}/>}

        </Box>
    );
}

export default CustomCheckBox;