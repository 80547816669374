import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Dialog from '../common/CustomDialog';
import Footer from '../new_reservation/common/Footer';
import Warning from '@material-ui/icons/PriorityHigh';

const useStyles = makeStyles(theme =>({
    root: {
        '& .dialogBody': {
            minHeight: "300px",
            maxHeight: "300px",
        },
    },
    warningContainer: {
      alignItems: 'center',
      color: '#b83b3b',
      display: 'flex',
      fontSize: '1.3rem',
      marginBottom: 30,
    },
    warning: {
      color: '#b83b3b',
      marginRight: 20,
    },
    description: {
      color: '#484848',
      fontSize: '1.3rem',
      lineHeight: 1.4,
    },
    finePrint: {
      marginTop: 20,
    }
}));

const AddNoteModal = props =>{
    const { 
      handleConfirm,
      handleCancel,
      dispatch 
    } = props;
    
    const classes = useStyles();

    return(
        <Dialog
            className={classes.root}
            headerTitle={'Erase Register Settings?'}
            closeModalHandler={handleCancel}
        >
            
          <div className={classes.warningContainer}>
            <Warning className={classes.warning} />
            Are you sure you would like to permanently delete this register's settings?
          </div>

          <div className={classes.description}>
            Your property's setting have been saved to this device. Erasing the register settings should only be used 
            if you no longer intend to use Counter Frontdesk on this electronic device. If you need to setup Counter 
            Frontdesk again, a manager will have to enter a username and password.
          </div>

          <div className={clsx(classes.description, classes.finePrint)}>
            <strong>Please Note:</strong> Locking the register is sufficient once you have ended your daily shift.
          </div>


          <Footer 
              label="Delete" 
              isBtnDisabled={false}
              onBtnClick={handleConfirm}
          />

        </Dialog>
    )
}

export default AddNoteModal;