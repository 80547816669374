import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import CalendarBedOptions from './CalendarBedOptions';
import ChooseRoomForm from './ChooseRoomForm';
import { fetchCurrencyFormat, getDatesOfRange } from '../../../../utils/utility';
import { Button } from '@material-ui/core';
import Footer from '../../common/Footer';

const useStyles = makeStyles(theme =>({
    root: {
        fontSize: '1.2rem'
    },
    container: {
        margin: '10px 2px',
        border: '1px solid #f2f2f2',
        borderRadius: 5,
        padding: 20,
    },
    button: {
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#13408d',
        color: '#ffffff',
        borderRadius: 25,
        padding: 15,
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#13408d',
            color: '#ffffff',
        }
    },
    error:{
        margin: '0px 2px',
        padding: 10,
        border: '1px solid #f2f2f2',
        borderRadius: 5,
        color: 'red',
    },
    buttonContainer: {
        '& div':{
            marginBottom: 10
        }
    }
}));

const ManualSelectBed = (props) => {
    const classes = useStyles();
    const { data, range, options, view, roomTypes, triggerBuild, timezone, currency } = props;
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [disableButton, setDisableButton] = React.useState(false);
    const [statusCheck, setStatusCheck] = React.useState({ check: false, date: null, error: false });
    const [form, setForm] = React.useState({ guestNumber: data.guestNumber });
    const [rangeArray, setRangeArray] = React.useState(getDatesOfRange(range.startDate, range.endDate, null, 'stringArray'));

    React.useEffect(() => {
        setRangeArray(getDatesOfRange(range.startDate, range.endDate, null, 'stringArray'))
        buildFormOnMountHandler();
    }, [options, triggerBuild])

    const buildFormOnMountHandler = () => {
        let itemIndex = data?.items?.length - 1;
        if(range.startDate < data.checkIn){
            itemIndex = 0;
        }

        let selectedItem = data?.items[itemIndex];
        let selectType = selectedItem?.dormOrPrivate;
        let alternateType = selectType === 'dorm' ? 'private': 'dorm';

        let selectedOptions = options.filter(option => option.rate && roomTypes[option.roomTypeID].dormOrPrivate === selectType)
        let alternateOptions = options.filter(option => option.rate && roomTypes[option.roomTypeID].dormOrPrivate === alternateType)

        let datesOfRange = getDatesOfRange(range.startDate, range.endDate, timezone, 'objectArray');
        let form = { guestNumber: data.guestNumber, items: datesOfRange };

        form.items.map(item => {
            let itemData = { ...item };
            let selectedBed = selectedOptions.sort(option => selectedItem.bedID === option.bedID ? -1 : 1).find(option => option.date === itemData.date && option.roomTypeID);

            if(selectedBed){
                item.roomTypeID = selectedBed.roomTypeID;
                item.rate = selectedBed.rate || 0;
                item.roomID = selectedBed.roomID;
                item.bedID = selectedBed.bedID;
                return item;
            }
            let alternateBed = alternateOptions.find(option => option.date === itemData.date && option.roomTypeID);
            if(alternateBed){
                item.roomTypeID = alternateBed.roomTypeID;
                item.rate = alternateBed.rate || 0;
                item.roomID = alternateBed.roomID;
                item.bedID = alternateBed.bedID;
                return item;
            }
        })
        setForm({ ...form });
        setSelectedDate(range.startDate)
    }

    const selectDateHandler = (date) => {
        setSelectedDate(date);
    }

    const onStatusCheckHandler = (check, date) => {
        if(!check){
            setStatusCheck({ check: false, date: null, error: false });
            selectDateHandler(date)
        } else{
            setStatusCheck({ check: false, date: null, error: true });
        }
    }

    const submitDataHandler = () => {
        let payload = { ...form };
        payload.items = form.items.map(item => {
            item.rate = parseFloat(item.rate || 0)
            return item
        })
        props.submitDataHandler(payload);
    }

    return (
        <div className={classes.root}>
            <CalendarBedOptions
                rangeArray={rangeArray}
                selectedDate={selectedDate}
                data={form}
                view={view}
                selectDateHandler={(date) => setStatusCheck({check: true, date: date})}
                currency={fetchCurrencyFormat(currency)}
            />

            {statusCheck.error && <div className={classes.error}>Select a bed/room before proceeding</div>}

            {selectedDate &&
                <React.Fragment>
                    <div className={classes.container}>
                    <ChooseRoomForm
                        statusCheck={statusCheck}
                        selectedDate={selectedDate}
                        startDate={range.startDate}
                        endDate={range.endDate}
                        formData={form}
                        options={options}
                        itemID={data.guestNumber}
                        originalRoomTypeID={data?.items?.find(item => item.date === selectedDate)?.roomTypeID}
                        saveDraftHandler={(result) => {
                            setForm({ ...result });
                            setStatusCheck({ check: false, date: null, error: false });
                        }}
                        onStatusCheckHandler={onStatusCheckHandler}
                        disableButtonHandler={setDisableButton}
                    />
                    </div>

                    {props.errorMessage && <div className={classes.error} style={{marginBottom: 5}}>{props.errorMessage}</div>}
                    
                    {/* {!disableButton && <div className={classes.buttonContainer}>
                        <Button className={classes.button} disabled={disableButton} onClick={submitDataHandler}>{props.buttonText || 'Apply changes'}</Button>
                    </div>} */}

                    <Footer 
                        label={props.buttonText || 'Apply changes'} 
                        isBtnDisabled={disableButton}
                        onBtnClick={submitDataHandler}
                    />

                </React.Fragment>
            }
        </div>
    )
}

const mapStateToProps = state =>{
    const { rooms } = state;
    return {
        roomTypes: rooms.roomTypes,
        rooms: rooms.rooms,
        beds: rooms.beds,
    }   
}

export default connect(mapStateToProps)(ManualSelectBed);