import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import {
  Button,
  Grid,
  Typography,
  InputBase,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import {
  fetchDateFormat,
  DATE_FORMATS,
  fetchDatePerTimezone,
  checkDateIfGreater,
} from "../../utils/utility";
import Select from "react-select";
import { get, set } from "lodash";
import { GUEST_DOC_TYPES as documentTypeOptions } from "../../common/constants/guest";
import { GENDER_OPTIONS as genderOptions } from "../../utils/constants";

const styles = (theme) => ({
  more: {
    fontSize: "1.4rem",
    color: "#666666",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  dropdownInput: {
    "& > div:not(.css-26l3qy-menu)": {
      minHeight: 30,
      border: 0,
      backgroundColor: "#f8f9fb",
      boxShadow: "none",
    },
    "& > div > div > div": {
      padding: "0 8px",
    },
    "& .css-26l3qy-menu > div > div": {
      padding: 8,
    },
    "& .css-26l3qy-menu": {
      zIndex: 5000,
    },
  },
  submitSection: {
    // marginTop: 20,
    textAlign: "end",
  },
  form: {
    // height: 'calc(100% - 75px)',
    // overflowY: 'scroll',
    // overflowX: 'hidden',
    padding: 20,
    backgroundColor: "#fff",
  },
  label: {
    fontWeight: 600,
    fontSize: "1.1rem",
    marginBottom: 10,
    marginTop: 10,
  },
  field: {
    background: "#f8f9fb",
    borderRadius: 2,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
    "& .css-1okebmr-indicatorSeparator": {
      display: "none",
    },
    "& .css-6q0nyr-Svg": {
      color: "#000000",
      width: 30,
      height: 30,
    },
  },
  fieldError: {
    border: "1px solid red",
  },
  icon: {
    width: 25,
    height: 25,
  },
  moreContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  delete: {
    marginTop: "-20px",
    "& img": {
      cursor: "pointer",
    },
  },
});

// const genderOptions = [
//   { value: "Male", label: "Male" },
//   { value: "Female", label: "Female" },
//   { value: "N/A", label: "N/A" }
// ];

// const documentTypeOptions = [
//   { value: "Passport", label: "Passport" },
//   { value: "Drivers Licence", label: "Drivers Licence" },
//   { value: "Other", label: "Other" }
// ];

class GuestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: true,
      showAddress: false,
      customerID: null,
      displayButton: false,
      error: this.handleGetInitialFormState(),
      customerInfo: this.handleGetInitialCustomerState(),
      countryOptions: this.handleGetCountries(),
    };
  }

  componentDidMount() {
    if (this.props.customerInfo) {
      this.handleProcessData(this.props.customerInfo);
    }
    if (this.props.new) {
      this.setState({ showMore: false });
    }
    // if(this.props.mode === "edit") {
    //   this.setState({showAddress: true });
    // }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.customerInfo &&
      this.props.mode === "edit" &&
      !_.isEqual(this.props.customerInfo, prevProps.customerInfo)
    ) {
      this.handleProcessData(this.props.customerInfo);
    }
  }

  handleProcessData = (data) => {
    const dateOfBirthArr = data.dateOfBirth ? data.dateOfBirth.split("T") : [];
    const dateOfBirth = dateOfBirthArr[0] || "";
    let address = { ...data.address };
    let intialState = this.handleGetInitialCustomerState();
    let customerInfo = { ...intialState, ...data };
    customerInfo.dateOfBirth = dateOfBirth;
    customerInfo.address = { ...intialState.address, ...data.address };
    customerInfo.address.country = address.country
      ? i18nIsoCountries.getAlpha3Code(address.country, "en") || address.country
      : "";
    customerInfo.gender = customerInfo.gender || "N/A";
    delete customerInfo.agreeUpdates;
    delete customerInfo._id;
    delete customerInfo.__v;
    delete customerInfo.createdAt;
    delete customerInfo.updatedAt;
    delete customerInfo.propertyID;
    delete customerInfo.propertyID;
    delete customerInfo.tags;
    delete customerInfo.age;

    this.setState({
      customerInfo: customerInfo,
      customerID: data._id,
    });
  };

  handleInputChange = (data, keyName) => {
    const customerInfo = { ...this.state.customerInfo };
    if (keyName === "country") customerInfo.address.country = data.value;
    else set(customerInfo, keyName, data.value);
    console.log(customerInfo);

    this.setState({ customerInfo: customerInfo, displayButton: true });
    this.handleReturnCustomerInfo(customerInfo);
  };

  handleFieldChange = (name) => (event) => {
    let error = { ...this.state.error };
    const customerInfo = { ...this.state.customerInfo };

    set(customerInfo, name, event.target.value);

    if (event.target.value) {
      set(error, name, false);
    }

    this.setState({ customerInfo, error, displayButton: true });
    this.handleReturnCustomerInfo(customerInfo);
  };

  handleReturnCustomerInfo = (customerInfo) => {
    let count = this.props.currentCount;
    if (count >= 0 && this.props.new) {
      this.props.handleGuestInfo(count, customerInfo);
    }
  };

  handleManageCustomer = () => {
    const mode = this.props.mode;
    const customerInfo = { ...this.state.customerInfo };
    let hasErrors = this.handleValidation(customerInfo);

    if (!hasErrors) {
      if (customerInfo.dateOfBirth)
        customerInfo.dateOfBirth = fetchDateFormat(
          customerInfo.dateOfBirth,
          DATE_FORMATS.DEFAULT_DATE
        );
      if (mode === "edit")
        this.props.handleUpdateCustomer(
          this.props.customerInfo._id,
          customerInfo,
          this.props.isPrimary
        );
      else if (mode === "add") this.props.handleCreateCustomer(customerInfo);
    }
  };

  handleDeleteGuest = () => {
    this.props.handleDeleteCustomer(this.props.customerInfo._id);
  };

  handleValidation = (data) => {
    let error = { ...this.state.error };
    let hasError = false;
    const mandatoryFields = ["firstName", "lastName"];
    mandatoryFields.map((item) => {
      if (!data[item]) {
        error[item] = true;
        hasError = true;
      }
    });
    if (
      data.dateOfBirth &&
      checkDateIfGreater(data.dateOfBirth, fetchDatePerTimezone(null))
    ) {
      error.dateOfBirth = true;
      // error.dateOfBirth.message = "Date cannot be in future";
      hasError = true;
    }

    // if (data.email) {
    //   error.phone = false;
    // } else if (data.phone) {
    //   error.email = false;
    // }
    this.setState({ error: error });
    return hasError;
  };

  handleGetInitialFormState() {
    const error = {
      address: {},
      documents: [{}],
    };
    return error;
  }

  handleShowMore = () => {
    this.setState({ showMore: true });
  };

  handleGetInitialCustomerState = () => {
    let customerInfo = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      dateOfBirth: "",
      gender: "N/A",
      companyName: "",
      taxID: "",
      nationality: "",
      documents: [{}],
      address: {
        lineOne: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
      },
    };
    return customerInfo;
  };

  handleGetCountries = () => {
    const countries = i18nIsoCountries.getNames("en");
    let countryOptions = [];
    for (var key in countries) {
      if (countries.hasOwnProperty(key)) {
        countryOptions.push({
          value: i18nIsoCountries.alpha2ToAlpha3(key),
          label: countries[key],
        });
      }
    }
    return countryOptions;
  };

  render() {
    const classes = this.props.classes;
    const mode = this.props.mode;
    const customerInfo = { ...this.state.customerInfo };
    let error = { ...this.state.error };
    const countryOptions = [...this.state.countryOptions];
    let count = this.props.currentCount;
    count = count >= 0 ? count : -1;
    let dateOfBirthValue = this.props.customerInfo
      ? fetchDateFormat(
          this.props.customerInfo.dateOfBirth,
          DATE_FORMATS.DEFAULT_DATE,
          this.props.timezone
        )
      : "";

    return (
      <div className={classes.form}>
        <Grid container spacing={2}>
          {/* {this.props.delete &&
            <Grid item xs={12} sm={12} align="right" className={classes.delete}>
              <img className={classes.icon} src="/images/icons/bin.png" onClick={() => this.handleDeleteGuest()} />
            </Grid>
          } */}

          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.label}>First Name*</div>
            <div
              className={clsx(classes.field, {
                [classes.fieldError]: error.firstName,
              })}
            >
              <InputBase
                disabled={this.props.disabled}
                type="text"
                id={"firstName" + count}
                fullWidth
                onChange={this.handleFieldChange("firstName")}
                error={error.firstName}
                value={customerInfo.firstName}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.label}>Last Name*</div>
            <div
              className={clsx(classes.field, {
                [classes.fieldError]: error.lastName,
              })}
            >
              <InputBase
                disabled={this.props.disabled}
                type="text"
                id={"lastName" + count}
                fullWidth
                onChange={this.handleFieldChange("lastName")}
                error={error.lastName}
                value={customerInfo.lastName}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.label}>Email</div>
            <div
              className={clsx(classes.field, {
                [classes.fieldError]: error.email,
              })}
            >
              <InputBase
                disabled={this.props.disabled}
                type="text"
                id={"email" + count}
                fullWidth
                onChange={this.handleFieldChange("email")}
                error={error.email}
                value={customerInfo.email}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.label}>Phone Number</div>
            <div
              className={clsx(classes.field, {
                [classes.fieldError]: error.phone,
              })}
            >
              <InputBase
                disabled={this.props.disabled}
                type="text"
                id={"phone" + count}
                fullWidth
                onChange={this.handleFieldChange("phone")}
                error={error.phone}
                value={customerInfo.phone}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className={classes.label}>Nationality</div>
            <div
              className={clsx(classes.field, {
                [classes.fieldError]: error.nationality,
              })}
            >
              <Select
                isDisabled={this.props.disabled}
                value={countryOptions.find(
                  (e) => e.value === customerInfo.nationality
                )}
                isSearchable={true}
                name="nationality"
                className={classes.dropdownInput}
                onChange={(e) => this.handleInputChange(e, "nationality")}
                options={countryOptions}
              />
            </div>
          </Grid>

          {/* -------------------------------------------- */}
          {/* SHOW MORE INPUTS SECTION */}
          {/* -------------------------------------------- */}
          {!this.props.showLess && this.state.showMore && (
            <>
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>Date of Birth</div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: error.dateOfBirth,
                  })}
                >
                  <InputBase
                    disabled={this.props.disabled}
                    type="date"
                    id={"dateOfBirth" + count}
                    fullWidth
                    onChange={this.handleFieldChange("dateOfBirth")}
                    error={error.dateOfBirth}
                    defaultValue={dateOfBirthValue}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>Gender</div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: error.gender,
                  })}
                >
                  <Select
                    isDisabled={this.props.disabled}
                    value={genderOptions.find(
                      (e) => e.value === customerInfo.gender
                    )}
                    isSearchable={true}
                    name="gender"
                    className={classes.dropdownInput}
                    onChange={(e) => {
                      this.handleInputChange(e, "gender");
                    }}
                    options={genderOptions}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>Document type</div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: get(error, "documents[0].type"),
                  })}
                >
                  <Select
                    isDisabled={this.props.disabled}
                    value={documentTypeOptions.find(
                      (e) => e.value === get(customerInfo, "documents[0].type")
                    )}
                    isSearchable={true}
                    name="gender"
                    className={classes.dropdownInput}
                    onChange={(e) => {
                      this.handleInputChange(e, "documents[0].type");
                    }}
                    options={documentTypeOptions}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>Document number</div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: get(error, "documents[0].number"),
                  })}
                >
                  <InputBase
                    disabled={this.props.disabled}
                    type="text"
                    id={"number" + count}
                    fullWidth
                    onChange={this.handleFieldChange("documents[0].number")}
                    error={get(error, "documents[0].number")}
                    value={get(customerInfo, "documents[0].number", "")}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>Company name</div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: error.companyName,
                  })}
                >
                  <InputBase
                    disabled={this.props.disabled}
                    type="text"
                    id={"companyName" + count}
                    fullWidth
                    onChange={this.handleFieldChange("companyName")}
                    error={error.companyName}
                    value={customerInfo.companyName}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.label}>Tax ID</div>
                <div
                  className={clsx(classes.field, {
                    [classes.fieldError]: error.taxID,
                  })}
                >
                  <InputBase
                    disabled={this.props.disabled}
                    type="text"
                    id={"taxID" + count}
                    fullWidth
                    onChange={this.handleFieldChange("taxID")}
                    error={error.taxID}
                    value={customerInfo.taxID}
                  />
                </div>
              </Grid>

              {!this.state.showAddress ? (
                <Grid item xs={12} sm={12} md={6}>
                  <Typography
                    className={classes.more}
                    onClick={() => this.setState({ showAddress: true })}
                  >
                    Show address <ExpandMoreIcon />
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.label}>Address</div>
                    <div
                      className={clsx(classes.field, {
                        [classes.fieldError]: error.address.lineOne,
                      })}
                    >
                      <InputBase
                        disabled={this.props.disabled}
                        type="text"
                        id={"address" + count}
                        fullWidth
                        onChange={this.handleFieldChange("address.lineOne")}
                        error={error.address.lineOne}
                        value={
                          customerInfo.address
                            ? customerInfo.address.lineOne
                            : "Address Line One"
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.label}>City</div>
                    <div
                      className={clsx(classes.field, {
                        [classes.fieldError]: error.address.city,
                      })}
                    >
                      <InputBase
                        disabled={this.props.disabled}
                        type="text"
                        id={"city" + count}
                        fullWidth
                        onChange={this.handleFieldChange("address.city")}
                        error={error.address.city}
                        value={
                          customerInfo.address ? customerInfo.address.city : ""
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.label}>State</div>
                    <div
                      className={clsx(classes.field, {
                        [classes.fieldError]: error.address.state,
                      })}
                    >
                      <InputBase
                        disabled={this.props.disabled}
                        type="text"
                        id={"state" + count}
                        fullWidth
                        onChange={this.handleFieldChange("address.state")}
                        error={error.address.state}
                        value={
                          customerInfo.address ? customerInfo.address.state : ""
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.label}>Zip Code</div>
                    <div
                      className={clsx(classes.field, {
                        [classes.fieldError]: error.address.zipCode,
                      })}
                    >
                      <InputBase
                        disabled={this.props.disabled}
                        type="text"
                        id={"zipCode" + count}
                        fullWidth
                        onChange={this.handleFieldChange("address.zipCode")}
                        error={error.address.zipCode}
                        value={
                          customerInfo.address
                            ? customerInfo.address.zipCode
                            : ""
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.label}>Country</div>
                    <div
                      className={clsx(classes.field, {
                        [classes.fieldError]: error.country,
                      })}
                    >
                      <Select
                        isDisabled={this.props.disabled}
                        value={
                          customerInfo.address && customerInfo.address.country
                            ? countryOptions.find(
                                (e) => e.value === customerInfo.address.country
                              )
                            : null
                        }
                        isSearchable={true}
                        name="country"
                        className={classes.dropdownInput}
                        onChange={(e) => this.handleInputChange(e, "country")}
                        options={countryOptions}
                      />
                    </div>
                  </Grid>
                </>
              )}
            </>
          )}

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                {this.props.masterBookerView && (
                  <FormControlLabel
                    value="masterbooker"
                    control={
                      <Checkbox
                        color="primary"
                        onChange={(e) =>
                          this.props.toggleMasterbooker(e.target.checked)
                        }
                      />
                    }
                    label="This is a masterbooker"
                    labelPlacement="end"
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {!this.props.showLess && !this.state.showMore && (
                  <Typography
                    className={classes.more}
                    onClick={this.handleShowMore}
                  >
                    Show more <ExpandMoreIcon />
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          {!this.props.new && (
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                justify="flex-end"
                className={classes.buttonSection}
              >
                {this.state.displayButton && (
                  <Grid item xs={12} className={classes.submitSection}>
                    <Button
                      variant={"outlined"}
                      className={classes.button}
                      onClick={this.handleManageCustomer}
                    >
                      Save
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(GuestForm);
