import http from "../utils/http";
// import tempRoomsList from '../../components/housekeeping/tempData';
import { normalize } from "normalizr";
import { housekeepingSchema } from "../schema/housekeeping";

export function getHousekeepingData() {
    const ACTION_NAME = "GET_HOUSEKEEPING_DATA";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST`});
        return http
        .get(`/api/bedStatus/`)
        .then(successResult => {
            const data = successResult?.data;
            let updatesRoomslist = [];
            
            //Adding unique id field to data as it can have multiple entries with same roomID (roomMapping scenario)...
            for(const [index, room] of Object.entries(data)){
                updatesRoomslist.push({
                    ...room,
                    id: `${room.roomID}_${index}`
                });
            }

            const normalizedData = normalize(updatesRoomslist, housekeepingSchema);
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: normalizedData.entities
            });

        })
        .catch(errorResult => {
            let error =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        });
    }
}

export function updateBedsStatus(payload) {
    const ACTION_NAME = "UPDATE_BEDS_STATUS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST`});
        return http
        .patch(`/api/bedStatus/`, { beds: [ ...payload ] })
        .then(successResult => {
            const data = successResult?.data;
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: data
            });

        })
        .catch(errorResult => {
            let error =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        });
    }
}