import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import 'react-dates/initialize';
import { withTranslation } from 'react-i18next';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import moment from "moment-timezone";

import { fetchDateFormat, DATE_FORMATS } from "../../utils/utility";

import { fetchAvailableRooms, resetBookingCart } from '../../redux/actions/bookingEngine';

const styles = theme => ({
    mainContainer: {
        backgroundColor: "#fff",
        padding: "30px",
        borderRadius: "20px",
        boxShadow:" 0 0 8px 0px #dedede"
    },
    dates: {
        '& .DateInput': {
            paddingLeft: 0,
            width: '50%',
        },
        '& .DateInput_input': {
            borderRadius: 10,
            boxShadow: '0 2px 20px #F0F0F0',
            color: '#333333',
            fontSize: '1.4rem',
            paddingLeft: 20,
            paddingRight: 0,
            width: 'calc(100% - 20px)',
        },
        '& .DateInput_input__focused': {
            borderBottom: '2px solid #000000',
        },
        '& .DateRangePickerInput_arrow': {
            display: 'none',
        },
        '& .DateRangePickerInput': {
            borderRadius: 10,
            border: 'none',  
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 0px',
                '&.DateInput': {
                    flex: 1
                },
                '&#checkinDateID':{
                    borderRight: '1px solid #dedede'
                },
            }
        },
        '& .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover': {
            background: '#000000',
            border: '1px double #000000'
        },
        '& .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover': {
            background: '#EEF2F1',
            border: '1px double #EEF2F1',
            color: '#484848',
        },
        '& .CalendarDay__selected_span': {
            background: '#EEF2F1',
            border: '1px double #EEF2F1',
            color: '#484848',
        }
    },
    nextButton: {
        background: '#000000',
        fontSize: '1.6rem',
        fontWeight: 600,
        width: "100%",
        borderRadius: "10px",
        marginTop: "30px"
    },
    errorMessage: {
        color: '#f44336',
        marginLeft: '14px',
        '&::first-letter':{
            textTransform: 'uppercase'
        }
    },
});

class TravelDetails extends Component {
    state = {
        checkInDate: null,
        checkOutDate: null,
        focusedInput: null
    };

    componentDidMount(){
        //this.props.dispatch(resetBookingCart());
    }

    handleDateChange = ({startDate,endDate}) =>{
        this.setState({ 
            checkInDate: startDate, 
            checkOutDate: endDate
        });
    }

    handleFetchAvailableRooms = () =>{
        this.props.dispatch(resetBookingCart());
        const { checkInDate, checkOutDate } = this.state;
        const { nextStep, propertyID } = this.props;
        const params = { 
            checkIn: fetchDateFormat(checkInDate, DATE_FORMATS.DEFAULT_DATE), 
            checkOut: fetchDateFormat(checkOutDate, DATE_FORMATS.DEFAULT_DATE) 
        };
        this.props.dispatch(fetchAvailableRooms(propertyID, params, nextStep));
    };


    dateChange = ({ startDate, endDate }) => {
        this.setState({ 
            checkInDate: startDate, 
            checkOutDate: endDate 
        });
    };

    focusChange = focusedInput => {
        this.setState({ focusedInput });
    };
    
    render() {
        const { classes, PropertyTimezone, t } = this.props;
        const {
            checkInDate,
            checkOutDate,
            focusedInput
        } = this.state;

        const smallDevice = window.matchMedia('(max-width: 700px)').matches;
        const numberOfMonths = smallDevice ? 1 : 2;

        return (
            <Box className = {classes.mainContainer} >
                <Box className={classes.dates}>
                    <DateRangePicker
                        startDate={checkInDate} // momentPropTypes.momentObj or null,
                        startDateId="checkinDateID" // PropTypes.string.isRequired,
                        endDate={checkOutDate} // momentPropTypes.momentObj or null,
                        endDateId="checkoutDateID" // PropTypes.string.isRequired,
                        onDatesChange={this.dateChange} // PropTypes.func.isRequired,
                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={this.focusChange} // PropTypes.func.isRequired,
                        numberOfMonths={numberOfMonths}
                        startDatePlaceholderText={t('bookingEngine.checkIn')}
                        endDatePlaceholderText={t('bookingEngine.checkOut')}
                        isOutsideRange={day => day.isBefore(moment.tz(new Date(), PropertyTimezone).format('YYYY-MM-DD'))}
                        displayFormat={'DD MMM YYYY'}
                        readOnly
                        withPortal={smallDevice}
                        block
                        hideKeyboardShortcutsPanel
                    />
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    className = {classes.nextButton}
                    onClick = {this.handleFetchAvailableRooms}
                >
                    {t('actions.next')}
                </Button>
            </Box>
        );
    }
}

const mapStateToProps = state => {
    const { bookingEngine } = state;
    return{
        PropertyTimezone:  bookingEngine.propertyDetails.timezone
    }
};
export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(TravelDetails)));