// Filters Reducer
const defaultState = []

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'CREATE_BOOKING_SOURCE_SUCCESS':
      let data = [...new Set([...state, action.payload.name])];
      return [...data]
    case 'FETCH_BOOKING_SOURCE_SUCCESS':
      let source = action.payload && action.payload.map(d => d.name);
      source = [...new Set([...source])];
      return [...source]
    default:
      return state;
  }
}