import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Select from 'react-select';

import Dialog from '../common/CustomDialog';
import Footer from '../common/Footer';
import { InputBase } from '@material-ui/core';
import { resetError } from '../../../redux/actions/reservations';

const useStyles = makeStyles(theme =>({
    root: {
        '& .dialogBody': {
            minHeight: "360px",
            maxHeight: "360px",
        },
    },
    label: {
        margin: '15px 0px'
    },
    textField: {
        border: "1px solid #e0e0e0",
        borderRadius: "5px",
        height: "120px",
        padding: "0px 20px",
        width: '100%'
    },
}));

const AddNoteModal = props =>{
    const { 
        closeModalHandler, 
        saveReservationNote, 
        createNewTag, 
        isLoading, 
        errors, 
        dispatch 
    } = props;
    
    const classes = useStyles();
    const [note, setNote] = useState('');    

    const submitHandler = () =>{
        saveReservationNote(note);
    }

    const getDisableStatus = () =>{
        if(!note.trim()) return true;
        return false;
    }

    const handleCloseError = () =>{
        dispatch(resetError('CREATE_RESERVATION_NOTE'));
    }

    return(
        <Dialog
            className={classes.root}
            headerTitle={'Add Note'}
            closeModalHandler={closeModalHandler}
            isLoading={isLoading}
            errors={errors}
            handleCloseError={handleCloseError}
        >
            
            <InputBase 
                className={classes.textField}
                placeholder="Enter new note here.."
                rows={5}
                multiline
                value={note}
                onChange={e => setNote(e.target.value)}
            />

            <Footer 
                label="Post" 
                isBtnDisabled={getDisableStatus()}
                onBtnClick={submitHandler}
            />

        </Dialog>
    )
}

const mapStateToProps = state =>{
    const { error, loading } = state;
    return {
        isLoading: loading.CREATE_RESERVATION_NOTE,
        error: error.CREATE_RESERVATION_NOTE
    }
}

export default connect(mapStateToProps)(AddNoteModal);