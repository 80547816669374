import React, { Component } from "react";
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import clsx from "clsx";
import {
  getMomentFormat,
  fetchDatePerTimezone,
  fetchDateFormat,
  DATE_FORMATS,
  fetchNoOfNights
} from "../../utils/utility";

const styles = (theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  dateRange: {
    fontSize: '1.3rem',
    fontWeight: 700,
    paddingLeft: theme.spacing(2),
    textTransform: 'uppercase',
  },
  fullDate: {
    alignItems: 'center',
    background: 'transparent',
    boxShadow: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: 20,
  },
  dateSection: {
    borderBottom: "0px solid #dddddd",
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(2),
    textAlign: "center",
    width: "100%",
  },
  roomSection: {
    borderBottom: "0px solid #dddddd",
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    textAlign: "center",
    width: "100%",
  },
  day: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '10%',
  },
  week: {
    color: '#484848',
    display: 'block',
    fontSize: '1rem',
    fontWeight: 600,
    // paddingBottom: '10px',
    textTransform: 'uppercase'
  },
  // date: {
  //   color: '#484848',
  //   fontSize: '1.1rem'
  // },
  dayOfWeek: {
    color: '#bdbdbd',
    display: 'block',
    fontSize: '1.3rem',
    textTransform: 'uppercase',
    paddingBottom: 10
  },
  date: {
    color: '#484848',
    fontSize: '1rem',
    fontWeight: 600,
  },
  dayBlock: {
    width: "10%",
    //paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  night: {
    paddingTop: 20,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    backgroundColor: '#f8f9fb',
    margin: '0 1px',
  },
  button: {
    alignItems: "center",
    border: "none",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    whiteSpace: "nowrap",
    padding: '10px',
    "&.greyOut": {
      backgroundColor: "#FFFFFF",
      color: '#bdbdbd'
    },
    "&.checkIn": {
      backgroundColor: "#FFFFFF",
    },
    "&.checkOut": {
      backgroundColor: "#FFFFFF",
    },
    "&:focus": {
      outline: "none"
    },
    "&.selected": {
      // backgroundColor: "#4444c2",
      backgroundColor: "#FFFFFF",
      backgroundImage: "none",
      // color: "#FFFFFF"
    },
    '&:after': {
      content:'""',
      width: 5,
      height: 5,
      borderRadius: '50%',
      background: 'transparent',
      display: 'inline-block'
    }
  },
  arrowContainer: {
    alignItems: 'center',
    // borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    marginRight: 0,
    marginLeft: 0,
    height: '40px',
    width: '40px',
  },
  arrow: {
    background: 'transparent',
    borderRadius: '50%',
    color: '#666666',
    height: '30px',
    width: '30px',
    minWidth: '30px',
    padding: 8,
    '&:hover': {
      background: '#edf2f7',
      color: '#000000'
    }
  },
  arrowIcon: {
    fontSize: '1.8rem',
  },
  picker: {
    '& input': {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: 'center',
      width: 'calc(100% - 0px)',
    },
    '& .SingleDatePickerInput': {
      background: 'transparent'
    },
    '& .DateInput': {
      background: 'transparent',
      [theme.breakpoints.down('sm')]: {
        width: '70px',
      }
    },
    '& .DateInput_input': {
      background: 'transparent'
    }
  },
  checkInOut: {
    borderRadius: "5px",
    color: '#bdbdbd',
    fontSize: 12,
    paddingTop: 3,
    paddingBottom: 3,
  },
  roomBubble: {
    background: '#EBEDF7',
    borderRadius: '5px',
    color: '#828282',
    fontSize: '1.1rem',
    marginTop: 15,
    marginLeft: '7.143%',
    padding: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    // width: 'calc(28.87% - 16px)',
    '&::before': {
      boxShadow: '0 2px 70px 0 #EBEDF7',
      zIndex: -1
    }
  },
  accessInfoHeader: {
    marginTop: 'unset',
    width: '100%',
    justifyContent: 'center'
  },
  isCurrentDate:{
    background: '#f8f9fb',
    width: '20%',
    padding: '10px',
    borderRadius: '5px',
    '&:after': {
      content:'""',
      width: 5,
      height: 5,
      borderRadius: '50%',
      background: '#b83b3b',
      display: 'inline-block'
    }
  },
  selectable: {
    cursor: 'pointer',
    width: '20%',
    padding: '10px',
    borderRadius: '5px',
    '&:hover': {
      background: '#f8f9fb',
    }
  }
});

class CalendarDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateInfo: {},
      currentDate: fetchDatePerTimezone(null, this.props.timezone),
      day: {
        startDate: fetchDatePerTimezone(null, this.props.timezone),
        endDate: fetchDatePerTimezone(null, this.props.timezone),
        days: []
      },
      numberOfDays: 10,
      // startDate: getMomentFormat(),
      // endDate: getMomentFormat().add(10,'days'),
      focused: null
    };
  }

  componentDidMount() {
    this.fetchDays(this.props.checkIn, this.props.checkOut);
    this.setState({ numberOfDays: this.props.numberOfDays })
    // console.log('Calendar dates set to ',this.props.numberOfDays);
  }

  componentDidUpdate(prevProps) {
    if (this.props.numberOfDays && !_.isEqual(this.props.numberOfDays, prevProps.numberOfDays)) {
      this.setState({ numberOfDays: this.props.numberOfDays })
      this.fetchDays(this.props.checkIn, this.props.checkOut);
    }

    if (this.props.forcedEndDate && !_.isEqual(this.props.forcedEndDate, prevProps.forcedEndDate)) {
      this.fetchDays(null, this.props.forcedEndDate);
    }
    if (this.props.forcedStartDate && !_.isEqual(this.props.forcedStartDate, prevProps.forcedStartDate)) {
      this.fetchDays(this.props.forcedStartDate, null);
    }
  }

  fetchPreviousDays = (calendarChangeBeyond) => {
    const day = { ...this.state.day };
    let noOfDiff = fetchNoOfNights(fetchDateFormat(day.startDate, DATE_FORMATS.DEFAULT_DATE), this.props.checkIn);
    //Check for outof range dates, to not allow
    if (calendarChangeBeyond && noOfDiff >= calendarChangeBeyond.prev) return;
    let startDate = day.startDate.subtract(1, "days");
    this.fetchDays(startDate, null);
  };

  fetchNextDays = (calendarChangeBeyond) => {
    const day = { ...this.state.day };
    let noOfDiff = fetchNoOfNights(this.props.checkOut, fetchDateFormat(day.endDate, DATE_FORMATS.DEFAULT_DATE));
    //Check for outof range dates, to not allow
    if (calendarChangeBeyond && noOfDiff >= calendarChangeBeyond.next) return;
    let endDate = day.endDate.add(1, "days");
    this.fetchDays(null, endDate);
  };

  fetchDays = (startDate, endDate) => {
    let day = { ...this.state.day };
    // console.log("day", day)
    // console.log("startDate", startDate)
    // console.log("endDate", endDate)
    let days = [];
    let difference = this.props.numberOfDays - 1;

    for (let index = 0; index <= difference; index++) {
      if (startDate) {
        if (index === 0) days.push(getMomentFormat(startDate));
        else if (index === difference) {
          days.push(getMomentFormat(startDate).add(index, "days"));
          day.startDate = getMomentFormat(startDate);
          day.endDate = getMomentFormat(startDate).add(index, "days");
          day.days = days;
          this.setState({ day: day });
          if (this.props.calendarDaysEnabled) this.props.handleCalendarDays(day)
        } else {
          days.push(getMomentFormat(startDate).add(index, "days"));
        }
      } else if (endDate) {
        if (index === 0) days.unshift(getMomentFormat(endDate));
        else if (index === difference) {
          days.unshift(getMomentFormat(endDate).subtract(index, "days"));
          day.endDate = getMomentFormat(endDate);
          day.startDate = getMomentFormat(endDate).subtract(index, "days");
          day.days = days;
          this.setState({ day: day });
          if (this.props.calendarDaysEnabled) this.props.handleCalendarDays(day)
        } else {
          days.unshift(getMomentFormat(endDate).subtract(index, "days"));
        }
      }
    }
  };

  datePickerDate = (date) => {
    // console.log(date)
    this.fetchDays(date, null)
  }

  render() {
    const day = { ...this.state.day };
    const classes = this.props.classes;
    const { showPerNight, calendarChangeBeyond, blockStyle, moduleID ='', dateFormat } = this.props;
    const { currentDate } = this.state;

    const daysButtonView = day.days.map((item, index) => (
      <div 
        key={index} 
        className={clsx({
          [classes.dayBlock]: true,
          [classes.night]: blockStyle
        })} 
        style={{ width: `${100 / this.props.numberOfDays}%` }}
      >
        <div
          onClick={() => { if (this.props.dateSelectEnabled) {this.datePickerDate(item)} }}
          className={clsx(
            classes.button,
            { 
              [classes.isCurrentDate]: fetchDateFormat(currentDate, DATE_FORMATS.DEFAULT_DATE) === fetchDateFormat(item, DATE_FORMATS.DEFAULT_DATE), 
              [classes.selectable]: this.props.dateSelectEnabled
            }
          )}
        >
          <span className={classes.week}>{fetchDateFormat(item, DATE_FORMATS.WEEKDAY)}</span>
          <span className={classes.date}>{fetchDateFormat(item, DATE_FORMATS.DAY)}</span>
        </div>
      </div>
    ));

    return (
      <div>
        {this.props.calendarChangeEnabled && <div className={classes.root}>
          <div className={clsx(classes.fullDate, 'datepicker-container')} style={{ marginRight: `${this.props.datePicker ? '120px' : '10px'}` }}>
            <div className={classes.arrowContainer}>
              <Button
                className={clsx(classes.arrow, "left")}
                onClick={() => this.fetchPreviousDays(calendarChangeBeyond)}
              >
                <ArrowBackIosIcon className={clsx(classes.arrowIcon, 'datePicker-arrow-icon')} />
              </Button>
            </div>
            {this.props.datePicker && <div className={classes.picker} style={{background: 'transparent'}}>
              <SingleDatePicker
                date={this.state.day.startDate} // momentPropTypes.momentObj or null,
                isOutsideRange={function noRefCheck() { }}
                id="start_date" // PropTypes.string.isRequired,
                onDateChange={(date) => this.datePickerDate(date)} // PropTypes.func.isRequired,
                onFocusChange={({ focused }) => this.setState({ focused })}
                focused={this.state.focused}
                noBorder={true}
                numberOfMonths={window.innerWidth < 901 ? 1 : 2}
                displayFormat={dateFormat || DATE_FORMATS.DEFAULT_DATE_SLASH}
                hideKeyboardShortcutsPanel
              />
            </div>}
            <div className={classes.arrowContainer}>
              <Button
                className={clsx(classes.arrow, "right")}
                onClick={() => this.fetchNextDays(calendarChangeBeyond)}
              >
                <ArrowForwardIosIcon className={clsx(classes.arrowIcon, 'datePicker-arrow-icon')} />
              </Button>
            </div>
          </div>
        </div>}

        <div className={clsx(classes.dateSection, 'date-section')}>
          {daysButtonView}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CalendarDates);
