const RoomInventoryReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOAD_RATES_AVAILABILITY_SUCCESS":
      return { ...state, rates: action.payload.rates, inventory: action.payload.inventory };
    //----------------------------------------------------------------------------------------
    case "LOAD_BED_ALLOCATIONS_SUCCESS":
      return { ...state, bedAllocations: action.payload };
    //----------------------------------------------------------------------------------------
    case "LOAD_ONE_BED_STATUS_SUCCESS":
      return { ...state, loadOneBedStatusSuccess: action.payload };
    //----------------------------------------------------------------------------------------
    case "LOAD_ALL_BEDS_STATUS_SUCCESS":
      return { ...state, bedsStatus: action.payload };
    //----------------------------------------------------------------------------------------
    // case "UPDATE_BEDS_STATUS_REQUEST":
    //   return { ...state, isBedsStatusUpdated: null };
    // case "UPDATE_BEDS_STATUS_SUCCESS":
    //   return { ...state, isBedsStatusUpdated: true };
    // case "UPDATE_BEDS_STATUS_SUCCESS":
    //   return { ...state, isBedsStatusUpdated: null };
    //----------------------------------------------------------------------------------------

    default:
      return state;
  }
};

export default RoomInventoryReducer;