import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { RESERVATION_STATUS } from '../../../utils/constants';
import IMAGES from '../../../constants/images';
import { updateReservationStatus } from '../../../redux/actions/reservations';

import GuestCheckin from '../guest-checkin';

const useStyles = makeStyles(theme =>({
    root: {
        cursor: "pointer",
        display: "flex",
        padding: "20px",
        borderRadius: "10px",
        backgroundColor: "#fff",
        alignItems: "center",
        '&.checkedin': {
            cursor: 'unset'
        }
    },
    checkinText: {
        fontSize: '1.3rem',
        fontWeight: 700,
        color: '#cd706e',
    },
    checkedInText: {
        fontSize: '1.3rem',
        fontWeight: 700,
        color: '#519884',
    },
    icon: {
        width: "20px",
        height: "20px",
        marginRight: "15px",
        '&.checkinIcon': {
            transform: "rotate(180deg)",
        }
    },
    checkedInBtn: {
        display: "flex",
        borderRadius: "10px",
        justifyContent: "space-between",
        backgroundColor: "#fff",
    },
    undoBtn: {
        display: "flex",
        alignItems: "center",
        padding: "0px 20px",
        borderLeft: "1px solid #9dc0b9",
        fontSize: "1.1rem",
        fontWeight: 600,
        color: "#519884",
        cursor: "pointer",
    },
    inActiveBtn: {
        border: '1px solid #ffffff85',
        color: '#fff',
        fontSize: '1.1rem',
        fontWeight: 700,
        padding: "20px",
        borderRadius: "10px",
        textAlign: 'center'
    }
}));

const isCheckinVisible = status =>{
    return [
        RESERVATION_STATUS.CONFIRMED,
        RESERVATION_STATUS.ONLINE_CHECKED_IN,
        RESERVATION_STATUS.IN_HOUSE,
        RESERVATION_STATUS.CHECKED_OUT
    ].includes(status);
}

const CheckinStatus = props =>{
    const classes = useStyles();
    const { reservation, dispatch } = props;
    const [showCheckinModal, setShowCheckinModal] = useState(false);

    const closeModalHandler = () =>{
        setShowCheckinModal(false);
    }

    const updateStatusHandler = () =>{
        const data = { status: RESERVATION_STATUS.CONFIRMED };
        dispatch(updateReservationStatus(reservation._id, data));
    }

    if([ RESERVATION_STATUS.IN_HOUSE, RESERVATION_STATUS.CHECKED_OUT ].includes(reservation.status)){
        return (
            <div className={classes.checkedInBtn}>
                <div className={clsx(classes.root, 'checkedin')}>
                    <img className={classes.icon} src={IMAGES.ICONS.check} />
                    <div className={classes.checkedInText}>Checked-in</div>
                </div>

                {reservation.status !== RESERVATION_STATUS.CHECKED_OUT && (
                    <div className={classes.undoBtn} onClick={updateStatusHandler}>Undo</div>
                )}
            </div>
        )
    }

    if(isCheckinVisible(reservation.status)){
        return (
            <>
                <div className={classes.root} onClick={() =>setShowCheckinModal(true)}>
                    <img className={clsx(classes.icon, 'checkinIcon')} src={IMAGES.ICONS.checkin} />
                    <div className={classes.checkinText}>Check-in guest</div>
                </div>

                {showCheckinModal && <GuestCheckin closeModalHandler={closeModalHandler}/>}
            </>
        )
    }

    return (
        <div className={classes.inActiveBtn}>
            <div>Check-in</div>
        </div>
    )
}

const mapStateToProps = state =>{
    const { reservationDetails } = state;
    return {
        reservation: reservationDetails?.reservation || {},
    }
}

export default connect(mapStateToProps)(CheckinStatus);