import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import RadioGroup from '@material-ui/core/RadioGroup';

import Dialog from '../common/CustomDialog';
import Footer from '../common/Footer';
import NumberInput from '../../common/NumberInput';
import CustomInput from '../common/CustomInput';
import { RadioButton } from '../common/Button';
import { TRANSACTION_MODE } from '../../../utils/constants';

const useStyles = makeStyles(theme => ({
    root: {
        '& .dialogBody': {
            minHeight: "360px",
            maxHeight: "360px",
            padding: "0px",
        },
    },
    tabContainer: {

        width: '100%',
        display: 'flex',
        boxSizing: 'border-box',

        '& .tab': {
            color: "#bcbcbc",
            width: "50%",
            cursor: "pointer",
            display: "flex",
            padding: "20px 30px",
            fontSize: "1.6rem",
            background: "#e0e0e08a",
            alignItems: "center",
            fontWeight: 600,
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            justifyContent: "center",
        },

        '& .tab.active': {
            background: 'unset',
            color: "#5f5f5f",
        }
    },
    tabBody: {
        padding: '30px'
    },
    radioGroup: {
        flexDirection: 'row',
        flexWrap: 'unset',
        marginBottom: "20px",
        marginTop: "10px",
    },
    radioBtn: {
        marginRight: '10px',
        '& .MuiFormControlLabel-label': {
            padding: "12px 20px",
            fontSize: "1.2rem",
            textAlign: "center",
            borderRadius: "8px",
            fontWeight: 500,
        }
    },
    textField: {
        height: "45px",
        fontWeight: 600,
        '& input': {
            paddingLeft: '10px',
            fontWeight: 500,
        }
    },
}));

const TABS = {
    discount: TRANSACTION_MODE.DISCOUNT,
    sucharge: TRANSACTION_MODE.SURCHARGE
}

const VALUE_TYPE = [
    { value: 'cash', label: 'Amount' },
    { value: 'percent', label: 'Percent' }
];

const DiscountModal = props => {
    const classes = useStyles();
    const { 
        closeModalHandler, 
        isLoading, 
        totalAmount, 
        transactionMode, 
        transactionModeIDs,
        createTransactionHandler 
    } = props;

    const [activeTabID, setActiveTabID] = useState(TABS.discount);
    const [discountType, setDiscountType] = useState('cash');
    const [discountTypeVal, setDiscountTypeVal] = useState({ amount: '', percent: '' });

    const handleTypeChange = (e) => {
        setDiscountType(e.target.value);
        setDiscountTypeVal({ amount: '', percent: '' });
    }

    const tabChangeHandler = tab => {
        setActiveTabID(tab);
        setDiscountType('cash');
        setDiscountTypeVal({ amount: 0, percent: 0 });
    }

    const handleAmountChange = (e) => {
        let value = e.target.value;
        value = value < 0 ? Math.abs(value) : value;
        setDiscountTypeVal({ ...discountTypeVal, amount: value });
    }

    const handlePercentChange = (e) => {
        if (!e.target.value) {
            setDiscountTypeVal({ ...discountTypeVal, percent: 0 });
        }

        const value = e.target.value;

        if (parseFloat(value) <= 100) {
            const amountConversion = parseFloat(parseFloat(totalAmount) * parseFloat(value) / 100).toFixed(2);
            setDiscountTypeVal({ percent: value, amount: amountConversion });
        }
    }

    const submitHandler = () => {

        const modeID = transactionModeIDs.find(id => transactionMode[id].name === activeTabID);

        let formattedAmount = parseFloat(discountTypeVal.amount);

        if (activeTabID == TRANSACTION_MODE.SURCHARGE) formattedAmount = formattedAmount * -1;

        const payload = {
            amount: formattedAmount,
            modeID
        }

        createTransactionHandler(payload);
        closeModalHandler();
    }

    const getInputNumberProps = () => {
        const modeName = activeTabID == TRANSACTION_MODE.DISCOUNT ? 'Discount' : 'Surcharge';
        if (discountType == 'percent') {
            return {
                id: 'percent',
                value: discountTypeVal.percent || "",
                label: 'Percent',
                placeholder: `${modeName} percent`,
                onChange: handlePercentChange
            }
        }

        return {
            id: 'amount',
            value: discountTypeVal.amount || "",
            label: 'Amount',
            placeholder: `${modeName} amount`,
            onChange: handleAmountChange
        }
    }

    const inputProps = getInputNumberProps();

    return (
        <Dialog
            className={classes.root}
            headerTitle=""
            closeModalHandler={closeModalHandler}
            isLoading={isLoading}
        >
            {/* ----------------TAB SELECTION----------------- */}
            <div className={classes.tabContainer}>
                <div
                    className={clsx("tab", { active: activeTabID == TABS.discount })}
                    onClick={() => tabChangeHandler(TABS.discount)}
                >
                    Discount
                </div>
                <div
                    className={clsx("tab", { active: activeTabID == TABS.sucharge })}
                    onClick={() => tabChangeHandler(TABS.sucharge)}
                >
                    Surcharge
                </div>
            </div>

            {/* ------------------TAB BODY----------------------- */}
            <div className={classes.tabBody}>

                <RadioGroup
                    className={classes.radioGroup}
                    name="refundSources"
                    onChange={handleTypeChange}
                    value={discountType}
                >
                    {VALUE_TYPE.map((option) => (
                        <RadioButton
                            className={classes.radioBtn}
                            key={option.value}
                            label={option.label}
                            value={option.value}
                        />
                    ))}
                </RadioGroup>

                <CustomInput
                    label={inputProps.label}
                    className={classes.input}
                    createMode
                >
                    <NumberInput
                        variantType="base"
                        className={classes.textField}
                        type="number"
                        min={0}
                        fullWidth
                        {...inputProps}
                    />
                </CustomInput>

            </div>

            <Footer
                label="Submit"
                onBtnClick={submitHandler}
                isBtnDisabled={!discountType || !discountTypeVal.amount}
            />
        </Dialog>
    )
}

const mapStateToProps = state => {
    const { loading, error } = state;
    return {
        isLoading: loading.CREATE_RESERVATION_TAX,
        errors: error.CREATE_RESERVATION_TAX
    }
}

export default connect(mapStateToProps)(DiscountModal);