import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import IMAGES from '../../../constants/images';

const useStyles = makeStyles(theme =>({
    root: {
        borderRadius: "30px",
        border: "1px solid #e0e0e0",
        padding: "0px 20px",
        // paddingRight: "10px",
        marginBottom: '10px',
        '&.error': {
            borderColor: '#f44336'
        }
    },
    editIcon: {
        width: "15px",
        height: "15px",
    },
    displayContainer: {
        display: "flex",
        alignItems: "center",
        padding: "5px 0px",

        '& .info': {
            flex: 1
        },

        '& .label': {
            margin: 0,
            fontSize: "1.1rem",
            color: "#999999",
        },

        '& .text': {
            fontSize: "1.2rem",
            fontWeight: 600,
            margin: "6px 0px",
        }
    },
    inputLabel: {
        fontSize: "1.1rem",
        margin: "8px 10px",
    }
}));

const CustomInput = props =>{
    const classes = useStyles();
    const { 
        label, 
        value = '--', 
        editable = false, 
        onEditClick, 
        presetEdit, 
        resetEdit, 
        error = false, 
        createMode, 
        className 
    } = props;

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() =>{
        if(editable) setIsEdit(false);
    }, [resetEdit]);

    useEffect(() => {
        if (presetEdit) setIsEdit(true);
    }, [presetEdit])

    const onEditHandler = () =>{
        if(onEditClick) onEditClick();
        setIsEdit(true);
    }

    //FOR ONLY INPUT DISPLAY....................
    if(createMode){
        return (
            <>
                <p className={classes.inputLabel}>{label}</p>
                <div className={clsx(classes.root, className, { error: error })}>
                    {props.children}
                </div>
            </>
        )
    }

    //FOR INPUT DISPLAY WITH EDIT AND READ-ONLY STATE....................
    return (
        <>
            {isEdit && <p className={classes.inputLabel}>{label}</p>}

            <div className={clsx(classes.root, className, { error: error })}>
                {!isEdit && (
                    <div className={classes.displayContainer}>
                        <div className="info">
                            <p className="label">{label}</p>
                            <p className="text">{value || '--'}</p>
                        </div>

                        {editable && (
                            <IconButton 
                                color="primary" 
                                component="span"
                                onClick={onEditHandler}
                            >
                                <img 
                                    src={IMAGES.ICONS.pencil} 
                                    className={classes.editIcon}
                                />
                            </IconButton>
                        )}
                    
                    </div>
                )}

                {isEdit && props.children}
            </div>
        </>
    )
}

export default CustomInput;