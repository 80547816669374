import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme=>({
    root:{
        display: 'flex',
        alignItems: 'center',
        paddingTop: '30px',
        margin: '0 auto',
        width: '75%',
        maxWidth: 500,
        paddingBottom: 40,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    continueBtn:{
        width: '100%',
        borderRadius: '5px',
        background: '#000',
        color: '#fff',
        fontSize: '1.6rem',
        height: 80,
        marginTop: 10,
        padding: '10px',
        fontWeight: 'bold',
        '&:hover':{
            background: '#000000',
            color: '#FFFFFF',
        }
    }
}));
const Footer = ({
    stepInfo = "",
    buttonLabel = t('fields.proceed'),
    onProceed,
    isKioskFlow
}) =>{
    const classes = useStyles();
    return(
        <Box className={classes.root}>
            <Button className={classes.continueBtn} onClick={onProceed}>{buttonLabel}</Button>
        </Box>
    );
}

const mapStateToProps = state =>{
    const { kiosk } = state;
    return{
        isKioskFlow: Boolean(kiosk.mode)
    }
}

export default withTranslation()(connect(mapStateToProps)(Footer));