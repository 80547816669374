const ACCCOMMODATION_NAME_TEMPLATES = {
    dorm: "${roomName} ${bedName}",
    private: "${roomName}"
}

const getAccommodationName = ({ dormOrPrivate = '', roomName = '', bedName = '' }, customTemplate = '' ) =>{

    if(!roomName && !bedName) return '';

    const replacements = {"${roomName}": roomName, "${bedName}": bedName};
    const regex = /[${]+\w+}/g;
    let template = ACCCOMMODATION_NAME_TEMPLATES.private;

    if(dormOrPrivate == 'dorm'){
        template = customTemplate || ACCCOMMODATION_NAME_TEMPLATES.dorm;
    }

    return template.replace(regex, all =>replacements[all] || all);
}

export {
    getAccommodationName
}