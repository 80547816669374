import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from "prop-types";

import { BED_STATUSES } from '../../utils/constants';

const useStyles = makeStyles(theme =>({
    root: {
        color: "#fff",
        width: "80px",
        height: "25px",
        fontSize: "1.1rem",
        borderRadius: "20px",
        '&.occupied': { backgroundColor: '#c7bad8' },
        '&.dirty': { backgroundColor: '#eab8b7' },
        '&.clean': { backgroundColor: '#bbddc4' },
    },
    loader: {
        color: '#fff',
    }
}));

const BUTTON_STATUSES = {
    [BED_STATUSES.occupied]: { label: 'Occupied', className: 'occupied' },
    [BED_STATUSES.unclean]: { label: 'Dirty', className: 'dirty' },
    [BED_STATUSES.ready]: { label: 'Clean', className: 'clean' },
}

const BedStatusButton = props =>{
    const classes = useStyles();
    const { status, isLoading, onClickHandler } = props;

    const buttonStatus = BUTTON_STATUSES[status];

    return (
        <ButtonBase 
            className={clsx(classes.root, buttonStatus.className)}
            disabled={isLoading}
            onClick={isLoading ? undefined : onClickHandler}
        >
            {!isLoading && buttonStatus.label}

            {isLoading && (
                <CircularProgress 
                    size={15} 
                    className={classes.loader}
                />
            )}
        </ButtonBase>
    )
}

BedStatusButton.prototypes = {
    status: PropTypes.instanceOf([ 
        BED_STATUSES.occupied, 
        BED_STATUSES.unclean, 
        BED_STATUSES.ready 
    ]).isRequired
}

export default BedStatusButton;