import http from "../utils/http";

export function getStripeAccountStatus(propertyID) {
    const ACTION_NAME = "GET_STRIPE_ACCOUNT_STATUS";
    return function(dispatch) {
      dispatch({ type: `${ACTION_NAME}_REQUEST` });
      return http
        .get(`/api/property/accountStatus`)
        .then(successResult => {
          const { data } = successResult;
          dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { ...data } });
          return data;
        })
        .catch(errorResult => {
          let error =
            errorResult && errorResult.response && errorResult.response.data
              ? errorResult.response.data
              : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
          return;
        });
    };
}