import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Footer from '../../common/Footer';
import moment from 'moment-timezone';

import DateChangeInfo from '../DateChangeInfo';
import { getDurationCount, formatDateRange } from '../../helper';
import { fetchDateFormat, DATE_FORMATS } from '../../../../utils/utility';
import { fetchNoOfNights } from '../../../../utils/utility';

const useStyles = makeStyles(theme =>({
    root: {
        
    },
    datePicker: {
        border: '1px solid #e0e0e0',
        padding: '8px',
        borderRadius: '30px',
        width: '240px',
        '& .DateInput_1': {
            width: '100%'
        }
    },
    incrementContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: "200px",
    },
    IconButton: {
        border: '1px solid #e0e0e0',
        color: '#000'
    },
    incDuration: {
        textAlign: "center",
        fontSize: "1.2rem",
        fontWeight: 600,
        '& p': {
            margin: '0px',
        },
        '& .count': {
            fontSize: "2.2rem",
            fontWeight: 600,
        },
    },
    seperator: {
        fontSize: '1.3rem',
        fontWeight: '600',
        margin: '30px 0px'
    }
}));

const DATE_FORMAT_OPTIONS = {
    dateFormat: 'DD. MMM YYYY', 
    seperator: '-'
}

const DurationSelect = props =>{
    const classes = useStyles();
    const { selectedAccomodation, setSelectedDateRange, getManualOptions } = props;
    const [checkInDate, setCheckInDate] = useState(null);
    const [focused, setFocused] = useState(null);
    const [dates, setDates] = useState({ original: '', new: '' });

    useEffect(() =>{
        const dateParams = {
            startDate: selectedAccomodation.checkIn,
            endDate: selectedAccomodation.checkOut,
        }
        setDates({ original: { ...dateParams }, new: '' });
    }, []);

    const onDateChangeHandler = (date) =>{
        const newDates = {
            startDate: moment(date).format(DATE_FORMATS.DEFAULT_DATE),
            endDate: selectedAccomodation.checkOut
        }
        setDates({ ...dates, new: { ...newDates }});
        setCheckInDate(date);
        setSelectedDateRange(newDates);
    }

    const nextStepHandler = () =>{ 
        const newStartDate = moment(dates.new.startDate).format(DATE_FORMATS.DEFAULT_DATE);
        const data = {
            startDate: newStartDate,
            duration: (fetchNoOfNights(newStartDate, dates.original.endDate) || 0) + 1
        }
        getManualOptions(data);
    }

    return (
        <>
            <div className={classes.root}>

                <DateChangeInfo 
                    originalDates={dates.original && formatDateRange(dates.original, DATE_FORMAT_OPTIONS) || ''}
                    newDates={dates.new && formatDateRange(dates.new, DATE_FORMAT_OPTIONS) || ''}
                />

                <div className={classes.datePicker}>
                    <SingleDatePicker 
                        date={checkInDate}
                        onDateChange={onDateChangeHandler}
                        focused={focused}
                        onFocusChange={({ focused }) => setFocused(focused)}
                        placeholder="Pick new arrival date"
                        noBorder 
                        numberOfMonths={1}
                        displayFormat={'DD MMM YYYY'}
                        hideKeyboardShortcutsPanel
                        isOutsideRange={day =>{ 
                            const date = moment(selectedAccomodation.checkIn).format(DATE_FORMATS.DEFAULT_DATE)
                            return day.isAfter(fetchDateFormat(date, DATE_FORMATS.DEFAULT_DATE));
                        }}
                    />
                </div>

            </div>

            <Footer 
                label="Next" 
                isBtnDisabled={!dates?.new.startDate ? true : false}
                onBtnClick={nextStepHandler}
            />
        </>
    )
}

export default connect(null)(DurationSelect);