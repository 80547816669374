import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { ElementsConsumer } from '@stripe/react-stripe-js';

import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FormHelperText from '@material-ui/core/FormHelperText';

import CheckboxSection from './CheckboxSection';
import http from "../../../../redux/utils/http";
import { showBookingLoader, hideBookingLoader, addReservationDetails } from '../../../../redux/actions/bookingEngine';
import { fetchSessionToken } from '../../../../redux/actions/bookingEngine';

const styles = theme =>({
    iframeContainer: {
        background: '#FFFFFF',
        marginTop: '50px',
        boxShadow: '0 0 8px 0px #dedede',
        borderRadius: '20px',
        paddingBottom: '110px',
        position: 'relative',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '75%',
        },
    },
    cardIframeContainer: {
        background: 'transparent',
        height: '300px',
        overflow: 'hidden',
        position: 'relative'
    },
    cardCaptureIframe:{
        border: '0',
        height: '100%',
        left: '25px',
        position: 'absolute',
        top: '25px',
        width: '91%',
        [theme.breakpoints.down('sm')]: {
            top: '22px',
            left: '5px',
            width: '97%'
        }
    },
    buttonContainer:{
        display: 'flex', 
        justifyContent: 'flex-end', 
        marginTop: '30px',
    },
    checkoutBtn:{
        width: '100%',
        backgroundColor: '#5C5C5C',
        color: '#fff',
        textTransform: 'capitalize',
        padding: "6px 20px",
        minWidth: '100px',
        marginBottom: '30px',

        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },

        '&:hover': {
            backgroundColor: '#5C5C5C',
            color: '#fff'
        }
    },
    footerContainer:{
        borderRadius: '20px',
        padding: '20px',
        boxShadow: '0 0 8px 0px #dedede',
        position: 'absolute',
        backgroundColor: '#fff',
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    footerItems:{
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#B7B7B7'
    },
    footerIcons:{
        width: '100px',
        height: '50px',
    },
    secureImage: {
        height: '100%',
        width: '100%',
    },
    cardErrorBlock:{
        width: '100%',
        marginLeft: '60px',
    },

    mainContentCard: {
        backgroundColor: '#fff',
        borderRadius: '20px',
        padding: '20px',
        boxShadow: '0 0 8px 0px #dedede',
        marginTop: '50px'
    }
});

const PCI_PROXY_DOMAIN = 'https://pci.channex.io';

class Payment extends React.PureComponent{
    state = {
        cardCaptureIframeSrc: null,
        termsAndCondtionChecked: false,
        termsAndConditionError: false,
        marketingUpdatesChecked: false,
        paymentError: ''
    }

    cardCaptureListener = event =>{
        
        if (event.origin !== PCI_PROXY_DOMAIN) {
            //console.log('rejected origin ',event.origin);
            return;
        }
        
        if (event.data.valid) {
            //console.log("card valid: ", event.data.valid);
        }

        if (event.data.success) {
            const { card_token } = event.data.card;
            this.props.dispatch(showBookingLoader());
            this.proceedToPayment(card_token);
        }
    }

    initPaymentForm = () =>{
        this.props.dispatch(showBookingLoader());
        fetchSessionToken().then(response=> {
            const channexSessionToken = response.data.session_token;
            this.setState({ cardCaptureIframeSrc: `https://pci.channex.io/api/v1/capture_form?session_token=${channexSessionToken}&style=booking_engine`});
            window.addEventListener("message", this.cardCaptureListener);
            this.props.dispatch(hideBookingLoader());
        }).catch(error => {
            console.log('error', error);
            let errorMessage =
              error && error.response && error.response.data
                ? error.response.data
                : error;
            this.props.dispatch(hideBookingLoader());
            this.handlePaymentError(errorMessage);
        });
    }

    componentDidMount(){
        this.initPaymentForm();
    }

    handlePaymentError = errorMessage =>{
        const { dispatch } = this.props;
        this.setState({ 
            disablePayBtn: false,
            paymentError: errorMessage
         });
        dispatch(hideBookingLoader());
    }

    checkBoxHandler = event => {
        const inputName = event.target.name;
        const checkedStatus = event.target.checked;
        if (inputName == 'termsAndCondtionChecked') {
            this.setState({
                [inputName]: checkedStatus,
                termsAndConditionError: !checkedStatus
            });
            return;
        }
        this.setState({ [inputName]: checkedStatus });
    }

    handleSubmit = () =>{
        const { validateCustomerDetails, termsConditions } = this.props;
        this.setState({ paymentError: '' });

        // Validating customer details form...
        if (!validateCustomerDetails()) {
            window.scrollTo(0, 0);
            return false;
        }

        // Validating card holder input and terms and conditions checkbox...
        const { termsAndCondtionChecked } = this.state;
        if(termsConditions?.length > 0) {
            if(!termsAndCondtionChecked){
                this.setState({ termsAndConditionError: true });
                return false;
            }
        }

        //Submit card Capture form...
        this.cardCaptureIframeRef.contentWindow.postMessage("submit", "https://pci.channex.io");
    }

    componentWillUnmount(){
        window.removeEventListener("message", this.cardCaptureListener)
    }

    //Stripe handler functions...............
    handleServerResponse = (response, channexCardToken) => {
        const { stripe, dispatch, nextStep, fetchBookingData } = this.props;
        if (response.requires_action) {
            // Use Stripe.js to handle the required card action
            stripe.handleCardAction(
                response.payment_intent_client_secret
            ).then(result => this.handleStripeJsResult(result, response.custID, channexCardToken));
        } else {
            // Show success message
            const data = {...response, username: fetchBookingData().guest.firstName}
            dispatch(addReservationDetails(data));
            nextStep();
            dispatch(hideBookingLoader());
            //console.log('payment Successful');
        }
    }

    handleStripeJsResult = (result, custID, channexCardToken) =>{
        if (result.error) {
            this.handlePaymentError(result.error.message);
            this.initPaymentForm();
            //console.log('errorAction ', result.error);
        } else {
            // The card action has been handled
            // The PaymentIntent can be confirmed again on the server
            const stripeData = {
                payment_intent_id: result.paymentIntent.id,
                custID
            };

            this.proceedToPayment(channexCardToken, stripeData);
        }
    }

    proceedToPayment = (channexCardToken, stripeData = {}) => {
        const {
            guest,
            reservation,
            propertyID,
            chargableAmount
        } = this.props.fetchBookingData();

        const { isKioskFlow, termsConditions } = this.props;

        const {
            termsAndCondtionChecked,
            marketingUpdatesChecked
        } = this.state;

        const postData = {
            stripe: { ...stripeData, chargableAmount },
            guest,
            reservation,
            propertyID,
            agreeTerms: (termsConditions?.length > 0) ? termsAndCondtionChecked : true,
            agreeUpdates: marketingUpdatesChecked,
            channexCardToken,
            isKiosk: isKioskFlow 
        };

        http.post(`/api/bookEngine/payment`, postData)
        .then(paymentResponse =>{
            this.handleServerResponse(paymentResponse.data, channexCardToken);
        }).catch(errorResult =>{
            let errorMessage =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;

            this.handlePaymentError(errorMessage);
            this.initPaymentForm();
            //console.log('server error ', errorMessage);
        });
    }

    render(){
        const { classes, t, isCollectPayment } = this.props;
        const { cardCaptureIframeSrc } = this.state;

        return(
            <div>
                {/*<div className={classes.cardIframeContainer}>
                    {cardCaptureIframeSrc && (
                        <iframe
                            ref={ ref => this.cardCaptureIframeRef = ref }
                            id="cardCaptureIframe"
                            height="300"
                            scrolling="no"
                            className={classes.cardCaptureIframe}
                            src= { cardCaptureIframeSrc }>
                        </iframe>
                    )}

                    <div className={classes.footerContainer}>
                        <div className={classes.footerItems}>
                            <div>
                                <LockOutlinedIcon />
                            </div>
                            <div ml={2}>
                                Secure purchase
                            </div>
                        </div>
                        <div className={classes.footerItems} >
                            <div className={classes.footerIcons}>
                                <img src={"/images/masterCard.svg"} className={classes.secureImage} alt="Mastercard Securecode" />
                            </div>
                        </div>
                    </div>
                </div>*/}
                <div className={classes.iframeContainer}>
                    <div className={classes.cardIframeContainer}>
                        {cardCaptureIframeSrc && (
                            <iframe
                                ref={ ref => this.cardCaptureIframeRef = ref }
                                id="cardCaptureIframe"
                                height="300"
                                scrolling="no"
                                className={classes.cardCaptureIframe}
                                src= { cardCaptureIframeSrc }>
                            </iframe>
                        )}
                    </div>

                    <div className={classes.footerContainer} >
                        <div className={classes.footerItems}>
                            <div>
                                <LockOutlinedIcon />
                            </div>
                            <div ml={2}>
                                Secure purchase
                            </div>
                        </div>
                        <div className={classes.footerItems} >
                            <div className={classes.footerIcons}>
                                <img src={"/images/masterCard.svg"} className={classes.secureImage} alt="Mastercard Securecode" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.mainContentCard}>
                    <CheckboxSection 
                        {...this.state}
                        t={t}
                        termsConditions={this.props.termsConditions}
                        checkBoxHandler={this.checkBoxHandler}
                    />
                </div>
                <div className={classes.buttonContainer}>
                    <Button
                        type="button"
                        className={classes.checkoutBtn}
                        onClick={this.handleSubmit}
                    >
                        {(isCollectPayment) ? t('bookingEngine.payNow') : t('bookingEngine.roomCategory.bookNow')}
                    </Button>
                </div>
            </div>
        );
    }
}

const InjectedCheckoutForm = props => {
    return (
        <ElementsConsumer>
            {({ elements, stripe }) => (
                <Payment
                    elements={elements}
                    stripe={stripe}
                    {...props}
                />
            )}
        </ElementsConsumer>
    );
};

const mapStateToProps = state =>{
    return{
        isKioskFlow: Boolean(state.kiosk.mode)
    }
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(InjectedCheckoutForm)));