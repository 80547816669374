import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';
import CircularProgress from '@material-ui/core/CircularProgress';
// import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const styles = theme => ({
    cardInfoContainer: {
        color: "#5f5f5f",
        padding: "20px",
        borderRadius: "10px",
        border: "1px solid #e0e0e0",
    },
    loadingCard: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 135,
        width: '100%',
        '& svg': {
            color: '#FFFFFF',
        }
    },
    logoContainer: {
        display: 'flex',
        fontWeight: 600,
        fontSize: '1.6rem',
    },
    cardNumber: {
        marginTop: '20px',
        marginBottom: 20,
        fontWeight: 600,
        fontSize: '1.4rem',
    },
    cardDigit: {
        marginRight: '10px'
    },
    cardRow: {
        justifyContent: 'space-between',
        display: 'flex',
        marginBottom: 10,
    },
    cardSection: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardLabel: {
        fontSize: '1.1rem',
        marginBottom: 3,
    },
    cardName: {
        fontSize: '1.4rem',
        fontWeight: 600,
    },
    cardExpiry: {
        fontSize: '1.4rem',
        fontWeight: 600
    },
    viewIcon: {
        cursor: 'pointer',
        height: 20,
        width: 20
    },
    vccContainer: {
        border: "1px solid #cf6e6a",
        overflow: "hidden",
        borderRadius: "10px",
        marginBottom: "15px",
        marginTop: "-40px",

        '& .header': {
            color: "#fff",
            padding: "15px 20px",
            fontSize: "1.4rem",
            textAlign: "center",
            fontWeight: 600,
            backgroundColor: "#cf6e6a",
        },

        '& .body': {
            padding: "15px 20px",
        },

        '& .secondaryText': {
            color: "#999999",
            fontSize: "1.1rem",
        }
    },
    vccBalance: {
        color: "#cf6e6a",
        display: "flex",
        fontSize: "1.4rem",
        fontWeight: 600,
        marginBottom: "8px",
        justifyContent: "space-between",
    },
    infoRow: {
        alignItems: 'center',
        background: '#F8F9FB',
        borderRadius: 10,
        display: 'flex',
        padding: 30,
        width: 'calc(100% - 60px)',
        marginBottom: '20px'
    },
    helperText: {
        color: '#A2A2A2',
        fontSize: '1.2rem',
        lineHeight: 1.4,
        marginLeft: 20,
        fontWeight: 'bold'
    },
    link: {
        color: '#13408d',
        cursor: 'pointer',
        fontSize: '1.2rem',
        fontWeight: 600,
        marginBottom: 20,
    },
});

class CardDetails extends Component {

    state = {
        cardData: null,
        isCardFetchLoading: false
    }

    processCardBrand = (brand) =>{
        if(!brand) return '';
        let cardBrand = brand.toLowerCase();
        const cardInitial = cardBrand.charAt(0).toUpperCase();
        cardBrand = `${cardInitial}${cardBrand.slice(1)}`;
        return cardBrand;
    }

    render() {
        const {
            maskedCards,
            selectedCardToken,
            classes,
        } = this.props;

        const {
            isCardFetchLoading,
            cardData
        } = this.state;

        let cardDisplay = null;

        if (maskedCards.length == 1) {
            cardDisplay = maskedCards[0];
        }

        if (maskedCards.length > 1 && selectedCardToken) {
            cardDisplay = maskedCards.find(cardData => cardData.card_token == selectedCardToken);
        }

        if (!cardDisplay) return null;

        const cardInfo = {
            cardType: cardData?.cardType || this.processCardBrand(cardDisplay.card_type),
            cardNumber: cardData?.cardNumber || cardDisplay.card_number.match(/.{1,4}/g).join('&nbsp &nbsp'),
            cardholderName: cardData?.cardName || cardDisplay.cardholder_name,
            cardExpiry: cardData?.cardExpiry || `${cardDisplay.expiration_month}/${cardDisplay.expiration_year.slice(2)}`,
            isPCIProxy: cardDisplay?.isPCIProxy || false
        }

        // const isCardInvalid = !String(cardInfo.cardNumber).match(/^[0-9*]{16}$/);
        
        return (
            <div className={classes.root}>

                {/* -----------------Check FOR INVALID CARD------------------- */}
                {/* {isCardInvalid && (
                    <div className={clsx('row', classes.marginTop)}>
                        <div className={classes.infoRow}>
                            <div>
                                <WarningRoundedIcon color='error' fontSize='large' />
                            </div>
                            <span className={classes.helperText}>
                                {'Invalid Card, please report using chat bubble in '}
                                <a className={classes.link} onClick={() => window.location.pathname = '/beds/calendar'}>calendar</a>
                            </span>
                        </div>
                    </div>
                )} */}

                {/* -----------------CARD INFO------------------- */}
                <div className={classes.cardInfoContainer}>

                    {isCardFetchLoading && <div className={classes.loadingCard}><CircularProgress /></div>}

                    {!isCardFetchLoading && <div>
                        <div className={classes.cardRow}>

                            {/* ---------------CARD BRAND-------------------*/}
                            <div className={classes.logoContainer}>
                                <div className={classes.cardValue}>{cardInfo.cardType}</div>
                            </div>

                        </div>

                        {/* ---------------CARD NUMBER----------------------- */}
                        <div className={classes.cardNumber}>{parse(cardInfo.cardNumber)}</div>

                        <div className={classes.cardRow}>

                            {/* ---------------CARDHOLDER NAME------------------------ */}
                            <div className={classes.cardSection}>
                                <div className={classes.cardLabel}>Name</div>
                                <div className={classes.cardName}>{cardInfo.cardholderName}</div>
                            </div>

                            {/* ---------------CARD EXPIRY DATE------------------------ */}
                            <div className={classes.cardSection}>
                                <div className={classes.cardLabel}>Expiry</div>
                                <div className={classes.cardExpiry}>{cardInfo.cardExpiry}</div>
                            </div>

                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(CardDetails);