import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import moment from "moment-timezone";

import DashboardGuestList from "./DashboardGuestList";
import NewReservation from "../reservation/new/ReservationPage";

import { Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    width: "calc(100% - 80px)",
    minHeight: 'calc(100vh - 120px)',
    background: '#F8F9FB',
    padding: "0px 40px 0px 40px",
    paddingTop: theme.spacing(5),
    marginTop: 70,
    [theme.breakpoints.down('xs')]: {
      padding: "30px 20px 0px 20px", 
      width: "calc(100% - 40px)",
      marginTop: 60,
    }
  },
  headerContainer: {
    alignItems: 'center',
    background: '#F8F9FB',
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 80,
    position: "relative",
    width: "100%",
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    color: '#333333',
    fontSize: '1.8rem',
    fontWeight: 600,
  },
  subTitle: {
    color: '#999999',
    marginTop: 5,
    fontSize: '1.2rem',
  },
  rightAlign: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button:{
    alignItems: 'center',
    background: '#000000',
    borderRadius: 10,
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.4rem',
    fontWeight: 600,
    justifyContent: 'center',
    padding: '12px 15px',
    '&:hover': {
        background: '#000000'
    },
  },
}));

const DashboardPage = (props) => {
  const classes = useStyles();
  const { timezone } = props;
  const [newBooking, setNewBooking] = React.useState(false);

  const handleHideDialog = () => {
    setNewBooking(false);
    props.handleCallInventoryData();
  };

  return (
    <div className={classes.root}>
      
      <div className={classes.headerContainer}>
      
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            Today's Activity
          </div>
          <div className={classes.subTitle}>
            {timezone && moment().tz(timezone).format('dddd, Do MMM')}
          </div>
        </div>
        
        <div className={classes.rightAlign}>
          {/* -------------------------------------------------------------------------------- */}
          {/* CREATE NEW RESERVATION */}
          {/* -------------------------------------------------------------------------------- */}
          <Button className={classes.button} onClick={() => setNewBooking(true)}>New Booking</Button>
        </div>
      </div>
      
      <DashboardGuestList 
        property={props.property} 
        handleGoReservation={props.handleGoReservation} 
        handleCallInventoryData={props.handleCallInventoryData} 
        history={props.history} 
        handleSelectedDate={props.handleSelectedDate}
      />


      {/* -------------------------------------------------------------------------------- */}
      {/* CREATE NEW RESERVATION */}
      {/* -------------------------------------------------------------------------------- */}
      {newBooking ? (
        <NewReservation
          open={newBooking}
          property={props.property}
          handleCloseCreate={handleHideDialog}
          history={props.history}
          handleSelectedDate={props.handleSelectedDate}
        />
      ) : null}
      
    </div>
  );
}

export default DashboardPage;
