import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import PaymentDetailsForm from './PaymentDetailsForm';
import PaymentOptions from './PaymentOptions';
import ChargeReservation from './ChargeReservation';
import { fetchDatePerTimezone } from '../../../utils/utility';

const styles = theme =>({
    root: {
    
    }
});

const STEPS_CONTENT = [
    { component: PaymentDetailsForm },
    { component: PaymentOptions },
    { component: ChargeReservation }
];

class PaymentContainer extends Component {
    state = {
        activeStep: 0,
        selectedModeID: null,
        paymentAmount: 0,
        paymentDate: null 
    }

    componentDidMount() {
        const { timezone, reservation } = this.props;
        const paymentDate = fetchDatePerTimezone(null, timezone);
        this.setState({ 
            paymentDate,
            paymentAmount: reservation?.balance || 0
        });
    }

    nextStep = () =>{
        this.setState(prevState =>({ activeStep: prevState.activeStep + 1 }));
    }

    prevStep = () =>{
        this.setState(prevState =>({ activeStep: prevState.activeStep - 1 }));
    }

    onChangePaymentMode = modeID =>{
        this.setState({ selectedModeID: modeID });
    }

    onChangePaymentDetails = (data) =>{
        this.setState({ [data.name]: data.value });
    }

    submitTransactionHandler = payload =>{
        const { createTransactionHandler, closeModalHandler } = this.props;
        createTransactionHandler(payload, closeModalHandler);
    }

    render() {
        const { classes, currency, showAddCardHandler } = this.props;
        const { activeStep, selectedModeID, paymentAmount, paymentDate } = this.state;
        const CurrentStep = STEPS_CONTENT[activeStep];

        const componentProps = {
            selectedModeID,
            paymentAmount,
            paymentDate,
            currency,
            nextStep: this.nextStep,
            prevStep: this.prevStep,
            onChangePaymentMode: this.onChangePaymentMode,
            onChangePaymentDetails: this.onChangePaymentDetails,
            submitTransactionHandler: this.submitTransactionHandler,
            showAddCardHandler
        }

        return (
            <div className={classes.root}>
                <CurrentStep.component {...componentProps} />
            </div>
        )
    }
}

const mapStateToProps = state =>{
    const { property, reservationDetails, register } = state;
    const propertyID = register.propertyID;
    const propertyInfo = property?.property?.[propertyID] || {};
    return {
        timezone: property.timezone,
        reservation: reservationDetails.reservation || {},
        currency: propertyInfo.currency || '',
    }
}

export default withStyles(styles)(connect(mapStateToProps)(PaymentContainer));