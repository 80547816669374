import React from 'react';
import PropTypes from 'prop-types';
import {
    FormControl,
    InputLabel,
    TextField,
    Select,
    Grid,
    FormHelperText
} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

const countryTelephoneData = require('country-telephone-data');

const useStyles = makeStyles(theme =>({
    formControlLabel: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: '12px',
        marginBottom: "12px"
    },

    formControl: {
        width: '100%'
    },

    formInput: {
        boxShadow: '0 0 8px 0px #dedede',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            '&.Mui-error fieldset': {
                border: '1px solid #f44336'
            },
        },
    },

    selectInput: {
        height: '40px',
        boxShadow: '0 0 8px 0px #dedede',
         '&:before': {
            border: 'none'
        },
        '&.MuiInput-underline:hover:before': {
            border: 'none !important'
        },
        '&.MuiInput-underline:after': {
            border: 'none !important'
        },
        '& .MuiSelect-select':{
            paddingLeft: '14px'
        },
        '& .MuiSelect-select:focus':{
          backgroundColor: '#fff'
        }
    },
    errorMessage: {
        color: "#f44336"
    }
}));

export default function PhoneField({ telephone, telephoneError, countryCode, countryCodeError, onInputChangeHandler, fieldLabel }) {

    const classes = useStyles();

    const handleGetCountryCodes = () => {
        const countryOptions = countryTelephoneData.allCountries.map(country => ({
            value: country.dialCode,
            label: `+${country.dialCode} ${country.name}`,
        }));

        return countryOptions;
    };

    const countryCodes = handleGetCountryCodes();

    return (
      <Grid container direction="row" spacing={0}>
          <Grid item xs={5}>
              <FormControl className={classes.formControl} style={{marginTop: '8px'}}>
                  <Select
                    native
                    variant="outlined"
                    className={classes.selectInput}
                    name="countryCode"
                    value={countryCode}
                    onChange={onInputChangeHandler}>
                      <option aria-label="None" value=""/>
                      {countryCodes.map((country, index) => <option key={index} value={country.value}>{country.label}</option>)}
                  </Select>
              </FormControl>
          </Grid>

          {/* Phone Number */}
          <Grid item xs={7}>
              <FormControl className={classes.formControl} style={{marginLeft: '5px'}}>
                  <TextField
                    inputProps={{type: 'tel'}}
                    className={classes.formInput}
                    variant="outlined"
                    margin="dense"
                    fullWidth={true}
                    name="telephone"
                    value={telephone}
                    onChange={onInputChangeHandler} />
              </FormControl>
          </Grid>
          {(countryCodeError && !telephoneError) && <FormHelperText className={classes.errorMessage}>{fieldLabel('fields.countryCodeError')}</FormHelperText>}
          {telephoneError && <FormHelperText className={classes.errorMessage}>{fieldLabel('fields.phoneError')}</FormHelperText>}
      </Grid>
    );
}

PhoneField.propTypes = {
    telephone: PropTypes.string.isRequired,
    telephoneError: PropTypes.bool.isRequired,
    countryCodeError: PropTypes.bool.isRequired,
    onInputChangeHandler: PropTypes.func.isRequired,
    fieldLabel: PropTypes.func.isRequired,
};
