import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DATE_FORMATS, fetchDateFormat, fetchDatePerTimezone, fetchNoOfNights } from '../../../../utils/utility';
import ManualSelectBed from '../manual-bed-select';
import { fetchRequiredKeyValue, validateEditReservationPayload } from '../Helper';

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '1.2rem',
        fontWeight: 600,
        color: '#329ef5'
    },
    buttonContainer: {
        margin: '20px 0'
    },
    button: {
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#13408d',
        color: '#ffffff',
        borderRadius: 25,
        padding: 15,
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#13408d',
            color: '#ffffff',
        }
    },
    stepper: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
    },
    step: {
        backgroundColor: '#657898',
        color: '#ffffff',
        fontWeight: 600,
        padding: 10,
        '&.current': {
            backgroundColor: '#13408d',
        }
    }
}));

const PreponeCheckinOptions = props =>{
    const classes = useStyles();
    const { range, data, view, formType, onSubmitHandler, options } = props;
    const [errorMessage, setErrorMessage] = React.useState('');

    useEffect(() => {
        setErrorMessage('');
    }, [range]);

    const nights = fetchNoOfNights(range.startDate, data.checkIn);
    const endDate = fetchDatePerTimezone(data.checkIn).subtract(1, 'days').format(DATE_FORMATS.DEFAULT_DATE);

    const submitDataHandler = (form) => {
        let requiredKeys = ['bedID', 'roomID', 'roomTypeID', 'rate', 'date'];
        let items = fetchRequiredKeyValue([...form.items], requiredKeys)

        let isValidated = validateEditReservationPayload(items, requiredKeys);
        if (isValidated?.error) {
            setErrorMessage(isValidated.errorMessage)
            return
        }
        onSubmitHandler({
            guestNumber: data.guestNumber,
            editDates: {
                checkIn: range.startDate,
                checkOut: data.checkOut,
                durationRange: [{ startDate: range.startDate, endDate: endDate }],
                bedsPerDate: items,
                formType: formType
            },
        });

    }

    return (
        <React.Fragment>
            {/* -------------------- Pre-pone the accommodation from check-in side-------------------- */}
            <div className={classes.label}>You are extending the stay for this booking by {nights || 0} nights,
                new check-in is {fetchDateFormat(range.startDate, 'MMM DD YYYY')}</div>
            <div className={classes.spacing}></div>

            <ManualSelectBed
                view={view}
                data={data}
                options={options}
                errorMessage={errorMessage}
                range={{
                    startDate: range.startDate,
                    endDate: endDate
                }}
                submitDataHandler={submitDataHandler}
            />
        </React.Fragment>
    )
}

export default PreponeCheckinOptions;