import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import InputBase from "@material-ui/core/InputBase";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import get from "lodash/get";

import CustomInput from "../common/CustomInput";
import UploadDocs from "./UploadDocs";
import { GENDER_OPTIONS as genderOptions } from "../../../utils/constants";
import { GUEST_DOC_TYPES as documentTypeOptions } from "../../../common/constants/guest";

const useStyles = makeStyles((theme) => ({
  root: {},
  textInput: {
    width: "100%",
    height: "45px",
  },
  showMoreContainer: {
    marginBottom: "10px",
    "& button": {
      "&:hover": {
        background: "#FFFFFF",
      },
    },
  },
  showMore: {
    "&.hide": {
      display: "none",
    },
  },
}));

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: "none",
    boxShadow: 0,
    cursor: "pointer",
    margin: "0px",
    height: "45px",
    "&:hover": { border: "none" },
  }),
  indicatorSeparator: (base, state) => ({
    display: "none",
  }),
};

const GuestForm = (props) => {
  const classes = useStyles();
  const {
    data = {},
    fileSelectHandler,
    countryOptions,
    onChangeHandler,
    formErrors,
    timeStamp,
    todaysDate,
  } = props;

  const [showMore, setShowMore] = useState(false);
  const [editAll, setEditAll] = useState(false);

  const showMoreHandler = () => {
    setShowMore((prevState) => !prevState);
  };

  const onTextInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    onChangeHandler(name, value);
  };

  const onEditClick = () => {
    setEditAll(true);
  };

  const commonInputProps = {
    editable: true,
    onEditClick: onEditClick,
    presetEdit: editAll,
    resetEdit: timeStamp,
  };

  return (
    <div className={classes.root}>
      {/* --------------FIRST NAME------------------- */}
      <CustomInput
        label="First Name"
        value={data.firstName}
        error={formErrors.firstName}
        {...commonInputProps}
      >
        <InputBase
          className={classes.textInput}
          name="firstName"
          value={data.firstName}
          onChange={onTextInputChange}
        />
      </CustomInput>

      {/* --------------LAST NAME------------------- */}
      <CustomInput
        label="Last Name"
        value={data.lastName}
        error={formErrors.lastName}
        {...commonInputProps}
      >
        <InputBase
          className={classes.textInput}
          name="lastName"
          value={data.lastName}
          onChange={onTextInputChange}
        />
      </CustomInput>

      {/* --------------EMAIL ADDRESS------------------- */}
      <CustomInput
        label="Email Address"
        value={data.email}
        {...commonInputProps}
      >
        <InputBase
          type="email"
          className={classes.textInput}
          name="email"
          value={data.email}
          onChange={onTextInputChange}
        />
      </CustomInput>

      {/* --------------NATIONALITY------------------- */}
      <CustomInput
        label="Nationality"
        value={countryOptions.find((e) => e.value === data.nationality)?.label}
        {...commonInputProps}
      >
        <Select
          styles={selectStyle}
          options={countryOptions}
          value={countryOptions.find((e) => e.value === data.nationality)}
          onChange={(option) => onChangeHandler("nationality", option.value)}
        />
      </CustomInput>

      <div className={classes.showMoreContainer}>
        <Button
          startIcon={showMore ? <RemoveRoundedIcon /> : <AddRoundedIcon />}
          onClick={showMoreHandler}
          disableRipple
        >
          {showMore ? "Less details" : "More details"}
        </Button>
      </div>

      <div className={clsx(classes.showMore, { hide: !showMore })}>
        {/* --------------Phone Number------------------- */}
        <CustomInput
          label="Phone Number"
          value={data.phone}
          {...commonInputProps}
        >
          <InputBase
            className={classes.textInput}
            type="tel"
            name="phone"
            value={data.phone}
            onChange={onTextInputChange}
          />
        </CustomInput>

        {/* --------------DATE OF BIRTH------------------- */}
        <CustomInput
          label="Date of Birth"
          value={data.dateOfBirth}
          error={formErrors.dateOfBirth}
          {...commonInputProps}
        >
          <InputBase
            type="date"
            className={classes.textInput}
            defaultValue={data.dateOfBirth}
            onChange={(e) => onChangeHandler("dateOfBirth", e.target.value)}
            inputProps={{ max: todaysDate }}
          />
        </CustomInput>

        {/* --------------GENDER------------------- */}
        <CustomInput
          label="Gender"
          value={genderOptions.find((e) => e.value === data.gender)?.label}
          {...commonInputProps}
        >
          <Select
            styles={selectStyle}
            options={genderOptions}
            value={genderOptions.find((e) => e.value === data.gender)}
            onChange={(option) => onChangeHandler("gender", option.value)}
          />
        </CustomInput>

        {/* {data.documents.length > 0 && ( */}
        <>
          {/* --------------DOCUMENT TYPE------------------- */}
          <CustomInput
            label="Document Type"
            value={
              documentTypeOptions.find(
                (e) => e.value === get(data, "documents[0].type")
              )?.label
            }
            {...commonInputProps}
          >
            <Select
              styles={selectStyle}
              options={documentTypeOptions}
              value={documentTypeOptions.find(
                (e) => e.value === get(data, "documents[0].type")
              )}
              onChange={(option) =>
                onChangeHandler("documents[0].type", option.value)
              }
            />
          </CustomInput>

          {/* --------------DOCUMENT NUMBER------------------- */}
          <CustomInput
            label="Document Number"
            value={get(data, "documents[0].number", "")}
            {...commonInputProps}
          >
            <InputBase
              className={classes.textInput}
              value={get(data, "documents[0].number", "")}
              onChange={(e) =>
                onChangeHandler("documents[0].number", e.target.value)
              }
            />
          </CustomInput>

          {/* --------------DOCUMENT ISSUING COUNTRY------------------- */}
          <CustomInput
            label="Country of issue of the document"
            value={
              countryOptions.find(
                (e) => e.value === get(data, "documents[0].issuingCountry")
              )?.label
            }
            {...commonInputProps}
          >
            <Select
              styles={selectStyle}
              options={countryOptions}
              value={countryOptions.find(
                (e) => e.value === get(data, "documents[0].issuingCountry")
              )}
              menuPlacement="top"
              onChange={(option) =>
                onChangeHandler("documents[0].issuingCountry", option.value)
              }
            />
          </CustomInput>

          {/* --------------DOCUMENT EXPIRY------------------- */}
          <CustomInput
            label="Document expiry"
            value={get(data, "documents[0].expiry", "")}
            error={formErrors.docExpiry}
            {...commonInputProps}
          >
            <InputBase
              className={classes.textInput}
              type="date"
              defaultValue={get(data, "documents[0].expiry", "")}
              onChange={(e) =>
                onChangeHandler("documents[0].expiry", e.target.value)
              }
              inputProps={{ min: todaysDate }}
            />
          </CustomInput>
        </>
        {/* )} */}

        {/* --------------COMPANY NAME------------------- */}
        <CustomInput
          label="Company Name"
          value={data.companyName}
          {...commonInputProps}
        >
          <InputBase
            className={classes.textInput}
            name="companyName"
            value={data.companyName}
            onChange={onTextInputChange}
          />
        </CustomInput>

        {/* --------------TAX ID------------------- */}
        <CustomInput label="Tax ID" value={data.taxID} {...commonInputProps}>
          <InputBase
            className={classes.textInput}
            name="taxID"
            value={data.taxID}
            onChange={onTextInputChange}
          />
        </CustomInput>

        {/* --------------ADDRESS------------------- */}
        <CustomInput
          label="Address"
          value={data.address.lineOne}
          {...commonInputProps}
        >
          <InputBase
            className={classes.textInput}
            name="address.lineOne"
            value={data.address.lineOne}
            onChange={onTextInputChange}
          />
        </CustomInput>

        {/* --------------CITY------------------- */}
        <CustomInput
          label="City"
          value={data.address.city}
          {...commonInputProps}
        >
          <InputBase
            className={classes.textInput}
            name="address.city"
            value={data.address.city}
            onChange={onTextInputChange}
          />
        </CustomInput>

        {/* --------------STATE------------------- */}
        <CustomInput
          label="State"
          value={data.address.state}
          {...commonInputProps}
        >
          <InputBase
            className={classes.textInput}
            name="address.state"
            value={data.address.state}
            onChange={onTextInputChange}
          />
        </CustomInput>

        {/* --------------ZIPCODE------------------- */}
        <CustomInput
          label="Zipcode"
          value={data.address.zipCode}
          {...commonInputProps}
        >
          <InputBase
            className={classes.textInput}
            name="address.zipCode"
            value={data.address.zipCode}
            onChange={onTextInputChange}
          />
        </CustomInput>

        {/* --------------COUNTRY------------------- */}
        <CustomInput
          label="Country"
          value={
            countryOptions.find((e) => e.value === data.address.country)?.label
          }
          {...commonInputProps}
        >
          <Select
            styles={selectStyle}
            options={countryOptions}
            value={countryOptions.find((e) => e.value === data.address.country)}
            menuPlacement="top"
            onChange={(option) =>
              onChangeHandler("address.country", option.value)
            }
          />
        </CustomInput>
      </div>

      <UploadDocs documents={data.documents} onFileSelect={fileSelectHandler} />
    </div>
  );
};

export default GuestForm;
