const ICON_URL = '/images/icons/';

const ICONS = {
  ADD: ICON_URL + 'dark-add.png',
  BED: ICON_URL + 'dark-bed.png',
  CALENDAR: ICON_URL + 'dark-calendar.png',
  CALL: ICON_URL + 'dark-call.png',
  EMAIL: ICON_URL + 'dark-email.png',
  FOOTPRINT: ICON_URL + 'dark-footprint.png',
  HOSTEL: ICON_URL + 'dark-hostel.png',
  MINUS: ICON_URL + 'dark-minus.png',
  MORE: ICON_URL + 'dark-more.png',
  NEXT: ICON_URL + 'dark-next.png',
  SLEEP: ICON_URL + 'dark-sleep.png',
  SMILE: ICON_URL + 'dark-boy-broad-smile.png',
  HAMMOCK: ICON_URL + 'dark-hammock.png',
  FLOWER: ICON_URL + 'dark-flower.png',
  BULB: ICON_URL + 'dark-bulb.png',
  SUPPORT: ICON_URL + 'dark-support.png',
  CLOSE: ICON_URL + 'dark-close.png',
}

export { ICONS }