import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import { withStyles } from "@material-ui/core/styles";
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';

import { validateEmail } from '../../booking_engine/helper';
import UploadIdProof from '../upload_id_proof';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import {
    Box,
    TextField,
    Button,
    Select
} from '@material-ui/core';
import CustomInput from './CustomInput';
import { DATE_FORMATS, fetchDateFormat } from '../../../utils/utility';

const styles = theme => ({
    root: {
        padding: '40px 30px',
        [theme.breakpoints.down('sm')]: {
            padding: '30px 10px'
        }
    },
    inputLabel: {
        fontSize: '1.2rem',
        color: '#666666',
        marginBottom: 10,
    },
    formControl: {
        width: '100%'
    },
    textInput:{
        background: '#FFFFFF',
        border: '1px solid #BDBDBD',
        borderRadius: '5px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            '&.Mui-error fieldset': {
                border: '1px solid #f44336'
            },
        },
    },
    selectInput:{
        boxShadow: 'none',
        border: '1px solid #BDBDBD',
        borderRadius: '5px',
        marginTop: '10px',
        '& fieldset': {
            border: 'none',
            borderRadius: '5px',
        },
        '&:hover fieldset': {
            border: 'none',
            borderRadius: '5px',
        },
        '& .MuiSelect-select:focus': {
            borderRadius: '5px',
            backgroundColor: '#FFFFFF',
        },
        '&.Mui-error fieldset': {
            border: '1px solid #f44336'
        },
    },
    submitBtn: {
        borderRadius: '5px',
        backgroundColor: '#000',
        border: 'none',
        color: '#fff',
        padding: '10px',
        marginTop: '5px',
        fontWeight: 'bold',
        padding: '7px 35px',
        border: '0.5px solid #000',
        '&:hover': {
            backgroundColor: '#000',
            color: '#FFFF',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '10px',
            fontSize: '15px'
        }
    },
    cancelBtn:{
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: 'none',
        color: '#000',
        padding: '10px',
        marginTop: '5px',
        marginRight: 10,
        fontWeight: 'bold',
        padding: '7px 30px',
        border: '0.5px solid #000',
        '&:hover': {
            backgroundColor: '#FFF',
            color: '#000',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: '10px',
            fontSize: '15px',
            marginLeft: '0px',
            marginBottom: '5px'
        }
    },
    errorMessage: {
        color: "#f44336",
        marginLeft: '0px',
        fontSize: '1.2rem'
    }
});

const formHelper = {
    nameError: false,
    emailError: false,
    invalidEmail: false,
    phoneError: false,
    countryError: false,
    addressError: false,
    cityError: false,
    docError: false,
    docNumberError: false,
    docExpiryError: false,
    docIssueCountryError: false,
}

const docFields = [
    { name: 'number', configName: 'documentNumber', errorName: 'docNumberError' },
    { name: 'expiry', configName: 'documentExpiry', errorName: 'docExpiryError' },
    { name: 'issuingCountry', configName: 'documentIssuingCountry', errorName: 'docIssueCountryError' },
];

function getCountryOptions() {
    const countries = i18nIsoCountries.getNames("en");
    let countryOptions = [];
    for (var key in countries) {
      if (countries.hasOwnProperty(key)) {
        countryOptions.push({ value: i18nIsoCountries.alpha2ToAlpha3(key), label: countries[key] })
      }
    }
    return countryOptions;
}

function getTodaysDate(timezone) {
    return moment.tz(new Date(), timezone).format('YYYY-MM-DD');
}

class UserInfoFrom extends Component {
    state ={
        name: '',
        email: '',
        phone: '',
        country: '',
        address: '',
        city: '',
        countries: [],
        uploadDoc: false,
        ...formHelper,
        todaysDate: new Date()
    }

    componentDidMount(){
        const countries = getCountryOptions();
        const { guestData, timezone } = this.props;

        const emailRegex = /^[A-Za-z0-9._%+-]+@(guest.booking.com|m.expediapartnercentral.com)$/;

        this.setState({
            name: guestData.firstName + ' ' + guestData.lastName,
            email: (emailRegex.test(guestData.email)) ? '' : guestData.email,
            phone: guestData.phone ? guestData.phone : '',
            country: (guestData.address &&  guestData.address.country) ? guestData.address.country : '',
            city: (guestData.address &&  guestData.address.city) ? guestData.address.city : '',
            address: (guestData.address &&  guestData.address.lineOne) ? guestData.address.lineOne : '',
            uploadDoc: (guestData.documents && guestData.documents.length > 0) ? true : false,
            countries,
            documents: guestData?.documents ? [ ...guestData?.documents ] : [],
            todaysDate: getTodaysDate(timezone)
        });
    }

    componentDidUpdate(prevProps){
        const { guestData } = this.props
        if(guestData?.documents?.length > 0 && !isEqual(guestData.documents, prevProps.guestData.documents)){
            const clonedGuestData = cloneDeep(guestData.documents);
            if(this.state.documents.length > 0){
                clonedGuestData[0] = { ...this.state.documents[0], ...clonedGuestData[0] }
            }
            this.setState({ documents: [ ...clonedGuestData ] });
        }
    }

    onInputChangeHandler = event =>{
        
        const inputVal = event.target.value;
        const inputName = event.target.name;
        const isError = !inputVal.trim() ? true : false;

        this.props.checkForChange();

        if(inputName === 'email') {
            this.setState({
                [inputName]: inputVal,
                [`${inputName}Error`]: isError,
                invalidEmail: false
            });
            return;
        }
        
        this.setState({
            [inputName]: inputVal,
            [`${inputName}Error`]: isError
        });
    }

    validateGuestDetails = () =>{
        const { 
            name,
            email,
            // uploadDoc
         } = this.state;

         const { customerDetails, customerDocuments, guestData } = this.props;

        let formInputStatus = { ...formHelper };
        let validationSuccess = true;

        const inputFields = [
            { name: 'email', errorName: 'emailError' },
            { name: 'phone', errorName: 'phoneError' },
            { name: 'city', errorName: 'cityError' },
            { name: 'address', errorName: 'addressError' },
            { name: 'country', errorName: 'countryError' },
        ];

        //Validate name....................
        if(!name.trim()) {
            formInputStatus.nameError = true;
            validationSuccess = false;
        }

        //Validate email....................
        if(customerDetails?.email === 'mandatory') {
            if(email.length > 0 && !validateEmail(email)) {
                formInputStatus.invalidEmail = true;
                validationSuccess = false;
            }
        }

        //Validate mandatory fields....................
        for( const field of inputFields){

            const fieldValue = this.state?.[field.name].trim() || '';
            const isMandatory = customerDetails?.[field.name] === 'mandatory';

            if(!fieldValue && isMandatory){
                formInputStatus[field.errorName] = true;
                validationSuccess = false;
            }
        }

        //Validate document....................
        if(customerDocuments && !customerDocuments.skip) {

            if (customerDocuments.documentRequired) {

                if(guestData?.documents?.length == 0){
                    formInputStatus.docError = true;
                    validationSuccess = false;
                }
            
                //Validate mandatory document fields....................
                for( const field of docFields){

                const fieldValue = get(this.state, `documents[0].${field.name}`, '');
                const isMandatory = customerDocuments?.[field.configName] === 'mandatory';
        
                if(!fieldValue && isMandatory){
                    formInputStatus[field.errorName] = true;
                    validationSuccess = false;
                }
            }
            }
        }

        this.setState({ ...formInputStatus });
        return validationSuccess;
    }

    saveHandler = () =>{
        const { 
            guestData, 
            accommodationID, 
            submitHandler,
            toggleMode 
        } = this.props;

        if(this.validateGuestDetails()){
            const { 
                name,
                email,
                phone,
                country,
                city,
                address,
                documents
            } = this.state;

            const nameInfo = name.trim().split(' ')
            const formData = {
                firstName: nameInfo[0],
                lastName: nameInfo[1] || '',
                email,
                phone,
                city,
                address: {...guestData.address, country: country, city: city, lineOne: address},
                documents
            }

            set(formData, )

            submitHandler({
                formData, 
                guestID: guestData._id, 
                accommodationID,
                toggleMode
            })
        }
    }

    docUploaded = (value) => {
        const { guestData } = this.props;

        if(!value) {
            if(guestData?.documents?.length === 0) {
                this.setState({ uploadDoc: value, docError: false });
            }
        }
        else {
            this.setState({ uploadDoc: value, docError: false });
        }
    }

    documentInputHandler = (type, value) =>{
        //This resets the "Please complete guest profiles to proceed." error.
        this.props.checkForChange();

        const documentList = cloneDeep(this.state.documents);
        set(documentList, `[0].${type}`, value);

        const errorField = docFields.find(field => field.name == type);
        this.setState({ 
            documents: [ ...documentList ],
            [errorField.errorName]: false
        });
    }

    getDocumentExpiry = () =>{
        const docExpiry = get(this.state.documents, '[0].expiry', '');

        if(docExpiry){
            return  fetchDateFormat(docExpiry, DATE_FORMATS.DEFAULT_DATE);
        }

        return docExpiry
    }

    render() {
        const { classes, guestData, customerDetails, customerDocuments, t, newGuest } = this.props;
        const { 
            name,
            nameError,
            email,
            emailError,
            invalidEmail,
            phone,
            phoneError,
            country,
            countryError,
            address,
            addressError,
            city,
            cityError,
            countries,
            docError,
            documents,
            docNumberError,
            docExpiryError,
            docIssueCountryError,
            todaysDate
        } = this.state;
        const settings = { customerDocuments }

        return (
            <Box className={classes.root}>

                {/* ---------------------------NAME INPUT---------------------- */}
                <CustomInput
                    hide={customerDetails?.name == 'none'}
                    isMandatory={customerDetails?.name === 'mandatory'}
                    label={t('fields.name')} 
                    isError={nameError}
                    name="name"
                    errorMessage={t('fields.nameError')}
                >
                    <TextField
                        className={classes.textInput}
                        variant="outlined"
                        margin="dense"
                        fullWidth={true}
                        name="name"
                        value={name}
                        onChange = { this.onInputChangeHandler }
                    />
                </CustomInput>
                
                {/* ---------------------------EMAIL INPUT---------------------- */}
                <CustomInput
                    hide={customerDetails?.email == 'none'}
                    isMandatory={customerDetails?.email === 'mandatory'}
                    label={t('fields.email')} 
                    isError={invalidEmail || emailError}
                    name="email"
                    errorMessage={
                        invalidEmail && t('fields.emailError') || 
                        emailError && t('fields.emailRequired')
                    }
                >
                    <TextField
                        inputProps={{ type: "email" }}
                        className={classes.textInput}
                        variant="outlined"
                        margin="dense"
                        fullWidth={true}
                        name="email"
                        value={email}
                        onChange = { this.onInputChangeHandler }
                    />
                </CustomInput>
                
                {/* ---------------------------PHONE INPUT---------------------- */}
                <CustomInput
                    hide={customerDetails?.phone == 'none'}
                    isMandatory={customerDetails?.phone === 'mandatory'}
                    label={t('fields.phone')} 
                    isError={phoneError}
                    name="phone"
                    errorMessage={t('fields.phoneError')}
                >
                    <TextField
                        inputProps={{ type: "tel" }}
                        className={classes.textInput}
                        variant="outlined"
                        margin="dense"
                        fullWidth={true}
                        name="phone"
                        type="tel"
                        value={phone}
                        onChange = { this.onInputChangeHandler }
                    />
                </CustomInput>
                
                {/* ---------------------------ADDRESS INPUT---------------------- */}
                <CustomInput
                    hide={customerDetails?.address == 'none'}
                    isMandatory={customerDetails?.address === 'mandatory'}
                    label={t('fields.address')} 
                    isError={addressError}
                    name="address"
                    errorMessage={t('fields.addressError')}
                >
                    <TextField
                        className={classes.textInput}
                        variant="outlined"
                        margin="dense"
                        fullWidth={true}
                        name="address"
                        value={address}
                        onChange = { this.onInputChangeHandler }
                    />
                </CustomInput>

                {/* ---------------------------CITY INPUT---------------------- */}
                <CustomInput
                    hide={customerDetails?.city == 'none'}
                    isMandatory={customerDetails?.city === 'mandatory'}
                    label={t('fields.city')} 
                    isError={cityError}
                    name="city"
                    errorMessage={t('fields.cityError')}
                >
                    <TextField
                        className={classes.textInput}
                        variant="outlined"
                        margin="dense"
                        fullWidth={true}
                        name="city"
                        value={city}
                        onChange = { this.onInputChangeHandler }
                    />
                </CustomInput>
                
                {/* ---------------------------COUNTRY INPUT---------------------- */}
                <CustomInput
                    hide={customerDetails?.country == 'none'}
                    isMandatory={customerDetails?.country === 'mandatory'}
                    label={t('fields.country')} 
                    isError={countryError}
                    name="country"
                    errorMessage={t('fields.countryError')}
                >
                    <Select
                        native
                        input={<OutlinedInput />}
                        fullWidth={true}
                        className={classes.selectInput}
                        margin="dense"
                        name="country"
                        value={country}
                        onChange = { this.onInputChangeHandler }
                    >
                        <option aria-label="None" value="" />
                        {countries.map((country, index) => (
                            <option key={index} value={country.value} >{country.label}</option>
                        ))}
                    </Select>
                </CustomInput>
                
                {/* ---------------------------DOCUMENT INPUT---------------------- */}
                {!newGuest && !customerDocuments?.skip && (
                    <>
                        {/* ---------------------------DOCUMENT NUMBER INPUT---------------------- */}
                        <CustomInput
                            hide={customerDocuments?.documentNumber == 'none'}
                            isMandatory={customerDocuments?.documentNumber === 'mandatory'}
                            label={t('fields.documentNumber')} 
                            isError={docNumberError}
                            name="documentNumber"
                            errorMessage={t('fields.documentNumberError')}
                        >
                            <TextField
                                className={classes.textInput}
                                variant="outlined"
                                margin="dense"
                                fullWidth={true}
                                name="documentNumber"
                                value={get(documents, '[0].number', '')}
                                onChange = {e =>this.documentInputHandler('number', e.target.value)}
                            />
                        </CustomInput>

                        {/* ---------------------------DOCUMENT EXPIRY INPUT---------------------- */}
                        <CustomInput
                            hide={customerDocuments?.documentExpiry == 'none'}
                            isMandatory={customerDocuments?.documentExpiry === 'mandatory'}
                            label={t('fields.documentExpiry')} 
                            isError={docExpiryError}
                            name="documentExpiry"
                            errorMessage={t('fields.documentExpiryError')}
                        >
                            <TextField
                                className={classes.textInput}
                                variant="outlined"
                                margin="dense"
                                fullWidth={true}
                                name="documentExpiry"
                                inputProps = {{ min: todaysDate }}
                                type="date"
                                value={this.getDocumentExpiry()}
                                onChange = {e =>this.documentInputHandler('expiry', e.target.value)}
                            />
                        </CustomInput>

                        {/* ---------------------------DOCUMENT ISSUING COUNTRY INPUT---------------------- */}
                        <CustomInput
                            hide={customerDocuments?.documentIssuingCountry == 'none'}
                            isMandatory={customerDocuments?.documentIssuingCountry === 'mandatory'}
                            label={t('fields.documentIssueCountry')} 
                            isError={docIssueCountryError}
                            name="documentIssuingCountry"
                            errorMessage={t('fields.documentIssueCountryError')}
                        >
                            <Select
                                native
                                input={<OutlinedInput />}
                                fullWidth={true}
                                className={classes.selectInput}
                                margin="dense"
                                name="documentIssuingCountry"
                                value={get(documents, '[0].issuingCountry', '')}
                                onChange = {e =>this.documentInputHandler('issuingCountry', e.target.value)}
                            >
                                <option aria-label="None" value="" />
                                {countries.map((country, index) => (
                                    <option key={index} value={country.value} >{country.label}</option>
                                ))}
                            </Select>
                        </CustomInput>

                        <div>
                            <UploadIdProof guestData={guestData} settings={settings} docUploaded={this.docUploaded}/>
                            {docError && <span className={classes.errorMessage}>{t('onlineCheckin.doc.idRequired')}</span>}
                        </div>
                    </>
                )}
                
                {/* ---------------------------SAVE BUTTON---------------------- */}
                <Box textAlign="right">
                    <Button
                        className={classes.submitBtn}
                        onClick={this.saveHandler}
                    >
                        {t('actions.save')}
                    </Button>
                </Box>
            </Box>
        );
    }

}

const mapStateToProps = state =>{
    const { onlineCheckin } = state;
    return{
        timezone:  onlineCheckin?.propertyDetails?.timezone || null,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(UserInfoFrom));