import React, { useState } from 'react';
import { connect } from 'react-redux'; 
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';

import { getAccomodationInfo, formatDateRange } from '../helper';
import useReservationStatus from '../hooks/useReservationStatus';
import IMAGES from '../../../constants/images';
import ConfirmForm from '../../common/ConfirmForm';
import { IconButton } from '../common/Button';
import { editReservation } from '../../../redux/actions/reservations';

const useStyles = makeStyles(theme =>({
    root: {
        border: "1px solid #e0e0e0",
        borderRadius: "10px",
        overflow: "hidden",
        marginTop: "10px",
        '&.delete': {
            border: '2px solid #d96977'
        }
    },
    infoContainer: {
        display: 'flex',
        '& .roomImage': {
            width: "150px",
            height: "100px",
            objectFit: "cover",
            [theme.breakpoints.down('sm')]: {
                width: "100px",
            }
        },

        '&.split': {
            borderTop: "5px solid #f5625f",
        }
    },
    editBtn: {
        width: "100%",
        borderTop: "1px solid #e0e0e0",
        padding: "15px",
        fontSize: "1.3rem",
        color: "#737373",
        backgroundColor: "#e0e0e033",
        '&:hover': {
            backgroundColor: '#0000000a'
        },
        '&.disableClick':{
            pointerEvents: "none",
        },
    },
    roomInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flex: 1,
        padding: "0px 10px",
        position: 'relative',

        '& p': { margin: 0 },

        '& .primaryText': {
            fontSize: "1.4rem",
            fontWeight: 600,
            marginBottom: "10px",
            [theme.breakpoints.down('sm')]: {
                fontSize: "1.2rem",
            }
        },
        '& .secondaryText': {
            fontSize: "1.1rem",
            fontWeight: 600,
            color: "#737373",
        }
    },
    confirmDelete: {
        display: "flex",
        padding: "10px",
        justifyContent: "center",
        borderTop: "1px solid #e0e0e0",
    },
    iconContainer: {
        position: "absolute",
        top: "9px",
        right: "9px",
    },
}));

const DEFAULT_ROOM_IMAGES = {
    private: IMAGES.PLACEHOLDERS.privateRoom,
    dorm: IMAGES.PLACEHOLDERS.sharedRoom
}

const ACTION_NAME = 'DELETE_ACCOMMODATION';

const RoomInfo = props => {
    const classes = useStyles();
    const { data, rooms, priceEditHandler, guestNumber, allowDelete, dispatch, reservation } = props;
    const [confirmOpen, setConfirmOpen] = useState(false);
    const resvStatus = useReservationStatus();

    const editPriceHandler = () =>{
        if(!resvStatus.isCancelled) priceEditHandler(guestNumber);
    }

    const getRoomImage = (roomID, dormOrPrivate) =>{
        const roomData = rooms?.rooms?.[roomID] || {};

        if(roomData?.images && roomData.images.length > 0){
            return roomData.images[0].key
        }

        return DEFAULT_ROOM_IMAGES[dormOrPrivate];
    }

    const deleteAccommodationHandler = () =>{
        
        if(resvStatus.isCancelled) return;

        const payload = {
            reservationID: reservation._id,
            deleteGuest: { guestNumber }
        }
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        dispatch(editReservation(payload))
            .then(success =>{
                dispatch({ type: `${ACTION_NAME}_SUCCESS` });
            })
    }

    return (
        <div className={clsx(classes.root, { delete: confirmOpen })}>

            {data.map((accomdation, index) =>{

                const isSplitAccomodation = index > 0;
                const roomInfo = { 
                    roomTypeID: accomdation.roomTypeID,
                    roomID: accomdation.roomID,
                    bedID: accomdation.bedID
                }
                const { name: bedName, dormOrPrivate } = getAccomodationInfo(roomInfo, rooms);
                const dates = { 
                    startDate: accomdation.checkIn,
                    endDate: accomdation.checkOut
                }
                const durationDate = formatDateRange(dates, { dateFormat: 'Do MMM YYYY', seperator: '-' })
  
                return(
                    <div key={`room_${accomdation._id}`} className={clsx(classes.infoContainer, { split: isSplitAccomodation })}>
                        
                        <img className="roomImage" src={getRoomImage(accomdation.roomID, dormOrPrivate)} />
                        
                        <div className={classes.roomInfo}>

                            {!resvStatus.isCancelled && allowDelete && index == 0 && !confirmOpen && (
                                <IconButton 
                                    className={classes.iconContainer}
                                    imgSrc={IMAGES.ICONS.trash}
                                    onClick={() => setConfirmOpen(true)}
                                />
                            )}
                            
                            <p className="primaryText">{bedName}</p>
                            <p className="secondaryText">{durationDate}</p>
                        </div>
                    </div>
                )
            })}

            {confirmOpen && (
                <div className={classes.confirmDelete}>
                    <ConfirmForm
                        open={true}
                        setOpen={setConfirmOpen}
                        onClose={() =>setConfirmOpen(false)}
                        onConfirm={deleteAccommodationHandler}
                    >
                        Are you sure you would like to delete this accommodation?
                    </ConfirmForm>
                </div>
            )}
            
            {!confirmOpen && ( 
                <ButtonBase 
                    className={clsx(classes.editBtn, { disableClick: resvStatus.isCancelled })}
                    onClick={editPriceHandler}
                >
                    Edit and view price
                </ButtonBase>
            )}
        
        </div>
    )
}

const mapStateToProps = state =>{
    const { rooms, reservationDetails } = state;
    return {
        rooms: rooms || {},
        reservation: reservationDetails?.reservation || {},
    }
}

export default connect(mapStateToProps)(RoomInfo);