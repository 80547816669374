import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Dialog from '../common/CustomDialog';
import PaymentContainer from '../payment';
import CardCaptureForm from './card-capture/CardCaptureForm';

const useStyles = makeStyles(theme =>({
    root: {
        '& .dialogBody': {
            minHeight: "450px",
            maxHeight: "450px",
            [theme.breakpoints.down('md')]: {
                paddingBottom: '20px',
                minHeight: "390px",
                maxHeight: "390px",
            },
            [theme.breakpoints.down('sm')]: {
                minHeight: "unset",
                maxHeight: "unset",
            }
        },
        '&.addCard': {
            paddingBottom: 'unset'
        }
    },
    hideContent: {
        display: 'none'
    }
}));

const CollectPaymentModal = props =>{
    const classes = useStyles();
    const [showAddCardModal, setShowAddCardModal] = useState(false);
    const { closeModalHandler, reservation, createTransactionHandler } = props;

    const handleCloseModal = () =>{
        if(showAddCardModal){
            setShowAddCardModal(false);
            return;
        }
        closeModalHandler();
    }

    const showAddCardHandler = () =>{
        setShowAddCardModal(true);
    }

    return(
        <Dialog
            className={clsx(classes.root, { addCard: showAddCardModal })}
            headerTitle={showAddCardModal ? 'Add Card' : 'Collect Payment'}
            closeModalHandler={handleCloseModal}
        >
            <div className={clsx({ [classes.hideContent]: showAddCardModal })}>
                <PaymentContainer 
                    createTransactionHandler={createTransactionHandler}
                    showAddCardHandler={showAddCardHandler} 
                    closeModalHandler={closeModalHandler}    
                />
            </div>

            {showAddCardModal && (
                <div className={classes.container}>
                    <CardCaptureForm 
                        reservationID={reservation._id}
                        closeModalHander={handleCloseModal}
                    />
                </div>
            )}
            
        </Dialog>
    )
}

const mapStateToProps = state =>{
    const { reservationDetails } = state;
    return {
        reservation: reservationDetails?.reservation || {}
    }
}

export default connect(mapStateToProps)(CollectPaymentModal);