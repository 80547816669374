import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import { withTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import PaymentRoomItem from './PaymentRoomItem';
import PaymentForm from './stripe_form';
import Footer from '../Footer';

import { calculateSumOfArray, getCurrency } from '../../booking_engine/helper';
import { fetchNoOfDays, fetchDateFormat } from '../../../utils/utility';
import { markReservationStatus } from '../../../redux/actions/onlineCheckin';

const styles = theme =>({
    root: {
        margin: '0 auto',
        width: '75%',
        maxWidth: 500,
        paddingTop: '60px',
        paddingBottom: 20,
        [theme.breakpoints.down('sm')]: {
           width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: '330px',
        }
    },
    title:{
        color: '#333333',
        fontSize: '2.4rem',
        marginBottom: 40,
    },
    rateInfoContainer:{
        display: 'flex',
        justifyContent: 'space-between'
    },
    spaceLeft:{
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '0px',
        }
    },
    borderSpacing: {
        marginTop: 10,
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0px',
        }
    },
    backIcon: {
        color: '#000000',
        cursor: 'pointer',
        fontSize: '18px',
        width: 40,
    },
    bottom: {
        marginBottom: 20
    },
    left: {
        marginLeft: 40
    },
    header: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '20px'
    }
});

const Payment = (props) =>{
    //const [initial, setInitial] = React.useState(true)
    const { 
        classes,
        summaryList,
        currency = {
            code: '',
            currency: ''
        },
        balance,
        taxAmount,
        totalAmount,
        nextStep,
        stripeAccount,
        settings,
        t,
        history
    } = props;

    const checkInHandler = () =>{
        const { dispatch, nextStep, reservationID } = props;
        dispatch(markReservationStatus( reservationID, nextStep ));
    }

    React.useEffect(() => {
        if((settings) && (settings.payments) && (settings.payments.skip)) {
            //setInitial(false)
            checkInHandler()
        }
    }, [settings])

    const handleBack = () => {
        history.goBack()
    }

    return(
        <>
            <Box className={classes.root}>
                <Box mb={4}>
                    <div className={classes.title}>
                        {t('onlineCheckin.payment.summary')}
                    </div>
                </Box>
                
                {summaryList.map((data, index) =>(
                    <PaymentRoomItem key={`summary_${index}`} accommodationData={data}/>
                ))}
                
                <Box className={`${classes.rateInfoContainer} ${classes.spaceLeft}`}>
                    <Box fontSize="15px">{t('onlineCheckin.payment.total')}</Box>
                    <Box fontSize="15px">{getCurrency(currency, totalAmount)}</Box>
                </Box>
                
                { taxAmount ? (
                    <Box className={`${classes.rateInfoContainer} ${classes.spaceLeft}`} mb={2}>
                        <Box fontSize="12px" fontWeight="bold" color="gray" >{t('onlineCheckin.payment.tax')}</Box>
                        <Box fontSize="12px" fontWeight="bold" color="gray">{getCurrency(currency, taxAmount)}</Box>
                    </Box>
                ) : ''}
                
                <Box borderTop="1px solid #E0E0E0" className={classes.borderSpacing} mb={2}/>
                
                <Box className={`${classes.rateInfoContainer} ${classes.spaceLeft}`} mb={6}>
                    <Box fontSize="17px" fontWeight="bold" >{t('onlineCheckin.payment.balance')}</Box>
                    <Box fontSize="17px" fontWeight="bold">{getCurrency(currency, balance)}</Box>
                </Box>
                {balance > 0 && (
                    <PaymentForm 
                        stripeAccount={stripeAccount}
                        nextStep={nextStep}
                    />
                )}
            </Box>
            {balance == 0 && (
                <Footer 
                    stepInfo=""
                    buttonLabel={t('onlineCheckin.payment.checkIn')}
                    onProceed = {checkInHandler}
                />
            )}
        </>
    )
}

const mapStateToProps = state =>{
    const { onlineCheckin } = state;
    const currency = onlineCheckin.currency;
    const accommodationList = onlineCheckin.accommodationList;
    const roomTypes = onlineCheckin.roomTypes;

    const summaryList = accommodationList.map(data=>{
        const rates = data.rateList.map(rateData => rateData.rate);
        const amount = calculateSumOfArray(rates);
        const amountWithCurrency = getCurrency(currency, amount);
        const startDate = fetchDateFormat(data.checkIn, 'ddd, MMM D');
        const endDate = fetchDateFormat(data.checkOut, 'ddd, MMM D');
        const roomTypeData = roomTypes[data.roomTypeID];
        return{
            guestCount: data.guestCount,
            duration: fetchNoOfDays(data.checkIn, data.checkOut),
            date: `${startDate} - ${endDate}`,
            amount: amountWithCurrency,
            name: roomTypes[data.roomTypeID]?.name || '',
            image: roomTypeData && roomTypeData.images && roomTypeData.images.length > 0 ?  
            roomTypeData.images[roomTypeData.images.length - 1 ] : '/images/P_1.png'
        }
    });

    const stripeAccount = onlineCheckin.propertyDetails && onlineCheckin.propertyDetails.stripeAccount ? 
        onlineCheckin.propertyDetails.stripeAccount : 
        null;

    return{
        summaryList,
        currency,
        balance: onlineCheckin.balance,
        taxAmount: onlineCheckin.taxAmount,
        totalAmount: onlineCheckin.totalAmount,
        reservationID: onlineCheckin.reservationID,
        stripeAccount,
        settings: onlineCheckin.settings
    }
}
export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(Payment)));