import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import IMAGES from '../../../constants/images';
import HorizontalScollView from '../common/HorizontalScollView';
import GuestItem from './GuestItem';
import RoomInfo from './RoomInfo';
import EditPrice from '../edit-price';

import { fetchGuestAccomodation, formatDateRange } from '../helper';
import CustomerDetails from '../customer-details';
import { fetchNoOfNights } from '../../../utils/utility';
import { toTitleCase } from '../../../utils/wordUtils';

import { Grid } from '@material-ui/core';

const useStyles = makeStyles(theme =>({
    root: {
        backgroundColor: "#fff",
        padding: "30px 20px",
        borderRadius: "10px",
        border: "1px solid #e0e0e0",
        [theme.breakpoints.down('sm')]: {
            padding: "30px 10px",
        }
    },
    header: {
        display: 'flex',
        marginBottom: '20px',
        '& h4': {
            margin: '10px 0px',
            marginRight: "20px",
        },
        [theme.breakpoints.down('sm')]:{
            flexDirection: 'column'
        }
    },
    iconInfo: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: "1.1rem",
            fontWeight: 600,
            marginLeft: "10px",
        },
    },
    iconContainer: {
        alignItems: 'center',
        backgroundColor: "#dff1ff",
        borderRadius: "50%",
        height: 25,
        width: 25,
        display: 'flex',
        justifyContent: 'center',
        '&.icon2': {
            backgroundColor: '#dff4e3'
        },
        '&.icon3': {
            backgroundColor: '#f4f1e0'
        }
    },
    icon: {
        height: 12,
        width: 12
    },
    listConatiner: {
        marginBottom: '40px'
    },
    guestList: {
        width: '100%',
        // [theme.breakpoints.up('sm')]: {
        //     width: '100%',
        // }
    }
}));

const DATE_FORMAT_OPTIONS = { 
    dateFormat: 'Do MMM YYYY', 
    seperator: '-' 
}

const BookingDetails = props =>{
    const classes = useStyles();
    const { reservation } = props;
    const guestCounterRef = useRef(1);
    let { current: guestCounter } = guestCounterRef;

    const [guestAccomodation, setGuestAccomodation] = useState({});
    const [selectedGuestNumber, setSelectedGuestNumber] = useState(null);
    const [selectedGuest, setSelectedGuest] = useState({ id: null });
    const [accommodationCount, setAccommodationCount] = useState(0);

    const nights = fetchNoOfNights(reservation.checkIn, reservation.checkOut);
    const reservationDuration = formatDateRange({
        startDate: reservation.checkIn,
        endDate: reservation.checkOut
    }, DATE_FORMAT_OPTIONS);

    useEffect(() =>{
        if(reservation.accommodationList){
            const accomodationData = fetchGuestAccomodation(reservation.accommodationList);
            setGuestAccomodation(accomodationData);
            setAccommodationCount(Object.keys(accomodationData).length);
        }
    }, [reservation]);

    const getCutomerList = (accommodation) =>{

        const { guests = [] } = reservation;

        const customerList = accommodation.map((acc, accIndx) =>{

            if(accIndx == 0 && acc.guestNumber == 1 && acc.customerID.length == 0){
                const primaryGuest = { ...guests[0] };
                const customerName = toTitleCase(`${primaryGuest?.firstName} ${primaryGuest?.lastName}`);
                guestCounter++;
                return (
                    <GuestItem 
                        key="guest_primary"
                        label="Primary booker" 
                        name={customerName}
                        onEditHandler={() =>setSelectedGuest({ id: primaryGuest._id, isPrimaryGuest: true })}
                    />
                )
            }

            if(accIndx == 0 && acc.guestNumber > 1 && acc.customerID.length == 0){
                const label = `Guest ${guestCounter}`;
                guestCounter++;
                return (
                    <GuestItem 
                        key={label}
                        label={label}
                        name="NA"
                    />
                )
            }

            return acc.customerID.map((customerID, custIndx) =>{
                const customerData = guests.find(guest => guest._id == customerID);
                const label = (accIndx == 0 && custIndx == 0 && acc.guestNumber == 1) ? "Primary booker" : `Guest ${guestCounter}`;
                const isPrimaryGuest = label == "Primary booker";
                const customerName = toTitleCase(`${customerData?.firstName} ${customerData?.lastName}`);
                guestCounter++;
                return (
                    <GuestItem 
                        key={`guest_${customerID}`}
                        label={label}
                        name={customerName}
                        onEditHandler={() =>setSelectedGuest({ id: customerData._id, isPrimaryGuest })}
                    />
                )
            })
        });

        return customerList.flat();
    }

    const priceEditHandler = guestNumber =>{
        setSelectedGuestNumber(guestNumber);
    }

    const closeModalHandler = () =>{
        setSelectedGuestNumber(null);
        setSelectedGuest({ id: null });
    }

    return (
        <div className={classes.root}>

            {/* -----------------HEADER---------------------------- */}
            <div className={classes.header}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={3} lg={2}>
                        <h4>Booking details</h4>
                    </Grid>

                    <Grid item>
                        <div className={classes.iconInfo}>
                            <div className={classes.iconContainer}>
                                <img className={classes.icon} src={IMAGES.ICONS.user}/>
                            </div>
                            <p>{reservation.guestCount} guests</p>
                        </div>
                    </Grid>

                    <Grid item>
                        <div className={classes.iconInfo}>
                            <div className={clsx(classes.iconContainer, 'icon2')}>
                                <img className={classes.icon} src={IMAGES.ICONS.nights}/>
                            </div>
                            <p>{nights} nights</p>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4} lg={3}>
                        <div className={classes.iconInfo}>
                            <div className={clsx(classes.iconContainer, 'icon3')}>
                                <img className={classes.icon} src={IMAGES.ICONS.calendar}/>
                            </div>
                            <p>{reservationDuration}</p>
                        </div>
                    </Grid>
                </Grid>
            </div>

            {/* ---------------------GUEST AND ROOM DEATILS--------------------- */}
            {Object.keys(guestAccomodation).map((guestNumber) =>{
                const accomodationData = guestAccomodation?.[guestNumber] || [];
                return (
                    <div className={classes.listConatiner} key={`acc_${guestNumber}`}>
                        <HorizontalScollView>
                            <div className={classes.guestList}>
                                {getCutomerList(accomodationData)}
                            </div>
                        </HorizontalScollView>

                        <RoomInfo 
                            guestNumber={guestNumber}
                            data={accomodationData} 
                            priceEditHandler={priceEditHandler}
                            allowDelete={accommodationCount > 1}
                        />
                    </div>
                )
            })}

            {selectedGuestNumber && (
                <EditPrice 
                    guestNumber={selectedGuestNumber} 
                    closeModalHandler={closeModalHandler}
                    guestAccomodation={guestAccomodation}
                />
            )}

            {selectedGuest.id && (
                <CustomerDetails
                    guestID={selectedGuest.id}
                    isPrimaryGuest={selectedGuest?.isPrimaryGuest}
                    closeModalHandler={closeModalHandler}
                />
            )}
        </div>
    )
}

const mapStateToProps = state =>{
    const { reservationDetails } = state;
    return {
        reservation: reservationDetails?.reservation || {},
    }
}

export default connect(mapStateToProps)(BookingDetails);