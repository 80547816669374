import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Dialog from "../common/CustomDialog";
import Footer from "./Footer";
import LineItems from "./LineItems";
import { fetchCurrencyFormat } from "../../../utils/utility";
import { updateReservationItems } from "../../../redux/actions/reservations";
import { UPSELL_ITEMS } from "../../../common/constants/addOns";

const INIT_SUMMARY_INFO = {
  amount: 0,
  count: 0,
};

const getSelectedItemsSummary = (selectedItems) => {
  let summary = { ...INIT_SUMMARY_INFO };

  for (const [, itemData] of Object.entries(selectedItems)) {
    summary.count += itemData.count;
    summary.amount += itemData.amount;
  }
  return summary;
};

const getItemImage = (iconID) => {
  let itemData = UPSELL_ITEMS.find((item) => item.iconID === iconID) || {};
  return itemData.imgSrc;
};

const useStyles = makeStyles((theme) => ({
  root: {},
  noDataConatiner: {
    border: "1px solid #e0e0e0",
    padding: "20px",
    borderRadius: "10px",
    fontSize: "1.4rem",
    color: "#999999",
    marginBottom: "20px",
    lineHeight: "18px",

    "& .title": {
      color: "#777777",
      fontSize: "1.2rem",
      fontWeight: 600,
      margin: "0px",
    },
  },
}));

const AddonsModal = (props) => {
  const classes = useStyles();
  const {
    closeModalHandler,
    upsellItems,
    currency,
    dispatch,
    reservation,
    isLoading,
  } = props;
  const [selectedItems, setSelectedItems] = useState({});
  const [summaryInfo, setSummaryInfo] = useState({ ...INIT_SUMMARY_INFO });
  const upsellItemIDs = Object.keys(upsellItems);

  const onCountChange = (itemID, count) => {
    let selected = { ...selectedItems };
    if (!selected[itemID]) {
      selected[itemID] = {
        upsellID: itemID,
      };
    }
    selected[itemID].count = count;
    selected[itemID].amount = (upsellItems[itemID].rate || 0) * count;
    if (count === 0 && selected[itemID]) delete selected[itemID];
    setSelectedItems({ ...selected });

    const summaryData = getSelectedItemsSummary(selected);
    setSummaryInfo({ ...summaryData });
  };

  const getSelectedList = (selectedItems) => {
    return Object.keys(selectedItems).map((id) => selectedItems[id]);
  };

  const handleSubmit = () => {
    const payload = getSelectedList(selectedItems);
    dispatch(updateReservationItems(reservation._id, payload)).then(
      (success) => {
        if (success) closeModalHandler();
      }
    );
  };

  return (
    <Dialog
      headerTitle="Add ons"
      closeModalHandler={closeModalHandler}
      isLoading={isLoading}
      // errors={errors}
      // handleCloseError={this.handleCloseError}
    >
      {upsellItemIDs.map((itemID) => {
        const itemData = selectedItems?.[itemID] || null;
        return (
          <LineItems
            key={`addOn_${itemID}`}
            itemID={itemID}
            name={upsellItems[itemID].name}
            amount={upsellItems[itemID].rate}
            imageSrc={getItemImage(upsellItems[itemID].iconID)}
            currency={currency}
            onCountChange={onCountChange}
            quantity={itemData?.count || 0}
          />
        );
      })}

      {upsellItemIDs.length == 0 && (
        <div className={classes.noDataConatiner}>
          <p className="title">No addon items found.</p>
        </div>
      )}

      <Footer
        label="Save"
        onBtnClick={handleSubmit}
        count={summaryInfo.count}
        amount={summaryInfo.amount}
        currency={currency}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { property, register, loading, reservationDetails } = state;

  const propertyID = register.propertyID;
  const propertyInfo = property?.property?.[propertyID] || {};
  const upsellItems = {};

  if (property?.upsellItems) {
    for (const [id, item] of Object.entries(property?.upsellItems)) {
      if (item.isActive) upsellItems[id] = item;
    }
  }

  return {
    upsellItems,
    currency: fetchCurrencyFormat(propertyInfo.currency) || "",
    reservation: reservationDetails.reservation || {},
    isLoading: loading.UPDATE_RESERVATION_ITEMS,
  };
};

export default connect(mapStateToProps)(AddonsModal);
