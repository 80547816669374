import { BED_STATUSES, RESERVATION_STATUS } from "../../utils/constants";

export const BED_STATUS_CONFIG = [
  //CHECKEDIN BLOCK CASES..................................
  {
    blockOneStatus: RESERVATION_STATUS.IN_HOUSE,
    blockTwoStatus: RESERVATION_STATUS.IN_HOUSE,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.IN_HOUSE,
    blockTwoStatus: RESERVATION_STATUS.CHECKED_OUT,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.IN_HOUSE,
    blockTwoStatus: 'empty',
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.IN_HOUSE,
    blockTwoStatus: 'mappingChange',
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.IN_HOUSE,
    blockTwoStatus: RESERVATION_STATUS.BLOCK,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.IN_HOUSE,
    blockTwoStatus: RESERVATION_STATUS.CONFIRMED,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },

  //CHECKEDOUT BLOCK CASES..................................
  {
    blockOneStatus: RESERVATION_STATUS.CHECKED_OUT,
    blockTwoStatus: RESERVATION_STATUS.IN_HOUSE,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.CHECKED_OUT,
    blockTwoStatus: RESERVATION_STATUS.CHECKED_OUT,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.CHECKED_OUT,
    blockTwoStatus: 'empty',
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.CHECKED_OUT,
    blockTwoStatus: 'mappingChange',
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.CHECKED_OUT,
    blockTwoStatus: RESERVATION_STATUS.BLOCK,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.CHECKED_OUT,
    blockTwoStatus: RESERVATION_STATUS.CONFIRMED,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },

  //EMPTY BLOCK CASES.................................
  {
    blockOneStatus: 'empty',
    blockTwoStatus: RESERVATION_STATUS.IN_HOUSE,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: 'empty',
    blockTwoStatus: RESERVATION_STATUS.CHECKED_OUT,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },
  {
    blockOneStatus: 'empty',
    blockTwoStatus: 'empty',
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },
  {
    blockOneStatus: 'empty',
    blockTwoStatus: 'mappingChange',
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: 'empty',
    blockTwoStatus: RESERVATION_STATUS.BLOCK,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: 'empty',
    blockTwoStatus: RESERVATION_STATUS.CONFIRMED,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },

  //MAPPING CHANGE BLOCK CASES..................................
  {
    blockOneStatus: 'mappingChange',
    blockTwoStatus: RESERVATION_STATUS.IN_HOUSE,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: 'mappingChange',
    blockTwoStatus: RESERVATION_STATUS.CHECKED_OUT,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },
  {
    blockOneStatus: 'mappingChange',
    blockTwoStatus: 'empty',
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },
  {
    blockOneStatus: 'mappingChange',
    blockTwoStatus: 'mappingChange',
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: 'mappingChange',
    blockTwoStatus: RESERVATION_STATUS.BLOCK,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: 'mappingChange',
    blockTwoStatus: RESERVATION_STATUS.CONFIRMED,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },

  //ROOM_BLOCK BLOCK CASES..................................
  {
    blockOneStatus: RESERVATION_STATUS.BLOCK,
    blockTwoStatus: RESERVATION_STATUS.IN_HOUSE,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.BLOCK,
    blockTwoStatus: RESERVATION_STATUS.CHECKED_OUT,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.BLOCK,
    blockTwoStatus: 'empty',
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.BLOCK,
    blockTwoStatus: 'mappingChange',
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.BLOCK,
    blockTwoStatus: RESERVATION_STATUS.BLOCK,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.BLOCK,
    blockTwoStatus: RESERVATION_STATUS.CONFIRMED,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },

  //CONFIRMED AND ONLINE CHECKED_IN BLOCK CASES..................................
  {
    blockOneStatus: RESERVATION_STATUS.CONFIRMED,
    blockTwoStatus: RESERVATION_STATUS.IN_HOUSE,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.CONFIRMED,
    blockTwoStatus: RESERVATION_STATUS.CHECKED_OUT,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.CONFIRMED,
    blockTwoStatus: 'empty',
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.CONFIRMED,
    blockTwoStatus: 'mappingChange',
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.CONFIRMED,
    blockTwoStatus: RESERVATION_STATUS.BLOCK,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.occupied ]
  },
  {
    blockOneStatus: RESERVATION_STATUS.CONFIRMED,
    blockTwoStatus: RESERVATION_STATUS.CONFIRMED,
    statuses: [ BED_STATUSES.unclean, BED_STATUSES.ready ]
  },
];