// Reports Reducer
const reportsReducerDefaultState = {
  reportName: '',
  sortBy: '',
  guestStatus: '',
  roomType: '',
  arrivalDate: '',
  departureDate: ''
}
export default (state = reportsReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_REPORT_NAME':
      return { ...state, reportName: action.reportName }
    case 'SET_SORT_BY':
      return { ...state, sortBy: action.sortBy }
    case 'SET_GUEST_STATUS':
      return { ...state, guestStatus: action.guestStatus }
    case 'SET_ROOM_TYPE':
      return { ...state, roomType: action.roomType }
    case 'SET_ARRIVAL_DATE':
      return { ...state, arrivalDate: action.arrivalDate }
    case 'SET_DEPARTURE_DATE':
      return { ...state, departureDate: action.departureDate }
    default: return state;
  }
}