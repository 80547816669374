export default (state = {}, action) => {
    switch (action.type) {
        case "LOAD_RESERVATION_SUCCESS":
            return {
                ...state,
                ...action.payload
            };

        case "LOAD_ACTIVITY_LOG_SUCCESS":
            return {
                ...state,
                activityLog: [ ...action.payload ]
            }

        case "LOAD_RESERVATION_TRANSACTIONS_SUCCESS":
            return {
                ...state,
                transactions: [ ...action.payload ]
            }
        
        case "UPDATE_BASIC_RESERVATION_DATA_SUCCESS":
        case "UPDATE_RESERVATION_DATA_SUCCESS":
        case "UPDATE_RESERVATION_STATUS_SUCCESS":
        case "DELETE_CUSTOMER_SUCCESS":
        case "ADD_ASSIGN_GUEST_ACCOMMODATION_SUCCESS":
        case "UPDATE_RESERVATION_ITEMS_SUCCESS":
        case "CREATE_RESERVATION_TAX_SUCCESS":
        case "DELETE_RESERVATION_TAX_SUCCESS":
            const reservationData = state.reservation;
            const updatedReservationData = { ...action.payload }
            return {
                ...state,
                reservation: {
                    ...reservationData,
                    ...updatedReservationData
                }
            }

        case "UPDATE_CUSTOMER_SUCCESS":
            const payloadGuestData = action.payload.guestData;

            let guestsInfo = state.reservation.guests || [];
            guestsInfo = guestsInfo.map(guest => guest._id === payloadGuestData._id ? { ...payloadGuestData } : guest);

            const reservationInfo = { ...state.reservation };

            if(action.payload.isPrimaryGuest){
                reservationInfo.guestName =  (`${payloadGuestData?.firstName} ${payloadGuestData?.lastName}`).trim() || ''; payloadGuestData?.guestName || '';
                reservationInfo.guestEmail =  payloadGuestData?.email || '';
            }

            return {
                ...state,
                reservation: {
                    ...reservationInfo,
                    guests: guestsInfo
                }
            }

        case "UPLOAD_CUSTOMER_DOC_SUCCESS":
            let resGuests = state.reservation.guests || [];
            resGuests = resGuests.map(guest => guest._id === action.payload._id ? { ...action.payload } : guest);
            return {
                ...state,
                reservation: {
                    ...state.reservation,
                    guests: resGuests
                }
            }

        case "CHANNEX_CAPTURE_CARD_SUCCESS":
            let channexCardTokens = state.reservation.channexCardTokens || [];
            return {
                ...state,
                maskedCards: [ ...state.maskedCards, action.payload ],
                reservation: {
                    ...state.reservation,
                    channexCardTokens: [ 
                        ...channexCardTokens,
                        action.payload.card_token
                    ]
                }
            }
        case "CREATE_RESERVATION_NOTE_SUCCESS":
            return { 
                ...state, 
                reservation: {
                    ...state.reservation,
                    notes: action.payload.notes
                }
            }
        
        case "CREATE_RESERVATION_TAG_SUCCESS":
            const tagID = action.payload._id;
            const resData = state.reservation;
            return {
                ...state,
                reservation: {
                    ...state.reservation,
                    tags: [ ...resData.tags, tagID ]
                }
            }

        case "GET_CONFIG_CONTENTS_SUCCESS":
            const { channelList } = action.payload;
            return {
                ...state,
                channelList
            }

        case "SEND_EMAIL_SUCCESS":
            return {
                ...state,
                emails: [ ...action.payload ]
            }

        case "TRANSACTION_REFUND_SUCCESS":
            return{
                ...state,
                reservation: {
                    ...state.reservation,
                    balance: action.payload.balance
                },
                transactions: [
                    ...state.transactions,
                    action.payload.transaction
                ]
            }

        case "CREATE_ORDER_SUCCESS":
        case "DELETE_ORDER_SUCCESS":
            return {
                ...state,
                reservation: {
                    ...state.reservation,
                    balance: action.payload.balance, 
                    taxAmount: action.payload.taxAmount, 
                    totalAmount: action.payload.totalAmount
                },
                orders: [ ...action.payload.orders ]
            }

        case "RESET_RESERVATION": 
            return {}

        default:
            return state;
    }
}