import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>({
    root: {
        display: 'flex',
        overflowX: "auto",
        padding: "10px 0px",
    },
    flexColumn: {
        flexDirection: 'column'
    }
}));

const HorizontalScollView = props =>{
    const classes = useStyles();
    const { flexColumn = false } = props;
    return (
        <div className={clsx(classes.root, 'hideScrollBar', { [classes.flexColumn]: flexColumn })}>
            {props.children}
        </div>
    )
}

export default HorizontalScollView;