import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import Header from '../booking_engine/header';
import { setMode } from '../../redux/actions/kiosk';

const styles = theme =>({
    main: {
        background: '#F8F9FB',
    },
    root: {
        background: '#F8F9FB',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
    },
    link:{
        marginTop: '30px',
        cursor: 'pointer',
        '&:hover':{
            textDecoration: 'underline',
        }
    },
    button: {
        alignItems: 'center',
        background: '#000000',
        borderRadius: 10,
        boxShadow: '0 3px 4px 0 #ECECEC',
        color: '#ffffff',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '2.5rem',
        fontWeight: 600,
        justifyContent: 'center',
        marginBottom: 40,
        height: 200,
        width: 400,
    },
    long: {
        background: '#FFFFFF',
        border: '1px solid #000000',
        color: '#333333',
        height: 70,
        fontSize: '1.5rem',
    }
});

class KioskLandinPage extends Component{
    navigateToBooking = () =>{
        const { navigateTo, dispatch } = this.props;
        dispatch(setMode(1));
        navigateTo('bookingEngine');
    }
    
    navigateToCheckin = () =>{
        const { navigateTo, dispatch } = this.props;
        dispatch(setMode(2));
        navigateTo('checkin');
    }

    render(){
        const { classes, propertyDetails, settings, i18n, t } = this.props;
        const propertyImage = settings && settings.propertyLogo ? settings.propertyLogo : '';
        return(
            <div className={classes.main}>
                <Header 
                    title={propertyDetails.name}
                    currency={propertyDetails.currency}
                    image = {propertyImage}
                    i18n = {i18n}
                    hideCurrency={true}
                />
                <div className={classes.root}>
                    <div className={classes.button} onClick={this.navigateToCheckin}>{t('kiosk.checkin')}</div>
                    <div className={clsx(classes.button, classes.long)} onClick={this.navigateToBooking}>{t('kiosk.noBooking')}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state =>{
    const { bookingEngine, onlineCheckin } = state;
    const propertyDetails = bookingEngine.propertyDetails ? bookingEngine.propertyDetails: {};
    return{
        propertyDetails,
        settings: onlineCheckin.settings
    }
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(KioskLandinPage)));