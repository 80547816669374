import React from 'react';
import { InputBase, TextField } from '@material-ui/core';

const NumberInput = (props) => {
  const { variantType, id, name, value, min, max, disabled, startAdornment, endAdornment, className, ...inputProps } = props;

  const onInputChange = (e) => {
    let input = e.target.value;
    if(min === 0)
      input = processInput(input)
    else if (input != '-')
      input = processInput(input)

    props.onChange({ target: { value: input } })
  }

  const processInput = (input) => {
    if(input.length === 2 && input.charAt(0) === "0" && input.charAt(1) !== "." ){
      input = parseInt(input);
    }
    while (isNaN(input)) {
      input = input
        .split('')
        .reverse()
        .join('')
        .replace(/[\D]/i, '')
        .split('')
        .reverse()
        .join('');
      // input = parseFloat(input)
    }
    return input
  }

  if(variantType == "base"){
    return (
      <InputBase
        { ...inputProps }
        id={id || name}
        name={name || id}
        value={value}
        disabled={disabled}
        fullWidth
        type="text"
        onChange={(e) => onInputChange(e)}
        startAdornment={startAdornment}
        endAdornment={endAdornment}
        className={className}
      />
    )
  }

  return (
    <TextField
      { ...props }
      id={id || name}
      name={name || id}
      value={value}
      disabled={disabled}
      fullWidth
      type="text"
      onChange={(e) => onInputChange(e)}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      className={className}
    />
  )
}

export default NumberInput;