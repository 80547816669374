import { schema } from "normalizr";

// Define user schema
const accessToProperties = new schema.Entity("accessToProperties", {}, {
  idAttribute: "propertyID"
});

const userSchema = new schema.Entity("user", {
  accessToProperties: [accessToProperties]
}, { idAttribute: "_id" });

const cardSchema = new schema.Entity("cards", {}, {
  idAttribute: "_id"
});

const customerSchema = new schema.Entity("customer", {cards: [cardSchema]}, {
  idAttribute: "_id"
});

export { userSchema, customerSchema, cardSchema };