import http from "../utils/http";
import axios from "axios";
import { normalize } from "normalizr";
import { customerSchema, cardSchema } from "../schema/user";

//----------------------------------------------------------------
// LOAD CUSTOMER DETAILS
//----------------------------------------------------------------
export function loadCustomer(id, type) {
  const ACTION_NAME = "LOAD_"+type+"_CUSTOMER";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/customer/" + id)
      .then(successResult => {
        let normalizedData = normalize(successResult.data, customerSchema);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities,
          _id: id 
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// UPDATE CUSTOMER DETAILS
//----------------------------------------------------------------
export function updateCustomer(id, payload, isPrimaryGuest = false) {
  const ACTION_NAME = "UPDATE_CUSTOMER";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/customer/" + id, payload)
      .then(successResult => {
        const data = successResult.data;
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: {
            guestData: data,
            isPrimaryGuest
          }
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  };
}

//----------------------------------------------------------------
// CREATE CUSTOMER DETAILS
//----------------------------------------------------------------
export function createCustomer(id, payload) {
  const ACTION_NAME = "CREATE_CUSTOMER";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/resvcustomer/" + id, payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// UPLOAD CUSTOMER DOC
//----------------------------------------------------------------
// export function uploadCustomerDoc(customerID, payload) {
//   const ACTION_NAME = "UPLOAD_CUSTOMER_DOC";
//   const type = payload.uploadType;
//   return function (dispatch) {
//     dispatch({ type: `${ACTION_NAME}_REQUEST` });
//     return http
//       .post(`/api/customer/uploadURL/${customerID}/${type}`, {
//         name: payload.name+'_time_'+new Date().getTime(),
//         type: payload.type
//       })
//       .then(successResult => {
//         const docInfo = successResult.data;
//         const key = docInfo.key;
//         const url = docInfo.url;
//         const options = {
//           headers: {
//             "Content-Type": payload.type === 'pdf'? 'application/pdf': payload.type
//           }
//         };
//         axios
//           .put(url, payload.file, options)
//           .then(result => {
//             http
//               .post(`/api/customer/saveURL/${customerID}/${type}`, {
//                 key: key
//               })
//               .then(response => {
//                 let normalizedData = normalize(response.data, customerSchema);
//                 dispatch({
//                   type: `${ACTION_NAME}_SUCCESS`,
//                   payload: normalizedData.entities,
//                   _id: customerID 
//                 });
//               })
//               .catch(error => {
//                 return error;
//               });
//           })
//           .catch(error => {
//             return error;
//           });
//       })
//       .catch(errorResult => {
//         let error =
//           errorResult && errorResult.response && errorResult.response.data
//             ? errorResult.response.data
//             : errorResult;
//         dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
//       });
//   };
// }

//----------------------------------------------------------------
// UPLOAD CUSTOMER DOC
//----------------------------------------------------------------
export function uploadCustomerDoc(uploadType, payload) {
  const ACTION_NAME = "UPLOAD_CUSTOMER_DOC";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/customer/uploadURL/${payload.customerID}/${uploadType}`, {
        propertyID: payload.propID,
        customerID: payload.customerID,
        name: payload.name+'_time_'+new Date().getTime(),
        type: payload.type
      })
      .then(successResult => {
        const docInfo = successResult.data;
        const key = docInfo.key;
        const url = docInfo.url;
        const options = {
          headers: {
            "Content-Type": (payload.type === 'pdf' ? 'application/pdf': payload.type)
          }
        };
        axios.put(url, payload.file, options)
          .then(result => {
            http.post(`/api/customer/saveURL/${payload.customerID}/${uploadType}`, {
              propertyID: payload.propID,
              customerID: payload.customerID,
              key: key
            }).then(response => {
              dispatch({
                type: `${ACTION_NAME}_SUCCESS`,
                payload: response.data,
                _id: payload.customerID
              });
            }).catch(error => {
              return error;
            });
          })
          .catch(error => {
            return error;
          });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD CUSTOMER CARD DETAILS
//----------------------------------------------------------------
export function loadCustomerCard(id) {
  const ACTION_NAME = "LOAD_CUSTOMER_CARD";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/cards/" + id)
      .then(successResult => {
        let normalizedData = normalize(successResult.data, [cardSchema]);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities.cards,
          cardIds: normalizedData.result
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// DELETE CUSTOMER
//----------------------------------------------------------------
export function deleteCustomer(reservationID, customerID) {
  const ACTION_NAME = "DELETE_CUSTOMER";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.delete(`/api/customer/${reservationID}/${customerID}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: data
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return false;
      });
  }
}