import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const AddBedInput = ({
    id = null,
    value = 0,
    maxValue,
    minValue = 0,
    onChangeHandler,
    renderHandler
}) =>{
    const [bedCount, setBedCount] = useState(value);
    useEffect(()=>{
        if(id) setBedCount(value);
    },[value, bedCount]);

    const addBed = () =>{
        if(bedCount >= maxValue) return;
        const updatedCount = bedCount + 1;
        onChangeHandler({ id, value: updatedCount });
        setBedCount(updatedCount);
    }
    const removeBed = () =>{
        if( bedCount > minValue ){
            const updatedCount = bedCount - 1;
            onChangeHandler({ id, value: updatedCount });
            setBedCount(updatedCount);
        }
    }

    return(
        <>{renderHandler(addBed, removeBed, bedCount, id)}</>
    )
};

AddBedInput.propTypes = {
    maxValue: PropTypes.number,
    onChangeHandler: PropTypes.func.isRequired
};

export default AddBedInput;

