const LOG = {
    CREATE_RESERVATION: {
        CODE: "CREATE_RESERVATION",
        TEXT: `Reservation created by {user}`
    },
    ROOM_CHANGE: {
        CODE: "ROOM_CHANGE",
        TEXT: `Room changed from {from} to {to} by {user}`
    },
    CHANGE_ARRIVAL_DATE: {
        CODE: "CHANGE_ARRIVAL_DATE",
        TEXT: `Arrival Date changed from {from} to {to} by {user}`
    },
    // CHANGE_ROOM_AND_ARRIVAL_DATE: {
    //     CODE: "CHANGE_ROOM_AND_ARRIVAL_DATE",
    //     TEXT: `Room and Checkin changed from {from} to {to} by {user}`
    // },
    EXTEND: {
        CODE: "EXTEND",
        TEXT: `Reservation extended from {from} to {to} by {user}`
    },
    CHANGE_DEPARTURE: {
        CODE: "CHANGE_DEPARTURE",
        TEXT: `Guest departure changed from {from} to {to} by {user}`
    },
    SHORTEN: {
        CODE: "SHORTEN",
        TEXT: `Reservation shortened from {from} to {to} by {user}`
    },
    STATUS: {
        CODE: "STATUS",
        TEXT: `Reservation status changed from {from} to {to} by {user}`
    },
    CANCEL: {
        CODE: "CANCEL",
        TEXT: `Reservation marked cancelled with reason "{note}" by {user}`
    },
    TRANSACTION: {
        CODE: "TRANSACTION",
        TEXT: `{mode_of_transaction} Payment added for {currency} {amount} by {user}`
    },
    REFUND: {
        CODE: "REFUND",
        TEXT: `{mode_of_transaction} refund added for {currency} {amount} by {user}`
    },
    VOID_TRANSACTION: {
        CODE: "VOID_TRANSACTION",
        TEXT: `Discount for {currency} {amount} voided by {user}`
    },
    CARD_ON_FILE: {
        CODE: "CARD_ON_FILE",
        TEXT: `{mode_of_transaction} charged for {currency} {amount} by {user}`
    },
    STRIPE: {
        CODE: "STRIPE",
        TEXT: `{mode_of_transaction} charged for {currency} {amount} by {user}`
    },
    STRIPE_REFUND: {
        CODE: "STRIPE_REFUND",
        TEXT: `{mode_of_transaction} refunded for {currency} {amount} by {user}`
    },
    DISCOUNT: {
        CODE: "DISCOUNT",
        TEXT: `{currency} {amount} discount added by {user}`
    },
    SURCHARGE: {
        CODE: "SURCHARGE",
        TEXT: `{currency} {amount} surcharge added by {user}`
    },
    INVOICE: {
        CODE: "INVOICE",
        TEXT: `Invoice generated by {user}`
    },
    EMAIL: {
        CODE: "EMAIL",
        TEXT: `Reservation email "{note}" sent by {user}`
    },
    CREATE_CUSTOMER: {
        CODE: "CREATE_CUSTOMER",
        TEXT: `Customer {name} created by {user}`
    },
    UPDATE_CUSTOMER: {
        CODE: "UPDATE_CUSTOMER",
        TEXT: ``
    },
    UPLOAD_CUSTOMER_DOCUMENT: {
        CODE: "UPLOAD_CUSTOMER_DOCUMENT",
        TEXT: ``
    },
    MANUAL_TAX: {
        CODE: "MANUAL_TAX",
        TEXT: "Reservation Tax {name} added by {user}"
    },
    TAX_UPDATE: {
        CODE: "TAX_UPDATE",
        TEXT: "Reservation Tax {name} updated by {user}"
    },
    TAX_DELETE: {
        CODE: "TAX_DELETE",
        TEXT: `Reservation Tax {name} deleted by {user}`
    },
    CREATE_NOTE: {
        CODE: "CREATE_NOTE",
        TEXT: `Reservation note created "{note}" by {user}`
    },
    DELETE_NOTE: {
        CODE: "DELETE_NOTE",
        TEXT: `Reservation note "{note}", deleted by {user}`
    },
    CHANGE_RATE: {
        CODE: "CHANGE_RATE",
        TEXT: `Reservation rate changed from {currency} {from} to {currency} {to} by {user}`
    },
    CHANGE_BED: {
        CODE: "CHANGE_BED",
        TEXT: `Reservation moved from {from} to {to} by {user}`
    },
    INTELRES_MOVE: {
        CODE: "INTELRES_MOVE",
        TEXT: `Reservation moved by System`
    },
    OTA_MODIFY: {
        CODE: "OTA_MODIFY",
        TEXT: `Reservation modified by System`
    },
    EDIT_BLOCK: {
        CODE: "EDIT_BLOCK",
        TEXT: `Room Block modified by {user}`
    },
    LOCK_RESERVATION: {
        CODE: "LOCK_RESERVATION",
        TEXT: `Reservation locked by {user}`
    },
    ADD_UPSELL_ITEM: {
        CODE: "ADD_UPSELL_ITEM",
        // TEXT: `{count} {name} added to reservation by {user}`,
        TEXT: `Following items added to reservation by {user}`,
        LOOP_TEXT: `{count} - {name}`,
    },
    REMOVE_UPSELL_ITEM: {
        CODE: "REMOVE_UPSELL_ITEM",
        // TEXT: `{count} {name} removed from reservation by {user}`,
        TEXT: `Following items removed from reservation by {user}`,
        LOOP_TEXT: `{count} - {name}`,
    },
    ADD_POS_ITEM: {
        CODE: "ADD_POS_ITEM",
        TEXT: `Following items added to reservation by {user}`,
        LOOP_TEXT: `{count} - {name}`,
    },
    REMOVE_POS_ITEM: {
        CODE: "REMOVE_POS_ITEM",
        // TEXT: `{count} {name} removed from reservation by {user}`,
        TEXT: `Following items removed from reservation by {user}`,
        LOOP_TEXT: `{count} - {name}`,
    },
    DELETE_GUEST: {
        CODE: "DELETE_GUEST",
        TEXT: `Guest removed from reservation by {user}`,
    },
    CHANGE_BED_MULTIPLE: {
        CODE: "CHANGE_BED_MULTIPLE",
        TEXT: `Reservation moved by {user}`,
        LOOP_TEXT: `from {initialBed} with rate {currency} {initialAmount} to {finalBed} with rate {currency} {finalAmount} for {dateString}`
    },
    CHANGE_RATE_MULTIPLE: {
        CODE: "CHANGE_RATE_MULTIPLE",
        TEXT: `Reservation rate change by {user}`,
        LOOP_TEXT: `from rate {currency} {initialAmount} to rate {currency} {finalAmount} for {dateString}`
    },
    CHANGE_ACCOMMODATION_ARRIVAL: {
        CODE: "CHANGE_ACCOMMODATION_ARRIVAL",
        TEXT: `Reservation moved by {user}`,
        LOOP_TEXT: `check-in from {initialCheckIn} to {finalCheckIn} and checkout from {initialCheckOut} to {finalCheckOut}`
    },
    CHANGE_ROOM_AND_ARRIVAL_DATE: {
        CODE: "CHANGE_ROOM_AND_ARRIVAL_DATE",
        TEXT: `Reservation updated by {user}`
    },
    UNLOCK_RESERVATION: {
        CODE: "UNLOCK_RESERVATION",
        TEXT: `Reservation unlocked by {user}`
    },
    ADD_BED_GUEST: {
        CODE: "ADD_BED_GUEST",
        TEXT: "A new bed added to reservation",
        LOOP_TEXT: "{name} with rate {currency} {initialAmount} for {date}"
    },
    CREATE_BOOKING: {
        CODE: 'CREATE_BOOKING',
        TEXT: 'Booked experience for {name} with {count} participants'
    },
    UPDATE_BOOKING: {
        CODE: 'UPDATE_BOOKING',
        TEXT: 'Updated experience for {name} with {count} participants'
    },
    UPDATE_BOOKING_RATE: {
        CODE: 'UPDATE_BOOKING_RATE',
        TEXT: 'Updated experience rate from {currency} {from} to {currency} {to}'
    },
    DELETE_BOOKING: {
        CODE: 'DELETE_BOOKING',
        TEXT: 'Removed a participant from experience booking {name}'
    },
    CHANGE_BOOKING_STATUS: {
        CODE: 'CHANGE_BOOKING_STATUS',
        TEXT: 'Marked a participant as {note} from experience {name}'
    },
    ONLINE_CHECK_IN: {
        CODE: 'ONLINE_CHECK_IN',
        TEXT: 'Guest has checked in online'
    },
    KIOSK_CHECK_IN: {
        CODE: 'KIOSK_CHECK_IN',
        TEXT: 'Guest has checked in using kiosk'
    },
    RESERVATION_UN_ALLOCATE_ON_ROOM_DELETE: {
        CODE: 'RESERVATION_UN_ALLOCATE_ON_ROOM_DELETE',
        TEXT: 'Reservation unallocated on room delete',
        LOOP_TEXT: 'Room changed from {initialBed} to {finalBed}'
    }
}

module.exports = { LOG }