import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";

import PersonalInfoCard from '../../online_checkin/personal_information/PersonalInfoCard';
import RoomInfoCard from '../../online_checkin/personal_information/RoomInfoCard';
import AddGuest from '../../online_checkin/personal_information/AddGuest';
import Footer from '../../online_checkin/Footer';

import { fetchDateFormat } from '../../../utils/utility';
import { withTranslation } from 'react-i18next';

const styles = theme =>({
    root: {
        // border: '1px solid'
    },
    bookingInfo: {
        padding: '20px',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 0px',
        }
    },
    imageContainer: {
        height: '100px',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            height: '120px',
        },
    },
    roomImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        borderRadius: '5px',
    },
    personalInfoSection:{
        marginTop: '20px',
        [theme.breakpoints.up("sm")]: {
            width: '80%',
            margin: '20px auto',
        },
    },
    headerText:{
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '20px'
    },
});

class PersonalInformation extends Component{
    formatReservationDates = (checkin, checkout) =>{
        const startDate = fetchDateFormat(checkin, 'ddd, MMM D');
        const endDate = fetchDateFormat(checkout, 'ddd, MMM D YYYY');
        return `${startDate} - ${endDate}`;
    }

    addGuest = data =>{
        const { dispatch, accommodationList, guests } = this.props;

        //Add new guest.....
        const accIndex = accommodationList.findIndex(acc => acc._id == data.accommodationID);
        const newCustomerID = `CUST_${data.accommodationID}_${accommodationList[accIndex].customerID.length + 1 }`;
        const customerIdArray = [ ...accommodationList[accIndex].customerID, newCustomerID ];
        let newAccommodationList = [...accommodationList];
        newAccommodationList[accIndex].customerID = [...customerIdArray];
        console.log('data.accommodationID ',data.accommodationID);
        console.log('accIndex ',accIndex);
        console.log('accommodationList ',accommodationList);
        console.log('newAccommodationList ',newAccommodationList);
        const newGuest = {
            [newCustomerID]: {
                address: { ...data.formData.address },
                  _id: newCustomerID,
                  firstName: data.formData.firstName,
                  lastName: data.formData.lastName,
                  email: data.formData.email,
                  phone: data.formData.phone,
                  documents: []
            }
        }
        dispatch({ 
            type: `ADD_NEW_GUEST_SUCCESS`, 
            payload: {
                accommodationList: newAccommodationList,
                guests: { ...guests, ...newGuest }
            }
        });
        data.toggleMode();
    }

    updateGuestDetails = data =>{
        const { dispatch } = this.props;
        dispatch({ 
            type: `UPDATE_GUEST_DETAILS_SUCCESS`, 
            payload: {
                guestID: data.guestID,
                guestData: {
                    firstName: data.formData.firstName,
                    lastName: data.formData.lastName,
                    email: data.formData.email,
                    phone: data.formData.phone,
                    address: { ...data.formData.address },
                }
            }
        });
        data.toggleMode();
    }

    onProceed = () =>{
        this.props.nextStep();
    }

    fetchAccommodationMenuList = (accommodationID) =>{
        const { accommodationList, roomTypes } = this.props;
        const list = accommodationList.filter(data => data._id != accommodationID);

        const menuList = list.map(data =>{
            return{
                title: `Move To ${roomTypes[data.roomTypeID].name}`,
                id: data._id
            }
        });

        return menuList;
    }   

    updateAccommodation = data =>{
        const { accommodationList, dispatch } = this.props;
        const tempData = [ ...accommodationList ];
        const accID = Object.keys(data)[0];
        const custID = data[accID][0];;

        const currentAccIndex = tempData.findIndex(acc => acc._id == accID);
        const prevAccIndex = tempData.findIndex(acc => acc.customerID.includes(custID));
        const custArray = tempData[prevAccIndex].customerID.filter(id => id != custID );

        tempData[prevAccIndex].customerID = [...custArray];
        tempData[currentAccIndex].customerID = [...tempData[currentAccIndex].customerID, custID];
       
        dispatch({ 
            type: `UPDATE_CUSTOMER_ACCOMODATION_SUCCESS`, 
            payload: {
                accommodationList: [...tempData],
            }
        });
    }

    render(){
        const { 
            classes, 
            accommodationList, 
            guests, 
            roomTypes,
            t,
            propertyDetails = { 
                address: {},
                images: []
            }
        } = this.props;
        return(
            <>
            <Box className={classes.root}>
                <Typography component="div" className={classes.headerText}>
                    Your booking details
                </Typography>
                <Box>
                    <Grid container>
                        <Grid item xs={12} sm={4}  className={classes.imageContainer}>
                            <img 
                                alt={propertyDetails.name} 
                                src={propertyDetails.images && propertyDetails.images[propertyDetails.images.length - 1]}
                                className={classes.roomImage}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} className={classes.bookingInfo}>
                            <Box fontWeight="bold" fontSize="17px" mb={1}>{propertyDetails.name}</Box>
                            <Box fontSize="15px" lineHeight="20px">{propertyDetails.address.lineOne}</Box>
                            <Box fontSize="15px" lineHeight="20px">{propertyDetails.address.zipCode} {propertyDetails.address.city} {propertyDetails.address.state}</Box>
                            <Box fontSize="15px" lineHeight="20px">{propertyDetails.address.country}</Box>
                        </Grid>
                    </Grid>
                </Box>
                {accommodationList.map(accommodation =>(
                    <Box mt={8} key={`accommodation_${accommodation._id}`}>
                        <RoomInfoCard 
                            roomTypeName={roomTypes[accommodation.roomTypeID].name}
                            dates={this.formatReservationDates(accommodation.checkIn, accommodation.checkOut)}
                            guestCount={accommodation.customerID.length}
                            t = {t}
                        />
                        <Typography component="div" className={classes.headerText}>
                            Your personal details
                        </Typography>
                       
                        <Box className={classes.personalInfoSection}>
                            {accommodation.customerID && accommodation.customerID.map((customerID,index) =>(
                                <PersonalInfoCard 
                                    key={`guest_${customerID}`} 
                                    guestData={guests[customerID]}
                                    accommodationID={accommodation._id}
                                    submitHandler={this.updateGuestDetails}
                                    accommodationMenuList = {this.fetchAccommodationMenuList(accommodation._id)}
                                    updateAccommodation = {this.updateAccommodation}
                                    t = {t}
                                />
                            ))}
                            <AddGuest 
                                accommodationID={accommodation._id}
                                submitHandler={this.addGuest}
                                t = {t}
                            />
                        </Box>
                        
                    </Box>
                ))}
                
            </Box>
            <Footer 
                stepInfo="step 1 of 5"
                buttonLabel="Proceed"
                onProceed = {this.onProceed}
            />
            </>
        )
    }
}

const mapStateToProps = state =>{
    return{
        accommodationList: state.onlineCheckin.accommodationList,
        guests: state.onlineCheckin.guests,
        propertyDetails:  state.onlineCheckin.propertyDetails,
        roomTypes: state.onlineCheckin.roomTypes,
        reservationID: state.onlineCheckin.reservationID,
        accomodationLinkRequired: state.onlineCheckin.accomodationLinkRequired
    }
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(PersonalInformation)));