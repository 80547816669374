import moment from "moment-timezone";
import { RESERVATION_STATUS } from "./constants";
import * as i18nIsoCountries from "i18n-iso-countries";
import { DATE_FORMATS, fetchFormattedCountryName, getAvatarFlag, humanise } from "./utility";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

// Fetch Guest Country name 
export const fetchGuestCountryViaAddress = (guest = {}) => {
    let countryName = guest.nationality ||
        (guest?.address?.country !== "Unknown code" ? (guest?.address?.country || '') : "");

    return i18nIsoCountries.getName(countryName, "en") || countryName;
}

// Fetch guest formatted address
export const fetchGuestFormattedAddress = (countryName = null, guest) => {
    if (!countryName) {
        countryName = fetchGuestCountryViaAddress(guest)
    }

    const formattedCountryName = fetchFormattedCountryName(countryName);

    return guest?.address?.city && formattedCountryName ?
        (guest?.address?.city + ", " + formattedCountryName) :
        (formattedCountryName || '');
}

// Fetch guest avatar either image or flag
export const fetchGuestAvatar = (countryName = null, guest) => {
    if (!countryName) {
        countryName = fetchGuestCountryViaAddress(guest)
    }

    return guest?.image || getAvatarFlag(countryName);
}

// Fetch guest flag
export const fetchGuestFlag = (countryName = null, guest) => {
    if (!countryName) {
        countryName = fetchGuestCountryViaAddress(guest)
    }

    return getAvatarFlag(countryName);
}
 
// Fetch room details
export const fetchRoomDetails = (roomType = {}, room = {}, bed = {}) => {
    return {
        roomTypeName: roomType.name || null,
        roomName: room.name || null,
        bedName: roomType.dormOrPrivate == 'dorm' && bed.name ? bed.name : null,
    }
}

export const fetchGuestName = (guest = {}, guestName) => {
    return (guest?.firstName || guest?.lastName) ? humanise(guest.firstName, guest.lastName) : guestName;
}

export const checkOverdue = (checkIn, checkOut, timezone, status) => {

    let today = moment.tz(new Date(), timezone).format(DATE_FORMATS.DEFAULT_DATE);

    if([RESERVATION_STATUS.CONFIRMED, RESERVATION_STATUS.ONLINE_CHECKED_IN].includes(status) && moment(today).isAfter(moment(checkIn))){
        return 'Overdue Check-in'
    }
    if(status === RESERVATION_STATUS.IN_HOUSE && moment(today).isAfter(moment(checkOut))) {
        return 'Overdue Check-out'
    }
}