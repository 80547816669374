import React from "react";
import { withStyles } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import LoginSection from "./LoginSection";

import { logoutUser } from "../../redux/actions/auth";
import { PAGES } from "../../utils/constants";
import IMAGES from "../../constants/images";

import Hidden from "@material-ui/core/Hidden";
import VIDEOS from "../../constants/videos";

const styles = theme => ({
  root: {
    background: '#FFFFFF',
    display: 'flex',
    height: "100vh",
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: '100%',
    justifyContent: "center",
    position: 'relative',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 80px)',
      paddingLeft: 40,
      paddingRight: 40,
    }
  },
  logoContainer: {
    position: 'absolute',
    top: 40,
    left: 40,
    '& img': {
      height: 60,
      width: 60,
      [theme.breakpoints.down('sm')]: {
        height: 40,
        width: 40,
      }
    },
    [theme.breakpoints.down('sm')]: {
      top: 20,
      left: 20,
    }
  },
  image: {
    borderLeft: '1px solid #E0E0E0',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '40%',
    position: 'relative'
  },
  videoPlayer: {
    top: "50%",
    left: "49.9%",
    width: "100%",
    cursor: "pointer",
    height: "100%",
    opacity: 1,
    position: "absolute",
    animation: "0.3s ease-out 0s 1 normal forwards running jBcSpD",
    transform: "translate(-50%,-50%)",
    background: "transparent",
    minHeight: "100%",
    objectFit: "cover",
  }

});

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth && this.props.auth.isAuthenticated) {
      if (localStorage.sessionToken) this.props.history.push(`/${PAGES.LANDING}`);
      else if(localStorage.jwtToken) this.props.history.push(`/${PAGES.LOCK}`);
      else {
        this.props.dispatch(logoutUser(this.props.history))
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.logoContainer}><img src={IMAGES.LOGOS.counter} /></div>
          <LoginSection />
        </div>
        <Hidden smDown>
          <div className={classes.image}>
            <video 
              className={classes.videoPlayer} 
              autoPlay="autoplay" 
              loop={true} 
              muted={true} 
              playsInline={true}
            >
              <source src={VIDEOS.login} type="video/mp4"/>
            </video>
          </div>
        </Hidden>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default withStyles(styles)(
  connect(mapStateToProps)(withRouter(LoginPage))
);
