import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Masonry from 'react-masonry-css';

import PropertyFilters from './PropertyFilters';
import RoomCard from './RoomCard';

import { denormalize } from 'normalizr';
import { housekeepingSchema } from '../../redux/schema/housekeeping';
import { applyPropertyfilters } from './helper';
import { INIT_FILTERS } from './constants';

import useOnboardingStatus from '../../hooks/useOnboardingStatus';
import UpgradeMsgPage from './UpgradeMsgPage';

const useStyles = makeStyles(theme => ({
  root: {
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    // width: "calc(100% - 80px)",
    minHeight: 'calc(100vh - 120px)',
    background: '#F8F9FB',
    padding: "0px 40px 0px 40px",
    paddingTop: theme.spacing(5),
    marginTop: 70,
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      padding: "30px 10px 0px 10px", 
      // width: "calc(100% - 40px)",
      marginTop: 60,
    }
  },
  title: {
    margin: '0px'
  },
  listConatiner: {
    marginTop: '20px'
  },
  noData: {
    textAlign: "center",
    margin: "10%",
    fontSize: "2.2rem",
    color: "#999999",
  }
}));

const HousekeepingPage = props => {

  const classes = useStyles();
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));
  const onboardingStatus = useOnboardingStatus();
  const { hkRooms, hkBeds, isLoading, rooms } = props;
  
  const [roomsList, setRoomsList] = useState([]);
  const [filters, setFilters] = useState({ ...INIT_FILTERS });

  const filteredRoomsList = applyPropertyfilters(filters, roomsList, rooms);

  useEffect(() =>{

    //DENORMALIZING HOUSEKEEPING DATA...........................
    const hkRoomIDs = Object.keys(hkRooms);

    if(hkRoomIDs.length > 0){ 

      const entities = {
        rooms: { ...hkRooms },
        beds: { ...hkBeds }
      }

      const denormalizedData = denormalize(hkRoomIDs, housekeepingSchema, entities);
      setRoomsList([ ...denormalizedData]);
    };

  }, [hkRooms, hkBeds]);

  const onChangeFilter = useCallback((filtr) =>{
    setFilters({ ...filtr });
  }, []);

  //CHECK FOR ONBOARDIND STATUS..................
  if(!onboardingStatus.isPro){
    return (
      <div className={classes.root}>
        <UpgradeMsgPage featureType="Pro"/>
      </div>
    )
  }

  return (
    <div className={classes.root}>

      <h3 className={classes.title}>Housekeeping</h3>

      <PropertyFilters 
        filters={filters}
        onChangeFilter={onChangeFilter} 
      />
      
      <div className={classes.listConatiner}>
        <Masonry
          breakpointCols={deviceSm ? 1 : 2}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {filteredRoomsList.map(roomData =>(
            <RoomCard 
              key={`room_${roomData.roomID}`}
              hkBedInfo={roomData.hkBedInfo}
              roomID={roomData.roomID}
              roomTypeID={roomData.roomTypeID}
            />
          ))}
        </Masonry>
        
        {/* -------------NO DATA FOUND MESSAGE------------------ */}
        {!isLoading && filteredRoomsList.length == 0 && (
          <p className={classes.noData}>
            No Results Found
          </p>
        )}

      </div>
      
    </div>
  )
}

const mapStateToProps = state =>{
  const { housekeeping, loading, rooms } = state;
  return {
    hkRooms: housekeeping.rooms || {},
    hkBeds: housekeeping.beds || {},
    rooms: rooms.rooms || {},
    isLoading: loading.GET_HOUSEKEEPING_DATA
  }
}

export default React.memo(connect(mapStateToProps)(HousekeepingPage));