import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
  loadingOverlay: {
    backgroundColor: "#EFEFEF",
    position: "fixed",
    width: "100%",
    height: "100%",
    zIndex: "10000",
    top: "0px",
    left: "0px",
    opacity: ".5",
    filter: "alpha(opacity=50)"
  },
  loading: {
    top: "50%",
    left: "50%",
    display: "inline-block",
    position: "relative"
  }
});

class SpinnerWrapper extends Component {
  state = {};
  render() {
    const { classes, loading } = this.props;

    return loading ? (
      <div className={classes.loadingOverlay}>
        <CircularProgress className={classes.loading} />
      </div>
    ) : null;
  }
}

export default withStyles(styles)(SpinnerWrapper);
