import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    Grid,
    Typography,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    FormHelperText
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { withTranslation } from 'react-i18next';

import * as i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const countryTelephoneData = require('country-telephone-data');
import PhoneField from '../../common/PhoneField'

const secondaryColor = '#5C5C5C';

const useStyles = makeStyles(theme =>({
    headerText: {
        fontWeight: 'bold',
        fontSize: '18px',
    },

    mainContentCard: {
        backgroundColor: '#fff',
        borderRadius: '20px',
        padding: '20px',
        boxShadow: '0 0 8px 0px #dedede'
    },

    formTypeHolder: {
        borderRadius: '10px',
        padding: '0',
        height: '50px',
        overflow: 'hidden',
        cursor: 'pointer'
    },

    formType: {
        padding: "0 15px",
        borderColor: "#F1F1F1"
    },

    formTypeCheckIcon: {
        display: 'none',
        width: "24px",
        height: "25px",
        padding: "0px"
    },

    activeFormType: {
        backgroundColor: secondaryColor,
        color: "#fff",

        [theme.breakpoints.up('sm')]: {
            backgroundColor: "unset",
            color: "#000",
            display: 'flex'
        },
    },

    paymentFormHolder: {
        marginTop: '30px',
        position: 'relative',
        overflow: 'visible',
        padding: '25px 20px',
        [theme.breakpoints.down('sm')]: {
            padding: '25px 0px',
        }
    },

    formGroupHolder: {
        padding: '0 15px',
        marginTop: '25px',

        '&:nth-Child(1)': {
            marginTop: '0',
        },

        '&:nth-Child(2)': {
            [theme.breakpoints.up('sm')]: {
                marginTop: '0',
            }
        }
    },

    formControlLabel: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: '12px',
        marginBottom: "12px"
    },

    selectControlLabel: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: '12px',
        marginBottom: "20px"
    },

    formControl: {
        width: '100%'
    },

    formInput: {
        boxShadow: '0 0 8px 0px #dedede',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            // '&.Mui-focused fieldset': {
            //   borderColor: '#212121',
            // },
            '&.Mui-error fieldset': {
                border: '1px solid #f44336'
            },
        },
        // height: '40px',
        // borderRadius: '5px',
        // marginTop: '15px',
        // padding: '0 10px',

        // '&:after': {
        //     display: 'none'
        // },

        // '&:before': {
        //     display: 'none'
        // }
    },

    textAreaInput: {
        boxShadow: '0 0 8px 0px #dedede',
        resize: 'vertical',
        borderRadius: '4px',
        border: 'none',
        padding: '14px',
        '&:focus': {
            outline: 'none'
        }
    },

    selectInput: {
        height: '40px',
        boxShadow: '0 0 8px 0px #dedede',
         '&:before': {
            border: 'none'
        },
        '&.MuiInput-underline:hover:before': {
            border: 'none !important'
        },
        '&.MuiInput-underline:after': {
            border: 'none !important'
        },
        '& .MuiSelect-select':{
            paddingLeft: '14px'
        },
        '& .MuiSelect-select:focus':{
          backgroundColor: '#fff'
        }
    },
    errorMessage: {
        color: "#f44336",
        // marginLeft: "14px"
    }
}));

const formType = {
    type1: 1,
    type2: 2
};

const CustomerDetailForm = ({
        activeFormType,
        firstName,
        firstNameError,
        lastName,
        lastNameError,
        email,
        emailError,
        countryCodeError,
        telephone,
        telephoneError,
        isPhoneNumberMandatory,
        timeOfArrival,
        nationality,
        specialRequest,
        onInputChangeHandler,
        setActiveFormType,
        t
}) =>{
    const classes = useStyles();

    const handleGetCountries = () => {
        const countries = i18nIsoCountries.getNames("en");
        let countryOptions = [];
        for (var key in countries) {
          if (countries.hasOwnProperty(key)) {
            countryOptions.push({ value: i18nIsoCountries.alpha2ToAlpha3(key), label: countries[key] })
          }
        }
        return countryOptions;
    }

    const countries = handleGetCountries();

    const getActiveFormClass = type =>{
        if (activeFormType === type) {
            return `${classes.formType} ${classes.activeFormType}`;
        }
        return `${classes.formType}`;
    }

    return(
        <>
            <Typography component="div" className={`${classes.mainContentCard} ${classes.formTypeHolder}`}>
                <Box display="flex" fontSize="14px" alignItems="center" height="100%" borderRadius={10}>
                    <Box
                        flex="1"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        height="100%"
                        borderRight={2}
                        borderColor="#F1F1F1"
                        className={getActiveFormClass(formType.type1)}
                        onClick={() => setActiveFormType(formType.type1)}
                    >
                        <span>{t('bookingEngine.selfBooking')}</span>
                        <DoneIcon className={`${classes.formTypeCheckIcon} ${getActiveFormClass(formType.type1)}`} />
                    </Box>
                    <Box
                        flex="1"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        height="100%"
                        borderLeft={2}
                        borderColor="#F1F1F1"
                        className={getActiveFormClass(formType.type2)}
                        onClick={() => setActiveFormType(formType.type2)}
                    >
                        <span>{t('bookingEngine.elseBooking')}</span>
                        <DoneIcon className={`${classes.formTypeCheckIcon} ${getActiveFormClass(formType.type2)}`} />
                    </Box>
                </Box>
            </Typography>
            <Card className={`${classes.mainContentCard} ${classes.paymentFormHolder}`}>
                <Grid container>
                    <Grid item xs={12} md={6} className={classes.formGroupHolder}>
                        <InputLabel htmlFor="first-name" className={classes.formControlLabel}>{t('fields.firstName')} *</InputLabel>
                        <FormControl className={classes.formControl}>
                            <TextField
                                className={classes.formInput}
                                variant="outlined"
                                margin="dense"
                                fullWidth = {true}
                                name = "firstName"
                                value = {firstName}
                                error = {firstNameError}
                                onChange = { onInputChangeHandler }
                            />
                            {firstNameError && (
                                <FormHelperText className={classes.errorMessage}>{t('fields.firstNameError')}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.formGroupHolder}>
                        <InputLabel htmlFor="last-name" className={classes.formControlLabel}>{t('fields.lastName')} *</InputLabel>
                        <FormControl className={classes.formControl}>
                            <TextField
                                className={classes.formInput}
                                variant="outlined"
                                margin="dense"
                                fullWidth = {true}
                                name = "lastName"
                                value = {lastName}
                                error = {lastNameError}
                                onChange = { onInputChangeHandler }
                            />
                            {lastNameError && (
                                <FormHelperText className={classes.errorMessage}>{t('fields.lastNameError')}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} className={classes.formGroupHolder}>
                        <InputLabel htmlFor="email" className={classes.formControlLabel}>{t('fields.email')} *</InputLabel>
                        <FormControl className={classes.formControl}>
                            <TextField
                                inputProps={{type: "email"}}
                                className={classes.formInput}
                                variant="outlined"
                                margin="dense"
                                fullWidth = {true}
                                name = "email"
                                value = {email}
                                error = {emailError}
                                onChange = { onInputChangeHandler }
                            />
                            {emailError && (
                                <FormHelperText className={classes.errorMessage}>{t('fields.emailError')}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} className={classes.formGroupHolder}>
                        <InputLabel htmlFor="timeOfArrival" className={classes.formControlLabel}>Time of Arrival</InputLabel>
                        <FormControl className={classes.formControl}>
                            <TextField
                                className={classes.formInput}
                                variant="outlined"
                                type="time"
                                margin="dense"
                                fullWidth = {true}
                                name = "timeOfArrival"
                                value = {timeOfArrival}
                                onChange = { onInputChangeHandler }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={6} className={classes.formGroupHolder}>
                        <InputLabel className={classes.formControlLabel}>{t('fields.phone')} {isPhoneNumberMandatory && '*'}</InputLabel>
                        <PhoneField
                            telephone={telephone}
                            telephoneError={telephoneError}
                            countryCodeError={countryCodeError}
                            onInputChangeHandler={onInputChangeHandler}
                            fieldLabel={t} />
                    </Grid>

                    <Grid item xs={6} md={6} className={classes.formGroupHolder}>
                        <InputLabel htmlFor="nationality" className={classes.selectControlLabel}>{t('fields.nationality')}</InputLabel>
                        <FormControl className={classes.formControl}>
                            <Select
                                native
                                variant="outlined"
                                fullWidth={true}
                                className={classes.selectInput}
                                name="nationality"
                                value={nationality}
                                onChange = { onInputChangeHandler }
                            >
                                <option aria-label="None" value="" />
                                { countries.map((country,index) => (
                                    <option key={index} value={country.value} >{country.label}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} className={classes.formGroupHolder}>
                        <InputLabel htmlFor="special-request" className={classes.formControlLabel}>{t('bookingEngine.specialRequest')}</InputLabel>
                        <FormControl className={classes.formControl}>
                            <textarea
                                className={classes.textAreaInput}
                                rows={3}
                                aria-label="maximum height"
                                name="specialRequest"
                                value={specialRequest}
                                onChange = { onInputChangeHandler }
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
}

export default withTranslation()(CustomerDetailForm);
