import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
const useStyles = makeStyles(theme=>({
    root:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
    },

    errorCode: {
        fontSize: "8rem !important"
    }
}));
const PageNotFound = () =>{
    const classes = useStyles(); 
    return(
        <>
            <Box className = {classes.root}>
                <Typography variant="h1" component="div" className={classes.errorCode}>
                    404
                </Typography>
                <Typography variant="h4" component="div" color="secondary">
                    Page Not Found
                </Typography>
            </Box>
        </>
    );
}

export default PageNotFound;