import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { getAmtFormat } from '../../../utils/utility';
import ConfirmForm from '../../common/ConfirmForm';

const useStyles = makeStyles(theme =>({
    root: {
        color: "#5f5f5f",
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #e3e3e3",

        '& .itemCount': {
            fontSize: "1.1rem",
            fontWeight: 600,
            marginBottom: "5px",
        },
        '& .itemInfo': {
            fontSize: "1.2rem",
            color: "#bdbdbd",
        }
    },
    rowItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    itemName: {
        fontWeight: 600,
        fontSize: "1.4rem",
        marginBottom: "5px",
        color: "#5f5f5f",
    },
    deleteBtn: {
        color: "#13408d",
        cursor: "pointer",
        fontSize: "1.1rem",
        margin: "0px",
        marginTop: "5px"
    }
}));

const POSItem = props =>{
    const classes = useStyles();
    const { productData, productID, quantity, currency, deleteHandler } = props;
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [selectedID, setSelectedID] = React.useState(null);

    const handleConfirmFormOpen = (id) => {
        setSelectedID(id);
        setConfirmOpen(true);
    }

    const handleConfirmFormClose = () => {
        setSelectedID(null);
        setConfirmOpen(false);
    }

    const onDeleteHandler = () =>{
        const payload = { productID: selectedID };
        deleteHandler(payload);
    }

    return (
        <div className={classes.root}>
            <div className="itemCount">{quantity} x items</div>
            <div className={clsx(classes.rowItem, classes.itemName)}>
                <div>{productData.name}</div>
                <div>{productData.price ? getAmtFormat(productData.price, currency) : null}</div>
            </div>
            <div className="itemInfo">{getAmtFormat(productData.price / quantity, currency) + ' per item'}</div>
            
            {!selectedID && (
                <p 
                    className={classes.deleteBtn}
                    onClick={() =>handleConfirmFormOpen(productID)}
                >
                    Delete
                </p>
            )}

            {selectedID == productID && (
                <ConfirmForm
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onClose={handleConfirmFormClose}
                    onConfirm={onDeleteHandler}
                >
                    Are you sure you would like to delete this product?
                </ConfirmForm>
            )}
        </div>
    );
}

const POSSummary = props =>{

    const { posProducts, currency, orders, deleteHandler } = props;

    const summary = orders.map(item =>{
        const productData = { ...posProducts[item.productID] };
        if (!item.quantity) return null;

        return (
            <POSItem 
                key={`pos_${item.productID}`}
                productData={productData}
                productID={item.productID}
                quantity={item.quantity}
                currency={currency}
                deleteHandler={deleteHandler}
            />
        )
    });
    return summary;
}

export default POSSummary;