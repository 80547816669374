import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';

import { getRoomOptions, getBuildingOptions, getRoomtypeOptions } from './helper';
import { INIT_FILTERS, FILTERS, ROOM_STATUS_OPTIONS, SORT_OPTIONS } from './constants';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: "wrap",
    top: "70px",
    position: "sticky",
    background: "#F8F9FB",
    padding: '30px 0px',
    zIndex: 99,
    [theme.breakpoints.down('xs')]: {
      position: 'unset'
    }
  },
  selectContainer: {
    minWidth: '170px',
    marginRight: '10px',
    paddingTop: '10px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginRight: 'unset'
    }
  }
}))

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: 'none',
    boxShadow: 0,
    cursor: 'pointer',
    margin: "0px",
    height: '45px',
    backgroundColor: '#f2f2f2',
    fontSize: '1.2rem',
    fontWeight: 600,
    '&:hover': { border: 'none' },
  }),
  indicatorSeparator: (base, state) => ({
    display: 'none'
  }),
  menuList: (base, state) => ({
    fontSize: '1.3rem',
  }),
};

const PropertyFilters = props => {
  const classes = useStyles();
  const { rooms, onChangeFilter, buildings, filters, roomTypes } = props;
  const [floorOptions, setFloorOptions] = useState([]);

  const roomOptions = useMemo(() =>getRoomOptions(rooms, roomTypes), [rooms, roomTypes]);
  const roomTypesOptions = useMemo(() =>getRoomtypeOptions(roomTypes), [roomTypes]);
  const buildingOptions = useMemo(() =>getBuildingOptions(buildings), [buildings]);

  const onChangeHandler = (data, action) => {
    if (!data) {
      clearFilterHandler(action);
      return;
    }
    const updatedFilters = { ...filters, [action.name]: data.value };
    onChangeFilter(updatedFilters);
  };

  const multiSelectHandler = (data, action) => {
    if (!data || data.length == 0) {
      clearFilterHandler(action);
      return;
    }
    const values = data.map(item => item.value);
    const updatedFilters = { ...filters, [action.name]: [...values] };
    onChangeFilter(updatedFilters);
    return;
  }

  const onSelectBuilding = (data, action) => {

    if (!data) {
      clearFilterHandler(action);
      setFloorOptions([]);
      return;
    }

    //GET FLOORS OPTIONS OF SELECTED BUILDING.....................
    const selectedBuilding = buildingOptions.find(building => building.value == data.value);
    const options = selectedBuilding.floors.filter(floor => floor).map(floor => ({ label: floor, value: floor }));

    const updatedFilters = { 
      ...filters, 
      [action.name]: data.value, 
      [FILTERS.floor]: INIT_FILTERS.floor 
    };

    onChangeFilter(updatedFilters);
    setFloorOptions([...options]);
  }

  const clearFilterHandler = (action) => {
    const updatedFilters = {
      ...filters,
      [action.name]: INIT_FILTERS[action.name]
    };

    //FLOOR IS A FILTER DEPENDENT ON BUILDING..............
    if(action.name == FILTERS.buildingID){
      updatedFilters.floor = INIT_FILTERS.floor;
    }

    onChangeFilter(updatedFilters);
  }

  return (
    <div className={classes.container}>

      {/* -----------------BUILDING FILTER------------------- */}
      <div className={classes.selectContainer}>
        <Select
          styles={selectStyle}
          placeholder="Building"
          name={FILTERS.buildingID}
          options={buildingOptions}
          onChange={onSelectBuilding}
          isClearable
        />
      </div>

      {/* -----------------FLOOR FILTER------------------- */}
      {floorOptions.length > 0 && (
        <div className={classes.selectContainer}>
          <Select
            styles={selectStyle}
            placeholder="Floor"
            name={FILTERS.floor}
            options={floorOptions}
            onChange={onChangeHandler}
            isClearable
          />
        </div>
      )}

      {/* -----------------SORT FILTER------------------- */}
      <div className={classes.selectContainer}>
        <Select
          styles={selectStyle}
          placeholder="Sort"
          name={FILTERS.sort}
          options={SORT_OPTIONS}
          onChange={onChangeHandler}
          isClearable
        />
      </div>

      {/* -----------------ROOM FILTER------------------- */}
      <div className={classes.selectContainer}>
        <Select
          styles={selectStyle}
          placeholder="Room"
          name={FILTERS.roomID}
          options={roomOptions}
          onChange={onChangeHandler}
          isClearable
        />
      </div>

      {/* -----------------ROOMTYPE FILTER------------------- */}
      <div className={classes.selectContainer}>
        <Select
          styles={selectStyle}
          placeholder="Roomtype"
          name={FILTERS.roomTypeID}
          options={roomTypesOptions}
          onChange={onChangeHandler}
          isClearable
        />
      </div>

      {/* -----------------STATUS FILTER------------------- */}
      <div className={classes.selectContainer}>
        <Select
          styles={selectStyle}
          placeholder="Status"
          name={FILTERS.statuses}
          options={ROOM_STATUS_OPTIONS}
          isMulti
          onChange={multiSelectHandler}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const { rooms, propertyConfig } = state;
  return {
    rooms: rooms.rooms || {},
    roomTypes: rooms.roomTypes || {},
    buildings: propertyConfig.buildings || {}
  }
}

export default connect(mapStateToProps)(PropertyFilters);