import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import Footer from '../../common/Footer';
import IconButton from '@material-ui/core/IconButton';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import moment from 'moment-timezone';

import DateChangeInfo from '../DateChangeInfo';
import { getDurationCount, formatDateRange } from '../../helper';
import { fetchDateFormat, DATE_FORMATS } from '../../../../utils/utility';
import { fetchNoOfNights } from '../../../../utils/utility';

const useStyles = makeStyles(theme =>({
    root: {
        
    },
    datePicker: {
        border: '1px solid #e0e0e0',
        padding: '8px',
        borderRadius: '30px',
        width: '240px',
        '& .DateInput_1': {
            width: '100%'
        }
    },
    incrementContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: "200px",
    },
    IconButton: {
        border: '1px solid #e0e0e0',
        color: '#000'
    },
    incDuration: {
        textAlign: "center",
        fontSize: "1.2rem",
        fontWeight: 600,
        '& p': {
            margin: '0px',
        },
        '& .count': {
            fontSize: "2.2rem",
            fontWeight: 600,
        },
    },
    seperator: {
        fontSize: '1.3rem',
        fontWeight: '600',
        margin: '30px 0px'
    }
}));

const DATE_FORMAT_OPTIONS = {
    dateFormat: 'DD. MMM YYYY', 
    seperator: '-'
}

const DurationSelect = props =>{
    const classes = useStyles();
    const { selectedAccomodation, setSelectedDateRange, getManualOptions } = props;
    const [checkOutdate, setCheckOutdate] = useState(null);
    const [focused, setFocused] = useState(null);
    const [extDayCount, setExtDayCount] = useState(0);
    const [dates, setDates] = useState({ original: '', new: '' });

    useEffect(() =>{
        const dateParams = {
            startDate: selectedAccomodation.checkIn,
            endDate: selectedAccomodation.checkOut,
        }
        setDates({ original: { ...dateParams }, new: '' });
    }, []);

    const incrementDay = () =>{
        const updatedCount = extDayCount + 1;
        setExtDayCount(updatedCount);
        setNewBookingDate(updatedCount);
    }

    const decrementDay = () =>{
        if(extDayCount == 1 || extDayCount == 0) return;
        const updatedCount = extDayCount - 1;
        setExtDayCount(updatedCount);
        setNewBookingDate(updatedCount);
    }

    const setNewBookingDate = (dayCount) =>{
        const newEndDate = moment(selectedAccomodation.checkOut).add(dayCount, 'days').format(DATE_FORMATS.DEFAULT_DATE);
        const newDates = {
            startDate: selectedAccomodation.checkIn,
            endDate: newEndDate
        }
        setDates({ ...dates, new: { ...newDates }});
        setCheckOutdate(newEndDate);
        setSelectedDateRange(newDates);
    }

    const onDateChangeHandler = (date) =>{
        const newDates = {
            startDate: selectedAccomodation.checkIn,
            endDate: moment(date).format(DATE_FORMATS.DEFAULT_DATE),
        }
        const dayCount = getDurationCount(selectedAccomodation.checkOut, newDates.endDate);
        setDates({ ...dates, new: { ...newDates }});
        setExtDayCount(dayCount);
        setCheckOutdate(date);
        setSelectedDateRange(newDates);
    }

    const nextStepHandler = () =>{
        const data = {
            startDate: dates.original.endDate,
            duration: (fetchNoOfNights(dates.original.endDate, dates.new.endDate) || 0) + 1
        }
        getManualOptions(data);
    }

    return (
        <>
            <div className={classes.root}>

                <DateChangeInfo 
                    originalDates={dates.original && formatDateRange(dates.original, DATE_FORMAT_OPTIONS) || ''}
                    newDates={dates.new && formatDateRange(dates.new, DATE_FORMAT_OPTIONS) || ''}
                />

                <div className={classes.datePicker}>
                    <SingleDatePicker 
                        date={checkOutdate && moment(checkOutdate) || null}
                        onDateChange={onDateChangeHandler}
                        focused={focused}
                        onFocusChange={({ focused }) => setFocused(focused)}
                        placeholder="Pick a check out date"
                        noBorder 
                        numberOfMonths={1}
                        displayFormat={'DD MMM YYYY'}
                        hideKeyboardShortcutsPanel
                        isOutsideRange={day =>{ 
                            const date = moment(selectedAccomodation.checkOut).add(1, 'days').format(DATE_FORMATS.DEFAULT_DATE)
                            return day.isBefore(fetchDateFormat(date, DATE_FORMATS.DEFAULT_DATE));
                        }}
                    />
                </div>

                <p className={classes.seperator}>or</p>

                <div className={classes.incrementContainer}>

                    <IconButton 
                        className={classes.IconButton}
                        onClick={decrementDay}
                    >
                        <RemoveRoundedIcon fontSize="large"/>
                    </IconButton>

                    <div className={classes.incDuration}>
                        <p className="count">{extDayCount}</p>
                        <p>days</p>
                    </div>

                    <IconButton 
                        className={classes.IconButton}
                        onClick={incrementDay}
                    >
                        <AddRoundedIcon fontSize="large"/>
                    </IconButton>
                </div>

            </div>

            <Footer 
                label="Next" 
                isBtnDisabled={extDayCount == 0 ? true : false}
                onBtnClick={nextStepHandler}
            />
        </>
    )
}

export default connect(null)(DurationSelect);