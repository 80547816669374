import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme =>({
    dateBtn:{
        boxShadow: '0 0 8px 0px #dedede',
        backgroundColor: '#fff',
        color: '#000',
        padding: '15px 40px',
        fontWeight: 'bold',
        marginRight: '10px',
        '&:hover':{
            backgroundColor: '#006f63',
            color: '#fff',
        },
        '&.active':{
            backgroundColor: '#006f63',
            color: '#fff',
            boxShadow: 'none',
            pointer: 'none'
        },
        [theme.breakpoints.down('sm')]: {
            flex: 1,
            padding: '10px',
            marginRight: '5px',
        }
    }
}));

const DateFilterBtn = props =>{
    const classes = useStyles();
    return(
        <Button className={`${classes.dateBtn} ${props.isActive ? 'active' : ''}`}>
            {props.label}
        </Button>
    );
}

export default DateFilterBtn;