import http from "../utils/http";
import { normalize } from "normalizr";
import { ratesAvailabilitySchema } from "../schema/inventory";
import { roomTypeSchema, roomSchema, bedSchema, bedAllocationSchema, bedStatusSchema, taxSchema } from "../schema/room";

//----------------------------------------------------------------
// LOAD ROOM
//----------------------------------------------------------------
export function loadRooms() {
  const ACTION_NAME = "LOAD_ROOM";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/rooms")
      .then(successResult => {
        const normalizedData = normalize(successResult.data, [roomSchema]);
        // localStorage.setItem(
        //   "roomsLocalStorage", JSON.stringify({
        //     rooms: normalizedData.entities.rooms,
        //     roomKeys: normalizedData.result
        //   })
        // );
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities.rooms
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD ROOM TYPES
//----------------------------------------------------------------
export function loadRoomTypes() {
  const ACTION_NAME = "LOAD_ROOM_TYPE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/roomtypes")
      .then(successResult => {
        const normalizedData = normalize(successResult.data, [roomTypeSchema]);
        // localStorage.setItem(
        //   "roomTypesLocalStorage", JSON.stringify({
        //     roomTypes: normalizedData.entities.roomTypes,
        //     roomTypeKeys: normalizedData.result
        //   })
        // );
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities.roomTypes
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD RATES AVAILABILITY
//----------------------------------------------------------------
export function loadRatesAvailability(params) {
  const ACTION_NAME = "LOAD_RATES_AVAILABILITY";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/rates", { params })
      .then(successResult => {
        let normalizedData = normalize(
          successResult.data,
          ratesAvailabilitySchema
        );
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// RESET ALL ROOM SUGGESTION
//----------------------------------------------------------------
export function resetRoomSuggestion() {
  return {
    type: "RESET_ROOM_SUGGESTION",
    payload: []
  };
}

//----------------------------------------------------------------
// LOAD BEDS
//----------------------------------------------------------------
export function loadBeds() {
  const ACTION_NAME = "LOAD_BEDS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/beds")
      .then(successResult => {
        const normalizedData = normalize(successResult.data, [bedSchema]);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities.beds
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD BED ALLOCATIONS
//----------------------------------------------------------------
export function loadBedAllocations(param) {
  const ACTION_NAME = "LOAD_BED_ALLOCATIONS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/allocations?date=" + param)
      .then(successResult => {
        var normalizedData = normalize(successResult.data, [bedAllocationSchema]);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities.allocations
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD ONE BED STATUS
//----------------------------------------------------------------
export function loadOneBedStatus(id) {
  const ACTION_NAME = "LOAD_ONE_BED_STATUS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/bedStatus/" + id)
      .then(successResult => {
        var normalizedData = normalize(successResult.data, bedStatusSchema);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities.status
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD ALL BED STATUS
//----------------------------------------------------------------
export function loadBedsStatus() {
  const ACTION_NAME = "LOAD_ALL_BEDS_STATUS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/bedStatus/")
      .then(successResult => {
        var normalizedData = normalize(successResult.data, [bedStatusSchema]);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities.status
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// UPDATE BED STATUS
//----------------------------------------------------------------
// export function updateBedsStatus(id, payload) {
//   const ACTION_NAME = "UPDATE_BEDS_STATUS";
//   return function (dispatch) {
//     dispatch({ type: `${ACTION_NAME}_REQUEST` });
//     return http
//     .patch("/api/bedStatus/" + id, payload)
//     .then(successResult => {
//       // var normalizedData = normalize(successResult.data, [bedStatusSchema]);
//       dispatch({
//         type: `${ACTION_NAME}_SUCCESS`,
//         payload: successResult.data
//       });
//     })
//     .catch(errorResult => {
//       let error =
//       errorResult && errorResult.response && errorResult.response.data
//       ? errorResult.response.data
//       : errorResult;
//       dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
//     });
//   };
// }

//----------------------------------------------------------------
// LOAD ALL ROOM SUGGESTION
//----------------------------------------------------------------
export function loadRoomSuggestion(propID, params) {
  const ACTION_NAME = "LOAD_ROOM_SUGGESTION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      // .get(`/api/frontdesk/reservation/suggest/new/${propID}`, { params })
      .get(`/api/reservation/suggest/bedScore/`, { params })
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`, payload: {
            suggestions: successResult.data.suggestions,
            prices: successResult.data.prices
          }
        });
      })
      .catch(errorResult => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// LOAD ALL TAX
//----------------------------------------------------------------
export function loadTax() {
  const ACTION_NAME = "LOAD_TAX";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/taxes/")
      .then(successResult => {
        var normalizedData = normalize(successResult.data, [taxSchema]);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities.taxes
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}