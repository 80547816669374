import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

import HorizontalScollView from '../common/HorizontalScrollView';
import RoomCardHeader from './RoomCardHeader';
import BedListItem from './BedListItem';
import { updateBedsStatus } from '../../redux/actions/housekeeping';
import { BED_STATUSES } from '../../utils/constants';
import { bedStatusInput, processNextBedStatus } from './helper';
import { alphaNumericSort } from '../../utils/wordUtils';

const useStyles = makeStyles(theme =>({
    root: {
        border: '1px solid #ECECEC',
        borderRadius: '8px',
        overflow: "hidden",
        background: '#FFFFFF',
    },
    content: {
        padding: "25px 20px",
        position: 'relative',
        '& .listItem:last-child': {
            marginBottom: '0px'
        }
    },
    labelContainer: {
        display: 'flex',
        paddingLeft: 170,
        marginBottom: -10
    },
    label: {
        color: '#BDBDBD',
        fontSize: '0.9rem',
        width: '50%',
    },
    second: {
        paddingLeft: 10,
    },
    loaderContainer: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 9999
    },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '40%',
        zIndex: 9999,
        width: "35px !important",
        height: "35px !important",
        color: "#999999",
    },
    noData: {
        textAlign: "center",
        fontSize: "1.5rem",
        color: "#999999",
        margin: 0
    }

}));

function getBedName(bedID, dormOrPrivate, beds){
    return dormOrPrivate == 'dorm' ? beds?.[bedID]?.name : 'Room'
}

const RoomCard = props =>{
    const classes = useStyles();
    const { roomID, roomTypeID, hkBedInfo, rooms, roomTypes, beds, dispatch, propertyCode } = props;
    const [isLoading, setLoading] = useState(false);
    const hasUncleanedBeds = hkBedInfo.some(bed => bed.bedStatus == BED_STATUSES.unclean);
    
    const roomType = roomTypes?.[roomTypeID] || {};
    const room = rooms?.[roomID] || {};

    const markAllBedsCleanHandler = useCallback(() =>{
        setLoading(true);

        const payload = [];

        for(const bed of hkBedInfo){

            if(bed.bedStatus !== BED_STATUSES.unclean) continue;

            const data = bedStatusInput(bed.reservations, bed.bedStatus);
            const updatedBedStatus = processNextBedStatus(data);

            payload.push({
                bedID: bed.bedID,
                status: updatedBedStatus
            });
        }

        markBedCleanHandler(payload)
            .then(success =>{
                setLoading(false)
            });
    }, [hkBedInfo]);

    const markBedCleanHandler = useCallback((payload) =>{
        return dispatch(updateBedsStatus(payload));
    }, []);

    const sortBedNames = (bedsList) =>{
        const sorterBedList = bedsList.sort((a,b) =>{
            const bedNameA = getBedName(a.bedID, roomType.dormOrPrivate, beds);
            const bedNameB = getBedName(b.bedID, roomType.dormOrPrivate, beds);
            return alphaNumericSort(bedNameA, bedNameB);
        });
        return sorterBedList;
    }

    return (
        <div className={classes.root}>

            <RoomCardHeader 
                imageSrc={roomType?.images?.[0]?.imagelink}
                title= {room.name}
                subTitle= {roomType.name}
                showMarkAllClean={hasUncleanedBeds}
                markCleanHandler={markAllBedsCleanHandler}
            />
            
            <div className={classes.content}>

                <div className={classes.labelContainer}>
                    <div className={classes.label}>LAST NIGHT</div>
                    <div className={clsx(classes.label, classes.second)}>TONIGHT</div>
                </div>

                <HorizontalScollView flexColumn>

                    {sortBedNames(hkBedInfo).map(bed =>(
                        <BedListItem 
                            key={`bed_${bed.bedID}`}
                            bedID={bed.bedID}
                            bedStatus={bed.bedStatus}
                            bedName={getBedName(bed.bedID, roomType.dormOrPrivate, beds)}
                            markBedCleanHandler={markBedCleanHandler}
                            reservations={bed.reservations}
                            propertyCode={propertyCode}
                        />
                    ))}
                </HorizontalScollView>
                
                {isLoading && (
                    <div className={classes.loaderContainer}>
                        <CircularProgress className={classes.loader}/>
                    </div>
                )}

                {/* -------------NO DATA FOUND MESSAGE------------------ */}
                {hkBedInfo.length == 0 && (
                    <p className={classes.noData}>
                        No Results Found
                    </p>
                )}

            </div>

        </div>
    )
}

const mapStateToProps = state =>{
    const { rooms, register } = state;
    return {
        rooms: rooms?.rooms || {},
        roomTypes: rooms?.roomTypes || {},
        beds: rooms?.beds || {},
        propertyCode: register?.propertyCode || null
    }
}

export default connect(mapStateToProps)(RoomCard);