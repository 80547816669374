import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { toTitleCase } from '../../utils/wordUtils';
import { RESERVATION_STATUS } from '../../utils/constants';
import { checkIfSingleSpannedResv } from './helper';
import HtmlTooltip from '../common/HtmlTooltip';

const useStyles = makeStyles(theme =>({
    root: {
        display: 'flex',
        '& $blockContainer:nth-child(2)': { marginLeft: '10px' },
    },
    blockContainer: {
        border: '1px solid #ECECEC',
        borderRadius: '5px',
        height: "50px",
        padding: '0px 10px',
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            width: "200px",
        },
        '&.singleBlock':{
            [theme.breakpoints.down('xs')]: {
                width: '430px'
            }
        }
    },
    checkedOut: {
        backgroundColor: '#f2f2f2',
        '& $name': { color: '#999999' }
    },
    name: {
        fontSize: '1.1rem',
        fontWeight: 600,
        margin: '0px',
        color: '#666666',
    },
    statusIndicator: {
        width: "6px",
        height: "6px",
        borderRadius: "50%",
        backgroundColor: "#c7bad8",
        marginLeft: "5px",
    },
    checkedIn: {
        display: 'flex',
        alignItems: 'center'
    },
    reservationBlock: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f6f6f6'
        }
    },
    blockedReservation: {
        backgroundColor: "#cccccc",
    },
    blocked: {
        backgroundColor: '#e3e3e3'
    }
}));

const EmptyBlock = props =>{
    const classes = useStyles();
    const { isBlocked = false } = props;
    return (
        <div className={clsx(classes.blockContainer, { [classes.blocked]: isBlocked })}></div>  
    )
}

const ReservationBlock = props =>{
    const classes = useStyles();

    const { status, name, onClickHandler, singleBlock = false } = props;

    if(status == RESERVATION_STATUS.BLOCK){
        return (
            <HtmlTooltip 
                title="Room block"
                cursorType="default"
            >
                <div className={clsx(classes.blockContainer, classes.blockedReservation, { singleBlock })}>
                    <p className={classes.name}>{toTitleCase(name)}</p>
                </div>
            </HtmlTooltip>
        )
    }

    if(status == RESERVATION_STATUS.CHECKED_OUT){
        return (
            <HtmlTooltip title="Checked-out">
                <div 
                    className={clsx(classes.blockContainer, classes.checkedOut, { singleBlock })}
                    onClick={onClickHandler}
                >
                    <p className={classes.name}>{toTitleCase(name)}</p>
                </div>
            </HtmlTooltip>
        )
    }

    return (
        <div 
            className={clsx(classes.blockContainer, classes.reservationBlock, { singleBlock })}
            onClick={onClickHandler}
        >
            <p className={classes.name}>{toTitleCase(name)}</p>
            {status == RESERVATION_STATUS.IN_HOUSE && <div className={classes.statusIndicator}></div>}
        </div>
    )
}

const BedOccupancyInfo = props => {
    const classes = useStyles();
    const { reservations, bedID, checkInGuest, navigateToReservation } = props;

    const reservationClickHandler = reservation =>{
        navigateToReservation(reservation.resvCode);
    }
    
    const isSingleSpanned = checkIfSingleSpannedResv(reservations);

    if(isSingleSpanned){
        const reservation = reservations[0];

        return (
            <ReservationBlock 
                status={reservation.status} 
                name={reservation.name}
                onClickHandler={() =>reservationClickHandler(reservation)}
                singleBlock={isSingleSpanned}
            />
        )
    }

    return (
        <div className={classes.root}>
            {reservations.map((reservation, index) =>{

                if(reservation.reservationID){
                    return(
                        <ReservationBlock 
                            key={`block_${reservation.reservationID}_${index}`}
                            status={reservation.status} 
                            name={reservation.name}
                            onClickHandler={() =>reservationClickHandler(reservation)}
                        />
                    )
                }

                return <EmptyBlock 
                    key={`empty_block_${bedID}_${index}`}
                    isBlocked={reservation.isBlocked}
                />
            })}
        </div>
    )
}

export default BedOccupancyInfo;