import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import Timeline from '../components/new_reservation/Timeline';
import InfoSection from '../components/new_reservation/info-section';
import SummaryDrawer from '../components/new_reservation/SummaryDrawer';
import AddOnOptions from '../components/new_reservation/AddOnOptions';
import RoomUpdatesOptions from '../components/new_reservation/RoomUpdatesOptions';
import BookingDetails from '../components/new_reservation/booking-details';

import { getReservationDetailsViaCode, resetError, resetReservation } from '../redux/actions/reservations';
// import { getStripeAccountStatus } from '../redux/actions/payments';
// import { loadEmailTemplates } from '../redux/actions/property';

const styles = theme =>({
    root: {
        // padding: '10px 20px',
        // paddingTop: '40px',
        backgroundColor: '#f8f9fb',
        minHeight: "100vh",
    },
    scrollContainer: {
        height: '100vh',
        overflowY: 'auto'
    },
    section: {
        border: '1px solid red'
    },
    mainSection: {
        padding: '40px',
        paddingTop: "0px",
        [theme.breakpoints.down('lg')]: {
            padding: '20px',
            paddingTop: "0px",
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
            paddingTop: "0px",
        },
    },
    backBtnContainer: {
        height: "40px",
        display: "flex",
    },
    backBtn: {
        fontSize: "1.2rem",
        padding: "10px 0px",
        color: "#777777",
        '&:hover': {
            backgroundColor: 'unset'
        },
        '& .icon': {
            fontSize: "1.6rem",
            marginRight: "2px",
        }
    },
    sidebarSection: {
        padding: '10px',
        padding: "40px 15px",
        backgroundColor: '#305c8b'
    },
    cardConatiner: {
        boxShadow: '0 1px 10px #e0e0e0',
        borderRadius: '10px',
        padding: '25px',
        boxSizing: 'border-box'
    },
    loaderContainer: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        background: 'rgba(255,255,255,0.5)',
        zIndex: 9999
    },
    loader: {
        textAlign: 'center',
        margin: 'auto',
        position: 'absolute',
        left: 0,
        border: 0,
        right: 0,
        top: '45%',
        zIndex: 9999
    },
});
class ReservationPage extends Component {

    state = {
        isDrawerOpen: false
    }

    componentDidMount(){
        const { history, match: { params }, dispatch } = this.props;

        if(!params?.propcode || !params.resvcode){
            history.push('/');
            return;
        }

        dispatch(getReservationDetailsViaCode(params.propcode, params.resvcode))
            .then(success =>{
                if(!success) history.push('/');
            });
        // dispatch(getStripeAccountStatus());
        // dispatch(loadEmailTemplates());
    }

    openDrawerHandler = () =>{
        this.setState({ isDrawerOpen: true });
    }

    closeDrawerHandler = () =>{
        this.setState({ isDrawerOpen: false });
    }

    handleCloseError = () =>{
        const { dispatch } = this.props;
        dispatch(resetError('UPDATE_RESERVATION_STATUS'));
        dispatch(resetError('UPDATE_RESERVATION_DATA'));
        dispatch(resetError('CREATE_RESERVATION_TRANSACTION'));
        dispatch(resetError('DELETE_ORDER'));
    }

    goToDashboard = () =>{
        // this.props.history.push('/');
        this.props.history.goBack();
    }

    render(){
        const { classes, isLoading, errors } = this.props;
        const { isDrawerOpen } = this.state;
        return ( 
            <div className={classes.root}>
                <Grid container className={classes.root}>

                    {/* -----------------MAIN SECTION------------------ */}
                    <Grid item md={8} sm={12} xs={12} className={clsx(classes.scrollContainer, 'hideScrollBar')}>
                        <div className={classes.mainSection}>
                            
                            <div className={classes.backBtnContainer}>
                                <IconButton 
                                    className={classes.backBtn}
                                    onClick={this.goToDashboard}
                                >
                                    <KeyboardBackspaceIcon className="icon" /> Back
                                </IconButton>
                            </div>

                            <InfoSection 
                                openDrawerHandler={this.openDrawerHandler}
                                goToDashboard={this.goToDashboard}
                            />

                            <AddOnOptions />
                            
                            <RoomUpdatesOptions />
                            <BookingDetails />
                        </div>
                    </Grid>
                    
                    {/* -----------------SIDEBAR TIMELINE SECTION------------------ */}
                    <Hidden smDown>
                        <Grid md={4} xs={12} item className={clsx(classes.sidebarSection, classes.scrollContainer, 'hideScrollBar')}>
                            <Timeline />
                        </Grid>
                    </Hidden>
                </Grid>
                
                {/* ----------DRAWER DISPLAYING TIMELINE FOR MOBILE VIEW---------- */}
                <Hidden mdUp>
                    <SummaryDrawer 
                        open={isDrawerOpen}
                        closeHandler={this.closeDrawerHandler}
                    />
                </Hidden>

                {/* ..............Circular Loader...................... */}
                {isLoading && (
                    <div className={classes.loaderContainer}>
                        <CircularProgress className={classes.loader} />
                    </div>
                )}

                {/* ------------- Snackbar error messages -------------- */}
                {errors && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={this.handleCloseError}
                        autoHideDuration={6000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{errors}</span>}
                    />
                )}
                
            </div>
        )
    }
}

const mapStateToProps = state =>{
    const { loading, error } = state;
    return {
        isLoading: 
            loading.UPDATE_RESERVATION_STATUS || 
            loading.LOAD_RESERVATION ||
            loading.DELETE_CUSTOMER ||
            loading.CREATE_RESERVATION_TRANSACTION ||
            loading.UPDATE_BASIC_RESERVATION_DATA ||
            loading.CANCEL_RESERVATION ||
            loading.DELETE_ACCOMMODATION ||
            loading.DELETE_RESERVATION_TAX ||
            loading.DELETE_ORDER,
        errors: error.LOAD_RESERVATION || 
            error.UPDATE_RESERVATION_STATUS ||
            error.UPDATE_RESERVATION_DATA ||
            error.CREATE_RESERVATION_TRANSACTION ||
            error.DELETE_ORDER
    };
}

export default withStyles(styles)(connect(mapStateToProps)(ReservationPage));