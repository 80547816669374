import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import IMAGES from '../../constants/images';
import HtmlTooltip from '../common/HtmlTooltip';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiPaper-root': {
      borderRadius: 20,
    },
  },
  container: {
    padding: 20,
    width: 200
  },
  iconContainer: {
    alignItems: 'center',
    borderRadius: 10,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 10,
    '&:hover': {
      background: '#ECECEC',
      transition: '0.5s ease'
    },
    '&.disabled':{
      cursor: "default !important",
      opacity: 0.5,
      '&:hover': {
        background: '#fff'
      }
    }
  },
  icon: {
    height: 30,
    width: 30,
    opacity: 0.7
  },
  text: {
    fontSize: '1.2rem',
    marginTop: 10,
    textAlign: 'center',
  }
}));

const MENU_ITEMS = [
  { id: 'cashIn', label: 'Cash In', iconSrc: IMAGES.ICONS.cashIn },
  { id: 'cashOut', label: 'Cash Out', iconSrc: IMAGES.ICONS.cashOut },
  // { id: 'bank', label: 'Bank', iconSrc: IMAGES.ICONS.bank },
];

const CashMenu = (props) => {
  const classes = useStyles();
  const { open, anchorEl, handleClose, onClickHandler, isCashDrawerClosed, shiftHandler, cashDrawerID } = props;
  const isCashDrawerSelected = cashDrawerID ? true : false;
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      className={classes.root}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className={classes.container}>
        <Grid container spacing={3}>

          {MENU_ITEMS.map((menu, index) =>(
            <Grid item xs={6} key={`menu_${menu.id}_$`}>
              <div
                className={classes.iconContainer} 
                onClick={() =>onClickHandler(menu.id)}
              >
                <img className={classes.icon} src={menu.iconSrc} />
                <div className={classes.text}>{menu.label}</div>
              </div>
            </Grid>
          ))}
          
          <Grid item xs={6}>
            <HtmlTooltip title={isCashDrawerSelected ? '' : 'No cash drawer was selected. Please logout and login again to select cash drawer on the setup screen.'}>
              <div 
                className={clsx(classes.iconContainer, { disabled: isCashDrawerSelected ? false: true })}
                onClick={isCashDrawerSelected ? shiftHandler : null}
              >
                <img className={classes.icon} src={IMAGES.ICONS.endShift} />
                <div className={classes.text}>
                  {isCashDrawerClosed ? 'Begin Shift' : 'End Shift'}
                </div>
              </div>
            </HtmlTooltip>
          </Grid>
        </Grid>
      </div>
    </Popover>
  )
}

export default CashMenu;