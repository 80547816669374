const POSReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOAD_PRODUCT_CATEGORIES_SUCCESS":
      return {
        ...state,
        categories: {...action.payload.productCategory},
        products: { ...action.payload.products } 
      };
    //----------------------------------------------------------------------------------------
    default:
      return state;
  }
};

export default POSReducer;
