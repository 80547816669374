import { fetchNoOfDays } from "../../../utils/utility";

const initialState = {
    selectedRoomTypeID: null,
    dormOrPrivate: 'dorm',
    bookingDetails: {},
    bookingCostInfo: {},
    selectedBookingData: {}
};

const initialRoomData = {
    noOfBedsBooked: 1,
    ratePlanID: null,
    baseRoomCost: 0,
    rates: []
};

export default (state = { ...initialState }, action) =>{
    switch(action.type){
        case 'FETCH_AVAILABLE_ROOMS_SUCCESS':
            const { checkInDate,  checkOutDate } = action.payload;
            const bookingDuration = fetchNoOfDays(checkInDate, checkOutDate)
            return { 
                ...state, 
                roomList: action.payload.roomListData,
                checkInDate: action.payload.checkInDate,
                checkOutDate: action.payload.checkOutDate,
                bookingDuration
            };
    
        case 'FETCH_ROOMS_INFO_SUCCESS':
            return { 
                ...state, 
                propertyDetails: action.payload.propertyDetails,
                bookEngineConfig: action.payload.bookEngineConfig,
                roomTypes: action.payload.roomTypes,
                taxItems: action.payload.taxItems,
                ratePlans: action.payload.ratePlans,
                currency: {
                    code:  action.payload.propertyDetails.currency,
                    country: action.payload.propertyDetails.address ? action.payload.propertyDetails.address.country : 'Germany'
                }
            };
        
        case 'SET_SELECTED_ROOM_TYPE_ID':
            return{
                ...state,
                selectedRoomTypeID: action.payload.roomTypeID,
                dormOrPrivate: action.payload.dormOrPrivate,
                selectedBookingData: { ...initialRoomData }
            } 

        case 'UPDATE_SELECTED_BOOKIN_DATA':
            return{
                ...state,
                selectedBookingData: { ...state.selectedBookingData, ...action.payload }
            }
        
        case 'ADD_ROOM_TO_BOOKING_CART':
            return {
                ...state,
                // selectedRoomTypeID: action.payload.roomTypeID,
                bookingDetails: {
                    ...state.bookingDetails,
                    [action.payload.roomTypeID]: { 
                        ...action.payload.roomData
                    }
                }
            };

        case 'UPDATE_BOOKING_ROOM_DETAILS':
            const updatedRoomDetails = { 
                ...state.bookingDetails[action.payload.roomTypeID], 
                ...action.payload.updatePayload 
            };
            return {
                ...state,
                bookingDetails: {
                    ...state.bookingDetails,
                    [action.payload.roomTypeID]: { ...updatedRoomDetails }
                }
            };

        case 'DELETE_ROOM_FROM_CART':
            const bookingCartData = { ...state.bookingDetails };
            delete bookingCartData[action.payload.roomTypeID];
            return{
                ...state,
                bookingDetails: { ...bookingCartData }
            } 

        case 'FETCH_TOTAL_BOOKING_COST_INFO_SUCCESS':
            return{
                ...state,
                bookingCostInfo: action.payload
            }
        
        case 'ADD_RESERVATION_DETAILS':
            return{
                ...state,
                reservationDetails: action.payload
            }

        case 'BOOKING_PAYMENT_SUCCESS':
            return{
                ...state
            }

        case 'RESET_BOOKING_CART':
            return { ...state, ...initialState };
        default:
            return state;
    }
};