import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import * as i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

import Dialog from '../common/CustomDialog';
import Footer from './Footer';
import GuestForm from './GuestForm';
import RoomBedList from './RoomBedList';

import { fetchGuestAccomodation, getAccomodationInfo, filterDataFields, formatGuestData, getTodaysDate } from '../helper';
import { addAssignGuestAccommodation, updateReservationStatus } from '../../../redux/actions/reservations';
import { uploadCustomerDoc } from '../../../redux/actions/customers';
import { RESERVATION_STATUS } from '../../../utils/constants';


const styles = theme =>({
    root: {},
    addBtnContainer: {
        display: "flex",
        justifyContent: "center",
        padding: "20px0px",
    },
    addBtn: {
        padding: "5px 20px",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        fontSize: "1.2rem",
        color: "#4f4f4f",
        fontWeight: 600,
        marginBottom: "30px",
        '& .addIcon': {
            color: '#4f4f4f',
            marginRight: "5px",
        }
    },
});

const handleGetCountries = () => {
    const countries = i18nIsoCountries.getNames("en");
    let countryOptions = [];
    for (var key in countries) {
      if (countries.hasOwnProperty(key)) {
        countryOptions.push({ value: i18nIsoCountries.alpha2ToAlpha3(key), label: countries[key] })
      }
    }
    return countryOptions;
}

const swapArrayElements = function(arr, indexA, indexB) {
    const temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    return arr
};


const defaultGuestFormData = {
    _id: '',
    firstName:"",
    lastName:"",
    email:"",
    phone:"",
    nationality:"",
    dateOfBirth:"",
    gender:"N/A",
    documents:[],
    companyName:"",
    taxID:"",
    address:{
        lineOne:"",
        city:"",
        state:"",
        country:"",
        zipCode:""
    }
}

const ERROR_FIELDS = {
    firstName: false,
    lastName: false,
}
class GuestCheckin extends Component {
    state = {
        accommodationList: [],
        accommodationCount: 0,
        activeStep: 0,
        currentAccommodationID: '',
        selectedAccommodationID: '',
        guestList: [],
        guestFormErrors: [],
        countryOptions: handleGetCountries(),
        isFormUpdated: false,
        showFormError: false
    }

    componentDidMount(){
        this.processAccomodationData();
    }

    processAccomodationData = () =>{
        const { rooms, reservation } = this.props;
        const accomodationData = fetchGuestAccomodation(reservation.accommodationList);

        let accommodationList = Object.keys(accomodationData).map(guestNumber =>{
            
            const accomodationInfo =  accomodationData[guestNumber][0];
            
            const roomInfo = {
                roomTypeID: accomodationInfo.roomTypeID,
                roomID: accomodationInfo.roomID,
                bedID: accomodationInfo.bedID
            }
            const { name, dormOrPrivate, roomCapacity } = getAccomodationInfo(roomInfo, rooms);
            return {
                _id: accomodationInfo._id,
                bedID: accomodationInfo.bedID,
                customerID: [ ...accomodationInfo.customerID ],
                name,
                dormOrPrivate,
                roomCapacity,
                ...roomInfo
            }
        });

        if(this.state.accommodationList.length > 0){
            const accIDsOrder = this.state.accommodationList.map(item => item._id);
            accommodationList = accommodationList.sort((a, b) =>accIDsOrder.indexOf(a._id) - accIDsOrder.indexOf(b._id));
        }

        this.accommodationStateHandler(accommodationList);
    }

    accommodationStateHandler = (accommodationList) =>{
        const  { activeStep } = this.state;
        const selectedAccommodationID = accommodationList[activeStep]._id;
        const guestList = this.getGuestList(accommodationList, activeStep, selectedAccommodationID);

        const guestFormErrors = guestList.map(data => ({ ...ERROR_FIELDS }));

        this.setState({ 
            accommodationList: [ ...accommodationList ],
            accommodationCount: accommodationList.length,
            selectedAccommodationID,
            currentAccommodationID: selectedAccommodationID,
            guestList: [ ...guestList ],
            guestFormErrors: [ ...guestFormErrors]
        });
    }

    componentDidUpdate(prevProps, prevState) {
        
        const  { activeStep, accommodationList } = this.state;
        const { reservation } = this.props;
        const isAccommodationUpdated = !isEqual(reservation.accommodationList, prevProps.reservation.accommodationList);

        if(isAccommodationUpdated){
           this.processAccomodationData(reservation.accommodationList);
        }

        if(!isAccommodationUpdated && prevState.activeStep !== activeStep){
            this.accommodationStateHandler(accommodationList);
        }

        if(!isAccommodationUpdated && !isEqual(reservation.guests, prevProps.reservation.guests)){
            this.accommodationStateHandler(accommodationList);
        }
    }

    getGuestList = (accommodationList, activeStep,  selectedAccommodationID) =>{
  
        if(accommodationList.length == 0) return [];

        const { reservation } = this.props;
        const { customerID } = accommodationList.find(data=> data._id == selectedAccommodationID);
        let guestList = [];

        //IF NO CUSTOMERS ADDED TO THE ACCOMMODATION.......................
        if(customerID.length == 0){

            guestList = [{ ...defaultGuestFormData }];

            if(activeStep == 0){
                const filteredGuestData = filterDataFields(reservation.guests[0], defaultGuestFormData);
                const updatedGuestData = formatGuestData(filteredGuestData, i18nIsoCountries);
                guestList[activeStep] = { ...updatedGuestData };
            }

            return guestList;
        }

        guestList = customerID.map(id =>{
            const customerData = reservation.guests.find(guest => guest._id == id);
            const filteredGuestData = filterDataFields(customerData, defaultGuestFormData);
            const updatedGuestData = formatGuestData(filteredGuestData, i18nIsoCountries);
            return { ...updatedGuestData }
        });

        return guestList;
    }

    onChangeRoomBed = event =>{
        const value = event.target.value;
        this.setState({ 
            selectedAccommodationID: value,
            isFormUpdated: true 
        });
    }

    nextStep = () =>{
        const { accommodationCount, activeStep } = this.state;
        if(activeStep < (accommodationCount - 1)){
            this.swapAccommodations(activeStep);
        }

        this.setState({ isFormUpdated: false });
    }

    prevStep = () =>{
        const { activeStep } = this.state;
        if(activeStep > 0){
            this.setState({ activeStep: activeStep - 1 });
        }
    }

    swapAccommodations = (activeStep) =>{
        const { accommodationList, selectedAccommodationID, currentAccommodationID } = this.state;
        let accomList = cloneDeep(accommodationList);

        const currAccIndx = accomList.findIndex(item => item._id == currentAccommodationID);
        const selectedAccIndx = accomList.findIndex(item => item._id == selectedAccommodationID);

        if(currAccIndx == selectedAccIndx){
            this.setState({ activeStep: activeStep + 1 });
            return;
        }

        accomList = swapArrayElements(accomList, currAccIndx, selectedAccIndx);

        this.setState({ 
            accommodationList: [ ...accomList ],  
            activeStep: activeStep + 1
        });
    }

    guestFormInputHandler = ({ key, value, guestIndex }) =>{
        const { guestList } = this.state;
        let customerList = cloneDeep(guestList);
        set(customerList[guestIndex], key, value);
        this.setState({ 
            guestList: [ ...customerList ],
            isFormUpdated: true
        });
    }

    removeGuestFormHandler = (guestIndex) =>{
        const { guestList, guestFormErrors } = this.state;
        let customerList = cloneDeep(guestList);

        customerList = customerList.filter((cust, index) => index !== guestIndex);
        const customerFormErrors = guestFormErrors.filter((cust, index) => index !== guestIndex);

        this.setState({ 
            guestList: [ ...customerList ],
            guestFormErrors: [ ...customerFormErrors] 
        });
    }

    addGuestFormHandler = () =>{
        this.setState(prevState =>({ 
            guestList: [ 
                ...prevState.guestList,  
                { ...defaultGuestFormData }
            ],
            guestFormErrors: [
                ...prevState.guestFormErrors,  
                { ...ERROR_FIELDS }
            ] 
        }));
    }

    processGuestPayload = (guestList, propertyID) =>{
        let data = { updateCustomers: [], addCustomers: [] };
        for (let guest of guestList){

            const { _id, ...guestDetails } = guest;

            if(guest?._id){
                data.updateCustomers.push({  customerID: _id,  ...guestDetails });
                continue; 
            }

            data.addCustomers.push({ ...guestDetails, propertyID });
        }
        return data;
    }

    submitGuestDetails = () =>{
        const { dispatch, reservation } = this.props;
        const { selectedAccommodationID, guestList } = this.state;

        const { updateCustomers, addCustomers } = this.processGuestPayload(guestList, reservation.propertyID);

        const payload = {
            accommodationID: selectedAccommodationID,
            ...(updateCustomers.length > 0 && { updateCustomers }),
            ...(addCustomers.length > 0 && { addCustomers }),
        }
        return dispatch(addAssignGuestAccommodation(reservation._id, payload));
    }

    fileSelectHandler = (guestID, fileData) =>{
        //IF DOCUMENT UPLOAD FOR NEW GUEST.......................
        if(!guestID){
            this.submitGuestDetails()
                .then(data =>{
                    if(data){
                        const customerID = data.newCustomerIDs[0];
                        this.fileUploadHandler(customerID, fileData);
                    }
                });
            return;
        }

        //IF DOCUMENT UPLOAD FOR EXISISTING GUEST.......................
        this.fileUploadHandler(guestID, fileData);
    }

    fileUploadHandler = (guestID, fileData) =>{
        const { reservation, dispatch } = this.props;
        const data = {
            propID: reservation.propertyID,
            customerID: guestID,
            ...fileData
        }
        dispatch(uploadCustomerDoc('doc', data));
        this.setState({ isFormUpdated: false });
    }

    validateGuestInfo = () =>{
        const { guestList, guestFormErrors } = this.state;
        const errors = [ ...guestFormErrors ];
        let success = true;

        guestList.forEach((guest, index) =>{

            if(!guest?.firstName || !guest.firstName.trim()){
                errors[index].firstName = true;
                success = false;
            }
    
            if(!guest?.lastName || !guest.lastName.trim()){
                errors[index].lastName = true;
                success = false;
            }
        });

        return success;
    }

    submitHandler = () =>{
        const { isFormUpdated, accommodationList, activeStep } = this.state;

        if(!isFormUpdated && accommodationList?.[activeStep]?.customerID?.length > 0){
            this.nextStep();
            return;
        }

        if(!this.validateGuestInfo()){ 
            this.triggerErrorSanckbar(true);
            return
        };

        this.submitGuestDetails()
            .then(data =>{
                if(data) this.nextStep();
            });
    }

    triggerErrorSanckbar = (status) =>{
        this.setState({ showFormError: status });
    }

    checkinStatusHandler = () =>{
        const { dispatch, reservation, closeModalHandler } = this.props;
        const { isFormUpdated, accommodationList, activeStep } = this.state;
        const payload = { status: RESERVATION_STATUS.IN_HOUSE };

        if(isFormUpdated || (accommodationList.length == 1 && accommodationList[activeStep]?.customerID?.length == 0)){

            if(!this.validateGuestInfo()){ 
                this.triggerErrorSanckbar(true);
                return;
            }

            this.submitGuestDetails()
                .then(success =>{
                    if(success){
                        dispatch(updateReservationStatus(reservation._id, payload))
                            .then(success => success && closeModalHandler())
                    }
                });
            return;
        }

        if(accommodationList?.[activeStep]?.customerID?.length == 0){
            this.validateGuestInfo();
            this.triggerErrorSanckbar(true);
            return;
        }

        dispatch(updateReservationStatus(reservation._id, payload))
            .then(success => success && closeModalHandler())
    }

    getFooterInfo = () =>{
        const { activeStep, accommodationCount } = this.state;
        const isLastStep = (activeStep == (accommodationCount - 1));
        const stepInfo = `${activeStep + 1} of ${accommodationCount}`;

        if(isLastStep){
            return {
                nextBtnLabel: 'Check-in',
                onClickNextBtn: this.checkinStatusHandler,
                stepInfo
            }
        }

        return {
            nextBtnLabel: 'Next',
            onClickNextBtn: this.submitHandler,
            stepInfo
        }
    }

    render() {
        const { classes, closeModalHandler, isLoading, timezone } = this.props;
        const { 
            accommodationList, 
            selectedAccommodationID, 
            currentAccommodationID, 
            guestList, 
            countryOptions,
            activeStep,
            guestFormErrors,
            showFormError
        } = this.state;

        const accommodation = accommodationList?.[activeStep] || {};
        const footerData = this.getFooterInfo();
        
        return (
            <Dialog
                headerTitle={'Check-in guest'}
                closeModalHandler={closeModalHandler}
                isLoading={isLoading}
            >
                {/* ----------------ROOMS/BEDS OPTIONS------------------ */}
                <RoomBedList 
                    data={accommodationList}
                    selectedAccommodationID={selectedAccommodationID}
                    currentAccommodationID={currentAccommodationID}
                    selectedAccomCapacity={accommodation.roomCapacity}
                    onChangeRoomBed={this.onChangeRoomBed}
                />
                
                {/* -----------------GUEST FORMS--------------------------- */}
                {guestList.map((guestInfo, index) =>{
                    const keyID = guestInfo._id ? `guest_${guestInfo._id}_${index}` : `guest_add_${index}`;
                    const guestFormError = guestFormErrors[index];
                    return(
                        <GuestForm 
                            guestIndex={index}
                            key={keyID}
                            keyID={keyID}
                            data={guestInfo}
                            countryOptions={countryOptions}
                            formErrors={guestFormError}
                            onChangeHandler={this.guestFormInputHandler}
                            onDeleteHandler={this.removeGuestFormHandler}
                            fileSelectHandler={this.fileSelectHandler}
                            todaysDate={getTodaysDate(timezone)}
                        />      
                    )  
                })}
                
                {/* ----------------------ADD GUEST BUTTON--------------------------- */}
                {accommodation.dormOrPrivate == 'private' && 
                    accommodation.roomCapacity !== guestList.length && (

                        <div className={classes.addBtnContainer}>
                            <IconButton 
                                className={classes.addBtn} 
                                onClick={this.addGuestFormHandler}
                            >
                                <AddRoundedIcon className="addIcon"/> Add Guest
                            </IconButton>
                        </div>
                )}

                <Footer
                    nextStepHandler={footerData.onClickNextBtn}
                    prevStepHandler={this.prevStep}
                    isNextBtnDisabled={false}
                    showPrevBtn={activeStep > 0}
                    stepInfo={footerData.stepInfo}
                    nextBtnLabel={footerData.nextBtnLabel}
                />

                {showFormError && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        onClose={() => this.triggerErrorSanckbar(false)}
                        autoHideDuration={6000}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">Please fill in the all the required guest information</span>}
                    />
                )}

            </Dialog>
        )
    }
}

const mapStateToProps = state =>{
    const { reservationDetails, rooms, loading, property } = state;
    return {
        reservation: reservationDetails?.reservation || {},
        timezone: property.timezone,
        rooms: rooms || {},
        isLoading: loading.ADD_ASSIGN_GUEST_ACCOMMODATION ||
            loading.UPLOAD_CUSTOMER_DOC
    }
}

export default withStyles(styles)(connect(mapStateToProps)(GuestCheckin));