import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { fetchDateFormat } from '../../utils/utility';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles(theme =>({
    root:{
        // border: '1px solid',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: '60px'
    },
    avatarContainer: {
        borderRadius: '50%',
        padding: '10px',
        margin: '40px 0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    avatar: {
        width: '150px',
        height: '150px'
    },
    whatsappIconContainer:{
        cursor: 'pointer'
    }
}));

const CheckinSuccess = props =>{
    const classes = useStyles();
    //const number = 1638586410;
    const connectToWhatsapp = () =>{
        window.open(`https://wa.me/${props.settings.pointOfContact.phone}`);
    }
    const { t } = props

    if(props.isKioskFlow) setTimeout(()=> window.location.reload(), 5000);

    return(
        <Box className={classes.root}>
            <Box mb={2}>
                <img src="/images/icons/like.png" 
                    width="55px"
                    height="55px"
                />
            </Box>
            <Box fontSize="16px" fontWeight="bold" mb={1}>
                {t('onlineCheckin.successPage.hurray')}
            </Box>
            <Box fontSize="20px" fontWeight="bold">
                {t('onlineCheckin.successPage.seeYou')} {props.checkinDate}
            </Box>
            {(props.settings) && (props.settings.pointOfContact) && <div>
                <Box className={classes.avatarContainer}>
                    <Avatar src={props.settings.pointOfContact.contactPic} className={classes.avatar} />
                </Box>
                <Box fontSize="16px" fontWeight="bold" mb={1}>
                    {t('onlineCheckin.successPage.yourManager')}
                </Box>
                <Box fontSize="20px" fontWeight="bold" mb={3}>
                    {props.settings.pointOfContact.name}
                </Box>

                <Box fontSize="13px" fontWeight="bold" color="gray" textAlign="center" mb={2}>
                    {t('onlineCheckin.successPage.message1')},<br/>
                    {t('onlineCheckin.successPage.message2')}
                </Box>
                <div
                    className={classes.whatsappIconContainer} 
                    onClick={connectToWhatsapp}>
                    <img src="/images/icons/whatsapp.png"
                        width="55px"
                        height="55px"
                    />
                </div>
            </div>}
        </Box>
    )
};

const mapStateToProps = state => {
    return {
        checkinDate: fetchDateFormat(state.onlineCheckin.checkIn, 'MMMM D'),
        settings: state.onlineCheckin.settings,
        isKioskFlow: Boolean(state.kiosk.mode)
    }
}

export default withTranslation()(connect(mapStateToProps)(CheckinSuccess))