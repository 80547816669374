import React from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const useStyles = makeStyles(theme =>({
    root:{
        padding: '20px'
    }
}));

const AlertModal = ({
    isOpen,
    message,
    continueHandler,
    cancelHandler,
    t
}) =>{
    const classes = useStyles();
    return(
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={cancelHandler}
        >
            <DialogContent className={classes.root}>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={cancelHandler} color="primary">
                {t('actions.cancel')}
            </Button>
            <Button onClick={continueHandler} color="primary" autoFocus>
                {t('actions.continue')}
            </Button>
            </DialogActions>
        </Dialog>
    );
}

export default withTranslation()(AlertModal);