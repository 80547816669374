import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles(theme =>({
    iconButton: {
        color: '#666666',
        cursor: 'pointer',
        fontSize: 8,
        padding: '4px'
    },
    moreIcon: {
        fontSize: '1.8rem'
    },
    productMenu: {
        '& ul': {
          paddingBottom: 0,
          paddingTop: 0,
        }
    },
    listItemIcon: {
        minWidth: '40px',
        width: '40px'
    },
    label: {
        fontSize: "1.2rem",
        fontWeight: 600,
    }
}));

const MoreButton = props =>{
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const { options = [] } = props;

    const onClickHandler = type =>{
        props.selectHandler(type);
        setAnchorEl(null);
    }

    return(
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                className={classes.iconButton}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                className={classes.productMenu}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                style={{ padding: 0 }}
                getContentAnchorEl={null}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {options.map(option =>(
                    <ListItem 
                        button 
                        key={`moreOption_${option.type}`}
                        onClick={() =>onClickHandler(option.type)}
                    >
                        <ListItemIcon  className={classes.listItemIcon}> {option.icon} </ListItemIcon>
                        <div className={classes.label}>{option.label}</div>
                    </ListItem>
                ))}
            </Menu>
        </>
    )
}

export default MoreButton;