import { schema } from "normalizr";

// Define your inventory schema
const features = new schema.Entity("features", {}, { idAttribute: "_id" });

// Define your transaction mode schema
const transactionModeSchema = new schema.Entity("transactionMode", {}, { idAttribute: "_id" });

// Define your ratesAvailability schema
const propertySchema = new schema.Entity(
  "property",
  {
    features: [features]
  },
  {
    idAttribute: "_id"
  }
);

// Define upsell items schema
const upsellItemsSchema = new schema.Entity("upsellItems", {}, { idAttribute: "_id" });

export { propertySchema, upsellItemsSchema, transactionModeSchema };