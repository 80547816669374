import http from "../utils/http";
import { normalize } from 'normalizr';
import { productCategorySchema } from "../schema/pos";

//----------------------------------------------------------------
// LOAD PRODUCTS AND PRODUCT CATEGORIES
//----------------------------------------------------------------
export function fetchProductCategorys() {
  const ACTION_NAME = "LOAD_PRODUCT_CATEGORIES";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/productCategory/products/`)
      .then(successResult => {
        const data = successResult.data;
        var normalizedOrder = normalize(data, [productCategorySchema]);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: { ...normalizedOrder.entities }
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// CREATE ORDER 
//----------------------------------------------------------------
export function createOrder(order) {
  const ACTION_NAME = "CREATE_ORDER";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/pos/order`, { ...order })
      .then(successResult => {
        const data = successResult.data;
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: { ...data.resvDetails }
        });
        return true;
      })
      .catch(errorResult => {
        console.log(errorResult)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return;
      });
  };
}

export function deleteOrder(reservationID, payload) {
  const ACTION_NAME = "DELETE_ORDER";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/pos/order/reservation/${reservationID}`, payload)
      .then(successResult => {
        const data = successResult.data;
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: data
        });
        return true;
      })
      .catch(errorResult => {
        console.log(errorResult)
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
        return;
      });
  };
}