import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { getAmtFormat, fetchDateFormat, humanise, DATE_FORMATS } from '../../../utils/utility';
import { TRANSACTION_MODE } from '../../../utils/constants';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: "30px",
    },
    title: {
        color: "#305c8b",
        cursor: "pointer",
        fontSize: "1.1rem",
        marginTop: "20px",
        fontWeight: 600,
        marginBottom: "20px",
    },
    paymentItem: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: "10px",
    },
    paymentDesc: {
        color: '#666666',
        fontWeight: 500,
        fontSize: '1.4rem',
        paddingBottom: 5,
    },
    transactionText: {
        paddingBottom: 5
    },
    secondaryText: {
        color: '#666666',
        fontSize: '1.1rem',
        paddingBottom: 5
    },
}));

const PaymentSummary = props => {

    const classes = useStyles();
    const { transactions, currency, transactionModes, timezone, users, t } = props;
    const [showPaymentSummary, setShowPaymentSummary] = React.useState(false);

    const getUserName = (row) =>{
        const user = users[row.createdBy] || {};
        const userName = humanise(user.firstName, user.lastName) || 'admin';
        return userName;
    }

    const getSummaryText = (row) => {

        let transactionText = '', 
            transactionByText = '',
            transactionDate = fetchDateFormat(row.createdAt, DATE_FORMATS.DATE_MONTH, timezone) + ' ' + fetchDateFormat(row.createdAt, DATE_FORMATS.TIME, timezone),
            userName = getUserName(row);
            // button = getSummaryButton(row, 'delete'); // default action button

        const transactionMode = { ...transactionModes[row.modeID] };

        switch (transactionMode.name) {
            case TRANSACTION_MODE.HOSTELWORLD:
                transactionText = TRANSACTION_MODE.HOSTELWORLD + ' ' + 'deposit';
                break;

            case TRANSACTION_MODE.CHANNEL:
                transactionText = 'Channel Collect';
                break;

            case TRANSACTION_MODE.DISCOUNT:
                transactionText = 'Discount';
                transactionByText = 'Applied' + ' by ' + userName;
                break;

            case TRANSACTION_MODE.SURCHARGE:
                transactionText = 'Surcharge';
                transactionByText = 'Applied' + ' by ' + userName;
                break;

            default:
                const transactionStatus = row.isRefund ? 'Refunded' : 'Paid';
                const transactionAction = row.isRefund ? 'Credited' : 'Collected';
                transactionText = transactionStatus + ' (' + transactionMode.name + ')';
                transactionByText = transactionAction + ' by ' + userName;
                break;
        }

        return (
            <div>
                <div className={classes.transactionText}>{transactionText}</div>
                <div className={classes.secondaryText}>{transactionDate}</div>
                {transactionByText && <div className={classes.secondaryText}>{transactionByText}</div>}
                {/* {(transactionMode.name !== TRANSACTION_MODE.STRIPE) && button} */}
            </div>
        )
    }

    const formatTranscationAmount = row => {
        if (!row.amount) return '';
        let amount = Math.abs(row.amount);
        amount = `${currency}${getAmtFormat(amount)}`;
        amount = row.isRefund ? `-${amount}` : amount;
        return amount;
    }

    return (
        <div className={classes.root}>

            <div className={classes.title} onClick={() => setShowPaymentSummary(!showPaymentSummary)} >
                {showPaymentSummary ? '- Hide payment history' : '+ Show payment history'}
            </div>

            {showPaymentSummary && transactions.map((row, index) => {
                const summaryText = getSummaryText(row);
                return (
                    <div className={classes.paymentItem} key={`payment_${index}`}>
                        <div className={classes.paymentDesc}>{summaryText}</div>
                        <div className={classes.paymentDesc}>{formatTranscationAmount(row)}</div>
                    </div>
                )
            })}
        </div>
    )
}

export default PaymentSummary;