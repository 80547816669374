import { humanise, fetchNoOfNights } from "../../../utils/utility";
import { UNALLOCATED } from "../../../utils/constants";
import { LOG } from "../../../common/constants/log";

export function stableSort(array, newest) {
  let firstTruthy = newest ? -1 : 1;
  let secondTruthy = newest ? 1 : -1;
  return array.sort((l, m) =>
    l.createdAt > m.createdAt ? firstTruthy : secondTruthy
  );
}

export function formatDateDisplay(dateArray) {
  const arrLength = dateArray.length;
  if (arrLength == 0) return "";
  if (arrLength > 1) {
    const dateString = `${dateArray[0]} to ${dateArray[arrLength - 1]}`;
    return dateString;
  }
  return dateArray[0];
}

export function getRoomTypes(accomList, roomTypes) {
  const roomTypeNames = accomList.map(
    (item) => roomTypes?.[item?.roomTypeID]?.name || UNALLOCATED
  );
  return roomTypeNames.join(", ");
}

export function getFormattedChangeItems(data) {
  let formattedData = [];
  data.forEach((currActivity, index) => {
    if (index == 0) {
      const dateArray = [currActivity.date];
      const dateString = formatDateDisplay(dateArray);
      formattedData.push({ ...currActivity, date: [...dateArray], dateString });
      return;
    }

    const prevActivity = data[index - 1];
    const prevDate = prevActivity.date;
    const formattedDataPrevIndex = formattedData.length - 1;

    if (
      currActivity.initialBed === prevActivity.initialBed &&
      currActivity.finalBed === prevActivity.finalBed &&
      currActivity.initialAmount === prevActivity.initialAmount &&
      currActivity.finalAmount === prevActivity.finalAmount &&
      fetchNoOfNights(prevDate, currActivity.date) === 1
    ) {
      const dateArray = [
        ...formattedData[formattedDataPrevIndex].date,
        currActivity.date,
      ];
      const dateString = formatDateDisplay(dateArray);
      formattedData[formattedDataPrevIndex].date = [...dateArray];
      formattedData[formattedDataPrevIndex].dateString = dateString;
      return;
    }

    const dateArray = [currActivity.date];
    const dateString = formatDateDisplay(dateArray);
    formattedData.push({
      ...currActivity,
      date: [currActivity.date],
      dateString,
    });
  });
  return formattedData;
}

export function processLogData(logData) {
  let log = { ...logData };

  const text = LOG?.[log?.activity]?.TEXT || null;
  const loopText = LOG?.[log?.activity]?.LOOP_TEXT || null;

  const itemCheckArr = [
    LOG.CHANGE_BED_MULTIPLE.CODE,
    LOG.CHANGE_RATE_MULTIPLE.CODE,
  ];
  const items = itemCheckArr.includes(log.activity)
    ? getFormattedChangeItems(log.items)
    : log.items;

  log.user = humanise(log.user);
  return { log, text, loopText, items };
}
