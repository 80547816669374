import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { fetchCurrencyFormat, amountCommaSeperator } from '../../../utils/utility';

const useStyles = makeStyles(theme =>({
    root: {
        textAlign: 'center',
        marginBottom: '50px',
        '& h4, p': { 
            margin: 0,
            marginBottom: '20px' 
        },
        '& p': {  
            color: "#737373",
            fontWeight: 600,
            fontSize: "1.3rem",
            marginBottom: '0px'
        }
    }
}));

const PaymentInfo = props =>{
    const classes = useStyles();
    const { amount, currency, subText } = props;
    return(
        <div className={classes.root}>
            <h4>{currency} {amountCommaSeperator(amount)}</h4>
            <p>{subText}</p>
        </div>
    )
}

export default PaymentInfo;