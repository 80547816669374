import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ConfirmForm from '../../common/ConfirmForm';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
// import CloseIcon from '@material-ui/icons/Close';
// import DoneIcon from '@material-ui/icons/Done';

const styles = theme =>({
    dndContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px dashed #BDBDBD',
        width: '100%',
        height: '150px',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 'unset'
        },
        '& img': {
            height: 40,
            width: 40,
            opacity: 0.5,
        }
    },
    docLink:{
        color: '#13408d',
        cursor: 'pointer',
        fontSize: '1.2rem',
        marginTop: 5,
        '&:hover': {
            color: '#13408d',
        }
    },
    imageRow: {
        alignItems: 'center',
        display: 'flex',
    },
    imageContainer: {
        width: '60%',
        minWidth: '60%',
        overflow: 'hidden',
        marginLeft: 0,
        '& img': {
            objectFit: 'cover',
            width: '100%',
        }
    },
    iconHolder: {
        marginLeft: 15,
    },
    icon: {
        alignItems: 'center',
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'flex',
        padding: 10,
        '& img': {
            height: 20,
            width: 20
        },
        '&:hover': {
            background: '#E0E0E0',
        }
    },
    sureSection: {
        marginLeft: 15,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    sureMessage: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    sureText: {
        color: '#666666',
        fontSize: '1.1rem',
    },
    options: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 20
    },
    actionIcon: {
        border: '1px solid #dddddd',
        borderRadius: '50%',
        cursor: 'pointer',
        fontSize: '1.8rem',
        marginLeft: 10,
        padding: 5,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        }
    }
});

class UploadItem extends Component{
    constructor(props) {
        super(props);
        this.state = { 
            docInfo: [] 
        }
    }

    handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        //console.log('event', e);
    }

    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({drag: false});
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            //console.log('e.dataTransfer.files ',e.dataTransfer.files);
            this.props.uploadHandler(e.dataTransfer.files, this.props.guestData._id);
            e.dataTransfer.clearData();
            this.dragCounter = 0;
        }
    }

    componentDidMount() {
        if (!this.props.guestData.documents || this.props.guestData.documents.length === 0) {
            let element = this.dropRef;
            //element.addEventListener('dragenter', this.handleDragIn);
            //element.addEventListener('dragleave', this.handleDragOut);
            element.addEventListener('dragover', this.handleDrag);
            element.addEventListener('drop', this.handleDrop);
            // element.addEventListener('click',()=>{
            //     this.inputRef.click();
            // });
        }
        else {
            const { guestData } = this.props;
            const guestDocInfo = this.processGuestDocs(guestData?.documents);

            this.setState({ docInfo: [ ...guestDocInfo] });
        }
    }

    componentDidUpdate(prevProps){
        const { guestData } = this.props;
        if(guestData?.documents && !isEqual(prevProps.guestData.documents, guestData.documents)){
            const guestDocInfo = this.processGuestDocs(guestData?.documents);
            this.setState({ docInfo: [ ...guestDocInfo] });
        }
    }

    processGuestDocs = (documents) =>{
        const processedDocs = [];
        const { docBaseUrl } = this.props;

        for(const doc of documents){
            if(doc.key){
                let docKey = doc.key;

                if(doc.key.includes(docBaseUrl)){
                    docKey = doc.key.split(`${docBaseUrl}/`)?.[1];
                }

                processedDocs.push({
                    ...doc,
                    key: docKey
                });
            }
        }
        return processedDocs;
    }
 
    componentWillUnmount() {
        if (!this.props.guestData.documents || this.props.guestData.documents.length === 0) {
            let element = this.dropRef;
            //element.removeEventListener('dragenter', this.handleDragIn);
            //element.removeEventListener('dragleave', this.handleDragOut);
            element.removeEventListener('dragover', this.handleDrag);
            element.removeEventListener('drop', this.handleDrop);
            // element.removeEventListener('click',()=>{
            //     this.inputRef.click();
            // });
        }
    }

    handleFileUpload = event =>{
        //console.log('event', event);
        const { guestData } = this.props;
        const files = event.target.files;
        this.props.uploadHandler(files, guestData._id);
    }

    handleDeleteDoc = (index) => {
        let info = cloneDeep(this.state.docInfo);
        info[index].delete = true;
        this.setState({ docInfo: [ ...info ] });
    }

    handleCancelDelete = (index) => {
        let info = cloneDeep(this.state.docInfo);
        info[index].delete = false
        this.setState({ docInfo: [ ...info ] })
    }

    handleConfirmDeleteDoc = (index) => {
        let guestData = cloneDeep(this.props.guestData);
        
        // if(guest.documents && guest.documents.length > 0)
        //     guest.documents.splice(index, 1)
            
        // this.props.handleDelete(guest)

        const guestDocs = []

        this.state.docInfo.forEach((doc, docIndx) =>{

            if(docIndx == index) return;

            const data = { ...doc };
            delete data.delete;
            guestDocs.push(data);
        });

        guestData.documents = [ ...guestDocs ];
        this.props.handleDelete(guestData);
    }

    render(){
        const { classes, guestData, guestIndex, docBaseUrl, t } = this.props;
        const handleDeleteDoc = this.handleDeleteDoc
        const handleConfirmDeleteDoc = this.handleConfirmDeleteDoc
        const handleCancelDelete = this.handleCancelDelete


        return(
            <Box mb={5}>
                { (!guestData.documents || guestData.documents.length === 0 || this.state.docInfo.length === 0) && <label htmlFor={`upload-new-id-${guestData._id}`}>
                    <Box
                        ref = {el => this.dropRef = el} 
                        className={`${classes.dndContainer}`}
                    >
                        <img src="/images/icons/cloud_upload.png" width="120px" height="120px" />

                        <Box mt={1} color="#666666" fontSize="1.2rem" fontWeight="bold">{t('onlineCheckin.doc.browse')}</Box>
                        <Box mt={1} fontSize="1.1rem" color="gray">{t('onlineCheckin.doc.drag')}</Box>
                    </Box>
                </label>}
                <input 
                    type="file"
                    id={`upload-new-id-${guestData._id}`}
                    style={{display: "none"}}
                    onChange={this.handleFileUpload}
                    accept="image/*,.pdf"
                />
                { this.state.docInfo.length > 0 && (
                    <Box margin="10px auto">
                        {this.state.docInfo.map((doc, index) => {
                            return (
                                <div className={classes.imageRow} key={index}>
                                    <div className={classes.imageContainer}>
                                        <object data={`${docBaseUrl}/${doc.key}`} type="image/png" style={{width: '100%'}}>
                                            <img src="images/icons/file.png" alt="Uploaded file"/>
                                        </object>
                                    </div>
                                    <div className={classes.iconHolder}>
                                        {!doc.delete && 
                                            <div className={classes.icon} onClick={() => handleDeleteDoc(index)} >
                                                <img src="/images/icons/bin.png" />
                                            </div>
                                        }
                                        <ConfirmForm
                                            title="Delete Room?"
                                            open={doc.delete}
                                            setOpen={() => {}}
                                            onClose={() => handleCancelDelete(index)}
                                            onConfirm={() => handleConfirmDeleteDoc(index)}
                                            >
                                            Are you sure you would like to delete this document?
                                        </ConfirmForm>
                                    </div>
                                </div>
                            )
                        })}
                        <form>
                            <input 
                                type="file"
                                id={`outlined-button-file-${guestData._id}`}
                                style={{display: "none"}}
                                onChange={this.handleFileUpload}
                                accept="image/*,.pdf"
                            />
                            <label htmlFor={`outlined-button-file-${guestData._id}`}>
                                <a className={classes.docLink} target="_blank">{t('onlineCheckin.doc.uploadAnother')}</a>
                            </label>
                        </form>
                    </Box>
                )}
            </Box>
        );
    }
}

export default withStyles(styles)(UploadItem);