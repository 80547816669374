import React, { Component } from 'react';
import moment from "moment-timezone";
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import CustomerDetailForm from './customerDetailForm';
import PaymentForm from './payment';
import { validateTextForHTMLInjection, validateEmail } from '../helper';
import { validateTelephone } from '../helper';
import CheckboxSection from './payment/CheckboxSection';
import Button from '@material-ui/core/Button';


import http from "../../../redux/utils/http";
import { 
    showBookingLoader, 
    hideBookingLoader, 
    addReservationDetails 
} from '../../../redux/actions/bookingEngine';

const formType = {
    type1: 1,
    type2: 2
};

const formHelper = {
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    countryCodeError: false,
    telephoneError: false
}

const styles = theme =>({
    buttonContainer:{
        display: 'flex', 
        justifyContent: 'flex-end', 
        marginTop: '30px',
    },
    checkoutBtn:{
        width: '100%',
        backgroundColor: '#5C5C5C',
        color: '#fff',
        textTransform: 'capitalize',
        padding: "6px 20px",
        minWidth: '100px',
        marginBottom: '30px',

        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },

        '&:hover': {
            backgroundColor: '#5C5C5C',
            color: '#fff'
        }
    },
    mainContentCard: {
        backgroundColor: '#fff',
        borderRadius: '20px',
        padding: '20px',
        boxShadow: '0 0 8px 0px #dedede',
        marginTop: '50px'
    },
});
class Checkout extends Component{
    state ={
        activeFormType: formType.type1,
        firstName: '',
        lastName: '',
        email: '',
        countryCode: '',
        telephone: '',
        nationality: '',
        timeOfArrival: '00:00',
        specialRequest: '',
        termsAndCondtionChecked: false,
        marketingUpdatesChecked: false,
        termsAndConditionError: false,
        paymentError: '',
        ...formHelper
    };

    componentDidMount(){
        window.scrollTo(0, 0);
        const { timezone, isPhoneNumberMandatory } = this.props
        const timeOfArrival = moment.tz(new Date(), timezone).format('HH:mm')
        this.setState({ timeOfArrival, isPhoneNumberMandatory })
    }

    setActiveFormTypeHandler = type =>{
        this.setState({ activeFormType: type });
    }

    onChangeHandler = event =>{
        const inputVal = event.target.value;
        const inputName = event.target.name; 

        this.setState({
            [inputName]: inputVal,
            [`${inputName}Error`]: !inputVal.trim() ? true : false
        });
    }

    validateCustomerDetails = () => {
        const { firstName, lastName, email, telephone, countryCode, isPhoneNumberMandatory} = this.state;
        let formInputStatus = { ...formHelper };
        let validationSuccess = true;

        if(
            !firstName.trim() || validateTextForHTMLInjection(firstName)
        ){
            formInputStatus.firstNameError = true;
            validationSuccess = false;
        }

        if(
            !lastName.trim() || validateTextForHTMLInjection(lastName)
        ){
            formInputStatus.lastNameError = true;
            validationSuccess = false;
        }

        if(!email.trim() || !validateEmail(email)){
            formInputStatus.emailError = true;
            validationSuccess = false;
        }

        if (isPhoneNumberMandatory) {
            if (!telephone.trim() || !validateTelephone(telephone)) {
                formInputStatus.telephoneError = true;
                validationSuccess = false;
            }

            if (!countryCode.trim()) {
                formInputStatus.countryCodeError = true;
                validationSuccess = false;
            }
        } else {
            if (countryCode.trim() && (!telephone.trim() || !validateTelephone(telephone))) {
                formInputStatus.telephoneError = true;
                validationSuccess = false;
            }

            if (telephone.trim() && !countryCode.trim()) {
                formInputStatus.countryCodeError = true;
                validationSuccess = false;
            }
        }

        this.setState({ ...formInputStatus });
        return validationSuccess;
    }

    validateTermsAndConditions = () =>{
        const { termsAndCondtionChecked } = this.state;
        const { terms } = this.props

        if(terms?.length > 0) {
            if(!termsAndCondtionChecked){
                this.setState({ termsAndConditionError: true });
                return false;
            }
        }

        return true;
    }

    fetchBookingData = () =>{
        const { 
            propertyID,
            chargableAmount,
            checkInDate,
            checkOutDate,
            accommodationList
        } = this.props;
        
        const { 
            firstName,
            lastName,
            email,
            countryCode,
            telephone,
            nationality,
            timeOfArrival,
            specialRequest
        } = this.state;

    return {
            guest:{
                firstName,
                lastName,
                email,
                timeOfArrival,
                phone: `+${countryCode.trim()}${telephone.trim()}`,
                country: nationality
            },
            reservation: {
                checkIn: checkInDate,
                checkOut: checkOutDate,
                accommodationList,
                guestComments: specialRequest.trim()
            },
            propertyID,
            chargableAmount
        }
    }

    // Checkout function without collect card...
    proceedWithoutCard = () =>{
        
        this.setState({ paymentError: '' });

        if(!this.validateCustomerDetails()) return;

        if(!this.validateTermsAndConditions()) return;

        this.props.dispatch(showBookingLoader());

        const {
            guest,
            reservation,
            propertyID,
            chargableAmount
        } = this.fetchBookingData();

        const { isKioskFlow, nextStep, dispatch, terms } = this.props;

        const {
            termsAndCondtionChecked,
            marketingUpdatesChecked
        } = this.state;

        const postData = {
            // stripe: { ...stripeData, chargableAmount },
            guest,
            reservation,
            propertyID,
            agreeTerms: (terms?.length > 0) ? termsAndCondtionChecked : true,
            agreeUpdates: marketingUpdatesChecked,
            // channexCardToken,
            isKiosk: isKioskFlow 
        };

        http.post(`/api/bookEngine/payment`, postData)
        .then(response =>{

            if(response.data){
                this.props.dispatch(hideBookingLoader());
                const data = {
                    ...response.data, 
                    username: this.fetchBookingData().guest.firstName
                }
                dispatch(addReservationDetails(data));
                nextStep();
            }

        }).catch(errorResult =>{
            let errorMessage =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            console.log('server error ', errorMessage);

            if(errorMessage) this.setState({ paymentError: errorMessage });

            this.props.dispatch(hideBookingLoader());
        });
    }

    checkBoxHandler = event =>{
        const inputName = event.target.name;
        const checkedStatus = event.target.checked;
        if (inputName == 'termsAndCondtionChecked') {
            this.setState({
                [inputName]: checkedStatus,
                termsAndConditionError: !checkedStatus
            });
            return;
        }
        this.setState({ [inputName]: checkedStatus });
    }

    render(){
        const { classes, nextStep, stripeAccount, t, isCollectCard, isCollectPayment, terms, timezone } = this.props;
        return(
            <>
                <CustomerDetailForm 
                    {...this.state}
                    onInputChangeHandler ={this.onChangeHandler} 
                    setActiveFormType = {this.setActiveFormTypeHandler}
                    t = {t}
                />

                {isCollectCard && (
                    <PaymentForm 
                        validateCustomerDetails = {this.validateCustomerDetails}
                        validateTermsAndConditions = {this.validateTermsAndConditions}
                        fetchBookingData = {this.fetchBookingData}
                        proceedToPayment = {this.proceedToPayment}
                        isCollectPayment = {isCollectPayment}
                        stripeAccount ={stripeAccount}
                        nextStep = {nextStep}
                        termsConditions={terms}
                        t = {t}
                    />
                )}

                {!isCollectCard && (
                    <div className={classes.mainContentCard}>
                        <CheckboxSection 
                            {...this.state}
                            t={t}
                            termsConditions={terms}
                            checkBoxHandler={this.checkBoxHandler}
                        />
                    </div>
                )}

                {!isCollectCard && (
                    <div className={classes.buttonContainer}>
                        <Button
                            id="cbe-checkout"
                            type="button"
                            className={classes.checkoutBtn}
                            onClick={this.proceedWithoutCard}
                        >
                            Checkout
                        </Button>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = state =>{
    const { bookingEngine, kiosk } = state;
    const { bookingDetails, bookingCostInfo, propertyDetails } = bookingEngine;
    const accommodationList = [];

    Object.keys(bookingDetails).map(roomTypeID =>{
        accommodationList.push({
            roomTypeID,
            ratePlanID: bookingDetails[roomTypeID].ratePlanID,
            quantity: bookingDetails[roomTypeID].noOfBedsBooked
        });
    });

    return{
        checkInDate: bookingEngine.checkInDate,
        checkOutDate: bookingEngine.checkOutDate,
        accommodationList,
        chargableAmount: bookingCostInfo.chargableNow,
        stripeAccount: propertyDetails.stripeAccount || null,
        isKioskFlow: kiosk.mode,
        timezone:  bookingEngine.propertyDetails.timezone
    }
}

export default withStyles(styles)(connect(mapStateToProps)(Checkout));
