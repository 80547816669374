import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import clsx from 'clsx';
import pluralize from 'pluralize';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles(theme =>({
    root: {
        margin: '0 auto',
        paddingTop: '60px',
        width: '75%',
        [theme.breakpoints.down('sm')]: {
           width: '100%'
        },
    },
    personalInfoSection:{
        marginTop: '20px',
    },
    title:{
        color: '#333333',
        fontSize: '2.4rem',
        marginBottom: 20,
    },
    roomsInfo: {
        padding: '20px 0px',
        '& .roomsContainer':{
        },
        '& .roomsContainer div':{
            fontSize: '1.3rem',
            marginBottom: '15px',
        },
        '& .title':{
            color: '#666666',
            fontSize: '1.3rem',
            marginBottom: 20,
        }
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '-450px',
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            marginTop: '-400px',
        }
    },
    image: {
        height: '950px',
        width: 'auto',
        objectFit: 'cover',
    },
    buttonContainer:{
        display: 'flex',
        alignItems: 'center',
        paddingTop: '30px',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    continueBtn:{
        width: '100%',
        borderRadius: '5px',
        background: '#000',
        color: '#fff',
        fontSize: '1.6rem',
        height: 80,
        marginTop: 10,
        padding: '10px',
        fontWeight: 'bold',
        '&:hover':{
            background: '#000000',
            color: '#FFFFFF',
        }
    },
    customText: {
        color: '#7B7B7B',
        fontSize: '1.3rem',
    }
}));

const CheckinSuccess = props =>{
    const classes = useStyles();
    const { kioskConfig, isKioskFlow, summaryList, handleResetKiosk, accommodationList } = props;

    return(
        <>
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12} sm={6}></Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Box className={classes.personalInfo}>
                            <div className={classes.title}>
                                Success!!
                            </div>
                        </Box>
                        
                        <div className={classes.roomsInfo}>
                            <div className="title">{`Please take note of the ${pluralize('room', summaryList.length !== 1)} you will be staying in`}</div>
                            <div className="roomsContainer">
                                {summaryList.map((room, index) =>(
                                    <div key={index}>{room.roomName} {(room.bedName.length > 0) ? `- ${room.bedName}` : ''}</div>
                                ))}
                            </div>
                        </div>

                        {kioskConfig && kioskConfig?.customPage?.text && (
                            <div className={classes.customText}>
                                {ReactHtmlParser(kioskConfig.customPage.text)}
                            </div>
                        )}

                        {isKioskFlow && <Box className={classes.buttonContainer}>
                            <Button className={classes.continueBtn} onClick={handleResetKiosk}>Back to Home</Button>
                        </Box>}
                    </Grid>
                </Grid>

            </div>        


            <div className={classes.imageContainer}>
                <img className={classes.image} src="/images/illustration-success.png" />
            </div>
        </>
    )
}

const mapStateToProps = state =>{
    const { kiosk, onlineCheckin } = state;

    const accommodationList = onlineCheckin.accommodationList;
    const roomTypes = onlineCheckin.roomTypes
    const rooms = onlineCheckin.rooms;
    const beds = onlineCheckin.beds;

    const summaryList = accommodationList.map(data=>{
        let check
        if(typeof(data.roomTypeID) === 'object') {
            check = data.roomTypeID._id
        }
        else
            check = data.roomTypeID
        return{
            _id: data._id,
            roomName: rooms[data.roomID].name,
            bedName: (roomTypes[check].dormOrPrivate === 'dorm') ? beds[data.bedID].name : ''
        }
    });

    return{
        kioskConfig: kiosk.config,
        isKioskFlow: Boolean(kiosk.mode),
        summaryList
    }
}

export default connect(mapStateToProps)(CheckinSuccess);