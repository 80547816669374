const CURRENCY = {
  INR: {
    currency: "Indian Rupee",
    code: 'INR',
    symbol: '₹',
    space: false,
    symbolplacement: 'left',
    decimalplaces: 2,
    thousandseparater: ',',
    fractionalseparator: '.',
    decimal: true,
    denomination: [
      { value: '1', label: '₹1' },
      { value: '2', label: '₹2' },
      { value: '5', label: '₹5' },
      { value: '10', label: '₹10' },
      { value: '20', label: '₹20' },
      { value: '50', label: '₹50' },
      { value: '100', label: '₹100' },
      { value: '200', label: '₹200' },
      { value: '500', label: '₹500' },
      { value: '2000', label: '₹2000' }
    ]
  },
  EUR: {
    currency: "Euro",
    code: 'EUR',
    symbol: '€',
    decimalplaces: 2,
    countrybasedformatting: true,
    countryinfo: {
      "Austria": {
        space: true,
        symbolplacement: 'right',
        thousandseparater: '.',
        fractionalseparator: ',',
        decimal: true,
      },
      "Belgium": {
        space: false,
        symbolplacement: 'left',
        thousandseparater: '.',
        fractionalseparator: ',',
        decimal: true,
      },
      "Catalonia": {
        space: true,
        symbolplacement: 'right',
        thousandseparater: '.',
        fractionalseparator: ',',
        decimal: true,
      },
      "Finland": {
        space: true,
        symbolplacement: 'right',
        thousandseparater: ' ',
        fractionalseparator: ',',
        decimal: true,
      },
      "France": {
        space: true,
        symbolplacement: 'right',
        thousandseparater: ' ',
        fractionalseparator: ',',
        decimal: true,
      },
      "Germany": {
        space: true,
        symbolplacement: 'right',
        thousandseparater: '.',
        fractionalseparator: ',',
        decimal: true,
      },
      "Greece": {
        space: false,
        symbolplacement: 'left',
        thousandseparater: '.',
        fractionalseparator: ',',
        decimal: true,
      },
      "Ireland": {
        space: false,
        symbolplacement: 'left',
        thousandseparater: ',',
        fractionalseparator: '.',
        decimal: true,
      },
      "Italy": {
        space: false,
        symbolplacement: 'left',
        thousandseparater: '.',
        fractionalseparator: ',',
        decimal: true,
      },
      "Luxembourg": {
        space: false,
        symbolplacement: 'left',
        thousandseparater: '.',
        fractionalseparator: ',',
        decimal: true,
      },
      "Netherlands": {
        space: false,
        symbolplacement: 'left',
        thousandseparater: '.',
        fractionalseparator: ',',
        decimal: true,
      },
      "Portugal": {
        space: true,
        symbolplacement: 'right',
        thousandseparater: ' ',
        fractionalseparator: ',',
        decimal: true,
      },
      "Spain": {
        space: true,
        symbolplacement: 'right',
        thousandseparater: '.',
        fractionalseparator: ',',
        decimal: true,
      },
    },
    denomination: [
      { value: '0.05', label: '5c' },
      { value: '0.1', label: '10c' },
      { value: '0.2', label: '20c' },
      { value: '0.5', label: '50c' },
      { value: '1', label: '€1' },
      { value: '2', label: '€2' },
      { value: '5', label: '€5' },
      { value: '10', label: '€10' },
      { value: '20', label: '€20' },
      { value: '50', label: '€50' },
      { value: '100', label: '€100' },
      { value: '200', label: '€200' },
      { value: '500', label: '€500' }
    ]
  },
  GBP: {
    currency: "Great Britain Pound",
    code: 'GBP',
    symbol: '£',
    space: false,
    symbolplacement: 'left',
    decimalplaces: 2,
    thousandseparater: ',',
    fractionalseparator: '.',
    decimal: true,
    denomination: [
      { value: '0.01', label: '1p' },
      { value: '0.02', label: '2p' },
      { value: '0.05', label: '5p' },
      { value: '0.1', label: '10p' },
      { value: '0.2', label: '20p' },
      { value: '0.5', label: '50p' },
      { value: '1', label: '£1' },
      { value: '2', label: '£2' },
      { value: '5', label: '£5' },
      { value: '10', label: '£10' },
      { value: '20', label: '£20' },
      { value: '50', label: '£50' }
    ]
  },
  USD: {
    currency: "United States Dollar",
    code: 'USD',
    symbol: '$',
    symbolplacement: '',
    decimalplaces: 2,
    thousandseparater: ',',
    fractionalseparator: '.',
    decimal: true,
    denomination: [
      { value: '1', label: '$1' },
      { value: '2', label: '$2' },
      { value: '5', label: '$5' },
      { value: '10', label: '$10' },
      { value: '20', label: '$20' },
      { value: '50', label: '$50' },
      { value: '100', label: '$100' }
    ]
  },
  AUD: {
    currency: "Australian Dollar",
    code: 'AUD',
    symbol: '$',
    symbolplacement: 'left',
    space: true,
    thousandseparater: ',',
    fractionalseparator: '.',
    decimalplaces: 2,
    decimal: true,
    denomination: [
      { value: '0.05', label: '5c' },
      { value: '0.1', label: '10c' },
      { value: '0.2', label: '20c' },
      { value: '0.5', label: '50c' },
      { value: '1', label: '$1' },
      { value: '2', label: '$2' },
      { value: '5', label: '$5' },
      { value: '10', label: '$10' },
      { value: '20', label: '$20' },
      { value: '50', label: '$50' },
      { value: '100', label: '$100' }
    ]
  },
  CAD: {
    currency: "Canadian dollar",
    code: 'CAD',
    symbol: '$',
    symbolplacement: 'left',
    decimalplaces: 2,
    space: true,
    thousandseparater: ',',
    fractionalseparator: '.',
    decimal: true,
    denomination: [
      { value: '5', label: 'C$5' },
      { value: '10', label: 'C$10' },
      { value: '20', label: 'C$20' },
      { value: '50', label: 'C$50' },
      { value: '100', label: 'C$100' }
    ]
  },
  COP: {
    currency: "Colombian peso",
    code: 'COP',
    symbol: '$',
    symbolplacement: 'left',
    decimalplaces: 2,
    space: true,
    thousandseparater: ',',
    fractionalseparator: '.',
    decimal: true,
    denomination: [
      { value: '50', label: '$50' },
      { value: '100', label: '$100' },
      { value: '200', label: '$200' },
      { value: '500', label: '$500' },
      { value: '1000', label: '$1000' },
      { value: '2000', label: '$2000' },
      { value: '5000', label: '$5000' },
      { value: '10000', label: '$10000' },
      { value: '20000', label: '$20000' },
      { value: '50000', label: '$50000' }
    ]
  },
  CHF: {
    currency: "Confoederatio Helvetica Franc",
    code: 'CHF',
    symbol: 'SFr.',
    symbolplacement: 'left',
    decimalplaces: 2,
    space: true,
    thousandseparater: "'",
    fractionalseparator: '.',
    decimal: true,
    denomination: [
      { value: '10', label: 'SFr.10' },
      { value: '20', label: 'SFr.20' },
      { value: '50', label: 'SFr.50' },
      { value: '100', label: 'SFr.100' },
      { value: '200', label: 'SFr.200' },
      { value: '1000', label: 'SFr.1000' }
    ]
  },
  NZD: {
    currency: "New Zealand dollar",
    code: 'NZD',
    symbol: '$',
    symbolplacement: 'left',
    decimalplaces: 2,
    space: true,
    thousandseparater: ',',
    fractionalseparator: '.',
    decimal: true,
    denomination: [
      { value: '0.1', label: '10c' },
      { value: '0.2', label: '20c' },
      { value: '0.5', label: '50c' },
      { value: '1', label: '$1' },
      { value: '2', label: '$2' },
      { value: '5', label: '$5' },
      { value: '10', label: '$10' },
      { value: '20', label: '$20' },
      { value: '50', label: '$50' },
      { value: '100', label: '$100' }
    ]
  },
  JPY: {
    currency: "Japanese yen",
    code: 'JPY',
    symbol: '¥',
    symbolplacement: '',
    decimalplaces: 2,
    space: true,
    thousandseparater: ',',
    fractionalseparator: '',
    decimal: false,
    denomination: [
      { value: '1', label: '¥1' },
      { value: '5', label: '¥5' },
      { value: '10', label: '¥10' },
      { value: '50', label: '¥50' },
      { value: '100', label: '¥100' },
      { value: '500', label: '¥500' },
      { value: '1000', label: '¥1000' },
      { value: '2000', label: '¥2000' },
      { value: '5000', label: '¥5000' },
      { value: '10000', label: '¥10000' }
    ]
  },
  ARS: {
    currency: "Argentine Peso",
    code: 'ARS',
    symbol: '$',
    symbolplacement: 'left',
    decimalplaces: 2,
    thousandseparater: '.',
    fractionalseparator: ',',
    decimal: true,
    space: true,
    denomination: [
      { value: '1', label: 'AR$1' },
      { value: '2', label: 'AR$2' },
      { value: '5', label: 'AR$5' },
      { value: '10', label: 'AR$10' },
      { value: '20', label: 'AR$20' },
      { value: '50', label: 'AR$50' },
      { value: '100', label: 'AR$100' },
      { value: '200', label: 'AR$200' },
      { value: '500', label: 'AR$500' },
      { value: '1000', label: 'AR$1000' },
    ]
  },
}

module.exports = {
  CURRENCY
}