import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

import { ElementsConsumer } from '@stripe/react-stripe-js';
import http from "../../../../redux/utils/http";
import { 
    showCheckinLoader, 
    hideCheckinLoader, 
    paymentSuccess, 
    markReservationStatus ,
    saveChannexToken
} from '../../../../redux/actions/onlineCheckin';

import CheckboxSection from './CheckboxSection';
import { fetchSessionToken } from '../../../../redux/actions/bookingEngine';

const styles = theme =>({
    title: {
        color: '#333333',
        fontSize: '2.4rem',
        marginTop: 80,
    },
    cardIframeContainer: {
        background: 'transparent',
        position: 'relative',
        height: '300px',
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '70%',
        },
    },
    cardCaptureIframe:{
        border: '0',
        height: '100%',
        left: '0px',
        position: 'absolute',
        top: '0px',
        width: '100%',
        // [theme.breakpoints.down('sm')]: {
        //     top: '22px',
        //     left: '5px',
        //     width: '97%'
        // }
    },
    buttonContainer:{
        display: 'flex', 
        justifyContent: 'flex-end', 
        marginTop: '30px',
    },
    checkoutBtn:{
        width: '100%',
        backgroundColor: '#5C5C5C',
        color: '#fff',
        textTransform: 'capitalize',
        padding: "6px 20px",
        minWidth: '100px',

        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },

        '&:hover': {
            backgroundColor: '#5C5C5C',
            color: '#fff'
        }
    },
    footerContainer:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    footerItems:{
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#B7B7B7'
    },
    footerIcons:{
        width: '100px',
        height: '50px',
    },
    secureImage: {
        height: '100%',
        width: '100%',
    },
    submitBtnContainer:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingTop: '30px',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
        '& .label':{
            fontSize: '15px',
            fontWeight: 'bold',
            marginRight: '20px'
        }
    },
    continueBtn:{
        width: '100%',
        borderRadius: '5px',
        background: '#000',
        color: '#fff',
        fontSize: '1.6rem',
        height: 80,
        marginTop: 10,
        padding: '10px',
        fontWeight: 'bold',
        '&:hover':{
            background: '#000000',
            color: '#FFFFFF',
        }
    },
    inputLabel: {
        fontWeight: 'bold',
        color: '#000',
        fontSize: '13px',
        color: 'gray'
    },
    formControl: {
        width: '100%'
    },
    selectInput:{
        boxShadow: '0 0 8px 0px #dedede',
        borderRadius: '5px',
        marginTop: '10px',
        '& fieldset': {
            border: 'none',
        },
        '&:hover fieldset': {
            border: 'none',
        },
        '&.Mui-error fieldset': {
            border: '1px solid #f44336'
        },
    },
    errorMessage: {
        color: '#f44336',
        // marginLeft: '14px',
        lineHeight: '20px',
        '&::first-letter':{
            textTransform: 'uppercase'
        }
    },

    addCardBtnConatiner:{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '30px',
        [theme.breakpoints.down("xs")]:{
            flexDirection: 'column',
            '& $addCardBtn':{
                width: '100%',
                margin: 'unset',
                marginBottom: '10px'
            }
        }
    },
    addCardBtn: {
        marginLeft: '10px'
    }
});

const PCI_PROXY_DOMAIN = 'https://pci.channex.io';

class Payment extends React.PureComponent{
    state = {
        cardCaptureIframeSrc: null,
        termsAndCondtionChecked: false,
        termsAndConditionError: false,
        marketingUpdatesChecked: false,
        paymentError: '',
        selectedCardToken: null,
        cardTokenExists: false,
        showAddCardForm: false
    }

    cardCaptureListener = event =>{
        
        if (event.origin !== PCI_PROXY_DOMAIN) {
            //console.log('rejected origin ',event.origin);
            return;
        }
        
        if (event.data.valid) {
           //console.log("card valid: ", event.data.valid);
        }

        if (event.data.success) {
            const cardData = event.data.card;
            this.props.dispatch(showCheckinLoader());
            this.saveChannexTokenHandler(cardData);
        }
    }

    initPaymentForm = () =>{
        this.props.dispatch(showCheckinLoader());
        fetchSessionToken().then(response=> {
            const channexSessionToken = response.data.session_token;
            
            this.setState({ 
                cardCaptureIframeSrc: `https://pci.channex.io/api/v1/capture_form?session_token=${channexSessionToken}&style=booking_engine`,
                showAddCardForm: true
            });
           
            window.addEventListener("message", this.cardCaptureListener);
            this.props.dispatch(hideCheckinLoader());
        }).catch(error => {
            console.log('error', error);
            let errorResult =
              error && error.response && error.response.data
                ? error.response.data
                : error;
            this.handlePaymentError(errorResult);
            this.props.dispatch(hideCheckinLoader());
        });
    }

    setMaskCardState = () =>{
        const { maskedCards } = this.props;
        if(maskedCards && maskedCards.length > 0){
            this.setState({ 
                cardTokenExists: true,
                selectedCardToken: maskedCards[0].card_token
            });
            
            this.hideAddCardForm();
            return;
        }
        this.initPaymentForm();
    }

    componentDidMount(){
       this.setMaskCardState();
    }

    componentDidUpdate = (prevProps) =>{
        const { maskedCards } = this.props;
        if(prevProps.maskedCards.length != maskedCards.length){
            this.setMaskCardState();
        }
    }

    handlePaymentError = errorMessage =>{
        const { dispatch } = this.props;
        this.setState({ 
            disablePayBtn: false,
            paymentError: errorMessage
        });
        setTimeout(() => this.setState({ paymentError: '' }), 10000);
        dispatch(hideCheckinLoader());
    }

    checkBoxHandler = event => {
        const inputName = event.target.name;
        const checkedStatus = event.target.checked;
        // if (inputName == 'termsAndCondtionChecked') {
        //     this.setState({
        //         [inputName]: checkedStatus,
        //         termsAndConditionError: !checkedStatus
        //     });
        //     return;
        // }
        this.setState({ [inputName]: checkedStatus });
    }

    submitHandler = () =>{
        const { cardTokenExists, selectedCardToken } = this.state;
        
        if(cardTokenExists && selectedCardToken){
            this.proceedToPayment(selectedCardToken);
            return;
        }
    }

    handleServerResponse = (response, channexCardToken) => {
        const { stripe, dispatch, nextStep, reservationID } = this.props;
        if (response.requires_action) {
            dispatch(hideCheckinLoader());

            // Use Stripe.js to handle the required card action
            stripe.handleCardAction(
                response.payment_intent_client_secret
            ).then(result => this.handleStripeJsResult(result, response.custID, channexCardToken));

        } else {
            // Show success message
            dispatch(paymentSuccess(response));
            dispatch(markReservationStatus(reservationID, nextStep));
            dispatch(hideCheckinLoader());
            //console.log('payment Successful');
        }
    }

    handleStripeJsResult = (result, custID, channexCardToken) =>{
        if (result.error) {
            this.handlePaymentError(result.error.message);
            // this.initPaymentForm();
            //console.log('errorAction ', result.error);
        } else {
            // The card action has been handled
            // The PaymentIntent can be confirmed again on the server
            const stripeData = {
                payment_intent_id: result.paymentIntent.id,
                custID
            };

            this.proceedToPayment(channexCardToken, stripeData);
        }
    }

    proceedToPayment = (channexCardToken, stripeData = {}) => {
        const {
            chargableAmount,
            reservationID,
            dispatch,
            isKioskFlow
        } = this.props;

        dispatch(showCheckinLoader());

        const {
            //termsAndCondtionChecked,
            marketingUpdatesChecked
        } = this.state;

        const postData = {
            stripe: { ...stripeData, chargableAmount },
            agreeTerms: true,
            agreeUpdates: marketingUpdatesChecked,
            channexCardToken,
            isKiosk: isKioskFlow
        };

        http.post(`/api/self/payment/${reservationID}`, postData)
        .then(paymentResponse =>{
            this.handleServerResponse(paymentResponse.data, channexCardToken);
        }).catch(errorResult =>{
            let errorMessage =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;

            this.handlePaymentError(errorMessage);
            //console.log('server error ', errorMessage);
        });
    }

    onInputChangeHandler = event =>{
        this.setState({ 
            selectedCardToken: event.target.value,
            paymentError: '' 
        });
    }

    addCardHandler = () =>{
        
        this.setState({ paymentError: '' });

        // Validating card holder input and terms and conditions checkbox...
        // const { termsAndCondtionChecked } = this.state;
        // if(!termsAndCondtionChecked){
        //     this.setState({ termsAndConditionError: true });
        //     return false;
        // }

        //Submit card Capture form...
        this.cardCaptureIframeRef.contentWindow.postMessage("submit", "https://pci.channex.io");
    }

    saveChannexTokenHandler = (cardData) =>{
        const { dispatch, reservationID } = this.props;
        const response = dispatch(saveChannexToken(reservationID, cardData, this.initPaymentForm));
        response.then(success =>{
            if(success) this.setState({ selectedCardToken: cardData.card_token });
        });
    }

    hideAddCardForm = () =>{
        this.setState({ 
            showAddCardForm: false, 
            termsAndCondtionChecked: false
        });
    }

    componentWillUnmount(){
        window.removeEventListener("message", this.cardCaptureListener);
    }

    render(){
        const { classes, isKioskFlow, maskedCards } = this.props;
        const { 
            cardCaptureIframeSrc, 
            selectedCardToken, 
            showAddCardForm, 
            cardTokenExists,
            paymentError
        } = this.state;

        return(
            <div>
                {cardTokenExists && !showAddCardForm && (
                    <Box mb={3}>
                        <InputLabel htmlFor="country" className={classes.inputLabel}>Select card</InputLabel>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                native
                                input={<OutlinedInput />}
                                fullWidth={true}
                                className={classes.selectInput}
                                margin="dense"
                                name="cardToken"
                                value={selectedCardToken}
                                onChange = { this.onInputChangeHandler }
                            >
                                {maskedCards.map((cardData, cardIndex) => (
                                    <option 
                                        key={`card_${cardIndex}`} 
                                        value={cardData.card_token} 
                                    >
                                        {cardData.card_number}
                                    </option>
                                ))}
                            </Select>
                            {paymentError && (
                                <FormHelperText className={classes.errorMessage}>{paymentError}</FormHelperText>
                            )}
                        </FormControl>
                    </Box>
                )}

                {!showAddCardForm && (
                    <div>
                        <Button variant="outlined" onClick={this.initPaymentForm}>Add Card</Button>
                    </div>
                )}
                
                {showAddCardForm && (
                    <>
                        <Box className={classes.title} mb={4} >Card details</Box>
                        <div className={classes.cardIframeContainer}>
                            {cardCaptureIframeSrc && (
                                <iframe
                                    ref={ ref => this.cardCaptureIframeRef = ref }
                                    id="cardCaptureIframe"
                                    height="300"
                                    scrolling="no"
                                    className={classes.cardCaptureIframe}
                                    src= { cardCaptureIframeSrc }>
                                </iframe>
                            )}
                        </div>

                        <div>
                            <div className={classes.footerContainer} >
                                <div className={classes.footerItems}>
                                    <div>
                                        <LockOutlinedIcon />
                                    </div>
                                    <div ml={2}>
                                        Secure purchase
                                    </div>
                                </div>
                                <div className={classes.footerItems} >
                                    <div className={classes.footerIcons}>
                                        <img src={"/images/masterCard.svg"} className={classes.secureImage} alt="Mastercard Securecode" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.submitBtnContainer}>
                            <Button 
                                variant="outlined" 
                                onClick={this.addCardHandler}
                                className={classes.continueBtn}
                            >
                                Submit
                            </Button>

                            {/* {cardTokenExists && (
                                <Button 
                                    variant="outlined" 
                                    onClick={this.hideAddCardForm}
                                    className={classes.addCardBtn}
                                >
                                    Cancel
                                </Button>
                            )} */}
                        </div>
                    </>
                )}

                {!showAddCardForm && (
                    <div className={classes.submitBtnContainer}>

                        <Button 
                            type="submit" 
                            className={classes.continueBtn}
                            onClick={this.submitHandler}
                        >
                            Complete Payment
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

const InjectedCheckoutForm = props => {
    return (
        <ElementsConsumer>
            {({ elements, stripe }) => (
                <Payment
                    elements={elements}
                    stripe={stripe}
                    {...props}
                />
            )}
        </ElementsConsumer>
    );
};

const mapStateToProps = state =>{
    return{
        reservationID: state.onlineCheckin.reservationID,
        maskedCards: state.onlineCheckin.maskedCards || [],
        chargableAmount: state.onlineCheckin.balance,
        isKioskFlow: Boolean(state.kiosk.mode)
    }
}

export default withStyles(styles)(connect(mapStateToProps)(InjectedCheckoutForm));