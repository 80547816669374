import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { withStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import { setMode } from '../../redux/actions/kiosk';

import Header from '../booking_engine/header';
import BookingNavigationInfo from '../booking_engine/bookingNavigationInfo';
import TravelDetails from '../booking_engine/travelDetails';
import RoomCategoryList from '../booking_engine/room_category_list/roomCategoryList';
import RatePlanList from '../booking_engine/rate_plan/ratePlanList';
import BookingCart from '../booking_engine/booking_summary/bookingCart';
import Checkout from '../booking_engine/checkout/checkout';

import { fetchReservationDetails } from '../../redux/actions/onlineCheckin';

const headerHeight = 60;
const navigationBlockHeight = 50;
const totalMinusHeight = headerHeight + navigationBlockHeight;

const styles = theme => ({
    root: {
        background: '#F8F9FB'
    },
    mainContainer: {
        paddingTop: headerHeight,
        minHeight: 'calc(130vh - 30px)',
        background: '#F8F9FB',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(130vh - 30px)',
            marginTop: '40px'
        }
    },
    mainContentHolder: {
        margin: '45px 0',
        background: '#F8F9FB'
        //paddingTop: totalMinusHeight,
        //paddingBottom: 35,
        // minHeight: `calc(100vh - ${totalMinusHeight}px)`,
    },
    mainContent: {
        width: '550px',
        borderRadius: '20px',
        margin: '0 auto',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
});

const ROUTE_PAGES = {
    '/': { nextUrl: 'room-category', prevUrl: '', step: 1 },
    '/room-category': { nextUrl: 'rate-plan', prevUrl: '', step: 2},
    '/rate-plan': { nextUrl: 'summary', prevUrl: 'room-category', step: 3},
    '/summary': { nextUrl: 'checkout', prevUrl: 'rate-plan', step: 4},
    '/checkout': { nextUrl: 'success', prevUrl: 'summary', step: 5},
    '/success': { nextUrl: 'success', prevUrl: 'checkout', step: 6},
}

class BookingEnginePage extends Component {

    googleAnalytics = (trackingID, options = {}) =>{
        ReactGA.initialize(
            trackingID,
            { ...options } 
        );
    }

    facebookPixel = (trackingID) =>{
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };
        ReactPixel.init(trackingID, advancedMatching, options);
        ReactPixel.pageView(); 	
    }

    componentDidMount(){
        const { googleAnalyticsID, fbPixelID  } = this.props;
        if(googleAnalyticsID) this.googleAnalytics(googleAnalyticsID);
        if(fbPixelID) this.facebookPixel(fbPixelID);
    }

    navigateToCheckin = () =>{
        const { match, dispatch, reservationDetails, history, checkInDate } = this.props;
        const newPath = match.url.replace('booking', 'checkin');
        const params = {
            name: reservationDetails.username,
            checkIn: checkInDate
        }
        dispatch(fetchReservationDetails(params, () => history.push(`${newPath}/personal-info`)));
    }

    navigateToStepHandler = (stepNo) =>{
        const { history, match } = this.props;
        let basePath = match.url;
        basePath = basePath[basePath.length - 1] == '/' ? basePath.slice(0, -1) : basePath;
        const currentRoute = Object.keys(ROUTE_PAGES).filter(route => ROUTE_PAGES[route].step == stepNo);

        if(currentRoute[0] == '/success'){
            this.navigateToCheckin();
            return;
        }

        history.push(`${basePath}${currentRoute}`);
        window.scrollTo(0,0);
    }

    nextStep = () =>{
        const currentPagePath = this.fetchCurrentPath();
        const activeStep = ROUTE_PAGES[currentPagePath].step;
        const gotoStep = activeStep + 1;
        this.navigateToStepHandler(gotoStep);
    }

    prevStep = () =>{
        const { history } = this.props;
        history.goBack();
    }

    fetchCurrentPath = () =>{
        const { location, match } = this.props;
        if(location.pathname == match.url) return '/';
        const path = location.pathname.replace(match.url,'');
        return path
    }

    handleResetKiosk = () =>{
        const { navigateTo, dispatch } = this.props;
        dispatch(setMode(0));
        navigateTo('');
    }

    getPaymentSettings = () =>{
        //Currently collect card and payment is set to true for kiosk flow og booking engine...
        const { bookEngineConfig } = this.props;
        const configOptions = {
            isCollectCard: Boolean(bookEngineConfig?.collectCard),
            isCollectPayment: Boolean(bookEngineConfig?.collectPayment),
            terms: bookEngineConfig?.terms
        }

        return { ...configOptions };
    }
    
    render(){
        const { match, classes, propertyDetails, history, bookEngineConfig } = this.props;
        
        const propertyImage = propertyDetails.images ? propertyDetails.images[propertyDetails.images.length - 1] : '';
        const paymentSettings = this.getPaymentSettings();
        const routeProps = {
            nextStep: this.nextStep,
            navigateToStep: this.navigateToStepHandler,
            propertyID: propertyDetails._id,
            ...paymentSettings
        }
        const currentPagePath = this.fetchCurrentPath()
        return(
            <div className={classes.root}>
                <Container className={`${classes.mainContainer} booking-main-containter`}>
                    <Header 
                        title={propertyDetails.name}
                        currency={propertyDetails.currency}
                        image = {propertyImage}
                        kiosk={true}
                        handleResetKiosk={this.handleResetKiosk}
                    />
                    { currentPagePath !== '/success' && <Box style={{marginTop: 85}}>
                        <BookingNavigationInfo 
                            activeStep={ ROUTE_PAGES[currentPagePath].step }
                            prevStepHandler = {this.prevStep}
                        />
                    </Box>}
                    <Box className={`${classes.mainContentHolder}`}>
                        <Box className={classes.mainContent}>
                            <Route path={`${match.url}`} exact render={props =><TravelDetails {...props} {...routeProps} />} />
                            <Route path={`${match.url}/room-category`} exact render={props =><RoomCategoryList {...props} {...routeProps} />} />
                            <Route path={`${match.url}/rate-plan`} exact render={props =><RatePlanList {...props} {...routeProps} />} />
                            <Route path={`${match.url}/summary`} exact render={props =><BookingCart {...props} {...routeProps} />} />
                            <Route path={`${match.url}/checkout`} exact render={props =><Checkout {...props} {...routeProps} />} />
                        </Box>
                    </Box>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { bookingEngine } = state;
    const propertyDetails = bookingEngine.propertyDetails ? bookingEngine.propertyDetails: {};
    const bookEngineConfig = bookingEngine?.bookEngineConfig || {};
    return{
        propertyDetails,
        reservationDetails: bookingEngine.reservationDetails,
        checkInDate: bookingEngine.checkInDate,
        bookEngineConfig
    };
};

export default withStyles(styles)(connect(mapStateToProps)(BookingEnginePage));