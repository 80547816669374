import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Select from 'react-select';

import Dialog from '../common/CustomDialog';
import Button from '../common/Button';
import IMAGES from '../../../constants/images';
import { toTitleCase } from '../../../utils/wordUtils';
import { DATE_FORMATS, fetchDateFormat } from '../../../utils/utility';
import { resetError, sendEmail } from '../../../redux/actions/reservations';

const useStyles = makeStyles(theme =>({
    root: {
        paddingBottom: "100px",
        '& .dialogBody': {
            minHeight: "calc(100vh - 245px)",
        },
        '& p': { margin: 'unset' },
    },
    footerConatiner: {
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        padding: "20px 30px",
        borderTop: '1px solid #e0e0e0',
        height: "100px",
        boxSizing: "border-box",
        [theme.breakpoints.down('xs')]:{
            padding: "15px 20px",
        }
    },
    sendBtn: {
        borderRadius: "5px",
        marginLeft: "10px",
        padding: "12px 20px",
        [theme.breakpoints.down('xs')]: {
            padding: "12px 10px",
            marginLeft: "5px",
        }
    },
    emailItem: {
        border: "1px solid #e0e0e0",
        padding: "20px",
        borderRadius: "10px",
        fontSize: "1.4rem",
        color: "#999999",
        marginBottom: '20px',
        lineHeight: '18px',
        '& p': {  marginBottom: "8px" },

        '& p:last-child': {  marginBottom: "0px" },
    },
    title: {
        fontSize: "1.2rem",
        fontWeight: 600,
        color: "#777777",
        marginBottom: "10px",
    },
    label: {
        color: "#305c8b",
        fontWeight: 600,
        fontSize: "1.3rem",
    },
    actionStatus: {
        display: "flex",
        fontSize: '1.4rem',
        color: "#999999",
        '& img': {
            width: "12px",
            height: "12px",
            padding: "5px",
            paddingLeft: 0,
        },
        '& span':{
            display: 'flex',
            alignItems: 'center',
            marginRight: '15px'
        }
    }
}));

const selectStyle = {
    container: (base, state) => ({
        ...base,
        flex: 1
    }),
    control: (base, state) => ({
        ...base,
        border: '1px solid #e0e0e0',
        borderRadius: '5px',
        boxShadow: 0,
        cursor: 'pointer',
        margin: "0px",
        height: '45px',
        '&:hover': { border: '1px solid #e0e0e0' },
    }),
    placeholder: (base, state) => ({
        ...base,
        fontSize: '1.5rem'
    }),
    // indicatorSeparator: (base, state) =>({
    //     display: 'none'
    // })
};

function getEmailTemplateOptions(emailTemplates){
    const templateOptions = [];
    const templateList = Object.entries(emailTemplates);

    if(templateList.length == 0){
        templateOptions.push({ label: 'No email templates setup', value: null });
        return templateOptions;
    }

    for(const [templateID, template] of templateList){
        if(template.isActive){ 
            templateOptions.push({ 
                label: template.name,
                 value: templateID 
            });
        }
    }

    return templateOptions
}

const EmailLogsModal = props =>{
    const classes = useStyles();
    const { closeModalHandler, reservation, emails, timezone, emailTemplates, dispatch, isLoading, errors } = props;
    const { guestName = '', guestComments = [], guestEmail = '' } = reservation;
    const templateOptions = getEmailTemplateOptions(emailTemplates);

    const [selectedTemplateID, setSelectedTemplateID] = useState(null);

    const onChangeSelect = data =>{
        if(!data){
            setSelectedTemplateID(null);
            return
        }
        setSelectedTemplateID(data.value);
    }

    const sendEmailHandler = () =>{
        const payload = {
            emailTemplateID: selectedTemplateID,
            reservationID: reservation._id
        }
        dispatch(sendEmail(payload))
            .then(success =>{
                if(success) setSelectedTemplateID(null); 
            })
    }

    const handleCloseError = () =>{
        dispatch(resetError('SEND_EMAIL'));
    }

    return (
        <Dialog 
            className={classes.root}
            headerTitle="Messages"
            closeModalHandler={closeModalHandler}
            isLoading={isLoading}
            errors={errors}
            handleCloseError={handleCloseError}
        >
            {/* -----------------GUEST COMMENTS----------------------------- */}
            {guestComments.map(commentInfo =>(
                <div className={classes.emailItem}>
                    <p className={classes.title}>
                        <span className={classes.label}>{toTitleCase(guestName)}</span> (Guest Comment)
                    </p>
                    <p>{commentInfo?.comment}</p>
                </div>
            ))}
            
            {/* -----------------------------GUEST EMAILS----------------------------- */}
            {guestEmail && emails.map(emailItem =>(

                <div className={classes.emailItem} key={emailItem._id}>
                    <p className={classes.title}>{fetchDateFormat(emailItem.createdAt, `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY}, ${DATE_FORMATS.TIME}`, timezone)}</p>
                    <p><span className={classes.label}>To:</span>  {emailItem.to}</p>
                    <p><span className={classes.label}>From:</span>  {emailItem.from}</p>
                    <p><span className={classes.label}>Subject:</span>  {emailItem.subject}</p>
                    
                    {emailItem.deliveryStatus && (
                        <p><span className={classes.label}>Delivery status:</span>  {emailItem.deliveryStatus}</p>
                    )}

                    {emailItem.deliveryStatus === 'delivered' && (
                        <div className={classes.actionStatus}>
                            {emailItem.openCount > 0 && <span><img src={IMAGES.ICONS.check} />Opened</span>}
                            {emailItem.clickCount > 0 && <span><img src={IMAGES.ICONS.check} />Clicked</span>}
                        </div>
                    )}

                </div>
            ))}

            {/* -----------------------------NO EMAILS FOUND MESSAGE----------------------------- */}
            {guestEmail && emails.length == 0 && (
                <div className={classes.emailItem}>
                    <p className={classes.title}>No system generated emails sent for this reservation</p>
                </div>
            )}

            {/* -----------------------------NO EMAIL ID FOUND MESSAGE----------------------------- */}
            {!guestEmail && (
                <div className={classes.emailItem}>
                    <p className={classes.title}>No Email ID found for the reservation</p>
                </div>
            )}

            {guestEmail && (
                <div className={classes.footerConatiner}>
                    <Select
                        styles={selectStyle}
                        placeholder="Select email template..."
                        options={templateOptions}
                        value={templateOptions.find(e => e.value === selectedTemplateID) || null}
                        onChange={onChangeSelect}
                        menuPlacement="top"
                        isClearable
                    />
                    <Button
                        className={classes.sendBtn}
                        disabled={!selectedTemplateID}
                        onClick={sendEmailHandler}
                    >
                        Send Email
                    </Button>
                </div>
            )}
        </Dialog>
    )
}

const mapStateToProps = state =>{
    const { reservationDetails, register, property, loading, error } = state;
    return {
        reservation: reservationDetails?.reservation || {},
        emails: reservationDetails?.emails || [],
        timezone: register.timezone || null,
        emailTemplates: property.emailTemplates || {},
        isLoading: loading.SEND_EMAIL,
        errors: error.SEND_EMAIL
    }
}

export default connect(mapStateToProps)(EmailLogsModal);