import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CardSection from '../../../online_checkin/Payment/stripe_form/CardSection';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { ElementsConsumer, CardNumberElement } from '@stripe/react-stripe-js';
import { showCheckinLoader, hideCheckinLoader } from '../../../../redux/actions/onlineCheckin';

const styles = theme => ({
    root:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingTop: '30px',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }
    },
    continueBtn:{
        borderRadius: '20px',
        backgroundColor: '#000',
        color: '#fff',
        padding: '10px',
        // marginTop: '5px',
        fontWeight: 'bold',
        padding: '7px 35px',
        border: '0.5px solid #000',
        '&:hover':{
            border: '0.5px solid #000',
            color: '#000',
            [theme.breakpoints.down('sm')]: {
                backgroundColor: '#000',
                color: '#fff',
            }
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            padding: '10px',
            fontSize: '15px',
            marginTop: '10px'
        }
    }
});

const formHelper = {
    cardNumberError: '',
    cardExpiryError: '',
    cardCvcError: '',
    cardHolderError: false,
    termsAndConditionError: false
}

class PaymentForm extends Component {
    state = {
        ...formHelper,
        cardHolder: '',
        termsAndCondtionChecked: false,
        marketingUpdatesChecked: false,
        disablePayBtn: false,
        paymentError: ''
    };

    disableFormAction = () =>{
        const { dispatch } = this.props;
        this.setState({ disablePayBtn: true });
        dispatch(showCheckinLoader());
    }

    handlePaymentError = errorMessage =>{
        const { dispatch } = this.props;
        this.setState({ 
            disablePayBtn: false,
            paymentError: errorMessage
         });
        dispatch(hideCheckinLoader());
    }

    onChangeHandler = event => {
        const inputName = event.target.name;
        const inputVal = event.target.value;
        this.setState({
            [inputName]: inputVal,
            [`${inputName}Error`]: !inputVal.trim() ? true : false
        });
    }

    checkBoxHandler = event => {
        const inputName = event.target.name;
        const checkedStatus = event.target.checked;
        if (inputName == 'termsAndCondtionChecked') {
            this.setState({
                [inputName]: checkedStatus,
                termsAndConditionError: !checkedStatus
            });
            return;
        }
        this.setState({ [inputName]: checkedStatus });
    }

    handleCardInputChange = event => {
        let errorMessage = ''
        if (event.error) {
            switch (event.elementType) {
                case 'cardNumber':
                    errorMessage = event.error.message.replace("Your ", "");
                    break;

                case 'cardExpiry':
                    errorMessage = event.error.message.replace("Your card's expiration", "Expiry");
                    break;

                case 'cardCvc':
                    errorMessage = event.error.message.replace("Your card's ", "");
                    break;
            }
        }
        this.setState({
            [`${event.elementType}Error`]: errorMessage
        });
    }

    validateFormInputs = () => {
        const { cardHolder, termsAndCondtionChecked } = this.state;
        const validationStatus = {
            cardHolderError: false,
            termsAndConditionError: false,
            success: true
        };

        // Validate cardHolder input.....
        if (!cardHolder.trim()) {
            validationStatus.cardHolderError = true;
            validationStatus.success = false;
        }

        // Validate terms and conditions check....
        if (!termsAndCondtionChecked) {
            validationStatus.termsAndConditionError = true;
            validationStatus.success = false;
        }

        this.setState({
            cardHolderError: validationStatus.cardHolderError,
            termsAndConditionError: validationStatus.termsAndConditionError
        });

        return validationStatus.success;
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ paymentError: '' });
        const { stripe, elements, validateCustomerDetails } = this.props;

        if (!stripe || !elements) return;

        // Validating card holder input and terms and conditions checkbox...
        if (!this.validateFormInputs()) return;

        this.disableFormAction();

        // Stripe create payment methode....
        stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: {
                // Include any additional collected billing details.
            },
        }).then(this.stripePaymentMethodHandler);
    };

    stripePaymentMethodHandler = result => {
        // if (result.error) {
        //     this.handlePaymentError('');
        //     return;
        // }

        const { 
            accommodationList, 
            guests, 
            totalAmount,
            checkIn,
            checkOut,
            reservationID,
            taxAmount,
            dispatch,
            nextStep
        } = this.props;

        dispatch({
            type: 'PAYMENT_SUCCESS',
            payload: {
                accommodationList,
                guests,
                reservationID,
                balance: 0,
                taxAmount,
                totalAmount,
                checkIn,
                checkOut,
            }
        });
        nextStep();
        dispatch(hideCheckinLoader());
    }

    render() {
        const { classes, stripe } = this.props;
        const { disablePayBtn } = this.state;
        return (
            <>
                <form onSubmit={this.handleSubmit}>
                    <CardSection
                        {...this.state}
                        onCardInputChange={this.handleCardInputChange}
                        onChangeHandler={this.onChangeHandler}
                        checkBoxHandler={this.checkBoxHandler}
                    />
                    <Box className={classes.root}>
                        <Box fontSize="15px" fontWeight="bold" mr={2}>
                            Step 5 of 5
                        </Box>
                        <Button 
                            type="submit" 
                            className={classes.continueBtn}
                            disabled={!stripe || disablePayBtn}
                        >
                            Complete Payment
                        </Button>
                    </Box>
                </form>
            </>
        );
    }
}

const InjectedCheckoutForm = props => {
    return (
        <ElementsConsumer>
            {({ elements, stripe }) => (
                <PaymentForm
                    elements={elements}
                    stripe={stripe}
                    {...props}
                />
            )}
        </ElementsConsumer>
    );
};

const mapStateToProps = state =>{
    return{
        reservationID: state.onlineCheckin.reservationID,
        chargableAmount: state.onlineCheckin.balance,
        accommodationList: state.onlineCheckin.accommodationList,
        guests: state.onlineCheckin.guests,
        totalAmount: state.onlineCheckin.totalAmount,
        taxAmount: state.onlineCheckin.taxAmount,
        checkIn: state.onlineCheckin.checkIn,
        checkOut: state.onlineCheckin.checkOut,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(InjectedCheckoutForm));