import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withTranslation } from 'react-i18next';
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';

import ReadMore from "../readMore";
import { getCurrency } from "../helper";

const secondaryColor = '#5C5C5C';

const useStyles = makeStyles(theme => ({
    mainContentCard: {
        borderRadius: '20px',
        padding: '20px',
        boxShadow: '0 2px 20px 0px #F0F0F0',
        marginBottom: "30px"
    },
    bookNowBtnHolder:{
        alignItems: 'center',
        display: "flex",
        flexDirection: "row-reverse",
        height: '100%',
        [theme.breakpoints.down("xs")]: {
            paddingBottom: '20px'
        }
    },
    bedsQuantity: {
        margin: '30px 0',
        alignItems: 'center',

        [theme.breakpoints.up('sm')]: {
            alignItems: 'flex-start',
        },
    },
    roomTypeName: {
        marginBottom: '10px'
    },
    roomBookBtn:{
        background: '#000000',
        boxShadow: 'none',
        borderRadius: "10px",
        fontWeight: 600,
        width: "100%",
        [theme.breakpoints.up('sm')]: {
            width: '110px',
        }
    },
    roomPriceOccupancy: {
        color: secondaryColor,
        marginTop: '20px'
    },
    roomPrice: {
        marginRight: '5px'
    },
    cancellationPolicy:{
        color: '#666666',
        fontSize: '1.2rem',
        fontWeight: 600
    },
    roomOccupancyType: {
        fontSize: '12px',
        alignSelf: 'center',
    },
    divider: {
        marginBottom: 15,
        marginTop: 15
    }
}));

const RatePlanListItem = ({ratePlanListData, currency, bookNowHandler, t}) => {
    const classes = useStyles();
    let policy = ''

    return (
        <>
            { ratePlanListData.map((data, index) => {

                if(!data.nonRefundable) {
                    if(data.cancellationPolicy) {
                        const cancPolicy = data.cancellationPolicy
                        if(cancPolicy.conditions && cancPolicy.conditions.length > 0)
                            policy = cancPolicy.conditions
                        else if (cancPolicy.percentChargeable && cancPolicy.duration)
                            policy = `${cancPolicy.percentChargeable} will be charged if cancelled ${cancPolicy.duration} days before arrival`
                        else
                            policy = ''
                    }
                }
                else {
                    policy = (data.nrPolicy && data.nrPolicy.length) ? data.nrPolicy : ''
                }

                return (
                    <Card key = {index} className={classes.mainContentCard}>
                        <Grid container spacing={5} className={classes.roomType}>
                            <Grid item xs={12} sm={8}>
                                <Typography component="div" >
                                    <Box variant="h5" mb={2} className={`${classes.roomTypeName}`} fontWeight="bold" fontSize="18px">
                                        {data.ratePlanName}
                                    </Box>

                                    {data.description && <ReadMore data = {data.description}/> }

                                    {policy && policy.length > 0 && <div>
                                        <Divider className={classes.divider} />
                                        <span className={classes.cancellationPolicy}>Cancellation Policy</span>
                                        <ReadMore data = {policy}/>
                                    </div>}
                                    
                                    <Typography component="div" className={classes.roomPriceOccupancy} fontWeight="bold" fontSize="18px" >
                                        <Box display="flex">
                                            <Box className={`${classes.roomPrice}`} fontWeight="bold">
                                                {getCurrency(currency, data.perNightCost)}
                                            </Box>
                                            <Box className={classes.roomOccupancyType}>
                                                {t('bookingEngine.roomCategory.perNight')}
                                            </Box>
                                        </Box>
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box className={classes.bookNowBtnHolder}>
                                    <Button id="cbe-bookNow" onClick={() => bookNowHandler(data)} width="100%" variant="contained" color="primary" className={classes.roomBookBtn}>{t('bookingEngine.roomCategory.bookNow')}</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                )
            })}
        </>
    );
}

export default withTranslation()(RatePlanListItem);