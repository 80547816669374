import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DATE_FORMATS, fetchDatePerTimezone, fetchNoOfNights } from '../../../../utils/utility';
import ManualSelectBed from '../manual-bed-select';
import { fetchRequiredKeyValue, validateEditReservationPayload } from '../Helper';

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '1.2rem',
        fontWeight: 600,
        color: '#329ef5'
    },
    spacing: {
        padding: '10px 0'
    },
}));


const ExtendOptions = (props) => {
    const classes = useStyles();
    const { data, range, view, options } = props;
    const [errorMessage, setErrorMessage] = React.useState('');
    const endDate = fetchDatePerTimezone(range.endDate).subtract(1, 'days').format(DATE_FORMATS.DEFAULT_DATE);

    React.useEffect(() => {
        setErrorMessage('')
    }, [range])

    const nights = fetchNoOfNights(data.checkOut, range.endDate);

    const submitDataHandler = (form) => {
        let accommodation = data?.items?.[data?.items?.length - 1];
        let accommodationID = accommodation ? accommodation.ID : null;
        let requiredKeys = ['bedID', 'roomID', 'roomTypeID', 'rate', 'date'];
        let items = fetchRequiredKeyValue([...form.items], requiredKeys)

        let isValidated = validateEditReservationPayload(items, requiredKeys);
        if (isValidated?.error) {
            setErrorMessage(isValidated.errorMessage)
            return
        }
        let payload = {
            accommodationID: accommodationID,
            extend: items
        }
        props.onSubmitHandler(payload)

    }

    return (
        <React.Fragment>
            {/* -------------------- Rate form for selected range -------------------- */}
            <div className={classes.label}>You are extending this booking by {nights || 0} nights</div>
            <div className={classes.spacing}></div>
            <ManualSelectBed
                view={view}
                data={data}
                options={options}
                errorMessage={errorMessage}
                range={{
                    startDate: data.checkOut,
                    endDate: endDate
                }}
                submitDataHandler={submitDataHandler}
            />
        </React.Fragment>
    )
}

export default ExtendOptions;