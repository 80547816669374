import React from 'react';
import { makeStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    color: '#d96977',
    fontSize: '1.2rem',
  },
  buttonHolder: {
    display: 'flex',
    marginLeft: 10,
    marginBottom: 4
  },
  roundbutton: {
    border: '1px solid #dddddd',
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    fontSize: '1.2rem',
    marginLeft: 10,
    padding: 5,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
}))


const ConfirmForm = (props) => {
  const classes = useStyles();
  const { children, open, setOpen, onClose, onConfirm } = props;
  return (
    open ? 
    <div className={classes.form}>
      <span className={classes.content}>{children}</span>
        <div className={classes.buttonHolder}>
          <CloseIcon className={classes.roundbutton} onClick={onClose} />
          <DoneIcon className={classes.roundbutton} onClick={() => {
            setOpen(false);
            onConfirm();
          }} />
        </div>
    </div> : <></>
  );  
};
export default ConfirmForm;