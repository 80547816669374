import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardSection from '../../../booking_engine/checkout/payment/cardSection';
import { ElementsConsumer, CardNumberElement } from '@stripe/react-stripe-js';
import { showBookingLoader, hideBookingLoader, addReservationDetails } from '../../../../redux/actions/bookingEngine';

const secondaryColor = '#5C5C5C';

const styles = theme => ({
    checkoutBtn: {
        width: '100%',
        backgroundColor: secondaryColor,
        color: '#fff',
        textTransform: 'capitalize',
        padding: "6px 20px",
        minWidth: '100px',

        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },

        '&:hover': {
            backgroundColor: secondaryColor,
            color: '#fff'
        }
    }
});

const formHelper = {
    cardNumberError: '',
    cardExpiryError: '',
    cardCvcError: '',
    cardHolderError: false,
    termsAndConditionError: false
}

class PaymentForm extends Component {
    state = {
        ...formHelper,
        cardHolder: '',
        termsAndCondtionChecked: false,
        marketingUpdatesChecked: false,
        disablePayBtn: false,
        paymentError: ''
    };

    disableFormAction = () =>{
        const { dispatch } = this.props;
        this.setState({ disablePayBtn: true });
        dispatch(showBookingLoader());
    }

    onChangeHandler = event => {
        const inputName = event.target.name;
        const inputVal = event.target.value;
        this.setState({
            [inputName]: inputVal,
            [`${inputName}Error`]: !inputVal.trim() ? true : false
        });
    }

    checkBoxHandler = event => {
        const inputName = event.target.name;
        const checkedStatus = event.target.checked;
        if (inputName == 'termsAndCondtionChecked') {
            this.setState({
                [inputName]: checkedStatus,
                termsAndConditionError: !checkedStatus
            });
            return;
        }
        this.setState({ [inputName]: checkedStatus });
    }

    handleCardInputChange = event => {
        let errorMessage = ''
        if (event.error) {
            switch (event.elementType) {
                case 'cardNumber':
                    errorMessage = event.error.message.replace("Your ", "");
                    break;

                case 'cardExpiry':
                    errorMessage = event.error.message.replace("Your card's expiration", "Expiry");
                    break;

                case 'cardCvc':
                    errorMessage = event.error.message.replace("Your card's ", "");
                    break;
            }
        }
        this.setState({
            [`${event.elementType}Error`]: errorMessage
        });
    }

    validateFormInputs = () => {
        const { cardHolder, termsAndCondtionChecked } = this.state;
        const validationStatus = {
            cardHolderError: false,
            termsAndConditionError: false,
            success: true
        };

        // Validate cardHolder input.....
        if (!cardHolder.trim()) {
            validationStatus.cardHolderError = true;
            validationStatus.success = false;
        }

        // Validate terms and conditions check....
        if (!termsAndCondtionChecked) {
            validationStatus.termsAndConditionError = true;
            validationStatus.success = false;
        }

        this.setState({
            cardHolderError: validationStatus.cardHolderError,
            termsAndConditionError: validationStatus.termsAndConditionError
        });

        return validationStatus.success;
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ paymentError: '' });
        const { stripe, elements, validateCustomerDetails } = this.props;

        if (!stripe || !elements) return;

        // Validating customer details form...
        if (!validateCustomerDetails()) {
            window.scrollTo(0, 0);
            return;
        }

        // Validating card holder input and terms and conditions checkbox...
        if (!this.validateFormInputs()) return;

        this.disableFormAction();

        // Stripe create payment methode....
        stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
            billing_details: {
                // Include any additional collected billing details.
            },
        }).then(this.stripePaymentMethodHandler);
    };

    stripePaymentMethodHandler = result => {
        // if (result.error) {
        //     this.handlePaymentError('');
        //     return;
        // }
        const { dispatch, nextStep, fetchBookingData } = this.props;
        const amount = fetchBookingData().chargableAmount;
        const response = {
            totalAmount: amount,
            amountPaid: amount,
            balance: 0,
            id: 'res123'
        };
        
        // Show success message
        const data = {...response, username: fetchBookingData().guest.firstName};
        dispatch(addReservationDetails(data));
        nextStep();
        dispatch(hideBookingLoader());
        //console.log('payment Successful');
    }

    render() {
        const { classes, stripe } = this.props;
        const { disablePayBtn } = this.state;
        return (
            <>
                <form onSubmit={this.handleSubmit}>
                    <CardSection
                        {...this.state}
                        onCardInputChange={this.handleCardInputChange}
                        onChangeHandler={this.onChangeHandler}
                        checkBoxHandler={this.checkBoxHandler}
                    />
                    <Box component="div" display="flex" justifyContent="flex-end" mt={3} >
                        <Button type="submit" disabled={!stripe || disablePayBtn} className={classes.checkoutBtn}>Pay Now</Button>
                    </Box>
                </form>
            </>
        );
    }
}

const InjectedCheckoutForm = props => {
    return (
        <ElementsConsumer>
            {({ elements, stripe }) => (
                <PaymentForm
                    elements={elements}
                    stripe={stripe}
                    {...props}
                />
            )}
        </ElementsConsumer>
    );
};

export default withStyles(styles)(connect(null)(InjectedCheckoutForm));
