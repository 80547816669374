import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import RadioGroup from '@material-ui/core/RadioGroup';

import Footer from '../common/Footer';
import CardSelect from './card-details/CardSelect';
import PaymentInfo from './PaymentInfo';
import { RadioButton } from '../common/Button';
import { humanise } from '../../../utils/utility';
import { TRANSACTION_MODE } from '../../../utils/constants';

const useStyles = makeStyles(theme =>({
    root: {
    
    },
    warning: {
        textAlign: "center",
        fontSize: "1.3rem",
        border: "1px solid #e0e0e0",
        borderRadius: "10px",
        padding: "10px",
        color: "#999999",
        backgroundColor: "#F2F2F2",
    },
    radioGroup: {
        alignItems: 'center'
    },
    radioBtn: {
        width: "380px",
        marginBottom: "20px",

        '& .MuiFormControlLabel-label':{
            fontSize: "1.3rem",
            textAlign: "center",
            padding: "15px",
        },

        [theme.breakpoints.down('xs')]: {
            width: "100%",
        }
    },
    error: {
        color: '#cd706e',
        fontSize: '1.4rem',
        textAlign: "center",
    },
}));

// const OTHER_MODES_FILTERS = [
//     'cash',
//     'card',
//     'Stripe',
//     'discount',
//     'surcharge'
// ];

const OTHER_MODE_ID = 'other';

const getDisplayInfo = (modeID) =>{
    switch(modeID) {
        case TRANSACTION_MODE.STRIPE:
            return {
                modeInfo: "Payment method: Stripe",
                nextBtnLabel: "Charge card" 
            }

        case TRANSACTION_MODE.CARD:
            return {
                modeInfo: "Payment method: Card",
                nextBtnLabel: "Charge card" 
            }

        case TRANSACTION_MODE.CASH:
            return {
                modeInfo: "Payment method: Cash",
                nextBtnLabel: "Collect cash" 
            }

        case OTHER_MODE_ID:
            return {
                modeInfo: "Choose a payment method below",
                nextBtnLabel: "Submit" 
            }

        default: 
            return { modeInfo: '', nextBtnLabel: 'Save' }
    }
}

const ChargeReservation = props =>{
    const classes = useStyles();
    const { 
        prevStep, 
        maskedCards, 
        transactionModeIDs, 
        transactionMode,
        paymentAmount,
        currency,
        selectedModeID, 
        cashDrawerOpenSessionID,
        submitTransactionHandler,
        showAddCardHandler,
        cashDrawerFeatureEnabled
    } = props;

    const [selectedCardToken, setSelectedCardToken] = useState(null);
    const [otherPaymentModes, setOtherPaymentModes] = useState([]);
    const [error, setError] = useState(false);
    const [otherSelectedModeID, setOtherSelectedModeID] = useState(null); 
    const [paymentMode, setPaymentMode] = useState({});
    const displayInfo = getDisplayInfo(paymentMode.name);

    useEffect(() =>{
        if(selectedModeID == OTHER_MODE_ID){
            setPaymentMode({ name: OTHER_MODE_ID });
            return;
        }

        const selectedMode = transactionMode[selectedModeID];
        setPaymentMode(selectedMode);
    }, []);

    useEffect(() =>{
        if(maskedCards?.length > 0){
            setSelectedCardToken(maskedCards[0].card_token);
        }
    }, [maskedCards]);

    useEffect(() =>{
        const otherModes = transactionModeIDs
            .filter(id => transactionMode[id].isCustomMode)
            .map(id => ({ 
                label: humanise(transactionMode[id].name),
                id: transactionMode[id]._id
            }));
        
        if(otherModes && otherModes?.length > 0){
            setOtherPaymentModes([ ...otherModes ]);
            setOtherSelectedModeID(otherModes?.[0]?.id);   
        }

    }, [transactionModeIDs]);

    const cardSelectHandler = cardNumber =>{
        setSelectedCardToken(cardNumber.value);
    }

    const  handleFetchModeID = (mode) => {
        return transactionModeIDs.find(id => transactionMode[id].name === mode);
    }

    const submitHandler = () =>{
        let payload = {
            amount: parseFloat(paymentAmount),
            modeID: paymentMode._id
        };

        if(paymentMode.name == OTHER_MODE_ID){
            payload.modeID = otherSelectedModeID;
        }

        const hostelworldID = handleFetchModeID(TRANSACTION_MODE.HOSTELWORLD);
        const channelID = handleFetchModeID(TRANSACTION_MODE.CHANNEL);
        const discountID = handleFetchModeID(TRANSACTION_MODE.DISCOUNT);

        const excludeModesList = [hostelworldID, channelID, discountID];

        if(!cashDrawerOpenSessionID && cashDrawerFeatureEnabled){
            if (!excludeModesList.includes(payload.modeID)) {
                setError(true);
                return false;
            }
        }

        if(cashDrawerOpenSessionID && cashDrawerFeatureEnabled){
            if (!excludeModesList.includes(payload.modeID)) {
                payload.cashDrawerSessionID = cashDrawerOpenSessionID;
            }
        }

        if(paymentMode.name == TRANSACTION_MODE.STRIPE){
            payload.channexCardToken = selectedCardToken;
        }

        submitTransactionHandler(payload);
    }

    const disableBtnCheck = () =>{
        if(paymentMode.name == TRANSACTION_MODE.STRIPE && !selectedCardToken) return true;
        return false;
    }

    const onChangeOtherMode = event =>{
        const value = event.target.value;
        setOtherSelectedModeID(value);
    }

    return (
        <div className={classes.root}>

            <PaymentInfo
                amount={paymentAmount}
                currency={currency}
                subText={displayInfo.modeInfo}
            />

            {error && <p className={classes.error}>Please start shift to capture payments</p>}

            {({
                [TRANSACTION_MODE.STRIPE]: (
                    <CardSelect 
                        addCardHandler={showAddCardHandler}
                        selectCardHandler={cardSelectHandler}
                        selectedCardToken={selectedCardToken}
                        maskedCards={maskedCards}
                    />
                ),

                // [TRANSACTION_MODE.CASH]: (
                //     <div className={classes.warning}>
                //         <p>Amount entered here will be captured for tracking purposes only.</p>
                //     </div>
                // ),

                [TRANSACTION_MODE.CARD]: (
                    <div className={classes.warning}>
                        <p>External credit card terminal.</p>
                        <p>Amount entered here will be captured for tracking purposes only.</p>
                    </div>
                ),

                [OTHER_MODE_ID]: (
                    <RadioGroup
                        className={classes.radioGroup}
                        name="paymentMode"
                        onChange={onChangeOtherMode}
                        value={otherSelectedModeID}
                    >
                        {otherPaymentModes.map((otherMode, index) =>(
                            <RadioButton
                                className={classes.radioBtn}
                                key={`paymentMode_${otherMode.id}`}
                                label={otherMode.label}
                                value={otherMode.id}
                            />
                        ))}
                    </RadioGroup>
                )

            })[paymentMode.name]}

            <Footer 
                nextBtnLabel={displayInfo.nextBtnLabel}
                isNextBtnDisabled={!error && disableBtnCheck()}
                nextStepHandler={submitHandler}
                prevStepHandler={prevStep}
                showPrevBtn
            />
        </div>
    )
}

const mapStateToProps = state =>{
    const { reservationDetails, property, register } = state;
    const cashDrawerData = property.cashDrawer || {};
    const cashDrawerID = register.cashDrawerID;
    const cashDrawerOpenSessionID = cashDrawerData?.[cashDrawerID]?.openSessionID || null;

    const cashDrawerFeatureEnabled = Object.keys(property?.features).some(fid =>{
        const propertyFeature = property.features[fid];
        return propertyFeature?.name === "Cash Drawer" && propertyFeature?.isEnabled === "true";
    });

    return {
        maskedCards: reservationDetails.maskedCards,
        transactionModeIDs: property.transactionModeIDs || [],
        transactionMode: property.transactionMode || {},
        cashDrawerOpenSessionID,
        cashDrawerFeatureEnabled
    }
}

export default connect(mapStateToProps)(ChargeReservation);