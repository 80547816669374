import { combineReducers } from "redux";
import AuthReducer from "./user/auth";
import CustomersReducer from "./customer/customers";
import ReservationsReducer from "./reservation/reservations";
import ReportsReducer from "./reports/reports";
import TodayReducer from "./landing/today";
import FiltersReducer from "./landing/filters";
import RegisterReducer from "./user/register";
import RoomReducer from "./room/room";
import RoomInventoryReducer from "./room/inventory";
import PropertyReducer from "./property/property";
import LoadingReducer from "./loading/loadingReducer";
import errorReducer from "./error/errorReducer";
import cashDrawerReducer from "./cashDrawer/cashDrawer";
import bookingEngineReducer from "./bookingEngine/bookingEngine";
import onlineCheckinReducer from './onlineCheckin/onlineCheckin';
import posReducer from './pos/pos';
import kioskReducer from "./kiosk/kiosk";
import Suggestions from './room/suggest';
import bookingSourceReducer from './reservation/bookingSource';
import ratePlanReducer from './rates/ratePlans';
import paymentReducer from './payments/payments';
import housekeepingReducer from './housekeeping/housekeeping';

import reservationDetailsReducer   from "./reservation/reservationDetails";
import propertyConfigReducer from "./propertyConfigs/propertyConfigs";

const AppReducer = combineReducers({
  auth: AuthReducer,
  customers: CustomersReducer,
  reservations: ReservationsReducer,
  reports: ReportsReducer,
  filters: FiltersReducer,
  today: TodayReducer,
  register: RegisterReducer,
  property: PropertyReducer,
  loading: LoadingReducer,
  error: errorReducer,
  inventory: RoomInventoryReducer,
  cashDrawer: cashDrawerReducer,
  rooms: RoomReducer,
  bookingEngine: bookingEngineReducer,
  pos: posReducer,
  onlineCheckin: onlineCheckinReducer,
  suggestions: Suggestions,
  bookingSource: bookingSourceReducer,
  kiosk: kioskReducer,
  ratePlans: ratePlanReducer,
  payments: paymentReducer,
  reservationDetails: reservationDetailsReducer,
  propertyConfig: propertyConfigReducer,
  housekeeping: housekeepingReducer
});

const RootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined
  }
  return AppReducer(state, action)
}

export default RootReducer;
