import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Counter from './Counter';

const useStyles = makeStyles(theme =>({
    root: {
        display: "flex",
        border: "1px solid #e0e0e0",
        borderRadius: "10px",
        overflow: "hidden",
        minHeight: "60px",
        marginBottom: '20px',
        '& p': { margin: '0px' }
    },
    imgConatainer: {
        width: "80px",
        height: "60px",
    },
    imageContent: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    infoContainer: {
        display: 'flex',
        padding: '10px',
        width: '100%',
    },
    infoSection: {
        flex: 1,
        fontWeight: 600,
        fontSize: "1.2rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingLeft: "5px",
        '& .itemName': {
            marginBottom: "5px",
            fontSize: "1.3rem",
        }
    },
}));

const LineItems = props =>{
    const classes = useStyles();
    const { onCountChange, quantity, currency, amount, name, itemID, imageSrc } = props;
    return (
        <div className={classes.root}>
            <div className={classes.imgConatainer}>
                <img src={imageSrc} className={classes.imageContent}/>
            </div>
    
            <div className={classes.infoContainer}>

                <div className={classes.infoSection}>
                    <p className="itemName">{name}</p>
                    <p>{currency}{amount}</p>
                </div>

                <Counter 
                    itemID={itemID}
                    count={quantity}
                    onCountChange={onCountChange}
                />
            </div>
        </div>
    )
}

export default LineItems;