import React, { Component } from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import { Snackbar } from "@material-ui/core";

const styles = theme => ({
  success: {
    backgroundColor: "green"
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: "#ffa000"
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  actionButton: {
    "&:focus": {
      outline: "none"
    }
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

class NotificationView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const classes = this.props.classes;
    const { open, className, message, onClose, variant } = this.props;

    const Icon =
      variant == "success" ? (
        <CheckCircleIcon className={clsx(classes.icon, classes.iconVariant)} />
      ) : variant === "warning" ? (
        <WarningIcon className={clsx(classes.icon, classes.iconVariant)} />
      ) : variant === "error" ? (
        <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
      ) : variant === "info" ? (
        <InfoIcon className={clsx(classes.icon, classes.iconVariant)} />
      ) : null;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
      >
        <SnackbarContent
          className={clsx(classes[variant], className)}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              {Icon}
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              className={classes.actionButton}
              onClick={onClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          ]}
        />
      </Snackbar>
    );
  }
}

export default withStyles(styles)(NotificationView);
