import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles(theme =>({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    addBtnContainer: {
        display: "flex",
        justifyContent: "center",
        padding: "20px 0px",
    },
    addBtn: {
        color: "#4f4f4f",
        border: "1px solid #e0e0e0",
        padding: "0px 9px",
        fontSize: "1.1rem",
        fontWeight: 600,
        borderRadius: "5px",
        height: "30px",
        '& .addIcon': {
            color: "#4f4f4f",
            fontSize: "2rem",
            marginRight: "5px",
        },
        '&:hover': {
            background: '#ECECEC'
        }
    },
    tag: {
        color: "#4f4f4f",
        border: "1px solid #e0e0e0",
        padding: "0px 10px",
        fontSize: "1.1rem",
        fontWeight: 600,
        borderRadius: "5px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        marginRight: "5px",
        boxSizing: "border-box",
        marginBottom: "5px",
    }
}));

const ReservationTags = props =>{
    const classes = useStyles();
    const { tagList, tagIDs = [], addTagModalHandler } = props;
    return (
        <div className={classes.root}>
            {tagIDs?.map((tagID, index) =>(
                <div 
                    key={`tag_${tagID}_${index}`}
                    className={classes.tag} 
                >
                    {tagList?.[tagID]?.name}
                </div>
            ))}

            <IconButton 
                className={classes.addBtn}
                onClick={addTagModalHandler}
            >
                <AddRoundedIcon className="addIcon"/> Add tag
            </IconButton>
        </div>
    )
}

export default ReservationTags;