import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { searchReservation, resetFilter } from '../../redux/actions/filters';

import Select from 'react-select';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { fetchDateFormat, DATE_FORMATS, removeEmpty } from '../../utils/utility';
import { RESERVATION_STATUS } from '../../utils/constants';

const styles = theme => ({
  container: {
    marginTop: 20,
    marginBottom: 40,
  },
  label: {
    fontSize: '1.3rem'
  },
  options: {
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.4rem',
    minWidth: 180,
    '& .SingleDatePicker': {
      width: '100%'
    },
    '& .SingleDatePickerInput__withBorder': {
      borderRadius: '4px',
      border: 'none',
      boxShadow: '0 2px 70px 0 #E0E0E0',
      color: 'rgba(0, 0, 0, 0.87)',
      width: 'calc(100% - 30px)'
    },
    '& input': {
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)',
      width: '100%',
    },
    '& .DateInput_input': {
      color: 'currentColor',
      fontFamily: "Roboto, sans-serif",
      fontWeight: 500,
      padding: '5px 8px 5px 16px'
    },
    '& .DateInput_input__focused': {
      borderBottom: 'none'
    },
    '& .DateInput': {
      alignItems: 'center',
      borderRadius: '4px',
      display: 'flex',
      height: 38,
      paddingTop: 0,
      width: '100%',
      paddingBottom: 0
    },
    '& .SingleDatePicker_picker__directionLeft': {
      [theme.breakpoints.down('xs')]: {
        left: '-30px!important'
      },
    }
  },
  selectOptions: {
    border: 'none'
  },
});

const selectStyle = {
  control: base => ({
    ...base,
    border: 0,
    paddingLeft: 8,
    // This line disable the blue border
    boxShadow: '0 2px 70px 0 #E0E0E0',
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    }
  })
};

class SearchAdvanced extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {},
      typingTimeout: 0,
      focused: false,
      sortOptions: [
        { value: 'date', label: 'Date' },
        { value: 'amount', label: 'Amount' }
      ],
      roomType: [
        { value: 'private', label: 'Private' },
        { value: 'dorm', label: 'Dorm' },
      ],
      guestStatus: [
        { value: RESERVATION_STATUS.IN_HOUSE, label: 'In-House' },
        { value: RESERVATION_STATUS.CHECKED_OUT, label: 'Check-out' },
        { value: RESERVATION_STATUS.NO_SHOW, label: 'No show' },
        { value: RESERVATION_STATUS.CANCELLED, label: 'Cancelled' },
        { value: RESERVATION_STATUS.ONLINE_CHECKED_IN, label: 'Online checked-in' },
        { value: 'past', label: 'Past Booking' },
        { value: 'future', label: 'Future Booking' }
      ]
    };
  }

  handleFieldChange = name => event => {
    let params = {};
    let search = { ...this.state.search };
    let value = event && (event.value || event.value === "") ? event.value : event || null;
    search[name] = value;
    if (!value) {
      search = removeEmpty(search);
    }
    this.setState({ search });
    params = { ...search };
    if (params.date) params.date = fetchDateFormat(params.date, DATE_FORMATS.DEFAULT_DATE);
    if (Object.keys(params).length)
      this.props.dispatch(searchReservation(params));
    else
      this.props.dispatch(resetFilter());
  }

  render() {

    const { classes } = this.props;
    const search = { ...this.state.search };

    return (
      <div className={classes.container}>
        
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} lg={3}>
            <div className={classes.options}>
              <SingleDatePicker
                date={search.date}
                onDateChange={this.handleFieldChange("date")}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                id="your_unique_id"
                numberOfMonths={1}
                showClearDate={true}
                isOutsideRange={() => false}
                displayFormat={'DD MMM YY'}
                hideKeyboardShortcutsPanel
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={4} lg={3}>
            <div className={clsx(classes.options, classes.selectOptions)}>
              <Select
                options={this.state.guestStatus}
                styles={selectStyle}
                value={search.status && this.state.guestStatus.find((status) => status.value === search.status)}
                isClearable={true}
                onChange={this.handleFieldChange("status")}
                placeholder={"Status"}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={4} lg={3}>
            <div className={clsx(classes.options, classes.selectOptions)}>
              <Select
                options={this.state.roomType}
                styles={selectStyle}
                value={search.roomType && this.state.roomType.find((room) => room.value === search.roomType)}
                isClearable={true}
                onChange={this.handleFieldChange("roomType")}
                placeholder={"Room Category"}
              />
            </div>
          </Grid>

        </Grid>
      </div>
    )
  };
};

const mapStateToProps = (state) => {
  return {
    filters: state.filters
  }
};

export default withStyles(styles)(connect(mapStateToProps)(SearchAdvanced));