import IMAGES from "../../constants/images";
const UPSELL_ITEMS = [
  {
    iconID: "0",
    name: "Extra Cleaning Fee",
    icon: "/images/icons/upsell-cleaning.png",
    imgSrc: IMAGES.PLACEHOLDERS.towel,
    isActive: false,
  },
  {
    iconID: "1",
    name: "Locks",
    icon: "/images/icons/upsell-locks.png",
    imgSrc: IMAGES.PLACEHOLDERS.lock,
    isActive: false,
  },
  {
    iconID: "2",
    name: "Towel",
    icon: "/images/icons/upsell-towel.png",
    imgSrc: IMAGES.PLACEHOLDERS.towel,
    isActive: false,
  },
  {
    iconID: "3",
    name: "Beach Towel",
    icon: "/images/icons/upsell-beach-towel.png",
    imgSrc: IMAGES.PLACEHOLDERS.towel,
    isActive: false,
  },
  {
    iconID: "4",
    name: "Bedsheets",
    icon: "/images/icons/upsell-sheets.png",
    imgSrc: IMAGES.PLACEHOLDERS.towel,
    isActive: false,
  },
  {
    iconID: "5",
    name: "Toilet Paper",
    icon: "/images/icons/upsell-tp.png",
    imgSrc: IMAGES.PLACEHOLDERS.towel,
    isActive: false,
  },
  {
    iconID: "6",
    name: "Gift Shop Item",
    icon: "/images/icons/upsell-gift-shop.png",
    imgSrc: IMAGES.PLACEHOLDERS.towel,
    isActive: false,
  },
  {
    iconID: "7",
    name: "Map",
    icon: "/images/icons/upsell-map.png",
    imgSrc: IMAGES.PLACEHOLDERS.map,
    isActive: false,
  },
  {
    iconID: "8",
    name: "Cold Drink",
    icon: "/images/icons/upsell-drink.png",
    imgSrc: IMAGES.PLACEHOLDERS.towel,
    isActive: false,
  },
  {
    iconID: "9",
    name: "Hot Drink",
    icon: "/images/icons/upsell-coffee.png",
    imgSrc: IMAGES.PLACEHOLDERS.tea,
    isActive: false,
  },
  {
    iconID: "10",
    name: "Snack",
    icon: "/images/icons/upsell-food.png",
    imgSrc: IMAGES.PLACEHOLDERS.towel,
    isActive: false,
  },
  {
    iconID: "11",
    name: "Water",
    icon: "/images/icons/upsell-water.png",
    imgSrc: IMAGES.PLACEHOLDERS.towel,
    isActive: false,
  },
  {
    iconID: "12",
    name: "Pick Up Service",
    icon: "/images/icons/upsell-pick-up.png",
    imgSrc: IMAGES.PLACEHOLDERS.taxi,
    isActive: false,
  },
  {
    iconID: "13",
    name: "Misc",
    icon: "/images/icons/upsell-misc.png",
    imgSrc: IMAGES.PLACEHOLDERS.towel,
    isActive: false,
  },
];

export { UPSELL_ITEMS };
