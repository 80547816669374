import React from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from 'react-redux';

import { loginUser } from "../../redux/actions/auth";
import { checkDataValidation } from "../../utils/utility";

import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: 400,
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
      width: 450,
    }
  },
  header: {
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  title: {
    color: '#333333',
    fontSize: '2rem',
    marginBottom: 5,
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 10,
  },
  inputLabel: {
    fontSize: "1.1rem",
    margin: "8px 10px",
  },
  textInput: {
    width: "100%",
    height: "45px",
    background: '#FFFFFF',
    borderRadius: "10px",
    border: "1px solid #e0e0e0",
    padding: "0px 20px",
    marginBottom: '10px',
    '&.error': {
        borderColor: '#f44336'
    }
  },
  buttonRow: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    paddingTop: 25,
  },
  link: {
    color: '#999999',
    cursor: 'pointer',
    marginRight: 15,
    fontSize: '1.1rem',
    '& a': {
      textDecoration: 'none'
    }
  },
  submit: {
    borderRadius: 5,
    color: '#FFFFFF',
    cursor: 'pointer',
    background: '#006699',
    fontSize: '1.3rem',
    fontWeight: 600,
    marginLeft: 'auto',
    padding: '15px 15px',
  }
});

class LoginSection extends React.Component {

  constructor() {
    super();
    this.state = {
      formHelper: {
        email: {
          error: false, required: true
        },
        password: {
          error: false, required: true
        },
      },
      email: "",
      password: ""
    };
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter' && this.state.email != '' && this.state.password != '') {
      e.preventDefault();
      this.handleLogin(e);
    }
  }

  handleLogin = e => {
    e.preventDefault();

    let formHelper = { ...this.state.formHelper };
    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    let validatedInfo = checkDataValidation(userData, formHelper)
    this.setState({ formHelper: validatedInfo.formHelper })
    if (!validatedInfo.hasError)
      this.props.dispatch(loginUser(userData, this.props.history));
  };

  handleOnChange = e => {
    let formHelper = { ...this.state.formHelper };
    formHelper[e.target.id].error = false;
    this.setState({ [e.target.id]: e.target.value, formHelper: formHelper });
  };

  render() {
    const formHelper = { ...this.state.formHelper };
    const { classes } = this.props;

    return (
      <div className={classes.root}>
          <div className={classes.header}>
            <div className={classes.title}>Welcome to Counter Frontdesk!</div>
          </div>
          <div className={classes.formGroup}>
            <InputBase
              id="email"
              type="email"
              className={classes.textInput}
              name="email"
              placeholder="Your email"
              value={this.state.email}
              onChange={this.handleOnChange}
              onKeyDown={this.handleKeyDown}
            />
          </div>
          <div className={classes.formGroup}>
            <InputBase
              id="password"
              type="password"
              className={classes.textInput}
              name="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handleOnChange}
              onKeyDown={this.handleKeyDown}
            />
          </div>
          <div className={classes.buttonRow}>
            <div className={classes.link}>
              <a href="https://workspace.counter.app/signup" target="_blank">Register</a>
            </div>
            <div className={classes.link} onClick={this.handleClickForgot}>
              <a href="https://workspace.counter.app/forgotPassword" target="_blank">Forgot Password?</a>
            </div>
            <div className={classes.submit} onClick={this.handleLogin}>
              Sign In
            </div>
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

LoginSection.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(mapStateToProps, null, null, { forwardRef: true })(withRouter(LoginSection)));