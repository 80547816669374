import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';
import IMAGES from '../../constants/images';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: "0px 2px 8px 0px #ececec",
        display: 'flex',
    },
    imgContainer: {
        width: "100px",
        maxHeight: "70px",
        '& img': {
            objectFit: "cover",
            width: "100%",
            height: "100%",
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    infoConatiner: {
        display: 'flex',
        flex: 1,
        justifyContent: "space-between",
        padding: "15px 20px",
    },
    roomInfo: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    title: {
        margin: 0,
        fontSize: '1.5rem'
    },
    subTitle: {
        fontSize: '1.1rem',
        color: '#666666',
        margin: '0px',
        marginTop: '5px',
        wordBreak: 'break-word', 
    },
    btnConatiner: {
        minWidth: "90px",
        textAlign: "end",
    },
    button: {
        fontSize: "1.1rem",
        color: "#999999",
    }
}));

const RoomCardHeader = props => {
    const classes = useStyles();
    const { 
        imageSrc = IMAGES.PLACEHOLDERS.sharedRoom, 
        title = '',
        subTitle = '',
        markCleanHandler = null,
        showMarkAllClean = false
    } = props;

    return (
        <div className={classes.root}>

            <div className={classes.imgContainer}>
                <img src={imageSrc} />
            </div>

            <div className={classes.infoConatiner}>

                <div className={classes.roomInfo}>
                    <h5 className={classes.title}>{title}</h5>
                    <p className={classes.subTitle}>{subTitle}</p>
                </div>

                {showMarkAllClean && (
                    <div className={classes.btnConatiner}>
                        <ButtonBase 
                            className={classes.button}
                            onClick={markCleanHandler}
                        >
                            Mark all as clean
                        </ButtonBase>
                    </div>
                )}
                
            </div>
        </div>
    )
}

export default RoomCardHeader;