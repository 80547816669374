import React, { Component } from "react";
import { connect } from 'react-redux';
import { Switch, Route, Link, Redirect } from "react-router-dom";
import IdleTimer from 'react-idle-timer'
import { withTranslation } from 'react-i18next';

import KioskLandinPage from '../components/kiosk';
import BookingEngine from '../components/kiosk/BookingEngine';
import Checkin from '../components/kiosk/Checkin';
import PageNotFound from '../components/booking_engine/pageNotFound';
import SessionTimeoutDialog from '../components/kiosk/SessionTimeout';

import { fetchPropertyInfo } from '../redux/actions/kiosk';

class KioskPage extends Component{

    state = { 
        showPage: false,
        isPageNotFound: false,
        timeoutModalOpen: false,
        timeoutCountdown: 0,
        countdownInterval: 0
    }

    constructor(props){
        super(props);
        this.idleTimer = React.createRef();
        this.timeout = 60000 * 2;
    }

    setDocumentTitle = (title) =>{
        document.title = title;
    }

    pauseIdleTimer = () =>{
        this.idleTimer.current.pause();
    }

    startIdleTimer = () =>{
        this.idleTimer.current.reset();
    }

    componentDidMount(){
        const { match, location, dispatch, history } = this.props;

        if(location.pathname != match.url){
            history.replace(match.url);
        }

        this.pauseIdleTimer();
        
        const propertyHandle = match.params.id;
        dispatch(fetchPropertyInfo(propertyHandle, this.pageNotFoundHandler));
    }

    componentDidUpdate(prevProps){
        if(!_.isEqual(this.props.propertyDetails, prevProps.propertyDetails)){
            this.setState({ showPage: true });
            this.setDocumentTitle(this.props.propertyDetails.name);
        }
    }

    navigateTo = page =>{
        const { history, match } = this.props;
        const path = this.fetchCurrentPath();
        let basePath = match.url;
        basePath = basePath[basePath.length - 1] == '/' ? basePath.slice(0, -1) : basePath;

        if(path && page == 'bookingEngine'){
            history.push(`${basePath}/booking`);
            this.startIdleTimer();
        }

        if(path && page == 'checkin'){
            history.push(`${basePath}/checkin`);
            this.startIdleTimer();
        }

        if(path && page == ''){
            history.push(`${basePath}`);
        }

        window.scrollTo(0,0);
    }

    fetchCurrentPath = () =>{
        const { location, match } = this.props;
        if(location.pathname == match.url) return '/';
        const path = location.pathname.replace(match.url,'');
        return path
    }

    pageNotFoundHandler = () =>{
        this.setState({ isPageNotFound: true });
    }

    clearSessionTimeout = () => {
        clearTimeout(this.timeout);
    };

    handleContinue = () => {
        this.setState({...this.state, timeoutModalOpen: false});
    }

    _onActive = () => {
        if (!this.timeoutModalOpen) {
          this.clearSessionTimeout();
        }
    };
      
    _onIdle = () => {
        const { location, match } = this.props;
        if (location.pathname != match.url && !this.timeoutModalOpen) {
            let internalCountDown = 10;
            this.setState({...this.state, timeoutModalOpen: true, timeoutCountdown: internalCountDown});
            this.countdownInterval = setInterval(() => {
                if (internalCountDown > 0) {
                    this.setState({...this.state, timeoutCountdown: --internalCountDown});
                    console.log(internalCountDown)
                } else {
                    console.log('here', this.state.timeoutModalOpen)
                    if (this.state.timeoutModalOpen) window.location.reload();
                }
            }, 1000);
        }
    };

    render(){
        const { match, kiosk } = this.props;
        const { isPageNotFound, showPage } = this.state;

        const routeProps = {
            navigateTo: this.navigateTo
        }

        if(isPageNotFound) return <PageNotFound />;

        if(kiosk?.config && !kiosk.config.isEnabled) return <PageNotFound />;
            
        return(
            <>
                {showPage && (
                    <>
                        <Route path={`${match.url}`} exact render={props =><KioskLandinPage {...props} {...routeProps} />}/>
                        <Route path={`${match.url}/booking`} render={props =><BookingEngine {...props} {...routeProps} />}/>
                        <Route path={`${match.url}/checkin`} render={props =><Checkin {...props} {...routeProps} />}/>
                    </>
                )}

                <IdleTimer
                    key='idleTimer'
                    startOnMount={ true }
                    ref={ this.idleTimer }
                    element={ document }
                    onActive={ this._onActive }
                    onIdle={ this._onIdle }
                    timeout={this.timeout}
                />  

                <SessionTimeoutDialog
                    countdown={this.state.timeoutCountdown}
                    onContinue={this.handleContinue}
                    onLogout={() => window.location.reload()}
                    open={this.state.timeoutModalOpen}
                />
            </>
        )
    }
}

const mapStateToProps = state =>{
    const { bookingEngine, kiosk } = state;
    const propertyDetails = bookingEngine.propertyDetails ? bookingEngine.propertyDetails: {};
    return{ 
        propertyDetails,
        kiosk
    };
}

export default withTranslation()(connect(mapStateToProps)(KioskPage));