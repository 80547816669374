export const propertyDetails = {
    "address": {
        "lineOne": "Mauerpark",
        "lineTwo": "",
        "city": "Berlin",
        "state": "Berlin",
        "country": "Germany",
        "zipCode": "10115"
    },
    "_id": "5df7695cc3a0427e4a4f4",
    "name": "Demo",
    "timezone": "Europe/Berlin",
    "images": [
        "/images/P_1.png"
    ],
    "currency": "EUR"
};

export const bookEngineConfig = {
    "createdAt": "2020-12-16T12:14:37.395Z",
    "propertyID": "5df7695cc3a0427e4a4f4f4a",
    "terms": "<div><!--block-->This is the terms and conditions for the demo property. You can configure the same in your booking engine settings in workspace</div>",
    "updatedAt": "2021-02-15T14:30:19.651Z",
    "_id": "60252a322a85cfa265381cf5"
}

export const roomTypes = {
    "5df7752d52394d0cd5cf743f": {
        "taxesApplicable": [
            "5e43fdbb91c3fa71a027f8b4",
            "5e43ff4e91c3fa71a027f8b7",
            "5e43fcf291c3fa71a027f8b3",
            "5e43fe0591c3fa71a027f8b5",
            "5e4407dcab147c31a6f9c73c"
        ],
        "isActive": true,
        "_id": "5df7752d52394d0cd5cf743f",
        "name": "Deluxe Double",
        "dormOrPrivate": "private",
        "numberOfUnits": 8,
        "bedsPerRoom": 2,
        "totalAccommodation": 8,
        "position": 1,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "images": [
            "https://counter-frontdesk-dev.s3.eu-central-1.amazonaws.com/property/images/5ebd15231f20e01ccff03ab8/5ebd164f1f20e01ccff03ac1/Dorm B"
        ],
        "createdAt": "2019-12-16T12:14:37.395Z",
        "updatedAt": "2020-06-19T15:12:39.471Z",
        "__v": 5,
        "description": "Double rooms with air-conditioners, televisions and en-suite bathrooms and a seriously long description about facilities and amenities"
    },
    "5df7752d52394d0cd5cf7440": {
        "taxesApplicable": [],
        "isActive": true,
        "_id": "5df7752d52394d0cd5cf7440",
        "name": "Standard Double",
        "dormOrPrivate": "private",
        "numberOfUnits": 11,
        "bedsPerRoom": 2,
        "totalAccommodation": 11,
        "position": 2,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "images": [],
        "createdAt": "2019-12-16T12:14:37.395Z",
        "updatedAt": "2020-04-02T08:38:16.814Z",
        "__v": 4,
        "description": "Double rooms with air-conditioning and en-suite bathrooms"
    },
    "5df7752d52394d0cd5cf7441": {
        "taxesApplicable": [],
        "isActive": true,
        "_id": "5df7752d52394d0cd5cf7441",
        "name": "Twin Private",
        "dormOrPrivate": "private",
        "numberOfUnits": 3,
        "bedsPerRoom": 2,
        "totalAccommodation": 3,
        "position": 3,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "images": [],
        "createdAt": "2019-12-16T12:14:37.395Z",
        "updatedAt": "2019-12-16T12:14:37.395Z",
        "__v": 0
    },
    "5df7752d52394d0cd5cf7442": {
        "taxesApplicable": [],
        "isActive": true,
        "_id": "5df7752d52394d0cd5cf7442",
        "name": "Basic Double",
        "dormOrPrivate": "private",
        "numberOfUnits": 2,
        "bedsPerRoom": 2,
        "totalAccommodation": 2,
        "position": 4,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "images": [],
        "createdAt": "2019-12-16T12:14:37.395Z",
        "updatedAt": "2019-12-16T12:14:37.395Z",
        "__v": 0
    },
    "5df7752d52394d0cd5cf7443": {
        "taxesApplicable": [],
        "isActive": true,
        "_id": "5df7752d52394d0cd5cf7443",
        "name": "Single",
        "dormOrPrivate": "private",
        "numberOfUnits": 3,
        "bedsPerRoom": 1,
        "totalAccommodation": 3,
        "position": 5,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "images": [],
        "createdAt": "2019-12-16T12:14:37.395Z",
        "updatedAt": "2019-12-16T12:14:37.395Z",
        "__v": 0
    },
    "5df7752d52394d0cd5cf7444": {
        "taxesApplicable": [],
        "isActive": true,
        "_id": "5df7752d52394d0cd5cf7444",
        "name": "4 Bed Female AC Dorm",
        "dormOrPrivate": "dorm",
        "numberOfUnits": 1,
        "bedsPerRoom": 4,
        "totalAccommodation": 4,
        "position": 6,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "images": [],
        "createdAt": "2019-12-16T12:14:37.395Z",
        "updatedAt": "2019-12-16T12:14:37.395Z",
        "__v": 0
    },
    "5df7752d52394d0cd5cf7445": {
        "taxesApplicable": [],
        "isActive": true,
        "_id": "5df7752d52394d0cd5cf7445",
        "name": "6 Bed AC Dorm",
        "dormOrPrivate": "dorm",
        "numberOfUnits": 2,
        "bedsPerRoom": 6,
        "totalAccommodation": 12,
        "position": 7,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "images": [],
        "createdAt": "2019-12-16T12:14:37.395Z",
        "updatedAt": "2019-12-16T12:14:37.395Z",
        "__v": 0
    },
    "5df7752d52394d0cd5cf7446": {
        "taxesApplicable": [],
        "isActive": true,
        "_id": "5df7752d52394d0cd5cf7446",
        "name": "8 Bed AC Dorm",
        "dormOrPrivate": "dorm",
        "numberOfUnits": 1,
        "bedsPerRoom": 8,
        "totalAccommodation": 8,
        "position": 8,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "images": [],
        "createdAt": "2019-12-16T12:14:37.395Z",
        "updatedAt": "2019-12-16T12:14:37.395Z",
        "__v": 0
    },
    "5df7752d52394d0cd5cf7447": {
        "taxesApplicable": [],
        "isActive": true,
        "_id": "5df7752d52394d0cd5cf7447",
        "name": "Female dorm 7 beds",
        "dormOrPrivate": "dorm",
        "numberOfUnits": 1,
        "bedsPerRoom": 7,
        "totalAccommodation": 7,
        "position": 9,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "images": [],
        "createdAt": "2019-12-16T12:14:37.395Z",
        "updatedAt": "2019-12-16T12:14:37.395Z",
        "__v": 0
    },
    "5df7752d52394d0cd5cf7448": {
        "taxesApplicable": [],
        "isActive": true,
        "_id": "5df7752d52394d0cd5cf7448",
        "name": "6 Bed Dorm",
        "dormOrPrivate": "dorm",
        "numberOfUnits": 1,
        "bedsPerRoom": 6,
        "totalAccommodation": 6,
        "position": 10,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "images": [],
        "createdAt": "2019-12-16T12:14:37.395Z",
        "updatedAt": "2019-12-16T12:14:37.395Z",
        "__v": 0
    },
    "5df7752d52394d0cd5cf7449": {
        "taxesApplicable": [],
        "isActive": true,
        "_id": "5df7752d52394d0cd5cf7449",
        "name": "8 Bed Non-AC Dorm",
        "dormOrPrivate": "dorm",
        "numberOfUnits": 1,
        "bedsPerRoom": 8,
        "totalAccommodation": 8,
        "position": 11,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "images": [],
        "createdAt": "2019-12-16T12:14:37.395Z",
        "updatedAt": "2019-12-16T12:14:37.395Z",
        "__v": 0
    },
    "5df7752d52394d0cd5cf744a": {
        "taxesApplicable": [],
        "isActive": true,
        "_id": "5df7752d52394d0cd5cf744a",
        "name": "4 Bed Dorm",
        "dormOrPrivate": "dorm",
        "numberOfUnits": 3,
        "bedsPerRoom": 4,
        "totalAccommodation": 12,
        "position": 12,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "images": [],
        "createdAt": "2019-12-16T12:14:37.395Z",
        "updatedAt": "2019-12-16T12:14:37.395Z",
        "__v": 0
    },
    "5df7752d52394d0cd5cf744b": {
        "taxesApplicable": [],
        "isActive": true,
        "_id": "5df7752d52394d0cd5cf744b",
        "name": "7 Bed Non-AC Dorm",
        "dormOrPrivate": "dorm",
        "numberOfUnits": 3,
        "bedsPerRoom": 7,
        "totalAccommodation": 21,
        "position": 13,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "images": [],
        "createdAt": "2019-12-16T12:14:37.395Z",
        "updatedAt": "2020-06-22T11:08:36.526Z",
        "__v": 1
    },
    "5eea3ab96c831ea30a4c0b75": {
        "taxesApplicable": [],
        "isActive": true,
        "_id": "5eea3ab96c831ea30a4c0b75",
        "dormOrPrivate": "private",
        "name": "test",
        "numberOfUnits": 7,
        "bedsPerRoom": 4,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "totalAccommodation": 7,
        "images": [],
        "createdAt": "2020-06-17T15:46:04.578Z",
        "updatedAt": "2020-06-22T11:33:09.927Z",
        "__v": 0
    },
    "5ef099a926f2990c2253073b": {
        "taxesApplicable": [],
        "isActive": true,
        "_id": "5ef099a926f2990c2253073b",
        "dormOrPrivate": "dorm",
        "name": "Test dorm",
        "numberOfUnits": 5,
        "bedsPerRoom": 4,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "totalAccommodation": 20,
        "images": [],
        "createdAt": "2020-06-22T11:44:47.804Z",
        "updatedAt": "2020-06-22T12:03:43.184Z",
        "__v": 0,
        "description": "testing"
    }
};

export const taxItems = {
    "5e43fcf291c3fa71a027f8b3": {
        "isDeleted": false,
        "_id": "5e43fcf291c3fa71a027f8b3",
        "name": "Fixed Accommodation Tax",
        "calculationMode": "exclusive",
        "code": "FAT",
        "type": "amountPerAccommodation",
        "value": 7,
        "applicationOrder": 1,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "rateSlabRules": [],
        "createdAt": "2020-02-12T13:26:10.117Z",
        "updatedAt": "2020-02-14T20:49:06.034Z",
        "__v": 0
    },
    "5e43fdbb91c3fa71a027f8b4": {
        "isDeleted": false,
        "_id": "5e43fdbb91c3fa71a027f8b4",
        "name": "Fixed Night Tax",
        "calculationMode": "inclusive",
        "code": "FNT2",
        "type": "amountPerNight",
        "value": 1,
        "applicationOrder": 2,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "rateSlabRules": [],
        "createdAt": "2020-02-12T13:29:31.379Z",
        "updatedAt": "2020-02-25T22:10:25.155Z",
        "__v": 0
    },
    "5e43fe0591c3fa71a027f8b5": {
        "isDeleted": false,
        "_id": "5e43fe0591c3fa71a027f8b5",
        "name": "Person Tourism Tax",
        "calculationMode": "exclusive",
        "code": "PTX8",
        "type": "amountPerPerson",
        "value": 5,
        "applicationOrder": 3,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "rateSlabRules": [],
        "createdAt": "2020-02-12T13:30:45.784Z",
        "updatedAt": "2020-02-12T13:30:45.784Z",
        "__v": 0
    },
    "5e43ff4e91c3fa71a027f8b7": {
        "isDeleted": false,
        "_id": "5e43ff4e91c3fa71a027f8b7",
        "name": "Hostel VAT",
        "calculationMode": "inclusive",
        "code": "",
        "type": "percentOfTotal",
        "value": 12,
        "applicationOrder": 5,
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "rateSlabRules": [],
        "createdAt": "2020-02-12T13:36:14.739Z",
        "updatedAt": "2020-04-26T13:03:59.633Z",
        "__v": 0
    },
    "5e4407dcab147c31a6f9c73c": {
        "isDeleted": false,
        "_id": "5e4407dcab147c31a6f9c73c",
        "name": "Goods and Services Tax",
        "calculationMode": "exclusive",
        "code": "IGST",
        "type": "rateSlabs",
        "applicationOrder": 6,
        "rateSlabRules": [
            {
                "priceFrom": 0,
                "priceTo": 999,
                "value": 0
            },
            {
                "priceFrom": 7500,
                "priceTo": 999999,
                "value": 18
            },
            {
                "priceFrom": 1000,
                "priceTo": 7499,
                "value": 10
            }
        ],
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "createdAt": "2020-02-12T14:12:44.772Z",
        "updatedAt": "2020-02-25T22:09:44.216Z",
        "__v": 0
    }
};

export const ratePlans = {
    "5e660c8c9af7d2031313202e": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf743f",
            "5df7752d52394d0cd5cf7441",
            "5df7752d52394d0cd5cf7443",
            "5df7752d52394d0cd5cf7449",
            "5df7752d52394d0cd5cf7447",
            "5df7752d52394d0cd5cf7440",
            "5df7752d52394d0cd5cf7444",
            "5df7752d52394d0cd5cf7446",
            "5df7752d52394d0cd5cf744a",
            "5df7752d52394d0cd5cf7445",
            "5df7752d52394d0cd5cf744b",
            "5df7752d52394d0cd5cf7448",
            "5df7752d52394d0cd5cf7442"
        ],
        "isDeleted": false,
        "_id": "5e660c8c9af7d2031313202e",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "isDefault": true,
        "isDerived": false,
        "sm": {
            "inclusions": [],
            "name": "Standard",
            "ratePlanCode": "CTRBNB",
            "isDerived": false,
            "isDefault": true
        },
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "createdAt": "2020-03-09T09:29:48.307Z",
        "updatedAt": "2020-03-09T09:29:48.307Z",
        "__v": 0
    },
    "5e660d669af7d20313132030": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf743f"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "_id": "5e660d669af7d20313132030",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "Standard",
            "ratePlanCode": 123456,
            "default": 1,
            "active": 1,
            "nonrefundable": 0,
            "isbreakfastincluded": 0
        },
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "createdAt": "2020-03-09T09:33:26.835Z",
        "updatedAt": "2020-03-09T09:33:26.835Z",
        "__v": 0
    },
    "5e660d7d9af7d20313132031": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf7441"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5e660d7d9af7d20313132031",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "Standard",
            "ratePlanCode": 123457,
            "default": 1,
            "active": 1,
            "nonrefundable": 0,
            "isbreakfastincluded": 0
        },
        "createdAt": "2020-03-09T09:33:49.732Z",
        "updatedAt": "2020-03-09T09:33:49.732Z",
        "__v": 0
    },
    "5e660d8c9af7d20313132032": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf7443"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5e660d8c9af7d20313132032",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "Standard",
            "ratePlanCode": 123458,
            "default": 1,
            "active": 1,
            "nonrefundable": 0,
            "isbreakfastincluded": 0
        },
        "createdAt": "2020-03-09T09:34:04.508Z",
        "updatedAt": "2020-03-09T09:34:04.508Z",
        "__v": 0
    },
    "5e660d9c9af7d20313132033": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf7449"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5e660d9c9af7d20313132033",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "Standard",
            "ratePlanCode": 123459,
            "default": 1,
            "active": 1,
            "nonrefundable": 0,
            "isbreakfastincluded": 0
        },
        "createdAt": "2020-03-09T09:34:20.943Z",
        "updatedAt": "2020-03-09T09:34:20.943Z",
        "__v": 0
    },
    "5e660dae9af7d20313132034": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf7447"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5e660dae9af7d20313132034",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "Standard",
            "ratePlanCode": 123450,
            "default": 1,
            "active": 1,
            "nonrefundable": 0,
            "isbreakfastincluded": 0
        },
        "createdAt": "2020-03-09T09:34:38.195Z",
        "updatedAt": "2020-03-09T09:34:38.195Z",
        "__v": 0
    },
    "5e660e229af7d20313132035": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf7442"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5e660e229af7d20313132035",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "Standard",
            "ratePlanCode": 123451,
            "default": 1,
            "active": 1,
            "nonrefundable": 0,
            "isbreakfastincluded": 0
        },
        "createdAt": "2020-03-09T09:36:34.574Z",
        "updatedAt": "2020-03-09T09:36:34.574Z",
        "__v": 0
    },
    "5e664f476c76e509a1356294": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf7442"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5e664f476c76e509a1356294",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "NonRefundable",
            "ratePlanCode": 223456,
            "default": 0,
            "active": 1,
            "nonrefundable": 1,
            "percent": -15,
            "isbreakfastincluded": 0,
            "parentrateplanid": "5e660e229af7d20313132035"
        },
        "createdAt": "2020-03-09T14:14:31.773Z",
        "updatedAt": "2020-03-09T14:14:31.773Z",
        "__v": 0
    },
    "5e664f646c76e509a1356295": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf7447"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5e664f646c76e509a1356295",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "NonRefundable",
            "ratePlanCode": 223456,
            "default": 0,
            "active": 1,
            "nonrefundable": 1,
            "percent": -15,
            "isbreakfastincluded": 0,
            "parentrateplanid": "5e660dae9af7d20313132034"
        },
        "createdAt": "2020-03-09T14:15:00.150Z",
        "updatedAt": "2020-03-09T14:15:00.150Z",
        "__v": 0
    },
    "5e664f746c76e509a1356296": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf7449"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5e664f746c76e509a1356296",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "NonRefundable",
            "ratePlanCode": 223456,
            "default": 0,
            "active": 1,
            "nonrefundable": 1,
            "percent": -15,
            "isbreakfastincluded": 0,
            "parentrateplanid": "5e660d9c9af7d20313132033"
        },
        "createdAt": "2020-03-09T14:15:16.100Z",
        "updatedAt": "2020-03-09T14:15:16.100Z",
        "__v": 0
    },
    "5e664f8a6c76e509a1356297": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf7443"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5e664f8a6c76e509a1356297",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "NonRefundable",
            "ratePlanCode": 223456,
            "default": 0,
            "active": 1,
            "nonrefundable": 1,
            "percent": -15,
            "isbreakfastincluded": 0,
            "parentrateplanid": "5e660d8c9af7d20313132032"
        },
        "createdAt": "2020-03-09T14:15:38.804Z",
        "updatedAt": "2020-03-09T14:15:38.804Z",
        "__v": 0
    },
    "5e664f9c6c76e509a1356298": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf7441"
        ],
        "isDeleted": true,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5e664f9c6c76e509a1356298",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "NonRefundable",
            "ratePlanCode": 223456,
            "default": 0,
            "active": 1,
            "nonrefundable": 1,
            "percent": -15,
            "isbreakfastincluded": 0,
            "parentrateplanid": "5e660d7d9af7d20313132031"
        },
        "createdAt": "2020-03-09T14:15:56.065Z",
        "updatedAt": "2020-03-09T14:15:56.065Z",
        "__v": 0
    },
    "5e664faf6c76e509a1356299": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf743f"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5e664faf6c76e509a1356299",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "NonRefundable",
            "ratePlanCode": 223456,
            "default": 0,
            "active": 1,
            "nonrefundable": 1,
            "percent": -15,
            "isbreakfastincluded": 0,
            "parentrateplanid": "5e660d669af7d20313132030"
        },
        "createdAt": "2020-03-09T14:16:15.599Z",
        "updatedAt": "2020-03-09T14:16:15.599Z",
        "__v": 0
    },
    "5e665093b6fac00a0d026d7a": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf743f",
            "5df7752d52394d0cd5cf7441",
            "5df7752d52394d0cd5cf7443",
            "5df7752d52394d0cd5cf7449",
            "5df7752d52394d0cd5cf7447",
            "5df7752d52394d0cd5cf7440",
            "5df7752d52394d0cd5cf7444",
            "5df7752d52394d0cd5cf7446",
            "5df7752d52394d0cd5cf744a",
            "5df7752d52394d0cd5cf7445",
            "5df7752d52394d0cd5cf744b",
            "5df7752d52394d0cd5cf7448",
            "5df7752d52394d0cd5cf7442"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5e665093b6fac00a0d026d7a",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "sm": {
            "derivedRate": {
                "addOrSubtract": "subtract",
                "percentOrAmount": "percent",
                "value": 10,
                "reference": "5e660c8c9af7d2031313202e"
            },
            "inclusions": [],
            "name": "Non Refundable",
            "ratePlanCode": "CTRNRR",
            "isDerived": true,
            "isDefault": false
        },
        "createdAt": "2020-03-09T14:20:03.423Z",
        "updatedAt": "2020-03-09T14:20:03.423Z",
        "__v": 0
    },
    "5e923497e8cab01292a45adf": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf743f",
            "5df7752d52394d0cd5cf7441",
            "5df7752d52394d0cd5cf7443",
            "5df7752d52394d0cd5cf7449",
            "5df7752d52394d0cd5cf7447",
            "5df7752d52394d0cd5cf7440",
            "5df7752d52394d0cd5cf7444",
            "5df7752d52394d0cd5cf7446",
            "5df7752d52394d0cd5cf744a",
            "5df7752d52394d0cd5cf7445",
            "5df7752d52394d0cd5cf744b",
            "5df7752d52394d0cd5cf7448",
            "5df7752d52394d0cd5cf7442"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5e923497e8cab01292a45adf",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "wb": {
            "name": "Standard",
            "ratePlanCode": 77777,
            "isDerived": false,
            "isDefault": true
        },
        "createdAt": "2020-04-11T21:20:23.837Z",
        "updatedAt": "2020-04-11T21:20:23.837Z",
        "__v": 0
    },
    "5ea5854fd6750b4739a7303f": {
        "roomTypeIDs": [
            "5ea5854fd6750b4739a7303e"
        ],
        "isDeleted": false,
        "_id": "5ea5854fd6750b4739a7303f",
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-04-26T12:57:51.761Z",
        "updatedAt": "2020-04-26T12:57:51.761Z",
        "__v": 0
    },
    "5ea585acd6750b4739a731a9": {
        "roomTypeIDs": [
            "5ea585acd6750b4739a731a8"
        ],
        "isDeleted": false,
        "_id": "5ea585acd6750b4739a731a9",
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-04-26T12:59:24.530Z",
        "updatedAt": "2020-04-26T12:59:24.530Z",
        "__v": 0
    },
    "5ebd5109134a2b11502bcece": {
        "roomTypeIDs": [
            "5ebd5109134a2b11502bcecd"
        ],
        "isDeleted": false,
        "_id": "5ebd5109134a2b11502bcece",
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-14T14:09:13.699Z",
        "updatedAt": "2020-05-14T14:09:13.699Z",
        "__v": 0
    },
    "5ec24ef245359a38dc48f7b7": {
        "roomTypeIDs": [
            "5ec24ef245359a38dc48f7b6"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec24ef245359a38dc48f7b7",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T09:01:38.241Z",
        "updatedAt": "2020-05-18T09:01:38.241Z",
        "__v": 0
    },
    "5ec2532033db3e0d2d3f9b03": {
        "roomTypeIDs": [
            "5ec2532033db3e0d2d3f9b02"
        ],
        "isDeleted": false,
        "_id": "5ec2532033db3e0d2d3f9b03",
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T09:19:28.523Z",
        "updatedAt": "2020-05-18T09:19:28.523Z",
        "__v": 0
    },
    "5ec2573633db3e0d2d3f9f4d": {
        "roomTypeIDs": [
            "5ec2573633db3e0d2d3f9f4c"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec2573633db3e0d2d3f9f4d",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T09:36:54.037Z",
        "updatedAt": "2020-05-18T09:36:54.037Z",
        "__v": 0
    },
    "5ec257bc33db3e0d2d3fa0b7": {
        "roomTypeIDs": [
            "5ec257bc33db3e0d2d3fa0b6"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec257bc33db3e0d2d3fa0b7",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T09:39:08.619Z",
        "updatedAt": "2020-05-18T09:39:08.619Z",
        "__v": 0
    },
    "5ec2590133db3e0d2d3fa221": {
        "roomTypeIDs": [
            "5ec2590133db3e0d2d3fa220"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec2590133db3e0d2d3fa221",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T09:44:33.266Z",
        "updatedAt": "2020-05-18T09:44:33.266Z",
        "__v": 0
    },
    "5ec25cfc4c578d4e2ec1fb71": {
        "roomTypeIDs": [
            "5ec25cfc4c578d4e2ec1fb70"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec25cfc4c578d4e2ec1fb71",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T10:01:32.628Z",
        "updatedAt": "2020-05-18T10:01:32.628Z",
        "__v": 0
    },
    "5ec2638f33db3e0d2d3fa946": {
        "roomTypeIDs": [
            "5ec2638f33db3e0d2d3fa945"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec2638f33db3e0d2d3fa946",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T10:29:35.544Z",
        "updatedAt": "2020-05-18T10:29:35.544Z",
        "__v": 0
    },
    "5ec270bace1fa556f0233990": {
        "roomTypeIDs": [
            "5ec270bace1fa556f023398f"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec270bace1fa556f0233990",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T11:25:46.377Z",
        "updatedAt": "2020-05-18T11:25:46.377Z",
        "__v": 0
    },
    "5ec2710529a4f7579e5d0068": {
        "roomTypeIDs": [
            "5ec2710529a4f7579e5d0067"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec2710529a4f7579e5d0068",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T11:27:01.104Z",
        "updatedAt": "2020-05-18T11:27:01.104Z",
        "__v": 0
    },
    "5ec2714ff461e458440fae60": {
        "roomTypeIDs": [
            "5ec2714ff461e458440fae5f"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec2714ff461e458440fae60",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T11:28:15.039Z",
        "updatedAt": "2020-05-18T11:28:15.039Z",
        "__v": 0
    },
    "5ec2716494cb6e5884192bfd": {
        "roomTypeIDs": [
            "5ec2716494cb6e5884192bfc"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec2716494cb6e5884192bfd",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T11:28:36.062Z",
        "updatedAt": "2020-05-18T11:28:36.062Z",
        "__v": 0
    },
    "5ec2718e6e956b58dba3f3e2": {
        "roomTypeIDs": [
            "5ec2718e6e956b58dba3f3e1"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec2718e6e956b58dba3f3e2",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T11:29:18.505Z",
        "updatedAt": "2020-05-18T11:29:18.505Z",
        "__v": 0
    },
    "5ec271a50b9002591ed7e52a": {
        "roomTypeIDs": [
            "5ec271a50b9002591ed7e529"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec271a50b9002591ed7e52a",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T11:29:41.098Z",
        "updatedAt": "2020-05-18T11:29:41.098Z",
        "__v": 0
    },
    "5ec271d1a7e37a598e312519": {
        "roomTypeIDs": [
            "5ec271d1a7e37a598e312518"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec271d1a7e37a598e312519",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T11:30:25.882Z",
        "updatedAt": "2020-05-18T11:30:25.882Z",
        "__v": 0
    },
    "5ec2726cb346985ac9c1d5f7": {
        "roomTypeIDs": [
            "5ec2726cb346985ac9c1d5f6"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec2726cb346985ac9c1d5f7",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T11:33:00.028Z",
        "updatedAt": "2020-05-18T11:33:00.028Z",
        "__v": 0
    },
    "5ec2729845dd985b27b0a556": {
        "roomTypeIDs": [
            "5ec2729845dd985b27b0a555"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec2729845dd985b27b0a556",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T11:33:44.943Z",
        "updatedAt": "2020-05-18T11:33:44.943Z",
        "__v": 0
    },
    "5ec272d046048e5b9132064b": {
        "roomTypeIDs": [
            "5ec272d046048e5b9132064a"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec272d046048e5b9132064b",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T11:34:40.200Z",
        "updatedAt": "2020-05-18T11:34:40.200Z",
        "__v": 0
    },
    "5ec27c687625a0260af71d74": {
        "roomTypeIDs": [
            "5ec27c687625a0260af71d73"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec27c687625a0260af71d74",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T12:15:36.584Z",
        "updatedAt": "2020-05-18T12:15:36.584Z",
        "__v": 0
    },
    "5ec281057625a0260af71ee4": {
        "roomTypeIDs": [
            "5ec281057625a0260af71ee3"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec281057625a0260af71ee4",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T12:35:17.988Z",
        "updatedAt": "2020-05-18T12:35:17.988Z",
        "__v": 0
    },
    "5ec2c24064251b0439f2118c": {
        "roomTypeIDs": [
            "5ec2c24064251b0439f2118b"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec2c24064251b0439f2118c",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-18T17:13:36.502Z",
        "updatedAt": "2020-05-18T17:13:36.502Z",
        "__v": 0
    },
    "5ec3a7d56370e8ab9993ac5e": {
        "roomTypeIDs": [
            "5ec3a7d56370e8ab9993ac5d"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec3a7d56370e8ab9993ac5e",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-19T09:33:09.089Z",
        "updatedAt": "2020-05-19T09:33:09.089Z",
        "__v": 0
    },
    "5ec6569bda64d61482fda79e": {
        "roomTypeIDs": [
            "5ec6569bda64d61482fda79d"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec6569bda64d61482fda79e",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-21T10:23:23.881Z",
        "updatedAt": "2020-05-21T10:23:23.881Z",
        "__v": 0
    },
    "5ec65dcc9d7dac14dbc16bf0": {
        "roomTypeIDs": [
            "5ec65dcc9d7dac14dbc16bef"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec65dcc9d7dac14dbc16bf0",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-05-21T10:54:04.459Z",
        "updatedAt": "2020-05-21T10:54:04.459Z",
        "__v": 0
    },
    "5ec6d99d00cc71197c903a1a": {
        "roomTypeIDs": [
            "5df7752d52394d0cd5cf743f",
            "5df7752d52394d0cd5cf7442",
            "5df7752d52394d0cd5cf7445"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ec6d99d00cc71197c903a1a",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "mya": {
            "_id": "5ec6d99d00cc71197c903a1b",
            "name": "standard",
            "ratePlanCode": "999999",
            "isDefault": true
        },
        "createdAt": "2020-05-21T19:42:21.214Z",
        "updatedAt": "2020-05-21T19:42:21.214Z",
        "__v": 0
    },
    "5ed6006e8f0330301d111a48": {
        "roomTypeIDs": [
            "5ed6006e8f0330301d111a47"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ed6006e8f0330301d111a48",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-06-02T07:31:58.836Z",
        "updatedAt": "2020-06-02T07:31:58.836Z",
        "__v": 0
    },
    "5edf8fabcfd396d5f953d181": {
        "roomTypeIDs": [
            "5edf8fabcfd396d5f953d180"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5edf8fabcfd396d5f953d181",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-06-09T13:33:31.834Z",
        "updatedAt": "2020-06-09T13:33:31.834Z",
        "__v": 0
    },
    "5ee9edf2c80d5324615b48ab": {
        "roomTypeIDs": [
            "5ee9edf2c80d5324615b48aa"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ee9edf2c80d5324615b48ab",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-06-17T10:18:26.627Z",
        "updatedAt": "2020-06-17T10:18:26.627Z",
        "__v": 0
    },
    "5ee9ef73b0b754248379e7f3": {
        "roomTypeIDs": [
            "5ee9ef73b0b754248379e7f2"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ee9ef73b0b754248379e7f3",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-06-17T10:24:51.845Z",
        "updatedAt": "2020-06-17T10:24:51.845Z",
        "__v": 0
    },
    "5ee9efaeb0b754248379e7f7": {
        "roomTypeIDs": [
            "5ee9efaeb0b754248379e7f6"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ee9efaeb0b754248379e7f7",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-06-17T10:25:50.943Z",
        "updatedAt": "2020-06-17T10:25:50.943Z",
        "__v": 0
    },
    "5ee9f011b0b754248379e7fb": {
        "roomTypeIDs": [
            "5ee9f011b0b754248379e7fa"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ee9f011b0b754248379e7fb",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-06-17T10:27:29.102Z",
        "updatedAt": "2020-06-17T10:27:29.102Z",
        "__v": 0
    },
    "5eea3a0d6c831ea30a4c0b72": {
        "roomTypeIDs": [
            "5eea3a0d6c831ea30a4c0b71"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5eea3a0d6c831ea30a4c0b72",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-06-17T15:43:09.915Z",
        "updatedAt": "2020-06-17T15:43:09.915Z",
        "__v": 0
    },
    "5eea3ab96c831ea30a4c0b76": {
        "roomTypeIDs": [
            "5eea3ab96c831ea30a4c0b75"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5eea3ab96c831ea30a4c0b76",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-06-17T15:46:01.249Z",
        "updatedAt": "2020-06-17T15:46:01.249Z",
        "__v": 0
    },
    "5ef099a926f2990c2253073c": {
        "roomTypeIDs": [
            "5ef099a926f2990c2253073b"
        ],
        "isDeleted": false,
        "isDefault": true,
        "isDerived": false,
        "visibility": ["Workspace", "Booking Engine"],
		"privateName": "Default",
		"publicName": "Default",
		"mapping": [],
        "_id": "5ef099a926f2990c2253073c",
        "propertyID": "5df7695cc3a0427e4a4f4f4a",
        "hw": {
            "name": "default",
            "default": 1,
            "nonrefundable": 0,
            "active": 1,
            "ratePlanCode": 99999999
        },
        "createdAt": "2020-06-22T11:44:41.158Z",
        "updatedAt": "2020-06-22T11:44:41.158Z",
        "__v": 0
    }
};

export const roomList = {
    "5df7752d52394d0cd5cf7441": {
        "available": 1,
        "rates": {
            "5e660d7d9af7d20313132031": [
                60
            ]
        }
    },
    "5df7752d52394d0cd5cf743f": {
        "available": 3,
        "rates": {
            "5e660d669af7d20313132030": [
                70
            ],
            "5e664faf6c76e509a1356299": [
                70
            ]
        }
    },
    "5df7752d52394d0cd5cf7442": {
        "available": 0,
        "rates": {
            "5e660e229af7d20313132035": [
                20
            ],
            "5e664f476c76e509a1356294": [
                20
            ]
        }
    },
    "5eea3ab96c831ea30a4c0b75": {
        "available": 7,
        "rates": {
            "5eea3ab96c831ea30a4c0b76": [
                80
            ]
        }
    },
    "5ef099a926f2990c2253073b": {
        "available": 20,
        "rates": {
            "5ef099a926f2990c2253073c": [
                25
            ]
        }
    }
}