import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '../common/Button';

const useStyles = makeStyles(theme =>({
    root: {
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: "15px 30px",
        borderTop: '1px solid #e0e0e0',
        height: "72px",
        boxSizing: "border-box",
        [theme.breakpoints.down('xs')]:{
            padding: "15px 20px",
        }
    },
    summary: {
        '& h4': { margin: 'unset' },
        '& span': {
            fontSize: "1.1rem",
            fontWeight: 600,
            color: "#777777",
        }
    }
}));

const Footer = props =>{
    const classes = useStyles();
    const { label = "", isBtnDisabled, onBtnClick, count, currency, amount } = props;
    return (
        <div className={classes.root}>
            <div className={classes.summary}>
                <h4>Total {currency}{amount}</h4>
                <span>Total items ({count})</span>
            </div>
            
            <Button
                disabled={isBtnDisabled}
                onClick={onBtnClick}
            >
                {label}
            </Button>
        </div>
    )
}

export default Footer;