import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { getCurrency } from '../../booking_engine/helper';

const useStyles = makeStyles(theme => ({
    invoiceBtn: {
        textAlign: 'center'
    },
    details: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    mainContainer: {
        padding: '20px',
        boxShadow: '0 0 8px 0px #dedede'
    },
    cardPrimaryText: {
        color: '#2e912e'
    },
    roomImageHolder: {
        height: '70px',
        width: '100%',

        [theme.breakpoints.up('md')]: {
            height: '70px',
        },
    },

    roomImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        borderRadius: '20px'
    },
    roomInfoDesc: {
        paddingLeft: "10px"
    },
    roomInfoContainer: {
        marginBottom: "10px"
    }
}));

const BookingConfirm = ({
    reservationDetails,
    bookingDetails,
    roomTypes,
    currency,
    dispatch
}) => {
    const classes = useStyles();
    return (
        <Card className={classes.mainContainer}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3" align="center" color="textPrimary" gutterBottom>
                        <span className={classes.cardPrimaryText}>Payment Successful!</span>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" align="justify" component="p" gutterBottom>
                        {reservationDetails.username}, your booking has been confirmed. Below are your booking details.
                    </Typography>
                </Grid>
                <Grid item xs={12} container className={classes.roomInfoContainer}>
                    <Typography variant="subtitle2" component="div" gutterBottom>
                        {Object.keys(bookingDetails).map((id, index) => (
                            <Box key={index}>
                                {`${index + 1}) ${roomTypes[id].name}  x${bookingDetails[id].noOfBedsBooked} bed(s)`}
                            </Box>
                        ))}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" component="div" gutterBottom>
                        <Box className={classes.details}>
                            <Box>Total amount</Box>
                            <Box>{getCurrency(currency, reservationDetails.totalAmount)}</Box>
                        </Box>
                        <Box className={classes.details}>
                            <Box>Amount paid</Box>
                            <Box>{getCurrency(currency, reservationDetails.amountPaid)}</Box>
                        </Box>
                        <Box className={classes.details}>
                            <Box>Balance</Box>
                            <Box>{getCurrency(currency, reservationDetails.balance)}</Box>
                        </Box>
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.invoiceBtn}>
                    <Button color="primary" variant="outlined">Download Invoice</Button>
                </Grid>
            </Grid>
        </Card>
    );
}

const mapStateToProps = state => {
    const { bookingEngine } = state;
    return {
        reservationDetails: bookingEngine.reservationDetails,
        bookingDetails: bookingEngine.bookingDetails,
        roomTypes: bookingEngine.roomTypes,
        currency: bookingEngine.currency
    }
}

export default connect(mapStateToProps)(BookingConfirm);