import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Select from 'react-select';

import Dialog from '../common/CustomDialog';
import Footer from '../common/Footer';
import CustomInput from '../common/CustomInput';
import NumberInput from '../../common/NumberInput';
import { resetError, createReservationTax } from '../../../redux/actions/reservations';

const useStyles = makeStyles(theme =>({
    root: {
        '& .dialogBody': {
            minHeight: "360px",
            maxHeight: "360px",
        },
    },
    textField: {
        height: "45px",
        fontWeight: 600,
        '& input': {
            paddingLeft: '10px',
            fontWeight: 500,
        }
    },
    input: {
        marginBottom: '30px'
    },
}));

const selectStyle = {
    control: (base, state) => ({
        ...base,
        border: 'none',
        boxShadow: 0,
        cursor: 'pointer',
        margin: "0px",
        height: '45px',
        fontSize: "1.3rem",
        '&:hover': { border: 'none' },
    }),
    indicatorSeparator: (base, state) => ({
        display: 'none'
    })
};

function getTaxOptions(taxes) {
    const taxOptions = [];
    for(const [taxID, taxData] of Object.entries(taxes)){
        if(taxData.calculationMode == 'exclusive'){
            taxOptions.push({
                value: taxID,
                label: taxData.name
            })
        }
    }
    return taxOptions;
}

const TaxModal = props =>{
    const classes = useStyles();
    const { closeModalHandler, taxes, isLoading, errors, dispatch, reservationID } = props;
    const [formInputs, setFormInputs] = useState({ taxID: null, value: 0 });
    const [successMsg, setSuccessMsg] = useState('');
    const taxOptions = getTaxOptions(taxes);

    const onSelectChange = data =>{
        setFormInputs({ ...formInputs, taxID: data.value });
    }

    const onInputChange = event =>{
        const value = event.target.value;
        setFormInputs({ ...formInputs, value });
    }

    const submitHandler = () =>{
        const payload = { ...formInputs };
        dispatch(createReservationTax(reservationID, payload))
            .then(success =>{
                if(success){
                    setSuccessMsg('Tax added successfully');
                    setTimeout(closeModalHandler, 2000);
                }
            })
    }

    const handleCloseError = () =>{
        dispatch(resetError('CREATE_RESERVATION_TAX'));
    }

    return(
        <Dialog
            headerTitle="Tax"
            className={classes.root}
            closeModalHandler={closeModalHandler}
            isLoading={isLoading}
            errors={errors}
            handleCloseError={handleCloseError}
            showMessage={successMsg}
        >
            <CustomInput
                label="Tax"
                className={classes.input}
                createMode
            >
                <Select
                    styles={selectStyle}
                    options={taxOptions}
                    value={taxOptions.find(e => e.value === formInputs.taxID)}
                    onChange={onSelectChange}
                />
            </CustomInput>

            <CustomInput
                label="Value"
                className={classes.input}
                createMode
            >
                <NumberInput
                    variantType="base"
                    className={classes.textField}
                    value={formInputs.value || ''}
                    onChange={onInputChange}
                    id="value"
                    type="number"
                    min={0}
                    fullWidth
                />
            </CustomInput>

            <Footer 
                label="Submit"
                isBtnDisabled={!(formInputs.taxID && formInputs.value > 0)}
                onBtnClick={submitHandler}
            />
        </Dialog>
    )
}

const mapStateToProps = state => {
    const { loading, error } = state;
    return {
        isLoading: loading.CREATE_RESERVATION_TAX,
        errors: error.CREATE_RESERVATION_TAX
    }
}

export default connect(mapStateToProps)(TaxModal);