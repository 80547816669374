import { BED_STATUSES, RESERVATION_STATUS } from "../../utils/constants";
import cloneDeep from "lodash/cloneDeep";
import { SORT_OPTIONS } from "./constants";
import { BED_STATUS_CONFIG } from "./bedStatusConfig";

function propertyFilter(data) {
    this.roomArray = data;

    this.building = function (buildingID) {
        if (!buildingID) return this;
        this.roomArray = this.roomArray.filter(item => item.buildingID == buildingID);
        return this;
    }

    this.floor = function (floor) {
        if (!floor) return this;
        this.roomArray = this.roomArray.filter(item => item.floor == floor);
        return this;
    }

    this.room = function (roomID) {
        if (!roomID) return this;
        this.roomArray = this.roomArray.filter(item => item.roomID == roomID);
        return this;
    }

    this.roomtype = function (roomTypeID) {
        if (!roomTypeID) return this;
        this.roomArray = this.roomArray.filter(item => item.roomTypeID == roomTypeID);
        return this;
    }

    this.status = function (statuses) {
        if (statuses.length == 0) return this;

        const updatedRoomArray = [];

        for(const item of this.roomArray){
            let hkBedInfo = [...item.hkBedInfo];
            hkBedInfo = hkBedInfo.filter(room => statuses.includes(room.bedStatus));

            if(hkBedInfo.length > 0){
                updatedRoomArray.push({
                    ...item,
                    hkBedInfo
                });
            }
        }
        this.roomArray = [...updatedRoomArray];
        return this;
    },

    this.sort = function (type, roomsInfo){
        if(!type) return this;
        this.roomArray.sort((a,b) =>{
            const roomA = roomsInfo?.[a.roomID]?.name?.toLocaleLowerCase();
            const roomB = roomsInfo?.[b.roomID]?.name?.toLocaleLowerCase();
            const sortVal = {
                [SORT_OPTIONS[0].value]: { comp1: -1, comp2: 1},
                [SORT_OPTIONS[1].value]: { comp1: 1, comp2: -1},
            }[type];

            if(roomA < roomB) return sortVal.comp1;
            if(roomA > roomB) return sortVal.comp2; 
            
            return 0;
        })
        return this;
    }

    this.result = function () {
        return this.roomArray;
    }
}

export function applyPropertyfilters(filters, roomsListData, roomsInfo){

    if(roomsListData.length == 0) return [];
  
    const roomsData = cloneDeep(roomsListData);
    const filterObj = new propertyFilter(roomsData);
    const filteredData = filterObj.building(filters.buildingID)
      .floor(filters.floor)
      .sort(filters.sort, roomsInfo)
      .room(filters.roomID)
      .roomtype(filters.roomTypeID)
      .status(filters.statuses)
      .result();
  
    return filteredData;
}

export function getRoomOptions(rooms, roomTypes) {
    const roomOptions = [];

    for (const [id, room] of Object.entries(rooms)) {
        
        const primaryRoomTypeID = room?.primaryRoomTypeID || null;

        if (primaryRoomTypeID && roomTypes?.[primaryRoomTypeID]?.isActive){
            roomOptions.push({
                value: id,
                label: room.name
            });
        }
    }
    return roomOptions;
}

export function getRoomtypeOptions(roomTypes){
    const roomtypeOptions = [];
    for (const [id, roomType] of Object.entries(roomTypes)) {

        if(!roomType.isActive) continue;

        roomtypeOptions.push({
            value: id,
            label: roomType.name
        });
        
    }

    return roomtypeOptions;
}

export function getBuildingOptions(buildings){
    const buildingOptions = [];

    for (const [buildingID, building] of Object.entries(buildings)) {

        buildingOptions.push({
            value: buildingID,
            label: building.name,
            floors: building.floors
        });
    }

    return buildingOptions;
}

export function bedStatusInput(reservations, bedStatus) {

    let data = {
        blockOneStatus: '', //reservation[0]
        blockTwoStatus: '', //reservation[1]
        status: bedStatus
    }

    for(const [i, reservation] of Object.entries(reservations)){
        let status = 'empty';
        
        if(reservation.reservationID){
            status = reservation.status;

            if(status == RESERVATION_STATUS.ONLINE_CHECKED_IN){
                status = RESERVATION_STATUS.CONFIRMED;
            }
        }

        if(!reservation.reservationID && reservation.isBlocked){
            status = 'mappingChange';
        }

        if(i == 0){
            data.blockOneStatus = status;
            continue;
        }

        data.blockTwoStatus = status;
    }

    return data;
} 

export function getNextBedStatus(status) {
    switch(status){
        case BED_STATUSES.unclean: 
            return BED_STATUSES.ready;

        case BED_STATUSES.ready: 
            return BED_STATUSES.occupied;

        case BED_STATUSES.occupied: 
            return BED_STATUSES.unclean;

        default:
            return BED_STATUSES.unclean;
    }
}

export function processNextBedStatus(data){
    let applicableStatuses = BED_STATUS_CONFIG.find(item => item.blockOneStatus == data.blockOneStatus && item.blockTwoStatus == data.blockTwoStatus)?.statuses;
    
    //Returning default bed status if input status not valid for blockOneStatus and blockTwoStatus combination...........
    if(!applicableStatuses.includes(data.status)) return BED_STATUSES.unclean;
    
    applicableStatuses = applicableStatuses.filter(val => val !== data.status);

    if(applicableStatuses.length == 0) return data.status;

    if(applicableStatuses.length == 1) return applicableStatuses[0];

    let nextStatus = data.status;

    for(const status of Object.keys(BED_STATUSES)){
        nextStatus = getNextBedStatus(nextStatus);
        if(applicableStatuses.includes(nextStatus)) break;
    }

    return nextStatus;
}

export function checkIfSingleSpannedResv(reservations) {
    let isSingleSpanned = false;
    let reservationID = null;

    for(const [index, reservation] of Object.entries(reservations)){

        if(!reservation?.reservationID) break;
        
        if(index == 1 && reservationID == reservation.reservationID){
            isSingleSpanned = true;
            break;
        }

        reservationID = reservation.reservationID;
    }

    return isSingleSpanned;
}

export function updateBedsStateList(beds, updatedBeds) {
    const bedsData = cloneDeep(beds);
    for(const bed of updatedBeds){
        bedsData[bed.bedID].bedStatus = bed.status;
    }
    return bedsData;
}