import React from 'react';
import { makeStyles } from '@material-ui/core';
import { withRouter } from 'react-router';

import SearchAdvanced from './SearchAdvanced';
import SearchResults from './SearchResults';

import { PAGES } from '../../utils/constants';

const useStyles = makeStyles (theme => ({
  root: {
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    width: "calc(100% - 80px)",
    minHeight: 'calc(100vh - 120px)',
    background: '#F8F9FB',
    padding: "0px 40px 0px 40px",
    paddingTop: theme.spacing(5),
    marginTop: 70,
    [theme.breakpoints.down('xs')]: {
      marginTop: 60,
      paddingTop: 20,
    }
  },
}))

const SearchPage = (props) => {
 
  const classes = useStyles();

  const handleGoReservation = (propertyCode, reservCode) => {
    props.history.push(`${propertyCode}/${PAGES.RESERVATION}/${reservCode}`);
  };

  return (
    <div className={classes.root}>
      <SearchAdvanced handleClose={props.handleClose}/>
      <SearchResults property={props.property} handleGoReservation={handleGoReservation} />
    </div>
  )
}

export default withRouter(SearchPage);