import http from "../utils/http";
import { normalize } from "normalizr";
import { cashDrawerSchema, cashAccountSchema, closeCashDrawerSchema } from "../schema/cashDrawer";
import { fetchDateFormat, DATE_FORMATS } from "../../utils/utility";

//----------------------------------------------------------------
// LOAD ALL CASH DRAWERS DETAILS
//----------------------------------------------------------------
export function loadAllCashDrawers(propertyID) {
  const ACTION_NAME = "LOAD_ALL_CASH_DRAWERS";
  // return function (dispatch) {
  //   dispatch({ type: `${ACTION_NAME}_REQUEST` });
  //   if(!propertyID) {
  //     dispatch({
  //       type: `${ACTION_NAME}_SUCCESS`,
  //       payload: {cashDrawer : null}
  //     });
  //     return;
  //   }
  //   return http
  //     .get("/api/cashDrawers/" + propertyID)
  //     .then(successResult => {
  //       let normalizedData = normalize(successResult.data, [cashDrawerSchema]);
  //       dispatch({
  //         type: `${ACTION_NAME}_SUCCESS`,
  //         payload: normalizedData.entities
  //       });
  //     })
  //     .catch(errorResult => {
  //       let error =
  //         errorResult && errorResult.response && errorResult.response.data
  //           ? errorResult.response.data
  //           : errorResult;
  //       dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
  //     });
  // };
  return function (dispatch, getState) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    if(!propertyID) {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: {cashDrawer : null}
        });
        return;
    }
    const { auth } = getState();
    const properties = auth.loginSuccess.accessToProperties;
    const selectedProperty = properties.find(data => data.propertyID == propertyID );
    let normalizedData = normalize(selectedProperty.cashDrawers, [cashDrawerSchema]);
    dispatch({
      type: `${ACTION_NAME}_SUCCESS`,
      payload: normalizedData.entities
    });
  }
}

//----------------------------------------------------------------
// LOAD ONE CASH DRAWER DETAILS
//----------------------------------------------------------------
export function loadOneCashDrawer(id) {
  const ACTION_NAME = "LOAD_ONE_CASH_DRAWER";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/cashDrawer/" + id)
      .then(successResult => {
        let normalizedData = normalize(successResult.data, cashDrawerSchema);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}
//----------------------------------------------------------------
// OPEN CASH DRAWER
//----------------------------------------------------------------
export function openCashDrawer(id, payload) {
  const ACTION_NAME = "OPEN_CASH_DRAWER";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/cashDrawer/" + id + "/open/", payload)
      .then(successResult => {
        let normalizedData = normalize(successResult.data, cashDrawerSchema);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities
        });
        dispatch({
          type: `LOAD_ONE_CASH_DRAWER_SUCCESS`,
          payload: normalizedData.entities
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}
//----------------------------------------------------------------
// CLOSE CASH DRAWER
//----------------------------------------------------------------
export function closeCashDrawer(id, payload) {
  const ACTION_NAME = "CLOSE_CASH_DRAWER";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/cashDrawer/" + id + "/close/", payload)
      .then(successResult => {
        let normalizedData = normalize(successResult.data, closeCashDrawerSchema);
        // console.log("normalizedData", normalizedData)
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: {
            closeCashDrawerID: id,
            closeCashDrawer: normalizedData.entities.closeCashDrawer,
            transactions: normalizedData.entities.transactions
          }
        });
        dispatch({
          type: `LOAD_ONE_CASH_DRAWER_SUCCESS`,
          payload: { cashDrawer: {}, sessions: {} }
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}
//----------------------------------------------------------------
//SET CASH DRAWER CLOSE VALUE
//----------------------------------------------------------------
export function setCashDrawerDialogCloseValue(payload) {
  return {
    type: "SET_CASH_DRAWER_DIALOG_CLOSE_VALUE",
    payload: payload
  };
}
//----------------------------------------------------------------
// LOAD ACCOUNTS
//----------------------------------------------------------------
export function loadCashAccounts(id) {
  const ACTION_NAME = "LOAD_CASH_ACCOUNTS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/cashAccountItems")
      .then(successResult => {
        let normalizedData = normalize(successResult.data, [cashAccountSchema]);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}
//----------------------------------------------------------------
// LOAD TRANSACTION REPORT
//----------------------------------------------------------------
export function loadTransactionReport(id) {
  const ACTION_NAME = "LOAD_TRANSACTION_REPORT";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/transactionReport/" + id, { responseType: 'arraybuffer' })
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}
//----------------------------------------------------------------
// POST CASH IN OUT
//----------------------------------------------------------------
export function createCashFlow(payload) {
  const ACTION_NAME = "CREATE_CASH_FLOW";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/transaction/cashflow", payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}