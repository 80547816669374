import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import moment from 'moment-timezone';
import { getAmtFormat, humanise, isDateBefore, fetchDateFormat, DATE_FORMATS } from '../../utils/utility';
import { toTitleCase } from '../../utils/wordUtils';
import { getAccommodationName } from '../../utils/helper';

export const fetchGuestAccomodation = (accommodationList = []) =>{
    const guestAccomodation = cloneDeep(accommodationList)
    .reduce((acc,curr) =>{
        acc[curr.guestNumber] = acc[curr.guestNumber] || [];
        acc[curr.guestNumber].push(curr);
        return acc;
    }, {});

    return guestAccomodation;
}

export const getDurationCount = (startDate, endDate) =>{
    startDate = moment(startDate);
    endDate = moment(endDate);
    return endDate.diff(startDate, 'days');
}

export const formatDateRange = (dates, options = { dateFormat: 'YYYY-MM-DD', seperator: '-' }) =>{
    const startDate = moment(dates.startDate).format(options.dateFormat); 
    const endDate = moment(dates.endDate).format(options.dateFormat); 
    const formatedDate = `${startDate} ${options.seperator} ${endDate}`;
    return formatedDate;  
}

export const getAccomodationInfo = (data, roomsData) =>{
    const { roomTypes, rooms, beds } = roomsData;

    const roomCapacity = roomTypes?.[data.roomTypeID]?.bedsPerRoom;
    const dormOrPrivate = roomTypes?.[data.roomTypeID]?.dormOrPrivate;
    let roomName = rooms?.[data.roomID]?.name || '';
    let bedName = beds?.[data.bedID]?.name || '';

    // const name = dormOrPrivate === 'dorm' ?
    //     bedName.toLowerCase().includes(roomName.toLowerCase()) ?
    //         bedName
    //         :
    //         ((roomName || '') + ' ' + (bedName || '')).trim()
    //     :
    //     roomName;

    const name = getAccommodationName({ dormOrPrivate, roomName, bedName });
    return { name, dormOrPrivate, roomCapacity };
}

export const getPropertyCurrency = (register, property) =>{
    const propertyID = register.propertyID;
    const propertyInfo = property?.property?.[propertyID] || {};
    return propertyInfo.currency
}

export const fetchGuestAccommodations = (params) => {
    const { reservation, roomTypes, rooms, beds, currency } = params;
    
    let guestAccommodation = [...reservation.accommodationList].reduce(function (r, a) {
        r[a.guestNumber] = r[a.guestNumber] || [];
        r[a.guestNumber].push(a);
        return r;
    }, Object.create(null));

    // console.log('guestAccommodation====', guestAccommodation)

    let data = Object.keys(guestAccommodation).map(key => {
        let checkInDates = [], checkOutDates = [];
        let guestArray = [...guestAccommodation[key]];
        let isUnallocatedGuest = guestArray.find(guest => !guest.bedID);
        let index = parseInt(key) - 1;
        let accommodationItem = {
            isUnallocatedGuest: isUnallocatedGuest ? true: false,
            guestNumber: key,
            guestName: toTitleCase(
                humanise(reservation?.guests?.[index]?.firstName, reservation?.guests?.[index]?.lastName)
            ) || 'Guest '+ key
        };

        let items = [];
        guestArray.map(item => {

            let dormOrPrivate = roomTypes?.[item.roomTypeID]?.dormOrPrivate;
            let roomName = rooms?.[item.roomID]?.name || '';
            let bedName = beds?.[item.bedID]?.name || '';
            const accommodationName = getAccommodationName({ dormOrPrivate, roomName, bedName });

            checkInDates.push(item.checkIn)
            checkOutDates.push(item.checkOut)

            item.rateList.map(rate => {
                items.push({
                    roomTypeID: item.roomTypeID,
                    formattedRate: getAmtFormat(Number(rate.rate || 0), currency),
                    rate: Number(rate.rate || 0),
                    currency: currency,
                    roomID: item.roomID,
                    bedID: item.bedID,
                    ID: item._id,
                    date: rate.date,
                    dormOrPrivate,
                    name: accommodationName
                })
                return rate;
            });
            return item
        });

        accommodationItem.checkIn = checkInDates.reduce(function (a, b) { return a < b ? a : b; });
        accommodationItem.checkOut = checkOutDates.reduce(function (a, b) { return a > b ? a : b; });
        accommodationItem.items = [...items];
        return accommodationItem;
    });

    return data;
}

export const filterDataFields = (data = {}, filterObj) =>{

    const filterFields = Object.keys(filterObj);

    const filteredData = Object.keys(data)
    .filter(key => filterFields.includes(key))
    .reduce((obj, key) => (obj[key] = data[key], obj), {});

    const updatedData = { ...filterObj, ...filteredData };
    return updatedData;
}

export function formatGuestData(guestData, i18nIsoCountries){

    const updatedGuestData = cloneDeep(guestData);

    set(updatedGuestData, 'address.country', (i18nIsoCountries.getAlpha3Code(updatedGuestData.address.country, 'en') || updatedGuestData.address.country));
        
    if(updatedGuestData.dateOfBirth){
        set(updatedGuestData, 'dateOfBirth', fetchDateFormat(updatedGuestData.dateOfBirth, DATE_FORMATS.DEFAULT_DATE));
    }

    const docExpiry = updatedGuestData.documents?.[0]?.expiry || null;
    if(docExpiry){
        set(updatedGuestData, 'documents[0].expiry', fetchDateFormat(docExpiry, DATE_FORMATS.DEFAULT_DATE));
    }

    return updatedGuestData;
}

export function getTodaysDate(timezone){
    return moment.tz(new Date(), timezone).format('YYYY-MM-DD');
}

export function validateDocExpiry(docExpiryDate, timezone){
    const today = getTodaysDate(timezone);
    return isDateBefore(docExpiryDate, today) ? false : true;
}