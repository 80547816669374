import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment-timezone';

import { CheckboxButton } from '../common/Button';
import Checkbox from '../common/CustomCheckbox';

const useStyles = makeStyles(theme =>({
    root: {
    
    },
    title: {
        margin: "0px",
        marginBottom: "20px",
        fontSize: "1.2rem",
    },
    rateLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& p': {
            fontSize: "1.3rem",
            fontWeight: 600,
            margin: "5px 0px",
        }
    },
    checkboxContainer: {
        textAlign: 'end',
        marginBottom: '20px'
    },
}));

const RateList = props =>{
    const classes = useStyles();
    const { 
        rateList, 
        selectedRates, 
        currency,
        selectedAllHandler,
        onChangeHandler,
        isSelectAll 
    } = props;

    const fetchRatesLabel = (rateInfo) =>{
        return(
            <div className={classes.rateLabel}>
                <p>{moment(rateInfo.date).format('dddd, DD MMM YYYY')}</p>
                <p>{currency}{rateInfo.rate}</p>
            </div>
        )
    }

    return(
        <div>
            <h6 className={classes.title}>Select one or multiple days to change price</h6>
            
            <div className={classes.checkboxContainer}>
                <Checkbox 
                    label="Select all"
                    labelPlacement="start"
                    onChangeHandler={selectedAllHandler}
                    checked={isSelectAll}
                />
            </div>

            {rateList.map((rate) =>(
                <CheckboxButton
                    key={`rate_${rate._id}`}
                    label={fetchRatesLabel(rate)}
                    value={rate._id}
                    checked={selectedRates.includes(rate._id)}
                    onChange={onChangeHandler}
                />
            ))}
        </div>
    )
}

export default RateList;