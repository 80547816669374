import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';

import PaymentRoomItem from '../../online_checkin/Payment/PaymentRoomItem';
import PaymentForm from './stripe_form';
import Footer from '../../online_checkin/Footer';

import { calculateSumOfArray, getCurrency } from '../../booking_engine/helper';
import { fetchNoOfDays, fetchDateFormat } from '../../../utils/utility';

const styles = theme =>({
    root: {
        // border: '1px solid'
        [theme.breakpoints.up('sm')]: {
            minHeight: '330px',
        }
    },
    rateInfoContainer:{
        display: 'flex',
        justifyContent: 'space-between'
    }
});

class Payment extends Component{
    render(){
        const { 
            classes, 
            summaryList,
            currency = {
                code: '',
                currency: ''
            },
            balance,
            taxAmount,
            totalAmount,
            nextStep 
        } = this.props;
        return(
            <>
                <Box className={classes.root}>
                    <Box fontSize="20px" fontWeight="bold" mb={4} >Summary</Box>
                    {summaryList.map((data, index) =>(
                        <PaymentRoomItem key={`summary_${index}`} accommodationData={data} />
                    ))}
                    <Box className={classes.rateInfoContainer}>
                        <Box fontSize="15px" fontWeight="bold" >Total</Box>
                        <Box fontSize="15px" fontWeight="bold" >{getCurrency(currency, totalAmount)}</Box>
                    </Box>
                    { taxAmount ? (
                        <Box className={classes.rateInfoContainer} mb={2}>
                            <Box fontSize="12px" fontWeight="bold" color="gray" >Tax</Box>
                            <Box fontSize="12px" fontWeight="bold" color="gray">{getCurrency(currency, taxAmount)}</Box>
                        </Box>
                    ) : ''}
                    
                    <Box borderTop="1px solid gray" mb={2} />
                    <Box className={classes.rateInfoContainer} mb={6}>
                        <Box fontSize="17px" fontWeight="bold" >Balance</Box>
                        <Box fontSize="17px" fontWeight="bold">{getCurrency(currency, balance)}</Box>
                    </Box>
                    {balance > 0 && (
                        <>
                            <Box fontSize="20px" fontWeight="bold" mb={4} >Card details</Box>
                            <PaymentForm nextStep={nextStep} />
                        </>
                    )}
                </Box>
                {balance == 0 && (
                    <Footer 
                        stepInfo=""
                        buttonLabel="Check-in"
                        onProceed = {nextStep}
                    />
                )}
            </>
        )
    }
}

const mapStateToProps = state =>{
    const currency = state.onlineCheckin.currency;
    const accommodationList = state.onlineCheckin.accommodationList;
    const roomTypes = state.onlineCheckin.roomTypes;

    const summaryList = accommodationList.map(data=>{
        const rates = data.rateList.map(rateData => rateData.rate);
        const amount = calculateSumOfArray(rates);
        const amountWithCurrency = getCurrency(currency, amount);
        const startDate = fetchDateFormat(data.checkIn, 'ddd, MMM D');
        const endDate = fetchDateFormat(data.checkOut, 'ddd, MMM D');
        const roomTypeData = roomTypes[data.roomTypeID];
        return{
            guestCount: data.guestCount,
            duration: fetchNoOfDays(data.checkIn, data.checkOut),
            date: `${startDate} - ${endDate}`,
            amount: amountWithCurrency,
            name: roomTypes[data.roomTypeID].name,
            image: roomTypeData.images && roomTypeData.images.length > 0 ?  
            roomTypeData.images[roomTypeData.images.length - 1 ] : '/images/P_1.png'
        }
    });

    return{
        summaryList,
        currency,
        balance: state.onlineCheckin.balance,
        taxAmount: state.onlineCheckin.taxAmount,
        totalAmount: state.onlineCheckin.totalAmount,
    }
}
export default withStyles(styles)(connect(mapStateToProps)(Payment));