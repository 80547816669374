import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import DateChangeInfo from './DateChangeInfo';
import { FORM_TYPE, DEVICE_VIEW } from './Helper';
import { formatDateRange } from '../helper';

import ExtendOptions from './extend-stay/ExtendOptions';
import PreponeCheckinOptions from './change-arrival-date/PreponeCheckinOptions';
import ShortenAccomodation from './shorten-stay/ShortenAccomodation';
import ChangeBedOptions from './change-bed/ChangeBedOptions';

import { editReservation } from '../../../redux/actions/reservations';

const styles = theme =>({
    root: {
    
    },
    dateInfo: {
        marginBottom: '30px'
    },
    searchField: {
        border: "1px solid #e0e0e0",
        padding: "10px 20px",
        borderRadius: "5px",
        width: "100%",

        '& input::placeholder': {
            fontSize: '1.2rem'
        }
    },
});

const DATE_FORMAT_OPTIONS = {
    dateFormat: 'DD. MMM YYYY', 
    seperator: '-'
}

class RoomSelect extends Component {

    onSubmitHandler = (data) =>{
        const { dispatch, closeModalHandler, reservation } = this.props;
        const payload = { 
            ...data, 
            reservationID: reservation._id 
        };
        dispatch(editReservation(payload))
            .then(success =>{
                if(success) closeModalHandler();
            })
    }

    render() {
        const { 
            classes, 
            selectedAccomodation, 
            selectedDateRange, 
            view,
            options,
            type 
        } = this.props;

        const originalDates = {
            startDate: selectedAccomodation.checkIn,
            endDate: selectedAccomodation.checkOut
        }

        return (
            <>
                <div className={classes.root}>
                    
                    {type == FORM_TYPE.EXTEND  && (
                        <>
                            <DateChangeInfo
                                originalDates={formatDateRange(originalDates, DATE_FORMAT_OPTIONS)}
                                newDates={formatDateRange(selectedDateRange, DATE_FORMAT_OPTIONS)}
                            />
                            <ExtendOptions 
                                data={selectedAccomodation} 
                                range={selectedDateRange}
                                view={DEVICE_VIEW.large}
                                options={options}
                                onSubmitHandler={this.onSubmitHandler}
                            />
                        </>
                    )}

                    {type == FORM_TYPE.PRE_PONE_CHECK_IN && (
                        <>
                            <DateChangeInfo
                                originalDates={formatDateRange(originalDates, DATE_FORMAT_OPTIONS)}
                                newDates={formatDateRange(selectedDateRange, DATE_FORMAT_OPTIONS)}
                            />
                            <PreponeCheckinOptions 
                                formType={type}
                                data={selectedAccomodation} 
                                range={selectedDateRange}
                                view={DEVICE_VIEW.large}
                                options={options}
                                onSubmitHandler={this.onSubmitHandler}
                            />
                        </>
                    )}
                    
                    {type == FORM_TYPE.SHORTEN_ACCOMMODATION && (
                        <>
                            <DateChangeInfo
                                originalDates={formatDateRange(originalDates, DATE_FORMAT_OPTIONS)}
                                newDates={formatDateRange(selectedDateRange, DATE_FORMAT_OPTIONS)}
                            />
                            <ShortenAccomodation 
                                data={selectedAccomodation} 
                                range={selectedDateRange}
                                view={DEVICE_VIEW.large}
                                onSubmitHandler={this.onSubmitHandler}
                            />
                        </>
                    )}

                    {type == FORM_TYPE.BED_FORM && (
                        <>
                            <DateChangeInfo
                                originalDatesLabel="You are changing the room for the selected range"
                                originalDates={formatDateRange(selectedDateRange, DATE_FORMAT_OPTIONS)}
                            />
                            <ChangeBedOptions
                                data={selectedAccomodation} 
                                range={selectedDateRange}
                                view={DEVICE_VIEW.large}
                                options={options}
                                onSubmitHandler={this.onSubmitHandler}
                            />
                        </>
                    )}

                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    const { reservationDetails } = state;
    return {
       reservation: reservationDetails?.reservation || {},
    }
 }

export default withStyles(styles)(connect(mapStateToProps)(RoomSelect));