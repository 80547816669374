const defaultProperty = { default: true }

export default (state = defaultProperty, action) => {
  switch(action.type) {
    case "GET_STRIPE_ACCOUNT_STATUS_SUCCESS":
        return { 
          ...state, 
          stripe: { ...action.payload } 
        }
    default:
      return state;
  }
};