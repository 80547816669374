import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Select from 'react-select';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Dialog from '../common/CustomDialog';
import Footer from '../common/Footer';
import CustomInput from '../common/CustomInput';
import NumberInput from '../../common/NumberInput';
import { RadioButton } from '../common/Button';
import { TRANSACTION_MODE } from '../../../utils/constants';
import { fetchDatePerTimezone, humanise } from '../../../utils/utility';
import { resetError, transactionRefund } from '../../../redux/actions/reservations';

const useStyles = makeStyles(theme => ({
    root: {

    },
    textField: {
        height: "45px",
        fontWeight: 600,
        '& input': {
            paddingLeft: '10px',
            fontWeight: 500,
        }
    },
    input: {
        marginBottom: '40px'
    },
    radioGroup: {
        flexDirection: 'row',
        flexWrap: 'unset'
    },
    radioBtn: {
        marginRight: '10px',
        '& .MuiFormControlLabel-label': {
            borderRadius: "8px",
            fontSize: "1.2rem",
            textAlign: "center",
            padding: "12px 40px",

            [theme.breakpoints.down('xs')]: {
                padding: '12px 20px'
            }
        }
    },
    otherRadioGroup: {
        padding: "10px",
        marginTop: "10px",
    },
    error: {
        fontSize: '1.2rem',
        color: 'red',
        marginTop: 5,
        marginBottom: 5,
    },
}));

const selectStyle = {
    control: (base, state) => ({
        ...base,
        border: 'none',
        boxShadow: 0,
        cursor: 'pointer',
        margin: "0px",
        height: '45px',
        '&:hover': { border: 'none' },
    }),
    indicatorSeparator: (base, state) => ({
        display: 'none'
    })
};

const OTHER_MODE_ID = 'other';

const DEFAULT_REFUND_SOURCES = [
    { id: TRANSACTION_MODE.CASH, label: 'Cash' },
    { id: TRANSACTION_MODE.CARD, label: 'Card' },
    { id: OTHER_MODE_ID, label: 'Other' },
];

const FORMS_ERRORS = {
    amount: false,
    mode: false,
    date: false,
    transaction: false
}

function processTransactions(transactionModes, transactions) {
    let transactionList = [];
    let formattedRefundList = {};

    transactions.forEach(data => {

        const modeName = transactionModes[data.modeID].name;
        if (modeName == TRANSACTION_MODE.SURCHARGE) return;

        if (data.isRefund) {
            const refundData = { ...data, amount: Math.abs(data.amount) };

            //Refund list data .............................
            formattedRefundList[data.originalTxnID] = formattedRefundList[data.originalTxnID] ?
                [...formattedRefundList[data.originalTxnID], { ...refundData }] :
                [{ ...refundData }];

            return;
        }
        // Transaction list data...........................
        transactionList = [...transactionList, { ...data }];
    });

    return {
        transactionList: [...transactionList],
        formattedRefundList: { ...formattedRefundList }
    }
}

function convertAmountToNegative(amount) {
    let formattedAmount = parseFloat(amount);
    formattedAmount = formattedAmount * -1;
    return formattedAmount;
}

const RefundModal = props => {
    const classes = useStyles();
    const { 
        closeModalHandler, 
        transactionModes, 
        transactionModeIDs, 
        currency, 
        transactions, 
        timezone, 
        dispatch, 
        isLoading, 
        errors,
        cashDrawerOpenSessionID 
    } = props;

    const [refundSources, setRefundSources] = useState([...DEFAULT_REFUND_SOURCES]);
    const [refundSuccess, setRefundSuccess] = useState('');
    const [selectedTransactionInfo, setSelectedTransactionInfo] = useState({});
    const [otherPaymentModes, setOtherPaymentModes] = useState([]);
    const [selectedRefundMode, setSelectedRefundMode] = useState(null);
    const [formErrors, setformErrors] = useState({ ...FORMS_ERRORS });
    const [formInputs, setFormInputs] = useState({ transactionID: null, amount: '', modeID: null });

    useEffect(() => {
        const otherModes = transactionModeIDs
            .filter(id => transactionModes[id].isCustomMode)
            .map(id => ({
                label: humanise(transactionModes[id].name),
                id: transactionModes[id]._id
            }));

        setOtherPaymentModes([...otherModes]);
    }, [transactionModeIDs]);

    const getTransactionModeID = (modeName) => {
        if (modeName == OTHER_MODE_ID) return null;
        return transactionModeIDs.find(mode => transactionModes[mode].name === modeName);
    }

    const getTransactionMode = modeID => {
        const transactionMode = { ...transactionModes[modeID] };
        return transactionMode.name
    }

    const fetchTransactionList = () => {
        let formattedTransactionList = [];

        const { transactionList, formattedRefundList } = processTransactions(transactionModes, transactions);

        //CHECK IF AMOUNT OF A PARTICULAR TRANSACTION IS COMPETELY REFUNDED....................
        transactionList.forEach(transaction => {
            const transactionID = transaction._id;
            const refundData = formattedRefundList[transactionID];
            const refundedAmount = refundData ? Math.abs(refundData.reduce((sum, r) => sum + r.amount, 0)) : 0;
            // const unrefundedAmount = transaction.amount - refundedAmount;
            if (transaction.amount != refundedAmount) {
                formattedTransactionList = [...formattedTransactionList, { ...transaction }];
            }
        });

        const transactionOptions = formattedTransactionList.map(data => {

            const transactionModeName = getTransactionMode(data.modeID);
            
            if (transactionModeName === TRANSACTION_MODE.STRIPE) return null;

            const todayDate = fetchDatePerTimezone(null, timezone)
            const updatedDate = fetchDatePerTimezone(data.updatedAt, timezone)
            const diff = todayDate.diff(updatedDate, 'days')
            const date = (diff === 0) ? 'Today - ' + updatedDate.format('hh:mm A') : updatedDate.format('DD MMM YYYY - hh:mm A');

            return {
                value: data._id,
                label: `${currency}${data.amount} (${transactionModeName}) [${date}]`
            }
        }).filter(data => data);

        return { transactionList: [...formattedTransactionList], transactionOptions };
    }

    const { transactionList, transactionOptions } = fetchTransactionList();

    const transactionSelectHandler = transaction => {
        const selectedTransactionData = transactionList.find(data => data._id == transaction.value);
        setSelectedTransactionInfo({ ...selectedTransactionData });
        setFormInputs({
            ...formInputs,
            transactionID: transaction.value,
            amount: selectedTransactionData.amount
        })
        setformErrors({ ...formErrors, transaction: false });
    }

    const onAmountChangeHandler = event => {
        const value = event.target.value;
        setFormInputs({ ...formInputs, amount: value });
        setformErrors({ ...formErrors, amount: false });
    }

    const onChangeRefundSource = event => {
        const value = event.target.value;
        setSelectedRefundMode(value);
        setFormInputs({ ...formInputs, modeID: getTransactionModeID(value) });
        setformErrors({ ...formErrors, mode: false });
    }

    const handleOtherTransMode = event => {
        const value = event.target.value;
        setFormInputs({ ...formInputs, modeID: value });
        setformErrors({ ...formErrors, mode: false });
    }

    const handleValidations = () => {
        let noErrorFound = true;
        let errorList = {};

        if (!selectedTransactionInfo._id) {
            setformErrors({ ...errors, transaction: true });
            return;
        }

        if (!selectedRefundMode) {
            noErrorFound = false;
            errorList.mode = true;
        }

        if (!formInputs.amount || isNaN(formInputs.amount) || Number(formInputs.amount) === 0) {
            noErrorFound = false;
            errorList.amount = true;
        }

        setformErrors({ ...errors, ...errorList });
        return noErrorFound
    }

    const submitHandler = () => {

        if (!handleValidations()) return;

        const {
            propertyID,
            reservationID,
            _id
        } = selectedTransactionInfo;

        const params = {
            propertyID,
            reservationID,
            originalTxnID: _id
        }

        const refundAmount = convertAmountToNegative(formInputs.amount);

        const payload = {
            modeID: formInputs.modeID,
            amount: refundAmount,
            ...(selectedRefundMode == TRANSACTION_MODE.CASH && { cashDrawerSessionID: cashDrawerOpenSessionID })
        }

        const response = dispatch(transactionRefund(params, payload));
        response.then(success => {
            if (success) {
                setRefundSuccess('Refund processed successfully');
                setTimeout(closeModalHandler, 2000);
            }
        });
    }

    const handleCloseError = () => {
        dispatch(resetError('TRANSACTION_REFUND'));
    }

    return (
        <Dialog
            className={classes.root}
            headerTitle="Process refund"
            closeModalHandler={closeModalHandler}
            isLoading={isLoading}
            errors={errors}
            handleCloseError={handleCloseError}
            showMessage={refundSuccess}
        >
            <CustomInput
                label="Select transaction"
                className={classes.input}
                createMode
                error={formErrors.transaction}
            >
                <Select
                    styles={selectStyle}
                    options={transactionOptions}
                    value={transactionOptions.find(e => e.value === formInputs.transactionID)}
                    onChange={transactionSelectHandler}
                />
            </CustomInput>

            {selectedTransactionInfo._id && (
                <CustomInput
                    label="Amount"
                    className={classes.input}
                    createMode
                    error={formErrors.amount}
                >
                    <NumberInput
                        variantType="base"
                        className={classes.textField}
                        value={formInputs.amount}
                        onChange={onAmountChangeHandler}
                        id="amount"
                        type="number"
                        min={0}
                        fullWidth
                        startAdornment={currency}
                    />
                </CustomInput>
            )}

            <RadioGroup
                className={classes.radioGroup}
                name="refundSources"
                onChange={onChangeRefundSource}
                value={selectedRefundMode}
            >
                {refundSources.map((source, index) => (
                    <RadioButton
                        className={classes.radioBtn}
                        key={`refundSources_${source.id}`}
                        label={source.label}
                        value={source.id}
                    />
                ))}
            </RadioGroup>

            {formErrors.mode && <p className={classes.error}>Please select a transaction mode</p>}

            {selectedRefundMode == OTHER_MODE_ID && (
                <RadioGroup
                    aria-label="payment-mode"
                    className={classes.otherRadioGroup}
                    value={formInputs.modeID}
                    onChange={handleOtherTransMode}
                >
                    {otherPaymentModes.map(otherMode =>
                        <FormControlLabel
                            key={`paymentMode_${otherMode.id}`}
                            label={otherMode.label}
                            value={otherMode.id}
                            control={<Radio color="primary" disableRipple />}
                        />
                    )}
                </RadioGroup>
            )}

            <Footer
                label="Submit"
                onBtnClick={submitHandler}
            />
        </Dialog>
    )
}

const mapStateToProps = state => {
    const { loading, error, property, register } = state;
    const cashDrawerData = property.cashDrawer || {};
    const cashDrawerID = register.cashDrawerID;
    const cashDrawerOpenSessionID = cashDrawerData?.[cashDrawerID]?.openSessionID || null;
    
    return {
        isLoading: loading.TRANSACTION_REFUND,
        errors: error.TRANSACTION_REFUND,
        cashDrawerOpenSessionID
    }
}

export default connect(mapStateToProps)(RefundModal);