import { schema } from "normalizr";

// Define a notes schema
const note = new schema.Entity("notes", {}, { idAttribute: "_id" });

const guest = new schema.Entity("guests", {}, { idAttribute: "_id" });
const rate = new schema.Entity("rateList", {}, { idAttribute: "_id" });
const email = new schema.Entity("email", {}, { idAttribute: "_id" });
const taxes = new schema.Entity("taxesApplied", {}, { idAttribute: "taxID" });
const activityLog = new schema.Entity("activityLog", {}, { idAttribute: "_id" });
const emailTemplates = new schema.Entity("emailTemplates", {}, { idAttribute: "_id" });
const upsellList = new schema.Entity("upsellList", {}, { idAttribute: "upsellID" });

// Define your accommodation schema
const accommodation = new schema.Entity(
  "accommodationList",
  { rateList: [rate] },
  { idAttribute: "_id" }
);

// Define your single reservation schema
export const reservationSchema = new schema.Entity(
  "reservation",
  {
    notes: [note],
    guests: [guest],
    accommodationList: [accommodation],
    taxesApplied: [taxes],
    upsellList: [upsellList],
  },
  {
    idAttribute: "_id"
  }
);

// Define your all reservations schema
export const reservationAllSchema = [reservationSchema];

export const noteSchema = [note];
export const emailSchema = [email];
export const emailTemplateSchema = [emailTemplates];
export const activityLogSchema = [activityLog];