import React from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Select from 'react-select';
import _ from "lodash";

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const secondaryColor = '#5C5C5C';

const useStyles = makeStyles(theme => ({
    headerHolder: {
        boxShadow: "0 2px 20px #F0F0F0",
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: '#fff',
        fontFamily: "Roboto, sans-serif",
        zIndex: '1000'
    },

    large: {
        height: '50px',
        width: '50px',
        marginRight: '20px',
        opacity: 1
    },

    bookBtn: {
        backgroundColor: secondaryColor,
        color: '#fff',
        textTransform: 'capitalize',
        fontSize: "13px",
        padding: "0 20px",
        minWidth: '100px',
        height: '40px',

        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },

        '&:hover': {
            backgroundColor: secondaryColor,
            color: '#fff'
        }
    },

    countryFlagImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },

    countryInfo: {
        marginTop: '0px',

        [theme.breakpoints.up('sm')]: {
            marginTop: '0',
        },
    },

    countryName: {
        borderRadius: 10,
        border: '1px solid #BDBDBD',
        color: '#999999',
        marginRight: '10px',
        marginLeft: 0,
        padding: '10px 15px',
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '10px',
        },
    },

    languageSelect: {
        borderRadius: 10,
        border: '1px solid #BDBDBD',
        color: '#999999',
        marginLeft: 0,
        padding: '3px 3px',
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '10px',
        },
    },
    iconContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 30,
        marginRight: 10
    },
    icon: {
        color: '#666666',
        cursor: 'pointer',
        fontSize: '2.8rem',
        padding: 10,
        borderRadius: '50%',
        '&:hover': {
            background: '#F0F0F0',
            color: '#333333',
        }
    }
}));

const selectStyle = {
    control: base => ({
        ...base,
        border: 0,
        paddingLeft: 8,
        // This line disable the blue border
        boxShadow: 'none',
        minWidth: '100px',
        '[type="text"]': {
            fontFamily: 'Roboto, sans-serif !important',
            fontSize: '1.4rem',
            color: '#999999'
        }
    })
}

const languageArray = [
    { label: 'EN', value: 'en' },
    { label: 'DE', value: 'de' },
    { label: 'ES', value: 'es' },
    { label: 'FR', value: 'fr' },
    { label: 'IT', value: 'it' },
    { label: 'PT', value: 'pt' },
    { label: 'RU', value: 'ru' },
    { label: 'SV', value: 'sv' },
    { label: 'JA', value: 'ja'}
]

const Header = ({ title, currency, image, i18n, hideCurrency=false, kiosk=false, handleResetKiosk }) => {
    const classes = useStyles();
    const theme = useTheme();
    const deviceMD = useMediaQuery(theme.breakpoints.up('sm'));
    const [selected, setSelected] = React.useState(languageArray[0])
    
    const selectLanguage = (e) => {
        setSelected(e)
        i18n.changeLanguage(e.value);
    }

    React.useEffect(() => {
        const currentLanguage = i18next.language
        const language = _.find(languageArray, {value: currentLanguage});
        if(language) {
            setSelected(language)
        }
    }, [i18n])

    return (
        <Typography component="div" className={classes.headerHolder}>
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} >
                <Box display="flex" alignItems="center" fontWeight="bold">
                    <Avatar alt={title} src={image} className={classes.large} mr={3} />
                    <span>{title}</span>
                </Box>
                <Box display="flex" alignItems="center" fontWeight="bold" flexDirection={`${deviceMD ? 'row' : 'column'}`}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={`${deviceMD ? 'flex-start' : 'space-between'}`}
                        className={classes.countryInfo}
                    >
                        {!hideCurrency && <Box className={classes.countryName}>{currency}</Box>}
                        <Box className={classes.languageSelect}>
                            <Select
                                //defaultValue={languageArray[0]}
                                value={selected}
                                styles={selectStyle}
                                options={languageArray}
                                onChange={(e) => selectLanguage(e)}
                                theme={theme => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#666666',
                                        primary25: '#dddddd'
                                    }
                                })}
                            />
                        </Box>
                        {kiosk && <Box display="flex" alignItems="center">
                            <div className={classes.iconContainer}><CloseIcon className={classes.icon} onClick={handleResetKiosk} /></div>
                        </Box>}
                    </Box>
                </Box>
            </Box>
        </Typography>
    )
}

export default withTranslation()(Header);