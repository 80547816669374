const RoomsReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOAD_ROOM_SUCCESS":
      return { ...state, rooms: action.payload };
    //----------------------------------------------------------------------------------------
    case "LOAD_ROOM_TYPE_SUCCESS":
      return { ...state, roomTypes: action.payload };
    //----------------------------------------------------------------------------------------
    case "LOAD_BEDS_SUCCESS":
      return { ...state, beds: action.payload };
    //----------------------------------------------------------------------------------------
    case "LOAD_TAX_SUCCESS":
      return { ...state, taxes: action.payload };
    //----------------------------------------------------------------------------------------
    case "LOAD_ROOM_SUGGESTION_REQUEST":
      return { ...state, suggestions: null };
    case "LOAD_ROOM_SUGGESTION_SUCCESS":
      return { ...state, suggestions: action.payload };
    case "LOAD_ROOM_SUGGESTION_FAILURE":
      return { ...state, suggestions: null };
    //----------------------------------------------------------------------------------------

    default:
      return state;
  }
};

export default RoomsReducer;
