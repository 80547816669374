import http from "../utils/http";
import axios from "axios";
import { normalize } from "normalizr";
import { roomInfoSchema } from "../schema/bookingEngineSchema";
import { guestSchema } from "../schema/onlineCheckin";

//http.defaults.headers.common["Authorization"] = token;

export function fetchPropertyInfo(propertyHandle, pageNotFoundHandler){
    const ACTION_NAME = "FETCH_CHECKIN_ROOMS_INFO";
    return function (dispatch) {
        dispatch(showCheckinLoader());
        dispatch({ type: `${ACTION_NAME}_REQUEST`});
        return http
        .get(`/api/bookEngine/content/${propertyHandle}?appFeature=ONLINE_CHECKIN`)
        .then(successResult => {
            let normalizedData = normalize(successResult.data, roomInfoSchema);
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: {
                    propertyDetails: successResult.data.property,
                    roomTypes: normalizedData.entities.roomTypes,
                    rooms: normalizedData.entities.rooms,
                    beds: normalizedData.entities.beds,
                    taxItems: normalizedData.entities.taxItems,
                    ratePlans: normalizedData.entities.ratePlans,
                    s3Path: successResult.data.s3Path,
                    settings: successResult.data.settings
                }
            });
            http.defaults.headers.common["Authorization"] = successResult.data.property._id;
            dispatch(hideCheckinLoader());
        })
        .catch(errorResult => {
            const isApiError = (errorResult && 
                errorResult.response && 
                errorResult.response.data) ? true: false;

            if(isApiError && 
                errorResult.response.status && 
                errorResult.response.status == 404
            ){
                pageNotFoundHandler();
                return;
            }

            let error = isApiError ? errorResult.response.data : errorResult;
            dispatch(showCheckinError(error));
            console.error('Internal server error');
            return false;
        });
    }
}

export const fetchReservationDetails = (params, nextStep) =>{
    const ACTION_NAME = "FETCH_RESERVATION_DETAILS";
    return function (dispatch) {
        dispatch(showCheckinLoader());
        dispatch({ type: `${ACTION_NAME}_REQUEST`});
        return http
        .get(`/api/self/booking`,{ params })
        .then(successResult => {
            dispatch(hideCheckinLoader());
            const { 
                accommodationList, 
                guests, 
                _id,
                balance,
                taxAmount,
                totalAmount, 
                checkIn,
                checkOut,
                status
            } = successResult.data.reservation;

            let accomodationLinkRequired = false;

            //Adding master booker customerID to accommodationList 1 by default..........
            const hasNoCustomers = accommodationList.every(data =>data.customerID == 0);
            if(hasNoCustomers){
                accomodationLinkRequired = true;
                accommodationList[0].customerID = [guests[0]._id];
            }
            
            // const  roomTypes = _.groupBy(accommodationList, room => room.roomTypeID);
            let normalizedData = normalize(guests, guestSchema);

            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: {
                    accommodationList,
                    guests: normalizedData.entities.guests,
                    reservationID: _id,
                    balance,
                    taxAmount,
                    totalAmount,
                    checkIn,
                    checkOut,
                    accomodationLinkRequired,
                    status,
                    maskedCards: successResult.data.maskedCards || []
                }
            });
            nextStep();
        })
        .catch(errorResult => {
            let error =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch(showCheckinError(error));
        });
    }
}

export const getUploadUrl = (type, data, customerID) =>{
    return http
    .post(`/api/self/uploadURL/${customerID}/${type}`, data);
}

export const uploadDoc = (url, data) =>{
    //console.log(url , data);
    const options = {
        headers: {
            "Content-Type": data.type === 'pdf' ? 'application/pdf': data.type
        }
    };
    return axios.put(url, data.file, options, { key: data.key });
}

export const saveUploadData = (type, key, doc, customerID) =>{
    const ACTION_NAME = (type == 'doc') ? "UPLOAD_DOCUMENT" : "UPLOAD_PROFILE_PIC";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST`});
        return http
        .post(`/api/self/saveURL/${customerID}/${type}`, {
            key: key,
            docType: doc
        })
        .then(response => {
            switch(type){
                case 'doc': 
                    const { documents } = response.data;
                    dispatch(hideCheckinLoader());
                    dispatch({ 
                        type: `${ACTION_NAME}_SUCCESS`, 
                        payload: {
                            guestID: customerID,
                            documents
                        }
                    });
                    break;

                case 'pic': 
                    const { image } = response.data;
                    dispatch({ 
                        type: `${ACTION_NAME}_SUCCESS`, 
                        payload: {
                            guestID: customerID,
                            image
                        }
                    });
                    break;
            }
           
        })
        .catch(errorResult => {
            let error =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch(showCheckinError(error));
        });
    }
}

export const updateGuestDetails = data =>{
    const ACTION_NAME = "UPDATE_GUEST_DETAILS";
    return function (dispatch) {
        dispatch(showCheckinLoader());
        dispatch({ type: `${ACTION_NAME}_REQUEST`});
        return http
        .patch(`/api/self/customer`, data)
        .then(successResult => {
            dispatch(hideCheckinLoader());
            //console.log('successResult ',successResult);
            const { data } = successResult;
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: {
                    guestID: data._id,
                    guestData: {
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: data.email,
                        phone: data.phone,
                        address: {...data.address},
                        documents: data.documents ? [ ...data.documents] : []
                    }
                }
            });

            return true;
        })
        .catch(errorResult => {
            let error =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch(showCheckinError(error));
            return;
        });
    }
}

export const addNewGuest = (data, reservationID, accommodationID) =>{
    const ACTION_NAME = "ADD_NEW_GUEST";
    return function (dispatch) {
        dispatch(showCheckinLoader());
        dispatch({ type: `${ACTION_NAME}_REQUEST`});
        return http
        .post(`/api/self/customer/${reservationID}/${accommodationID}`, data)
        .then(successResult => {
            dispatch(hideCheckinLoader());
            //console.log('successResult ',successResult);
            const { reservation } = successResult.data;
            let normalizedData = normalize(reservation.guests, guestSchema);
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: {
                    accommodationList: reservation.accommodationList,
                    guests: normalizedData.entities.guests
                }
            });
            return true;
        })
        .catch(errorResult => {
            let error =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch(showCheckinError(error));
            return;
        });
    }
}

export const updateCustomerAccomodation = (reservationID, data) =>{
    const ACTION_NAME = "UPDATE_CUSTOMER_ACCOMODATION";
    return function (dispatch) {
        dispatch(showCheckinLoader());
        dispatch({ type: `${ACTION_NAME}_REQUEST`});
        return http
        .patch(`/api/self/attachCustomers/${reservationID}`, data)
        .then(successResult => {
            dispatch(hideCheckinLoader());
            const { data } = successResult;
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: {
                    accommodationList: [...data.accommodationList],
                }
            });
            return true;
        })
        .catch(errorResult => {
            let error =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch(showCheckinError(error));
            return;
        });
    }
}

export const saveChannexToken = (reservationID, cardData, initPaymentForm) =>{
    const ACTION_NAME = "SAVE_CHANNEX_TOKEN";
    return function (dispatch) {
        dispatch(showCheckinLoader());
        dispatch({ type: `${ACTION_NAME}_REQUEST`});
        return http
        .patch(`/api/self/data/${reservationID}`, { channexCardToken: cardData.card_token })
        .then(successResult => {
            dispatch(hideCheckinLoader());
            // const { reservation } = successResult.data;
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: { ...cardData }
            });
            return true;
        })
        .catch(errorResult => {
            
            initPaymentForm();
            
            let error =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch(showCheckinError(error));
            return;
        });
    }
}

export const markReservationStatus = (reservationID, nextStep) =>{
    return function (dispatch, getState) {
        
        const kiosk = getState().kiosk;
        const isKioskFlow = Boolean(kiosk.mode);

        dispatch(showCheckinLoader());
        return http
        .patch(`/api/self/mark/${reservationID}`, { isKiosk: isKioskFlow })
        .then(successResult => {
            nextStep();
            dispatch(hideCheckinLoader());
            return true;
        })
        .catch(errorResult => {
            let error =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch(showCheckinError(error));
            return;
        });
    }
}

export const paymentSuccess = data =>({
    type: 'PAYMENT_SUCCESS',
    payload: data.reservation
});

export const showCheckinLoader = () =>({
    type: 'ONLINE_CHECKIN_LOADER_REQUEST'
});

export const hideCheckinLoader = () =>({
    type: 'ONLINE_CHECKIN_LOADER_SUCCESS'
});

export const showCheckinError = error =>({
    type: 'ONLINE_CHECKIN_LOADER_FAILURE',
    payload: error
});
