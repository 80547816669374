import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Button,
  Typography,
  Avatar,
  DialogTitle,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  initials,
  removeSpace,
  getAmtFormat,
  humanise,
} from "../../utils/utility";
import {
  openCashDrawer,
  closeCashDrawer,
  setCashDrawerDialogCloseValue,
} from "../../redux/actions/cashDrawer";
import { CURRENCY } from "../../common/constants/currency";
import { colorPrimaryBg } from "../../utils/css";

const styles = (theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    [theme.breakpoints.down("sm")]: {
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  },
  dialogTitle: {
    backgroundColor: "#F8F9FB",
    paddingBottom: 10,
    textAlign: "center",
  },
  dialogContent: {
    background: "#F8F9FB",
    display: "flex",
    flexDirection: "column",
  },
  drawer: {
    backgroundColor: "#F8F9FB",
  },
  drawerSection: {
    background: "#FFFFFF",
    borderRadius: "5px",
    border: "1px solid #ECECEC",
    display: "inline-block",
    marginTop: 100,
    marginLeft: "25%",
    textAlign: "center",
    width: "50%",
    [theme.breakpoints.down("md")]: {
      marginLeft: "20%",
      width: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5%",
      width: "90%",
    },
  },
  avatar: {
    display: "flex",
    border: "3px solid transparent",
    cursor: "pointer",
    height: 80,
    width: 80,
    margin: "auto",
    marginTop: -40,
    marginBottom: 10,
  },
  name: {
    fontSize: "1.4rem",
    marginBottom: 45,
  },
  greeting: {
    fontWeight: 600,
    marginBottom: 45,
  },
  cashItemContainer: {
    alignItems: "center",
    display: "flex",
  },
  cashLabel: {
    color: "#828282",
    fontSize: "1.3rem",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 10,
    width: "30%",
  },
  label: {
    display: "inline-block",
    textAlign: "right",
    minWidth: 20,
    maxWidth: 40,
    marginRight: 10,
    verticalAlign: "sub",
  },
  totalContainer: {
    marginTop: 40,
    marginBottom: 40,
  },
  totalLabel: {
    fontWeight: 600,
    marginBottom: 5,
  },
  totalAmount: {
    fontSize: "2.4rem",
    fontWeight: 600,
  },
  input: {
    width: "70%",
  },
  gridContainer: {
    marginLeft: 100,
    marginRight: 100,
    [theme.breakpoints.down("md")]: {
      marginLeft: 80,
      marginRight: 80,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
    },
    [`& fieldset`]: {
      borderRadius: 10,
    },
  },
  submitContainer: {
    alignItems: "flex-end",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 40,
    width: "100%",
  },
  shiftButton: {
    backgroundColor: "#000000",
    borderRadius: 10,
    color: "#FFFFFF",
    fontSize: "1.8rem",
    fontWeight: 600,
    marginBottom: 20,
    padding: 10,
    width: 200,
    "&:hover": {
      backgroundColor: "#000000",
      color: "#FFFFFF!important",
    },
    marginRight: "25%",
    [theme.breakpoints.down("md")]: {
      marginRight: "20%",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "5%",
    },
  },
});

class CashDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      total: 0,
      cash: {},
    };
  }
  handleFieldChange = (name) => (event) => {
    let cash = { ...this.state.cash };
    if (name === "amount") {
      cash["1"] = { type: "1", count: getAmtFormat(event.target.value) };
    } else {
      cash[name] = { type: name, count: parseInt(event.target.value) };
    }
    this.setState({ cash: cash }, function () {
      this.handleCalculateTotal();
    });
  };
  handleCalculateTotal = () => {
    let cash = { ...this.state.cash };
    let total = 0;

    Object.keys(cash).forEach((key) => {
      if (cash[key] && cash[key].type && cash[key].count) {
        total = total + cash[key].type * cash[key].count;
        // total = getAmtFormat(total);
      }
    });
    this.setState({ total: getAmtFormat(total) });
  };
  handleUserShift = (type) => {
    if (this.props.cashDrawerID && this.props.cashDrawerFeatureEnabled) {
      if (type === "open") {
        this.props.dispatch(
          openCashDrawer(this.props.cashDrawerID, {
            startCount: this.state.total,
          })
        );
      } else if (type === "close") {
        this.props.dispatch(
          closeCashDrawer(this.props.cashDrawerID, {
            endCount: this.state.total,
          })
        );
      }
    }
  };

  handleCloseCashDrawer = () => {
    const type = this.props.type || "open";
    if (type === "close") {
      this.props.dispatch(setCashDrawerDialogCloseValue(true));
      this.props.handleCloseCashDrawer();
    } else if (type === "open") {
      this.props.handleLock();
    }
  };

  handleBuildCashType = (currency) => {
    currency = currency ? removeSpace(currency) : "";
    // console.log('currency', currency)
    const denomination =
      currency && CURRENCY[currency] ? CURRENCY[currency].denomination : [];
    return denomination;
  };

  render() {
    const classes = this.props.classes;
    const user = { ...this.props.user };
    const userInitials = initials(user.firstName, user.lastName);
    const avatar = user.avatarURL || null;
    const type = this.props.type || "open";
    const buttonText = type === "open" ? "Start" : "End";
    const open = this.props.cashDrawerFeatureEnabled;
    const currency = this.props.property ? this.props.property.currency : null;
    const cashType = this.handleBuildCashType(currency);

    return (
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth={true}
        fullScreen={true}
        className={classes.drawer}
      >
        {/* Close Button */}
        {/* -------------------------------------------------------- */}
        <DialogTitle className={classes.dialogTitle}>
          <IconButton
            className={classes.closeButton}
            onClick={this.handleCloseCashDrawer}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <div className={classes.drawerSection}>
            {/* Avatar and Username */}
            {/* -------------------------------------------------------- */}
            <Avatar alt={userInitials} src={avatar} className={classes.avatar}>
              {avatar ? null : userInitials}
            </Avatar>
            <Typography component="p" className={classes.name}>
              Hi {humanise(user.firstName)}!
            </Typography>
            <Typography component="p" className={classes.greeting}>
              Please enter cash amount
            </Typography>

            {/* Cash Count */}
            {/* -------------------------------------------------------- */}
            <div className={classes.gridContainer}>
              <Grid
                container
                justify={cashType.length ? "flex-start" : "center"}
                spacing={3}
              >
                {cashType.length ? (
                  cashType.map((item, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      key={index}
                      className={classes.cashItemContainer}
                    >
                      <div className={classes.cashLabel}>{item.label}</div>
                      <TextField
                        className={classes.input}
                        type="number"
                        inputProps={{ min: "0", step: "1" }}
                        style={{ borderRaduis: 25 }}
                        onChange={this.handleFieldChange(item.value)}
                        variant="outlined"
                      />
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12} sm={4}>
                    <TextField
                      className={classes.input}
                      type="number"
                      inputProps={{ min: "0", step: "1" }}
                      onChange={this.handleFieldChange("amount")}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {currency}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </div>

            {/* Submit */}
            {/* -------------------------------------------------------- */}
            <div className={classes.totalContainer}>
              <Typography variant="h6" className={classes.totalLabel}>
                Total Amount
              </Typography>
              <Typography component="p" className={classes.totalAmount}>
                {currency} {this.state.total}
              </Typography>
            </div>
          </div>

          <div className={classes.submitContainer}>
            <Button
              variant="outlined"
              className={classes.shiftButton}
              onClick={() => this.handleUserShift(type)}
            >
              {buttonText} Shift
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  let propertyID = state.register.propertyID;
  let property =
    state.property.property && propertyID
      ? { ...state.property.property[propertyID] }
      : {};
  property.features = property.features || [];
  let propertyfeatures = { ...state.property.features };
  let cashDrawerFeatureEnabled = false;
  property.features.map((fID) => {
    if (
      propertyfeatures[fID] &&
      propertyfeatures[fID].name === "Cash Drawer" &&
      propertyfeatures[fID].isEnabled === "true"
    )
      cashDrawerFeatureEnabled = true;
  });
  return {
    cashDrawerFeatureEnabled: cashDrawerFeatureEnabled,
    user: state.auth.unlockSuccess || {},
    cashDrawerID: state.register.cashDrawerID || null,
  };
};

export default withStyles(styles)(connect(mapStateToProps)(CashDrawer));
