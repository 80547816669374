const defaultState = {
  reservation: null,
  notes: null,
  guests: null,
  accommodationList: null,
  taxesApplied: null,
  selectedAccommodation: null,
  selectedReservation: null,
  upsellList: null,
  selectedHeader: null,
  maskedCards: []
}
const ReservationReducer = (
  state = { ...defaultState },
  action
) => {
  switch (action.type) {
    // ---------------------------------------------------------------- */
    case "CREATE_RESERVATION_REQUEST":
      return { ...state, ...defaultState, createReservationSuccess: null };
    case "CREATE_RESERVATION_SUCCESS":
      return { ...state, createReservationSuccess: action.payload };
    case "CREATE_RESERVATION_FAILURE":
      return { ...state, createReservationSuccess: null };
    // ----------------------------------------------------------------
    case "LOAD_RESERVATION_REQUEST":
      return { ...state, createReservationNoteSuccess: null, updateReservationStatusSuccess: null, ...defaultState, errorFound: false };
    case "LOAD_RESERVATION_SUCCESS":
      return { 
        ...state, 
        ...action.payload.reservation, 
        maskedCards: [ ...action.payload.maskedCards ], 
        errorFound: false 
      };
    case "LOAD_RESERVATION_FAILURE":
      return { ...state, ...defaultState, errorFound: true };
    // ----------------------------------------------------------------
    
    // case "CHANNEX_CAPTURE_CARD_SUCCESS":
    //   return {
    //     ...state,
    //     maskedCards: [ ...state.maskedCards, action.payload ],
    //     selectedReservation: {
    //       ...state.selectedReservation,
    //       channexCardTokens: [ 
    //         ...state.selectedReservation.channexCardTokens,
    //         action.payload.card_token
    //       ]
    //     }
    //   }

    case "UPDATE_RESERVATION_REQUEST":
      return { ...state, isReservationUpdated: null };
    case "UPDATE_RESERVATION_SUCCESS":
      return { ...state, ...action.payload, isReservationUpdated: true };
    case "UPDATE_RESERVATION_FAILURE":
      return { ...state, isReservationUpdated: null };
    // ----------------------------------------------------------------
    case "CONFIRM_RESERVATION_REQUEST":
      return { ...state, isReservationUpdated: null };
    case "CONFIRM_RESERVATION_SUCCESS":
      return { ...state, ...action.payload, isReservationUpdated: true };
    case "CONFIRM_RESERVATION_FAILURE":
      return { ...state, isReservationUpdated: null };
    // ----------------------------------------------------------------
    case "DELETE_RESERVATION_TAX_REQUEST":
      return { ...state, isReservationUpdated: null };
    case "DELETE_RESERVATION_TAX_SUCCESS":
      return { ...state, ...action.payload, isReservationUpdated: true };
    case "DELETE_RESERVATION_TAX_FAILURE":
      return { ...state, isReservationUpdated: null };
    // ----------------------------------------------------------------
    case "CANCEL_RESERVATION_REQUEST":
      return { ...state, cancelReservationSuccess: null };
    case "CANCEL_RESERVATION_SUCCESS":
      return { ...state, cancelReservationSuccess: action.payload };
    case "CANCEL_RESERVATION_FAILURE":
      return { ...state, cancelReservationSuccess: null };
    // ----------------------------------------------------------------
    case "UPDATE_RESERVATION_STATUS_REQUEST":
      return { ...state, updateReservationStatusSuccess: null };
    case "UPDATE_RESERVATION_STATUS_SUCCESS":
      return { ...state, updateReservationStatusSuccess: action.payload };
    case "UPDATE_RESERVATION_STATUS_FAILURE":
      return { ...state, updateReservationStatusSuccess: null };
    // ----------------------------------------------------------------
    case "CREATE_RESERVATION_NOTE_REQUEST":
      return { ...state, isNoteSaved: null };
    // case "CREATE_RESERVATION_NOTE_SUCCESS":
    //   return { ...state, notes: action.payload.notes };
    case "CREATE_RESERVATION_NOTE_FAILURE":
      return { ...state, isNoteSaved: null };
    // ----------------------------------------------------------------
    case "LOAD_RESERVATION_TRANSACTIONS_REQUEST":
      return { ...state, loadReservationTransactionsSuccess: null };
    case "LOAD_RESERVATION_TRANSACTIONS_SUCCESS":
      return { ...state, loadReservationTransactionsSuccess: action.payload };
    case "LOAD_RESERVATION_TRANSACTIONS_FAILURE":
      return { ...state, loadReservationTransactionsSuccess: null };
    // ----------------------------------------------------------------
    case "CREATE_RESERVATION_TRANSACTION_REQUEST":
      return { ...state, createReservationTransactionSuccess: null };
    case "CREATE_RESERVATION_TRANSACTION_SUCCESS":
      return { ...state, createReservationTransactionSuccess: action.payload };
    case "CREATE_RESERVATION_TRANSACTION_FAILURE":
      return { ...state, createReservationTransactionSuccess: null };
    // ----------------------------------------------------------------
    case "VOID_RESERVATION_TRANSACTION_REQUEST":
      return { ...state, voidReservationTransactionSuccess: null };
    case "VOID_RESERVATION_TRANSACTION_SUCCESS":
      return { ...state, voidReservationTransactionSuccess: action.payload };
    case "VOID_RESERVATION_TRANSACTION_FAILURE":
      return { ...state, voidReservationTransactionSuccess: null };
    // ----------------------------------------------------------------
    case "FETCH_RESERVATION_EXTEND_SCENARIO_REQUEST":
      return { ...state, extendScenarios: null };
    case "FETCH_RESERVATION_EXTEND_SCENARIO_SUCCESS":
      return { ...state, extendScenarios: action.payload };
    case "FETCH_RESERVATION_EXTEND_SCENARIO_FAILURE":
      return { ...state, extendScenarios: null };
    // ----------------------------------------------------------------
    case "CREATE_RESERVATION_STRIPE_PAYMENT_REQUEST":
      return { ...state, createReservationStripePaymentSuccess: null };
    case "CREATE_RESERVATION_STRIPE_PAYMENT_SUCCESS":
      return { ...state, createReservationStripePaymentSuccess: action.payload };
    case "CREATE_RESERVATION_STRIPE_PAYMENT_FAILURE":
      return { ...state, createReservationStripePaymentSuccess: null };
    // ----------------------------------------------------------------
    case "LOAD_EMAILS_REQUEST":
      return { ...state, emails: null, emailIDs: null };
    case "LOAD_EMAILS_SUCCESS":
      return { ...state, emails: action.payload.emails, emailIDs: action.payload.emailIDs };
    case "LOAD_EMAILS_FAILURE":
      return { ...state, emails: null, emailIDs: null };
    //------------------------------------------------------------------------
    case "SEND_EMAIL_REQUEST":
      return { ...state, isEmailSent: false };
    // case "SEND_EMAIL_SUCCESS":
    //   return { ...state, emails: action.payload.emails, emailIDs: action.payload.emailIDs, isEmailSent: true };
    case "SEND_EMAIL_FAILURE":
      return { ...state, isEmailSent: false };
    //------------------------------------------------------------------------
    // case "LOAD_ACTIVITY_LOG_REQUEST":
      // return { ...state, activityLog: null, logIDs: null };
    case "LOAD_ACTIVITY_LOG_SUCCESS":
      return { ...state, ...action.payload };
    // case "LOAD_ACTIVITY_LOG_FAILURE":
      // return { ...state, activityLog: null, logIDs: null };
    //------------------------------------------------------------------------
    case "UPDATE_RESERVATION_ITEMS_REQUEST":
      return { ...state, isReservationUpdated: null };
    case "UPDATE_RESERVATION_ITEMS_SUCCESS":
      return { ...state, ...action.payload, upsellList: action.payload.upsellList, isReservationUpdated: true };
    case "UPDATE_RESERVATION_ITEMS_FAILURE":
      return { ...state, isReservationUpdated: null };
    // ----------------------------------------------------------------
    case "SELECTED_RESERVATION_ACCOMMODATION":
      return { ...state, selectedAccommodation: action.payload };
    // ----------------------------------------------------------------
    case "SELECTED_RESERVATION":
      return { ...state, selectedReservation: action.payload };
    // ----------------------------------------------------------------
    case "SELECTED_HEADER":
      return { ...state, selectedHeader: action.payload };
    // ----------------------------------------------------------------
    case "SET_DRAWER_OPEN_VALUE":
      return { ...state, mobileOpen: action.payload };
    // ----------------------------------------------------------------
    case "RESET_RESERVATION":
      return { ...defaultState };
    // ----------------------------------------------------------------
    default:
      return state;
  }
};

export default ReservationReducer;
