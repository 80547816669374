import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import clsx from 'clsx';
import pluralize from 'pluralize';

// import DashboardGuestListItem from "./DashboardGuestListItem";
import TaskComplete from "./TaskComplete";
import ReservationCard from './ReservationCard';
import StatsInfoHeader from "./StatsInfoHeader";

import { RESERVATION_TYPE, RESERVATION_STATUS } from "../../utils/constants";
import { checkDateInPast, getRoundValue, DATE_FORMATS, fetchDateFormat, checkDateInBetween, fetchDatePerTimezone } from "../../utils/utility";

import Grid from "@material-ui/core/Grid";
import Hidden from '@material-ui/core/Hidden'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 20
  },
  listContainer: {
    background: '#F8F9FB',
    position: 'sticky',
    top: 70
  },
  header: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
      marginBottom: theme.spacing(2),
    },
    '& h2': {
      [theme.breakpoints.down("sm")]: {
        fontSize: "2rem",
      }
    }
  },
  firstHeader: {
    marginTop: "30px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "30px",
    }
  },
  newBooking: {
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addIcon: {
    cursor: "pointer",
    fontSize: "5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem"
    }
  },
  inhouse: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    }
  },
  sectionHeader: {
    fontSize: '1.8rem',
    fontWeight: 600,
    color: '#000000',
    marginBottom: 20,
  },
  progressContainer: {
    width: "100%",
  },
  bar: {
    borderRadius: '15px',
    boxShadow: '0 4px 4px 0px #ECECEC',
    height: '5px',
  },
  status: {
    alignItems: 'center',
    color: '#BDBDBD',
    display: 'flex',
    marginTop: 10,
  },
  statusNumber: {
    fontSize: '2.2rem',
    fontWeight: 600,
    marginRight: 10,
  },
  statusDesc: {
    marginRight: 20,
    fontSize: '1.2rem',
  },
  checkIn: {
    backgroundColor: '#FFFFFF',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#000000'
    }
  },
  checkOut: {
    backgroundColor: '#FFFFFF',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#000000'
    }
  },
  viewAll: {
    alignItems: "center",
    color: "#666666",
    fontSize: "1.4rem",
    textDecoration: "none",
    lineHeight: "2.4rem",
    marginLeft: "auto",
    verticalAlign: "middle",
    cursor: "pointer",
    "&:hover": {
      color: "#666666",
      textDecoration: "none"
    }
  },
  button: {
    alignItems: 'center',
    background: '#000000',
    borderRadius: 4,
    color: '#FFFFFF',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '1.2rem',
    fontWeight: 600,
    justifyContent: 'center',
    lineHeight: 1.5,
    margin: '0px 10px',
    '&:hover': {
      background: '#000000'
    },
  },
}));

const DashboardGuestList = props => {
  const classes = useStyles();
  const {
    accommodations,
    property,
    arrivalPercentage,
    handleGoReservation,
    allGuests,
    departureGuests,
    departurePercentage,
    inHouseGuests,
    inHouseGuestCount,
    arrivalGuests,
    inHouseToday,
    checkedOutToday
  } = props;


  return (
    <div className={classes.container}>

      <div className={classes.listContainer}>
        <Grid container spacing={5}>

          <Hidden xsDown><Grid item xs={12} sm={4}>
            <StatsInfoHeader
              title="Check-ins"
              label={`${pluralize('Check-in', arrivalGuests.length)} left`}
              value={arrivalGuests.length}
              percentVal={arrivalPercentage}
              completionVal={inHouseToday}
            />
          </Grid></Hidden>

          <Hidden xsDown><Grid item xs={12} sm={4}>
            <StatsInfoHeader
              title="Check-outs"
              label={`${pluralize('Check-out', departureGuests.length)} left`}
              completionVal={checkedOutToday}
              value={departureGuests.length}
              percentVal={departurePercentage}
            />
          </Grid></Hidden>

          <Hidden xsDown><Grid item xs={12} sm={4}>
            <StatsInfoHeader
              title="In-house"
              label="Guests tonight"
              value={inHouseGuestCount}
              percentVal={100}
            />
          </Grid></Hidden>
        </Grid>
      </div>

      <Grid container spacing={5}>

        {/* -------------------------------------------------------------------------------- */}
        {/* DISPLAY ARRIVALS */}
        {/* -------------------------------------------------------------------------------- */}
        <Grid item xs={12} sm={4}>

          <Hidden smUp><StatsInfoHeader
            title="Check-ins"
            label={`${pluralize('Check-in', arrivalGuests.length)} left`}
            value={arrivalGuests.length}
            percentVal={arrivalPercentage}
            completionVal={inHouseToday}
          /></Hidden>

          {arrivalGuests.length ?
            <Grid container className="reservations-list__arrivals">
              {
                arrivalGuests.map((reservation, index) => (
                  <ReservationCard
                    key={reservation._id}
                    currency={property.currency}
                    timezone={property.timezone}
                    accommodation={accommodations?.[reservation?.accommodationList?.[0]]}
                    guest={allGuests?.[reservation?.guests?.[0]]}
                    reservation={reservation}
                    handleGoReservation={handleGoReservation}
                  />
                ))
              }
            </Grid> : <TaskComplete type="checkins" />
          }
        </Grid>

        {/* -------------------------------------------------------------------------------- */}
        {/* DISPLAY DEPARTURES */}
        {/* -------------------------------------------------------------------------------- */}
        <Grid item xs={12} sm={4}>

          <Hidden smUp><StatsInfoHeader
            title="Check-outs"
            label={`${pluralize('Check-out', departureGuests.length)} left`}
            completionVal={checkedOutToday}
            value={departureGuests.length}
            percentVal={departurePercentage}
          /></Hidden>

          {departureGuests.length ?
            <Grid container className="reservations-list__arrivals">
              {
                departureGuests.map((reservation, index) => (
                  <ReservationCard
                    key={reservation._id}
                    currency={property.currency}
                    timezone={property.timezone}
                    accommodation={accommodations?.[reservation?.accommodationList?.[0]]}
                    guest={allGuests?.[reservation?.guests?.[0]]}
                    reservation={reservation}
                    handleGoReservation={handleGoReservation}
                  />
                ))
              }
            </Grid> : <TaskComplete type="checkouts" />
          }

        </Grid>

        {/* -------------------------------------------------------------------------------- */}
        {/* DISPLAY IN-HOUSE GUESTS */}
        {/* -------------------------------------------------------------------------------- */}
        <Grid item xs={12} sm={4}>

          <Hidden smUp><StatsInfoHeader
            title="In-house"
            label="Guests tonight"
            value={inHouseGuestCount}
            percentVal={100}
          /></Hidden>

          <Grid container className="reservations-list__in-house">
            {inHouseGuests.map((reservation, index) => (
              <ReservationCard
                key={reservation._id}
                currency={property.currency}
                timezone={property.timezone}
                accommodation={accommodations?.[reservation?.accommodationList?.[0]]}
                guest={allGuests?.[reservation?.guests?.[0]]}
                reservation={reservation}
                handleGoReservation={handleGoReservation}
              />
            ))}
          </Grid>
        </Grid>

      </Grid>

    </div>
  );
};

const MapStateToProps = state => {

  const reservationIds = state.today.reservationIDs || [];
  const reservations = state.today.reservation || {};
  const guests = state.today.guests || {};
  const timezone = state.property.timezone;
  const timezoneToday = fetchDatePerTimezone(null, timezone);

  const inHouseGuests = [];
  const todaysInHouseGuests = [];
  const arrivalGuests = [];
  const departureGuests = [];
  // const highlightedGuests = [];

  let inHouseToday = 0,
    checkedOutToday = 0,
    arrivalPercentage = 0,
    departurePercentage = 0,
    inHouseGuestCount = 0;

  reservationIds.forEach(key => {
    let reservationInfo = reservations[key];
    // let outstanding = false, yestArrival = false;

    // Ensure that today's departures don't show up as in-house
    let todayDepCheck = false;

    if (fetchDateFormat(reservationInfo.checkIn, DATE_FORMATS.BASIC_DATE) === fetchDateFormat(timezoneToday, DATE_FORMATS.BASIC_DATE)) {
      if (reservationInfo.status === RESERVATION_STATUS.CONFIRMED || reservationInfo.status === RESERVATION_STATUS.ONLINE_CHECKED_IN) arrivalGuests.push(reservationInfo);
      if (reservationInfo.status === RESERVATION_STATUS.IN_HOUSE) inHouseToday++;
    }

    if (fetchDateFormat(reservationInfo.checkOut, DATE_FORMATS.BASIC_DATE) === fetchDateFormat(timezoneToday, DATE_FORMATS.BASIC_DATE)) {
      if (reservationInfo.status === RESERVATION_STATUS.IN_HOUSE) {
        departureGuests.push(reservationInfo);
        todayDepCheck = true;
      }
      if (reservationInfo.status === RESERVATION_STATUS.CHECKED_OUT) checkedOutToday++;
    }

    if (reservationInfo.status === RESERVATION_STATUS.IN_HOUSE && !todayDepCheck) {
      inHouseGuests.push(reservationInfo)
      inHouseGuestCount += reservationInfo.guestCount;
    }

    //Backend already checking for the checking to be gte to start of yesterday
    // if (checkDateInBetween(reservationInfo.checkIn, reservationInfo.checkOut, timezoneToday) &&
    //   fetchDateFormat(reservationInfo.checkIn, DATE_FORMATS.BASIC_DATE) !== fetchDateFormat(timezoneToday, DATE_FORMATS.BASIC_DATE) &&
    //   reservationInfo.status === RESERVATION_STATUS.CONFIRMED)
    //   yestArrival = true;

    // if (checkDateInPast(reservationInfo.checkOut) && reservationInfo.status === RESERVATION_STATUS.IN_HOUSE)
    //   outstanding = true;

    // if (yestArrival || outstanding) {
    //   reservationInfo.outstanding = outstanding;
    //   reservationInfo.yestArrival = yestArrival;
    //   highlightedGuests.push(reservationInfo)
    // }

  });

  arrivalPercentage = arrivalGuests.length ? getRoundValue(inHouseToday * 100 / ((arrivalGuests.length + inHouseToday) || 1)) : 100;
  departurePercentage = departureGuests.length ? getRoundValue(checkedOutToday * 100 / ((departureGuests.length + checkedOutToday) || 1)) : 100;

  return {
    // highlightedGuests: highlightedGuests,
    arrivalPercentage: arrivalPercentage,
    departurePercentage: departurePercentage,
    allGuests: guests,
    inHouseGuests: inHouseGuests,
    inHouseGuestCount: inHouseGuestCount,
    arrivalGuests: arrivalGuests,
    departureGuests: departureGuests,
    accommodations: state.today.accommodationList || {},
    inHouseToday,
    checkedOutToday
  };
};

export default connect(MapStateToProps)(DashboardGuestList);
