import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import ReservationDetails from '../reservation-details';
import ButtonBase from '@material-ui/core/ButtonBase';

import { fetchDateFormat } from '../../../utils/utility';
import IMAGES from '../../../constants/images';
import { RESERVATION_STATUS } from '../../../utils/constants';
import { updateReservationStatus } from '../../../redux/actions/reservations';
import useReservationStatus from '../hooks/useReservationStatus';

const useStyles = makeStyles(theme =>({
    root: {
        // display: 'flex',
        borderRadius: '10px',
        backgroundColor: '#fff',
        overflow: 'hidden'
    },
    infoContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: "15px 20px",
        flex: 1
    },
    buttonContainer: {
        display: 'flex',
        '&.expandedView': {
            // borderRadius: "0px 0px 10px 10px",
            // borderTop: "0px",
        }
    },
    checkIcon: {
        width: '20px',
        height: '20px'
    },
    details:{
        marginLeft: '10px',
        '& .primaryText':{
            fontSize: '1.3rem',
            fontWeight: 700,
            color: '#519884',
            marginBottom: '10px',
        },
        '& .secondaryText':{
            fontSize: '1.2rem',
            color:' #b4b4b4',
        }
    },
    viewBtn: {
        display: "flex",
        alignItems: "center",
        padding: "0px 20px",
        borderLeft: "1px solid #519884",
        fontSize: "1.1rem",
        fontWeight: 600,
        color: "#519884",
        cursor: "pointer",
    },
    closeBtn: {
        width: "100%",
        backgroundColor: "#f2f2f2",
        padding: "25px",
        fontSize: "1.1rem",
        fontWeight: 600,
        color: "#525252",
    },
    cancelledStatus: {
        padding: '20px',
        fontSize: '1.4rem',
        fontWeight: 600,
        color: '#5f5f5f'
    },
    cancelledBtn: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    undoBtn: {
        display: "flex",
        alignItems: "center",
        padding: "0px 20px",
        borderLeft: "1px solid #5f5f5f",
        fontSize: "1.1rem",
        fontWeight: 600,
        color: "#5f5f5f",
        cursor: "pointer",
    }
}));

const BookingStatus = props =>{
    const classes = useStyles();
    const { reservation, timezone, dispatch } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const resvStatus = useReservationStatus();

    const updateStatusHandler = (status) =>{
        const data = { status };
        dispatch(updateReservationStatus(reservation._id, data));
    }

    if(resvStatus.isCancelled){
        return(
            <div className={clsx(classes.root, classes.cancelledBtn)}>
                <div className={classes.cancelledStatus}>

                    {({
                        [RESERVATION_STATUS.CANCELLED]: 'Booking cancelled',
                        [RESERVATION_STATUS.NO_SHOW]: 'No Show'
                    })[reservation.status]}

                </div>
                <div 
                    className={classes.undoBtn}
                    onClick={() => updateStatusHandler(RESERVATION_STATUS.CONFIRMED)}
                >
                    Confirm back
                </div>
            </div>
        )
    }
    
    return (
        <div className={classes.root}>

            <div className={classes.buttonContainer}>
                <div className={classes.infoContainer}>
                    <img src={IMAGES.ICONS.check} className={classes.checkIcon}/>
                    <div className={classes.details}>
                        <div className="primaryText">Booking confirmed</div>
                        <div className="secondaryText">
                            {fetchDateFormat(reservation.reservationDate, 'Do MMMM YYYY', timezone)} {reservation.bookingSource}
                        </div>
                    </div>
                </div>

                {!isExpanded && (
                    <div 
                        className={classes.viewBtn} 
                        onClick={() =>setIsExpanded(true)}
                    >
                        View
                    </div>
                )}
                
            </div>

            {isExpanded &&( 
                <>
                    <ReservationDetails />

                    <ButtonBase 
                        className={classes.closeBtn}
                        onClick={() =>setIsExpanded(false)}
                    >
                        Close window
                    </ButtonBase>
                </>
            )}

            
        </div>
    )
}

const mapStateToProps = state =>{
    const { reservationDetails, property, bookingSource } = state;
    return {
        reservation: reservationDetails?.reservation || {},
        timezone: property.timezone
    }
}

export default connect(mapStateToProps)(BookingStatus);