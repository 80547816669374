import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomerDetailForm from '../../booking_engine/checkout/customerDetailForm';
import PaymentForm from './payment';
import { validateEmail } from '../../booking_engine/helper';

const formType = {
    type1: 1,
    type2: 2
};

const formHelper = {
    firstNameError: false,
    lastNameError: false,
    emailError: false
}

class Checkout extends Component{
    state ={
        activeFormType: formType.type1,
        firstName: '',
        lastName: '',
        email: '',
        telephone: '',
        nationality: '',
        specialRequest: '',
        ...formHelper
    };

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    setActiveFormTypeHandler = type =>{
        this.setState({ activeFormType: type });
    }

    onChangeHandler = event =>{
        const inputVal = event.target.value;
        const inputName = event.target.name; 

        this.setState({
            [inputName]: inputVal,
            [`${inputName}Error`]: !inputVal.trim() ? true : false
        });
    }

    validateCustomerDetails = () =>{
        const { firstName, lastName, email } = this.state;
        let formInputStatus = { ...formHelper };
        let validationSuccess = true;

        if(!firstName.trim()){
            formInputStatus.firstNameError = true;
            validationSuccess = false;
        }

        if(!lastName.trim()){
            formInputStatus.lastNameError = true;
            validationSuccess = false;
        }

        if(!email.trim() || !validateEmail(email)){
            formInputStatus.emailError = true;
            validationSuccess = false;
        }

        this.setState({ ...formInputStatus });
        return validationSuccess;
    }

    fetchBookingData = () =>{
        const { 
            propertyID,
            chargableAmount,
            checkInDate,
            checkOutDate,
            accommodationList
        } = this.props;
        const { 
            firstName,
            lastName,
            email,
            telephone,
            nationality,
            specialRequest
        } = this.state;

    return {
            guest:{
                firstName,
                lastName,
                email,
                phone: telephone.trim(),
                country: nationality
            },
            reservation: {
                checkIn: checkInDate,
                checkOut: checkOutDate,
                accommodationList,
                guestComments: specialRequest.trim()
            },
            propertyID,
            chargableAmount
        }
    }

    render(){
        const { nextStep } = this.props;
        return(
            <>
                <CustomerDetailForm 
                    {...this.state}  
                    onInputChangeHandler ={this.onChangeHandler} 
                    setActiveFormType = {this.setActiveFormTypeHandler}
                />
                <PaymentForm 
                    validateCustomerDetails = {this.validateCustomerDetails}
                    fetchBookingData = {this.fetchBookingData}
                    nextStep = {nextStep}
                />
            </>
        );
    }
}

const mapStateToProps = state =>{
    const { bookingEngine } = state;
    const { bookingDetails, bookingCostInfo } = bookingEngine;
    const accommodationList = [];

    Object.keys(bookingDetails).map(roomTypeID =>{
        accommodationList.push({
            roomTypeID,
            ratePlanID: bookingDetails[roomTypeID].ratePlanID,
            quantity: bookingDetails[roomTypeID].noOfBedsBooked
        });
    });
    return{
        checkInDate: bookingEngine.checkInDate,
        checkOutDate: bookingEngine.checkOutDate,
        accommodationList,
        chargableAmount: bookingCostInfo.chargableNow
    }
}

export default connect(mapStateToProps)(Checkout);