import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { connect } from 'react-redux';
// import clsx from 'clsx';
// import { SingleDatePicker } from 'react-dates';

import NumberInput from '../../common/NumberInput';
import CustomInput from '../common/CustomInput';
import Footer from '../common/Footer';
import { fetchCurrencyFormat } from '../../../utils/utility';

const useStyles = makeStyles(theme =>({
    root: {
        
    },
    textField: {
        height: "45px",
        fontWeight: 600,
        '& input': { 
            paddingLeft: '10px',
            fontWeight: 500,
        }
    },
    datePicker: {
        height: "45px",
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
        '& .DateInput_1, .DateInput_input': {
            width: '100%',
            paddingLeft: 'unset'
        },
        '& .SingleDatePicker, .SingleDatePickerInput': {
            width: '100%'
        }
    },
}));

const PaymentDetailsForm = props =>{
    const classes = useStyles();
    const { nextStep, paymentAmount, paymentDate, currency, onChangePaymentDetails } = props;
    // const [focused, setFocused] = useState(null);

    // const onDateChangeHandler = (value) =>{
    //     onChangePaymentDetails({ name: 'paymentDate', value });
    // }

    const onChangeAmount = event =>{
        const value = event.target.value;
        onChangePaymentDetails({ name: 'paymentAmount', value });
    }

    return (
        <div className={classes.root}>

            {/* <CustomInput 
                className={classes.datePicker}
                label="Date"
                createMode
            >
                <SingleDatePicker 
                    date={paymentDate}
                    onDateChange={onDateChangeHandler}
                    focused={focused}
                    onFocusChange={({ focused }) => setFocused(focused)}
                    placeholder=""
                    noBorder 
                    numberOfMonths={1}
                    displayFormat={'DD MMM YYYY'}
                    hideKeyboardShortcutsPanel
                />
            </CustomInput> */}

            <CustomInput
                label="Amount"
                createMode
            >
                <NumberInput
                    variantType="base"
                    className={classes.textField}
                    value={paymentAmount}
                    onChange={onChangeAmount}
                    id="amount"
                    type="number"
                    min={0}
                    fullWidth
                    autoFocus
                    startAdornment={fetchCurrencyFormat(currency)}
                />
            </CustomInput>

            <Footer 
                nextBtnLabel="Next"
                isNextBtnDisabled={!paymentAmount}
                nextStepHandler={nextStep}
            />

        </div>
    )
}

export default PaymentDetailsForm;