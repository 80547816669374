import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Button } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { DATE_FORMATS, fetchDateFormat, getAmtFormat, getMomentFormat } from '../../../../utils/utility';
import { DEVICE_VIEW } from '../Helper';
import { getAccommodationName } from '../../../../utils/helper';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 2px',
        width: 'calc(100% / 5 - 4px)',
        textAlign: 'center',
        minHeight: 200,
        '&.smallDevice': {
            width: 'calc(100% / 4 - 8px)',
            fontSize: '1rem'
        }
    },
    arrowSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& > img': {
            marginLeft: 20,
        }
    },
    fullDate: {
        alignItems: 'center',
        background: 'transparent',
        boxShadow: 'none',
        color: 'rgba(0, 0, 0, 0.87)',
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: 20,
    },
    arrowContainer: {
        alignItems: 'center',
        // borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        marginRight: 0,
        marginLeft: 0,
        height: '40px',
        width: '40px',
    },
    arrow: {
        background: 'transparent',
        borderRadius: '50%',
        color: '#666666',
        height: '30px',
        width: '30px',
        minWidth: '30px',
        padding: 8,
        '&:hover': {
            background: '#edf2f7',
            color: '#000000'
        }
    },
    arrowIcon: {
        fontSize: '1.8rem',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        alignItems: "center",
        border: "none",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        whiteSpace: "nowrap",
        padding: '10px 10px 15px 10px',
    },
    card: {
        border: '1px solid #dadada',
        padding: 10,
        marginTop: 10,
        borderRadius: 5,
        textAlign: 'center',
        cursor: 'pointer',
        '&.highlighted': {
            backgroundColor: '#f8f9fb'
        },
        '&.selected': {
            backgroundColor: '#f2f2f2'
        },
        '&.noAction': {
            cursor: 'default'
        },
        '&.different': {
            borderLeft: '4px solid #d96977'
        },
        [theme.breakpoints.down('xs')]: {
            padding: 2,
        }
    },
    noBorderAction: {
        border: '1px solid transparent',
        cursor: 'default'
    },
    isCurrentDate: {
        background: '#f8f9fb',
        width: '20%',
        padding: '10px',
        borderRadius: '5px',
        margin: 'auto',
        '&:after': {
            content: '""',
            width: 5,
            height: 5,
            borderRadius: '50%',
            background: '#b83b3b',
            display: 'inline-block'
        }
    },
    week: {
        color: '#484848',
        display: 'block',
        fontSize: '1rem',
        fontWeight: 600,
        textTransform: 'uppercase'
    },
    labelContainer: {
        padding: '0px 20px',
        display: 'flex',
        alignItems: 'center',
        minHeight: 100,
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        }
    },
    currencyContainer: {
        alignItems: 'center',
        minHeight: 50,
    },
    label: {
        fontSize: '1.4rem',
        color: '#515151',
        fontWeight: 600,
        padding: '10px 0',
    },
    border: {
        border: '1px solid #f2f2f2',
    },
    currency: {
        fontWeight: 600,
        padding: '10px 0 0',
    },
    text: {
        fontSize: '1rem',
        fontWeight: 600,
        color: '#4e4e4e',
    }
}));

const DateRoomCard = (props) => {
    const classes = useStyles();
    const { data, timezone, selected, currency, isDifferent, view } = props;
    const currentDate = fetchDateFormat(getMomentFormat(null, timezone), DATE_FORMATS.DEFAULT_DATE)
    let date = fetchDateFormat(props.date, 'DD')
    if (date === '01') date = fetchDateFormat(props.date, 'D MMM');

    return (
        <div className={clsx(classes.root, {['smallDevice']: view === DEVICE_VIEW.SMALL})}>
            <div className={clsx(classes.button, { [classes.isCurrentDate]: currentDate === props.date })}>
                <span className={classes.week}>{fetchDateFormat(props.date, DATE_FORMATS.WEEKDAY)}</span>
                <span className={classes.date}>{date}</span>
            </div>
            {data ?
                <div className={clsx(classes.card, { 'selected': selected, 'different': isDifferent })}
                    onClick={() => props.selectDateHandler(props.date)}>
                    <div className={classes.labelContainer}>
                        <div className={classes.label}>{data.name}</div>
                    </div>
                    <div className={classes.border}></div>
                    <div className={classes.currencyContainer}>
                        <div className={classes.currency}>{getAmtFormat(data.rate, currency)}</div>
                        <div className={classes.text}>Base rate</div>
                    </div>
                </div> :
                <div className={clsx(classes.card, classes.noBorderAction)} />
            }
        </div>
    )
}


const CalendarBedOptions = (props) => {
    const classes = useStyles();
    const { rangeArray, selectedDate, data, currency, view } = props;
    let maxCards = view === DEVICE_VIEW.SMALL ? 4 : 5
    const [startRange, setStartRange] = React.useState(0);
    const [endRange, setEndRange] = React.useState(rangeArray.length > maxCards ? maxCards : (rangeArray.length + 1));
    let extraSpace = rangeArray.length < 5 ? true : false;
    let previousDayBed = null;

    React.useEffect(()=>{
        if(rangeArray){
            maxCards = view === DEVICE_VIEW.SMALL ? 4 : 5;
            setStartRange(0);
            setEndRange(rangeArray.length > maxCards ? maxCards : (rangeArray.length + 1));
            extraSpace = rangeArray.length < maxCards ? true : false;
        }
    }, [rangeArray, view])

    const fetchPreviousDateHandle = () => {
        if (startRange > 0) {
            setStartRange(startRange - 1)
            setEndRange(endRange - 1)
        }
    }

    const fetchNextDateHandle = () => {
        if (endRange < rangeArray.length) {
            setStartRange(startRange + 1)
            setEndRange(endRange + 1)
        }
    }

    const selectDateHandler = (date) => {
        props.selectDateHandler(date);
    }

    const fetchPropsDataHandler = (date, previousDayBed) => {
        let dataInfo = { ...data };
        let isDifferent = false;
        let cardData = dataInfo?.items?.find(item => item.date === date) || {};
        let currentDayBed = cardData?.bedID;
        if (previousDayBed && previousDayBed !== currentDayBed) {
            isDifferent = true;
        }
        previousDayBed = currentDayBed;

        let dormOrPrivate = props?.roomTypes?.[cardData?.roomTypeID]?.dormOrPrivate;
        let roomName = props?.rooms?.[cardData?.roomID]?.name || '';
        let bedName = props?.beds?.[cardData?.bedID]?.name || '';
        // cardData.name = dormOrPrivate === 'dorm' ?
        //     bedName.toLowerCase().includes(roomName.toLowerCase()) ?
        //         bedName
        //         :
        //         ((roomName || '') + ' ' + (bedName || '')).trim()
        //     :
        //     roomName;

        cardData.name = getAccommodationName({ dormOrPrivate, roomName, bedName });

        return { cardData, isDifferent, currentDayBed }
    }

    return (
        <React.Fragment>
            <div className={classes.arrowSection}>
                <div className={classes.fullDate} >
                    <div className={classes.arrowContainer}>
                        <Button className={classes.arrow} onClick={fetchPreviousDateHandle}>
                            <ArrowBackIosIcon className={classes.arrowIcon} />
                        </Button>
                    </div>

                    <div className={classes.arrowContainer}>
                        <Button className={classes.arrow} onClick={fetchNextDateHandle}>
                            <ArrowForwardIosIcon className={classes.arrowIcon} />
                        </Button>
                    </div>
                </div>
            </div>

            <div className={classes.row}>
                {
                    rangeArray?.slice(startRange, endRange).map((date, index) => {
                        const { isDifferent, cardData, currentDayBed }
                            = fetchPropsDataHandler(date, previousDayBed)
                        previousDayBed = currentDayBed;

                        return (
                            <DateRoomCard
                                key={index}
                                date={date}
                                view={view}
                                isDifferent={isDifferent}
                                currency={currency}
                                selected={selectedDate === date}
                                data={cardData}
                                selectDateHandler={selectDateHandler}
                            />
                        )
                    })
                }
                
                {extraSpace ? <div style={{width: `calc(100% - (${rangeArray.length} * calc(100% / 5 - 4px)))`}}></div> : null}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state =>{
    const { rooms } = state;
    return {
        roomTypes: rooms.roomTypes,
        rooms: rooms.rooms,
        beds: rooms.beds,
    }   
}

export default connect(mapStateToProps)(CalendarBedOptions);