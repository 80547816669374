import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RESERVATION_CANCELLED_STATUSES } from '../../../utils/constants';
import isEqual from 'lodash/isEqual';

const resvStatusSelector = state =>{
    const { reservationDetails } = state;
    return{
        reservation: reservationDetails?.reservation || {}
    }
}

export default function useReservationStatus(){
    const [status, setStatus] = useState({ isCancelled: false, isPaymentCompleted: false,  status: '' });
    const { reservation } = useSelector(resvStatusSelector, isEqual);

    useEffect(() =>{
            const isReservationCancelled = RESERVATION_CANCELLED_STATUSES.includes(reservation?.status);
            setStatus({ 
                isCancelled: isReservationCancelled,
                isPaymentCompleted: reservation?.balance <= 0,
                status: reservation?.status
            });
    }, [reservation]);

    return status;
}