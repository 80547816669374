import { schema } from "normalizr";

// Define room type schema
const roomTypeSchema = new schema.Entity("roomTypes", {}, { idAttribute: "_id" });


// Define room schema
const roomSchema = new schema.Entity("rooms", {}, { idAttribute: "_id" });


// Define bed schema
const bedSchema = new schema.Entity("beds", {}, { idAttribute: "_id" });

// Define bed allocation schema
const bedAllocationSchema = new schema.Entity("allocations", {}, { idAttribute: "_id" });

// Define tax schema
const taxSchema = new schema.Entity("taxes", {}, { idAttribute: "_id" });

// Define bed schema
const bedStatusSchema = new schema.Entity("status", {}, { idAttribute: "bedID" });

// Define your all rooms schema
export { roomTypeSchema, roomSchema, bedSchema, bedAllocationSchema, bedStatusSchema, taxSchema };
