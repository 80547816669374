import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_SAMPLE_RATE } from "../config";

const initLogger = () => {
  if (!SENTRY_DSN)
    return console.log("WARNING: SENTRY_DSN env variable not configured.");

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: SENTRY_SAMPLE_RATE,
  });
};

export default initLogger;
