import ICONS from './icons';
import ILLUSTRATIONS from './illustrations';
import LOGOS from './logos';
import PLACEHOLDERS from './placeholders';

export default {
    ICONS,
    ILLUSTRATIONS,
    LOGOS,
    PLACEHOLDERS
}