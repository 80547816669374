import React, { useState, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import BedStatusButton from './BedStatusButton';
import BedOccupancyInfo from './BedOccupancyInfo';
import { processNextBedStatus, bedStatusInput } from './helper';

const useStyles = makeStyles(theme =>({
    root: {
        display: 'flex',
        alignItems: "center",
        '& h5': { margin: 0 },
        '& $section:first-child': {  paddingLeft: 'unset', }
    },
    section: {
        padding: '10px 0px',
        paddingLeft: '20px',
        '&.bedName': { width: "50px" }
    },
    bedOccupancy: {
        flex: 1,
        [theme.breakpoints.down('xs')]: {
            flex: "unset",
            flexShrink: 0,
        }
    },
    confirmBtn: {
        color: '#807b7b'
    }
}));

const BedListItem = props =>{

    const classes = useStyles();
    const history = useHistory();
    
    const { bedID, bedStatus, bedName, markBedCleanHandler, reservations, propertyCode } = props;
    const [isBtnLoading, setIsBtnLoading] = useState(false);

    const onStatusChangeHandler = useCallback(() =>{
        
        setIsBtnLoading(true);
        
        const data = bedStatusInput(reservations, bedStatus);
        const updatedBedStatus = processNextBedStatus(data);

        const payload = [{ bedID, status: updatedBedStatus}];

        markBedCleanHandler(payload)
            .then(success =>{
                setIsBtnLoading(false);
            });

    }, [bedStatus]);

    const navigateToReservation = (resvCode) =>{
        history.push(`${propertyCode}/reservation/${resvCode}`);
    }

    return (
        <div className={classes.root}>
            
            <div className={clsx(classes.section, 'bedName')}>
                <h5>{bedName}</h5>
            </div>
            
            <div className={classes.section}>
                <BedStatusButton 
                    status={bedStatus}
                    isLoading={isBtnLoading}
                    onClickHandler={onStatusChangeHandler}
                />
            </div>

            <div className={clsx(classes.section, classes.bedOccupancy)}>
                <BedOccupancyInfo 
                    bedID={bedID}
                    reservations={reservations} 
                    navigateToReservation={navigateToReservation}
                />
            </div>
        </div>
    )
}

export default React.memo(BedListItem);