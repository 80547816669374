import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import DurationDatePicker from './DurationDatePicker';
import ManualSelectBed from '../manual-bed-select';
import { fetchRequiredKeyValue, validateEditReservationPayload } from '../Helper';

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '1.1rem',
        fontWeight: 600,
    },
    spacing: {
        padding: '10px 0'
    },
    disabledLabel: {
        fontSize: '1.1rem',
        color: '#bcbcbc',
    }
}));

const ChangeBedOptions = (props) => {
    const classes = useStyles();
    const { data, range, view, options } = props;

    const [errorMessage, setErrorMessage] = React.useState('');

    React.useEffect(() => {
        setErrorMessage('')
    }, [range])

    const submitDataHandler = (form) => {
        let requiredKeys = ['bedID', 'roomID', 'roomTypeID', 'rate', 'date'];
        let items = fetchRequiredKeyValue([...form.items], requiredKeys)
        let isValidated = validateEditReservationPayload(items, requiredKeys);
        if (isValidated?.error) {
            setErrorMessage(isValidated.errorMessage)
            return
        }

        let payload = {
            guestNumber: data.guestNumber,
            changeBedByDate: items
        }
        props.onSubmitHandler(payload)
    }

    return (
        <React.Fragment>
            {/* -------------------- Duration picker -------------------- */}
            {/* <div className={classes.disabledLabel}>Date range selected</div> */}
            {/* <div className={classes.spacing}></div>
            <DurationDatePicker startLabel={'Check in date'} endLabel={'Check out date'} startDate={range.startDate} endDate={range.endDate} disabled={true} onDatesChangeHandler={void 0}/>
            <div className={classes.spacing}></div> */}

            {/* -------------------- Rate form for selected range -------------------- */}
            {/* <div className={classes.label}>You are changing the room for the selected range</div> */}
            {/* <div className={classes.spacing}></div> */}
            
            <ManualSelectBed
                view={view}
                errorMessage={errorMessage}
                data={data}
                range={range}
                options={options}
                submitDataHandler={submitDataHandler}
            />

        </React.Fragment>
    )
}

export default ChangeBedOptions;