import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '../common/Button';

const useStyles = makeStyles(theme =>({
    root: {
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: "15px 30px",
        borderTop: '1px solid #e0e0e0',
    },
    info: {
        flex: 1,
        textAlign: "center",
        fontSize: "1.2rem",
        fontWeight: 600,
    },
    prevBtn: {
        padding: "10px 25px",
        backgroundColor: "#fff !important",
        color: "#777777",
        border: "1px solid #777777",
        fontWeight: 600,
        '&.hidden':{
            visibility: 'hidden'
        },
        '&:hover': {
            color: "#777777",
        }
    }
}));

const Footer = props =>{
    const classes = useStyles();
    const { isNextBtnDisabled, nextStepHandler, prevStepHandler, showPrevBtn, nextBtnLabel, stepInfo } = props;
    return (
        <div className={classes.root}>

            <Button
                className={clsx(classes.prevBtn, { hidden: !showPrevBtn })}
                onClick={prevStepHandler}
            >
                Previous
            </Button>

            <div className={classes.info}>{stepInfo} guests</div>

            <Button
                disabled={isNextBtnDisabled}
                onClick={nextStepHandler}
            >
                {nextBtnLabel}
            </Button>

        </div>
    )
}

export default Footer;