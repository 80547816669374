import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    backgroundColor: "#fff",
    borderRadius: '10px',
    border: '1px solid #e1e1e1',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    padding: 20,
    width: 'calc(100% - 40px)',
  },
  image: {
    display: 'block',
    height: 50,
    marginRight: 20,
    width: 50,
    [theme.breakpoints.down("sm")]: {
      width: "70px",
      height: "70px"
    },
  },
  text: {
    color: '#333333',
    fontSize: '1.3rem',
    lineHeight: 1.5,
    marginLeft: 10,
  }
}))

const TaskComplete = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {/* <img className={classes.image} src='/images/icons/box.png' /> */}
      <span className={classes.text}>Hooray!! All {props.type} are complete..</span>
    </div>
  )
}

export default TaskComplete;