import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

const useStyles = makeStyles(theme =>({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            flexDirection: "column",
            alignItems: 'unset',
        },
    },
    dateInfo: {
        marginBottom: "50px",
        '& p': {
            margin: '0px'
        },
        '& .label': {
            fontSize: "1.2rem",
            fontWeight: 600,
            color: "#777777",
            marginBottom: "10px",
        },
        '& .date': {
            fontSize: "1.4rem",
            fontWeight: 600,
        }
    },
}));

const DateChangeInfo = props =>{
    const classes = useStyles();
    const { 
        originalDates, 
        newDates, 
        className,
        originalDatesLabel='Original booking dates', 
        newDatesLabel='New booking dates' 
    } = props;

    return(
        <div className={clsx(classes.root, className)}>
            <div className={classes.dateInfo}>
                <p className="label">{originalDatesLabel}</p>
                <p className="date">{originalDates}</p>
            </div>

            {newDates && (
                <div className={classes.dateInfo}>
                    <p className="label">{newDatesLabel}</p>
                    <p className="date">{newDates}</p>
                </div>
            )}
            
        </div>
    )
}

export default DateChangeInfo;