import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
// import IconButton from '@material-ui/core/IconButton';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withTranslation } from 'react-i18next';

import CloseIcon from '@material-ui/icons/Close';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import Typography from '@material-ui/core/Typography';
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

// import UserInfo from './UserInfo';
import UserInfoFrom from './UserInfoForm';

// import ProfilePicSection from './ProfilePicSection';

const useStyles = makeStyles(theme =>({
    infoCard:{
        background: '#FFFFFF',
        boxShadow: 'none',
        border: '1px solid #BDBDBD',
        borderRadius: '5px',
        marginBottom: '20px',
        position: 'relative',
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 20,
        '&:hover': {
          background: '#dddddd',
          color: '#666666'
        },
        [theme.breakpoints.down('sm')]: {
          top: '20px',
          right: '10px'
        }
    },
    topMargin:{
        marginTop: 25
    }
}));
  

const PersonalInfoCard = props =>{
    const { t, customerID } = props
    const classes = useStyles();
    const [isEditMode, setIsEditMode] = useState(false);
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);
  
    // const handleClick = (event) => {
    //   setAnchorEl(event.currentTarget);
    // };
  
    // const handleClose = () => {
    //   setAnchorEl(null);
    // };

    const setEditMode = () =>{
        // setIsEditMode(!isEditMode)
        // setAnchorEl(null);
        props.changeEdit(customerID)
    }

    // const handleAccommodationUpdate = accomodationID =>{
    //     const guetsID = props.guestData._id;
    //     const data = { [accomodationID]: [guetsID] };
    //     props.updateAccommodation(data);
    // }
    
    return(
        <Card className={classes.infoCard}>
            <CloseIcon className={classes.closeIcon} onClick={() => setEditMode()} />
            <div className={classes.topMargin}>
                <UserInfoFrom 
                    guestData={props.guestData}
                    accommodationID={props.accommodationID}
                    submitHandler={props.submitHandler}
                    customerDetails={props.settings.customerDetails}
                    customerDocuments={props.settings.customerDocuments}
                    toggleMode={()=>setIsEditMode(false)}
                    checkForChange={props.checkForChange}
                    t={t}
                    newGuest={false}
                />
            </div>
        </Card>     
    );
}

export default withTranslation()(PersonalInfoCard);