import React from 'react';
import clsx from 'clsx';
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardMedia, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 200,
    padding: 20,
    margin: 10,
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75) !important',
    '& .MuiCardContent-root:last-child': {
      paddingBottom: 0
    }
  },
  media: {
    height: 0,
    paddingTop: '65%',
    borderRadius: 5
  },
  label: {
    padding: '16px 0 0'
  },
  action: {
    cursor: 'pointer'
  },
  selected: {
    background: '#E6DDD3'
  },
  CardActions: {
    padding: '8px 0!important'
  }
}));

const Item = (props) => {
  const classes = useStyles();
  const { data, itemID, selected } = props;

  const onClickItem = () => {
    if (selected) props.onCountChange(0, itemID);
    if (!selected) props.onCountChange(1, itemID);
  }

  return (
    <Card className={clsx({
      [classes.root]: true,
      [classes.action]: true,
      [classes.selected]: selected
    })} onClick={() => onClickItem(itemID, selected)}>
      <CardMedia className={classes.media} image={data.image} />
      <CardContent className={classes.label}>
        <Typography>{data.name}</Typography>
        <Typography>{data.rate}</Typography>
      </CardContent>
    </Card>
  )
}

export default (Item);