import React, { Component } from 'react';
import clsx from 'clsx';
import pluralize from 'pluralize';
import { connect } from 'react-redux';
import { Dialog, DialogContent, DialogActions, Button, CircularProgress} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import BasicDetails from './BasicDetails';
import Navigation from './Navigation';
import Breadcrumb from './Breadcrumb';
import SelectRoomTypes from './SelectRoomTypes';
import AutomaticSelection from './AutomaticSelection';
import GuestList from './GuestList';

import { deepSameKeys, fetchDateFormat, DATE_FORMATS, getAmtFormat, fetchCurrencyFormat } from '../../../utils/utility';
import { ICONS } from '../../../redux/utils/imageUrls';
import { loadRoomSuggestion, resetRoomSuggestion} from '../../../redux/actions/room';
import { addReservation, createBookingSource  } from '../../../redux/actions/reservations';
import { loadRatePlans } from '../../../redux/actions/ratePlans';
import { PAGES } from '../../../utils/constants';
import http from "../../../redux/utils/http";

const styles = (theme) => ({
  Button: {
    background: '#000',
    color: '#fff',
    width: 100,
    height: 50,
    fontWeight: 700,
    '&:hover': {
      background: '#000'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  fullWidth: {
    width: '100%'
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    },
  },
  error: {
    color: 'red',
    fontSize: '1.4rem',
    padding: '30px 5px 0'
  },
  dialogAction: {
    padding: '8px 30px 30px 30px'
  },
  dialogContent: {
    padding: '40px 5px',
    margin: '0 auto',
    overflow: 'hidden',
  },
  space: {
    height: 50
  }
});


const STEPS = {
  1: 'New Booking',
  2: 'Select Room Type',
  3: 'Select Room',
  4: 'Guest Details',
}

const reservationFormData = {
  reservation: {
    propertyID: '',
    bookingSource: '',
    checkIn: '',
    nights: 0,
    checkOut: ''
  }
}

class ReservationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disableButton: false,
      buttonText: 'Next',
      currentStep: 1,
      prevStep: 1,
      data: { typeID: {} },
      messageopen: true,
      filteredSuggest: [],
      availability: {},
      breadcrumbList: {},
      ratePlanOptions: [],
      selectedRatePlan: ''
    };
    this.basic = React.createRef();
    this.type = React.createRef();
    this.bed = React.createRef();
    this.guest = React.createRef();

    // console.log("Test, build again")
  }

  componentDidMount(){
    const { dispatch, ratePlans } = this.props;
    if (ratePlans.default) dispatch(loadRatePlans());
  }

  componentDidUpdate(prevProps) {
    if (this.props.suggestions && !_.isEqual(this.props.suggestions, prevProps.suggestions)) {
      this.handleProcessSuggestions(this.props.suggestions);
    }

    if (this.props.createReservationSuccess && !_.isEqual(this.props.createReservationSuccess, prevProps.createReservationSuccess)) {
      // this.props.history.push(`/${PAGES.RESERVATION_EDIT}/` + this.props.createReservationSuccess._id);
      this.props.history.push(`${this.props.register.propertyCode}/${PAGES.RESERVATION}/${this.props.createReservationSuccess.code}`);
    }
  }

  fetchRoomTypeAvailability = (params) => {
    http.get(`/api/reservation/suggest/bedCount/${this.props.propertyID}`, {
      params
    })
      .then(successResult => {
        let data = successResult.data || [];
        data = data.reduce(function (r, a) {
          r[a.roomTypeID] = r[a.roomTypeID] || {};
          r[a.roomTypeID] = a;
          return r;
        }, Object.create(null));
        this.setState({ availability: data });
      })
      .catch(errorResult => {
        this.setState({ availability: {} });
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        console.log('error ', error);
      });
  }

  // call suggestion api
  fetchSuggestions = (data) => {
    let startCount = 0, endCount = 0;
    Object.keys(data.typeID).map(roomTypeID => {
      const count = data.typeID[roomTypeID];
      if (count) {
        startCount = 1 + endCount;
        let params = {
          duration: data.nights || 1,
          startDate: data.checkIn,
          guestCount: count,
          roomTypeID: roomTypeID,
          guestNumber: startCount
        }
        endCount = endCount + count;
        this.props.dispatch(loadRoomSuggestion(data.propertyID, params))
      }
    })
  }

  // Go next step
  handleNext = (currentStep) => {
    let hasError = false, errorMessage = '', newButtonText = null;
    let { buttonText } = this.state;
    let data = { ...this.state.data };

    switch (currentStep) {
      case 1:
        let reservation = this.basic.current.handleProcessData();
        console.log('reservationFormData', reservationFormData.reservation, reservation);
        hasError = !deepSameKeys(reservationFormData.reservation, reservation)
        newButtonText = 'Next';
        data = { ...data, ...reservation };
        if (reservation.nights < 1) hasError = true;
        if (hasError) errorMessage = 'Please select all fields to proceed.'
        if (reservation.nights < 0) errorMessage = 'Enter error message here'
        if (reservation.checkIn === reservation.checkOut) {
          hasError = true;
          errorMessage = 'Check in and check out cannot be same'
        }
        if (!hasError) {
          this.fetchRoomTypeAvailability({ startDate: reservation.checkIn, endDate: reservation.checkOut });
        }
        break;
      case 2:
        let typedata = this.type.current.handleProcessData();
        newButtonText = 'Proceed';
        data = { ...data, ...typedata };
        if (!Object.keys(data.typeID).length) hasError = true;
        if (hasError) errorMessage = 'Please select a room type';
        else this.fetchSuggestions(data);
        break;
      case 3:
        let blockData = this.bed.current.handleProcessData();
        newButtonText = 'Finish';
        if (!blockData.calendarOptions) hasError = true;
        if (hasError) errorMessage = 'Please select a room';
        data = { ...data, ...blockData };
        break;
      case 4:
        let guestInfo = this.guest.current.handleProcessData();
        let customers = [...guestInfo.customers];
        data = { ...data, customers };
        if (!guestInfo.customers.length || (guestInfo.totalGuest !== customers.length)) hasError = true;
        if (hasError) errorMessage = 'Please enter guest info.';
        else this.handleSubmit(data);
        break;
      default: ;
    }
    if (hasError) {
      this.dialogContent.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (currentStep !== 4) {
      currentStep++;
      buttonText = newButtonText || buttonText;
      this.handleBuildBreadcrumb(data, currentStep)
    }
    this.setState({ currentStep, prevStep: currentStep-1, data, hasError, errorMessage, buttonText })
  }

  // Go previous steps
  handlePrev = (currentStep) => {
    let { buttonText } = this.state;
    switch (currentStep) {
      case 1: buttonText = 'Next'; break;
      case 2: buttonText = 'Next'; break;
      case 3: buttonText = 'Proceed'; break;
    }
    if (this.state.filteredSuggest.length && currentStep < 3) {
      this.props.dispatch(resetRoomSuggestion());
    }
    this.handleBuildBreadcrumb(this.state.data, currentStep)
    this.setState({ currentStep, prevStep: currentStep+1, buttonText, hasError: false, errorMessage: '' });
  }

  // create a breadcrumb
  handleBuildBreadcrumb = (data, step) => {
    data = { ...data };
    let breadcrumbList = { ...this.state.breadcrumbList };
    const property = { ...this.props.property };
    const currencySymbol = fetchCurrencyFormat(property.currency);
    let dateText = data.checkIn || null,
      nightsText = data.nights || null,
      guestCount = data.guestCount || null,
      hasShared = data.hasShared || false,
      hasPrivate = data.hasPrivate || false,
      total = getAmtFormat(data.total, currencySymbol);

    const removeNextKeys = (list, num) => {
      let keys = Object.keys(list);
      keys.forEach(key => { if (key > num && list[key]) delete list[key]; });
      return list
    }

    switch (step) {
      case 1:
        breadcrumbList = {};
        break;
      case 2:
        breadcrumbList = {};
        breadcrumbList[1] = { step: step - 1, text: dateText ? fetchDateFormat(dateText, DATE_FORMATS.DATE_MONTH) : null, icon: ICONS.CALENDAR }
        breadcrumbList[2] = { step: step - 1, text: `${nightsText} ${pluralize('nights', nightsText)}`, icon: ICONS.SLEEP }
        break;
      case 3:
        breadcrumbList[3] = { step: step - 1, text: guestCount, icon: ICONS.SMILE }
        if (hasShared) breadcrumbList[4] = { step: step - 1, text: 'Shared Room', icon: ICONS.HOSTEL }
        if (hasPrivate) breadcrumbList[5] = { step: step - 1, text: 'Private Room', icon: ICONS.BED }
        breadcrumbList = removeNextKeys(breadcrumbList, 5);
        break;
      case 4:
        breadcrumbList[6] = { step: step - 1, text: 'Total amount ' + total, icon: null, final: true }
        break;
    }
    this.dialogContent.scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.setState({ breadcrumbList });
  }

  //  submit form
  handleSubmit = (data) => {
    let booking = {
      method: 'automatic',
      reservation: {
        propertyID: data.propertyID,
        bookingSource: data.bookingSource,
        checkIn: data.checkIn,
        checkOut: data.checkOut,
        guestCount: data.guestCount,
        ratePlanIDs: [ ...data.ratePlanIDs ]
      },
      calendarOptions: data.calendarOptions,
      customers: data.customers
    }
    this.props.dispatch(addReservation(booking, 'reservation'))
  }

  selectRatePlanHandler = data =>{
    this.setState({
      selectedRatePlan: data.value
    })
  }

  getformattedPrices = (suggestions) =>{
    const { data: { typeID }} = this.state;
    const { ratePlans } = this.props;
    let filteredPrices = {};
    let ratePlanOptions = [];

    let pricesArray = suggestions && suggestions.length > 0 ? [ ...suggestions[0].prices ] : [];

    //Process rateplans....................
    pricesArray.map(data =>{
      const ratePlanInfo = ratePlans[data.ratePlanID];

      if(ratePlanInfo?.isDeleted) return;

      const ratePlanName = ratePlanInfo.isDerived ? `${ratePlanInfo.privateName} (derived)` : `${ratePlanInfo.privateName}`;
      filteredPrices[ratePlanName] = filteredPrices[ratePlanName] ? 
        [...filteredPrices[ratePlanName], data] : [ data ];
    });

    const selectedRoomTypeCount = Object.keys(typeID).filter(roomTypeID => typeID[roomTypeID] > 0).length;

    Object.keys(filteredPrices).map(ratePlanName =>{

      if(filteredPrices[ratePlanName].length < selectedRoomTypeCount){ 
        delete filteredPrices[ratePlanName];
        return;
      };
      ratePlanOptions.push({
        value: ratePlanName,
        label: ratePlanName
      })
    });

    return { 
      filteredPrices: { ...filteredPrices },
      ratePlanOptions: [ ...ratePlanOptions ]
    }
  }

  // Check if atleast a single object of calendarOption has NEW_RES
  handleProcessSuggestions = (suggestions) => {
    let filteredSuggest = [];

    const { filteredPrices, ratePlanOptions } = this.getformattedPrices(suggestions);
  
    suggestions.forEach(data => {
      let suggest = { ...data };

      if (suggest && suggest.calendarOptions) {
        let calendarOptions = [...suggest.calendarOptions];
        if (calendarOptions.find(option => option.guest && option.reservationID === "NEW_RES")) {
          filteredSuggest.push({ ...data, prices: { ...filteredPrices } })
        }
      }
    });
    
    this.setState({ 
      filteredSuggest, 
      ratePlanOptions, 
      selectedRatePlan: ratePlanOptions.length > 0 ?  ratePlanOptions[0].value : '' 
    });
  }

  handleCreateBookingSource = (value) => {
    this.props.dispatch(createBookingSource({ propertyID: this.props.propertyID, name: value }))
  }


  render() {
    const { classes } = this.props;
    const { currentStep, prevStep, buttonText, hasError, errorMessage, availability, ratePlanOptions, selectedRatePlan } = this.state;
    const data = { ...this.state.data };
    const property = { ...this.props.property };

    return (
      <Dialog
        open={true}
        onClose={this.props.handleCloseCreate}
        maxWidth="md"
        fullWidth={true}
        fullScreen={window.innerWidth < 901}>
        <div className={classes.root}>
          {currentStep !== 3 ?
            <CloseIcon className={classes.closeIcon} onClick={() => this.props.handleCloseCreate()} /> : null
          }

          <DialogContent>
            <div ref={node => { this.dialogContent = node; }}>
              <DialogContent className={classes.dialogContent}>

                {/* ------------ Navigating back to previous screen and show title ------------ */}
                <Navigation step={currentStep} title={STEPS[currentStep]} onChangePrev={this.handlePrev} />
                {hasError && (<div className={classes.error}>{errorMessage}</div>)}
                {currentStep > 1 && (<><div className={classes.space}></div>
                  <Breadcrumb 
                    list={this.state.breadcrumbList} 
                    ratePlanOptions={ratePlanOptions} 
                    currentStep={currentStep} 
                    onChangePrev={this.handlePrev} 
                    divider={true} 
                    selectedRatePlan={selectedRatePlan}
                    selectRatePlanHandler={this.selectRatePlanHandler}
                    displayOption = { currentStep == 3 } 
                  />
                </>)}

                {/* ------------ Creat Reservation form information ------------ */}
                {currentStep === 1 &&
                  <BasicDetails
                    ref={this.basic}
                    property={property}
                    bookingSource={this.props.bookingSource}
                    prefilledData={data}
                    createBookingSource={this.handleCreateBookingSource}
                  />}

                {currentStep === 2 &&
                  <SelectRoomTypes
                    ref={this.type}
                    property={property}
                    availability={availability}
                    availabilityMin={data.nights}
                    checkAvailability={true}
                    roomTypes={this.props.roomTypes}
                    multiple={true}
                    prefilledData={data.typeID}
                  />}

                {currentStep === 3 &&
                    <AutomaticSelection
                      ref={this.bed}
                      property={property}
                      guestCount={data.guestCount}
                      date={data.checkIn}
                      nights={data.nights}
                      suggestions={this.state.filteredSuggest}
                      loading={this.props.loading}
                      currentStep={currentStep}
                      onChangePrev={this.handlePrev}
                      selectedRatePlan={selectedRatePlan}
                    /> 
                }

                {currentStep === 4 &&
                  <GuestList
                    ref={this.guest}
                    property={property}
                    guestCount={data.guestCount}
                    viewMasterBooker={true}
                  />}

              </DialogContent>
            </div>
          </DialogContent>

          {/* ------------ Action Button ------------ */}
          <DialogActions className={classes.dialogAction}>
            <Button
              className={clsx({
                [classes.Button]: true,
                [classes.fullWidth]: currentStep === 3
              })}
              onClick={() => this.handleNext(currentStep)}>
              {buttonText}
            </Button>
          </DialogActions>

          {/* -------------- Loader --------------- */}
          {this.props.loading &&
            <div className={"loadingOverlay"}>
              <CircularProgress className={"loading"} />
            </div>
          }
          
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  let property = state.property.property && state.register.propertyID ? {...state.property.property[state.register.propertyID]} : {};
  property.propertyID = state.register.propertyID;
  return {
    property: property,
    roomTypes : state.rooms.roomTypes,
    suggestions: state.suggestions || [],
    taxes: state.rooms.taxes,
    rooms: state.rooms.rooms,
    beds: state.rooms.beds,
    bookingSource: state.bookingSource,
    propertyID: state.register.propertyID,
    createReservationSuccess: state.reservations.createReservationSuccess,
    ratePlans: state.ratePlans,
    register: state?.register || {}
    // reservationCreated: state.success.ADD_RESERVATION,
    // loading: state.loading.ADD_RESERVATION || state.loading.LOAD_ROOM_SUGGESTION,
    // errors: state.errors.ADD_RESERVATION
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ReservationPage));