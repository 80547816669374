import { CURRENCY } from "../../common/constants/currency";

export const caculateAverage = (data) => {
  const sum = data.reduce((a, b) => a + b, 0);
  const avg = sum / data.lenght;
  return avg;
};

export const calculateSumOfArray = (data) => {
  const sum = data.reduce((a, b) => a + b, 0);
  return sum;
};

export const calculateBaseRoomCost = (data) => {
  const sumOfSelectedRatePlan = data.sumOfSelectedRatePlan;
  const noOfBedsBooked = data.noOfBedsBooked;

  const baseRoomCost = sumOfSelectedRatePlan * noOfBedsBooked;
  return baseRoomCost;
};

export const fetchLowestRatePlan = (data) => {
  const ratePlans = [];
  Object.keys(data).map((ratePlanID) => {
    const sumOfRates = data[ratePlanID].reduce((a, b) => a + b, 0);
    ratePlans.push(sumOfRates);
  });
  const lowestRatePlan = Math.min(...ratePlans);
  return lowestRatePlan;
};

export const validateTextForHTMLInjection = (text) => {
  var re = /<[a-z][\s\S]*>/i;
  return re.test(text)
}

export const validateEmail = (email) => {
  var re = /^[^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*@[^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*(\.[a-zA-Z]{2,})$/;
  return re.test(String(email).toLowerCase());
};

export const validateTelephone = (phoneNumber) => {
  // This regex allows only numeric characters and can be customized for your specific format
  const phoneRegex = /^(?![-+])[0-9]{1,15}$/;

  return phoneRegex.test(phoneNumber);
};

export const getCurrency = ({ code = "EUR", country = "Germany" }, amount) => {
  try {
    const currencyData = CURRENCY[code];
    let formatedAmount = "";
    const currencyDetails = currencyData.countrybasedformatting
      ? { ...currencyData.countryinfo[country], symbol: currencyData.symbol }
      : currencyData;

    const {
      symbolplacement,
      symbol,
      space,
      thousandseparater = ",",
      decimalplaces = 2,
      fractionalseparator = ".",
    } = currencyDetails;

    const roundedAmount =
      Math.round(amount * Math.pow(10, decimalplaces)) /
      Math.pow(10, decimalplaces);
    // Format thousands and fractional seperator...
    const values = roundedAmount.toString().split(".");
    formatedAmount =
      values[0].replace(/.(?=(?:.{3})+$)/g, `$&${thousandseparater}`) +
      (values.length == 2 ? fractionalseparator + values[1] : "");

    // Format symbol placement...
    if (symbolplacement == "right") {
      formatedAmount = space
        ? `${formatedAmount} ${symbol}`
        : `${formatedAmount}${symbol}`;
    } else {
      formatedAmount = space
        ? `${symbol} ${formatedAmount}`
        : `${symbol}${formatedAmount}`;
    }
    return formatedAmount;
  } catch (error) {
    //console.log('error ',error);
    return `${code} ${amount}`;
  }
};
