import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { checkOverdue, fetchGuestFlag, fetchGuestName, fetchRoomDetails } from '../../utils/reservationHelper';
import { fetchCurrencyFormat, fetchNoOfNights, getAmtFormat, fetchDateFormat, DATE_FORMATS } from '../../utils/utility';

import ExpandIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(theme => ({
    tooltip: {
        backgroundColor: '#F8F9FB',
        borderRadius: 10,
        border: '1px solid #dadde9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        marginBottom: '20px',
        overflow: 'hidden',
        padding: 0,
        width: '100%',
        '& .tooltipHeader': {
            padding: 0,
            backgroundColor: '#FFFFFF',
            display: 'flex',
            '& .imageContainer': {
                width: 60,
                height: '100%',
            },
            '& .placeholder': {
                background: '#F0F0F0',
                borderRadius: '50%',
                height: 40,
                width: 40,
                marginRight: 20,
            },
            '& .titleContainer': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            },
            '& .title': {
                fontSize: '1.2rem',
                fontWeight: 600,
            },
            '& .tagList': {
                alignItems: 'center',
                display: 'flex',
                fontSize: '0.9rem',
                marginTop: 5,
            },
            '& .overdue': {
                alignItems: 'center',
                background: '#E6DEF0',
                borderRadius: 2,
                color: '#715497',
                display: 'flex',
                marginRight: 5,
                padding: '4px 8px 3px 8px',
            },
            '& .payment': {
                alignItems: 'center',
                background: '#FFE4E3',
                borderRadius: 2,
                color: '#ff5f58',
                display: 'flex',
                padding: '4px 8px 3px 8px',
            },
            '& .rightCol': {
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: 20,
                width: 'calc(20% - 20px)',
            },
            '& .expandContainer': {
                alignItems: 'center',
                borderRadius: '50%',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                width: 40,
                height: 40,
                '&:hover': {
                    background: '#F2F2F2',
                }
            },
            '& .leftCol': {
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex',
                minHeight: 30,
                height: 40,
                padding: 20,
                width: 'calc(80% - 40px)',
                '& img': {
                    width: 40,
                    height: 40
                },
            },
        },
        '& .tooltipContent': {
            padding: '20px 20px',
            display: 'flex',
            fontSize: '1.1rem',
            '& .col': {
                width: '50%',
                '&:first-child': {
                    borderRight: '1px solid #f2f2f2',
                    marginRight: 25
                }
            },
        },
        '& .rowCol': {
            paddingBottom: 15,
            '&:last-child': {
                paddingBottom: 0
            }
        },
        '& .label': {
            color: '#808080',
            paddingBottom: 5,
        },
        '& .value': {
            color: '#393939',
            fontWeight: 600
        },
        '& .error': {
            color: '#ff5f58',
        },
    },
}));

const ReservationCard = (props) => {
    const classes = useStyles();
    const {
        currency,
        timezone,
        // accommodation = {},
        reservation = {},
        guest = {},
        // roomTypes = {}, rooms = {}, beds = {}, bedsStatus = {},
        register = {},
        handleGoReservation
    } = props;
    const [expand, setExpand] = React.useState(false);

    const numberOfGuest = reservation.guestCount;
    const countryFlag = fetchGuestFlag(null, guest);
    // const bedStatus = bedsStatus?.[accommodation.bedID]?.status;
    // const roomStatus = bedStatus == 'Unclean' ? 'Room is being cleaned' : null;
    const guestName = fetchGuestName(guest, reservation.guestName);
    const numberOfNight = fetchNoOfNights(reservation.checkIn, reservation.checkOut);
    const outstandingAmount = reservation.balance ? getAmtFormat(reservation.balance, fetchCurrencyFormat(currency)): null;
    const overdue = checkOverdue(reservation.checkIn, reservation.checkOut, timezone, reservation.status);

    // const { roomTypeName, roomName, bedName } = fetchRoomDetails(
    //     roomTypes[accommodation.roomTypeID],
    //     rooms[accommodation.roomID],
    //     beds[accommodation.bedID]
    // );

    return (
        <div className={classes.tooltip}>


            <div className="tooltipContainer">
                <div className="tooltipHeader">
                    <div className="leftCol" onClick={() => handleGoReservation(register.propertyCode, reservation.code)}>
                        {countryFlag && <div className="imageContainer">
                            <img src={countryFlag} />
                        </div>}
                        {!countryFlag && <div className="placeholder"></div>}
                        <div className="titleContainer">
                            <div className="title">{guestName}</div>
                            {(outstandingAmount || overdue) && <div className="tagList">
                                {overdue && <div className="overdue">{overdue}</div>}
                                {outstandingAmount && <div className="payment">{`Balance ${outstandingAmount}`}</div>}
                            </div>}
                        </div>
                    </div>
                    <div className="rightCol">
                        {!expand && <div className={"expandContainer"} onClick={() => setExpand(true)}>
                            <ExpandIcon />
                        </div>}
                        {expand && <div className={"expandContainer"} onClick={() => setExpand(false)}>
                            <ExpandLessIcon />
                        </div>}
                    </div>
                </div>

                {expand && <div className="tooltipContent">
                    <div className="col">
                        <div className="rowCol">
                            <div className="label">Reservation Number</div>
                            <div className="value">{reservation.code}</div>
                        </div>
                        <div className="rowCol">
                            <div className="label">Check In / Out</div>
                            <div className="value">
                                {fetchDateFormat(reservation.checkIn, `${DATE_FORMATS.WEEKDAY}, ${DATE_FORMATS.DAY_MONTH}`)} - {fetchDateFormat(reservation.checkOut, `${DATE_FORMATS.WEEKDAY}, ${DATE_FORMATS.DAY_MONTH}`)}
                            </div>
                        </div>
                        <div className="rowCol">
                            <div className="label">Number of Guests</div>
                            <div className="value">{numberOfGuest || '-'}</div>
                        </div>
                        <div className="rowCol">
                            <div className="label">Total Amount</div>
                            <div className="value">{getAmtFormat(reservation.totalAmount, fetchCurrencyFormat(currency))}</div>
                        </div>
                    </div>
                    <div className="col">
                        {reservation.bookingSource && <div className="rowCol">
                            <div className="label">Booking Source</div>
                            <div className="value">{reservation.bookingSource}</div>
                        </div>}
                        <div className="rowCol">
                            <div className="label">Number of Nights</div>
                            <div className="value">{numberOfNight}</div>
                        </div>
                        <div className="rowCol">
                            <div className="label">Arrival Time</div>
                            <div className="value">{reservation.timeOfArrival || '-'}</div>
                        </div>
                        {outstandingAmount && <div className="rowCol">
                            <div className="label error">Balance</div>
                            <div className="value error">{outstandingAmount}</div>
                        </div>}
                    </div>
                </div>}

            </div>

        </div>
    )
}

const mapStateToProps = state => {

    return {
        roomTypes: state.rooms.roomTypes,
        rooms: state.rooms.rooms,
        beds: state.rooms.beds,
        register: state?.register || {},
        // bedsStatus: state.inventory.bedsStatus
    }
};

export default connect(mapStateToProps)(ReservationCard);