import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Select from 'react-select';

import Dialog from '../common/CustomDialog';
import Footer from '../common/Footer';
import { InputBase } from '@material-ui/core';
import { resetError } from '../../../redux/actions/reservations';

const useStyles = makeStyles(theme =>({
    root: {
        '& .dialogBody': {
            minHeight: "360px",
            maxHeight: "360px",
        },
    },
    label: {
        margin: '15px 0px'
    },
    textField: {
        border: "1px solid #e0e0e0",
        borderRadius: "30px",
        height: "45px",
        padding: "0px 20px",
        width: '100%'
    },
    link:{
        textAlign: 'end',
        cursor: "pointer",
        padding: "5px 10px",
        '& a': {
            fontSize: "1.3rem",
            color: "#1b82d7",
            marginLeft: "5px",
        }
    }
}));

const selectStyle = {
    control: (base, state) => ({
        ...base,
        border: '1px solid #e0e0e0',
        boxShadow: 0,
        cursor: 'pointer',
        borderRadius: '30px',
        padding: '0px 10px',
        margin: "0px",
        height: '45px',
        '&:hover': { border: '1px solid #e0e0e0' },
    }),
    indicatorSeparator: (base, state) =>({
        display: 'none'
    }),
    menuList: (provided, state) => ({
        ...provided,
        height: 200
    })
};

const AddTagModal = props =>{
    const { 
        closeModalHandler, 
        tagList, 
        saveReservationTag, 
        createNewTag, 
        isLoading, 
        errors, 
        dispatch 
    } = props;
    
    const [isCreateMode, setIsCreatedMode] = useState(false);
    const [selectedTagID, setSelectedTagID] = useState(null);
    const [tagName, setTagName] = useState('');
    const classes = useStyles();

    const tagOptions = Object.keys(tagList).map(id =>({ 
        label: tagList[id].name, 
        value: tagList[id]._id 
    }));

    const toggleMode = () =>{
        setSelectedTagID(null);
        setTagName('')
        setIsCreatedMode(prevState => !prevState);
    }

    const getDisableStatus = () =>{
        if(isCreateMode && !tagName.trim()) return true;
        if(!isCreateMode && !selectedTagID) return true;
        return false;
    }

    const submitHandler = () =>{
        if(isCreateMode){
            createNewTag(tagName);
            return
        }
        saveReservationTag(selectedTagID);
    }

    const handleCloseError = () =>{
        dispatch(resetError('CREATE_RESERVATION_TAG'));
    }

    return(
        <Dialog
            className={classes.root}
            headerTitle={'Add Tag'}
            closeModalHandler={closeModalHandler}
            isLoading={isLoading}
            errors={errors}
            handleCloseError={handleCloseError}
        >
                {!isCreateMode && (
                    <>
                        <h6 className={classes.label}>Select reservation tag</h6>
                        <Select
                            styles={selectStyle}
                            options={tagOptions}
                            value={tagOptions.find(e => e.value === selectedTagID)}
                            onChange={option => setSelectedTagID(option.value)}
                        />

                        <div className={classes.link} onClick={toggleMode}>
                            <a>Create new tag</a>
                        </div>
                    </>
                )}
                
                {isCreateMode && (
                    <>
                        <h6 className={classes.label}>Create reservation tag</h6>
                        <InputBase 
                            className={classes.textField}
                            placeholder="Enter new tag"
                            value={tagName}
                            onChange={e => setTagName(e.target.value)}
                        />
                        <div className={classes.link} onClick={toggleMode}>
                            <a>Select existing tag</a>
                        </div>
                    </>
                )}

            <Footer 
                label="Save" 
                isBtnDisabled={getDisableStatus()}
                onBtnClick={submitHandler}
            />

        </Dialog>
    )
}

const mapStateToProps = state =>{
    const { error, loading } = state;
    return {
        isLoading: loading.CREATE_RESERVATION_TAG,
        error: error.CREATE_RESERVATION_TAG
    }
}

export default connect(mapStateToProps)(AddTagModal);