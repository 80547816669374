import http from "../utils/http";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { normalize } from "normalizr";
import { userSchema } from "../schema/user";
import { PAGES } from "../../utils/constants";
import { setupRegister } from "./register";
import { loadAllCashDrawers } from "./cashDrawer";

//----------------------------------------------------------------
// REGISTER USER
//----------------------------------------------------------------
export const registerUser = (userData, history) => {
  const ACTION_NAME = "LOGIN";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    http
      .post("/api/users/register", userData)
      .then(res => {
        const { token, user } = res.data;

        // Set token to Auth header
        setAuthToken(token);

        // Set current user
        dispatch(setCurrentOwner(user));
        history.push(`/${PAGES.LOCK}`);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: user });
      })
      .catch(errorResult => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
};

//----------------------------------------------------------------
// LOGIN - GET TOKEN
//----------------------------------------------------------------
export const loginUser = (userData, history) => {
  const ACTION_NAME = "LOGIN";
  return (dispatch) => {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    http
      .post("/api/user/login", userData)
      .then(res => {
        console.log(res.data)
        const { user, cashDrawers, properties, s3path } = res.data;
        const token = user.token;
        user.accessToProperties = properties;
        // console.log("res.data", res.data)
        // Set token to Auth header
        setAuthToken(token);
        localStorage.setItem("jwtToken", token);

        // Set current user
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: { ...user, cashDrawers, s3path } });
        
        handlePropertyStepup(properties, dispatch, history);
      })
      .catch(errorResult => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: {message: "Unable to login"} });
      });
  }
};

const handlePropertyStepup = (properties, dispatch, history) =>{
  if(properties.length == 1){
    const property = properties[0];
    const cashDrawerFeature = property.features.find(data => data.name == "Cash Drawer");          
    let cashDrawerID = '';
    let cashDrawerName = '';
    // Check if cashDrawer feature is enabled...
    if(cashDrawerFeature && cashDrawerFeature.isEnabled === 'true'){
      if(property.cashDrawers.length > 1){
        // If more than one cashDrawer exists,
        // navigate to property setup screen...
        history.push(`/${PAGES.SETUP}`);
      }else{
        dispatch(loadAllCashDrawers(property.propertyID));
        // setup register and navigate to pin screen...
        cashDrawerID = property.cashDrawers.length == 1 ? property.cashDrawers[0]._id : '';
        cashDrawerName = property.cashDrawers.length == 1 ? property.cashDrawers[0].name : '';

        dispatch(setupRegister(
          property.propertyID,
          property.name,
          property.code,
          cashDrawerID,
          cashDrawerName
        ));
        history.push(`/${PAGES.LOCK}`);
      }
    }else{
      dispatch(loadAllCashDrawers(null));
      // If cashDrawer feature is disabled,
      // setup register and navigate to pin screen
      dispatch(setupRegister(
        property.propertyID,
        property.name,
        property.code,
        cashDrawerID,
        cashDrawerName
      ));
      history.push(`/${PAGES.LOCK}`);
    }
  }else{
    history.push(`/${PAGES.SETUP}`);
  }
}

//----------------------------------------------------------------
// USER SCREEN UNLOCK
//----------------------------------------------------------------
export function unlockScreen(propertyID, loadParams, history) {
  const ACTION_NAME = "UNLOCK";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/user/login/${propertyID}`, loadParams)
      .then(successResult => {
        const { token, user, propCode } = successResult.data;

        // console.log(successResult);
        localStorage.setItem("sessionToken", token);
        setAuthToken(token);

        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: user });
        
        if(propCode) dispatch({ type: `UNLOCK_SCREEN_PROPERTY_CODE`, payload: propCode });

        history.push(`/${PAGES.LANDING}`);
      })
      .catch(errorResult => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// USER SCREEN LOCK
//----------------------------------------------------------------
export const lockScreen = (propertyID, history) => {
  const ACTION_NAME = "LOCK";
  return (dispatch) => {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/user/lockscreen/`)
      .then(successResult => {
        localStorage.removeItem("sessionToken");
        localStorage.removeItem("roomsLocalStorage");
        localStorage.removeItem("roomTypesLocalStorage");
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        const token = localStorage.jwtToken;
        setAuthToken(token);
        history.push(`/${PAGES.LOCK}`);
      })
      .catch(errorResult => {
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
};


//----------------------------------------------------------------
// USER LOGOUT
//----------------------------------------------------------------
export const logoutUser = (history) => {
  return dispatch => {
    // Remove auth header for future requests
    localStorage.removeItem("sessionToken");
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("roomsLocalStorage");
    localStorage.removeItem("roomTypesLocalStorage");
    setAuthToken(false);

    dispatch({ type: "LOGOUT" });
    history.push(`/${PAGES.LOGIN}`);
  }
};


// Set logged in owner
export const setCurrentOwner = decoded => {
  return {
    type: "SET_CURRENT_OWNER",
    payload: decoded
  };
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: "SET_CURRENT_USER",
    payload: decoded
  };
};

export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// GET ALL USERS
export function loadUsers() {
  const ACTION_NAME = "LOAD_USERS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/users/")
      .then(successResult => {
        let normalizedData = normalize(successResult.data, [userSchema]);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// START SHIFT
//----------------------------------------------------------------
export function startShift() {
  const ACTION_NAME = "START_SHIFT";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/shift/")
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// END SHIFT
//----------------------------------------------------------------
export function endShift() {
  const ACTION_NAME = "END_SHIFT";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/shift/")
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

// export function getWSOnboardingStatus(){
//   const ACTION_NAME = "GET_WS_ONBORADING_STATUS";
//   return function (dispatch) {
//     dispatch({ type: `${ACTION_NAME}_REQUEST` });
//     return http
//       .get("/api/property/onboardingStatus")
//       .then(successResult => {
//         const { data } = successResult;
//         dispatch({ 
//           type: `${ACTION_NAME}_SUCCESS`,
//           payload: data
//         });
//         return true;
//       })
//       .catch(errorResult => {
//         let error =
//           errorResult && errorResult.response && errorResult.response.data
//             ? errorResult.response.data
//             : errorResult;

//         dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
//         return;
//       });
//   }
// }