import http from "../utils/http";
import { normalize } from 'normalizr';
import { ratePlanSchema } from '../schema/ratePlanSchema';

//----------------------------------------------------------------
// LOAD Rate Plans
//----------------------------------------------------------------
export function loadRatePlans() {
  const ACTION_NAME = "LOAD_RATEPLANS";
  return function(dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/ratePlans`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        var normalizedOrder = normalize(data, [ ratePlanSchema ] );
        // console.log(normalizedOrder);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: normalizedOrder.entities.ratePlans });
      })
      .catch(errorResult => {
        console.log(errorResult);
        // dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  };
}

//----------------------------------------------------------------
// RESET RATE PLANS
//----------------------------------------------------------------
export const resetRatePlans = () => (dispatch) => {
  dispatch({ type: "RESET_RATEPLANS"})
};

