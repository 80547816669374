import { schema } from "normalizr";

// Define room type schema
const roomType = new schema.Entity("roomTypes", {}, { idAttribute: "_id" });

// Define tax schema
const taxItem = new schema.Entity("taxItems", {}, { idAttribute: "_id" });

// Define rate plan schema
const ratePlanItem = new schema.Entity("ratePlans", {}, { idAttribute: "_id" });

// Define room schema
const roomItem = new schema.Entity("rooms", {}, { idAttribute: "_id" });

// Define beds schema
const bedItem = new schema.Entity("beds", {}, { idAttribute: "_id" });

const roomInfoSchema = { 
    roomTypes: [roomType],
    taxItems: [taxItem],
    ratePlans: [ratePlanItem],
    rooms: [roomItem],
    beds: [bedItem]
};

export { roomInfoSchema };