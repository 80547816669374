import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { ONBOARDING_STATUSES } from '../utils/constants';

const onboardingStatusSelector = state =>{
    const { propertyConfig } = state;
    return{
        onboardingStatus: propertyConfig?.onboardingStatus || ONBOARDING_STATUSES.free
    }
}

export default function useOnboardingStatus(){
    const [status, setStatus] = useState({ 
        isFree: false, 
        isPro: false, 
        isPaid: false, 
        status: ONBOARDING_STATUSES.free
    });

    const { onboardingStatus } = useSelector(onboardingStatusSelector, isEqual);

    useEffect(() =>{
            setStatus({ 
                isFree: onboardingStatus == ONBOARDING_STATUSES.free,
                isPro: onboardingStatus == ONBOARDING_STATUSES.pro,
                isPaid: [ONBOARDING_STATUSES.plus, ONBOARDING_STATUSES.pro].includes(onboardingStatus),
                status: onboardingStatus
            });
    }, [onboardingStatus]);

    return status;
}