import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: "flex-start",
        display: "flex",
        flexDirection: 'column',
        marginBottom: 20,
        marginTop: 10,
        [theme.breakpoints.down("sm")]: {
            marginTop: "30px",
            marginBottom: theme.spacing(2),
        },
        '& h2': {
            [theme.breakpoints.down("sm")]: {
                fontSize: "2rem",
            }
        }
    },
    sectionHeader: {
        fontSize: '1.8rem',
        fontWeight: 600,
        color: '#000000',
        marginBottom: 20,
    },
    progressContainer: {
        width: "100%",
    },
    bar: {
        borderRadius: '15px',
        boxShadow: '0 4px 4px 0px #ECECEC',
        height: '5px',
    },
    status: {
        alignItems: 'center',
        color: '#BDBDBD',
        display: 'flex',
        marginTop: 10,
    },
    statusNumber: {
        fontSize: '2.2rem',
        fontWeight: 600,
        marginRight: 10,
    },
    statusDesc: {
        marginRight: 20,
        fontSize: '1.2rem',
    },
    checkIn: {
        backgroundColor: '#FFFFFF',
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#000000'
        }
    },
    checkOut: {
        backgroundColor: '#FFFFFF',
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#000000'
        }
    },
}));

const StatsInfoHeader = props => {
    const classes = useStyles();
    const { 
        title = '', 
        label = '', 
        value = 0, 
        percentVal = 0, 
        completionVal = '' 
    } = props;

    return (
        <div className={classes.root}>

            <div className={classes.sectionHeader}>{title}</div>

            {/* --------------PROGRESSBAR----------------- */}
            <div className={classes.progressContainer}>
                <LinearProgress
                    className={clsx(classes.bar, classes.checkOut)}
                    variant="determinate"
                    value={percentVal}
                />
            </div>

            {/* ----------------STATS------------------ */}
            <div className={classes.status}>
                <div className={classes.statusNumber}>{value}</div>
                <div className={classes.statusDesc}>{label}</div>

                {completionVal > 0 && (
                    <>
                        <div className={classes.statusNumber}>{completionVal}</div>
                        <div className={classes.statusDesc}>Done</div>
                    </>
                )}
                
            </div>

        </div>
    )
}

export default StatsInfoHeader;