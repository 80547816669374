import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import 'react-dates/lib/css/_datepicker.css';
import Footer from '../../common/Footer';
import moment from 'moment-timezone';

import DateChangeInfo from '../DateChangeInfo';
import { getDurationCount, formatDateRange } from '../../helper';
import { fetchDateFormat, DATE_FORMATS } from '../../../../utils/utility';
import { fetchNoOfNights } from '../../../../utils/utility';
import BedsRangeSelect from './BedsRangeSelect';

const useStyles = makeStyles(theme =>({
    root: {
        
    },
}));

const DATE_FORMAT_OPTIONS = {
    dateFormat: 'DD. MMM YYYY', 
    seperator: '-'
}

const DurationSelect = props =>{
    const classes = useStyles();
    const { selectedAccomodation, setSelectedDateRange, getManualOptions } = props;
    const [dates, setDates] = useState({ original: '', new: '' });

    useEffect(() =>{
        const dateParams = {
            startDate: selectedAccomodation.checkIn,
            endDate: selectedAccomodation.checkOut,
        }
        setDates({ original: { ...dateParams }, new: '' });
    }, []);

    const onDateChangeHandler = ({ startDate, endDate }) =>{
        const checkinDate = moment(startDate).format(DATE_FORMATS.DEFAULT_DATE);
        const newDates = {
            startDate: moment(startDate).format(DATE_FORMATS.DEFAULT_DATE),
            endDate: endDate && moment(endDate).format(DATE_FORMATS.DEFAULT_DATE) || checkinDate
        }
        setDates({ ...dates, new: { ...newDates }});
        setSelectedDateRange(newDates);
    }

    const nextStepHandler = () =>{ 
        const newStartDate = moment(dates.new.startDate).format(DATE_FORMATS.DEFAULT_DATE);
        const newEndDate = moment(dates.new.endDate).format(DATE_FORMATS.DEFAULT_DATE);
        const data = {
            startDate: newStartDate,
            duration: (fetchNoOfNights(newStartDate, newEndDate) || 0) + 1
        }
        getManualOptions(data);
    }

    return (
        <>
            <div className={classes.root}>

                <DateChangeInfo 
                    originalDates={dates.original && formatDateRange(dates.original, DATE_FORMAT_OPTIONS) || ''}
                    newDates={dates.new && formatDateRange(dates.new, DATE_FORMAT_OPTIONS) || ''}
                    originalDatesLabel="Booking dates"
                    newDatesLabel="Change beds dates"
                />

                <BedsRangeSelect 
                    data={selectedAccomodation?.items || []}
                    onDateChangeHandler={onDateChangeHandler}
                />

            </div>

            <Footer 
                label="Next" 
                isBtnDisabled={!dates?.new.startDate ? true : false}
                onBtnClick={nextStepHandler}
            />
        </>
    )
}

export default connect(null)(DurationSelect);