import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import CounterInput from './CounterInput';

const useStyles = makeStyles(theme =>({
    root:{
        marginBottom: '40px'
    },
    activityInfo:{
        height: '175px',
        padding: '0px 40px',
        [theme.breakpoints.down('xs')]: {
            padding: '10px',
            height: '100%'
        }
    },
    imageContainer: {
        width: '100%',
        height: '175px'
    },
    roomImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
        borderRadius: '5px',
    },
    iconContainer:{
        fontSize: '13px',
        marginBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        '& img':{
            marginRight: '10px'
        }
    },
    addBtn:{
        borderRadius: '20px',
        backgroundColor: '#000',
        color: '#fff',
        padding: '5px 30px',
        fontWeight: 'bold',
        border: '0.5px solid #000',
        '&:hover':{
            border: '0.5px solid #000',
            color: '#000',
        },
        [theme.breakpoints.down('sm')]:{
            width: '100%',
        }
    }
}));

const ActivityItem = props =>{
    const classes = useStyles();
    return(
        <Grid container className={classes.root}>
            <Grid item xs={12} sm={5} className={classes.imageContainer}>
                    <img 
                        alt={'Breakfast'} 
                        src={'/images/deluxe-breakfast.jpg'}
                        className={classes.roomImage}
                    />
            </Grid>
            <Grid item xs={12} sm={7} className={classes.activityInfo}>
                <Box fontSize="17px" fontWeight="bold" marginBottom="3px">Deluxe Breakfast</Box>
                <Box fontSize="15px" mb={2}>served in our lounge until 1pm</Box>
                <Box fontSize="15px" fontWeight="bold" mb={1}>15 Euro per person</Box>
                <Box className={classes.iconContainer}>
                    <img src="/images/icons/location.png" width="15px" height="15px"/> Lounge Area
                </Box>
                <Box className={classes.iconContainer}>
                    <img src="/images/icons/history.png" width="15px" height="15px"/> 8am to 1pm
                </Box>
                <Box mt={2} display="flex">
                    <CounterInput />
                    <Button className={classes.addBtn}>Add</Button>
                </Box>
            </Grid>
        </Grid>
    );
}

export default ActivityItem;