import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import { withTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Header from '../components/booking_engine/header';
import BookingNavigationInfo from '../components/booking_engine/bookingNavigationInfo';
import TravelDetails from '../components/booking_engine/travelDetails';
import RoomCategoryList from '../components/booking_engine/room_category_list/roomCategoryList';
import RatePlanList from '../components/booking_engine/rate_plan/ratePlanList';
import BookingCart from '../components/booking_engine/booking_summary/bookingCart';
import Checkout from '../components/booking_engine/checkout/checkout';
import { fetchPropertyInfo, fetchAvailableRooms, setSelectedRoomType, updateSelectedBookingData, addRoomToBookingCart } from '../redux/actions/bookingEngine';
import ConfirmBooking from '../components/booking_engine/confirmBooking';
import PageNotFound from '../components/booking_engine/pageNotFound';
import AlertModal from '../components/booking_engine/alertModal';
import moment from 'moment-timezone';

import { fetchDateFormat, DATE_FORMATS } from "../utils/utility";
import { sum } from 'lodash';
import { calculateBaseRoomCost } from '../components/booking_engine/helper';

const headerHeight = 60;
const navigationBlockHeight = 50;
const totalMinusHeight = headerHeight + navigationBlockHeight;

const styles = theme => ({
    mainContainer: {
        paddingTop: totalMinusHeight,
        minHeight: 'calc(100vh - 80px)',
        background: '#ffffff',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 40px)',
            marginTop: '40px'
        }
    },
    mainContentHolder: {
        margin: '35px 0',
        // minHeight: `calc(100vh - ${totalMinusHeight}px)`,
    },
    mainContent: {
        width: '550px',
        borderRadius: '20px',
        margin: '0 auto',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
});

const BookingSteps = {
    STEP1: 1,
    STEP2: 2,
    STEP3: 3,
    STEP4: 4,
    STEP5: 5,
    STEP6: 6
}

class BookingEnginePage extends Component {
    state = {
        propertyID: null,
        showPage: false,
        activeStep: BookingSteps.STEP1,
        isPageNotFound: false,
        showAlert: false,
        continueHandler: null
    }

    alreadyExecuted = {}

    setDocumentTitle = (title) => {
        document.title = title;
    }

    googleAnalytics = (trackingID, options = {}) => {
        ReactGA.initialize(
            trackingID,
            { ...options }
        );
    }

    facebookPixel = (trackingID) => {
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        const options = {
            autoConfig: true, 	// set pixel's autoConfig
            debug: false, 		// enable logs
        };
        ReactPixel.init(trackingID, advancedMatching, options);
        ReactPixel.pageView();
    }

    beforeUnloadHandler = e => {
        const { activeStep } = this.state;
        if (activeStep == BookingSteps.STEP6) return;
        return e.returnValue = '/'
    }

    validateQueryParams = (propertyTimezone) => {
        const { location: { search } } = this.props;
        let validation = { startDate: false, endDate: false };

        const errorResponse = {
            hasDateParams: false,
            startDate: null,
            endDate: null
        };

        const searchParams = new URLSearchParams(search);
        const startDate = searchParams.get('checkIn');
        const endDate = searchParams.get('checkOut');

        if (!startDate || !endDate) return { ...errorResponse };

        //Check if date is valid.............
        validation.startDate = moment(startDate).isValid;
        validation.endDate = moment(endDate).isValid;

        if (!validation.startDate || !validation.endDate) return { ...errorResponse };

        const today = moment.tz(new Date(), propertyTimezone).format('YYYY-MM-DD');
        if (moment(startDate).isBefore(today)) return { ...errorResponse };

        return { hasDateParams: true, startDate, endDate };
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const propertyHandle = params.id;
        const response = this.props.dispatch(fetchPropertyInfo(propertyHandle, this.pageNotFoundHandler));
        response.then(data => {
            if (data?.propertyID && data?.timezone) {
                const fetchAvailRoomsRes = this.checkDateQueryParams(data)
                fetchAvailRoomsRes?.then(() => {
                    this.checkRoomTypeQueryParam()
                    this.checkGuestsRatePlanQueryParam()
                })
            }
        })

        window.addEventListener('beforeunload', this.beforeUnloadHandler);
    }

    checkDateQueryParams = data => {
        const queryData = this.validateQueryParams(data.timezone);

        if (!queryData.hasDateParams) return;

        const params = {
            checkIn: fetchDateFormat(queryData.startDate, DATE_FORMATS.DEFAULT_DATE),
            checkOut: fetchDateFormat(queryData.endDate, DATE_FORMATS.DEFAULT_DATE)
        };
        return this.props.dispatch(fetchAvailableRooms(data.propertyID, params, this.nextStep));
    }

    checkRoomTypeQueryParam = () => {
        const queryData = this.validateRoomTypeQueryParams() || {}

        if (!queryData.valid) return

        this.props.dispatch(setSelectedRoomType({
            roomTypeID: queryData.roomTypeID,
            dormOrPrivate: this.props.bookingEngine.roomTypes[queryData.roomTypeID]?.dormOrPrivate
        }))
        this.nextStep()
    }

    validateRoomTypeQueryParams = () => {
        const searchParams = new URLSearchParams(this.props.location.search)
        const roomTypeID = searchParams.get('roomTypeID')
        if (!roomTypeID) return { valid: false }

        if (this.props.bookingEngine.roomList[roomTypeID]?.available > 0) {
            return { valid: true, roomTypeID }
        }
    }

    checkGuestsRatePlanQueryParam = () => {
        const { dispatch, bookingEngine } = this.props
        const queryData = this.validateGuestsRatePlanQueryParam()

        if (!queryData.valid) return

        const noOfBedsBooked = queryData.guestCount
        const selectedRoomTypeID = bookingEngine?.selectedRoomTypeID
        const dormOrPrivate = bookingEngine?.roomTypes[selectedRoomTypeID]?.dormOrPrivate

        // guest count
        const updatePayload = { noOfBedsBooked: queryData.guestCount }
        dispatch(updateSelectedBookingData(updatePayload))

        // rate
        const rates = bookingEngine?.roomList[selectedRoomTypeID]?.rates[queryData.ratePlanID]
        const roomCostData = {
            noOfBedsBooked,
            sumOfSelectedRatePlan: sum(rates)
        };
        const baseRoomCost = calculateBaseRoomCost(roomCostData);

        const payload = {
            ratePlanID: queryData.ratePlanID,
            baseRoomCost,
            rates: rates,
            noOfBedsBooked,
            dormOrPrivate
        };
        dispatch(updateSelectedBookingData(payload));
        dispatch(addRoomToBookingCart(selectedRoomTypeID, payload))

        this.nextStep()
    }

    validateGuestsRatePlanQueryParam = () => {
        const searchParams = new URLSearchParams(this.props.location.search)
        const guestCount = searchParams.get('guestCount')
        const ratePlanID = searchParams.get('ratePlanID')
        if (!guestCount || !ratePlanID) return { valid: false }

        return { valid: true, ratePlanID, guestCount: Number(guestCount) }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.propertyDetails, prevProps.propertyDetails)) {
            const { googleAnalyticsID, fbPixelID } = this.props.propertyDetails;

            if (googleAnalyticsID) this.googleAnalytics(googleAnalyticsID);
            if (fbPixelID) this.facebookPixel(fbPixelID);

            this.setState({
                showPage: true,
                propertyID: this.props.propertyDetails._id
            });

            this.setDocumentTitle(this.props.propertyDetails.name);
        }
    }

    closeAlertModal = () => {
        this.setState({
            showAlert: false,
            continueHandler: null
        })
    }

    navigateToStepHandler = (stepNo) => {
        const { bookingCartItemCount } = this.props;
        if (stepNo == BookingSteps.STEP1 && bookingCartItemCount > 0) {
            this.setState({
                showAlert: true,
                continueHandler: () => {
                    this.setState({
                        showAlert: false,
                        activeStep: stepNo
                    });
                }
            })
            return;
        }
        this.setState({ activeStep: stepNo });
    }

    nextStep = () => {
        const { activeStep } = this.state;
        this.setState({ activeStep: activeStep + 1 });
    }

    prevStep = () => {
        const { activeStep } = this.state;
        const gotoStep = activeStep - 1;
        this.navigateToStepHandler(gotoStep);
    }

    getPaymentSettings = () => {
        const { bookEngineConfig } = this.props;
        const configOptions = {
            isCollectCard: Boolean(bookEngineConfig?.collectCard),
            isCollectPayment: Boolean(bookEngineConfig?.collectPayment),
            isPhoneNumberMandatory: Boolean(bookEngineConfig?.meta?.validatePhone),
            terms: bookEngineConfig?.terms
        }

        return { ...configOptions };
    }

    fetchBookingPageComponent = () => {
        const { activeStep, propertyID, alertModal } = this.state;
        const paymentSettings = this.getPaymentSettings();
        const props = {
            nextStep: this.nextStep,
            navigateToStep: this.navigateToStepHandler,
            propertyID,
            ...paymentSettings
        };
        switch (activeStep) {
            case BookingSteps.STEP1:
                return <TravelDetails {...props} />

            case BookingSteps.STEP2:
                return <RoomCategoryList {...props} />

            case BookingSteps.STEP3:
                return <RatePlanList {...props} />

            case BookingSteps.STEP4:
                return <BookingCart {...props} />

            case BookingSteps.STEP5:
                return <Checkout {...props} />

            case BookingSteps.STEP6:
                return <ConfirmBooking {...props} />

            default:
                return <TravelDetails />
        }
    }

    pageNotFoundHandler = () => {
        this.setState({ isPageNotFound: true });
    }

    render() {
        const { classes, propertyDetails, t, i18n } = this.props;
        const { activeStep, showPage, isPageNotFound, showAlert, continueHandler } = this.state;
        const BookingComponent = this.fetchBookingPageComponent();
        const propertyImage = propertyDetails.images ? propertyDetails.images[propertyDetails.images.length - 1] : '';
        return (
            <>
                {showPage &&
                    <Container className={`${classes.mainContainer}`}>
                        <Header
                            title={propertyDetails.name}
                            currency={propertyDetails.currency}
                            image={propertyImage}
                            i18n={i18n}
                        />
                        {activeStep != BookingSteps.STEP6 && (
                            <BookingNavigationInfo
                                activeStep={activeStep}
                                prevStepHandler={this.prevStep}
                            />
                        )}
                        <Box className={`${classes.mainContentHolder}`}>
                            <Box className={classes.mainContent}>
                                {BookingComponent}
                            </Box>
                        </Box>
                    </Container>
                }

                {isPageNotFound && (
                    <PageNotFound />
                )}

                {showAlert && (
                    <AlertModal
                        isOpen={showAlert}
                        message={t('bookingEngine.discardProgressConfirmation')}
                        continueHandler={continueHandler}
                        cancelHandler={this.closeAlertModal}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    const { bookingEngine } = state;
    //console.log(bookingEngine);
    const propertyDetails = bookingEngine.propertyDetails ? bookingEngine.propertyDetails : {};
    const bookingCartItemCount = Object.keys(bookingEngine.bookingDetails).length;
    const bookEngineConfig = bookingEngine?.bookEngineConfig || {};
    return {
        propertyDetails,
        bookingCartItemCount,
        bookEngineConfig,
        bookingEngine
    };
};

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(withRouter(BookingEnginePage))));
