import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'transparent',
    width: '100%',
    maxWidth: 1000,
    margin: '0 auto',
  },
  container: {
    background: 'transparent',
  },
  label: {
    '& span': {
      fontSize: '1.2rem',
    },
    '& .MuiStepIcon-active': {
      color: '#000000',
    },
  }
}));

function getSteps() {
  return ['Find your reservation', 'Guest details', 'Terms & Conditions', 'Complete Payment'];
}

export default function StepperFunction(props) {
  const classes = useStyles();
  const { activeStep } = props;
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper className={classes.container} activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step className={classes.label} key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
