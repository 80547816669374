import http from "../utils/http";
import { normalize } from "normalizr";
import { reservationSchema, reservationAllSchema, noteSchema, emailSchema, activityLogSchema } from "../schema/reservation";
//----------------------------------------------------------------
// CREATE RESERVATION
//----------------------------------------------------------------
export function createReservation(payload) {
  const ACTION_NAME = "CREATE_RESERVATION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/reservation/", payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// ADD RESERVATION
//----------------------------------------------------------------
export function addReservation(payload, type, propertyID) {
  const ACTION_NAME = "CREATE_RESERVATION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post('/api/reservation/intelligent/', { ...payload })
      .then(successResult => {
        const data = successResult.data && successResult.data.newRes;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        // console.log("errorResult", errorResult.response)
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  }
}

//----------------------------------------------------------------
// CREATE RESERVATION ACCOMMODATION
//----------------------------------------------------------------
// export function createReservationAccommodation(id, payload) {
//   const ACTION_NAME = "CREATE_RESERVATION_ACCOMMODATION";
//   return function (dispatch) {
//     dispatch({ type: `${ACTION_NAME}_REQUEST` });
//     return http
//       .post("/api/reservation/accList/" + id, payload)
//       .then(successResult => {
//         let normalizedData = normalize(successResult.data, reservationSchema);
//         dispatch({
//           type: `${ACTION_NAME}_SUCCESS`,
//           payload: normalizedData.entities
//         });
//       })
//       .catch(errorResult => {
//         let error =
//           errorResult && errorResult.response && errorResult.response.data
//             ? errorResult.response.data
//             : errorResult;
//         dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
//       });
//   };
// }

//----------------------------------------------------------------
// LOAD ONE RESERVATION DATA
//----------------------------------------------------------------
export function loadReservation(id) {
  const ACTION_NAME = "LOAD_RESERVATION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/reservation/" + id)
      .then(successResult => {
        let normalizedData = normalize(successResult.data.reservation, reservationSchema);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: {
            reservation: { ...normalizedData.entities },
            maskedCards: [...successResult.data.maskedCards]
          }
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// UPDATE RESERVATION
//----------------------------------------------------------------
export function updateReservation(id, payload) {
  const ACTION_NAME = "UPDATE_RESERVATION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/reservation/edit/" + id, payload)
      .then(successResult => {
        let normalizedData = normalize(successResult.data, reservationSchema);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  };
}

//----------------------------------------------------------------
// EDIT BASIC RESERVATION DETAILS
//----------------------------------------------------------------
export function updateReservationDetails(reservationID, payload) {
  const ACTION_NAME = "UPDATE_BASIC_RESERVATION_DATA";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/reservation/edit/${reservationID}`, payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: payload
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  };
}

//----------------------------------------------------------------
// UPDATE RESERVATION STATUS
//----------------------------------------------------------------
export function updateReservationStatus(id, payload) {
  const ACTION_NAME = "UPDATE_RESERVATION_STATUS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/reservation/status/" + id, payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  };
}

export function editReservation(payload) {
  const ACTION_NAME = "UPDATE_RESERVATION_DATA";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`/api/reservation/edit`, payload)
      .then(successResult => {

        const data = successResult.data?.updatedRes || successResult.data;

        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: data
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return
      });
  };
}

//----------------------------------------------------------------
// CANCEL RESERVATION DATA
//----------------------------------------------------------------
export function cancelReservation(id, payload) {
  const ACTION_NAME = "CANCEL_RESERVATION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/reservation/cancel/" + id, payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          deletedKey: id,
          payload: successResult.data
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  };
}

//----------------------------------------------------------------
// UPDATE RESERVATION STATUS
//----------------------------------------------------------------
// export function updateReservationStatus(id, payload) {
//   const ACTION_NAME = "UPDATE_RESERVATION_STATUS";
//   return function (dispatch) {
//     dispatch({ type: `${ACTION_NAME}_REQUEST` });
//     return http
//       .patch("/api/reservation/status/" + id, payload)
//       .then(successResult => {
//         dispatch({
//           type: `${ACTION_NAME}_SUCCESS`,
//           payload: successResult.data
//         });
//       })
//       .catch(errorResult => {
//         let error =
//           errorResult && errorResult.response && errorResult.response.data
//             ? errorResult.response.data
//             : errorResult;
//         dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
//       });
//   };
// }

//----------------------------------------------------------------
// CONFIRM RESERVATION
//----------------------------------------------------------------
export function confirmReservation(id, payload) {
  const ACTION_NAME = "CONFIRM_RESERVATION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/reservation/confirm/" + id, payload)
      .then(successResult => {
        let normalizedData = normalize(successResult.data, reservationSchema);
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: normalizedData.entities
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// CREATE RESERVATION NOTE
//----------------------------------------------------------------
export function createReservationNote(id, payload) {
  const ACTION_NAME = "CREATE_RESERVATION_NOTE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/reservation/" + id + "/note", payload)
      .then(successResult => {
        // console.log('posted new note', successResult.data)
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: { notes: successResult.data }
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD RESERVATION ALERTS
//----------------------------------------------------------------
// export function loadReservationAlerts(id) {
//   const ACTION_NAME = "LOAD_RESERVATION_ALERTS";
//   return function (dispatch) {
//     dispatch({ type: `${ACTION_NAME}_REQUEST` });
//     return http
//       .get("/api/alerts/" + id)
//       .then(successResult => {
//         dispatch({
//           type: `${ACTION_NAME}_SUCCESS`,
//           payload: successResult.data
//         });
//       })
//       .catch(errorResult => {
//         let error =
//           errorResult && errorResult.response && errorResult.response.data
//             ? errorResult.response.data
//             : errorResult;
//         dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
//       });
//   };
// }

//----------------------------------------------------------------
// DELETE RESERVATION ALERT
//----------------------------------------------------------------
// export function deleteReservationAlert(id) {
//   const ACTION_NAME = "DELETE_RESERVATION_ALERT";
//   return function (dispatch) {
//     dispatch({ type: `${ACTION_NAME}_REQUEST` });
//     return http
//       .delete("/api/alert/" + id)
//       .then(successResult => {
//         dispatch({
//           type: `${ACTION_NAME}_SUCCESS`,
//           payload: successResult.data
//         });
//       })
//       .catch(errorResult => {
//         let error =
//           errorResult && errorResult.response && errorResult.response.data
//             ? errorResult.response.data
//             : errorResult;
//         dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
//       });
//   };
// }

//----------------------------------------------------------------
// LOAD RESERVATION TRANSACTIONS
//----------------------------------------------------------------
export function loadReservationTransactions(id) {
  const ACTION_NAME = "LOAD_RESERVATION_TRANSACTIONS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/transactions/" + id)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// CREATE RESERVATION TRANSACTION
//----------------------------------------------------------------
export function createReservationTransaction(reservationID, payload) {
  const ACTION_NAME = "CREATE_RESERVATION_TRANSACTION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/transactions/" + reservationID, payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return
      });
  };
}

//----------------------------------------------------------------
// VOID RESERVATION TRANSACTION
//----------------------------------------------------------------
export function voidReservationTransaction(transactionID) {
  const ACTION_NAME = "VOID_RESERVATION_TRANSACTION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/transaction/void/" + transactionID)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// FETCH RESERVATION EXTEND SCENARIO
//----------------------------------------------------------------
export function fetchReservationExtendScenario(payload) {
  const ACTION_NAME = "FETCH_RESERVATION_EXTEND_SCENARIO";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/reservation/extendScenario", payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
        if (!successResult.data || !payload[0].key || (
          successResult.data && payload[0] && payload[0].key && successResult.data[payload[0].key] &&
          successResult.data[payload[0].key].bedID && !successResult.data[payload[0].key].bedID.length)) {
          dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: { message: "Beds not available" } });
        }
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// CREATE RESERVATION STRIPE PAYMENT
//----------------------------------------------------------------
export function createReservationStripePayment(reservationID, payload) {
  const ACTION_NAME = "CREATE_RESERVATION_STRIPE_PAYMENT";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/charge-card-off-session/" + reservationID, payload)
      .then(successResult => {
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// LOAD RESERVATION INVOICE
//----------------------------------------------------------------
export function loadReservationInvoice(id) {
  const ACTION_NAME = "LOAD_RESERVATION_INVOICE";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/reservation/invoice/" + id, { responseType: 'arraybuffer' })
      .then(successResult => {
        let blob = new Blob([successResult.data], { type: 'application/pdf' }),
          url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');

        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: "Success"
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}
//----------------------------------------------------------------
// LOAD RESERVATION EMAILS DETAILS
//----------------------------------------------------------------
export function loadEmails(reservationID) {
  const ACTION_NAME = "LOAD_EMAILS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/email/" + reservationID)
      .then(successResult => {
        let normalizedData = normalize(successResult.data, emailSchema);
        // console.log("normalizedData", normalizedData)
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: { emails: normalizedData.entities.email, emailIDs: normalizedData.result }
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}
//----------------------------------------------------------------
// LOAD RESERVATION ACTIVITY LOGS
//----------------------------------------------------------------
export function loadActivityLog(reservationID) {
  const ACTION_NAME = "LOAD_ACTIVITY_LOG";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get("/api/reservation/activity/" + reservationID)
      .then(successResult => {
        // let normalizedData = normalize(successResult.data, activityLogSchema);
        // dispatch({
        //   type: `${ACTION_NAME}_SUCCESS`,
        //   payload: { activityLog: normalizedData.entities.activityLog, logIDs: normalizedData.result }
        // });

        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: successResult.data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}

//----------------------------------------------------------------
// SEND RESERVATION EMAIL
//----------------------------------------------------------------
export function sendEmail(payload) {
  const ACTION_NAME = "SEND_EMAIL";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post("/api/email/", payload)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  };
}

//----------------------------------------------------------------
// EDIT RESERVATION WITH ITEMS
//----------------------------------------------------------------
export function updateReservationItems(reservationID, payload) {
  const ACTION_NAME = "UPDATE_RESERVATION_ITEMS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/reservation/items/" + reservationID, payload)
      .then(successResult => {
        const data = successResult.data
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: data
        });
        return true
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  };
}

//----------------------------------------------------------------
// DELETE RESERVATION TAX
//----------------------------------------------------------------
export function deleteReservationTax(reservationID, payload) {
  const ACTION_NAME = "DELETE_RESERVATION_TAX";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/reservation/tax/" + reservationID, payload)
      .then(successResult => {
        const data = successResult.data;
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: data
        });
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  };
}


//----------------------------------------------------------------
// CREATE_BOOKING_SOURCE
//----------------------------------------------------------------
export function createBookingSource(payload) {
  const ACTION_NAME = "CREATE_BOOKING_SOURCE";
  return function (dispatch) {
    return http.post('/api/bookingSource/', { ...payload })
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
// FETCH_BOOKING_SOURCE
//----------------------------------------------------------------
export function fetchBookingSource() {
  const ACTION_NAME = "FETCH_BOOKING_SOURCE";
  return function (dispatch) {
    return http.get(`/api/bookingSource/`)
      .then(successResult => {
        const data = successResult.data;
        // console.log(data);
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data })
      })
      .catch(errorResult => {
        console.log(errorResult);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorResult });
      });
  }
}

//----------------------------------------------------------------
//RESET RESERVATION DETAILS
//----------------------------------------------------------------
export function resetReservationDetails(payload) {
  return {
    type: "RESET_RESERVATION"
  };
}

//----------------------------------------------------------------
//SELECTED RESERVATION ACCOMMODATION
//----------------------------------------------------------------
export function selectedAccommodation(payload) {
  return {
    type: "SELECTED_RESERVATION_ACCOMMODATION",
    payload: payload
  };
}

//----------------------------------------------------------------
//SELECTED RESERVATION
//----------------------------------------------------------------
export function selectedReservation(payload) {
  return {
    type: "SELECTED_RESERVATION",
    payload: payload
  };
}

//----------------------------------------------------------------
//SELECTED HEADER
//----------------------------------------------------------------
export function selectedHeader(payload) {
  return {
    type: "SELECTED_HEADER",
    payload: payload
  };
}

//----------------------------------------------------------------
//SET DRAWER OPEN VALUE
//----------------------------------------------------------------
export function setDrawerOpenValue(payload) {
  return {
    type: "SET_DRAWER_OPEN_VALUE",
    payload: payload
  };
}

// Show card details ............................
export function fetchChannexSessionToken(scope = 'card') {
  return http.get(`/api/bookEngine/payment/pci/sessionToken/`);
}

export function fetchCardDetails(params) {
  return http.get(`https://pci.channex.io/api/v1/show_card`, { params })
}

export function channexCaptureCard(reservationID, data, initChannexForm) {
  const ACTION_NAME = "CHANNEX_CAPTURE_CARD";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch(`api/reservation/data/${reservationID}`, { channexCardToken: data.card_token })
      .then(successResult => {
        const { reservation } = successResult.data;
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: data
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;

        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });

        initChannexForm();

        return;
      });
  };
}

export function resetError(action) {
  return function (dispatch) {
    dispatch({ type: `${action}_RESET`, payload: { error: null } });
  };
}


export function getReservationDetailsViaCode(propertyCode, reservationCode) {
  const ACTION_NAME = "LOAD_RESERVATION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/reservation/${propertyCode}/${reservationCode}`)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS`, payload: data });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        const errorMessage = error.error && error.error.message || error;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: errorMessage });
        return false;
      });
  };
}

//----------------------------------------------------------------
// LOAD MANUAL OPTIONS
//----------------------------------------------------------------
export function loadReservationManualOptions(propertyID, params) {
  const ACTION_NAME = "LOAD_RESERVATION_MANUAL_OPTIONS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .get(`/api/reservation/manualOptions/${propertyID}`, { params })
      .then(successResult => {
        const data = successResult.data;
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        return data;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        console.log(error);
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  };
}

//FETCH AND OPEN CUSTOMER DOCS IN NEW TAB..... 
export const handleLoadDocument = (file, fileName) => {
  const ACTION_NAME = "OPEN_CUSTOMER_DOCUMENT";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post(`/api/customer/document/`, { file, fileName })
      .then(successResult => {
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        window.open(successResult.data, '_blank');
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
      });
  }
};

//FETCH RESERVATION TAGS............................. 
export const getReservationTags = (propertyID) => {
  const ACTION_NAME = "GET_REERVATION_TAGS";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.get(`/api/tags`, { params: { propertyID } })
      .then(successResult => {
        dispatch({ type: `${ACTION_NAME}_SUCCESS` });
        return successResult.data;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return false;
      });
  }
};

//CREATE RESERVATION TAG.................................
export const createReservationTag = payload => {
  const ACTION_NAME = "CREATE_RESERVATION_TAG";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http.post(`/api/tag`, payload)
      .then(successResult => {
        const data = successResult.data;
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: data
        });
        return data;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return false;
      });
  }
}

//----------------------------------------------------------------
// CREATE AND ASSIGN GUEST TO ACCOMMODATION
//----------------------------------------------------------------
export function addAssignGuestAccommodation(reservationID, payload) {
  const ACTION_NAME = "ADD_ASSIGN_GUEST_ACCOMMODATION";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .patch("/api/reservation/checkInFlow/" + reservationID, payload)
      .then(successResult => {
        const data = successResult.data;
        dispatch({
          type: `${ACTION_NAME}_SUCCESS`,
          payload: data.reservation
        });
        return data
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  };
}

//----------------------------------------------------------------
// TRANSACTION REFUND
//----------------------------------------------------------------
export function transactionRefund(params, data) {
  const ACTION_NAME = "TRANSACTION_REFUND";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/transaction/refund/${params.reservationID}/${params.originalTxnID}`, data)
      .then(successResult => {
        const { message: { balance, transaction } } = successResult.data;
        dispatch({ 
          type: `${ACTION_NAME}_SUCCESS`,
          payload: {
            balance,
            transaction
          }
        });
        return true;
      })
      .catch(errorResult => {
        let error = errorResult?.response?.data?.error?.message;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  };
}

//----------------------------------------------------------------
// TRANSACTION REFUND
//----------------------------------------------------------------
export function createReservationTax(reservationID, payload) {
  const ACTION_NAME = "CREATE_RESERVATION_TAX";
  return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
    return http
      .post(`/api/reservation/manualTax/${reservationID}`, payload)
      .then(successResult => {
        const data = successResult.data;
        dispatch({ 
          type: `${ACTION_NAME}_SUCCESS`,
          payload: data
        });
        return true;
      })
      .catch(errorResult => {
        let error =
          errorResult && errorResult.response && errorResult.response.data
            ? errorResult.response.data
            : errorResult;
        dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        return;
      });
  };
}