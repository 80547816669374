import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { getAmtFormat } from '../../../utils/utility';

const useStyles = makeStyles(theme =>({
    root: {
        color: "#5f5f5f",
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #e3e3e3",

        '& .itemCount': {
            fontSize: "1.1rem",
            fontWeight: 600,
            marginBottom: "5px",
        },
        '& .itemInfo': {
            fontSize: "1.2rem",
            color: "#bdbdbd",
        }
    },
    rowItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    itemName: {
        fontWeight: 600,
        fontSize: "1.4rem",
        marginBottom: "5px",
        color: "#5f5f5f",
    },
}));

const UpsellSummary = props =>{
    const classes = useStyles();
    const { upsellItems, currency, reservation, } = props;
    const reservationUpsell = reservation?.upsellList || [];

    const summary = reservationUpsell.filter(resItem => resItem)
        .map((item, index) => {
            const upsellData = { ...upsellItems[item.upsellID] }

            if (!item.count) return null;

            return (
                <div className={classes.root} key={`upsell_${index}`}>
                    <div className="itemCount">{item.count} x items</div>
                    <div className={clsx(classes.rowItem, classes.itemName)}>
                        <div>{upsellData.name}</div>
                        <div>{item.amount ? getAmtFormat(item.amount, currency) : null}</div>
                    </div>
                    <div className="itemInfo">{getAmtFormat(item.amount / item.count, currency) + ' per item'}</div>
                </div>
            )
    });

    return summary;
}

export default UpsellSummary;