import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import CustomCheckBox from './CustomCheckBox';
import Footer from '../Footer';
import { withTranslation } from 'react-i18next';

const useStyles = makeStyles(theme =>({
    root: {
        margin: '0 auto',
        width: '75%',
        maxWidth: 500,
        paddingTop: '60px',
        [theme.breakpoints.down('sm')]: {
           width: '100%'
        },
    },
    personalInfoSection:{
        marginTop: '20px',
    },
    title:{
        color: '#333333',
        fontSize: '2.4rem',
        marginBottom: 40,
    },
    label: {
        fontSize: '1.2rem',
        color: '#666666',
        marginBottom: 10,
    },
    row: {
        marginBottom: 40,
    },
    cardContainer:{
        border: '1px solid #000000',
        boxShadow: 'none',
        borderRadius: '5px',
        color: '#7B7B7B',
        fontSize: '1.3rem',
        lineHeight: 1.3,
        padding: '20px',
        marginBottom: '20px'
    },
    rulesHeaderText: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '10px'
    },
    rulesText: {
        fontSize: '15px',
        marginBottom: '20px'
    },
    error: {
        color: "#f44336",
        fontWeight: 400,
        fontSize: '12px'
    },
    backIcon: {
        color: '#000000',
        cursor: 'pointer',
        fontSize: '18px',
        width: 40,
    },
    bottom: {
        marginBottom: 20
    },
    header: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '20px'
    }
}));

const Rules = props =>{
    const { settings, t, history } = props
    const [form, setForm] = React.useState({ houseRules: false, termsAndConditions: false, covidGuidelines: false})
    const [error, setError] = React.useState({ houseRules: false, termsAndConditions: false, covidGuidelines: false})
    const classes = useStyles();

    const handleChange = (value) => {
        setForm({...form, [value]: !form[value]})
        setError({...error, [value]: false})
    }

    const proceed = (value) => {
        if(!value) {
            if(settings.customerRules) {
                if((settings.customerRules.houseRules && settings.customerRules.houseRules != 'undefined') && (!form.houseRules)) {
                    return setError({...error, houseRules: true})
                }
                if((settings.customerRules.termsAndConditions && settings.customerRules.termsAndConditions != 'undefined') && (!form.termsAndConditions)) {
                    return setError({...error, termsAndConditions: true})
                }
                if((settings.customerRules.covidGuidelines && settings.customerRules.covidGuidelines != 'undefined') && (!form.covidGuidelines)) {
                    return setError({...error, covidGuidelines: true})
                }
            }
            props.nextStep()
        }
        else {
            props.nextStep()
        }
    }

    if (settings) {
        if ((settings.customerRules) && (settings.customerRules.skip))
            proceed(true)
        else if (!settings.customerRules)
            proceed(true)
    }

    const handleBack = () => {
        history.goBack()
    }
 
    return(
        <>
            <Box className={classes.root}>
                <Box className={classes.personalInfo}>
                    <div className={classes.title}>
                        The fine print...
                    </div>
                </Box>
                <div className={classes.left}>
                    {(settings) && (settings.customerRules) && (settings.customerRules.houseRules && settings.customerRules.houseRules != 'undefined') && <Box mb={3} className={classes.row}>
                        <Box className={classes.label}>{t('onlineCheckin.rules.houseRules')}</Box>
                        <Card className={classes.cardContainer}>
                            <div  dangerouslySetInnerHTML={{__html: settings.customerRules.houseRules}} />
                        </Card>
                        <CustomCheckBox 
                            label={t('onlineCheckin.rules.agreeRules')}
                            name="houseRules"
                            onChange={() => handleChange('houseRules')}
                        />
                        {(error.houseRules) && <p className={classes.error}>{t('onlineCheckin.rules.houseRulesError')}</p>}
                    </Box>}
                    {(settings) && (settings.customerRules) && (settings.customerRules.termsAndConditions && settings.customerRules.termsAndConditions != 'undefined') && <Box mb={3} className={classes.row}>
                        <Box className={classes.label}>{t('onlineCheckin.rules.terms')}</Box>
                        <Card className={classes.cardContainer}>
                            <div  dangerouslySetInnerHTML={{__html: settings.customerRules.termsAndConditions}} />
                        </Card>
                        <CustomCheckBox 
                            label={t('onlineCheckin.rules.agreeTerms')}
                            name="termsAndConditions"
                            onChange={() => handleChange('termsAndConditions')}
                        />
                        {(error.termsAndConditions) && <p className={classes.error}>{t('onlineCheckin.rules.termsError')}</p>}
                    </Box>}
                    {(settings) && (settings.customerRules) && (settings.customerRules.covidGuidelines && settings.customerRules.covidGuidelines != 'undefined') && <Box mb={3} className={classes.row}>
                        <Box className={classes.label}>{t('onlineCheckin.rules.guidelines')}</Box>
                        <Card className={classes.cardContainer}>
                            <div  dangerouslySetInnerHTML={{__html: settings.customerRules.covidGuidelines}} />
                        </Card>
                        <CustomCheckBox 
                            label={t('onlineCheckin.rules.agreeGuidelines')}
                            name="covidGuidelines"
                            onChange={() => handleChange('covidGuidelines')}
                        />
                        {(error.covidGuidelines) && <p className={classes.error}>{t('onlineCheckin.rules.guidelinesError')}</p>}
                    </Box>}
                    {(settings) && (!settings.customerRules) && <div>
                        <Box mb={3} className={classes.row}>
                            <Box className={classes.label}>{t('onlineCheckin.rules.guidelines')}</Box>
                            <Card className={classes.cardContainer}>
                                <Box fontSize="15px" color="gray" lineHeight="20px">
                                    It is a long established fact that a reader will be distracted by the 
                                    readable content of a page when looking at its layout. The point of using 
                                    Lorem Ipsum is that it has a more-or-less normal distribution of letters,
                                    as opposed to using 'Content here, content here', making it look like 
                                    readable English. Many desktop publishing packages and web page editors
                                    now use Lorem Ipsum as their default model text, and a search for 'lorem 
                                    ipsum' will uncover many web sites still in their infancy. Various versions 
                                    have evolved over the years, sometimes by accident, sometimes on purpose
                                    (injected humour and the like).
                                </Box>
                            </Card>
                            <CustomCheckBox 
                                label={t('onlineCheckin.rules.agreeGuidelines')}
                                name="terms_and_conditions"
                            />
                        </Box>
                        <Box mb={3} className={classes.row}>
                            <Box className={classes.headerText}>{t('onlineCheckin.rules.houseRules')}</Box>
                            <Card className={classes.cardContainer}>
                                <div className={classes.rulesHeaderText}>{t('onlineCheckin.rules.groupBooking')}</div>
                                <div className={classes.rulesText}>
                                    {t('onlineCheckin.rules.groupBookingSubText')}
                                </div>

                                <div className={classes.rulesHeaderText}>{t('onlineCheckin.rules.checkInCheckOut')}</div>
                                <div className={classes.rulesText}>
                                    {t('onlineCheckin.rules.checkInText')}<br/>
                                    {t('onlineCheckin.rules.checkOutText')}
                                </div>

                                <div className={classes.rulesHeaderText}>{t('onlineCheckin.rules.breakfast')}</div>
                                <div className={classes.rulesText}>
                                    {t('onlineCheckin.rules.breakfastText')}
                                </div>
                            </Card>
                            <CustomCheckBox 
                                label={t('onlineCheckin.rules.agreeRules')}
                                name="terms_and_conditions2"
                            />
                        </Box>
                    </div>}
                </div>
            </Box>
            <Footer 
                stepInfo="Step 3 of 4"
                buttonLabel={t('actions.proceed')}
                onProceed = {() => proceed(false)}
            />
        </>
    );
}

const mapStateToProps = state =>{
    const { onlineCheckin } = state;
    return{
        settings: onlineCheckin.settings
    }
}

export default withTranslation()(connect(mapStateToProps)(Rules))