import React from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FormControlLabel, InputBase, Button, InputAdornment } from '@material-ui/core';
import NumberInput from '../../../common/NumberInput';
import Checkbox from '../../common/CustomCheckbox';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '1.2rem'
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 600,
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  section: {
    marginBottom: 30,
    marginTop: 10
  },
  sectionTitle: {
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: 10
  },
  sectionChips: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 30,
    flexWrap: 'wrap'
  },
  chips: {
    padding: '10px 15px',
    border: "1px solid #e0e0e0",
    borderRadius: 5,
    fontSize: '1.2rem',
    fontWeight: 600,
    margin: 4,
    cursor: 'pointer',
    '&.selected': {
      backgroundColor: '#eeeeee',
    }
  },
  success: {
    color: '#4caf50'
  },
  warning: {
    color: '#ff9800'
  },
  error: {
    margin: '15px 0',
    color: '#e37381'
  },
  break: {
    margin: '20px 0px',
    width: '100%',
    height: 4,
    boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
  },
  form: {
    display: 'grid'
  },
  button: {
    backgroundColor: '#eeeeee',
    width: '100%',
    padding: '10px 0',
    '&:hover': {
      backgroundColor: '#eeeeee',
    }
  },
  field: {
    background: '#ffffff',
    borderRadius: 5,
    width: '25%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    border: "1px solid #e0e0e0",
    marginBottom: '5px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  block: {
    display: 'block',
    padding: '0 4px'
  },
  checkbox: {
    marginLeft: '-8px'
  }
}));

const SelectBedForm = (props) => {
  const classes = useStyles();
  const { roomTypes, rooms, beds, form, currency, t } = props;

  return (
    <div className={classes.root}>
      <div className={classes.title}>Room Type | {props.roomTypeName}</div>

      {props.typeOption && <>
        <div className={classes.sectionTitle}>Select type of room</div>
        <div className={classes.sectionChips}>
          {['dorm', 'private'].map((value) => (
            <div
              key={value}
              className={clsx({
                [classes.chips]: true,
                'selected': value === form.typeOfRoom
              })}
              onClick={() => { if (value !== form.typeOfRoom) { props.handleSelectTypeOfRoom(value) } }}>
              {value}
            </div>
          ))}
        </div>
      </>}

      {props.typeOption && !props?.alternateRoomTypes?.length && <div className={classes.error}>No room available for this selection!</div>}

      {props.alternateRoomTypes && props.alternateRoomTypes.length > 0 && 
        <>
          {!props.typeOption &&
            <>
              <div className={classes.sectionTitle}>Select Room</div>
              <div className={classes.error}>No room available for this Room Type!</div>
            </>
          }

          <div className={classes.sectionTitle}>Select type of room</div>
          <div className={classes.sectionChips}>
            {props.alternateRoomTypes.map((id) => (
              roomTypes[id] &&
              <div
                key={id}
                className={clsx({
                  [classes.chips]: true,
                  'selected': id === form.roomTypeID
                })}
                onClick={() => { if (id !== form.roomTypeID) { props.handleSelectRoomType(id) } }}>
                {roomTypes[id] && roomTypes[id].name}
              </div>
            ))}
          </div>
        </>
      }

      {props.roomList &&
        <>
          <div className={classes.sectionTitle}>Select Room</div>
          <div className={classes.sectionChips}>
            {Object.keys(props.roomList).map((id) => (
              rooms[id] &&
              <div className={clsx({
                [classes.chips]: true,
                'selected': id === form.roomID
              })} key={id} onClick={() => {
                if (id !== form.roomID) {
                  props.handleSelectRoom(id, form.roomTypeID)
                }
              }}>{rooms[id].name}</div>
            ))}
          </div>
        </>}

      {props.bedList && props.bedList.length > 0 &&
        <div className={classes.section}>
          <div className={classes.sectionTitle}>Select Bed</div>
          <div className={classes.sectionChips}>
            {props.bedList.map(bedId => beds[bedId] &&
              <div className={clsx({
                [classes.chips]: true,
                'selected': bedId === form.bedID
              })} key={bedId} onClick={() => {
                if (bedId !== form.bedID) {
                  props.handleSelectBed(bedId)
                }
              }}>{beds[bedId].name}</div>
            )}
          </div>
        </div>}


      {((!props.single && props.isAvailable)) && <>
        {props.isAvailable &&
          <div className={classes.success}>
            <span>{props.edit? 'Apply selection to the given date range' : 'This Room and Bed is available for the entire stay!'}</span>
          </div>
        }
        <div className={classes.section}>
          {/* <FormControlLabel value="selection" control={
            <Checkbox
              color="primary"
              checked={form.entireStay}
              onChange={(e) => props.handleSetSelectionEntireStay(e.target.checked)}
            />
          } label={props.edit? 'Apply selection to the given date range' : 'Apply selection to the given of the stay'} labelPlacement="end" /> */}
        
          <Checkbox 
            className={classes.checkbox}
            value={form.entireStay}
            onChange={(e) => props.handleSetSelectionEntireStay(e.target.checked)}
            label={props.edit? 'Apply selection to the given date range' : 'Apply selection to the given of the stay'}
          />

        </div>
      </>}

      {!props.isAvailable && form.bedID && !props.single && 
        <div className={classes.warning}>
          <span>{'This Room and Bed is available only for the selected date!'}</span>
        </div>
      }

      {form.bedID && <>
        <div className={classes.break}></div>
        <div className={classes.section}>
          <div className={classes.sectionTitle}>Set Price for {props.bedOrRoom}</div>
          <div className={classes.form}>
            <NumberInput
              variantType="base"
              disabled={!(form.bedID)}
              className={classes.field}
              value={form.amount}
              min={0}
              onChange={(e) => { props.setAmount(e.target.value) }}
              startAdornment={
                <InputAdornment position="start">{currency}</InputAdornment>
              } />

              {/* <FormControlLabel value="rate" style={{ marginTop: 15 }} control={
                <Checkbox
                  color="primary"
                  disabled={!form.bedID}
                  checked={form.rateEntireStay}
                  onChange={(e) => { props.setRateEntireStay(e.target.checked) }}
                />
              } label={props.edit? 'Apply this price for the selected date range' : 'Apply this price for the entire stay'} labelPlacement="end" /> */}

              <Checkbox 
                className={classes.checkbox}
                disabled={!form.bedID}
                value={form.rateEntireStay}
                onChange={(e) => { props.setRateEntireStay(e.target.checked) }}
                label={props.edit? 'Apply this price for the selected date range' : 'Apply this price for the entire stay'}
              />
          </div>
        </div>
        {!props.edit && <div className={classes.action}>
          <Button className={classes.button} onClick={() => props.handleSave()} disabled={!(form.bedID)}>{props.ButtonText}</Button>
        </div>}
      </>}
    </div>
  )
}

export default withTranslation()(SelectBedForm);