import http from "../utils/http";
import axios from 'axios';
import { normalize } from "normalizr";
import { roomInfoSchema } from "../schema/bookingEngineSchema";

// const propertyID = "5df7695cc3a0427e4a4f4f4a";

export function fetchPropertyInfo(propertyHandle, pageNotFoundHandler){
    const ACTION_NAME = "FETCH_ROOMS_INFO";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST`});
        return http
        .get(`/api/bookEngine/content/${propertyHandle}/`)
        .then(successResult => {
            let normalizedData = normalize(successResult.data, roomInfoSchema);
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: {
                    propertyDetails: successResult.data.property,
                    bookEngineConfig: successResult.data?.bookEngineConfig || {},
                    roomTypes: normalizedData.entities.roomTypes,
                    taxItems: normalizedData.entities.taxItems,
                    ratePlans: normalizedData.entities.ratePlans
                }
            });

            return {
                propertyID: successResult?.data?.property?._id,
                timezone: successResult?.data?.property?.timezone,
            }

        })
        .catch(errorResult => {

            const isApiError = (errorResult && 
                errorResult.response && 
                errorResult.response.data) ? true: false;

            if(isApiError && 
                errorResult.response.status && 
                errorResult.response.status == 404
            ){
                pageNotFoundHandler();
                return;
            }

            let error = isApiError ? errorResult.response.data : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
            console.error('Internal server error');
        });
    }
}

export function fetchAvailableRooms(propertyID, params, nextStep) {
    const ACTION_NAME = "FETCH_AVAILABLE_ROOMS";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST`});
        return http
        .get(`/api/bookEngine/rooms/${propertyID}/`, {params} )
        .then(successResult => {
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: {
                    roomListData: successResult.data,
                    checkInDate: params.checkIn,
                    checkOutDate: params.checkOut
                }
            });
            nextStep();
        })
        .catch(errorResult => {
            let error =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        });
    }
}

export const resetBookingCart = () =>({
    type: 'RESET_BOOKING_CART'
});

export const setSelectedRoomType = payload =>({
    type: 'SET_SELECTED_ROOM_TYPE_ID',
    payload
});

export const updateSelectedBookingData = payload =>({
    type: 'UPDATE_SELECTED_BOOKIN_DATA',
    payload
});

export const addRoomToBookingCart = (roomTypeID, roomData) =>({
    type: 'ADD_ROOM_TO_BOOKING_CART',
    payload: { roomTypeID, roomData: {...roomData} }
});

export const updateBookingRoomDetails = (roomTypeID, updatePayload) =>({
    type: 'UPDATE_BOOKING_ROOM_DETAILS',
    payload: { roomTypeID, updatePayload } 
});

export const deleteRoomFromCart = roomTypeID =>({
    type: 'DELETE_ROOM_FROM_CART',
    payload: { roomTypeID }
});

export const fetchTotalBookingCostInfo = postData =>{
    const ACTION_NAME = "FETCH_TOTAL_BOOKING_COST_INFO";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST`});
        return http
        .post(`/api/bookEngine/content/taxCalculate`, postData )
        .then(successResult => {
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`,
                payload: successResult.data
            });
        })
        .catch(errorResult => {
            let error =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        });
    }
}

export const showBookingLoader = () =>({
    type: 'BOOKING_LOADER_REQUEST'
});

export const hideBookingLoader = () =>({
    type: 'BOOKING_LOADER_SUCCESS'
});

export const addReservationDetails = data =>({
    type: 'ADD_RESERVATION_DETAILS',
    payload: data
});

export const fetchBookingInvoice = reservationID =>{
    const ACTION_NAME = "BOOKING_LOADER";
    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST`});
        return http
        .get(`/api/bookEngine/content/confirmation/${reservationID}/`, 
            { responseType: 'arraybuffer' })
        .then(successResult => {
            let blob = new Blob([successResult.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
            dispatch({ type: `${ACTION_NAME}_SUCCESS`});
        })
        .catch(errorResult => {
            let error =
              errorResult && errorResult.response && errorResult.response.data
                ? errorResult.response.data
                : errorResult;
            dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
        });
    }
}

// Channex payments.....................
export const fetchSessionToken = () =>{
    return http.get(`/api/bookEngine/payment/pci/sessionToken/`);
}

