const PropertyReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOAD_PROPERTY_SUCCESS":
      const { property, features, timezone } = action.payload;
      return { ...state, property: property, features: features, timezone: timezone };
    // ----------------------------------------------------------------
    case "LOAD_ONE_CASH_DRAWER_REQUEST":
      return { ...state, cashDrawer: null, cashDrawerSessions: null };
    case "LOAD_ONE_CASH_DRAWER_SUCCESS":
      return { ...state, cashDrawer: action.payload.cashDrawer, cashDrawerSessions: action.payload.sessions };
    case "LOAD_ONE_CASH_DRAWER_FAILURE":
      return { ...state, cashDrawer: null, cashDrawerSessions: null };
    //------------------------------------------------------------------------
    case "LOAD_EMAIL_TEMPLATES_SUCCESS":
      return { ...state, ...action.payload };
    //------------------------------------------------------------------------
    case "SET_CASH_DRAWER_DIALOG_CLOSE_VALUE":
      return { ...state, cashDrawerDialogCloseValue: action.payload };
    //------------------------------------------------------------------------
    case "LOAD_CASH_ACCOUNTS_SUCCESS":
      return { ...state, accounts: action.payload.accounts, accountTypes: action.payload.subCategory };
    //------------------------------------------------------------------------
    case "LOAD_TRANSACTION_MODES_SUCCESS":
      return { ...state, ...action.payload };
    //------------------------------------------------------------------------
    case "LOAD_UPSELL_ITEMS_SUCCESS":
      return { ...state, ...action.payload };
    //------------------------------------------------------------------------
    default:
      return state;
  }
};

export default PropertyReducer;
