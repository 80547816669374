import React from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

const useStyles = makeStyles(theme=>({
    base: {
        background: '#006f63',
        color: '#ffffff'
    },
    root:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px'
    },
    infoContainer:{
        padding: '10px',
        textAlign: 'center'
    },
    languageSelect: {
        borderRadius: 10,
        boxShadow: '0 2px 20px #F0F0F0',
        color: '#999999',
        minWidth: '75px',
        marginRight: 10,
        marginTop: 15,
        padding: '3px 3px',
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
            marginLeft: '10px',
        },
    }
}));

const selectStyle = {
    control: base => ({
        ...base,
        border: 0,
        paddingLeft: 8,
        // This line disable the blue border
        boxShadow: 'none',
        minWidth: '75px',
        '[type="text"]': {
            fontFamily: 'Roboto, sans-serif !important',
            fontSize: '1.4rem',
            color: '#999999'
        }
    })
}

const languageArray = [
    { label: 'EN', value: 'en' },
    { label: 'DE', value: 'de' },
    { label: 'ES', value: 'es' },
    { label: 'FR', value: 'fr' },
    { label: 'IT', value: 'it' },
    { label: 'PT', value: 'pt' },
    { label: 'RU', value: 'ru' },
    { label: 'SV', value: 'sv' }
]

const Header = ({
    title,
    propertyDetails,
    settings,
    t,
    i18n
}) => {
    const classes = useStyles();

    let displayComponent

    const theme = useTheme();
    const deviceMD = useMediaQuery(theme.breakpoints.up('sm'));
    const [selected, setSelected] = React.useState(languageArray[0])

    const selectLanguage = (e) => {
        setSelected(e)
        i18n.changeLanguage(e.value);
    }

    React.useEffect(() => {
        let isMounted = true
        const currentLanguage = i18next.language
        const language = _.find(languageArray, {value: currentLanguage});
        if(isMounted && language) {
            setSelected(language)
        }

        return () => { isMounted = false }
    }, [i18n])

    if ((settings) && (settings.bannerColor) && (settings.fontColor)) {
        displayComponent = (
            <React.Fragment>
                <Box className={classes.base} style={{background: settings.bannerColor, color: settings.fontColor}}>
                    <Grid container>
                        <Grid item md={4}></Grid>
                        <Grid item xs={12} md={4} className={classes.root}>
                            <Box className={classes.infoContainer}>
                                <Box fontSize="20px" fontWeight="bold" mb={1}>{t('onlineCheckin.header')}</Box>
                                <Box fontSize="15px" fontWeight="bold">{title}</Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4} md={3}></Grid>
                        <Grid item xs={4} md={1}>
                            <Box className={classes.languageSelect}>
                                <Select
                                    //defaultValue={languageArray[0]}
                                    value={selected}
                                    styles={selectStyle}
                                    options={languageArray}
                                    onChange={(e) => selectLanguage(e)}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#666666',
                                            primary25: '#dddddd'
                                        }
                                    })}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                    {/*<Box className={classes.root}>
                        <Box className={classes.infoContainer}>
                            <Box fontSize="20px" fontWeight="bold" mb={1}>{t('onlineCheckin.header')}</Box>
                            <Box fontSize="15px" fontWeight="bold">{title}</Box>
                        </Box>
                    </Box>*/}
                </Box>
            </React.Fragment>
        )
    }
    else {
        displayComponent = (
            <React.Fragment>
                <Box className={classes.base}>
                    <Grid container>
                        <Grid item md={4}></Grid>
                        <Grid item xs={12} md={4} className={classes.root}>
                            <Box className={classes.infoContainer}>
                                <Box fontSize="20px" fontWeight="bold" mb={1}>{t('onlineCheckin.header')}</Box>
                                <Box fontSize="15px" fontWeight="bold">{title}</Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4} md={3}></Grid>
                        <Grid item xs={4} md={1}>
                            <Box className={classes.languageSelect}>
                                <Select
                                    //defaultValue={languageArray[0]}
                                    value={selected}
                                    styles={selectStyle}
                                    options={languageArray}
                                    onChange={(e) => selectLanguage(e)}
                                    theme={theme => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary: '#666666',
                                            primary25: '#dddddd'
                                        }
                                    })}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                </Box>
            </React.Fragment>
        )
    }

    return(
        <div>
            {displayComponent}
        </div>
    )
}

const mapStateToProps = state =>{
    const { onlineCheckin } = state;
    return{
        propertyDetails:  onlineCheckin.propertyDetails,
        settings: onlineCheckin.settings
    }
}

export default withTranslation()(connect(mapStateToProps)(Header))