import React from "react";
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';

import { fetchDateFormat } from '../../utils/utility';

const secondaryColor = '#5C5C5C';

const useStyles = makeStyles(theme =>({
    icon: {
        color: '#000000',
        cursor: 'pointer',
        fontSize: '18px',
        width: 40,
    },

    navigationBlockHeaderDateIcon: {
        marginLeft: '5px'
    },

    travelDate: {
        alignItems: "center",
        color: "#a1a1a1",
        display: "flex", 
        fontSize: "15px",
        fontWeight: "500",
        paddingLeft: 40,
    },
    linkContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    saveContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 30,
    },
    addContainer: {
        display: 'flex',
        justifyContent: 'flex-start'
    },
    button: {
        background: '#000000',
        borderRadius: 10,
        color: '#ffffff',
        cursor: 'pointer',
        fontSize: '1.4rem',
        fontWeight: 600,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: 5,
        paddingBottom: 5
    }
}));

const BookingNavigationInfo = ({ 
    activeStep,
    checkInDate,
    checkOutDate,
    prevStepHandler,
    t
}) =>{
    const classes = useStyles();
    const checkInDateFormat = fetchDateFormat(checkInDate, 'Do');
    const checkOutDateFormat = fetchDateFormat(checkOutDate, 'Do MMMM YYYY');
    const formattedTraveldate = `${checkInDateFormat} - ${checkOutDateFormat}`;

    const stepDetails = {
        step1: { title: t('bookingEngine.steps.step1') },
        step2: { title: t('bookingEngine.steps.step2') },
        step3: { title: t('bookingEngine.steps.step3') },
        step4: { title: t('bookingEngine.steps.step4') },
        step5: { title: t('bookingEngine.steps.step5') },
    };
    
    return(
        <Typography component="div" className={classes.linkContainer}>
            <div className={classes.addContainer}>
                <div>
                    <Box display="flex" fontWeight="bold" fontSize="16px">
                        {( activeStep > 1 ) && (
                            <ArrowBackIosIcon 
                                className={classes.icon} 
                                onClick={prevStepHandler}
                            />
                        )}
                        <span>
                            {`Step ${activeStep}: ${stepDetails[`step${activeStep}`].title}`}
                        </span>
                    </Box>

                    {( activeStep > 1 ) && (
                        <Box className={classes.travelDate} mt={1}>
                            <span>
                                {formattedTraveldate}
                            </span>
                            {/* <CreateOutlinedIcon className={classes.navigationBlockHeaderDateIcon} fontSize="small" /> */}
                        </Box>
                    )}
                </div>
            </div>
        </Typography>
    );
}

const mapStateToProps = state =>({
    checkInDate: state.bookingEngine.checkInDate,
    checkOutDate: state.bookingEngine.checkOutDate
})

export default withTranslation()(connect(mapStateToProps)(BookingNavigationInfo));