export const accommodationList = [
    {
        "isOccupied": false,
        "customerID": [
            "CUST_5ef1ec3e7d5f390e7e3e119f_1"
        ],
        "rateList": [
            {
                "_id": "5ef1ec3e7d5f390e7e3e11a1",
                "rate": 500,
                "date": "2020-06-30"
            },
            {
                "_id": "5ef1ec3e7d5f390e7e3e11a2",
                "rate": 500,
                "date": "2020-07-01"
            }
        ],
        "_id": "5ef1ec3e7d5f390e7e3e119f",
        "checkIn": "2020-06-30",
        "checkOut": "2020-07-02",
        "roomTypeID": "5df7752d52394d0cd5cf743f",
        "roomID": "5eea3aba6c831ea30a4c0b7b",
        "bedID": "5eea3aba6c831ea30a4c0b7c"
    },
    {
        "isOccupied": false,
        "customerID": [],
        "rateList": [
            {
                "_id": "5ef1ec3e7d5f390e7e3e11a1",
                "rate": 500,
                "date": "2020-06-30"
            },
            {
                "_id": "5ef1ec3e7d5f390e7e3e11a2",
                "rate": 500,
                "date": "2020-07-01"
            }
        ],
        "_id": "5ef1ec3e7d5f390e7e3e119fe",
        "checkIn": "2020-06-30",
        "checkOut": "2020-07-02",
        "roomTypeID": "5df7752d52394d0cd5cf7441",
        "roomID": "5eea3aba6c831ea30a4c0b7b",
        "bedID": "5eea3aba6c831ea30a4c0b7c"
    }
];

export const guests = {
    'CUST_5ef1ec3e7d5f390e7e3e119f_1': {
        "address": {
            "lineOne": "",
            "city": "",
            "state": "",
            "country": "FRA",
            "zipCode": ""
        },
        "_id": "CUST_5ef1ec3e7d5f390e7e3e119f_1",
        "firstName": "",
        "lastName": "",
        "email": "sel@mail.com",
        "phone": "9876543",
        "documents": []
    }
}