import React from "react";
import { connect } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  fetchNoOfDays,
  checkDateInBetween,
  fetchDateFormat,
  DATE_FORMATS,
  checkDateIfGreater,
  getAmtFormat
} from "../../utils/utility";
import clsx from "clsx";
import { colorPrimaryBg } from "../../utils/css";

const styles = (theme) => ({
  roomBubble: {
    background: colorPrimaryBg,
    borderRadius: 2,
    boxShadow: '0 2px 60px 0 '+colorPrimaryBg,
    color: '#333333',
    fontSize: '1.3rem',
    padding: theme.spacing(2),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minHeight: 33,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    },
    '&:before': {
      boxShadow: '0px 0px 5px 0px rgba(180, 180, 180, 0.75)',
      zIndex: -1
    },
  },
  shadow: {
    background: '#ffffff',
    boxShadow: '0 2px 30px 0 #E0E0E0',
    color: '#333333'
  },
  roomSection: {
    borderBottom: "0px solid #dddddd",
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(1),
    paddingTop: 15,
    width: "100%",
  },
  night: {
    backgroundImage: 'linear-gradient(90deg, #f8f9fb 49.02%, #ffffff 49.02%, #ffffff 50%, #f8f9fb 50%, #f8f9fb 99.02%, #ffffff 99.02%, #ffffff 100%)',
    backgroundSize: '40.2%',
    '&.stripes3': {
      backgroundSize: '67%',
    },
    '&.stripes5': {
      backgroundSize: '40.2%',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '67%',
    },
  },
  description: {
    fontSize: "1.3rem"
  }
});

const Accommodation = (props) => {
  const classes = props.classes;
  const { day, dataKeys, dataInfo, numberOfDays, showPerNight, beds, rooms, roomTypes } = props;
  let siblingArr = [];
  let blockCounter = 0;


  const handleFetchAssignedRoomView = () => {

    return dataKeys.map((key, keyIndex) => {
      let data = dataInfo[key] || {};
      let divCounter = 0;
      let encounter = [];
      let multipleAccommodation = [];

      if (data.siblingAccommodationID) siblingArr.push(data.siblingAccommodationID);
      if (siblingArr.includes(data._id)) return null;
      let finalData = [];

      // recursive function to check sibling accommodation
      const handleFindDataBySibling = (sib) => {
        let count;
        //case 1: If accommodation checkin & checkout in range
        if (checkDateInBetween(day.startDate, day.endDate, sib.checkIn) && checkDateInBetween(day.startDate, day.endDate, sib.checkOut))
          count = fetchNoOfDays(sib.checkIn, sib.checkOut);
        //case 2: If accommodation checkin in range but not checkout
        else if (checkDateInBetween(day.startDate, day.endDate, sib.checkIn) && !checkDateInBetween(day.startDate, day.endDate, sib.checkOut))
          count = fetchNoOfDays(sib.checkIn, day.endDate);
        //case 3: If accommodation checkout in range but not checkin
        else if (!checkDateInBetween(day.startDate, day.endDate, sib.checkIn) && checkDateInBetween(day.startDate, day.endDate, sib.checkOut))
          count = fetchNoOfDays(day.startDate, sib.checkOut);
        //case 4: If accommodation checkin & checkout envelops range
        else if (checkDateInBetween(sib.checkIn, sib.checkOut, day.startDate) && checkDateInBetween(sib.checkIn, sib.checkOut, day.endDate))
          count = 4;
        //case 5:if showing accommodation for a night and checkout not between calendar start and end date
        if (count >= 0 && showPerNight && !checkDateInBetween(day.startDate, day.endDate, sib.checkOut))
          count++;
        //case 6: If accommodation checkin & checkout outside range
        if (!count && count !== 0) count = 0;

        // console.log(
        //   "day.startDate,",fetchDateFormat(day.startDate, DATE_FORMATS.BASIC_DATE),
        //   '\n', "day.endDate",fetchDateFormat(day.endDate, DATE_FORMATS.BASIC_DATE),
        //   '\n', "sib.checkIn", fetchDateFormat(sib.checkIn, DATE_FORMATS.BASIC_DATE),
        //   '\n', "sib.checkOut", fetchDateFormat(sib.checkOut, DATE_FORMATS.BASIC_DATE),
        //   '\n', "check checkin between start and end", checkDateInBetween(day.startDate, day.endDate, sib.checkIn), 
        //   '\n', "check checkOut between start and end", checkDateInBetween(day.startDate, day.endDate, sib.checkOut),
        //   '\n', "count", count)


        finalData.push({
          _id: sib._id,
          count: count,
          bedName: beds?.[sib?.bedID]?.name,
          checkIn: sib.checkIn,
          checkOut: sib.checkOut,
          roomName: rooms?.[sib?.roomID]?.name,
          roomTypeName: roomTypes?.[sib?.roomTypeID]?.name,
          amount: sib.amount,
          dormOrPrivate: roomTypes?.[sib?.roomTypeID]?.dormOrPrivate,
          hasSibling: sib.siblingAccommodationID ? true : false
        })
        if (sib.siblingAccommodationID && dataInfo[sib.siblingAccommodationID]) {
          handleFindDataBySibling(dataInfo[sib.siblingAccommodationID])
        }
        return finalData;
      }
      blockCounter++;
      multipleAccommodation = handleFindDataBySibling(data);

      return (
        <Grid container key={`guest-${keyIndex}`}>
          <Grid item xs={12} sm={12}>
            <div className={clsx({ [classes.roomSection]: true, [classes.night]: showPerNight, [`stripes${numberOfDays}`]: true })}>
              {showPerNight ? null :
                <div style={{ width: `${(50 / numberOfDays)}%` }}></div>
              }
              {/* ------------------------------------------------ */}
              {/* LOOP THROUGH CALENDAR DATES */}
              {/* ------------------------------------------------ */}
              {day.days.map((dayValue, dayIndex) => {

                // ------------------------------------------------
                // LOOP THROUGH ACCOMMODATION */}
                // ------------------------------------------------
                let viewHtml = multipleAccommodation.map((accommodation, accommodationIndex) => {
                  let view = null;


                  // Check if accommodation has NOT been encountered before 
                  // Check if date within accomm date range or equal to checkin date
                  // ------------------------------------------------------------------
                  if (!encounter.includes(accommodation._id) &&
                    ((fetchDateFormat(dayValue, DATE_FORMATS.BASIC_DATE) === fetchDateFormat(accommodation.checkIn, DATE_FORMATS.BASIC_DATE)) ||
                      checkDateInBetween(accommodation.checkIn, accommodation.checkOut, dayValue))) {

                    let editOptions = {
                      editSwap: true,
                      editArrival: accommodationIndex === 0,
                      editShorten: !accommodation.hasSibling,
                      editExtend: !accommodation.hasSibling,
                    }

                    let borderWidth = accommodation.hasSibling ? '4px' : '0px';

                    view = (
                      <div key={dayIndex + "_" + accommodationIndex} style={{ 
                        width: `calc(${(100 / numberOfDays) * accommodation.count}% - ${borderWidth}`,
                        borderRight: `${borderWidth} solid red`
                        }}>
                        <div
                          className={clsx({
                            [classes.roomBubble]: true,
                            [classes.shadow]: blockCounter && true
                          })}
                          onClick={() => {
                            if (props.editEnabled){
                              // props.handleEditRoom(accommodation._id, editOptions)
                            }
                            else if (props.suggestEnabled)
                              props.handleManageReservation(data.suggest)
                          }}
                        >
                          {/* {accommodation.dormOrPrivate === 'dorm' ?
                            (accommodation.bedName.toLowerCase().includes(accommodation.roomName.toLowerCase()) ?
                              <Typography className={classes.description}>{accommodation.bedName}</Typography> :
                              <>
                                <Typography className={classes.description}>{accommodation.bedName}</Typography>
                                <Typography className={classes.description}>{accommodation.roomName} </Typography>
                              </>)
                            :
                            <Typography className={classes.description}>{accommodation.roomName}</Typography>
                          } */}

                          {accommodation.dormOrPrivate === 'dorm' ?
                              <>
                                <Typography className={classes.description}>{accommodation.bedName}</Typography>
                                <Typography className={classes.description}>{accommodation.roomName} </Typography>
                              </>
                            :
                            <Typography className={classes.description}>{accommodation.roomName}</Typography>
                          }

                          {props.isForDisplay && <Typography className={classes.description}>{accommodation.roomTypeName}</Typography>}
                          {props.showRate && <Typography className={classes.description}>{getAmtFormat(accommodation.amount, props.currency)}</Typography>}
                        </div>
                      </div>
                    );

                    // encountered!!
                    encounter.push(accommodation._id);
                    divCounter = divCounter + accommodation.count;
                  }

                  // check if date equal to accom check out date
                  // date > checkout ?????
                  // ------------------------------------------------------------------
                  else if (fetchDateFormat(dayValue, DATE_FORMATS.BASIC_DATE) === fetchDateFormat(accommodation.checkOut, DATE_FORMATS.BASIC_DATE) ||
                    checkDateIfGreater(dayValue, accommodation.checkOut)) {
                    if (!encounter.includes(accommodation._id)) encounter.push(accommodation._id);
                    view = null;
                  }

                  // increment counter
                  else if (divCounter <= dayIndex) {
                    divCounter++;
                    view = <div key={dayIndex + "_" + accommodationIndex} style={{ width: `${100 / numberOfDays}%` }}></div>;
                  }
                  return view;
                })
                return viewHtml;
              })}
            </div>
          </Grid>
        </Grid>
      )
    })
  }

  const roomsAssigned = handleFetchAssignedRoomView();

  return (
    roomsAssigned
  );

}

const mapStateToProps = (state) => {
  return {
    beds: state.rooms.beds || {},
    rooms: state.rooms.rooms || {},
    roomTypes: state.rooms.roomTypes || {}
  };
};
export default withStyles(styles)(connect(mapStateToProps)(Accommodation));
