import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';

import Button from '../common/Button';
import ConfirmForm from '../../common/ConfirmForm'; 

const useStyles = makeStyles(theme =>({
    root: {
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: "15px 30px",
        borderTop: '1px solid #e0e0e0',
        height: "72px",
        boxSizing: "border-box",
        [theme.breakpoints.down('sm')]: {
            padding: "8px 10px",
        }
    },
    deleteBtn: {
        padding: "10px 15px",
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        fontSize: "1.2rem",
        color: "#cd706e",
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#0000000a'
        }
    },
    confirmContainer: {
        marginRight: '30px'
    }
}));

const Footer = props =>{
    const classes = useStyles();
    const { label = "", isBtnDisabled, onBtnClick, showDeleteOption = true, onDeleteBtnClick } = props;
    const [confirmOpen, setConfirmOpen] = useState(false);
    return (
        <div className={classes.root}>

            {!confirmOpen && (
                <ButtonBase 
                    className={classes.deleteBtn}
                    onClick={() =>setConfirmOpen(true)}
                >
                    Delete guest
                </ButtonBase>
            )}

            {confirmOpen && (
                <div className={classes.confirmContainer}>
                    <ConfirmForm
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onClose={() =>setConfirmOpen(false)}
                        onConfirm={onDeleteBtnClick}
                    >
                        Are you sure you would like to delete this guest?
                    </ConfirmForm>
                </div>
            )}

            <Button
                className={classes.saveBtn}
                disabled={isBtnDisabled}
                onClick={onBtnClick}
            >
                {label}
            </Button>
        </div>
    )
}

export default Footer;