import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';

import IMAGES from '../../constants/images';
import HorizontalScollView from './common/HorizontalScollView';

import AddonsModal from './addon-options/AddonsModal';
import POSModal from './addon-options/POSModal';

const useStyles = makeStyles(theme =>({
    root: {

    },
    plusIcon: {
        width: '20px',
        height: '20px'
    },
    btnList: {
        display: 'flex',
        '& $iconButton:nth-child(2n)': {
            margin: '0px 10px'
        },
        '& $iconButton:last-child': {
            marginLeft: '10px'
        }
    },
    iconButton: {
        border: "1px solid #e0e0e0",
        width: "200px",
        padding: "10px 20px",
        justifyContent: "space-between",
        borderRadius: "30px",
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',

        '& p':{
            margin: 0,
            fontSize: '1.2rem',
            fontWeight: 600,
            pointerEvents: 'none'
        },
    }
}));

const OPTIONS = {
    addOns: 'addOns',
    experirences: 'experirences',
    pos: 'pos',
};

const OPTIONS_LIST = [
    { id: OPTIONS.addOns, label: 'Add ons', imgSrc: IMAGES.ICONS.addons },
    // { id: OPTIONS.experirences, label: 'Experiences', imgSrc: IMAGES.ICONS.experiences},
    { id: OPTIONS.pos, label: 'POS', imgSrc: IMAGES.ICONS.pos }
];

const AddOnOptions = props =>{
    const classes = useStyles();
    const [selectedOption, setSelectedOption] = useState(null);

    const onClickHandler = event =>{
        const optionID = event.target.id;
        setSelectedOption(optionID);
    }

    const resetHandler = () =>{
        setSelectedOption(null);
    }

    return (
        <div className={classes.root}>
            <HorizontalScollView>
                <div className={classes.btnList}>
                    {OPTIONS_LIST.map(option =>(
                        <div 
                            key={`addons_${option.id}`}
                            id={option.id}
                            className={classes.iconButton}
                            onClick={onClickHandler}
                        >
                            <p>{option.label}</p>
                            <img 
                                id={option.id}
                                src={IMAGES.ICONS.plus} 
                                className={classes.plusIcon}
                            />
                        </div>
                    ))}
                </div>
            </HorizontalScollView>

            {({

                [OPTIONS.addOns]: <AddonsModal closeModalHandler={resetHandler} />,

                [OPTIONS.experirences]: <div>Experirences</div>,

                [OPTIONS.pos]: <POSModal closeModalHandler={resetHandler} />

            })[selectedOption]}
        </div>
    )
}

export default AddOnOptions;