const defaultState = {
  reservation: null,
  guests: null,
  accommodationList: null,
  reservationIDs: []
}
//----------------------------------------------------------------
// LOAD TODAYS CHECK-INS
//----------------------------------------------------------------
export default (state = { ...defaultState }, action) => {
  switch (action.type) {
    // ----------------------------------------------------------------
    case "LOAD_LANDING_PAGE_RESERVATIONS_REQUEST":
      return { ...state, loadLandingPage: true };
    case "LOAD_LANDING_PAGE_RESERVATIONS_SUCCESS":
      return { ...state, ...action.payload, loadLandingPage: false };
    case "LOAD_LANDING_PAGE_RESERVATIONS_FAILURE":
      return { ...state, ...defaultState, loadLandingPage: false };
    // ----------------------------------------------------------------
    default:
      return state;
  }
};



