import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import { RESERVATION_STATUS } from '../../../utils/constants';
import IMAGES from '../../../constants/images';
import { updateReservationStatus } from '../../../redux/actions/reservations';

const useStyles = makeStyles(theme =>({
    root: {
        cursor: "pointer",
        display: "flex",
        padding: "20px",
        borderRadius: "10px",
        backgroundColor: "#fff",
        alignItems: "center",
    },
    checkinText: {
        fontSize: '1.3rem',
        fontWeight: 700,
        color: '#cd706e',
    },
    checkedInText: {
        fontSize: '1.3rem',
        fontWeight: 700,
        color: '#519884',
    },
    icon: {
        width: "20px",
        height: "20px",
        marginRight: "15px",
    },
    checkedInBtn: {
        display: "flex",
        borderRadius: "10px",
        justifyContent: "space-between",
        backgroundColor: "#fff",
    },
    undoBtn: {
        display: "flex",
        alignItems: "center",
        padding: "0px 20px",
        borderLeft: "1px solid #9dc0b9",
        fontSize: "1.1rem",
        fontWeight: 600,
        color: "#519884",
        cursor: "pointer",
    },
    inActiveBtn: {
        border: '1px solid #ffffff85',
        color: '#fff',
        fontSize: '1.1rem',
        fontWeight: 700,
        padding: "20px",
        borderRadius: "10px",
        textAlign: 'center'
    }
}));

const CheckoutStatus = props =>{
    const classes = useStyles();
    const { reservation, dispatch } = props;

    const updateStatusHandler = (status) =>{
        const data = { status };
        dispatch(updateReservationStatus(reservation._id, data));
    }

    if(reservation.status == RESERVATION_STATUS.CHECKED_OUT){
        return (
            <div className={classes.checkedInBtn}>
                <div className={classes.root}>
                    <img className={classes.icon} src={IMAGES.ICONS.check} />
                    <div className={classes.checkedInText}>Checked-out</div>
                </div>
                <div 
                    className={classes.undoBtn}
                    onClick={() => updateStatusHandler(RESERVATION_STATUS.IN_HOUSE)}
                >
                    Undo
                </div>
            </div>
        )
    }

    if(reservation.status == RESERVATION_STATUS.IN_HOUSE){
        return (
            <div 
                className={classes.root} 
                onClick={() => updateStatusHandler(RESERVATION_STATUS.CHECKED_OUT)}
            >
                <img className={classes.icon} src={IMAGES.ICONS.checkout} />
                <div className={classes.checkinText}>Check-out guest</div>
            </div>
        )
    }

    return (
        <div className={classes.inActiveBtn}>
            <div>Check-out guest</div>
        </div>
    )
}

const mapStateToProps = state =>{
    const { reservationDetails } = state;
    return {
        reservation: reservationDetails?.reservation || {},
    }
}

export default connect(mapStateToProps)(CheckoutStatus);