import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core';
import moment from "moment-timezone";
import clsx from 'clsx';

import {
    Box,
    Avatar,
    FormControl,
    InputLabel,
    TextField,
    FormHelperText,
    Button
} from '@material-ui/core';
import { SingleDatePicker } from 'react-dates';
import { fetchDateFormat, DATE_FORMATS } from '../../utils/utility';
import { fetchReservationDetails } from '../../redux/actions/onlineCheckin';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const styles = theme =>({
    root: {
        margin: '0 auto',
        width: '75%',
        maxWidth: 500,
        paddingTop: '60px',
        [theme.breakpoints.down('sm')]: {
           width: '100%'
        },
    },
    backButton: {
        paddingTop: '4%',
        float: 'left', 
        display:'inline-block',
        width: '100%',
        marginBottom: 40,
    },
    mainContent: {
        float: 'left', 
        display:'inline-block',
        width: '90%'
    },
    propertyInfoHeader: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '40px',
        fontWeight: 'bold'
    },
    propertyImage: {
        width: '50px',
        height: '50px',
        marginRight: '15px'
    },
    title:{
        color: '#333333',
        fontSize: '2.4rem',
        marginBottom: 40,
    },
    inputLabel:{
       color: '#666666',
       fontSize: '1.3rem',
       marginBottom: 10,
       lineHeight: 1.6,
    },
    formControl:{
        width: '100%',
        border: '1px solid #000000',
        borderRadius: '5px',
        height: '50px',
        '& .DateInput_input':{
            borderRadius: '5px',
            padding: '10.5px 14px',
            fontSize: '1.4rem',
            fontWeight: 500,
        },
        '& .DateInput_input__focused':{
            borderBottom: 'none'
        },
        '& .SingleDatePicker':{
            background: '#FFFFFF',
            borderRadius: '5px',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        '& .SingleDatePickerInput':{
            background: '#FFFFFF',
            border: 'none',
            boxShadow: 'none',
            borderRadius: '5px',
            height: '100%',
        },
        '& .DateInput':{
            borderRadius: '5px',
            height: '100%',
            width: '100%',
        },
        '& .SingleDatePicker_picker':{
            top: '50px !important'
        },
        '& .DateInput_fang': {
            top: '40px !important'
        },
        '& .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover': {
            background: '#000000',
            border: '1px double #000000'
        }
    },
    textInput:{
        background: '#FFFFFF',
        border: '1px solid #000000',
        borderRadius: '5px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        '& .MuiOutlinedInput-root': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.4rem',
            height: '100%',
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            '&.Mui-error fieldset': {
                border: '1px solid #f44336'
            },
        },
    },
    text: {
        color: '#666666',
        fontSize: '1.3rem',
        paddingTop: 10,
        paddingBottom: 10,
    },
    submitBtn:{
        width: '100%',
        borderRadius: '5px',
        background: '#000',
        color: '#fff',
        fontSize: '1.6rem',
        height: 80,
        marginTop: 10,
        padding: '10px',
        fontWeight: 'bold',
        '&:hover':{
            background: '#000000',
            color: '#FFFFFF',
        }
    },
    errorMessage: {
        color: "#f44336",
        fontSize: '1.1rem',
        marginTop: 5,
    },
    icon: {
        color: '#000000',
        cursor: 'pointer',
        fontSize: '18px',
        width: 40,
    }
})

const formHelper = {
    fullnameError: false,
    checkinDateError: false
}
class CheckinFrom extends Component{
    state = {
        fullname: '',
        checkInDate: null,
        focusedInput: false,
        ...formHelper
    };

    componentDidMount() {
        if (this.props.isKioskFlow) {
            this.setState({ 
                checkInDate: moment()
            })
        }
    }

    onInputChangeHandler = event =>{
        const fullname = event.target.value;
        this.setState({ 
            fullname: fullname,
            fullnameError: !fullname.trim() ? true : false 
        });
    }

    dateChange = (checkInDate) => {
        this.setState({ 
            checkInDate: checkInDate, 
            checkinDateError: !checkInDate ? true : false
        });
    };

    focusChange = ({ focused }) => {
        this.setState({ focusedInput: focused });
    };

    validateCheckinDetails = () =>{
        let { 
            fullname,
            checkInDate
         } = this.state;

        let formInputStatus = { ...formHelper };
        let validationSuccess = true;

        if(fullname.trim()) {
            var emailCheck = /\S+@\S+\.\S+/
            var resCodeCheck = /\d/
            if(emailCheck.test(fullname)) {
                formInputStatus.fullnameError = false
                validationSuccess = true
            }
            else if(resCodeCheck.test(fullname)) {
                const fullnameSplit = fullname.split('-')
                if(fullnameSplit.length === 2 && fullnameSplit.every(item => Number.isInteger(item)))
                    fullname = fullnameSplit[0]
                formInputStatus.fullnameError = false
                validationSuccess = true
            }
            else if(fullname.split(' ').length > 1){
                formInputStatus.fullnameError = false
                validationSuccess = true
            }
            else {
                formInputStatus.fullnameError = true
                validationSuccess = false
            }
        }
        else {
            formInputStatus.fullnameError = true
            validationSuccess = false
        }

        if(!checkInDate){
            formInputStatus.checkinDateError = true
            validationSuccess = false
        }
        this.setState({ ...formInputStatus })
        return { validationSuccess, fullname }
    }

    submitHandler = () =>{
        const { validationSuccess, fullname } = this.validateCheckinDetails()
        if(validationSuccess) {
            const { dispatch, nextStep } = this.props;
            const { checkInDate } = this.state;
            const params = { 
                name: fullname,
                checkIn: fetchDateFormat(checkInDate, DATE_FORMATS.DEFAULT_DATE),
            };
            dispatch(fetchReservationDetails(params, nextStep))
        }
    }

    render(){

        const { classes, propertyTimezone = null, isKioskFlow, settings } = this.props;
        const {
            fullname,
            checkInDate,
            focusedInput,
            fullnameError,
            checkinDateError,
        } = this.state;

        const {
            propertyDetails = {
                images: [],
                timezone: null
            },
            t
        } = this.props;

        return(
            <Box className={classes.root}>
                <div className={classes.mainContent}>
                    <Box>
                        <Box mb={3}>
                            <div className={classes.title}>Let's dig up your reservation ...</div>
                            <InputLabel htmlFor="last-name" className={classes.inputLabel}>Please provide your reservation number, full name or email</InputLabel>
                            <FormControl style={{width: '100%'}}>
                                <TextField
                                    variant="outlined" 
                                    margin="dense"
                                    fullWidth = {true}
                                    placeholder="Enter Booking Details"
                                    name = "fullname"
                                    value = {fullname}
                                    className={classes.textInput}
                                    autoFocus
                                    onChange = { this.onInputChangeHandler }
                                />
                                {fullnameError && (
                                    <FormHelperText className={classes.errorMessage}>{t('onlineCheckin.enterNameError')}</FormHelperText>
                                )}
                            </FormControl>
                        </Box>

                        {/* Check-in date picker */}
                        {!isKioskFlow && <Box mb={3}>
                            <InputLabel htmlFor="check-in" className={classes.inputLabel}>{t('onlineCheckin.checkinDate')}</InputLabel>
                            <FormControl className={classes.formControl}>
                                <SingleDatePicker
                                    date={checkInDate} 
                                    onDateChange={this.dateChange}
                                    focused={focusedInput}
                                    onFocusChange={this.focusChange}
                                    id="checkin_date"
                                    placeholder="Select Arrival Date"
                                    numberOfMonths={1}
                                    isOutsideRange={day => day.isBefore(moment.tz(new Date(), propertyDetails.timezone).format('YYYY-MM-DD'))}
                                    showClearDate={true}
                                    readOnly
                                    displayFormat={'DD MMM YYYY'}
                                    block
                                    hideKeyboardShortcutsPanel
                                />
                            </FormControl>
                            {checkinDateError && (
                                <FormHelperText className={classes.errorMessage}>{t('onlineCheckin.enterDateError')}</FormHelperText>
                            )}
                        </Box>}
                        <Box>
                            <Button 
                                className={classes.submitBtn}
                                onClick={this.submitHandler}
                            >
                                {t('onlineCheckin.header')}
                            </Button>
                        </Box>
                    </Box>
                </div>
            </Box>
        )
    }
}

const mapStateToProps = state =>{
    const { onlineCheckin, kiosk } = state;
    return{
        propertyDetails:  onlineCheckin.propertyDetails,
        settings: onlineCheckin.settings,
        isKioskFlow: Boolean(kiosk.mode)
    }
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(CheckinFrom)));