import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';

const CustomToolTip = withStyles(theme => ({
    tooltip: {
        color: "#fff",
        fontSize: "1.1rem",
        maxWidth: "220px",
        backgroundColor: "#3e3e3e",
        // padding: "15px",
        borderRadius: "3px",
    },
}))(Tooltip);

const HtmlTooltip = props =>{
    const { title, cursorType, customClass } = props;
    return(
        <CustomToolTip 
            title={title} 
            arrow
            style={{ cursor: cursorType || 'pointer' }}
            className={customClass}
        >
            {props.children}
        </CustomToolTip>
    )
}

export default HtmlTooltip;