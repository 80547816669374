import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import RadioGroup from '@material-ui/core/RadioGroup';
import Footer from '../common/Footer';
import { RadioButton } from '../common/Button';
import { fetchGuestAccomodation, getAccomodationInfo } from '../helper';
import { toTitleCase } from '../../../utils/wordUtils';

const useStyles = makeStyles(theme =>({
    root: {
    },
    label: {
        margin: "0px 10px",
        '& h5': {
            margin: "unset",
            fontSize: "1.2rem",
        },
        '& p': {
            margin: "unset",
            fontSize: "1.1rem",
            fontWeight: 600,
            color: "#737373",
        },
    },
}));

const RoomBedOptions = props =>{
    const classes = useStyles();
    const { reservation, rooms, nextStep, setSelectedAccomdation } = props;
    const [selectedOption, setSelectedOption] = useState('');
    const [guestAccomodation, setGuestAccomodation] = useState({});
    const guestCounterRef = useRef(1);
    let { current: guestCounter } = guestCounterRef;

    useEffect(() =>{
        if(reservation.accommodationList){
            const accomodationData = fetchGuestAccomodation(reservation.accommodationList);
            setGuestAccomodation(accomodationData);
        }
    }, [reservation]);

    const onChangeHandler = event =>{
        const guestNumber = event.target.value;
        setSelectedAccomdation(guestNumber);
        setSelectedOption(guestNumber);
    }

    const fetchGuestLabel = (accommodation) =>{
        const { guests } = reservation;
        const data = { rooms: '', guests: '' };

        accommodation.forEach((acc, accIndx) =>{

            const roomData = {
                roomTypeID: acc.roomTypeID,
                roomID: acc.roomID,
                bedID: acc.bedID,
            }

            //GET ROOM NAMES FOR THE ACCOMODATION....................
            const { name } = getAccomodationInfo(roomData, rooms)
            data.rooms += accIndx == 0 ? name : ` / ${name}`;
            
            //GET GUEST NAMES FOR THE ACCOMODATION....................
            if(accIndx == 0 && acc.guestNumber == 1 && acc.customerID.length == 0){
                const primaryGuest = { ...guests[0] };
                data.guests = toTitleCase(`${primaryGuest.firstName} ${primaryGuest.lastName}`);
                guestCounter++;
                return;
            }

            if(accIndx == 0 && acc.guestNumber > 1 && acc.customerID.length == 0){
                data.guests = `Guest ${guestCounter}`;
                guestCounter++;
            }

            acc.customerID.map((customerID, custIndx) =>{
                const customerData = guests.find(guest => guest._id == customerID);
                const customerName = toTitleCase(`${customerData.firstName} ${customerData.lastName}`);
                data.guests += (custIndx == 0) ? customerName : `, ${customerName}`;
                guestCounter++;
            })
            
        })

        return (
            <div className={classes.label}>
                <h5>{data.rooms}</h5>
                <p>{data.guests}</p>
            </div>
        )
    }

    return (
        <>
            <div className={classes.root}>
                <RadioGroup
                    name="accomdodations"
                    onChange={onChangeHandler}
                >
                    {Object.keys(guestAccomodation).map((guestNumber, index) =>(
                        <RadioButton
                            key={`accomodation_${guestNumber}`}
                            label={fetchGuestLabel(guestAccomodation[guestNumber])}
                            value={guestNumber}
                        />
                    ))}
                </RadioGroup>
            </div>

            <Footer 
                label="Next"
                isBtnDisabled={!Boolean(selectedOption)} 
                onBtnClick={nextStep}
            />
        </>
    )
}

const mapStateToProps = state =>{
    const { reservationDetails, rooms } = state;
    return {
        reservation: reservationDetails?.reservation || {},
        rooms: rooms || {}
    }
}

export default connect(mapStateToProps)(RoomBedOptions);