import { updateBedsStateList, updateBedsReservation } from "../../../components/housekeeping/helper";

export default (state = {}, action) => {
    switch (action.type) {

        case "GET_HOUSEKEEPING_DATA_SUCCESS":
            return { 
                ...state,
                rooms: { ...action.payload.rooms },
                beds: { ...action.payload.beds }
            };

        case "UPDATE_BEDS_STATUS_SUCCESS": {
            const updatedBeds = updateBedsStateList(state.beds, action.payload);
            return {
                ...state,
                beds: { ...updatedBeds }
            }
        }

        default:
            return state;
    }
};