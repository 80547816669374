const isEmpty = require("is-empty");

const initialState = {
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return { ...state, loginSuccess: null, loginRequested: Date.now() };
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: !isEmpty(action.payload), loginSuccess: action.payload };
    case "LOGIN_FAILURE":
      return { ...state, loginSuccess: null };

    //----------------------------------------------------------------------------------------
    case "UNLOCK_REQUEST":
      return { ...state, unlockSuccess: null, unlockRequested: Date.now() };
    case "UNLOCK_SUCCESS":
      return { ...state, isUnlocked: !isEmpty(action.payload), unlockSuccess: action.payload };
    case "UNLOCK_FAILURE":
      return { ...state, unlockSuccess: null };

    //----------------------------------------------------------------------------------------
    case "LOCK_SUCCESS":
      return { ...state, unlockSuccess: null, isUnlocked: false };
    case "LOCK_FAILURE":
      return { ...state, lockFailure: action.payload };

    //----------------------------------------------------------------------------------------
    case "LOGOUT":
      return {
        ...state,
        loginSuccess: null,
        isAuthenticated: false,
        unlockSuccess: null,
        isUnlocked: false
      };

    //----------------------------------------------------------------------------------------
    case "LOAD_USERS_REQUEST":
      return { ...state, users: null, accessToProperties: null };
    case "LOAD_USERS_SUCCESS":
      return { ...state, users: action.payload.user, accessToProperties: action.payload.accessToProperties };
    case "LOAD_USERS_FAILURE":
      return { ...state, users: null, accessToProperties: null };

    //----------------------------------------------------------------------------------------
    case "START_SHIFT_REQUEST":
      return { ...state, startShiftSuccess: null };
    case "START_SHIFT_SUCCESS":
      return { ...state, startShiftSuccess: action.payload };
    case "START_SHIFT_FAILURE":
      return { ...state, startShiftSuccess: null };

    //----------------------------------------------------------------------------------------
    case "END_SHIFT_REQUEST":
      return { ...state, endShiftSuccess: null };
    case "END_SHIFT_SUCCESS":
      return { ...state, endShiftSuccess: action.payload };
    case "END_SHIFT_FAILURE":
      return { ...state, endShiftSuccess: null };

    //----------------------------------------------------------------------------------------

    default:
      return state;
  }
};
