import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { PAGES } from "../../utils/constants";

const PrivateRoute = ({ component: Component, layout: Layout, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated === true ? (
        auth.isUnlocked === true ? <Layout><Component {...props} /></Layout> : <Redirect to="/lock" />
      ) : <Redirect to={`/${PAGES.LOGIN}`} />
    }
  />
);

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);