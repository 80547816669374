import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import NumberInput from '../../common/NumberInput';

const useStyles = makeStyles(theme =>({
    root: {
        marginTop: "20%",
    },
    label: {
        margin: '15px 0px'
    },
    textField: {
        border: "1px solid #e0e0e0",
        borderRadius: "30px",
        height: "45px",
        padding: "0px 20px",
        fontWeight: 600,
        '& input': {
            textAlign: "center",
        }
    }
}));

const UpdatePriceForm = props =>{
    const classes = useStyles();
    const { newPrice, onChangeHandler, currency } = props;

    const handleAmountChange = (e) => {
        let value = e.target.value;
        value = value < 0 ? Math.abs(value) : value;
        onChangeHandler(value);
    }

    return(
        <div className={classes.root}>

            <h6 className={classes.label}>Enter new price</h6>

            <NumberInput
                variantType="base"
                className={classes.textField}
                value={newPrice || ""}
                onChange={handleAmountChange}
                variant="outlined"
                // margin="dense"
                id="amount"
                // label="Amount"
                type="number"
                min={0}
                fullWidth 
                startAdornment={currency}
                autoFocus
            />
        </div>
    )
}

export default UpdatePriceForm;