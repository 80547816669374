import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme =>({
    root: {
        marginLeft: 'unset',
        width: "fit-content",
        '& .MuiFormControlLabel-label':{
            fontSize: "1.2rem",
            fontWeight: 600,
        }
    },
    checkbox: {
        // width: 20, 
        // height: 20, 
        // fontSize: '1.6rem'
    },
    checkboxIcon: {
        color: '#fff', 
        outline: 'auto #e0e0e0',
        width: 20, 
        height: 20,
    },
    checkedIcon: {
        color: '#777777', 
        outline: 'auto #e0e0e0',
        width: 20, 
        height: 20,
    }
}));

const CustomCheckbox = props =>{
    const classes = useStyles();
    const { 
        className, 
        label = "", 
        onChangeHandler, 
        labelPlacement = "end",  
        value,
        ...componentProps 
    } = props;
 
    return(
        <FormControlLabel
            className={clsx(classes.root, className)}
            control={<Checkbox 
                className={classes.checkbox}
                icon={<CheckRoundedIcon className={classes.checkboxIcon}/>}
                checkedIcon={<CheckRoundedIcon className={classes.checkedIcon}/>}
            />}
            label={label}
            labelPlacement={labelPlacement}
            onChange={onChangeHandler}
            {...(value && { value })}
            { ...componentProps}
        />
    )
}

export default CustomCheckbox;