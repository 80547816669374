import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Select from 'react-select';

import CardDetails from './CardDetails';

const useStyles = makeStyles(theme =>({
    root: {
    
    },
    cardSelect: {
        marginBottom: '20px'
    },
    blankCard: {
        color: "#999999",
        display: "flex",
        fontSize: "1.5rem",
        textAlign: "center",
        flexDirection: "column",
        justifyContent: "center",
        border: "1px solid #e0e0e0",
        borderRadius: "10px",
        padding: "30px 0px",
        '& span': {
            marginBottom: 10,
        }
    },
    cardSelectHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
        '& .title': {
            fontSize: '1.2rem',
            fontWeight: 600,
            color: '#484848'
        }
    },
    addCard: {
        cursor: 'pointer',
        color: '#484848',
        fontSize: '1.2rem',
        marginBottom: 5,
    },
    link: {
        color: 'blue',
        cursor: 'pointer',
        display: 'inline !important'
    },
}));

const selectStyle = {
    control: (base, state) => ({
        ...base,
        boxShadow: 0,
        cursor: 'pointer',
        margin: "5px 0px",
        border: "1px solid #e0e0e0",
        borderRadius: "10px",
        '&:hover': { border: "1px solid #e0e0e0" },
    }),
    indicatorSeparator: (base, state) =>({
        display: 'none'
    })
};

const CardSelect = props =>{
    const classes = useStyles();
    const {
        addCardHandler,
        selectCardHandler,
        selectedCardToken,
        maskedCards = []
    } = props;

    const isCardAdded = maskedCards && maskedCards.length > 0;
    const cardNumberOptions = maskedCards.map(cardData => ({ value: cardData.card_token, label: cardData.card_number }));
    
    return (
        <div className={classes.root}>
            <div className={classes.cardSelect}>
                {/* ---------- HAS NO CARDS ADDED TO RESERVATION --------------- */}
                {!isCardAdded && (
                    <div className={classes.noCardDisplay}>
                        <div className={classes.blankCard}>
                            <span>No card tagged to this reservation.</span>
                            <span
                                className={classes.link}
                                onClick={addCardHandler}
                            >
                                + Add New Card
                            </span>
                        </div>
                    </div>
                )}

                {/* ---------- HAS CARDS ADDED TO RESERVATION --------------- */}
                {isCardAdded && (
                    <>
                        <div className={classes.cardSelectHeader}>
                            <div className="title">Card details</div>
                            <div
                                className={classes.addCard}
                                onClick={addCardHandler}
                            >
                                New Card +
                            </div>
                        </div>

                        <Select
                            value={cardNumberOptions.find(e => e.value === selectedCardToken)}
                            name="cardNumber"
                            onChange={selectCardHandler}
                            options={cardNumberOptions}
                            styles={selectStyle}
                            theme={theme => ({
                                ...theme,
                                border: 0,
                                colors: {
                                    ...theme.colors,
                                    primary: '#666666',
                                    primary25: '#dddddd'
                                },
                            })}
                        />
                    </>
                )}
            </div>

            <CardDetails 
                maskedCards={maskedCards}
                selectedCardToken={selectedCardToken}
            />
        </div>
    )
}

export default CardSelect;