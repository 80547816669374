import React, { Component } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import * as i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import Dialog from '../common/CustomDialog';
import GuestForm from './GuestForm';
import Footer from './Footer';
import { updateCustomer, uploadCustomerDoc, deleteCustomer } from '../../../redux/actions/customers';
import { resetError } from '../../../redux/actions/reservations';
import { toTitleCase } from '../../../utils/wordUtils';
import { checkDateIfGreater, fetchDatePerTimezone } from '../../../utils/utility';
import { filterDataFields, formatGuestData, validateDocExpiry, getTodaysDate } from '../helper';

const defaultGuestFormData = {
    _id: '',
    firstName:"",
    lastName:"",
    email:"",
    phone:"",
    nationality:"",
    dateOfBirth:"",
    gender:"",
    documents:[],
    companyName:"",
    taxID:"",
    address:{
        lineOne:"",
        city:"",
        state:"",
        country:"",
        zipCode:""
    }
}

const ERROR_FIELDS = {
    firstName: false,
    lastName: false,
    dateOfBirth: false,
    docExpiry: false
}

const handleGetCountries = () => {
    const countries = i18nIsoCountries.getNames("en");
    let countryOptions = [];
    for (var key in countries) {
      if (countries.hasOwnProperty(key)) {
        countryOptions.push({ value: i18nIsoCountries.alpha2ToAlpha3(key), label: countries[key] })
      }
    }
    return countryOptions;
}
class CustomerDetails extends Component {

    state = {
        guestDetails: { ...defaultGuestFormData },
        countryOptions: handleGetCountries(),
        formErrors: { ...ERROR_FIELDS },
        timeStamp: null
    }

    componentDidMount() {
        this.initGuestForm();
    }

    componentDidUpdate(prevProps) {
        const { reservation, guestID } = this.props; 
        if(!isEqual(reservation.guests, prevProps.reservation.guests)){
            this.GuestUpdateHandler(reservation, guestID);
        }
    }

    GuestUpdateHandler = (reservation, guestID) =>{
        const guestData = reservation.guests.find(guest => guest._id == guestID);
        // const prevGuestData = prevProps.reservation.guests.find(guest => guest._id == guestID);

        // if(guestData?.documents && !isEqual(guestData?.documents, prevGuestData?.documents)){
        //     const updatedGuestData = formatGuestData(guestData, i18nIsoCountries);
        //     this.setState(prevState =>({ 
        //         guestDetails: { 
        //             ...prevState.guestDetails, 
        //             documents: [ ...updatedGuestData.documents] 
        //         }
        //     }));
        // }
        const filteredGuestData = filterDataFields(guestData, defaultGuestFormData);
        const updatedGuestData = formatGuestData(filteredGuestData, i18nIsoCountries);
        this.setState(prevState =>({ 
            guestDetails: { ...prevState.guestDetails, ...updatedGuestData }
        }));
    }

    initGuestForm = () =>{
        const { guestID, reservation } = this.props;
        const guestData = reservation.guests.find(guest => guest._id == guestID);

        if(!guestData) return;

        // Filtering form fields from guest data........
        const filteredGuestData = filterDataFields(guestData, defaultGuestFormData);
        const updatedGuestData = formatGuestData(filteredGuestData, i18nIsoCountries);
        
        this.setState({ 
            guestDetails: { 
                ...defaultGuestFormData, 
                ...updatedGuestData 
            }
        });
    }

    fileSelectHandler = fileData =>{
        const { propertyID, guestID, dispatch } = this.props;
        const data = {
            propID: propertyID,
            customerID: guestID,
            ...fileData
        }
        dispatch(uploadCustomerDoc('doc', data));
    }

    guestFormInputHandler = (key, value) =>{
        const { guestDetails } = this.state;
        let guestInfo = cloneDeep(guestDetails);
        set(guestInfo, key, value);
        this.setState({ guestDetails: { ...guestInfo }});
    }

    validateGuestForm = () =>{
        const { guestDetails } = this.state;
        const errors = { ...ERROR_FIELDS }
        let success = true;

        if(!guestDetails?.firstName || !guestDetails.firstName.trim()){
            errors.firstName = true;
            success = false;
        }

        if(!guestDetails?.lastName || !guestDetails.lastName.trim()){
            errors.lastName = true;
            success = false;
        }

        if (guestDetails.dateOfBirth && checkDateIfGreater(guestDetails.dateOfBirth, fetchDatePerTimezone(null))) {
            errors.dateOfBirth = true;
            success = false;
        }

        const docExpiryDate = guestDetails?.documents?.[0]?.expiry || null;
    
        if(docExpiryDate && !validateDocExpiry(docExpiryDate, this.props.timezone)){
            errors.docExpiry = true;
            success = false;
        }

        this.setState({ formErrors: { ...errors }});
        return success;
    }

    submitHandler = () =>{
        const { isPrimaryGuest, dispatch } = this.props;
        if(!this.validateGuestForm()) return;
        const { _id, ...payload } = this.state.guestDetails;
        dispatch(updateCustomer(_id, payload, isPrimaryGuest))
            .then(success =>{
                if(success) this.setState({ timeStamp: new Date() });
                this.props.closeModalHandler()
            })
    }

    deleteGuestHandler = () =>{
        const { reservation, guestID, dispatch, closeModalHandler } = this.props;
        dispatch(deleteCustomer(reservation._id, guestID))
            .then(success =>{
                if(success) closeModalHandler();
            });
    }

    handleCloseError = () =>{
        this.props.dispatch(resetError('DELETE_CUSTOMER'));
    }

    render() {
        const { closeModalHandler, isLoading, errors, timezone } = this.props;
        const { guestDetails, countryOptions, formErrors, timeStamp } = this.state;
        const guestName = toTitleCase(`${guestDetails.firstName} ${guestDetails.lastName}`);

        return(
            <Dialog
                headerTitle={`Guest details ${guestName}`}
                closeModalHandler={closeModalHandler}
                isLoading={isLoading}
                errors={errors}
                handleCloseError={this.handleCloseError}
            >
                <GuestForm 
                    data={guestDetails} 
                    formErrors={formErrors}
                    countryOptions={countryOptions}
                    fileSelectHandler={this.fileSelectHandler}
                    onChangeHandler={this.guestFormInputHandler}
                    timeStamp={timeStamp}
                    todaysDate={getTodaysDate(timezone)}
                />

                <Footer 
                    label="Save"
                    onBtnClick={this.submitHandler}
                    onDeleteBtnClick={this.deleteGuestHandler}
                />

            </Dialog>
        )
    }
}

const mapStateToProps = state =>{
    const { reservationDetails, register, loading, error, property } = state;
    return {
        propertyID: register.propertyID,
        timezone: property.timezone,
        reservation: reservationDetails?.reservation || {},
        isLoading: loading.UPDATE_CUSTOMER || 
            loading.OPEN_CUSTOMER_DOCUMENT ||
            loading.UPLOAD_CUSTOMER_DOC,
        errors: error.DELETE_CUSTOMER
    }
}

export default connect(mapStateToProps)(CustomerDetails);