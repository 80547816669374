import axios from 'axios';
import { PAGES } from '../../utils/constants';

const axiosInstance = axios.create({
  baseURL: process.env.API_BASE_URL ? process.env.API_BASE_URL : 'http://localhost:3000',
});

axiosInstance.interceptors.response.use(undefined, err => {
  const error = err.response;
  if (error.status === 401) {
    localStorage.removeItem("sessionToken");
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("roomsLocalStorage");
    localStorage.removeItem("roomTypesLocalStorage");
    window.location = `/${PAGES.LOGIN}`;
  } else {
    return Promise.reject(err);
  }
});

export default axiosInstance;