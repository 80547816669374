import http from "../utils/http";
import { normalize } from 'normalizr';
import { productCategorySchema } from "../schema/pos";

export function loadPropertyConfig() {
    const ACTION_NAME = "LOAD_PROPERTY_CONFIG";
    const PRODUCTS_ACTION_NAME = "LOAD_PRODUCT_CATEGORIES";

    return function (dispatch) {
        dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
            .get("/api/content")
            .then(successResult => {
                const { 
                    channelStatus, 
                    productCategory = [], 
                    buildings = [], 
                    ...configData 
                } = successResult.data;

                let channelList = {};
                let buildingsList = {};

                channelStatus.forEach(channel => {
                    channelList[channel.name] = { ...channel }
                });

                buildings.forEach(building =>{
                    buildingsList[building.buidlingID] = { ...building }
                });

                dispatch({
                    type: `${ACTION_NAME}_SUCCESS`,
                    payload: {
                        channelList,
                        buildings: { ...buildingsList },
                        ...configData
                    }
                });

                //PRODUCT CATEGORIES...............................
                const normalizedProducts = normalize(productCategory, [productCategorySchema]);
                dispatch({
                    type: `${PRODUCTS_ACTION_NAME}_SUCCESS`,
                    payload: { ...normalizedProducts.entities }
                });

            })
            .catch(errorResult => {
                let error =
                    errorResult && errorResult.response && errorResult.response.data
                        ? errorResult.response.data
                        : errorResult;
                dispatch({ type: `${ACTION_NAME}_FAILURE`, payload: error });
            });
    };
}