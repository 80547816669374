import React, { Component } from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Avatar, Dialog, DialogContent, Button, Grid } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';

import { fetchDateFormat, DATE_FORMATS, fetchCurrencyFormat, getAmtFormat, humanise } from '../../utils/utility';
import { TRANSACTION_MODE } from '../../utils/constants';
import { colorPrimaryBg } from '../../utils/css';

const styles = (theme) => ({
  dialogTitle: {
    textAlign: "center",
    paddingBottom: 40
  },
  container: {
    background: '#F8F9FB',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 100,
    maxWidth: 800,
    [theme.breakpoints.down("sm")]: {
      marginLeft: '5%',
      width: '90%',
    }
  },
  userContainer: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '5px',
    border: '1px solid #ECECEC',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 30,
  },
  spacer: {
    marginTop: 24,
  },
  avatar: {
    display: "flex",
    border: "3px solid transparent",
    cursor: "pointer",
    height: 50,
    width: 50,
    margin: "auto",
    marginBottom: 20,
  },
  userName: {
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  shiftTime: {
    color: '#999999',
    fontSize: '1.2rem',
    marginTop: 10,
  },
  shiftCash: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 40
  },
  shiftCashLabel: {
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: 5,
  },
  shiftCashAmount: {
    fontSize: '1.6rem',
    fontWeight: 600,
  },
  reportContainer: {
    alignItems: 'center',
    background: '#ffffff',
    borderRadius: '5px',
    border: '1px solid #ECECEC',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  reportName: {
    fontWeight: 600,
    fontSize: '1.8rem',
    marginTop: 40,
  },
  drawer: {
    backgroundColor: '#F8F9FB'
  },
  lineItems: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginTop: 20,
    paddingTop: 20,    
    width: '100%'
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: 0,
    marginBottom: theme.spacing(3)
  },
  rightAlign: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  primary: {
    fontSize: '1.4rem',
    fontWeight: 600,
    marginBottom: 5
  },
  secondary: {
    fontSize: '1.3rem',
    color: '#828282'
  },
  summary: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    width: '100%'
  },
  summaryLabel: {
    fontSize: '1.2rem',
    fontWeight: 600,
    marginBottom: 10
  },
  summaryAmount: {
    fontSize: '3rem',
    fontWeight: 600
  },
  successText: { color: "#43a047" },
  errorText: { color: "#E37381" },
  warningText: { color: "#F2D073" },
  buttonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    marginBottom: 20
  },
  download: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    opacity: 0.7,
    '& img': {
      height: 40,
      width: 40
    }
  },
  lockButton: {
    backgroundColor: "#000000",
    borderRadius: 10,
    color: "#FFFFFF",
    fontSize: '1.8rem',
    fontWeight: 600,
    marginLeft: 20,
    padding: 10,
    width: 200,
    "&:hover":{
      backgroundColor: "#000000",
      color: "#FFFFFF!important",
    }
  },
});

const CashReport = (props) => {

  const classes = props.classes;
  const cashDrawerData = { ...props.cashDrawerData}
  const transaction = { ...props.transaction}
  const overShortAmt = cashDrawerData.overShort;
  const users = { ...props.users };
  const startedByUser = users[cashDrawerData.startedByID] ? humanise(users[cashDrawerData.startedByID].firstName, users[cashDrawerData.startedByID].lastName) : "";
  const endedByUser = users[cashDrawerData.endedByID] ? humanise(users[cashDrawerData.endedByID].firstName, users[cashDrawerData.endedByID].lastName) : "";
  const currency = fetchCurrencyFormat(props.property ? props.property.currency : null);
  const finalOvershotData = getAmtFormat(overShortAmt, currency);

  const handleDownload = () => {
    let blob = new Blob([props.transactionReport], { type: 'application/pdf' }),
      url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.href = url;
    link.download = "TransactionReport-" + fetchDateFormat(moment(), DATE_FORMATS.DEFAULT_DATE + "-" + DATE_FORMATS.TIME, props.timezone) + "-.pdf";
    link.click();
  }

  return (
    <Dialog 
      open={true} 
      fullScreen={true} 
      fullWidth={true}
      className={classes.drawer}
      style={{background: colorPrimaryBg}}
    >
      <DialogContent style={{background: '#F8F9FB'}}>
        <div className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <div className={classes.userContainer}>
                <Avatar
                  src={''}
                  className={classes.avatar}
                >
                  TU
                </Avatar>
                <div className={classes.userName}>{startedByUser}</div>
                <div className={classes.shiftTime}>
                  {fetchDateFormat(cashDrawerData.startTime, DATE_FORMATS.LONG_DATE + ', ' + DATE_FORMATS.TIME, props.timezone)}
                </div>
                <div className={classes.shiftCash}>
                  <div className={classes.shiftCashLabel}>Starting Amount</div>
                  <div className={classes.shiftCashAmount}>
                    {getAmtFormat(cashDrawerData.startCount, currency)}
                  </div>
                </div>
              </div>
              <div className={clsx(classes.userContainer, classes.spacer)}>
                <Avatar
                  src={''}
                  className={classes.avatar}
                >
                  TU
                </Avatar>
                <div className={classes.userName}>{endedByUser}</div>
                <div className={classes.shiftTime}>
                  {fetchDateFormat(cashDrawerData.endTime, DATE_FORMATS.LONG_DATE + ', ' + DATE_FORMATS.TIME, props.timezone)}
                </div>
                <div className={classes.shiftCash}>
                  <div className={classes.shiftCashLabel}>Closing Amount</div>
                  <div className={classes.shiftCashAmount}>
                    {getAmtFormat(cashDrawerData.endCount, currency)}
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={7}>
              <div className={classes.reportContainer}>
                <div className={classes.reportName}>Shift Report</div>
                <div className={classes.lineItems}>
                  
                  <div className={classes.item}>
                    <div>
                      <div className={classes.primary}>Cash</div>
                      <div className={classes.secondary}>{transaction.cashCount} transactions</div>
                    </div>
                    <div className={classes.rightAlign}>
                      <div className={classes.primary}>{getAmtFormat(cashDrawerData.cashIncome, currency)}</div>
                    </div>
                  </div>

                  <div className={classes.item}>
                    <div>
                      <div className={classes.primary}>Card</div>
                      <div className={classes.secondary}>{transaction.cardCount} transactions</div>
                    </div>
                    <div className={classes.rightAlign}>
                      <div className={classes.primary}>{getAmtFormat(transaction.cardAmount, currency)}</div>
                    </div>
                  </div>

                  <div className={classes.item}>
                    <div>
                      <div className={classes.primary}>Other</div>
                      <div className={classes.secondary}>{transaction.otherCount} transactions</div>
                    </div>
                    <div className={classes.rightAlign}>
                      <div className={classes.primary}>{getAmtFormat(transaction.otherAmount, currency)}</div>
                    </div>
                  </div>

                  <div className={classes.summary}>
                    <div className={classes.summaryLabel}>Cash Reconciliation</div>
                    <div className={clsx({
                      [classes.summaryAmount]: true,
                      [classes.successText]: overShortAmt === 0,
                      [classes.errorText]: overShortAmt < 0,
                      [classes.warningText]: overShortAmt > 0
                    })}>{finalOvershotData}</div>
                    <div className={clsx({
                      [classes.summaryLabel]: true,
                      [classes.successText]: overShortAmt === 0,
                      [classes.errorText]: overShortAmt < 0,
                      [classes.warningText]: overShortAmt > 0
                    })}>{overShortAmt !== 0 && (overShortAmt < 0 ? 'short' : 'over')}</div>
                  </div>
                  
                </div>
              </div>
            </Grid>
          </Grid>

          <div className={classes.buttonContainer}>
            <div className={classes.download} onClick={props.handleDownload}><img onClick={handleDownload} src='/images/icons/download.png'/></div>
            <Button onClick={props.handleCloseReport} className={classes.lockButton}>Lock Register</Button>
          </div>

        </div>
      </DialogContent>

    </Dialog>
  );
}

const mapStateToProps = state => {
  let closeCashDrawerID = state.cashDrawer.closeCashDrawerID;
  let closeCashDrawer = state.cashDrawer.closeCashDrawer && closeCashDrawerID ? state.cashDrawer.closeCashDrawer[closeCashDrawerID] : {};
  let transactionIDs = closeCashDrawer.transactions || [];
  let transactions = state.cashDrawer.transactions || {};
  let transactionMode = state.property.transactionMode || {};
  let cashCount = 0, cardAmount = 0, cardCount = 0, otherAmount = 0, otherCount = 0;

  transactionIDs.forEach(key => {
    let transactionInfo = transactions[key];
    if(transactionMode[transactionInfo.modeID].name === TRANSACTION_MODE.CASH){
      cashCount++;
    }
    else if(transactionMode[transactionInfo.modeID].name === TRANSACTION_MODE.CARD){
      cardAmount += transactionInfo.amount;
      cardCount++;
    } else {
      otherAmount += transactionInfo.amount;
      otherCount++;
    }
  });

  return {
    transaction: {
      cashCount: parseInt(cashCount || 0),
      cardAmount: cardAmount,
      cardCount: parseInt(cardCount || 0),
      otherAmount: otherAmount,
      otherCount: parseInt(otherCount || 0)
    },
    cashDrawerData: closeCashDrawer || {},
    transactionReport: state.cashDrawer.transactionReport,
    timezone: state.property.timezone,
    users: state.auth.users || {}
  };
};

export default withStyles(styles)(connect(mapStateToProps)(CashReport));