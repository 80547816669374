import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import RadioGroup from '@material-ui/core/RadioGroup';

import { RadioButton } from '../common/Button';
import PaymentInfo from './PaymentInfo';
import Footer from '../common/Footer';
import { TRANSACTION_MODE } from '../../../utils/constants';

const useStyles = makeStyles(theme =>({
    root: {
    
    },
    radioGroup: {
        alignItems: 'center'
    },
    radioBtn: {
        width: "380px",
        marginBottom: "20px",

        '& .MuiFormControlLabel-label':{
            fontSize: "1.5rem",
            textAlign: "center",
            padding: "15px",
        },

        [theme.breakpoints.down('xs')]: {
            width: "100%",
        }
    }
}));

const PAYMENT_OPTIONS = [
    { id: TRANSACTION_MODE.CASH, label: 'Cash' },
    { id: TRANSACTION_MODE.CARD, label: 'Card' }
];

const getStripeStatus = (stripe) =>{
    if(stripe?.activated && !stripe?.action_required) return true;
    return false;
}

const PaymentOptions = props =>{
    const classes = useStyles();
    const { 
        nextStep, 
        prevStep, 
        transactionModeIDs, 
        transactionMode, 
        selectedModeID, 
        onChangePaymentMode,
        paymentAmount,
        currency,
        stripe 
    } = props;

    const [paymentOptions, setPaymentOptions] = useState([]);

    useEffect(() =>{
        
        let paymentTypeOptions = PAYMENT_OPTIONS.map(option =>({
            ...option,
            value: getTansactionModeID(option.id)
        }));

        //CHECK IF STRIPE IS ENABLED......................
        if(getStripeStatus(stripe)){

            const stripeTransactionMode = {
                id: TRANSACTION_MODE.STRIPE, 
                label: 'Stripe',
                value: getTansactionModeID(TRANSACTION_MODE.STRIPE)
            }
            
            paymentTypeOptions = [ stripeTransactionMode, ...paymentTypeOptions ];
        }
        
        //CHECK IF CUSTOM TRANSACTION MODES EXISTS......................
        if(transactionModeIDs.some(id => transactionMode[id].isCustomMode)){
            paymentTypeOptions.push({ id: 'other', label: 'Other', value: 'other' });
        }

        setPaymentOptions([ ...paymentTypeOptions ]);

    }, [transactionModeIDs]);

    const getTansactionModeID = (modeType) =>{
        return transactionModeIDs?.find(id => transactionMode[id].name == modeType)
    }

    const onChangeHandler = event =>{
        const option = event.target.value;
        onChangePaymentMode(option);
    }
    
    return (
        <>
            <div className={classes.root}>

                <PaymentInfo 
                    amount={paymentAmount}
                    currency={currency}
                    subText="Choose a payment type below"
                />

                <RadioGroup
                    className={classes.radioGroup}
                    name="paymentOption"
                    onChange={onChangeHandler}
                    value={selectedModeID}
                >
                    {paymentOptions.map((paymentType, index) =>(
                        <RadioButton
                            className={classes.radioBtn}
                            key={`paymentType_${paymentType.id}`}
                            label={paymentType.label}
                            value={paymentType.value}
                        />
                    ))}
                </RadioGroup>
            </div>

            <Footer 
                nextBtnLabel="Next"
                isNextBtnDisabled={!selectedModeID}
                nextStepHandler={nextStep}
                prevStepHandler={prevStep}
                showPrevBtn
            />
        </>
    )
}

const mapStateToProps = state =>{
    const { property, payments } = state;
    return {
        transactionModeIDs: property.transactionModeIDs || [],
        transactionMode: property.transactionMode || {},
        stripe: payments?.stripe || {},
    }
}

export default connect(mapStateToProps)(PaymentOptions);