import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';

import { setMode } from '../../redux/actions/kiosk';

import Activities from '../online_checkin/activities';
import CheckinFrom from '../online_checkin/checkinForm';
import CheckinSuccess from './CheckinSuccess';
import Header from '../booking_engine/header';
import UploadIdProof from '../online_checkin/upload_id_proof';
import Payment from '../online_checkin/Payment';
import PersonalInformation from '../online_checkin/personal_information';
import Rules from '../online_checkin/rules';
import Stepper from '../online_checkin/Stepper';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const styles = theme =>({
    main: {
        background: '#F8F9FB'
    },
    root: {
        paddingTop: '30px',
        paddingLeft: 0,
        paddingRight: 0,
        overflow: 'hidden',
        background: '#F8F9FB',
        marginTop: 80,
        maxWidth: '100vw',
        minHeight: 'calc(100vh - 80px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 40px)',
            paddingTop: '40px'
        }
    },
    mainContentHolder:{
        // margin: '35px 0',
        background: '#F8F9Fb',
        marginTop: '35px',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    mainContent:{
        background: '#F8F9Fb',
    },
    icon: {
        color: '#000000',
        cursor: 'pointer',
        fontSize: '18px',
        width: 40,
    },
    divClass: {
        textAlign: 'center',
        justifyContent: 'center'
    }
});

const ROUTE_PAGES = {
    '/': { nextUrl: 'personal-info', prevUrl: ''},
    '/personal-info': { nextUrl: 'rules', prevUrl: '', title: 'Personal Information'},
    '/rules': { nextUrl: 'payment', prevUrl: 'upload-id', title: 'Rules'},
    // '/activities': { nextUrl: 'payment', prevUrl: 'rules', title: 'Activities'},
    '/payment': { nextUrl: 'success', prevUrl: 'activities', title: 'Payment'},
}
class Checkin extends Component{

    state = {
        activeStep: 0
    }

    componentDidMount(){
        document.body.style.backgroundColor="#fff";
        window.scrollTo(0,0);
    }

    nextStep = () =>{
        const { history, match, bookingStatus } = this.props;
        const path = this.fetchCurrentPath();
        let basePath = match.url;
        basePath = basePath[basePath.length - 1] == '/' ? basePath.slice(0, -1) : basePath;
  
        if(bookingStatus == 'Online Checked In' || bookingStatus == 'In House'){
            this.setState(prevState => ({...prevState, activeStep: 4}))
            history.push(`${basePath}/success`);
            return;
        }

        if(path) history.push(`${basePath}/${ROUTE_PAGES[path].nextUrl}`);
        window.scrollTo(0,0);
        console.log('here', this.state.activeStep);
        this.setState(prevState => ({...prevState, activeStep: prevState.activeStep + 1}));
    }

    prevStep = () =>{
        const { history } = this.props;
        history.goBack();
        window.scrollTo(0,0);
        setState(prevState => ({...prevState, activeStep: prevState.activeStep - 1}));
    }

    handleResetKiosk = () =>{
        const { navigateTo, dispatch } = this.props;
        dispatch(setMode(0));
        navigateTo('');
    }


    fetchCurrentPath = () =>{
        const { location, match } = this.props;
        if(location.pathname == match.url) return '/';
        const path = location.pathname.replace(match.url,'');
        return path
    }

    render(){
        const { classes, match, settings, propertyDetails, handleResetKiosk, i18n } = this.props;
        const propertyImage = settings && settings.propertyLogo ? settings.propertyLogo : '';
        const currentPagePath = this.fetchCurrentPath();
        const routeProps = {
            currentPagePath,
            nextStep: this.nextStep,
            prevStep: this.prevStep,
            handleResetKiosk: this.handleResetKiosk
        }
        return(
            <div className={classes.main}>
                <Header 
                    title={propertyDetails.name}
                    currency={propertyDetails.currency}
                    image = {propertyImage}
                    i18n = {i18n}
                    hideCurrency={true}
                    kiosk={true}
                    handleResetKiosk={this.handleResetKiosk}
                />
                <Container className={classes.root}>

                    {(this.state.activeStep < 4) && <Stepper 
                        activeStep={this.state.activeStep}
                    />}

                    <Box className={`${classes.mainContentHolder}`}>
                        <Box className={classes.mainContent}>
                            <Route path={`${match.url}`} exact render={props =><CheckinFrom {...props} {...routeProps} />} />
                            <Route path={`${match.url}/personal-info`} exact render={props =><PersonalInformation {...props} {...routeProps} />} />
                            <Route path={`${match.url}/rules`} exact render={props =><Rules {...props} {...routeProps} />} />
                            {/* <Route path={`${match.url}/activities`} exact render={props =><Activities {...props} {...routeProps} />} /> */}
                            <Route path={`${match.url}/payment`} exact render={props =><Payment {...props} {...routeProps} />} />
                            <Route path={`${match.url}/success`} exact render={props =><CheckinSuccess {...props} {...routeProps} />} />
                        </Box>
                    </Box>
                </Container>
            </div>
        )
    }
} 

const mapStateToProps = state =>{
    const { bookingEngine, onlineCheckin } = state;
    const propertyDetails = bookingEngine.propertyDetails ? bookingEngine.propertyDetails: {};
    return{
        bookingStatus: onlineCheckin.status,
        propertyDetails,
        settings: onlineCheckin.settings
    };
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(Checkin)));