const ratePlans = {default: true};

export default (state = ratePlans, action) => {
  switch (action.type) {
    case "LOAD_RATEPLANS_SUCCESS":
      return {
        ...action.payload
      };
    case "RESET_RATEPLANS": 
      return ratePlans
    case "USER_LOGOUT":
      return ratePlans
    default:
      return state;
  }
};