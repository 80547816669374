import React, { Component } from 'react';
import { Switch, Route, Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

import { withStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import Header from '../../online_checkin/Header';
import CheckinFrom from './CheckinForm';
import PersonalInformation from './PersonalInformation';
import UploadIdProof from './UploadInfo';
import Rules from '../../online_checkin/rules';
import Activities from '../../online_checkin/activities';
import Payment from './Payment';
import CheckinSuccess from '../../online_checkin/CheckinSuccess';

import * as DATA from '../booking_engine/data';

const styles = theme =>({
    root: {
        marginTop: '30px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '40px'
        }
    },
    mainContentHolder:{
        // margin: '35px 0',
        width: '650px',
        margin: '35px auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    mainContent:{
        paddingBottom: '30px',
    }
});

const ROUTE_PAGES = {
    '/': { nextUrl: 'personal-info', prevUrl: ''},
    '/personal-info': { nextUrl: 'rules', prevUrl: '', title: 'Personal Information'},
    // '/upload-id': { nextUrl: 'rules', prevUrl: 'personal-info', title: 'Upload your ID'},
    '/rules': { nextUrl: 'payment', prevUrl: 'upload-id', title: 'Rules'},
    // '/activities': { nextUrl: 'payment', prevUrl: 'rules', title: 'Activities'},
    '/payment': { nextUrl: 'success', prevUrl: 'activities', title: 'Payment'},
}

const settings = {
    customerDetails: {
        address: "display",
        city: "display",
        country: "display",
        email: "display",
        name: "mandatory",
        phone: "display",
        photo: "display"
    }
}

class OnlineCheckin extends Component{
    state = {
        showPage: false
    }

    setDocumentTitle = (title) =>{
        document.title = title;
    }

    componentDidMount(){
        const { location, match, history, dispatch } = this.props;
        document.body.style.backgroundColor="#fff";
        if(location.pathname != match.url){
            history.replace(match.url);
        }
        const propertyHandle = match.params.id;
        console.log(location.pathname, match.url);
        dispatch({ 
            type: `FETCH_CHECKIN_ROOMS_INFO_SUCCESS`, 
            payload: {
                propertyDetails: DATA.propertyDetails,
                roomTypes: DATA.roomTypes,
                taxItems: DATA.taxItems,
                ratePlans: DATA.ratePlans,
                s3Path: '',
                settings: {}
            }
        });
    }

    componentDidUpdate(prevProps){
        if(!_.isEqual(this.props.propertyDetails, prevProps.propertyDetails)){
            this.setDocumentTitle(this.props.propertyDetails.name);
        }
    }

    nextStep = () =>{
        const { history, match } = this.props;
        const path = this.fetchCurrentPath();
        let basePath = match.url;
        basePath = basePath[basePath.length - 1] == '/' ? basePath.slice(0, -1) : basePath;
        console.log(`${basePath}/${ROUTE_PAGES[path].nextUrl}`);
        if(path) history.push(`${basePath}/${ROUTE_PAGES[path].nextUrl}`);
        window.scrollTo(0,0);
    }

    fetchCurrentPath = () =>{
        const { location, match } = this.props;
        // console.log(this.props);
        if(location.pathname == match.url) return '/';
        const path = location.pathname.replace(match.url,'');
        return path
    }

    pageNotFoundHandler = () =>{
        this.setState({ isPageNotFound: true });
    }

    showElement = currentPagePath =>{
        if(currentPagePath == '/') return false;
        if(currentPagePath == '/success') return false;
        return true;
    }

    render(){
        const { classes, match, i18n } = this.props;
        const currentPagePath = this.fetchCurrentPath();
        const routeProps = {
            currentPagePath,
            nextStep: this.nextStep
        }
        return(
            <>
                {this.showElement(currentPagePath) && <Header title={ROUTE_PAGES[currentPagePath].title} i18n={i18n}/>}
                <Container className={classes.root}>
                    <Box className={`${classes.mainContentHolder}`}>
                        <Box className={classes.mainContent}>
                            <Switch>
                                <Route path={`${match.url}`} exact render={props =><CheckinFrom {...props} {...routeProps} />} />
                                <Route path={`${match.url}/personal-info`} exact render={props =><PersonalInformation {...props} {...routeProps} />} />
                                <Route path={`${match.url}/upload-id`} exact render={props =><UploadIdProof {...props} {...routeProps} />} />
                                <Route path={`${match.url}/rules`} exact render={props =><Rules {...props} {...routeProps} />} />
                                <Route path={`${match.url}/activities`} exact render={props =><Activities {...props} {...routeProps} />} />
                                <Route path={`${match.url}/payment`} exact render={props =><Payment {...props} {...routeProps} />} />
                                <Route path={`${match.url}/success`} exact render={props =><CheckinSuccess {...props} {...routeProps} />} />
                            </Switch>
                        </Box>
                    </Box>
                </Container>
            </>
        )
    }
} 

const mapStateToProps = state =>{
    const { onlineCheckin } = state;
    //console.log(onlineCheckin);
    const propertyDetails = onlineCheckin.propertyDetails ? onlineCheckin.propertyDetails: {};
    return{ 
        propertyDetails
    };
}

export default withStyles(styles)(connect(mapStateToProps)(OnlineCheckin));