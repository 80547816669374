import React from 'react';
import { makeStyles } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles( theme => ({
  dialog: {
    overflowY: 'scroll',
    zIndex: '10000'
  },
  dialogPaper: {
    minHeight: 'calc(100% -  128px)',
    maxHeight: 'calc(100% - 128px)',
    marginTop: 60,
    [theme.breakpoints.down('sm')]: {
      minHeight: '100%',
      maxHeight: '100%'
    }
  },
  dialogTitle: {
    alignItems: 'center',
    background: '#ffffff',
    borderBottom: "1px solid #dddddd",
    display: 'flex',
    justifyContent: 'center',
    minHeight: 40,
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    textAlign: "center",
    zIndex: '105'
  },
  closeIcon: {
    borderRadius: '50%',
    color: '#666666',
    cursor: 'pointer',
    padding: theme.spacing(1),
    position: 'absolute',
    right: 24,
    transition: '150ms',
    top: 24,
    '&:hover': {
      background: '#dddddd',
      color: '#666666'
    }
  },
  content: {
    padding: theme.spacing(3),
    height: 'calc(82vh - 186px)'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  termsButton: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
}))

const TermsAndConditions = (props) => {
  
  const classes = useStyles();
  const { open, handleClose, handleAccept } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paperFullWidth: classes.dialogPaper }}
      maxWidth="sm"
      fullWidth={true}
      fullScreen={window.innerWidth < 901}
    >
      {/* --------------------------------------------------------- */}
      {/* Header */}
      {/* --------------------------------------------------------- */}
      <div className={classes.dialog}>
        <div className={classes.dialogTitle}>
          <Typography variant="h5">Terms & Conditions</Typography>
          {/*<CloseIcon className={classes.closeIcon} onClick={handleClose} />*/}
        </div>

        <div className={classes.content}>
          <div  dangerouslySetInnerHTML={{__html: props.termsAndConditions}} />
        </div>

        <div className={classes.buttonContainer}>
          <Button variant="contained" color="primary" className={classes.termsButton} onClick={handleClose}>
            Close
          </Button>
        </div>
      </div>

    </Dialog>
  );
}


export default TermsAndConditions;