import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
// import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import UserInfoFrom from './UserInfoForm';

const useStyles = makeStyles(theme =>({
    infoCard:{
        background: '#FFFFFF',
        boxShadow: 'none',
        border: '1px solid #BDBDBD',
        borderRadius: '5px',
        marginBottom: '20px',
        position: 'relative',
    },
    profilePicContainer:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px',
        position: 'relative'
    },
    profilePic: {
        width: '90px',
        height: '90px',
        marginBottom: '10px'
    },
    addGuestBtn:{
        alignItems: 'center',
        border: '1px dashed #BDBDBD',
        color: '#7B7B7B',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '1.4rem',
        justifyContent: 'center',
        padding: 20,
        width: 'calc(100% - 42px)',
    },
    addIcon:{
        color: '#7B7B7B',
        fontSize: '1.6rem',
        marginRight: 10,
    },
    deleteIcon:{
        position: 'absolute',
        zIndex: 999,
        width: '50px',
        right: '10px',
        top: '10px',
        [theme.breakpoints.down('sm')]: {
            right: '5px',
            top: '5px',
        }
    }
}));

const AddGuest = props =>{
    const classes = useStyles();
    const [addGuest, setAddGuest] = useState(false);
    const { t } = props
    const guestData = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: { country: '' },
        id: null
    }
    return(
        <>
            {addGuest ? (
                <Card className={classes.infoCard}>
                    <Box className={classes.profilePicContainer}>
                        <IconButton 
                            aria-label="delete" 
                            className={classes.deleteIcon}
                            onClick={()=>setAddGuest(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <UserInfoFrom
                        customerDetails={props.settings.customerDetails}
                        guestData={guestData}
                        accommodationID={props.accommodationID}
                        submitHandler={props.submitHandler} 
                        toggleMode={()=>setAddGuest(false)}
                        checkForChange={props.checkForChange}
                        t = {t}
                        newGuest={true} 
                    />
                </Card>   
            ):(
                <div className={classes.addGuestBtn} onClick={()=>setAddGuest(true)}>
                    <AddIcon className={classes.addIcon} />
                    <div>{t('onlineCheckin.details.addGuest')}</div>
                </div>
            )}
            
        </>
    );
}

export default AddGuest;