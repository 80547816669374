import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Dialog from '../../common/CustomDialog';
import RoomBedOptions from '../RoomBedOptions';
import DurationSelect from './DurationSelect';
import RoomSelect from '../RoomSelect';
import { fetchCurrencyFormat } from '../../../../utils/utility';
import { getPropertyCurrency, fetchGuestAccommodations } from '../../helper';
import { FORM_TYPE } from '../Helper';
import { loadReservationManualOptions } from '../../../../redux/actions/reservations';

const styles = theme => ({
   root: {},
});

const STEPS_CONTENT = {
   1: { title: 'Select room or bed to edit', component: RoomBedOptions },
   2: { title: 'Select new arrival date', component: DurationSelect },
   3: { title: 'Select a room', component: RoomSelect }
}

class ChangeArrivalDate extends Component {
   state = {
      activeStep: 1,
      selectedAccomodation: {},
      selectedDateRange: null,
      options: []
   }

   setSelectedAccomdation = (guestNumber) => {
      const { reservation, roomTypes, rooms, beds, currency } = this.props;
      const data = {
         reservation,
         roomTypes,
         rooms,
         beds,
         currency
      }
      const guestAccomodations = fetchGuestAccommodations(data);
      const selectedAccomodation = guestAccomodations.find(acc => acc.guestNumber == guestNumber);
      this.setState({ selectedAccomodation: { ...selectedAccomodation } });
   }

   setSelectedDateRange = (selectedDateRange) => {
      this.setState({ selectedDateRange });
   }

   nextStep = () => {
      const { activeStep } = this.state;
      this.setState({ activeStep: activeStep + 1 });
   }

   getManualOptions = (data) => {
      const { propertyID, dispatch } = this.props;
      dispatch(loadReservationManualOptions(propertyID, data))
         .then(response => {
            if (response) {
               this.setState({ options: [...response.availableBeds] });
               this.nextStep();
            }
         });
   }

   render() {
      const { classes, closeModalHandler, timezone, currencyCode, propertyID, isLoading } = this.props;
      const { activeStep, selectedAccomodation, selectedDateRange, options } = this.state;
      const CurrentStep = STEPS_CONTENT[activeStep];

      const componentProps = {
         type: FORM_TYPE.PRE_PONE_CHECK_IN,
         nextStep: this.nextStep,
         currency: currencyCode,
         propertyID,
         timezone,
         selectedAccomodation: { ...selectedAccomodation },
         selectedDateRange,
         options,
         setSelectedAccomdation: this.setSelectedAccomdation,
         setSelectedDateRange: this.setSelectedDateRange,
         getManualOptions: this.getManualOptions,
         closeModalHandler
      }

      return (
         <Dialog
            isLoading={isLoading}
            headerTitle={CurrentStep.title}
            closeModalHandler={closeModalHandler}
         >
            <CurrentStep.component {...componentProps} />
         </Dialog>
      )
   }
}

const mapStateToProps = state => {
   const { register, property, rooms, reservationDetails, loading } = state;
   const currencyCode = getPropertyCurrency(register, property);
   return {
      currencyCode,
      currency: fetchCurrencyFormat(currencyCode) || '',
      rooms: rooms?.rooms || {},
      roomTypes: rooms?.roomTypes || {},
      beds: rooms?.beds || {},
      reservation: reservationDetails?.reservation || {},
      timezone: property.timezone,
      propertyID: register.propertyID,
      isLoading: loading.UPDATE_RESERVATION_DATA ||
         loading.LOAD_RESERVATION_MANUAL_OPTIONS
   }
}

export default withStyles(styles)(connect(mapStateToProps)(ChangeArrivalDate));