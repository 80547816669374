import React from 'react';
import { Grid } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import _ from "lodash";
import Item from './Item';

const breakpointXLScr = 1440;
const breakpointLScr = 1024;
const breakpointMScr = 768;
// const breakpointSScr = 576;


const sliderSettings = {
  dots: false,
  rows: 3,
  slidesPerRow: 4,
  adaptiveHeight: true,
  arrows: true,
  infinite: true,
  responsive: [
    {
      breakpoint: breakpointXLScr,
      settings: {
        slidesPerRow: 3
      }
    },
    {
      breakpoint: breakpointLScr,
      settings: {
        slidesPerRow: 2
      }
    },
    {
      breakpoint: breakpointMScr,
      settings: {
        slidesPerRow: 1
      }
    }
  ],
  beforeChange: (current, next) => console.log(current, next)
}

const ItemList = (props) => {
  const { itemType, items } = props;

  const handleSelectItem = item => {
    if (itemType === "product") {
      item.quantity = 1;
      // this.props.actions.selectProducts(item);
    } else if (itemType === "category") {
      // this.props.callbackSelectedCategory(item);
    }
  };

  return (
    <Grid container spacing={2} justify="flex-start">
      <Grid
        item
        xs={12}
        className="test"
      >
        {items.length > 0 && (
          <div>
            <Slider {...sliderSettings}>
              {items.map((item, i) => (
                <div key={i} className="item-div">
                  <Item
                    item={item}
                    key={item._id}
                    callBackSelectItem={handleSelectItem}
                  />
                </div>
              ))}
            </Slider>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default ItemList;
