import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import UploadItem from '../../online_checkin/upload_id_proof/UploadItem';
import Footer from '../../online_checkin/Footer';

import { 
    showCheckinLoader, 
    hideCheckinLoader
} from '../../../redux/actions/onlineCheckin';
import { withTranslation } from 'react-i18next';

const styles = theme =>({
    root: {
        // border: '1px solid'
    },
});

class UploadIdProof extends Component{
    uploadHandler = (files, customerID) =>{
        const { dispatch } = this.props;
        console.log('files ',files);
        dispatch(showCheckinLoader());
        dispatch({ 
            type: `UPLOAD_DOCUMENT_SUCCESS`, 
            payload: {
                guestID: customerID,
                documents: [{ key: ' '}]
            }
        });
        dispatch(hideCheckinLoader());
    }

    render(){
        const { classes, guests, nextStep, s3Path, t } = this.props;
        return(
            <>
                <Box className={classes.root}>
                    {Object.keys(guests).map((guestID, index) =>(
                        <UploadItem
                            key={guestID}
                            guestIndex = {index + 1}
                            guestData = {guests[guestID]}
                            uploadHandler={this.uploadHandler} 
                            docBaseUrl = {s3Path}
                            t={t}
                        />
                    ))}
                </Box>
                <Footer
                    stepInfo="step 2 of 5"
                    buttonLabel="Proceed"
                    onProceed = {nextStep}
                />
            </>
        )
    }
}

const mapStateToProps = state=>{
    return{
        guests: state.onlineCheckin.guests,
        s3Path: state.onlineCheckin.s3Path
    };
}

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(UploadIdProof)));