import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import MoreButton from '../common/MoreButton';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme =>({
    root: {
        display: 'flex',
        alignItems: "center",
        marginBottom: "10px",
        '& p': {
            margin: '0px'
        }
    },
    label: {
        fontSize: "1.2rem",
        width: "100px",
        marginRight: "10px",
        fontWeight: 600,
        color: "#737373",
    },
    guestName: {
        border: "1px solid #e0e0e0",
        borderRadius: "30px",
        padding: "15px 20px",
        fontSize: "1.2rem",
        fontWeight: 600,
        color: "#5f5f5f",
        flex: 3
    },
    addons: {
        display: "flex",
        fontSize: "1.1rem",
        fontWeight: 600,
        color: "#5f5f5f",
        border: "1px solid #e0e0e0",
        borderRadius: "30px",
        width: "100px",
        padding: "15px 20px",
        justifyContent: "space-between",
        flex: 1,
        marginLeft: '10px'
    }
}));

const moreOptions = [
    { type: 'edit', label: 'Edit Guest', icon: <EditIcon /> },
];

const GuestItem = props => {
    const classes = useStyles();
    const { label, name, onEditHandler } = props;

    const moreButtonHandler = type =>{
        if(type == 'edit') onEditHandler();
    }

    return (
        <div className={classes.root}>
            <p className={classes.label}>{label}</p>
            <div className={classes.guestName}>{name}</div>

            {onEditHandler && (
                <MoreButton 
                    options={moreOptions}
                    selectHandler = {moreButtonHandler}
                />
            )}
            
            {/* <div className={classes.addons}>
                <p>Experiences</p>
                <p>1</p>
            </div>
            <div className={classes.addons}>
                <p>POS</p>
                <p>5</p>
            </div>
            <div className={classes.addons}>
                <p>Add ons</p>
                <p>0</p>
            </div> */}
        </div>
    )
}

export default GuestItem;