import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';

import clsx from 'clsx';
import { getAvatarFlag, initials, humanise } from '../../../utils/utility';
import { ICONS } from "../../../utils/imageUrls";
import * as i18nIsoCountries from "i18n-iso-countries";
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import Grid from '@material-ui/core/Grid';
import Avatar from "@material-ui/core/Avatar";
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme =>({
    container: {
        background: '#FFFFFF',
        borderRadius: 5,
        border: '1px solid #BDBDBD',
        paddingTop: 20,
        paddingBottom: 20,
        marginBottom: 20
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    nameHolder: {
        paddingLeft: 20,
    },
    name: {
        color: '#484848',
        fontSize: '1.5rem',
        marginBottom: 5,
    },
    detailsRow: {
        color: '#999999',
        display: 'flex',
        fontSize: '1rem',
        marginTop: 10,
    },
    email: {
        paddingLeft: 20
    },
    iconHolder: {
        display:'flex',
        justifyContent: 'flex-end',
        marginRight: 24,
    },
    phone: {
        paddingLeft: 20,
    },
    icon:{
        alignItems: 'center',
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        height: 40,
        width: 40,
        '& img':{
            height: 20,
            width: 20,
        },
        '&:hover': {
            background: '#E0E0E0',
        }
    },
    error: {
        color: '#d96977'
    },
    rightPadding: {
        marginRight: 15,
        '&:hover': {
            background: '#FFFFFF'
        }
    },
    userInfoLabel:{
        [theme.breakpoints.up("sm")]: {
            textAlign: 'right',
            paddingRight: '40px',
        },
        [theme.breakpoints.down("sm")]: {
            width: '45%'
        }
    },
    userInfo:{
        [theme.breakpoints.up("sm")]: {
            paddingLeft: '40px',
        }
    },
    infoCard:{
        background: '#FFFFFF',
        boxShadow: 'none',
        border: '1px solid #BDBDBD',
        borderRadius: '5px',
        marginBottom: '20px',
        position: 'relative',
    },
    closeIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        position: 'absolute',
        right: 24,
        transition: '150ms',
        top: 20,
        '&:hover': {
          background: '#dddddd',
          color: '#666666'
        },
        [theme.breakpoints.down('sm')]: {
          top: '20px',
          right: '10px'
        }
    },
    topMargin:{
        marginTop: 25
    }
}));

const UserInfo = ({ guestData = {}, t, customerID, changeEdit, validateGuest }) =>{
    const classes = useStyles()

    const emailRegex = /^[A-Za-z0-9._%+-]+@(guest.booking.com|m.expediapartnercentral.com)$/

    const editInformation = () => {
        changeEdit(customerID)
    }

    return(
        <div className={classes.container}>
            <Grid container alignItems="center" spacing={3}>
                <Grid item xs={8}>
                    <div className={classes.nameHolder}>
                        <div className={classes.name}>{`${guestData.firstName} ${(guestData.lastName) ? guestData.lastName : ''}`}</div>
                    </div>
                    {(guestData.email || guestData.phone) && <div className={classes.detailsRow}>
                        {guestData.email && <div className={classes.email}>{emailRegex.test(guestData.email) ? '' : guestData.email}</div>}
                        {guestData.phone && <div className={classes.phone}>{guestData.phone}</div>}
                    </div>}
                </Grid>
                <Grid item xs={4}>
                    <div className={classes.iconHolder}>
                        {!validateGuest(customerID) && <div className={clsx(classes.icon, classes.rightPadding)}>
                            <ErrorIcon className={classes.error} />
                        </div>}
                        <div className={classes.icon} onClick={editInformation}><RightIcon /></div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default withTranslation()(UserInfo);