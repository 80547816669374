import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import RadioGroup from '@material-ui/core/RadioGroup';

import { RadioButton } from '../common/Button';

const useStyles = makeStyles(theme =>({
    root: {
        marginBottom: '20px',
        '& h5': {
            marginTop: '0px'
        }
    },
    roomtypesList: {
        flexDirection: 'row',
        '& .MuiFormControlLabel-root': {
            marginRight: '10px'
        }
    }
}));

const RoomBedList = props => {
    const classes = useStyles();
    const { data = [], selectedAccommodationID, currentAccommodationID, onChangeRoomBed } = props;

    const checkIfDisabled = (accommodationItem) =>{

        //CURRENT ROOM SHOULD BE SELECTABLE IF ANOTHER ROOM OPTION IS SELECTED................
        if(accommodationItem._id == currentAccommodationID) return { isDisabled: false, msg: '' };

        //DISABLE ROOM OPTION IF ROOM ALREADY HAS GUESTS ADDED TO IT.................
        if(accommodationItem.customerID.length > 0) return { isDisabled: true, msg: 'Guest already assigned to this room/bed.' };

        const currentAccommodationData = data.find(acc => acc._id == currentAccommodationID);

        if(currentAccommodationData.dormOrPrivate == 'private' && 
            accommodationItem.dormOrPrivate == 'dorm' &&
            currentAccommodationData.roomCapacity > 1
        ) return { isDisabled: true, msg: 'Insufficient room capacity to switch room/bed' };

        if([currentAccommodationData.dormOrPrivate, accommodationItem.dormOrPrivate].includes('private') && 
            currentAccommodationData.roomCapacity > accommodationItem.roomCapacity
        ) return { isDisabled: true, msg: 'Insufficient room capacity to switch room/bed' };;
        
        return { isDisabled: false, msg: '' };;
    }

    return(
        <div className={classes.root}>

            <h5>Assign guest to room or bed</h5>

            <RadioGroup
                name="roomBed"
                className={classes.roomtypesList}
                value={selectedAccommodationID}
                onChange={onChangeRoomBed}
            >
                {data.map(accommodation =>{
                    const accommodationStatus = checkIfDisabled(accommodation);
                    return(
                        <RadioButton
                            key={accommodation._id}
                            label={accommodation.name}
                            value={accommodation._id}
                            disabled={accommodationStatus.isDisabled}
                            tooltipTitle={accommodationStatus.msg}
                        />
                    )
                })}
            </RadioGroup>
        </div>
    )
}

const mapStateToProps = state =>{
    const { rooms } = state;
    return {
        beds: rooms?.beds || {}
    }
}

export default connect(mapStateToProps)(RoomBedList);