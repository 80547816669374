import React, { Fragment, Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Fab from '@material-ui/core/Fab';
import SummaryCard from './summaryCard';

import BookingCartItem from './bookingCartItem';
import { calculateSumOfArray, calculateBaseRoomCost, getCurrency } from '../helper';
import { 
    updateBookingRoomDetails, 
    resetBookingCart, 
    deleteRoomFromCart,
    fetchTotalBookingCostInfo 
} from '../../../redux/actions/bookingEngine';

const styles = theme => ({
    mainContentCard: {
        backgroundColor: '#fff',
        borderRadius: '20px',
        padding: '20px',
        boxShadow: '0 2px 20px #F0F0F0'
    },
    addRoomBtn: {
        padding: '0',
        height: '45px',
        borderRadius: '10px',
        cursor: 'pointer',
        width: '100%',

        display: 'flex',
        alignItems: 'center', 
        justifyContent: 'center', 
        fontSize: '13px',
        fontWeight: 'bold',
        padding: '10px',

        [theme.breakpoints.up('sm')]: {
            width: '200px',
        },
    },
    addRoomBtnHolder: {
        display: "flex",
        justifyContent: "center",
        alignitems: "center"
    },
    checkoutBtn: {
        background: '#000000',
        borderRadius: "10px",
        boxShadow: 'none',
        fontWeight: 600,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        }
    },
    checkoutBtnHolder: {
        display: "flex",
        justifyContent: "flex-end",
        marginTop: 30,
        [theme.breakpoints.down("xs")]: {
            paddingBottom: 30
        }
    },
    bedsQuantity: {
        display: "flex" ,
        flexDirection: "row-reverse",
        [theme.breakpoints.up('sm')]: {
            flexDirection: "column",
        },
    },

    bedsQuantityTitle: {
        display: "flex",
        fontWeight: "bold",
        fontSize: "15px",
        width: "100%",
        justifyContent: "space-around",
        [theme.breakpoints.up('sm')]: {
            marginBottom: "20px"
        },
        [theme.breakpoints.down('xs')]: {
            alignItems: "center"
        },
    },
    bedQuantityIcon: {
        backgroundColor: '#fff',
        boxShadow: '0 2px 20px #F0F0F0',
        height: '35px',
        width: '35px',
        '&:hover': {
            backgroundColor: '#E0E0E0',
        }
    },
    bedQuantityBtn: {
        display: "flex",
        width: "100%",
        justifyContent: "space-around"
    }
});

class BookingCart extends Component{

    componentDidMount(){
        this.fetchTotalBookingCost();
        window.scrollTo(0, 0);
    }

    fetchTotalBookingCost = () =>{
        const { 
            bookingDetails, 
            checkInDate,
            checkOutDate,
            dispatch 
        } = this.props;

        const data = { 
            checkIn: checkInDate,
            checkOut: checkOutDate,
            rowItems: {}
        };

        Object.keys(bookingDetails).map(roomTypeID =>{
            data.rowItems[roomTypeID] = {
                quantity: bookingDetails[roomTypeID].noOfBedsBooked,
                rates: [ ...bookingDetails[roomTypeID].rates ]
            }
        });

        if(Object.keys(data.rowItems).length > 0) dispatch(fetchTotalBookingCostInfo(data));
    }
    
    navigateToRoomListing  = () =>{
        this.props.navigateToStep(2);
    }

    discardRoom = roomId => {
        const { bookingDetails, dispatch } = this.props;
        //Reset booking cart if only room while discarding and redirect.
        if(Object.keys(bookingDetails).length == 1){
            dispatch(resetBookingCart());
            this.navigateToRoomListing();
            return
        }
        dispatch(deleteRoomFromCart(roomId));
    }

    calculateTotalRoomCost = data =>{
        const { bookingDetails } = this.props;
        const rates = bookingDetails[data.id].rates;
        const baseRoomCost = {
            noOfBedsBooked: data.value,
            sumOfSelectedRatePlan: calculateSumOfArray(rates)
        };
        const totalRoomCost = calculateBaseRoomCost(baseRoomCost);
        return totalRoomCost;
    }

    componentDidUpdate(prevProps){
        if(!_.isEqual(this.props.bookingDetails, prevProps.bookingDetails)){
            this.fetchTotalBookingCost();
        }
    }

    updateBedCountHandler = data => {
        const { dispatch } = this.props;
        // Discard room if beds selected in zero
        if(data.value == 0){
            this.discardRoom(data.id);
            return
        }
        const baseRoomCost = this.calculateTotalRoomCost(data);

        const updatePayload = { noOfBedsBooked: data.value, baseRoomCost };
        dispatch(updateBookingRoomDetails(data.id, updatePayload));
    }

    addBedInputRenderer = (addBedHandler,removeBedHandler,value, id) =>{
        const { bookingDetails, classes, currency } = this.props;
        return(
            <Box className={classes.bedsQuantity}>
                <Box className={classes.bedsQuantityTitle}> 
                    {value} {bookingDetails[id].dormOrPrivate == 'dorm' ? 'Bed' : 'Room'} {getCurrency(currency, bookingDetails[id].baseRoomCost)}
                </Box>
                <Box className={classes.bedQuantityBtn}>
                    <Fab 
                        aria-label="add" 
                        className={classes.bedQuantityIcon}
                        onClick = {removeBedHandler} 
                    >
                        <RemoveIcon />
                    </Fab>
                    <Fab 
                        aria-label="add" 
                        className={classes.bedQuantityIcon}
                        onClick ={addBedHandler}
                    >
                        <AddIcon />
                    </Fab>
                </Box>
            </Box>
        )
    }

    render(){
        const { 
            classes,
            bookingDetails, 
            roomList,
            roomTypes,
            bookingCostInfo,
            taxItems,
            currency,
            nextStep,
            t
        } = this.props;

        return(
            <Fragment>
                { Object.keys(bookingDetails).map((id, index) => (
                    <BookingCartItem 
                        key={`cartItem_${index}`}
                        roomTypeID = {id}
                        updateBedCountHandler = {this.updateBedCountHandler}
                        noOfBedsAvailable = {roomList[id].available}
                        noOfBedsBooked = {bookingDetails[id].noOfBedsBooked}
                        roomData = {roomTypes[id]}
                        addBedInputRenderer = {this.addBedInputRenderer}
                    />
                ))}
                <Box component="div" className={classes.addRoomBtnHolder} my={4}>
                    <ButtonBase id="cbe-addAnotherRoom" onClick={this.navigateToRoomListing} className={`${classes.mainContentCard} ${classes.addRoomBtn}`}>
                        <AddIcon /><Box ml={1}>{t('bookingEngine.addAnotherRoom')}</Box>
                    </ButtonBase>
                </Box>
                { Object.keys(bookingCostInfo).length > 0 && (
                     <SummaryCard
                        bookingCostInfo={bookingCostInfo}
                        taxItems={taxItems} 
                        currency={currency} 
                        t = {t}
                    />
                )}

                <Box component="div" className={classes.checkoutBtnHolder} mt={3} >
                    <Button id="cbe-proceedCheckout" onClick={nextStep} variant="contained" color="primary" className={classes.checkoutBtn}>{t('bookingEngine.proceedCheckout')}</Button>
                </Box>
            </Fragment>
        );
    }
}

const mapStateToProps = state =>{
    const { bookingEngine } = state;
    //console.log('bedCountData',state);
    
    return {
        bookingDetails: bookingEngine.bookingDetails,
        roomTypes: bookingEngine.roomTypes,
        taxItems: bookingEngine.taxItems,
        roomList: bookingEngine.roomList,
        checkInDate: bookingEngine.checkInDate,
        checkOutDate: bookingEngine.checkOutDate,
        bookingCostInfo: bookingEngine.bookingCostInfo,
        currency: bookingEngine.currency
    };
}
export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(BookingCart)));