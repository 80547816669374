import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { DATE_FORMATS, fetchDateFormat, fetchDatePerTimezone, fetchNoOfNights } from '../../../../utils/utility';
import { fetchAccommodationFormType, FORM_TYPE } from '../Helper';
import Footer from '../../common/Footer';

const useStyles = makeStyles(theme => ({
    label: {
        fontSize: '1.7rem',
        fontWeight: 600,
        color: '#329ef5'
    },
    buttonContainer: {
        margin: '20px 0'
    },
    button: {
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#13408d',
        color: '#ffffff',
        borderRadius: 25,
        padding: 15,
        fontWeight: 600,
        '&:hover': {
            backgroundColor: '#13408d',
            color: '#ffffff',
        }
    }
}));

const getShortenInfo = (formType, nights, range) =>{
    switch(formType){
        case FORM_TYPE.POST_PONE_CHECK_IN:
            return `You are shortening the stay for this booking by ${nights || 0} nights,
            new check-in is ${fetchDateFormat(range.startDate, 'MMM DD YYYY')}`;

        case FORM_TYPE.SHORTEN_ACCOMMODATION:
            return `You are shortening the stay for this booking by ${nights || 0} nights,
            new check-in is ${fetchDateFormat(range.startDate, 'MMM DD YYYY')}
            and new check-out is ${fetchDateFormat(range.endDate, 'MMM DD YYYY')}`;
        
        case FORM_TYPE.DEPARTURE:
            return `You are shortening the stay for this booking by ${nights || 0} nights, 
            new checkout is ${fetchDateFormat(range.endDate, 'MMM DD YYYY')}`;
    }
}

const getNightsDuration = (formType, data, range) =>{
    switch(formType){
        case FORM_TYPE.POST_PONE_CHECK_IN:
            return fetchNoOfNights(data.checkIn, range.startDate);;

        case FORM_TYPE.SHORTEN_ACCOMMODATION:
            return fetchNoOfNights(data.checkIn, data.checkOut) - fetchNoOfNights(range.startDate, range.endDate);;
        
        case FORM_TYPE.DEPARTURE:
            return fetchNoOfNights(range.endDate, data.checkOut);
    }
}

const ShortenAccomodation = props =>{
    const classes = useStyles();
    const { range, data, onSubmitHandler } = props;

    const formType = fetchAccommodationFormType(data.checkIn, data.checkOut, range.startDate, range.endDate);
    const nights = getNightsDuration(formType, data, range);

    const submitDataHandler = () => {
        let submitData = {};

        switch(formType){
            case FORM_TYPE.POST_PONE_CHECK_IN:
                submitData = {
                    guestNumber: data.guestNumber,
                    editDates: {
                        checkIn: range.startDate,
                        checkOut: data.checkOut,
                        formType: formType
                    },
                }
                break;
    
            case FORM_TYPE.SHORTEN_ACCOMMODATION:
                submitData = {
                    guestNumber: data.guestNumber,
                    editDates: {
                        checkIn: range.startDate,
                        checkOut: range.endDate,
                        formType: formType
                    },
                }
                break;
            
            case FORM_TYPE.DEPARTURE:
                let accommodation = data?.items?.find(item => item.date === range.endDate);
                let accommodationID = accommodation ? accommodation.ID : null;
                submitData = {
                    accommodationID: accommodationID,
                    changeDeparture: {
                        date: range.endDate
                    }
                }
                break;
        }
        onSubmitHandler(submitData);
    }

    return (
        <React.Fragment>
            {/* -------------------- Shorten accommodation-------------------- */}
            <div className={classes.label}>You are shortening the stay for this booking by {nights || 0} nights</div>
            
            <Footer 
                label="Save"
                onBtnClick={submitDataHandler}
            />
        </React.Fragment>
    )
}

export default ShortenAccomodation;