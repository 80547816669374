import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    Grid,
    Box,
    FormControl,
    InputLabel,
    TextField,
    FormHelperText
} from '@material-ui/core';

import {
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";

import { withTranslation } from 'react-i18next';
import CustomCheckBox from '../customCheckBox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const secondaryColor = '#5C5C5C';

const useStyles = makeStyles(theme =>({
    mainContentCard: {
        background: '#FFFFFF',
        borderRadius: '20px',
        padding: '20px',
        boxShadow: '0 0 8px 0px #dedede'
    },

    paymentFormHolder: {
        marginTop: '30px',
        position: 'relative',
        overflow: 'visible',
        paddingTop: '25px',
        paddingBottom: '25px'
    },

    formGroupHolder: {
        padding: '0 15px',
        marginTop: '25px',

        '&:nth-Child(1)': {
            marginTop: '0',
        },

        '&:nth-Child(2)': {
            [theme.breakpoints.up('sm')]: {
                marginTop: '0',
            }
        }
    },

    formControlLabel: {
        color: '#000',
        fontWeight: 'bold',
        fontSize: '12px',
        marginBottom: "12px"
    },

    formControl: {
        width: '100%'
    },

    formInput: {
        boxShadow: '0 0 8px 0px #dedede',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            // '&.Mui-focused fieldset': {
            //   borderColor: '#212121',
            // },
            '&.Mui-error fieldset': {
                border: '1px solid #f44336'
            },
        },
    },

    expiryInputDate: {
        width: '100px',
        marginRight: '30px',
        [theme.breakpoints.down('sm')]: {
            flex: 1,
            marginRight: '10px',
        },
    },

    expiryInputCVV: {
        width: '100px',
        [theme.breakpoints.down('sm')]: {
            flex: 1
        },
    },
    formTermsAndCondition: {
        padding: '0 15px'
    },
    secureImage: {
        height: '100%',
        width: '100%',
        // objectFit: 'cover'
    },
    secureBlock: {
        position: 'absolute',
        backgroundColor: '#fff',
        // width: '100%',
        left: 0,
        right: 0,
        bottom: 0
    },
    stripeForm: {
        paddingBottom: '120px'
    },

    '.MuiSelect-select': {
        '&:focus': {
            backgroundColor: '#fff'
        }
    },

    checkoutBtn: {
        width: '100%',
        backgroundColor: secondaryColor,
        color: '#fff',
        textTransform: 'capitalize',
        fontSize: "12px",
        padding: "6px 20px",
        minWidth: '100px',

        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },

        '&:hover': {
            backgroundColor: secondaryColor,
            color: '#fff'
        }
    },

    errorMessage: {
        color: '#f44336',
        marginLeft: '14px',
        lineHeight: '20px',
        '&::first-letter':{
            textTransform: 'uppercase'
        }
    },
    cardErrorBlock:{
        width: '100%',
        marginLeft: '60px',
    }
}));

const options = {
    style: {
      base: {
        fontSize: "15px",
        color: "#212121",
        // fontSize: "20px",
        // letterSpacing: "0.025em",
        // fontFamily: "Source Code Pro, monospace",
        // "::placeholder": {
        //   color: "#aab7c4"
        // }
      },
      invalid: {
        color: "#212121"
      }
    }
};

const CardSection = props =>{
    const classes = useStyles();
    const { t } = props
    return(
        <Card className={`${classes.mainContentCard} ${classes.paymentFormHolder} ${classes.stripeForm}`}>
            <Grid container>
                <Grid item xs={12} sm={6} className={classes.formGroupHolder}>
                    <InputLabel htmlFor="card-number" className={classes.formControlLabel}>{t('fields.cardNumber')} *</InputLabel>
                    <FormControl className={classes.formControl}>
                        <CardNumberElement
                            options={options}
                            onChange={props.onCardInputChange}
                        />
                        {props.cardNumberError && (
                            <FormHelperText className={classes.errorMessage}>{props.cardNumberError}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGroupHolder}>
                    <InputLabel htmlFor="card-holder" className={classes.formControlLabel}>{t('fields.cardHolder')} *</InputLabel>
                    <FormControl className={classes.formControl}>
                        <TextField
                            name="cardHolder"
                            variant="outlined"
                            margin="dense"
                            fullWidth={true}
                            className={classes.formInput}
                            error={props.cardHolderError}
                            value={props.cardHolder}
                            onChange={props.onChangeHandler}
                        />
                        {props.cardHolderError && (
                            <FormHelperText className={classes.errorMessage}>{t('fields.cardHolderError')}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={7} className={classes.formGroupHolder}>
                    <InputLabel htmlFor="expiry-input-date" className={classes.formControlLabel}>{t('fields.expiry')} *</InputLabel>
                    <Grid container>
                        <Grid item className={classes.expiryInputDate}>
                            <FormControl className={classes.formControl}>
                                <CardExpiryElement
                                    options={options}
                                    onChange={props.onCardInputChange}
                                />
                                {props.cardExpiryError && (
                                    <FormHelperText className={classes.errorMessage}>{props.cardExpiryError}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item className={classes.expiryInputCVV}>
                            <FormControl className={classes.formControl}>
                                <CardCvcElement
                                    options={options}
                                    onChange={props.onCardInputChange}
                                />
                                {props.cardCvcError && (
                                    <FormHelperText className={classes.errorMessage}>{props.cardCvcError}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.formGroupHolder}>
                    <CustomCheckBox
                        name="termsAndCondtionChecked"
                        label="I agree to Counter T&C, Property T&C and Counter privacy policy*"
                        checked = {props.termsAndCondtionChecked}
                        onChangeHandler={props.checkBoxHandler}
                    />
                    {props.termsAndConditionError && (
                        <FormHelperText className={classes.errorMessage}>{t('bookingEngine.aceeptTerms')}</FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} className={classes.formTermsAndCondition}>
                    <CustomCheckBox
                        name="marketingUpdatesChecked"
                        label={t('bookingEngine.receiveUpdates')}
                        checked = {props.marketingUpdatesChecked}
                        onChangeHandler={props.checkBoxHandler}
                    />
                </Grid>
                <Box className={classes.cardErrorBlock}>
                    <FormHelperText className={classes.errorMessage}>{props.paymentError}</FormHelperText>
                </Box>
            </Grid>
            <Box className={`${classes.mainContentCard} ${classes.secureBlock}`} display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center" fontSize="16px" fontWeight="bold" color="#B7B7B7">
                        <Box>
                            <LockOutlinedIcon />
                        </Box>
                        <Box ml={2}>
                            Secure purchase
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" fontSize="16px" fontWeight="bold" color="#B7B7B7" >
                        <Box width="100px" height="50px">
                            <img src={"/images/masterCard.svg"} className={classes.secureImage} alt="Mastercard Securecode" />
                        </Box>
                        {/* <Box ml={2} width="100px" height="50px">
                            <img src={"/images/masterCard.svg"} className={classes.secureImage} alt="Visa" />
                        </Box> */}
                    </Box>
                </Box>
        </Card>
    );
}

export default withTranslation()(CardSection);