import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme =>({
    root: {
    
    },
    dialogContent: {
        display: "flex",
        alignItems: "center",
        lineHeight: "22px",
        color: "#4f4f4f",
        padding: "40px",
        fontSize: "1.6rem",
        minHeight: "160px",
        '& a': { 
            color: "#0055dc",
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const UpgradeMsgModal = props =>{
    const classes = useStyles();
    const { closeModalHandler } = props;
    return(
        <Dialog
            open={true}
            onClose={closeModalHandler}
            maxWidth="sm"
        >
            <IconButton className={classes.closeButton} onClick={closeModalHandler}>
                <CloseIcon />
            </IconButton>

            <DialogContent className={classes.dialogContent}>
                <p>This is a Plus feature. Please upgrade your account in order to unlock this advanced functionality.
                Contact <a href="mailto:support@counter.app">support@counter.app</a> if you need help upgrading your account.</p>

            </DialogContent>
        </Dialog>
    )
}

export default UpgradeMsgModal;