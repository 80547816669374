import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import RoomInfoCard from "./roomInfoCard";
import RatePlanListItem from "./ratePlanListItem";
import AddBedInput from "../addBedInput";

import { updateSelectedBookingData, addRoomToBookingCart } from "../../../redux/actions/bookingEngine";
import { calculateSumOfArray, calculateBaseRoomCost } from "../helper";

const secondaryColor = '#5C5C5C';

const styles = theme => ({
    bedsQuantity: {
        display: "flex", 
        margin: '30px 0',

        [theme.breakpoints.up('sm')]: {
            flexDirection: "column"
        },

        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    bedsQuantityTitle: {
        [theme.breakpoints.up('sm')]: {
            marginBottom: '20px'
        }
    },
    bedQuantityIcon: {
        backgroundColor: '#fff',
        boxShadow: '0 2px 20px 0px #F0F0F0',
        height: '45px',
        width: '45px',

        '&:hover': {
            backgroundColor: '#E0E0E0',
        }
    },
    bedQuantity: {
        height: '45px',
        width: '50px',
        display: 'inline-block',
        textAlign: 'center',
        lineHeight: '45px',
        fontSize: '20px',
        fontWeight: 'bold'
    },
    addBedContainer: {
        display: "flex",
        alignItems: "center",
        marginLeft: "20px",
        [theme.breakpoints.up('sm')]: {
            marginLeft: "0px"
        },
    }
});

class RatePlanList extends Component{

    componentDidMount(){
        window.scrollTo(0, 0)
    }
    
    addBedInputRenderer = (addBedHandler,removeBedHandler,value) =>{
        const { classes, dormOrPrivate } = this.props;
        return(
            <Typography component="div">
                <Box className={classes.bedsQuantity}>
                    <Box variant="h5" component="h5" className={`${classes.bedsQuantityTitle}`} m={0} fontWeight="bold" fontSize="18px">
                   { dormOrPrivate == 'dorm' ? 'Beds' : 'Rooms'}
                    </Box>
                    <Box className={classes.addBedContainer}>
                        <Fab 
                            aria-label="add" 
                            className={classes.bedQuantityIcon}
                            onClick = {removeBedHandler} 
                        >
                            <RemoveIcon />
                        </Fab>
                        <span className={classes.bedQuantity}>{value}</span>
                        <Fab 
                            aria-label="add" 
                            className={classes.bedQuantityIcon}
                            onClick ={addBedHandler}
                        >
                            <AddIcon />
                        </Fab>
                    </Box>
                </Box>
            </Typography>
        );
    }

    updateBedCountHandler = data =>{
        const { dispatch } = this.props;
        const updatePayload = { noOfBedsBooked: data.value };
        dispatch(updateSelectedBookingData(updatePayload));
    }

    bookNowHandler = data =>{
        const { nextStep, noOfBedsBooked, selectedRoomTypeID, dormOrPrivate, dispatch } = this.props;
        const roomCostData = {
            noOfBedsBooked,
            sumOfSelectedRatePlan: data.sumOfSelectedRatePlan
        };
        const baseRoomCost = calculateBaseRoomCost(roomCostData);

        const payload = { 
            ratePlanID: data.id, 
            baseRoomCost,
            rates: data.rates,
            noOfBedsBooked,
            dormOrPrivate
        };
        dispatch(updateSelectedBookingData(payload));
        dispatch(addRoomToBookingCart(selectedRoomTypeID, payload));
        nextStep();
    }

    render(){
        const { 
            roomTypeInfo,
            ratePlanListData,
            selectedRoomData,
            currency,
            noOfBedsBooked,
            t,
            classes
        } = this.props;
        return(
            <div style={{ paddingBottom: 30 }}>
                <RoomInfoCard  roomData = {roomTypeInfo}/>
                <AddBedInput 
                    value = {noOfBedsBooked} 
                    minValue = {1}
                    maxValue = {selectedRoomData.available}
                    onChangeHandler = {this.updateBedCountHandler}
                    renderHandler = {this.addBedInputRenderer}
                />
                <RatePlanListItem 
                    ratePlanListData = {ratePlanListData} 
                    bookNowHandler = {this.bookNowHandler}
                    currency = {currency}
                    t={t}
                />
            </div>
        );
    }
}

const mapStateToProps = state =>{
    //console.log('state ', state);
    const { bookingEngine } = state;
    const { selectedRoomTypeID, dormOrPrivate } = bookingEngine;
    const ratePlanListData = [];
    const selectedRoomData = bookingEngine.roomList[selectedRoomTypeID];
    const roomTypeInfo = bookingEngine.roomTypes[selectedRoomTypeID];
    const noOfBedsBooked = bookingEngine.selectedBookingData.noOfBedsBooked;

    // Rate plan IDs of the selected room
    const ratePlanIDs = Object.keys(selectedRoomData.rates).map(ratePlanID =>ratePlanID);
    // const propertyNotation = 'hw';
    
    ratePlanIDs.map(id =>{
        const sumOfSelectedRatePlan = calculateSumOfArray(selectedRoomData.rates[id]);
        let perNightCost = (sumOfSelectedRatePlan / bookingEngine.bookingDuration).toFixed(2);
        
        const ratePlanData = bookingEngine.ratePlans[id]
        if(ratePlanData?.visibility?.includes("Booking Engine")){
            ratePlanListData.push({ 
                // ...ratePlanData[propertyNotation],
                sumOfSelectedRatePlan,
                ratePlanName: ratePlanData.publicName || 'Standard Rates',
                perNightCost: parseFloat(perNightCost),
                rates: selectedRoomData.rates[id],
                id,
                description: ratePlanData?.description || '',
                cancellationPolicy: ratePlanData?.cancellationPolicy || {},
                nonRefundable: ratePlanData?.nonRefundable,
                nrPolicy: ratePlanData?.nrPolicy
            });
        }
    });

    return{
        selectedRoomTypeID,
        selectedRoomData,
        roomTypeInfo,
        ratePlanListData,
        noOfBedsBooked,
        currency: bookingEngine.currency,
        dormOrPrivate
    }
};

export default withStyles(styles)(connect(mapStateToProps)(RatePlanList));