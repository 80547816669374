import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Select from 'react-select';
import Dialog from '../common/CustomDialog';
import Footer from '../new_reservation/common/Footer';
import { TextField, Grid } from "@material-ui/core";
import { createCashFlow } from "../../redux/actions/cashDrawer";
import { TRANSACTION_TYPE } from "../../utils/constants";

const styles = (theme) => ({
  root: {
    '& .dialogBody': {
        minHeight: "360px",
        maxHeight: "360px",
    },
  },
  textField: {
    margin: "00px",
    "& input, & select, & textarea, & div": {
      backgroundColor: "#FFFFFF"
    },
    "& input": {
      height: '2em'
    },
    "& label": {
      fontSize: "1.6rem",
      padding: "5px 0 0 6px"
    }
  },
  dropdownInput: {
    "& > div": {
      minHeight: 55
    },
    "& .css-26l3qy-menu": {
      zIndex: 5000
    }
  }
});

const selectStyle = {
  control: base => ({
    ...base,
    // border: 0,
    paddingLeft: 8,
    marginTop: 8,
    marginBottom: 4,
    '[type="text"]': {
      fontFamily: 'Roboto, sans-serif !important',
      fontSize: '1.4rem',
      color: 'rgba(0, 0, 0, 0.87)'
    },
    // This line disable the blue border
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxShadow: 'none',
    '&:hover': {
        border: '1px solid black',
    }
  })
};
class CashOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        type: TRANSACTION_TYPE.CASH_OUT
      },
      typeOptions: [],
      selectedOption: null,
    }
  }

  //------------------------------------
  //------ life cycle functions --------
  //------------------------------------ 
  componentDidUpdate(prevProps, prevState) {
    if (this.props.createCashFlow && !_.isEqual(this.props.createCashFlow, prevProps.createCashFlow)) {
      this.props.handleCashFlow('')
    }
  }
  //------------------------------------
  //-------- Data on load --------------
  //------------------------------------
  handleAccountsOption = () => {
    let accounts = this.props.accounts;
    let accOptions = [];
    for (var key in accounts) {
      if (accounts.hasOwnProperty(key)) {
        accOptions.push({ value: accounts[key]._id, label: accounts[key].name })
      }
    }
    return accOptions;
  }

  //------------------------------------
  //------- On change functions --------
  //------------------------------------
  handleFieldChange = name => event => {
    let data = name === "type" ? {} : { ...this.state.data };
    let temp = data;
    let value = event.target.value;
    let keyName = name.split('.');
    for (var i = 0; i < keyName.length - 1; i++) {
      temp = temp[keyName[i]] = temp[keyName[i]] || {};
    }
    temp[keyName[keyName.length - 1]] = value;
    if(keyName[keyName.length - 1] === "categoryID") {
      data.accounting.subCategoryID = null;
    }
    this.setState({ data });
  }

  handleAccountChange = (option) => {
    let typeOptions = [];
    let value = option && option.value;
    if (value) {
      let accounts = { ...this.props.accounts };
      let accountTypes = { ...this.props.accountTypes };
      let accountData = (value && accounts[value]) || {};
      const sub = accountData.subCategory || [];
      for (let index = 0; index < sub.length; index++) {
        if (accountTypes[sub[index]])
          typeOptions.push({ value: accountTypes[sub[index]]._id, label: accountTypes[sub[index]].name })
      }
    }
    this.setState({ typeOptions, selectedOption: null })
    this.handleFieldChange("accounting.categoryID")({ target: { value: value } });
  }

  //------------------------------------
  //------- On submit function --------
  //------------------------------------
  handleSubmit = () => {
    const data = { ...this.state.data };
    let amount = (-1 * parseFloat(data.amount));

    let payload = {
      amount: amount || null,
      cashDrawerSessionID: this.props.cashDrawerSessionID,
      type: data.type,
      accounting: data.accounting,
      note: data.note
    }
    this.props.dispatch(createCashFlow(payload))
  }

  //------------------------------------
  //-------- On Render function --------
  //------------------------------------
  render() {
    const { classes } = this.props;
    const data = { ...this.state.data };
    const typeOptions = [...this.state.typeOptions];
    const accountOptions = this.handleAccountsOption();
    const { selectedOption } = this.state;

    // ----------------------------------------------
    // ------- Display account type dropdown --------
    // ----------------------------------------------
    const typeView = (typeOptions.length ?
      <Grid item xs={12}>
        <Select
          options={typeOptions}
          value={selectedOption}
          styles={selectStyle}
          className={classes.dropdownInput}
          isClearable={true}
          onChange={(e) => {
            this.setState({ selectedOption: e });
            this.handleFieldChange("accounting.subCategoryID")({ target: e || { value: null } })
          }}
          placeholder={"Type"}
        />
      </Grid> : null
    );
    // ----------------------------------------------
    // --------- Display amount textfield -----------
    // ----------------------------------------------
    const amountView = (data.type ?
      <Grid item xs={12}>
        <TextField
        error={data.amount <= 0 ? true: false }
        helperText={data.amount <= 0 ? "Enter amount more than 0": ""}
        className={classes.textField}
        value={data.amount || ""}
        onChange={this.handleFieldChange("amount")}
        variant="outlined"
        margin="dense"
        id="amount"
        label="Amount"
        type="number"
        inputProps={{ min: "0", step: "1" }}
        fullWidth />
      </Grid> : null
    );
    // ----------------------------------------------
    // ---------- Display notes textfield -----------
    // ----------------------------------------------
    const noteView = (data.type ?
      <Grid item xs={12}>
        <TextField
        className={classes.textField}
        onChange={this.handleFieldChange("note")}
        value={data.note || ""}
        variant="outlined"
        margin="dense"
        multiline
        rows="2"
        id="note"
        label="Notes"
        fullWidth />
      </Grid> : null
    );

    return (

      <Dialog
        className={classes.root}
        headerTitle={'Cash Out'}
        closeModalHandler={() => this.props.handleCashFlow('')}
        isLoading={false}
      >
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
                options={accountOptions}
                styles={selectStyle}
                className={classes.dropdownInput}
                isClearable={true}
                onChange={this.handleAccountChange}
                placeholder={"Account"}
              />
            </Grid>
            
            {typeView}
            {amountView}
            {noteView}
          </Grid>
        </div>

        <Footer 
          label="Submit" 
          isBtnDisabled={false}
          onBtnClick={this.handleSubmit}
        />
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  let cashDrawerID = state.register.cashDrawerID;
  let cashDrawerData = state.property.cashDrawer || {};
  let cashDrawerSessionID = cashDrawerData[cashDrawerID] ? cashDrawerData[cashDrawerID].openSessionID : null;
  return {
    cashDrawerSessionID: cashDrawerSessionID,
    createCashFlow: state.cashDrawer.createCashFlow,
    accounts: state.property.accounts,
    accountTypes: state.property.accountTypes,
  }
}

export default withStyles(styles)(connect(mapStateToProps)(CashOut));