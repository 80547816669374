// Filters Reducer
const filtersReducerDefaultState = {
  text: '',
  sortBy: '',
  arrivalDate: '',
  guestStatus: '',
  roomType: '',
  onLoad: true,
  isSearched: null,
  isSearching: false,
  searchActive: false
}

const defaultState = {
  reservation: null,
  guests: null,
  accommodationList: null,
  reservationIDs: [],
  suggestions: []
}
export default (state = { ...filtersReducerDefaultState, ...defaultState }, action) => {
  switch (action.type) {
    case 'TOGGLE_SEARCH_ACTIVE':
      return {
        ...state,
        searchActive: action.status
      }
    case 'SET_TEXT_FILTER':
      return { ...state, text: action.text }
    case 'SET_SORT_BY':
      return { ...state, sortBy: action.sortBy }
    case 'SET_GUEST_STATUS':
      return { ...state, guestStatus: action.guestStatus }
    case 'SET_ROOM_TYPE':
      return { ...state, roomType: action.roomType }
    case 'SET_ARRIVAL_DATE':
      return { ...state, arrivalDate: action.arrivalDate }
    // ----------------------------------------------------------------
    case 'RESET_FILTER':
      return { ...state, ...filtersReducerDefaultState, ...defaultState }
    // ----------------------------------------------------------------
    case "SEARCH_RESERVATION_REQUEST":
      return { ...state, ...defaultState, isSearched: false, isSearching: true, onLoad: false };
    case "SEARCH_RESERVATION_SUCCESS":
      return { ...state, ...action.payload, isSearched: true, isSearching: false };
    case "SEARCH_RESERVATION_FAILURE":
      return { ...state, ...defaultState, isSearched: null, isSearching: false };
    // ----------------------------------------------------------------
    default: return state;
  }
}