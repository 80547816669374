import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import AddCardModal from '../summary/card-capture/AddCardModal';
import CardSelect from '../payment/card-details/CardSelect';

const styles = theme => ({
    root: {
        marginTop: '40px'
    },
});
class ViewCardDetails extends Component {

    state = {
        selectedCardToken: null,
        isCardFetchLoading: false,
        cardData: null,
        showAddCardModal: false
    }

    componentDidMount() {
        const { maskedCards } = this.props;
        if (maskedCards && maskedCards.length > 0) this.setState({ selectedCardToken: maskedCards[0].card_token });
    }

    cardSelectHandler = cardNumber => {
        this.setState({
            selectedCardToken: cardNumber.value,
            cardData: null
        });
    }

    closeModalHander = () => {
        this.setState({ showAddCardModal: false });
    }

    render() {

        const {
            classes,
            maskedCards,
            reservationID
        } = this.props;

    
        const { selectedCardToken, showAddCardModal } = this.state;

        return (
            <div className={ classes.root}>

               <CardSelect
                    addCardHandler={() =>this.setState({ showAddCardModal: true })}
                    selectCardHandler={this.cardSelectHandler}
                    selectedCardToken={selectedCardToken}
                    maskedCards={maskedCards}
               />

                {showAddCardModal && (
                    <AddCardModal
                        isOpen={showAddCardModal}
                        reservationID={reservationID}
                        closeModalHander={this.closeModalHander}
                    />
                )}

            </div>
        );
    }
}

const mapStateToProps = state => {
    const { reservationDetails } = state;

    return {
        maskedCards: reservationDetails.maskedCards,
        reservationID: reservationDetails.reservation?._id,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(ViewCardDetails));