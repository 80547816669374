import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import moment from "moment-timezone";

import {
    Box,
    Avatar,
    FormControl,
    InputLabel,
    TextField,
    FormHelperText,
    Button
} from '@material-ui/core';
import { SingleDatePicker } from 'react-dates';
import { fetchDateFormat, DATE_FORMATS, getMomentAddDayISO } from '../../../utils/utility';
import { guests, accommodationList } from './data';

const styles = theme =>({
    root: {
        margin: '0 auto',
        width: '50%',
        paddingTop: '60px',
        [theme.breakpoints.down('sm')]: {
           width: '100%'
        },
    },
    propertyInfoHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        marginBottom: '20px',
        fontWeight: 'bold'
    },
    propertyImage: {
        width: '50px',
        height: '50px',
        marginRight: '15px'
    },
    inputLabel:{
       fontWeight: 'bold',
       color: '#000',
       fontSize: '13px'
    },
    formControl:{
        width: '100%',
        '& .DateInput_input':{
            padding: '5px 5px',
            fontSize: '16px'
        },
        '& .DateInput_input__focused':{
            borderBottom: 'none'
        },
        '& .SingleDatePickerInput':{
            marginTop: '5px',
            border: 'none',
            boxShadow: '0 0 8px 0px #dedede',
            borderRadius: '5px',
        },
        '& .DateInput':{
            borderRadius: '5px',
        }
    },
    textInput:{
        boxShadow: '0 0 8px 0px #dedede',
        borderRadius: '5px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            '&.Mui-error fieldset': {
                border: '1px solid #f44336'
            },
        },
    },
    submitBtn:{
        width: '100%',
        borderRadius: '20px',
        backgroundColor: '#000',
        color: '#fff',
        padding: '10px',
        marginTop: '20px',
        fontWeight: 'bold',
        '&:hover':{
            border: '0.5px solid #000',
            color: '#000',
        }
    },
    errorMessage: {
        color: "#f44336",
    },
})

const formHelper = {
    fullnameError: false,
    checkinDateError: false
}
class CheckinFrom extends Component{
    state = {
        fullname: '',
        checkInDate: null,
        focusedInput: false,
        ...formHelper
    };

    onInputChangeHandler = event =>{
        const fullname = event.target.value;
        this.setState({ 
            fullname: fullname,
            fullnameError: !fullname.trim() ? true : false 
        });
    }

    dateChange = (checkInDate) => {
        this.setState({ 
            checkInDate: checkInDate, 
            checkinDateError: !checkInDate ? true : false
        });
    };

    focusChange = ({ focused }) => {
        this.setState({ focusedInput: focused });
    };

    validateCheckinDetails = () =>{
        const { 
            fullname,
            checkInDate
         } = this.state;

        let formInputStatus = { ...formHelper };
        let validationSuccess = true;

        if(!fullname.trim()){
            formInputStatus.fullnameError = true;
            validationSuccess = false;
        }

        if(!checkInDate){
            formInputStatus.checkinDateError = true;
            validationSuccess = false;
        }

        this.setState({ ...formInputStatus });
        return validationSuccess;
    }

    submitHandler = () =>{
        if(this.validateCheckinDetails()){
            const { dispatch, nextStep } = this.props;
            const { fullname, checkInDate } = this.state;

            guests['CUST_5ef1ec3e7d5f390e7e3e119f_1'].firstName = fullname;
            const checkoutDate = getMomentAddDayISO(checkInDate, 3);
            
            dispatch({ 
                type: `FETCH_RESERVATION_DETAILS_SUCCESS`, 
                payload: {
                    accommodationList: [...accommodationList],
                    guests: { ...guests },
                    reservationID: '5ef1ec3e7d5f390e7e3e119e',
                    balance: 1000,
                    taxAmount: 10,
                    totalAmount: 1000,
                    checkIn: fetchDateFormat(checkInDate, DATE_FORMATS.DEFAULT_DATE),
                    checkOut: fetchDateFormat(checkoutDate, DATE_FORMATS.DEFAULT_DATE),
                    accomodationLinkRequired: false,
                    maskedCards: [],
                    status: "Confirmed"
                }
            });

            nextStep();
        }
    }

    render(){
        const { classes, propertyTimezone = null } = this.props;
        const {
            fullname,
            checkInDate,
            focusedInput,
            fullnameError,
            checkinDateError
        } = this.state;

        const {
            propertyDetails = {
                images: [],
                timezone: null
            }
        } = this.props;

        return(
            <Box className={classes.root}>
                <Box className={classes.propertyInfoHeader}>
                    <Avatar 
                        alt={propertyDetails.name} 
                        src={propertyDetails.images && propertyDetails.images[propertyDetails.images.length - 1]}
                        className={classes.propertyImage}
                    />
                    <Box>
                        {propertyDetails.name}
                    </Box>
                </Box>
                <Box>
                    <Box mb={3}>
                        <InputLabel htmlFor="last-name" className={classes.inputLabel}>Enter name</InputLabel>
                        <FormControl className={classes.formControl}>
                            <TextField
                                variant="outlined" 
                                margin="dense"
                                fullWidth = {true}
                                name = "fullname"
                                value = {fullname}
                                className={classes.textInput}
                                // error = {fullnameError}
                                onChange = { this.onInputChangeHandler }
                            />
                            {fullnameError && (
                                <FormHelperText className={classes.errorMessage}>Fullname name is required</FormHelperText>
                            )}
                        </FormControl>
                    </Box>
                    <Box mb={3}>
                        <InputLabel htmlFor="check-in" className={classes.inputLabel}>Enter check-in date</InputLabel>
                        <FormControl className={classes.formControl}>
                            <SingleDatePicker
                                date={checkInDate} 
                                onDateChange={this.dateChange}
                                focused={focusedInput}
                                onFocusChange={this.focusChange}
                                id="checkin_date"
                                placeholder=""
                                numberOfMonths={1}
                                isOutsideRange={day => day.isBefore(moment.tz(new Date(), propertyDetails.timezone).format('YYYY-MM-DD'))}
                                showClearDate={true}
                                readOnly
                                displayFormat={'DD MMM YY'}
                                block
                            />
                            {checkinDateError && (
                                <FormHelperText className={classes.errorMessage}>Check-in date is required</FormHelperText>
                            )}
                        </FormControl>
                    </Box>
                    <Box>
                        <Button 
                            className={classes.submitBtn}
                            onClick={this.submitHandler}
                        >
                                Online self check-in
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    }
}

const mapStateToProps = state =>{
    const { onlineCheckin } = state;
    return{
        propertyDetails:  onlineCheckin.propertyDetails
    }
}

export default withStyles(styles)(connect(mapStateToProps)(CheckinFrom));