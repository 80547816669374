import { schema } from "normalizr";

// Define cash drawer schema
const sessions = new schema.Entity("sessions", {}, { idAttribute: "_id" });
const cashDrawerSchema = new schema.Entity("cashDrawer", {sessions: [sessions]}, { idAttribute: "_id" });

const subCategory = new schema.Entity("subCategory", {}, { idAttribute: "_id" });
const cashAccountSchema = new schema.Entity("accounts", {subCategory: [subCategory]}, { idAttribute: "_id" })

const transactions = new schema.Entity("transactions", {}, { idAttribute: "_id" });
const closeCashDrawerSchema = new schema.Entity("closeCashDrawer", {transactions: [transactions]}, { idAttribute: "cashDrawerID" })

// Define your all rooms schema
export { cashDrawerSchema, cashAccountSchema, closeCashDrawerSchema };