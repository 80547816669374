import React, { Component } from 'react';
import { Switch, Route, Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

import { withStyles } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import Header from '../components/booking_engine/header';
import Stepper from '../components/online_checkin/Stepper';
import CheckinFrom from '../components/online_checkin/checkinForm';
import PersonalInformation from '../components/online_checkin/personal_information';
import Rules from '../components/online_checkin/rules';
import Activities from '../components/online_checkin/activities';
import Payment from '../components/online_checkin/Payment';
import CheckinSuccess from '../components/online_checkin/CheckinSuccess';

import { fetchPropertyInfo } from '../redux/actions/onlineCheckin';

const styles = theme =>({
    main: {
        background: '#F8F9FB',
    },
    root: {
        background: '#F8F9FB',
        minHeight: 'calc(100vh - 80px)',
        marginTop: '80px',
        paddingTop: '30px',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 40px)',
            marginTop: '40px'
        }
    },
    mainContentHolder:{
        background: '#F8F9FB',
        width: '650px',
        margin: '35px auto',
        paddingBottom: 35,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    mainContent:{
        paddingBottom: '30px',
        background: '#F8F9FB',
    }
});

const ROUTE_PAGES = {
    '/': { nextUrl: 'personal-info', prevUrl: ''},
    '/personal-info': { nextUrl: 'rules', prevUrl: '', title: 'Personal Information'},
    '/rules': { nextUrl: 'payment', prevUrl: 'personal-info', title: 'Rules'},
    //'/activities': { nextUrl: 'payment', prevUrl: 'rules', title: 'Activities'},
    '/payment': { nextUrl: 'success', prevUrl: 'rules', title: 'Payment'},
}
class OnlineCheckin extends Component{
    state = {
        showPage: false,
        activeStep: 0
    }

    setDocumentTitle = (title) =>{
        document.title = title;
    }

    componentDidMount(){
        const { location, match, history } = this.props;
        document.body.style.backgroundColor="#fff";
        if(location.pathname != match.url){
            history.replace(match.url);
        }
        const propertyHandle = match.params.id;
        this.props.dispatch(fetchPropertyInfo(propertyHandle, this.pageNotFoundHandler));
    }

    componentDidUpdate(prevProps){
        if(!_.isEqual(this.props.propertyDetails, prevProps.propertyDetails)){
            this.setDocumentTitle(this.props.propertyDetails.name);
        }
    }

    nextStep = () =>{
        const { history, match, bookingStatus } = this.props;
        const path = this.fetchCurrentPath();
        let basePath = match.url;
        basePath = basePath[basePath.length - 1] == '/' ? basePath.slice(0, -1) : basePath;
  
        if(bookingStatus == 'Online Checked In' || bookingStatus == 'In House'){
            this.setState(prevState => ({...prevState, activeStep: 4}))
            history.push(`${basePath}/success`);
            return;
        }

        if(path) history.push(`${basePath}/${ROUTE_PAGES[path].nextUrl}`);
        window.scrollTo(0,0);

        this.setState(prevState => ({...prevState, activeStep: prevState.activeStep + 1}));
    }

    fetchCurrentPath = () =>{
        const { location, match } = this.props;
        // console.log(this.props);
        if(location.pathname == match.url) return '/';
        const path = location.pathname.replace(match.url,'');
        return path
    }

    pageNotFoundHandler = () =>{
        this.setState({ isPageNotFound: true });
    }

    render(){
        const { classes, match, propertyDetails, settings, i18n } = this.props;
        const propertyImage = settings && settings.propertyLogo ? settings.propertyLogo : '';
        const currentPagePath = this.fetchCurrentPath();
        const routeProps = {
            currentPagePath,
            nextStep: this.nextStep
        }
        return(
            <div className={classes.main}>
                
                <Header 
                    title={propertyDetails.name}
                    currency={propertyDetails.currency}
                    hideCurrency={true}
                    image = {propertyImage}
                    i18n = {i18n}
                    hideCurrency={true}
                />
                
                <Container className={classes.root}>

                    {this.state.activeStep!==4 && <Stepper 
                        activeStep={this.state.activeStep}
                    />}

                    <Box className={`${classes.mainContentHolder}`}>
                        <Box className={classes.mainContent}>
                            <Switch>
                                <Route path={`${match.url}`} exact render={props =><CheckinFrom {...props} {...routeProps} />} />
                                <Route path={`${match.url}/personal-info`} exact render={props =><PersonalInformation {...props} {...routeProps} />} />
                                <Route path={`${match.url}/rules`} exact render={props =><Rules {...props} {...routeProps} />} />
                                {/*<Route path={`${match.url}/activities`} exact render={props =><Activities {...props} {...routeProps} />} /> */}
                                <Route path={`${match.url}/payment`} exact render={props =><Payment {...props} {...routeProps} />} />
                                <Route path={`${match.url}/success`} exact render={props =><CheckinSuccess {...props} {...routeProps} />} />
                            </Switch>
                        </Box>
                    </Box>
                </Container>
            </div>
        )
    }
} 

const mapStateToProps = state =>{
    const { onlineCheckin } = state;
    const propertyDetails = onlineCheckin.propertyDetails ? onlineCheckin.propertyDetails: {};
    return{ 
        propertyDetails,
        bookingStatus: onlineCheckin.status,
        settings: onlineCheckin.settings
    };
}

export default withStyles(styles)(connect(mapStateToProps)(OnlineCheckin));