import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import clsx from "clsx";

import RefreshIcon from "@material-ui/icons/Refresh";
import DownArrow from "@material-ui/icons/ArrowDownward";
import UpArrow from "@material-ui/icons/ArrowUpward";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";

import Dialog from "../common/CustomDialog";
import {
  fetchDateFormat,
  DATE_FORMATS,
  fetchTemplateString,
} from "../../../utils/utility";
import { LOG } from "../../../common/constants/log";
import { stableSort, getRoomTypes, processLogData } from "./activityLogHelper";
import { loadActivityLog } from "../../../redux/actions/reservations";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "0px",
  },
  header: {
    alignItems: "center",
    borderBottom: "0px solid #dddddd",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 15,
  },
  orderBy: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    fontSize: "1.2rem",
    marginLeft: 15,
    marginRight: 5,
    color: "#BDBDBD",
    "&:hover": {
      color: "#333333",
    },
  },
  sortIcon: {
    marginRight: 5,
  },
  logsConatiner: {
    color: "#bdbdbd",
    fontSize: "1.4rem",
    lineHeight: 1.5,
  },
  noteItem: {
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
    marginBottom: "20px",
    padding: "10px 20px",
  },
  chip: {
    alignItems: "center",
    display: "inline-block",
    justifyContent: "center",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
  },
  smallText: {
    fontSize: "1.1rem",
    paddingRight: 5,
    fontWeight: 600,
  },
  highlightedText: {
    color: "#305c8b",
    cursor: "pointer",
    display: "block",
    fontWeight: 600,
    overflow: "hidden",
  },
  lightText: {
    color: "#828282",
    fontWeight: 400,
    textAlign: "right",
  },
  subject: {
    cursor: "pointer",
    display: "block",
    color: "#5f5f5f",
    fontSize: "1.4rem",
    overflow: "auto",
    paddingTop: theme.spacing(1),
    width: "100%",
    wordWrap: "break-word",
    display: "flex",
    alignItems: "center",
    margin: "0px",
    "&.isExpandable": {
      paddingTop: "unset",
    },
  },
  noActivity: {
    paddingTop: 0,
    textAlign: "center",
    color: "#999999",
    fontSize: "1.4rem",
    overflow: "auto",
    width: "100%",
    wordWrap: "break-word",
  },
  resFields: {
    display: "flex",
    marginTop: "10px",
    marginLeft: "10px",
    flexDirection: "column",
  },
  detail: {
    fontSize: "1.2rem",
    color: "#999999",
  },
  actionIcon: {
    cursor: "pointer",
    padding: 5,
  },
  noLogs: {
    color: "#bdbdbd",
    fontSize: "1.4rem",
    textAlign: "center",
    marginTop: "30px",
  },
}));

const ActivityLogModal = (props) => {
  const classes = useStyles();
  const {
    closeModalHandler,
    isLoading,
    activityLog,
    timezone,
    roomTypes,
    dispatch,
    reservation,
  } = props;
  const [newestFirst, setNewestFirst] = React.useState(false);
  const [expanded, setExpanded] = React.useState({});

  const fetchActivityLog = () => {
    dispatch(loadActivityLog(reservation._id));
  };

  const getLogBody = (log, items, loopText) => {
    if (log.activity === "CREATE_RESERVATION") {
      return (
        <div className={classes.resFields}>
          <div className={classes.detail}>
            Check In:{" "}
            {fetchDateFormat(
              log.reservation.checkIn,
              `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY}`,
              timezone
            )}
          </div>
          <div className={classes.detail}>
            Check Out:{" "}
            {fetchDateFormat(
              log.reservation.checkOut,
              `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY}`,
              timezone
            )}
          </div>
          <div className={classes.detail}>
            Room Type:{" "}
            {getRoomTypes(log.reservation.accommodationList, roomTypes)}
          </div>
          <div className={classes.detail}>
            Number of guests: {log.reservation.guestCount}
          </div>
          <div className={classes.detail}>
            Booking Source: {log.reservation.bookingSource}
          </div>
          <div className={classes.detail}>
            Amount: {log.reservation.totalAmount}
          </div>
          <div className={classes.detail}>
            Balance: {log.reservation.balance}
          </div>
        </div>
      );
    }

    if (log) {
      return (
        expanded &&
        expanded[log._id] === true &&
        items?.map((item, itemIndex) => {
          if (log.activity === LOG.CHANGE_ROOM_AND_ARRIVAL_DATE.CODE) {
            return (
              <div className={classes.resFields} key={itemIndex}>
                {item.initialBed && (
                  <div className={classes.detail}>
                    Room changed from {item.initialBed} to {item.finalBed}
                  </div>
                )}
                {item.initialCheckIn && (
                  <div className={classes.detail}>
                    Arrival date changed from {item.initialCheckIn} to{" "}
                    {item.finalCheckIn}
                  </div>
                )}
                {item.initialCheckOut && (
                  <div className={classes.detail}>
                    Departure date changed from {item.initialCheckOut} to{" "}
                    {item.finalCheckOut}
                  </div>
                )}
                {item.initialAmount &&
                  item.initialAmount !== item.finalAmount && (
                    <div className={classes.detail}>
                      Reservation rate changed from {log.currency}{" "}
                      {item.initialAmount} to {log.currency} {item.finalAmount}
                    </div>
                  )}
              </div>
            );
          }

          return (
            <div key={itemIndex} className={classes.detail}>
              {fetchTemplateString(loopText, { ...log, ...item })}
            </div>
          );
        })
      );
    }
    return null;
  };

  return (
    <Dialog
      className={classes.root}
      headerTitle={"Activity"}
      closeModalHandler={closeModalHandler}
      isLoading={isLoading}
    >
      <div className={classes.header}>
        <div onClick={fetchActivityLog} className={classes.orderBy}>
          <RefreshIcon className={classes.actionIcon} />
          Refresh Logs
        </div>

        {newestFirst && (
          <div
            onClick={() => setNewestFirst(false)}
            className={classes.orderBy}
          >
            <UpArrow className={classes.sortIcon} />
            Newest first
          </div>
        )}

        {!newestFirst && (
          <div onClick={() => setNewestFirst(true)} className={classes.orderBy}>
            <DownArrow className={classes.sortIcon} />
            Oldest first
          </div>
        )}
      </div>

      <Grid className={classes.logsConatiner} container>
        {activityLog.length > 0 &&
          stableSort(activityLog, newestFirst).map((logData, index) => {
            const { log, text, loopText, items } = processLogData(logData);

            return (
              <Grid item xs={12} key={index} className={classes.noteItem}>
                <div className={classes.chip}>
                  <span
                    className={clsx(classes.smallText, classes.highlightedText)}
                  >
                    {fetchDateFormat(
                      log.createdAt,
                      `${DATE_FORMATS.MONTH} ${DATE_FORMATS.DAY} ${DATE_FORMATS.YEAR}, ${DATE_FORMATS.TIME}`,
                      timezone
                    )}
                  </span>
                </div>

                <p
                  className={clsx(classes.subject, {
                    isExpandable: items.length > 0,
                  })}
                >
                  {fetchTemplateString(text, log)}
                  {items &&
                    items.length > 0 &&
                    (expanded && expanded[log._id] === true ? (
                      <ExpandLessIcon
                        className={classes.actionIcon}
                        onClick={() =>
                          setExpanded({ ...expanded, [log._id]: false })
                        }
                      />
                    ) : (
                      <ExpandMoreIcon
                        className={classes.actionIcon}
                        onClick={() =>
                          setExpanded({ ...expanded, [log._id]: true })
                        }
                      />
                    ))}
                </p>

                {getLogBody(log, items, loopText)}
              </Grid>
            );
          })}

        {activityLog.length === 0 && (
          <div className={classes.noLogs}>
            No activity found for this reservation.
          </div>
        )}
      </Grid>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const { reservationDetails, rooms, property, loading } = state;
  return {
    activityLog: reservationDetails?.activityLog || [],
    reservation: reservationDetails?.reservation || {},
    roomTypes: rooms?.roomTypes || {},
    timezone: property?.timezone || "",
    isLoading: loading.LOAD_ACTIVITY_LOG,
  };
};

export default connect(mapStateToProps)(ActivityLogModal);
