import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

import Timeline from "./Timeline";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawerPaper: {
        width: '100%',
        backgroundColor: "#305c8b", 
    },
    header: {
        height: 60,
        display: 'flex',
        alignItems: 'center',
        padding: '0 20px',
        position: 'fixed',
        background: '#f8f9fb',
        width: '100%',
        zIndex: 9999,
    },
    section: {
        marginTop: 60,
    },
    arrowIcon: {
        borderRadius: '50%',
        color: '#666666',
        cursor: 'pointer',
        padding: theme.spacing(1),
        transition: '150ms',
        top: 10,
        marginRight: 10,
        '&:hover': {
            background: '#dddddd',
            color: '#666666'
        },
    },
    cardRoot: {
        margin: '10px',
        marginTop: "20px",
        marginLeft: '0px'
    }
}));

const SummaryDrawer = (props) => {
    const classes = useStyles();
    const { open, closeHandler } = props;

    return (
        <Drawer
            open={open}
            anchor={'left'}
            onClose={closeHandler}
            transitionDuration={400}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.header}>
                <div className={classes.toolbar}>
                    <ArrowBackRoundedIcon
                        className={classes.arrowIcon}
                        onClick={closeHandler}
                    />
                </div>
            </div>

            <div className={classes.section}>
                <div className={classes.cardRoot}>
                    <Timeline />
                </div>
            </div>
        </Drawer>
    )

}

export default SummaryDrawer;