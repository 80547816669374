import http from "../utils/http";
import { normalize } from "normalizr";
import { roomInfoSchema } from "../schema/bookingEngineSchema";

export function fetchPropertyInfo(propertyHandle, pageNotFoundHandler){
    const ACTION_NAME = "LOAD_KIOSK";
    return function (dispatch) {
    dispatch({ type: `${ACTION_NAME}_REQUEST` });
        return http
        .get(`/api/bookEngine/content/${propertyHandle}/`)
        .then(successResult => {
            let normalizedData = normalize(successResult.data, roomInfoSchema);
            // Set booking engine store data......
            dispatch({ 
                type: `FETCH_ROOMS_INFO_SUCCESS`, 
                payload: {
                    propertyDetails: successResult.data.property,
                    bookEngineConfig: successResult.data?.bookEngineConfig || {},
                    roomTypes: normalizedData.entities.roomTypes,
                    taxItems: normalizedData.entities.taxItems,
                    ratePlans: normalizedData.entities.ratePlans
                }
            });

            // Set online checkin store data......
            dispatch({ 
                type: `FETCH_CHECKIN_ROOMS_INFO_SUCCESS`, 
                payload: {
                    propertyDetails: successResult.data.property,
                    roomTypes: normalizedData.entities.roomTypes,
                    rooms: normalizedData.entities.rooms,
                    beds: normalizedData.entities.beds,
                    taxItems: normalizedData.entities.taxItems,
                    ratePlans: normalizedData.entities.ratePlans,
                    s3Path: successResult.data.s3Path,
                    settings: successResult.data.settings
                }
            });

            http.defaults.headers.common["Authorization"] = successResult.data.property._id;
            dispatch({ 
                type: `${ACTION_NAME}_SUCCESS`, 
                payload: {
                    kioskConfig: successResult.data.kioskConfig
                }
            });
        })
        .catch(errorResult => {

            const isApiError = (errorResult && 
                errorResult.response && 
                errorResult.response.data) ? true: false;

            if(isApiError && 
                errorResult.response.status && 
                errorResult.response.status == 404
            ){
                pageNotFoundHandler();
                return;
            }

            let error = isApiError ? errorResult.response.data : errorResult;
            console.error('Internal server error');
            dispatch({ type: `${ACTION_NAME}_ERROR` });
        });
    }
}

export const setMode = status =>({
    type: 'SET_KIOSK_MODE',
    payload: status
});